import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PublicBookingPageContainer } from '../../publicBookingPage';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { DEFAULT_BOOKING_PAGE_HOW, PREVIEW_MODAL, previewModalActions } from '../../../store/bookingPages';
import { Modal } from '../../common';
import labels from './labels';

import { Button } from 'primereact/button';
import { BookingPageLabelsInput, UpdateBookingPageInput } from '../../../API';

type IBookingPagePreviewModal = {
  bookingPage: UpdateBookingPageInput;
  onSave: (value: BookingPageLabelsInput) => void;
  isReadOnly?: boolean;
};
export const BookingPagePreviewModal = ({ bookingPage, onSave, isReadOnly }: IBookingPagePreviewModal) => {
  const dispatch = useDispatch();
  const bookingPageHow = useSelector(eventSelectors.selectBookingPageHow);

  const [maximized, setMaximized] = useState(false);

  const handleResetLabels = () => {
    dispatch(eventActions.setPreviewBookingPageHow(DEFAULT_BOOKING_PAGE_HOW));
  };

  const handlePreviousPage = () => {
    dispatch(eventActions.setPreviousStep());
  };

  const handleNextPage = () => {
    dispatch(eventActions.setNextStep());
  };

  const handleClose = () => {
    dispatch(previewModalActions.closeModal());
  };

  const handleSave = () => {
    onSave(bookingPageHow);
    dispatch(previewModalActions.closeModal());
  };

  return (
    <Modal.Container
      name={PREVIEW_MODAL}
      style={{ maxWidth: '1000px' }}
      className={`w-full h-screen ${maximized && 'max-w-full max-h-full'}`}
      contentStyle={{
        backgroundImage: `url(${bookingPage.style?.backgroundImage})`,
        backgroundColor: `${bookingPage.style?.lookAndFeel?.siteColorBackground}`,
      }}
      contentClassName={`p-0 bg-cover bg-center ${maximized ? 'border-noround' : ''}`}
      headerClassName={`p-2 ${maximized ? 'border-noround' : ''}`}
    >
      <Modal.Header>
        <div className="flex justify-content-between align-items-center">
          <div>
            <Button className="mr-2" label={labels.close} outlined onClick={handleClose} />
            {!isReadOnly && <Button label={labels.reset} text onClick={handleResetLabels} />}
          </div>
          <div className="text-xl font-semibold">{isReadOnly ? labels.preview : labels.previewTitle}</div>
          <div>
            {!isReadOnly && (
              <Button
                className="mr-2"
                label={labels.complete}
                icon="pi pi-angle-right"
                iconPos="right"
                onClick={handleSave}
              />
            )}
            <Button
              icon={`pi pi-window-${maximized ? 'minimize' : 'maximize'}`}
              text
              onClick={() => setMaximized((prev) => !prev)}
            />
          </div>
        </div>
      </Modal.Header>

      <div className="h-full flex pt-3">
        <div className="flex-1 text-center px-2">
          <Button
            style={{ transform: 'translateX(-50%)' }}
            className="fixed p-0 px-5 bg-black-alpha-30"
            icon="pi pi-angle-left text-8xl text-white"
            text
            onClick={handlePreviousPage}
          />
        </div>
        <PublicBookingPageContainer previewBookingPage={bookingPage} isReadOnly={isReadOnly} />
        <div className="flex-1 text-center px-2">
          <Button
            style={{ transform: 'translateX(-50%)' }}
            className="fixed p-0 px-5 bg-black-alpha-30"
            icon="pi pi-angle-right text-8xl text-white"
            text
            onClick={handleNextPage}
          />
        </div>
      </div>
    </Modal.Container>
  );
};
