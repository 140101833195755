import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { QuickSetupScreen } from '..';
import labels from './labels';
import { Button } from 'primereact/button';
import { quickSetupNavigationActions } from '../../../store/quickSetup';
import { Divider } from 'primereact/divider';
import { Badge } from 'primereact/badge';
import { userSettingsSelectors } from '../../../store/userSettings';

export const SetupCalendarConnectedStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const email = useSelector(userSettingsSelectors.selectConnectedEmail);
  const isFirstAdmin = useSelector(userSettingsSelectors.selectIsFirstTenantUser);

  const handleNext = () => {
    dispatch(quickSetupNavigationActions.navigateNext());
  };

  return (
    <QuickSetupScreen.Container title={labels.title}>
      <div className="flex flex-column align-items-center">
        <div>
          <span className="pi pi-check text-3xl text-green-500 font-bold mr-1 vertical-align-sub" />
          <span className="text-xl mt-3 font-bold">{labels.description}</span>
        </div>
        <div className="text-lg mt-2">{labels.message}</div>
        <div className="flex flex-column align-items-center col-12 md:col-8">
          <Divider className="my-4" />

          <div className="flex flex-column align-items-start">
            <div className="text-xl">{labels.instructionTitle + email}</div>
            <div className="pt-3">
              <Badge value="A" size="large" className="bg-gray-300 text-color font-semibold mr-3" />
              {labels.instructionStepA1 + '"' + email + '"' + labels.instructionStepA2}
            </div>
            <div className="pt-3">
              <Badge value="B" size="large" className="bg-gray-300 text-color font-semibold mr-3" />
              {labels.instructionStepB + '"' + email + '".'}
            </div>
            <div className="py-3">
              <Badge value="C" size="large" className="bg-gray-300 text-color font-semibold mr-3" />
              {labels.instructionStepC1}
              <span className="font-italic">{labels.instructionStepC2}</span>
              {labels.instructionStepC3}
            </div>
          </div>
          {isFirstAdmin ? (
            <div className="flex mt-3 p-2 border-round" style={{ background: '#e7f4fd' }}>
              <i className="pi pi-info-circle text-2xl pr-2" />
              {labels.instructionNote}
            </div>
          ) : (
            <Divider className="my-3" />
          )}
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <div className="flex justify-content-end">
          <Button label={labels.next} className="w-8rem m-2" onClick={handleNext} />
        </div>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
