import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { SortMethods, Statuses } from './typings';
import dayjs from 'dayjs';
import { bookingTemplatesSelectors } from '../bookingTemplates';
import { bookingPageSelectors } from '../bookingPages';
import { CustomFieldType } from '../../API';

const scheduledMeetingsState = (state: State) => state.scheduledMeetings;

const selectEvents = createSelector(scheduledMeetingsState, (state) => state.scheduledMeetings);
const selectIsFetching = createSelector(scheduledMeetingsState, (state) => state.isFetching);
const selectError = createSelector(scheduledMeetingsState, (state) => state.error);

const selectSortMethod = createSelector(scheduledMeetingsState, (state) => state.sortMethod);
const selectDateRange = createSelector(scheduledMeetingsState, (state) => state.dateRange);
const selectStatus = createSelector(scheduledMeetingsState, (state) => state.status);
const selectSelectedBookingPages = createSelector(scheduledMeetingsState, (state) => state.selectedBookingPages);

const selectFilteredEvents = createSelector(
  selectEvents,
  selectStatus,
  selectSelectedBookingPages,
  selectSortMethod,
  selectDateRange,
  (events, status, bookingPagesIds, method, dateRange) =>
    events.filter((event) => {
      if (bookingPagesIds.length && !bookingPagesIds.includes(event.bookingPageId)) {
        return false;
      }
      if (status && status !== Statuses.ALL) {
        if (
          (!!event.canceled?.isCanceled && status === Statuses.BOOKED) ||
          (!event.canceled?.isCanceled && status === Statuses.CANCELED)
        ) {
          return false;
        }
      }

      if (method === SortMethods.UPCOMING || method === SortMethods.PAST) {
        const today = dayjs().format('YYYY-MM-DD');
        return method === SortMethods.UPCOMING ? event.eventDate >= today : event.eventDate < today;
      } else if (dateRange && dateRange.length === 2) {
        return (
          event.eventDate >= dayjs(dateRange[0]).format('YYYY-MM-DD') &&
          event.eventDate <= dayjs(dateRange[1]).format('YYYY-MM-DD')
        );
      }
    })
);

const selectEventsDays = createSelector(selectFilteredEvents, (events) => {
  const daysList: string[] = [];
  events.forEach((event) => {
    if (!daysList.includes(event.eventDate)) daysList.push(event.eventDate);
  });

  return daysList.sort();
});

const selectEventsByDay = (day: string) =>
  createSelector(selectFilteredEvents, (events) =>
    events
      .filter((event) => event.eventDate === day)
      .sort((aEvent, bEvent) => (aEvent.startTime > bEvent.startTime ? 1 : -1))
  );

const selectSelectedEvent = createSelector(scheduledMeetingsState, (state) => state.selectedScheduledMeeting);
const selectPreviewBookingPage = createSelector(
  selectSelectedEvent,
  bookingPageSelectors.selectBookingPages,
  (event, bookingPages) => bookingPages.find((page) => page.id === event.bookingPageId)
);

const selectEventId = createSelector(selectSelectedEvent, (event) => event.eventId);
const selectEventCanceled = createSelector(selectSelectedEvent, (event) => event.canceled);
const selectEventCancelType = createSelector(selectEventCanceled, (canceled) => canceled?.type);
const selectEventCancelNote = createSelector(selectEventCanceled, (canceled) => canceled?.note);
const selectEventAttendeeName = createSelector(selectSelectedEvent, (event) => {
  const attendeeName = event.inputFields?.find((field) => field?.fieldType === CustomFieldType.NAME);
  return attendeeName?.value ? attendeeName.value : '';
});
const selectEventAttendeeEmail = createSelector(selectSelectedEvent, (event) => {
  const attendeeEmail = event.inputFields?.find((field) => field?.fieldType === CustomFieldType.EMAIL);
  return attendeeEmail?.value ? attendeeEmail.value : '';
});

const selectEventAttendeeInfo = createSelector(
  selectEventAttendeeEmail,
  selectEventAttendeeName,
  (email, name) => `${name} (${email})`
);

const selectHostId = createSelector(selectSelectedEvent, (event) => event.host);
const selectCoHostIds = createSelector(
  selectSelectedEvent,
  (event) => event.cohosts?.filter((cohost): cohost is string => !!cohost) || []
);

export const scheduledMeetingsSelectors = {
  selectEvents,
  selectIsFetching,
  selectError,
  selectSortMethod,
  selectDateRange,
  selectStatus,
  selectSelectedBookingPages,
  selectFilteredEvents,
  selectEventsDays,
  selectEventsByDay,
  selectSelectedEvent,
  selectPreviewBookingPage,
  selectEventId,
  selectEventCanceled,
  selectEventCancelType,
  selectEventCancelNote,
  selectEventAttendeeName,
  selectEventAttendeeEmail,
  selectEventAttendeeInfo,
  selectHostId,
  selectCoHostIds,
};
