export const Common = Object.freeze({
  ACCEPT_INVITE: `Aceitar convite`,
  ACCESS_RESTRICT: `Desculpe, você não está autorizado a acessar esta página`,
  ACTIVATE: `Ativar`,
  ALL: `Todos`,
  ARE_YOU_SURE: `Você tem certeza?`,
  AUTHORISE: `Autorizar`,
  AVAILABLE: `Disponível`,
  BACK: `Voltar`,
  CALENDAR_DAYS: `Dias de calendário`,
  CANCEL: `Cancelar`,
  CHOOSE_DATE: `Escolher data`,
  CLEAR: `Limpar`,
  CLONE: `Clonar`,
  CLOSE: `Fechar`,
  COMMON: `Comum`,
  COMPLETE: `Completo`,
  CONNECT: `Conectar`,
  CONNECTED: `Conectado`,
  CONFIRM_DELETE: `Confirmar exclusão`,
  COPY: `Copiar`,
  COPY_LINK: `Copiar link`,
  COPIED_LINK: `Copiado!`,
  COUNTINUE: `Continuar`,
  COUNTRY: `País`,
  CUSTOM: `Personalizado`,
  DATE: `Data`,
  DATE_RANGE: `Intervalo de datas`,
  DAYS: `dias`,
  DEACTIVATE: `Desativar`,
  DEFAULT: `Padrão`,
  DELETE: `Excluir`,
  DESCRIPTION: `Descrição`,
  DISABLE: `Desativar`,
  DISCONNECT: `Desconectar`,
  DOWNLOAD: `Baixar`,
  EDIT: `Editar`,
  EMAIL: `E-mail`,
  EMAIL_ALL_SMALL: `e-mail`,
  EMPTY_ERROR: `Não pode ficar vazio`,
  ENABLE: `Ativar`,
  ERROR: `Erro`,
  EXPORT: `Exportar`,
  FILTER: `Filtrar`,
  FRIDAYS: `Sextas-feiras`,
  FROM: `de`,
  GOOGLE: `Google`,
  HOURS: `horas`,
  HOUR_SHORTENED: `hrs`,
  INFO: `Informação`,
  INTERNAL: `Interno`,
  LEARN_MORE: `Saiba mais`,
  LOCKED: `Bloqueado`,
  LOGIN: `Login`,
  LOG_OUT: `Sair`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `mins`,
  MINUTES: `minutos`,
  MONDAYS: `Segundas-feiras`,
  MY_ACCOUNT: `Minha conta`,
  NAME_IS_DUPLICATE: `Ops! Este nome já foi usado. Por favor, insira um diferente.`,
  NEXT: `Próximo`,
  NONE: `Nenhum`,
  NO: `Não`,
  NO_RESULTS_FOUND: `Nenhum resultado encontrado`,
  NEVER: 'Nunca',
  OFF: `Desligado`,
  ON: `Ligado`,
  ONLY_ONE_ACCOUNT: `Você só pode ter uma conta SUMO1 por endereço de e-mail.`,
  PASSWORD: `Senha`,
  PHONE_NUMBER: `Número de telefone`,
  PICTURE_FORMATS: `SVG, JPG, GIF ou PNG. Tamanho máximo de 5 MB.`,
  PREVIEW: `Visualização`,
  QUICK_SETUP: `Configuração rápida`,
  REGISTER: `Registrar`,
  RESET: `Redefinir`,
  ROLES: `Funções`,
  SATURDAYS: `Sábados`,
  SAVE: `Salvar`,
  SAVE_CHANGES: `Salvar alterações`,
  SEARCH: `Buscar`,
  SECOND_SHORTENED: `seg`,
  SELECTED: `Selecionado`,
  SHARE: `Compartilhar`,
  SIGN_OUT: `Sair`,
  SIGN_UP: `Cadastrar-se`,
  SUCCESS: `Sucesso`,
  SUNDAYS: `Domingos`,
  TIMEZONE: `Fuso horário`,
  THURSDAYS: `Quintas-feiras`,
  TIMES: `Vezes`,
  TODAY: `Hoje`,
  TUESDAYS: `Terças-feiras`,
  UNAVAILABLE: `Indisponível`,
  UNLINK: `Desvincular`,
  UNSAVED_CHANGES: `Todas as alterações não salvas serão perdidas.`,
  UPLOAD: `Enviar`,
  UPLOAD_PICTURE: `Enviar imagem`,
  USER: `Usuário`,
  USERS: `Usuários`,
  VIEW: `Visualizar`,
  YES: `Sim`,
  YES_CANCEL: `Sim, Cancelar`,
  WARNING: `Aviso`,
  WEDNESDAYS: `Quartas-feiras`,
  WEEKDAYS: `Dias da semana`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Erro proibido`,
  TITLE_404: `404 - Página não encontrada`,
  TITLE_500: `500 - Erro interno do servidor`,
  MESSAGE_403_1: `Você não tem permissão para acessar `,
  MESSAGE_403_2: ` neste servidor.`,
  MESSAGE_404_1: `A URL solicitada `,
  MESSAGE_404_2: ` não foi encontrada neste servidor.`,
  MESSAGE_500_1: `Houve um erro. Isso é tudo o que sabemos. Por favor, aguarde e `,
  MESSAGE_500_2: `tente recarregar`,
  MESSAGE_500_3: ` novamente.`,
  HOME_BUTTON: `Início`,
});

export const PrivateRoute = Object.freeze({
  ACCOUNT: `Conta`,
  ACCOUNT_SETTINGS: `Configurações da conta`,
  PROFILE: `Perfil`,
  ADMIN_SETTINGS: `Configurações de administrador`,
  AVAILABILITY: `Disponibilidade`,
  CALENDAR_CONNECTIONS: `Conexões de calendário`,
  HELP_CENTER: `Central de Ajuda`,
  HOME: `Início`,
  USER_SETUP: `Configuração do usuário`,
  USER_SETTINGS: `Configurações do usuário`,
  INTEGRATION: `Integração`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Direitos autorais 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc. Todos os direitos reservados.`,
  PRIVACY_POLICY: `Política de Privacidade`,
  SECURITY_POLICY: `Política de Segurança`,
  TERMS: `Termos`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Contas`,
  ADMIN_SETTINGS_TITLE: `Configurações de admin`,
  ADMIN_TOOLS_TITLE: `Ferramentas de admin`,
  BILLING_TITLE: `Faturamento`,
  BOOKING_TOOLS_TITLE: `Ferramentas de reserva`,
  DASHBOARD_TITLE: `Painel`,
  MORE_TOOLS_TITLE: `Mais Ferramentas`,
  OPERATIONS_TITLE: `Operações`,
  ORGS_TITLE: `Organizações`,
  STAFF_TITLE: `Equipe`,
  SUMO1_FOR_GMAIL: `SUMO1 para Gmail`,
  SUPPORT_TITLE: `Suporte`,
  OPEN_MY_SUMO1: `Abrir Meu SUMO1`,
  OPEN_OPS_CONSOLE: `Abrir Console OPS`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Clique aqui para agendar uma chamada ou reunião virtual com um especialista.`,
  BOOK_MEETING_TITLE: `Agendar uma reunião`,
  CALL_DESCRIPTION: `Ligue para nós a qualquer momento de segunda a sexta, das 8h às 17h (EST).`,
  CALL_TITLE: `LIGAR PARA 800-708-1790`,
  CHAT_DESCRIPTION: `Clique no link "Chat conosco" no canto inferior direito para ver se estamos disponíveis.`,
  CHAT_TITLE: `Chat agora`,
  DESCRIPTION: `Você também pode entrar em contato com vendas ou suporte para obter assistência.`,
  EMAIL_DESCRIPTION: `Envie um e-mail para experts@sumoscheduler.com para obter respostas.`,
  EMAIL_TITLE: `Envie-nos um e-mail`,
  HEADER_TITLE: `Login falhou`,
  HELP_CENTER_DESCRIPTION: `Vídeos de ajuda, artigos de base de conhecimento e mais.`,
  HELP_CENTER_TITLE: `Centro de Ajuda & KBI`,
  LOG_CASE_DESCRIPTION: `Os clientes podem clicar aqui para registrar um caso com o suporte ao cliente.`,
  LOG_CASE_TITLE: `Registrar um caso`,
  MESSAGE: `Seu "superadministrador" pode fazer login para comprar licenças e retomar o uso do SUMO1.`,
  TITLE: `Licença expirada`,
  TITLE_TRIAL: `Período de avaliação gratuito expirado`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Cancelamentos`,
  MEETINGS_BOOKED: `Reuniões agendadas`,
  REMINDERS_SENT: `Lembretes enviados`,
  RESCHEDULES: `Reagendamentos`,
  TITLE: `Minhas estatísticas de agendamento`,
  INSTALL_CHROME_EXTENSION_PART1: `Instale a extensão do Chrome do SUMO1`,
  INSTALL_CHROME_EXTENSION_PART2: `para enviar links de convite por e-mail diretamente da sua caixa de entrada, calendário ou CRM.`,
  INVITE_PEOPLE_DESCRIPTION: `Adicione usuários ao SUMO1 para aproveitar a co-organização, reserva de equipe, reserva em nome de e muito mais.`,
  INVITE_PEOPLE_TITLE: `Convidar pessoas`,
  CALENDAR_WARNING_DESCRIPTION: `Seu calendário não está conectado. Você corre o risco de fazer uma dupla reserva se não conectar um calendário.`,
  CALENDAR_WARNING_BUTTON: `Conectar calendário`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Adicione um link "Agendar comigo" à sua assinatura de e-mail.`,
  STEP1_TITLE: `Configurar assinatura de e-mail`,
  STEP2_DESCRIPTION: `Envie um convite para uma reunião para alguém com a extensão do Chrome.`,
  STEP2_TITLE: `Enviar convite com um clique`,
  STEP3_DESCRIPTION: `O que mais eles deveriam testar desde o primeiro dia?`,
  STEP3_TITLE: `Testar outra funcionalidade`,
  TITLE: `Comece a agendar facilmente`,
});
