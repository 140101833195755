import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const CLONE_MODAL: ModalName = 'BookingPageCloneModal';
export const cloneModalActions = modalsActionsFactory(CLONE_MODAL);
export const cloneModalSelectors = modalsSelectorsFactory(CLONE_MODAL);

export const DELETE_BOOKING_PAGES_MODAL_NAME: ModalName = 'BookingPageDeleteModal';
export const deleteBookingPagesModalActions = modalsActionsFactory(DELETE_BOOKING_PAGES_MODAL_NAME);
export const deleteBookingPagesSelectors = modalsSelectorsFactory(DELETE_BOOKING_PAGES_MODAL_NAME);

export const PREVIEW_MODAL: ModalName = 'BookingPagePreviewModal';
export const previewModalActions = modalsActionsFactory(PREVIEW_MODAL);
export const previewModalSelectors = modalsSelectorsFactory(PREVIEW_MODAL);

export const ADD_TO_WEBSITE_MODAL: ModalName = 'BookingPageAddToWebsiteModal';
export const addToWebsiteModalActions = modalsActionsFactory(ADD_TO_WEBSITE_MODAL);
export const addToWebsiteModalSelectors = modalsSelectorsFactory(ADD_TO_WEBSITE_MODAL);

export const CONFIRM_OVERRIDE_MODAL: ModalName = 'BookingPageConfirmOverrideModal';
export const confirmOverrideModalActions = modalsActionsFactory(CONFIRM_OVERRIDE_MODAL);
export const confirmOverrideModalSelectors = modalsSelectorsFactory(CONFIRM_OVERRIDE_MODAL);