import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Path } from '../../../../routing';
import { Modal } from '../../../common';
import {
  AccountOption,
  MERGE_ACCOUNTS_MODAL_NAME,
  accountsActions,
  accountsSelectors,
  mergeAccountsModalActions,
} from '../../../../store/opsConsole/accounts';
import labels from './labels';

export const AccountsMergeModal = () => {
  const dispatch = useDispatch();
  const accountName = useSelector(accountsSelectors.selectSelectedAccountName);
  const accountsOptions = useSelector(accountsSelectors.selectFilteredMergeAccountsOptions);
  const selectedAccountsOptions = useSelector(accountsSelectors.selectSelectedMergeAccountsOptions);
  const isMergeButtonDisabled = useSelector(accountsSelectors.selectIsMergeButtonDisabled);

  const handleCancel = () => {
    dispatch(mergeAccountsModalActions.closeModal());
  };

  const handleMerge = () => {
    dispatch(accountsActions.mergeAccountsRequest());
  };

  const handleAccountsChange = (accounts: AccountOption[]) => {
    dispatch(accountsActions.setMergeIds(accounts.map((account) => account.id)));
  };

  const searchAccounts = (value: string) => {
    dispatch(accountsActions.setMergeSearchString(value));
  };

  const selectedItemTemplate = (item: AccountOption) => (
    <div>
      <Link
        className="text-color hover:underline hover:text-primary"
        target="_blank"
        to={Path.OPSConsoleAccount.replace(':accountId', item.id)}
      >
        {item.name}
      </Link>
    </div>
  );

  return (
    <Modal.Container name={MERGE_ACCOUNTS_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="max-w-30rem p-fluid">
        <div className="w-full py-2 px-3 uppercase sumo-header-bg-dark">{labels.rules}</div>

        <ul>
          <li>{labels.rule1}</li>
          <li>{labels.rule2}</li>
        </ul>

        <div className="my-3 text-2xl">
          {`${labels.mergeTitle1} "${accountName}" ${labels.mergeTitle2}`}
        </div>

        <AutoComplete
          field="name"
          dropdown
          multiple
          placeholder={labels.searchPlaceholder}
          value={selectedAccountsOptions}
          suggestions={accountsOptions}
          onChange={(e) => handleAccountsChange(e.value)}
          completeMethod={(e) => searchAccounts(e.query)}
          selectedItemTemplate={selectedItemTemplate}
        />

        <div className="flex align-items-center justify-content-center gap-2 mt-5 w-full py-2 px-3 sumo-header-bg-dark">
          <i className="pi pi-info-circle"></i>
          <div className="">{labels.note}</div>
        </div>
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined autoFocus />
          <Button label={labels.title} onClick={handleMerge} disabled={isMergeButtonDisabled} />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
