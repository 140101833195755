export const DefaultBookingTemplate = Object.freeze({
  NAME: `Reunión de 30 Minutos`,
  CONFIRMATION_SUBJECT: 'Nueva reunión: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Reprogramado: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Recordatorio: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Cancelado: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Seguimiento: <Meeting Name> con <Host Name>',
  CONFIRMATION_BODY: `Hola <Invitee Name>,

Su nueva reunión <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date> está programada.

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Hola <Invitee Name>,

Su reunión <Meeting Name> con <Host Name> ha sido reprogramada.

<b>Hora y Fecha actualizadas</b>
<Meeting Time>, <Meeting Date>

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Hola <Invitee Name>,

Este es un recordatorio amistoso de que su <Meeting Name> con <Host Name> es a las <Meeting Time>, <Meeting Date>.

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Hola <Invitee Name>,

Su reunión <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date> ha sido cancelada.`,
  FOLLOW_UP_BODY: `Hola <Invitee Name>,

Esperamos que su reunión "<Meeting Name>" con <Host Name> haya sido productiva.

Si tiene algún comentario o necesita más asistencia, no dude en comunicarse con nosotros.`,
  SMS_TEXT:
    'Este es un recordatorio amistoso de que su <Meeting Name> con <Host Name> es a las <Meeting Time>, <Meeting Date>. Ver o editar reunión <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `Nueva plantilla`,
  TITLE: `Plantillas de reserva`,
  VIEW_DETAILS: `Ver Detalles`,
  MINUTE: `min`,
  HOUR: `h`,
  CLONE_MODAL_TITLE: `Clonar plantilla de reserva`,
  DELETE_MODAL_CONFIRM_TEXT: `¿Está seguro de que desea eliminar esta plantilla de reserva?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `¿Está seguro de que desea eliminar estas plantillas de reserva?`,
  DELETE_MODAL_USED_PAGES_TEXT: `Primero debe eliminar estas plantillas de reserva de las siguientes páginas de reserva:`,
  FILTER_MY_TEMPLATES: `Mis plantillas`,
  FILTER_ALL_TEMPLATES: `Todas las plantillas`,
  TOOLTIP: `Crear plantillas de reserva para páginas de reserva.`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Error al clonar la plantilla de reserva`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Plantilla de reserva clonada exitosamente`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Error al eliminar la plantilla de reserva`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Plantilla de reserva eliminada correctamente`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `No se pudo obtener la plantilla de reserva`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Error al guardar la plantilla de reserva`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de reserva guardada correctamente`,
});

export const EditBookingTemplate = Object.freeze({
  ADDITIONAL_TITLE: `Opciones Adicionales`,
  WHAT_TITLE_PART_1: `¿Qué`,
  WHAT_TITLE_PART_2: ` evento es este?`,
  WHO_TITLE_PART_1: `¿Quién`,
  WHO_TITLE_PART_2: `puede asistir?`,
  WHERE_TITLE_PART_1: `¿Dónde`,
  WHERE_TITLE_PART_2: ` tendrá lugar este evento?`,
  WHEN_TITLE_PART_1: `¿Cuándo`,
  WHEN_TITLE_PART_2: ` pueden las personas reservar este evento?`,
  INVITEE_TITLE_PART_1: `Preguntas para el`,
  INVITEE_TITLE_PART_2: ` invitado`,
  INVITEE_SUBTITLE: `Nombre, Correo Electrónico, Preguntas Personalizadas`,
  NOTIFICATIONS_TITLE_PART_1: `Notificaciones`,
  NOTIFICATIONS_TITLE_PART_2: ``,
  CONFIRMATION_TITLE_PART_1: `Página de`,
  CONFIRMATION_TITLE_PART_2: ` Confirmación`, // y Política de Cancelación
  LOCKED_TOOLTIP: `Registro bloqueado. No tiene permisos para editar. Última modificación por:`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  TEAMS: `Equipos potenciales`,
  HOSTS: `Anfitriones`,
  HOSTS_TOOLTIP: `Seleccione uno o varios hosts para esta plantilla de reserva. Si selecciona varios anfitriones, se agregarán a la reunión reservada.`,
  RESCHEDULE_WITH: `Reprogramar con`,
  RESCHEDULE_WITH_TOOLTIP: `Si el invitado reprograma la reunión, ¿con quién debería reprogramarlos SUMO?`,
  ASSIGNED_HOST: `Anfitrión Asignado`,
  ROUND_ROBIN_TO_NEW_HOST: `Round-Robin a un Nuevo Anfitrión`,
  POTENTIAL_HOSTS: `Anfitriones Potenciales`,
  HOST_VIDEO_CONFERENCE: `Videoconferencia del Anfitrión`,
  HOST_LIST_REQUIREMENTS: `Debería estar en la lista de anfitriones, no tienes permiso para Todas las Páginas de Reserva.`,
  HOSTS_LABEL: `Elija una persona, varias personas o un equipo (donde un miembro será seleccionado a través de round-robin) para asistir a esta reunión.`,
  HOSTS_PLACEHOLDER: `Buscar anfitriones o equipos`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Selecciona una o más opciones para presentar al invitado al programar ellos mismos en la página de reservas:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Omitir el paso "Dónde" para reuniones virtuales`,
  SKIP_WHERE_STEP_DESCRIPTION: `El enlace de la videoconferencia se agregará automáticamente a la reunión.`,
  PHONE_CALL: `Llamada telefónica`,
  HOSTS_PHONE: `Número de teléfono del anfitrión`,
  HOSTS_PHONE_DESCRIPTION: `El número de teléfono del anfitrión se agregará automáticamente a la reunión.`,
  HOSTS_PHONE_NOTIFICATION: `Uno o más anfitriones no tienen número de teléfono`,
  INVITEE_PHONE: `Pedir al invitado que ingrese su número de teléfono`,
  INVITEE_PHONE_DESCRIPTION: `Se le pedirá al invitado que agregue su número de teléfono.`,
  CUSTOM_PHONE: `Número de teléfono personalizado`,
  CUSTOM_PHONE_PLACEHOLDER: `Ingresar número de teléfono`,
  CUSTOM_PHONE_DESCRIPTION: `Este número de teléfono se agregará automáticamente a la reunión.`,
  IN_PERSON: `En persona`,
  CUSTOM_ADDRESS: `Dirección personalizada`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Ingresar dirección de la reunión`,
  CUSTOM_ADDRESS_DESCRIPTION: `Esta dirección se agregará automáticamente a la reunión.`,
  LOCATION: `Ubicación de la empresa`,
  LOCATION_DESCRIPTION: `Se le pedirá al invitado que elija una de estas ubicaciones.`,
  INVITEE_LOCATION: `Ubicación del invitado`,
  INVITEE_LOCATION_DESCRIPTION: `Se le pedirá al invitado que agregue una dirección de reunión.`,
  DEFAULT_LOCATION: 'Ubicación predeterminada',
  VIDEO_CONFERENCE: `Videoconferencia`,
  VIDEO_CONFERENCE_NOTIFICATION: `Uno o más anfitriones no tienen ninguna videoconferencia`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Nombre completo`,
  EMAIL_LABEL: `Correo Electrónico`,
  ADD_QUESTION_BUTTON: `Agregar Nueva Pregunta`,
  QUESTION_TITLE_NEW: `Nueva Pregunta`,
  QUESTION_TITLE_EDIT: `Editar Pregunta`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  DESCRIPTION: `Agrega una pregunta que se mostrará en la Página de Reservas para que el invitado la complete al reservar la reunión.`,
  QUESTION_LABEL: `Pregunta`,
  REQUIRED_LABEL: `Requerido`,
  ACTIVE_LABEL: `Activo`,
  TYPE_LABEL: `Tipo de Respuesta`,
  TYPE_CHECKBOXES: `Casillas de Verificación`,
  TYPE_RADIO_BUTTONS: `Botones de Opción`,
  TYPE_SELECT: `Menú Desplegable`,
  TYPE_TEL: `Número de Teléfono`,
  TYPE_TEXT: `Línea Única`,
  TYPE_TEXTAREA: `Múltiples Líneas`,
  ANSWERS_LABEL: `Respuestas`,
  ANSWERS_SINGLE_DESCRIPTION_1: `El invitado puede seleccionar `,
  ANSWERS_SINGLE_DESCRIPTION_2: `una`,
  ANSWERS_SINGLE_DESCRIPTION_3: ` de las siguientes`,
  ANSWERS_MULTIPLE_DESCRIPTION_1: `El invitado puede seleccionar una o `,
  ANSWERS_MULTIPLE_DESCRIPTION_2: `muchas`,
  ANSWERS_MULTIPLE_DESCRIPTION_3: ` de las siguientes`,
  ANSWER_PLACEHOLDER: `Respuesta`,
  ADD_ANSWER_BUTTON: `Agregar Respuesta`,
  DELETE_BUTTON: `Eliminar`,
  CANCEL_BUTTON: `Cancelar`,
  SAVE_BUTTON: `Guardar`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `Confirmaciones por Correo Electrónico`,
  CONFIRMATION_DESCRIPTION: `Siempre se creará una reunión en el calendario del anfitrión en SUMO1, y el invitado se agregará como asistente.
Al activar esta función, SUMO1 también enviará una confirmación por correo electrónico.`,
  RESCHEDULE_TITLE: `Reprogramación de correo electrónico`,
  RESCHEDULE_DESCRIPTION: `El invitado recibirá una notificación por correo electrónico de cualquier cambio en su reunión.`,
  REMINDER_TITLE: `Recordatorios por Correo Electrónico`,
  REMINDER_DESCRIPTION: `El invitado recibirá un recordatorio por correo electrónico antes de la reunión programada.`,
  CANCELATION_TITLE: `Cancelaciones por Correo Electrónico`,
  CANCELATION_DESCRIPTION: `Se enviará una notificación por correo electrónico a su invitado si cancela la reunión.`,
  FOLLOW_UP_TITLE: `Seguimiento por Correo Electrónico`,
  FOLLOW_UP_DESCRIPTION: `Automatiza el seguimiento de los próximos pasos con un correo electrónico enviado después de la reunión.`,
  SMS_TITLE: `Recordatorio por SMS`,
  SMS_DESCRIPTION: `El invitado tendrá la opción de recibir recordatorios por SMS antes de una reunión programada.`,
  SMS_BODY: `SMS Cuerpo del mensaje`,
  SMS_LABEL_PART1: `Sólo EE.UU. y Canadá`,
  SMS_LABEL_PART2: `Actualmente, los recordatorios por mensaje de texto son gratuitos y solo están disponibles para números de teléfono de invitados en EE. UU. y Canadá.`,
  PERSONALIZE: `Personalizar`,
  EMAIL_SUBJECT: `Asunto del Correo Electrónico`,
  EMAIL_BODY: `Cuerpo del Correo Electrónico`,
  MERGE_VARIABLES: `Variables de Combinación`,
  TIMING: `Temporización`,
  RESET: `Restablecer`,
  ADD_ANOTHER_REMINDER: `Agregar Otro Recordatorio`,
  CANCELATION_POLICY: `Política de Cancelación`,
  CANCELATION_POLICY_TOOLTIP: `Cualquier texto que ingreses aquí aparecerá en todas las notificaciones por correo electrónico enviadas al invitado.`,
  TIME_UNIT_DAYS_BEFORE: `día(s) antes`,
  TIME_UNIT_HOURS_BEFORE: `hora(s) antes`,
  TIME_UNIT_MINUTES_BEFORE: `minuto(s) antes`,
  TIME_UNIT_DAYS_AFTER: `día(s) después`,
  TIME_UNIT_HOURS_AFTER: `hora(s) después`,
  TIME_UNIT_MINUTES_AFTER: `minuto(s) después`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `Después de Reservar`,
  TYPE_DISPLAY_SUMO: `Mostrar Página de Confirmación SUMO`,
  TYPE_DISPLAY_EXTERNAL: `Redirigir a una Página Externa`,
  EXTERNAL_LINK_LABEL: `URL de Redirección Externa`,
  EXTERNAL_LINK_PLACEHOLDER: `https://redireccion-aqui.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Mostrar botón "Reservar Otra Reunión"`,
  ALLOW_RESCHEDULE_LABEL: `Permitir Reprogramación`,
  ALLOW_CANCEL_LABEL: `Permitir Cancelación`,
  ADD_CUSTOM_LINK_LABEL: `Agregar Enlace Personalizado`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Agrega un enlace personalizado a la Página de Confirmación, como un enlace a una encuesta.`,
  ADD_CUSTOM_LINK_TITLE: `Agregar Enlace Personalizado`,
  EDIT_CUSTOM_LINK_TITLE: `Editar Enlace Personalizado`,
  CUSTOM_LINK_LABEL: `Rótulo del Enlace Personalizado`,
  CUSTOM_LINK_PLACEHOLDER: `Ejemplo de Enlace`,
  LINK_URL_LABEL: `URL del Enlace`,
  LINK_URL_PLACEHOLDER: `https://ejemplo-enlace.com`,
});
