import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Calendar, CalendarChangeEvent, CalendarMonthChangeEvent, CalendarViewChangeEvent } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Label, TimeZoneSelector } from '../../common';
import { eventSelectors, eventActions } from '../../../store/publicBookingPage';
import labels from './labels';
import i18n from '../../../i18n/i18n';
import { Nullable } from 'primereact/ts-helpers';
import { DateRangeType } from '../../../API';

export const PublicBookingPageDateTime = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(eventSelectors.selectIsMobile);
  const isPreviewMode = useSelector(eventSelectors.selectIsPreviewMode);
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const dateLabel = useSelector(eventSelectors.selectDateLabel);
  const timeLabel = useSelector(eventSelectors.selectTimeLabel);
  const viewDate = useSelector(eventSelectors.selectViewDate);
  const eventDate = useSelector(eventSelectors.selectEventDate);
  const startTime = useSelector(eventSelectors.selectStartTime);
  const timeZone = useSelector(eventSelectors.selectTimeZone);
  const timeZoneOptions = useSelector(eventSelectors.selectTimeZoneOptionsList);
  const isHostReschedule = useSelector(eventSelectors.selectIsHostReschedule);
  const availableTimes = useSelector(eventSelectors.selectAvailableTimes);
  const disabledDates = useSelector(eventSelectors.selectDisabledDates);
  // const availableTimesByDate = useSelector(eventSelectors.selectAvailableTimesByDate);
  const is12Hour = useSelector(eventSelectors.selectIs12Hour);
  const subHeaderStyle = useSelector(eventSelectors.selectSubHeaderStyle);
  const buttonStyle = useSelector(eventSelectors.selectButtonStyle);
  const outlinedButtonStyle = useSelector(eventSelectors.selectOutlinedButtonStyle);
  const dateRange = useSelector(eventSelectors.selectDateRange);

  const minDate = new Date(); // today
  const [maxDate, setMaxDate] = useState(new Date(new Date().setFullYear(minDate.getFullYear() + 1))); // after 1 year from today
  const [isDateSelected, setIsDateSelected] = useState<boolean>(Boolean(eventDate) && Boolean(startTime));
  const [calendarViewDate, setCalendarViewDate] = useState(viewDate);

  const handleEditDate = () => {
    setIsDateSelected(false);
  };

  const handleDateChange = (date: Nullable<string | Date | Date[]>) => {
    setIsDateSelected(true);
    // TODO: remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch<any>(eventActions.setEventDateThunk(date ? dayjs(date.toString()).format('YYYY-MM-DD') : ''));
  };

  // // select the first available date in current month
  // useEffect(() => {
  //   if (Object.keys(availableTimesByDate).length > 0) {
  //     const firstDateKey = Object.keys(availableTimesByDate)[0];
  //     const firstDateValue = availableTimesByDate[firstDateKey][0];
  //     const date = new Date(firstDateValue);
  //     handleDateChange(date);
  //     dispatch(eventActions.setViewDate(date.valueOf()));
  //   }
  // }, [availableTimesByDate]);

  useEffect(() => {
    if (dateRange) {
      switch (dateRange.type) {
        case DateRangeType.DAYS_IN_FUTURE:
          if (dateRange.count) {
            const futureDate = new Date();
            futureDate.setDate(minDate.getDate() + dateRange.count);
            setMaxDate(futureDate);
          }
          break;
        case DateRangeType.SPECIFIC_DATES:
          if (dateRange.to) {
            const specificDateTo = new Date(dateRange.to);
            setMaxDate(specificDateTo);
          }
          break;
        case DateRangeType.UNLIMITED:
        default:
          setMaxDate(new Date(new Date().setFullYear(minDate.getFullYear() + 1)));
      }
    }
  }, [dateRange]);

  const handleMonthChange = (e: CalendarMonthChangeEvent | CalendarViewChangeEvent) => {
    const getDateFromEvent = (event: CalendarMonthChangeEvent | CalendarViewChangeEvent): Date => {
      if ('month' in event && 'year' in event) {
        return new Date(event.year, event.month - 1, 1);
      } else if ('value' in event) {
        return event.value;
      }
      return new Date();
    };

    const adjustDateWithinRange = (date: Date): Date => {
      if (date < minDate) {
        return minDate;
      } else if (date > maxDate) {
        return maxDate;
      }
      return date;
    };

    const viewMonthDate = adjustDateWithinRange(getDateFromEvent(e));
    if (
      calendarViewDate.getMonth() === viewMonthDate.getMonth() &&
      calendarViewDate.getFullYear() !== viewMonthDate.getFullYear()
    ) {
      setCalendarViewDate(viewMonthDate);
      return; // disable updates on year switch
    }

    setCalendarViewDate(viewMonthDate);
    handleDateChange(viewMonthDate);
    handleMonthUpdate(viewMonthDate);
  };

  const handleMonthUpdate = (date: Date) => {
    if (isPreviewMode) {
      return;
    }

    // ensure targetDate is within the allowed date range and is different from the current viewDate
    if (date >= minDate && date <= maxDate && viewDate.valueOf() !== date.valueOf()) {
      dispatch(eventActions.setViewDate(date.valueOf()));
      dispatch(eventActions.getAgendaRequest());
    }
  };

  const handleTimeButtonClick = (time: Date) => {
    // TODO: remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch<any>(eventActions.setEventStartTimeThunk(time));
  };

  const handleTimeZoneChange = (timeZone: string) => {
    dispatch(eventActions.updateEvent({ timeZone }));
  };

  const handleDateLabelChange = (dateLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ dateLabel }));
  };

  const handleTimeLabelChange = (timeLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ timeLabel }));
  };

  const handleViewNextMonth = () => {
    const currentYear = calendarViewDate.getFullYear();
    const currentMonth = calendarViewDate.getMonth();
    handleMonthChange({
      month: (currentMonth + 1) % 12 + 1,
      year: currentMonth === 11 ? currentYear + 1 : currentYear,
    });
  };
  
  const renderSubHeaderLabel = (value: string, onChange: (value: string) => void) => (
    <Label
      editClassName="hover:text-primary"
      textClassName="text-xl font-semibold"
      textStyle={subHeaderStyle}
      editable={isEditable}
      value={value}
      onBlurUpdate={onChange}
    />
  );

  const renderCalendar = () => {
    return (
      <Calendar
        inline
        className="w-12"
        panelClassName="border-0 p-0"
        locale={i18n.language}
        value={eventDate}
        viewDate={viewDate}
        disabledDates={disabledDates}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(e: CalendarChangeEvent) => handleDateChange(e.value)}
        onMonthChange={handleMonthChange}
        onViewDateChange={handleMonthChange}
      />
    );
  };

  const renderTimeSlots = () => (
    <div className="flex flex-column h-full p-fluid">
      <div className="flex-1 p-3 overflow-y-auto">
        <div className="grid -mb-2">
          {availableTimes.length ? (
            availableTimes.map((time, index) => (
              <div key={index} className="col-12">
                <Button
                  style={time.valueOf() !== startTime?.valueOf() ? outlinedButtonStyle : buttonStyle}
                  className="justify-content-center font-bold"
                  outlined={time.valueOf() !== startTime?.valueOf()}
                  onClick={() => handleTimeButtonClick(time)}
                >
                  {time.toLocaleTimeString('en-US', {
                    timeZone,
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: is12Hour,
                  })}
                </Button>
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="w-full flex justify-content-center mb-3">{labels.slotsNoFound}</div>
              <Button
                className="justify-content-center font-bold"
                outlined
                style={outlinedButtonStyle}
                label={labels.viewNextMonth}
                onClick={handleViewNextMonth}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex align-items-center border-top-1 surface-border font-bold px-3 pt-2 -mb-1">
        <i className="pi pi-globe mr-2"></i>
        <span>{labels.timeZone}</span>
      </div>
      <TimeZoneSelector
        selectedMode="short"
        options={timeZoneOptions}
        onChange={handleTimeZoneChange}
        value={timeZone}
        disabled={isHostReschedule}
        className="px-2 border-none shadow-none"
      />
    </div>
  );

  return isMobile ? (
    <>
      <div className="pl-3 ml-1 -mr-1 mt-3">{renderSubHeaderLabel(dateLabel, handleDateLabelChange)}</div>
      <div className="card mb-0 border-1 surface-border shadow-none p-0">
        {isDateSelected ? (
          <div className="flex justify-content-between align-items-center px-3 py-2">
            <div>{dayjs(eventDate).format('dddd, MMMM D, YYYY')}</div>
            <div>
              <Button style={outlinedButtonStyle} label={labels.edit} outlined onClick={handleEditDate} />
            </div>
          </div>
        ) : (
          renderCalendar()
        )}
      </div>
      {isDateSelected && (
        <>
          <div className="pl-3 mt-3">{renderSubHeaderLabel(timeLabel, handleTimeLabelChange)}</div>
          <div className="card mb-0 border-1 surface-border shadow-none p-0 h-30rem">{renderTimeSlots()}</div>
        </>
      )}
    </>
  ) : (
    <>
      <div className="flex mt-3">
        <div className="w-7 pl-3 ml-1 -mr-1">{renderSubHeaderLabel(dateLabel, handleDateLabelChange)}</div>
        <div className="w-5 pl-3">{renderSubHeaderLabel(timeLabel, handleTimeLabelChange)}</div>
      </div>
      <div className="card mt-1 mb-0 border-1 surface-border shadow-none p-0 overflow-hidden flex h-29rem">
        <div className="w-7 border-right-1 surface-border">{renderCalendar()}</div>
        <div className="w-5 h-full">{renderTimeSlots()}</div>
      </div>
    </>
  );
};
