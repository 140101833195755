import { SelectButton } from 'primereact/selectbutton';
import labels from './labels';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import {
  ORGS_CSV_HEADERS,
  ORG_STATUS_OPTIONS,
  ORG_STATUS_VALUES,
  ORG_TYPE_OPTIONS,
  TERM_FILTER_OPTIONS,
  TERM_FILTER_VALUES,
  orgsActions,
  orgsSelectors,
} from '../../../../store/opsConsole/orgs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { DateFormat } from '../../../../store/userSettings';
import { formatDateUTC } from '../../../../services/DateService';
import { downloadCSV } from '../../../../services/utils';

export const OrgsFilters = () => {
  const dispatch = useDispatch();
  const orgsRecords = useSelector(orgsSelectors.selectOrgs);
  const status = useSelector(orgsSelectors.selectFilterStatus);
  const term = useSelector(orgsSelectors.selectFilterTerm);

  const handleStatusChange = (status: ORG_STATUS_VALUES) => {
    dispatch(orgsActions.changeFilter({ status }));
  };

  const handleTermChange = (term: TERM_FILTER_VALUES) => {
    dispatch(orgsActions.changeFilter({ term }));
  };

  // utility to Convert Orgs Array to CSV
  const convertArrayToCSV = (): string => {
    const rows = orgsRecords.map((org) =>
      [
        org.name,
        org.tenantId,
        ORG_STATUS_OPTIONS.find((status) => status.value === org.status)?.label,
        ORG_TYPE_OPTIONS.find((type) => type.value === org.type)?.label,
        TERM_FILTER_OPTIONS.find((term) => term.value === org.term)?.label,
        org.amount,
        org.amountPerUser,
        org.owned,
        org.assigned,
        org.percentOfWeekAdoption,
        org.percentOfMonthAdoption,
        org.startDate,
        org.endDate,
        org.daysLeft,
        org.bookedMeetings,
        org.note,
        formatDateUTC(org.updatedAt, DateFormat.default),
      ].join(',')
    );

    return [ORGS_CSV_HEADERS.join(',').toUpperCase(), ...rows].join('\n');
  };

  return (
    <div className="flex justify-content-between flex-wrap mb-2">
      <div className="flex flex-wrap">
        <div className="flex flex-column mr-5 mb-3 sm:mb-0">
          <label htmlFor="term">{labels.term}</label>
          <SelectButton
            value={term}
            onChange={(e) => handleTermChange(e.value)}
            options={TERM_FILTER_OPTIONS}
            id="term"
          />
        </div>
        <div className="flex flex-column mb-3 sm:mb-0">
          <label htmlFor="status">{labels.status}</label>
          <Dropdown
            inputId="status"
            options={ORG_STATUS_OPTIONS}
            value={status}
            onChange={(e) => handleStatusChange(e.value)}
            className="w-10rem"
          />
        </div>
      </div>
      <div className="flex align-items-end">
        <Button
          icon="pi pi-cloud-download"
          label={labels.export}
          text
          onClick={() => downloadCSV(convertArrayToCSV(), 'orgs.csv')}
        />
      </div>
    </div>
  );
};
