import { t } from '../../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  customName: t('BookingPageWhatStep:CUSTOM_NAME_LABEL'),
  customNamePlaceholder: t('BookingPageWhatStep:CUSTOM_NAME_PLACEHOLDER'),
  description: t('BookingPagesOverrideModal:DESCRIPTION'),
  duplicate: t('Common:NAME_IS_DUPLICATE'),
  save: t('Common:SAVE'),
  title: t('BookingPagesOverrideModal:TITLE'),
};
