import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { notificationsActions } from '../notifications';
import { billingActions, billingActionTypes } from './actions';
import { billingSelectors } from './selectors';
import { getSubscriptionDetails, changeSeatsOrTerm, deleteScheduledChange } from './service';
import { ChargeeBeeResponse, Subscription, updateSubscriptionRequest } from './types';
import {
  ADD_SEATS_ERROR_TOAST,
  CHANGE_TERM_ERROR_TOAST,
  CHANGE_TERM_SUCCESS_TOAST,
  DELETE_CHANGE_ERROR_TOAST,
  DELETE_CHANGE_SUCCESS_TOAST,
  GET_BILLING_ERROR_TOAST,
  REMOVE_SEATS_ERROR_TOAST,
  getAddSeatsSuccessToast,
  getRemoveSeatsSuccessToast,
} from './constants';
import { changeSeatsModalActions, changeTermModalActions } from './modal';
import { userSettingsSelectors } from '../userSettings';
import { handleServiceError } from '../utils/reduxUtils';

const selectChangeSeatsRequest = createSelector(
  userSettingsSelectors.selectFullName,
  billingSelectors.selectChangeSeats,
  billingSelectors.selectIsAddOrRemoveSeats,
  (userName, seats, addOrRemove) =>
    ({
      ...(addOrRemove
        ? { addSeats: seats }
        : {
            scheduledChange: {
              seats,
              changeBy: userName,
              requestDate: new Date().toISOString(),
            },
          }),
    } as updateSubscriptionRequest)
);

const selectChangeTermRequest = createSelector(
  userSettingsSelectors.selectFullName,
  billingSelectors.selectTerm,
  (userName, plan) =>
    ({
      scheduledChange: {
        plan,
        changeBy: userName,
        requestDate: new Date().toISOString(),
      },
    } as updateSubscriptionRequest)
);

function* getBillingSaga(): any {
  try {
    const response: Subscription = yield call(getSubscriptionDetails);

    yield put(billingActions.getBillingSuccess(response));
  } catch (error: unknown) {
    yield put(billingActions.getBillingFail(error?.toString() || ''));
    yield call(handleServiceError, error, GET_BILLING_ERROR_TOAST, true);
  }
}

function* changeSeatsSaga(): any {
  try {
    const addOrRemoveSeats: number = yield select(billingSelectors.selectIsAddOrRemoveSeats);
    const seats: number = yield select(billingSelectors.selectChangeSeats);
    const request: updateSubscriptionRequest = yield select(selectChangeSeatsRequest);

    const response: ChargeeBeeResponse = yield call(changeSeatsOrTerm, request);

    yield put(billingActions.changeSeatsSuccess(response));
    yield put(changeSeatsModalActions.closeModal());
    yield put(billingActions.getBillingRequest());
    yield put<any>(
      notificationsActions.showToast(
        addOrRemoveSeats ? getAddSeatsSuccessToast(seats) : getRemoveSeatsSuccessToast(seats)
      )
    );
  } catch (error: unknown) {
    yield put(billingActions.changeSeatsFail(error?.toString() || ''));
    const addOrRemoveSeats: number = yield select(billingSelectors.selectIsAddOrRemoveSeats);
    yield call(handleServiceError, error, addOrRemoveSeats ? ADD_SEATS_ERROR_TOAST : REMOVE_SEATS_ERROR_TOAST);
  }
}

function* changeTermSaga(): any {
  try {
    const request: updateSubscriptionRequest = yield select(selectChangeTermRequest);

    const response: ChargeeBeeResponse = yield call(changeSeatsOrTerm, request);

    yield put(billingActions.changeTermSuccess(response));
    yield put(changeTermModalActions.closeModal());
    yield put(billingActions.getBillingRequest());
    yield put<any>(notificationsActions.showToast(CHANGE_TERM_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(billingActions.changeSeatsFail(error?.toString() || ''));
    yield call(handleServiceError, error, CHANGE_TERM_ERROR_TOAST);
  }
}

function* deleteScheduledChangeSaga(action: ReturnType<typeof billingActions.deleteScheduledChangeRequest>): any {
  try {
    if (action.type === billingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST) {
      yield call(deleteScheduledChange, action.payload);

      yield put(billingActions.deleteScheduledChangeSuccess());
      yield put(billingActions.getBillingRequest());
      yield put<any>(notificationsActions.showToast(DELETE_CHANGE_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(billingActions.deleteScheduledChangeFail(error?.toString() || ''));
    yield put(billingActions.getBillingRequest()); // TODO: delete when deleteScheduledChange is fixed
    yield call(handleServiceError, error, DELETE_CHANGE_ERROR_TOAST);
  }
}

export function* watchBillingSaga() {
  yield takeLatest(billingActionTypes.GET_BILLING_REQUEST, getBillingSaga);
  yield takeLatest(billingActionTypes.CHANGE_SEATS_REQUEST, changeSeatsSaga);
  yield takeLatest(billingActionTypes.CHANGE_TERM_REQUEST, changeTermSaga);
  yield takeLatest(billingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST, deleteScheduledChangeSaga);
}
