import { t } from '../../i18n/i18n';

export default {
  bookMeetingDesc: t('LicenseExpired:BOOK_MEETING_DESCRIPTION'),
  bookMeetingTitle: t('LicenseExpired:BOOK_MEETING_TITLE'),
  callDesc: t('LicenseExpired:CALL_DESCRIPTION'),
  callTitle: t('LicenseExpired:CALL_TITLE'),
  chatDesc: t('LicenseExpired:CHAT_DESCRIPTION'),
  chatTitle: t('LicenseExpired:CHAT_TITLE'),
  desc: t('LicenseExpired:DESCRIPTION'),
  emailDesc: t('LicenseExpired:EMAIL_DESCRIPTION'),
  emailTitle: t('LicenseExpired:EMAIL_TITLE'),
  helpCenterDesc: t('LicenseExpired:HELP_CENTER_DESCRIPTION'),
  helpCenterTitle: t('LicenseExpired:HELP_CENTER_TITLE'),
  login: t('LicenseExpired:HEADER_TITLE'),
  logCaseDesc: t('LicenseExpired:LOG_CASE_DESCRIPTION'),
  logCaseTitle: t('LicenseExpired:LOG_CASE_TITLE'),
  title: t('LicenseExpired:TITLE'),
  titleTrial: t('LicenseExpired:TITLE_TRIAL'),
  message: t('LicenseExpired:MESSAGE'),
};
