import { t } from '../../../../i18n/i18n';

export default {
  yearly: t('Billing:ANNUAL_TITLE'),
  buyLicenses: t('License:BUY_LICENSES'),
  buyLicensesMessage: t('Billing:BUY_LICENSES_MESSAGE'),
  cancel: t('Common:CANCEL'),
  enterNumber: t('Billing:LICENSES_PLACEHOLDER'),
  licenses: t('Billing:LICENSES_TITLE'),
  licenseMessagePart1: t('Billing:LICENSE_MESSAGE_PART_1'),
  licenseMessagePart2: t('Billing:LICENSE_MESSAGE_PART_2'),
  licenseMessagePart3: t('Billing:LICENSE_MESSAGE_PART_3'),
  numberLicenses: t('Billing:NUMBER_OF_LICENSES'),
  next: t('Common:NEXT'),
  month: t('Billing:MONTH_TITLE'),
  monthly: t('Billing:MONTHLY_TITLE'),
  billingEmail: t('OPSConsoleOrgs:BILLING_EMAIL'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
};
