export enum DateFormat {
  DDMMYYYY = 'DD/MM/YYYY',
  default = 'MM/DD/YYYY',
  YYYYMMDD = 'YYYY/MM/DD',
}

export enum LinkStatus {
  BUSY = 'BUSY',
  FREE = 'FREE',
}

export enum TimeFormat {
  default = '12h',
  military = '24h',
}

export type UserDetails = {
  loggedRole: string;
  workspaceIds: string[];
  isFirstTenantUser: boolean;
};

export type ConncetToZoom = {
  code: string,
  redirectURI: string
}
