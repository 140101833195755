import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { NavigationKey } from './typings';

const navigationsState = (state: State) => state.navigations;

const selectSteps = (key: NavigationKey) =>
  createSelector(navigationsState, (navigations) => navigations[key]?.steps || []);

const selectCurrentStepIndex = (key: NavigationKey) =>
  createSelector(navigationsState, (navigations) => navigations[key]?.currentStep);

const selectCurrentStep = (key: NavigationKey) =>
  createSelector(selectSteps(key), selectCurrentStepIndex(key), (steps, index) => steps[index]);

const selectCurrentStepName = (key: NavigationKey) => createSelector(selectCurrentStep(key), (step) => step?.name);

const selectCurrentProgressValue = (key: NavigationKey) =>
  createSelector(
    selectSteps(key),
    selectCurrentStepIndex(key),
    (steps, currentStepIndex) => ((currentStepIndex + 1) / steps.length) * 100 || 0
  );

const selectIsLastStep = (key: NavigationKey) =>
  createSelector(
    selectSteps(key),
    selectCurrentStepIndex(key),
    (steps, currentStepIndex) => steps.length === currentStepIndex + 1
  );

const selectNavigationDisabled = (key: NavigationKey) =>
  createSelector(navigationsState, (navigations) => navigations[key]?.navigationDisabled || false);

export const navigationsSelectors = {
  selectSteps,
  selectCurrentStepIndex,
  selectCurrentStepName,
};

export const navigationsSelectorsFactory = (key: NavigationKey) => ({
  selectSteps: selectSteps(key),
  selectCurrentStepIndex: selectCurrentStepIndex(key),
  selectCurrentStepName: selectCurrentStepName(key),
  selectCurrentProgressValue: selectCurrentProgressValue(key),
  selectIsLastStep: selectIsLastStep(key),
  selectNavigationDisabled: selectNavigationDisabled(key),
});
