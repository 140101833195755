import { Role } from '../../API';
import { ChangePermission, Permission } from './typings';

export enum RolesActionTypes {
  GET_ROLES_REQUEST = 'roles/GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS = 'roles/GET_ROLES_SUCCESS',
  GET_ROLES_FAIL = 'roles/GET_ROLES_FAIL',
  SET_SELECTED_ROLE = 'roles/SET_SELECTED_ROLE',
  UPDATE_SELECTED_ROLE = 'roles/UPDATE_SELECTED_ROLE',
  CLONE_SELECTED_ROLE = 'roles/CLONE_SELECTED_ROLE',
  CHECK_PERMISSION = 'roles/CHECK_PERMISSION',
  UNCHECK_PERMISSION = 'roles/UNCHECK_PERMISSION',
  DEACTIVATE_ROLE_REQUEST = 'roles/DEACTIVATE_ROLE_REQUEST',
  DEACTIVATE_ROLE_SUCCESS = 'roles/DEACTIVATE_ROLE_SUCCESS',
  DEACTIVATE_ROLE_FAIL = 'roles/DEACTIVATE_ROLE_FAIL',
  SAVE_ROLE_REQUEST = 'roles/SAVE_ROLE_REQUEST',
  SAVE_ROLE_SUCCESS = 'roles/SAVE_ROLE_SUCCESS',
  SAVE_ROLE_FAIL = 'roles/SAVE_ROLE_FAIL',
  DELETE_ROLE_REQUEST = 'roles/DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS = 'roles/DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAIL = 'roles/DELETE_ROLE_FAIL',
}

export type RolesAction =
  | { type: RolesActionTypes.GET_ROLES_REQUEST }
  | { type: RolesActionTypes.GET_ROLES_SUCCESS; payload: Role[] }
  | { type: RolesActionTypes.GET_ROLES_FAIL; error: unknown }
  | { type: RolesActionTypes.SET_SELECTED_ROLE; payload: Role }
  | { type: RolesActionTypes.UPDATE_SELECTED_ROLE; payload: Partial<Role> }
  | { type: RolesActionTypes.CLONE_SELECTED_ROLE }
  | { type: RolesActionTypes.CHECK_PERMISSION; payload: ChangePermission }
  | { type: RolesActionTypes.UNCHECK_PERMISSION; payload: ChangePermission }
  | { type: RolesActionTypes.DEACTIVATE_ROLE_REQUEST }
  | { type: RolesActionTypes.DEACTIVATE_ROLE_SUCCESS }
  | { type: RolesActionTypes.DEACTIVATE_ROLE_FAIL; error: unknown }
  | { type: RolesActionTypes.SAVE_ROLE_REQUEST }
  | { type: RolesActionTypes.SAVE_ROLE_SUCCESS }
  | { type: RolesActionTypes.SAVE_ROLE_FAIL; error: unknown }
  | { type: RolesActionTypes.DELETE_ROLE_REQUEST }
  | { type: RolesActionTypes.DELETE_ROLE_SUCCESS }
  | { type: RolesActionTypes.DELETE_ROLE_FAIL; error: unknown };

const getRolesRequest = (): RolesAction => ({ type: RolesActionTypes.GET_ROLES_REQUEST });
const getRolesSuccess = (payload: Role[]): RolesAction => ({
  type: RolesActionTypes.GET_ROLES_SUCCESS,
  payload,
});
const getRolesFail = (error: unknown): RolesAction => ({ type: RolesActionTypes.GET_ROLES_FAIL, error });

const setSelectedRole = (payload: Role): RolesAction => ({
  type: RolesActionTypes.SET_SELECTED_ROLE,
  payload,
});

const updateSelectedRole = (payload: Partial<Role>): RolesAction => ({
  type: RolesActionTypes.UPDATE_SELECTED_ROLE,
  payload,
});

const cloneSelectedRole = (): RolesAction => ({
  type: RolesActionTypes.CLONE_SELECTED_ROLE,
});

const checkPermission= (payload: ChangePermission): RolesAction => ({
  type: RolesActionTypes.CHECK_PERMISSION,
  payload,
});
const uncheckPermission = (payload: ChangePermission): RolesAction => ({
  type: RolesActionTypes.UNCHECK_PERMISSION,
  payload,
});

const deactivateRoleRequest = (): RolesAction => ({
  type: RolesActionTypes.DEACTIVATE_ROLE_REQUEST,
});
const deactivateRoleSuccess = (): RolesAction => ({
  type: RolesActionTypes.DEACTIVATE_ROLE_SUCCESS,
});
const deactivateRoleFail = (error: unknown): RolesAction => ({
  type: RolesActionTypes.DEACTIVATE_ROLE_FAIL,
  error,
});

const saveRoleRequest = (): RolesAction => ({
  type: RolesActionTypes.SAVE_ROLE_REQUEST,
});
const saveRoleSuccess = (): RolesAction => ({
  type: RolesActionTypes.SAVE_ROLE_SUCCESS,
});
const saveRoleFail = (error: unknown): RolesAction => ({
  type: RolesActionTypes.SAVE_ROLE_FAIL,
  error,
});

const deleteRoleRequest = (): RolesAction => ({
  type: RolesActionTypes.DELETE_ROLE_REQUEST,
});
const deleteRoleSuccess = (): RolesAction => ({
  type: RolesActionTypes.DELETE_ROLE_SUCCESS,
});
const deleteRoleFail = (error: unknown): RolesAction => ({
  type: RolesActionTypes.DELETE_ROLE_FAIL,
  error,
});

export const rolesActions = {
  getRolesRequest,
  getRolesSuccess,
  getRolesFail,
  setSelectedRole,
  updateSelectedRole,
  cloneSelectedRole,
  checkPermission,
  uncheckPermission,
  deactivateRoleRequest,
  deactivateRoleSuccess,
  deactivateRoleFail,
  saveRoleRequest,
  saveRoleSuccess,
  saveRoleFail,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFail,
};
