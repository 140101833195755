import { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteBookingTemplatesModalActions,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../store/bookingTemplates';
import { Path } from '../../../routing';
import labels from './labels';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { TimeUnit, LocationType, UpdateBookingTemplateInput } from '../../../API';
import { navigationService } from '../../../services/NavigationService';
import { userSettingsSelectors } from '../../../store/userSettings';

interface IBookingTemplateCard {
  bookingTemplate: UpdateBookingTemplateInput;
  handleCheckboxChange: (id: string, checked: boolean | undefined) => void;
}

export const BookingTemplateCard = memo(({ bookingTemplate, handleCheckboxChange }: IBookingTemplateCard) => {
  const { id, enabled, what, where, when } = bookingTemplate;
  const dispatch = useDispatch();
  const menu = useRef<Menu>(null);
  const selectedBookingTemplates = useSelector(bookingTemplatesSelectors.selectSelectedBookingTemplates);
  const isBookingTemplatesCreate = useSelector(userSettingsSelectors.selectBookingTemplatesCreate);
  const isBookingTemplatesEdit = useSelector(userSettingsSelectors.selectBookingTemplatesEdit);
  const isBookingTemplatesDelete = useSelector(userSettingsSelectors.selectBookingTemplatesDelete);
  const selected = selectedBookingTemplates.includes(id);

  const description = [
    when?.duration?.count + (when?.duration?.timeUnit === TimeUnit.MINUTE ? labels.minute : labels.hour),
    ...(where?.locationTypes?.map((type) => {
      switch (type) {
        case LocationType.IN_PERSONAL:
          return labels.inPerson;
        case LocationType.PHONE_CALL:
          return labels.phoneCall;
        case LocationType.VIDEO_CONFERENCE:
          return labels.videoConference;
      }
    }) || []),
  ].join(', ');

  const handleEdit = () => {
    navigationService.navigateTo(Path.EditBookingTemplate.replace(':bookingTemplateId', id));
  };

  const handleEnable = () => {
    dispatch(
      bookingTemplatesActions.enableBookingTemplateRequest({
        ...bookingTemplate,
        enabled: !enabled,
      })
    );
  };

  const handleClone = () => {
    dispatch(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
    dispatch<any>(bookingTemplatesActions.cloneBookingTemplateThunk()); // TODO: any
  };

  const handleDelete = () => {
    dispatch(bookingTemplatesActions.selectBookingTemplate(id));
    dispatch(deleteBookingTemplatesModalActions.openModal());
  };

  const menuItems = [
    { label: labels.edit, icon: 'pi pi-fw pi-pencil', command: handleEdit, visible: isBookingTemplatesEdit },
    {
      label: enabled ? labels.deactivate : labels.activate,
      icon: enabled ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open',
      command: handleEnable,
      visible: isBookingTemplatesEdit,
    },
    { label: labels.clone, icon: 'pi pi-fw pi-clone', command: handleClone, visible: isBookingTemplatesCreate },
    { label: labels.delete, icon: 'pi pi-fw pi-trash', command: handleDelete, visible: isBookingTemplatesDelete },
  ];

  return (
    <div
      className={`h-full border-1 border-round surface-border cursor-pointer hover:bg-gray-50 ${
        enabled ? '' : 'bg-gray-100 text-gray-500'
      }`}
      onClick={handleEdit}
    >
      <div
        className={`flex flex-column card shadow-none bg-transparent`}
        style={{
          borderLeft: `6px solid ${enabled ? what?.color : '#9E9E9E'}`,
          margin: '-1px',
          height: 'calc(100% + 2px)',
        }}
      >
        <div className="flex justify-content-end">
          {isBookingTemplatesDelete && (
            <div className="mr-auto" onClick={(e) => e.stopPropagation()}>
              <Checkbox checked={selected} onChange={(e) => handleCheckboxChange(id, e.target.checked)} />
            </div>
          )}
          {(isBookingTemplatesCreate || isBookingTemplatesEdit || isBookingTemplatesDelete) && (
            <div className="static sm:relative flex-container -mt-2 -mr-2 mb-2" onClick={(e) => e.stopPropagation()}>
              <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
              <Button rounded text icon="pi pi-cog text-lg" onClick={(event) => menu.current?.toggle(event)} />
            </div>
          )}
        </div>

        <div className="text-xl font-medium overflow-hidden text-overflow-ellipsis white-space-nowrap">
          {what?.customName}
        </div>

        <div className="h-3rem overflow-hidden text-overflow-ellipsis mt-1 mb-2">{description}</div>

        <div className="flex justify-content-end align-items-end mt-auto -mb-3 -mr-3">
          {!isBookingTemplatesEdit && (
            <div className="locked bg-yellow-100 border-round py-1 px-2 mb-3 mr-auto">
              <i className="pi pi-lock mr-2" />
              {labels.locked}
            </div>
          )}
          <Button label={labels.viewDetails} text />
        </div>
      </div>
    </div>
  );
});

BookingTemplateCard.displayName = 'BookingTemplateCard';
