export const styles = {
  dayType: {
    width: '40px',
  },
  dayContainer: {
    display: 'flex',
    gap: '15px',
    marginBottom: '16px',
  },
  timeContainer: {
    alignItems: 'center',
    marginRight: '8px',
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '8px',
  },
  unavailableText: {
    fontSize: '14px',
    marginLeft: '8px',
  },
  timeInput: {
    width: '100px',
  },
  dayError: {
    color: '#f44336',
  },
};
