import { UpdateBookingPageInput } from '../../API';
import { APIPostRequest } from '../utils/reduxUtils';
import { CreateShortLink } from './types';

// ***
// When changing functions, do not forget to specify new data
// in src/docs/BookingPages.md
// ***

// ***
// When changing functions, do not forget to specify new data
// in src/docs/BookingPages.md
// ***

export async function getBookingPages(workspaceId: string) {
  try {
    const response = (await APIPostRequest('/api/public/getBookingPage', {
      workspaceId,
    })).bookingPages as UpdateBookingPageInput[];

    if (response) {
      return response;
    } else {
      throw new Error('BookingPage not found');
    }
  } catch (error: unknown) {
    console.error('Redux bookingPages service; Error fetching booking pages:', error);
    throw error;
  }
}

export async function getBookingPage(bookingPageId: string) {
  try {
    const response = (await APIPostRequest('/api/public/getBookingPage', {
      bookingPageId,
    })).bookingPages as UpdateBookingPageInput[];

    if (response && response.length > 0) {
      return response[0];
    } else {
      throw new Error('BookingPage not found');
    }
  } catch (error: unknown) {
    console.error('Redux bookingPages service; Error fetching booking pages:', error);
    throw error;
  }
}

export async function postBookingPage(bookingPage: UpdateBookingPageInput) {
  try {
    return await APIPostRequest('/api/js/upsertBookingPage', {
      data: bookingPage,
    });
  } catch (error: unknown) {
    console.error('Redux bookingPages service; Error saving booking pages:', error);
    throw error;
  }
}

export const deleteBookingPages = async (bookingPagesIds: string[], workspaceId: string) => {
  try {
    await APIPostRequest('/api/js/deleteBookingPage', { data: bookingPagesIds.map((id) => ({ id, workspaceId })) });
  } catch (error: unknown) {
    console.error('Redux bookingPages service; Error deleting booking pages:', error);
    throw error;
  }
};

export async function createShortLink(body: CreateShortLink) {
  try {
    return await APIPostRequest('/api/js/createShortLink', body) as string;
  } catch (error: unknown) {
    console.error('Redux bookingPages service; Error fetching booking pages:', error);
    throw error;
  }
}
