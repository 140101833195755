import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { UpdateBookingPageInput, VideoConferenceType, WorkspaceInput } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CLONE_BOOKING_PAGE_ERROR_TOAST,
  CLONE_BOOKING_PAGE_SUCCESS_TOAST,
  DEFAULT_BOOKING_PAGE_DATA,
  DELETE_BOOKING_PAGES_ERROR_TOAST,
  DELETE_BOOKING_PAGES_SUCCESS_TOAST,
  EMPTY_ACCORDION_INDEXES,
  GET_BOOKING_PAGES_ERROR_TOAST,
  GET_BOOKING_PAGE_ERROR_TOAST,
  SAVE_BOOKING_PAGE_ERROR_TOAST,
  SAVE_BOOKING_PAGE_SUCCESS_TOAST,
} from './constants';
import { bookingPagesActionTypes, bookingPagesActions } from './actions';
import { bookingPageSelectors } from './selectors';
import { deleteBookingPages, getBookingPages, getBookingPage, postBookingPage, createShortLink } from './service';
import { authentificationSelectors } from '../authentification';
import { copyPublicFile, uploadPublicFile } from '../userSettings/service';
import { generatePublicFileKeyCopy, generatePublicFileKey, generateShortUrlRequest } from './utils';
import { UpsertBookingPage } from './types';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { getBaseUrl } from '../../services/utils';
import { handleServiceError } from '../utils/reduxUtils';
import { usersSaga } from '../users/sagas';
import { teamsSaga } from '../teams/sagas';
import { bookingTemplatesSagas } from '../bookingTemplates/sagas';
import { workspacesSelectors } from '../workspaces';
import { userSettingsSelectors } from '../userSettings';

const selectCreateBookingPageRequest = createSelector(
  authentificationSelectors.selectWorkspace,
  bookingPageSelectors.selectBookingPage,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingPage, lastModify) => ({
    ...bookingPage,
    workspaceId,
    lastModify,
  })
);

const selectCloneBookingPageRequest = createSelector(
  authentificationSelectors.selectWorkspace,
  bookingPageSelectors.selectBookingPage,
  bookingPageSelectors.selectCloneName,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingPage, cloneName, lastModify) => ({
    ...bookingPage,
    id: '',
    workspaceId,
    adminOnly: false,
    lastModify,
    what: { ...bookingPage.what, customName: cloneName },
  })
);

// copy workspace images if required, remove old and temporary images
function* processFilesOnCreateUpdate() {
  // const imageNamesToRemove: string[] = yield select(bookingPageSelectors.selectImageNamesToRemove);
  const isWorkspaceLogoToUse: string = yield select(bookingPageSelectors.selectIsWorkspaceLogoToUse);
  const isWorkspaceBackgroundToUse: string = yield select(bookingPageSelectors.selectIsWorkspaceBackgroundToUse);
  const logo: string = yield select(bookingPageSelectors.selectFinalLogoImage);
  const background: string = yield select(bookingPageSelectors.selectFinalBackgroundImage);

  // for (const imageName of imageNamesToRemove) {
  //   yield fork(removeFile, imageName);
  // }

  const avatar: string = isWorkspaceLogoToUse
    ? yield call(copyPublicFile, generatePublicFileKey(logo), generatePublicFileKeyCopy(logo))
    : logo;

  const backgroundImage: string = isWorkspaceBackgroundToUse
    ? yield call(copyPublicFile, generatePublicFileKey(background), generatePublicFileKeyCopy(background))
    : background;

  yield put(bookingPagesActions.updateStyleStep({ avatar, backgroundImage }));
}

// remove images from deleted booking pages
// function* processFilesOnDelete() {
//   const imageNamesToRemove: string[] = yield select(bookingPageSelectors.selectSelectedBookingPagesImageNames);
//   for (const imageName of imageNamesToRemove) {
//     yield fork(removeFile, imageName);
//   }
// }

// TODO: find out how resolve typings error when thunk is dispatching from saga
function* getBookingPagesSaga() {
  try {
    const workspace: string = yield select(authentificationSelectors.selectWorkspace);
    const response: UpdateBookingPageInput[] = yield call(getBookingPages, workspace);

    yield put(bookingPagesActions.getBookingPagesSuccess(response));
  } catch (error: unknown) {
    yield put(bookingPagesActions.getBookingPagesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_PAGES_ERROR_TOAST, false);
  }
}

function* getBookingPagesPageSaga(action: ReturnType<typeof bookingPagesActions.getBookingPagesPageRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST) {
      const workspace: string = yield select(authentificationSelectors.selectWorkspace);
      const bookingPages: UpdateBookingPageInput[] = yield select(bookingPageSelectors.selectBookingPages);
      const requestsList = [
        ...(!action.isInitialVisit ? [call(getBookingPages, workspace), call(bookingTemplatesSagas.getBookingTemplates)] : []),
        call(usersSaga.getUsers),
        call(teamsSaga.getTeams),
      ];
      let [response]: [UpdateBookingPageInput[]] = yield all(requestsList);
      if(action.isInitialVisit ){
        response = bookingPages;
      }
      yield put(bookingPagesActions.getBookingPagesSuccess(response));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.getBookingPagesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_PAGES_ERROR_TOAST, true);
  }
}

function* getBookingPageSaga(action: ReturnType<typeof bookingPagesActions.getBookingPageRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST) {
      const id = action.payload.id;
      const isInitial = action.payload.isInitialVisit;
      const currentWorkspaceId: string = yield select(authentificationSelectors.selectWorkspace);
      const currentWorkspace: WorkspaceInput = yield select(
        workspacesSelectors.selectWorkspaceById(currentWorkspaceId)
      );
      const userId: string = yield select(authentificationSelectors.selectUserId);
      const defaultVideoConference: VideoConferenceType = yield select(
        userSettingsSelectors.selectDefaultVideoIntegration
      );

      const requestsList = [
        ...(id ? [call(getBookingPage, id)] : []),
        ...(!isInitial ? [call(bookingTemplatesSagas.getBookingTemplates)] : []),
        call(usersSaga.getUsers),
        call(teamsSaga.getTeams),
      ];
      let [bookingPage]: [UpdateBookingPageInput] = yield all(requestsList);

      if (!id) {
        bookingPage = {
          ...DEFAULT_BOOKING_PAGE_DATA,
          workspaceId: currentWorkspaceId,
          labels: currentWorkspace.labels,
          style: {
            ...currentWorkspace.style,
            avatar: null,
            backgroundImage: null,
          },
          who: {
            ...DEFAULT_BOOKING_PAGE_DATA.who,
            host: [{ userId: userId }],
          },
          where: {
            ...DEFAULT_BOOKING_PAGE_DATA.where,
            videoConferenceType: defaultVideoConference,
          },
        };
      }
      yield put(bookingPagesActions.getBookingPageSuccess(bookingPage));
      yield put(bookingPagesActions.updateAccordionIndexes({ first: 0 }));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.getBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_PAGE_ERROR_TOAST);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

function* createBookingPageSaga({ payload: isRedirect }: any) {
  try {
    yield call(processFilesOnCreateUpdate);

    const createBookingPage: UpdateBookingPageInput = yield select(selectCreateBookingPageRequest);
    const response: UpsertBookingPage = yield call(postBookingPage, createBookingPage);

    const shortLinkRequest = generateShortUrlRequest(createBookingPage, response.id);
    const shortLink: string = yield call(createShortLink, shortLinkRequest);

    if (isRedirect) {
      yield put(bookingPagesActions.clearBookingPage());
      yield call(navigationService.navigateTo, Path.BookingPages);
    } else {
      yield put(bookingPagesActions.updateRecord({ id: response.id, displayId: response.displayId, shortLink }));
      yield put(bookingPagesActions.updateAccordionIndexes(EMPTY_ACCORDION_INDEXES));
    }

    yield put(bookingPagesActions.createBookingPagesuccess());
    yield put<any>(notificationsActions.showToast(SAVE_BOOKING_PAGE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingPagesActions.createBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_PAGE_ERROR_TOAST);
  }
}

function* cloneBookingPageSaga() {
  try {
    yield call(processFilesOnCreateUpdate);

    const cloneBookingPage: UpdateBookingPageInput = yield select(selectCloneBookingPageRequest);
    const response: UpsertBookingPage = yield call(postBookingPage, cloneBookingPage);

    const shortLinkRequest = generateShortUrlRequest(cloneBookingPage, response.id);
    yield call(createShortLink, shortLinkRequest);

    yield call(navigationService.navigateTo, Path.BookingPages);

    yield put(bookingPagesActions.cloneBookingPagesuccess());
    yield put<any>(notificationsActions.showToast(CLONE_BOOKING_PAGE_SUCCESS_TOAST));
    yield put(bookingPagesActions.getBookingPagesRequest());
  } catch (error: unknown) {
    yield put(bookingPagesActions.cloneBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_BOOKING_PAGE_ERROR_TOAST);
  }
}

// TODO: find out how resolve typings error when saga has parameters
function* updateBookingPageSaga({ payload: isRedirect }: any) {
  try {
    yield call(processFilesOnCreateUpdate);

    const bookingPage: UpdateBookingPageInput = yield select(bookingPageSelectors.selectBookingPage);
    const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
    yield call(postBookingPage, { ...bookingPage, lastModify });

    if (isRedirect) {
      yield put(bookingPagesActions.clearBookingPage());
      yield call(navigationService.navigateTo, Path.BookingPages);
    } else {
      yield put(bookingPagesActions.updateAccordionIndexes(EMPTY_ACCORDION_INDEXES));
    }

    yield put(bookingPagesActions.saveBookingPagesuccess());
    yield put<any>(notificationsActions.showToast(SAVE_BOOKING_PAGE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingPagesActions.saveBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_PAGE_ERROR_TOAST);
  }
}

function* activateBookingPageSaga({ payload: bookingPage }: any) {
  try {
    const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
    yield call(postBookingPage, { ...bookingPage, lastModify });
    yield put(bookingPagesActions.activateBookingPagesuccess());
    yield put(bookingPagesActions.setBookingPage(bookingPage));
    yield put<any>(notificationsActions.showToast(SAVE_BOOKING_PAGE_SUCCESS_TOAST));
    yield put(bookingPagesActions.getBookingPagesRequest());
  } catch (error: unknown) {
    yield put(bookingPagesActions.activateBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_PAGE_ERROR_TOAST);
  }
}

function* deleteBookingPagesSaga() {
  try {
    const ids: string[] = yield select(bookingPageSelectors.selectSelectedBookingPages);
    const workspaceId: string = yield select(authentificationSelectors.selectWorkspace);

    yield call(deleteBookingPages, ids, workspaceId);

    // yield call(processFilesOnDelete);

    yield call(navigationService.navigateTo, Path.BookingPages);

    yield put(bookingPagesActions.deleteBookingPagesSuccess());
    yield put<any>(notificationsActions.showToast(DELETE_BOOKING_PAGES_SUCCESS_TOAST));
    yield put(bookingPagesActions.unselectAllBookingPage());
    yield put(bookingPagesActions.getBookingPagesRequest());
  } catch (error: unknown) {
    yield put(bookingPagesActions.deleteBookingPagesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_BOOKING_PAGES_ERROR_TOAST);
  }
}

function* uploadAvatarFileSaga({ payload }: any) {
  try {
    const path: string = yield select(authentificationSelectors.selectTenantFileFolderPath);
    const imageLink: string = yield call(uploadPublicFile, payload.file, path);
    yield put(bookingPagesActions.uploadAvatarFileSuccess({ imageType: payload.imageType, imageLink }));
  } catch (error: unknown) {
    yield put(bookingPagesActions.uploadAvatarFileFail(error));
  }
}

function* uploadBackgroundFileSaga({ payload }: any) {
  try {
    const path: string = yield select(authentificationSelectors.selectTenantFileFolderPath);
    const imageLink: string = yield call(uploadPublicFile, payload.file, path);
    yield put(bookingPagesActions.uploadBackgroundFileSuccess({ imageType: payload.imageType, imageLink }));
  } catch (error: unknown) {
    yield put(bookingPagesActions.uploadBackgroundFileFail(error));
  }
}

const selectBookingPageShortLinkRequest = (bookingPages: UpdateBookingPageInput[]) =>
  createSelector([() => bookingPages], (bookingPages) =>
    bookingPages.map((bookingPage) => ({
      bookingPage,
      url: getBaseUrl() + Path.PublicBookingPage.replace(':bookingPageId', bookingPage.id),
    }))
  );

function* createShortLinksSaga(action: ReturnType<typeof bookingPagesActions.shortLinksRequest>): any {
  try {
    if (action.type === bookingPagesActionTypes.SHORT_LINKS_REQUEST) {
      const bookingPagesWithNoShortLinks = action.payload;
      const bookingPages = yield select(bookingPageSelectors.selectBookingPages);
      const shortLinkRequests = yield select(selectBookingPageShortLinkRequest(bookingPagesWithNoShortLinks));
      const shortLinkResponses: String[] = yield all(
        shortLinkRequests.map((shortLinkRequest: any) => call(createShortLink, shortLinkRequest))
      );

      // add shortLink to each bookingPage in bookingPagesWithNoShortLinks
      const updatedBookingPages = bookingPagesWithNoShortLinks.map((bookingPage, index) => ({
        ...bookingPage,
        shortLink: shortLinkResponses[index][0],
      }));
      // filter out the bookingPages that were updated with shortLinks
      const filteredBookingPages = bookingPages.filter(
        (bookingPage: UpdateBookingPageInput) =>
          !bookingPagesWithNoShortLinks.find((pageWithNoShortLink) => pageWithNoShortLink.id === bookingPage.id)
      );
      // combine the updated booking pages with the rest of the booking pages
      const finalBookingPages = [...filteredBookingPages, ...updatedBookingPages];
      // dispatch the updated booking pages
      yield put(bookingPagesActions.getBookingPagesSuccess(finalBookingPages));
      yield put(bookingPagesActions.shortLinksSuccess());
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.shortLinksFail(error));
  }
}

export function* watchBookingPagesSaga() {
  yield takeLatest(bookingPagesActionTypes.GET_BOOKING_PAGES_REQUEST, getBookingPagesSaga);
  yield takeLatest(bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST, getBookingPagesPageSaga);
  yield takeLatest(bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST, getBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.CREATE_BOOKING_PAGE_REQUEST, createBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.CLONE_BOOKING_PAGE_REQUEST, cloneBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.SAVE_BOOKING_PAGE_REQUEST, updateBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_REQUEST, activateBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.DELETE_BOOKING_PAGES_REQUEST, deleteBookingPagesSaga);
  yield takeLatest(bookingPagesActionTypes.UPLOAD_AVATAR_FILE_REQUEST, uploadAvatarFileSaga);
  yield takeLatest(bookingPagesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST, uploadBackgroundFileSaga);
  yield takeLatest(bookingPagesActionTypes.SHORT_LINKS_REQUEST, createShortLinksSaga);
}

export const bookingPagesSagas = {
  getBookingPages: getBookingPagesSaga,
};
