import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { CreateUserEventInput, UpdateUserEventInput } from '../../API';
import { eventActions, EventActionTypes } from './actions';
import { eventSelectors } from './selectors';
import { cancelEvent, fetchAgenda, createEvent, updateEvent, sendNotifications } from './service';
import { AgendaType, CreateUpdateEventResponse, EventAction, EventSteps, GetAgendaRequestType } from './types';
import { notificationsActions } from '../notifications';
import { CANCEL_EVENT_ERROR_TOAST, CANCEL_EVENT_SUCCESS_TOAST, scheduledMeetingsActions } from '../scheduledMeetings';
import { agendaEndOfMonth, agendaStartOfMonth } from './utils';
import { authentificationSelectors } from '../authentification';
import { SERVER_ERROR_CODES } from '../../types/constants';
import { NO_USERS_AVAILABLE_LABEL } from './constants';

const selectGetAgendaRequest = createSelector(
  eventSelectors.selectBookingPageId,
  eventSelectors.selectScheduledMeetingId,
  eventSelectors.selectViewDate,
  eventSelectors.selectTimeZone,
  eventSelectors.selectBookingPage,
  (bookingPageId, eventId, viewDate, timeZone, agendaBookingPage) =>
    ({
      bookingPageId,
      eventId,
      timeZone,
      startTime: agendaBookingPage.id ? agendaStartOfMonth(viewDate, timeZone) : undefined,
      endTime: agendaBookingPage.id ? agendaEndOfMonth(viewDate, timeZone) : undefined,
    } as GetAgendaRequestType)
);

const selectEventRequest = createSelector(
  eventSelectors.selectEvent,
  eventSelectors.selectBookingPage,
  (event, bookingPage) => ({
    ...event,
    bookingPageId: bookingPage.id || '',
    bookingPageName: event.bookingPageName || bookingPage.what?.customName || '',
  })
);

function* getAgendaSaga(action: ReturnType<typeof eventActions.getAgendaRequest>): any {
  // TODO: do we need the action?
  try {
    const request: GetAgendaRequestType = yield select(selectGetAgendaRequest);

    const response: AgendaType = yield call(fetchAgenda, request);

    const isEventLoaded = yield select(eventSelectors.selectIsExisting);
    if (response.event && !isEventLoaded) {
      yield put(eventActions.setScheduledMeeting(response.event));
    }

    yield put(eventActions.getAgendaSuccess(response));

    // const step = yield select(eventSelectors.selectStep);
    // if (step === EventSteps.WHAT && Boolean(response.meetingType)) {
    //   yield put(eventActions.setEventStep(EventSteps.WHEN));
    // }
  } catch (error: unknown) {
    yield put(eventActions.getAgendaFail(error?.toString() || ''));
  }
}

function* createEventSaga(): any {
  try {
    const request: CreateUserEventInput = yield select(selectEventRequest);

    const response: CreateUpdateEventResponse = yield call(createEvent, request);
    yield fork(sendNotifications, response.event.eventId, EventAction.CREATE);

    yield put(eventActions.createEventSuccess(response));
  } catch (error: unknown) {
    const errorText = error?.toString() || '';
    const errorMessage = errorText.includes(SERVER_ERROR_CODES.NoUsersAvailable) ? NO_USERS_AVAILABLE_LABEL : errorText;
    yield put(eventActions.createEventFail(errorMessage));
  }
}

function* updateEventSaga(): any {
  try {
    const request: UpdateUserEventInput = yield select(selectEventRequest);

    const response: CreateUpdateEventResponse = yield call(updateEvent, request);
    yield fork(sendNotifications, request.eventId, EventAction.UPDATE);

    yield put(eventActions.updateEventSuccess(response));

    const isAuthenticated: boolean = yield select(authentificationSelectors.selectIsUserAuthenticated);
    if (isAuthenticated) {
      yield put(scheduledMeetingsActions.getScheduledMeetingsRequest());
    }
  } catch (error: unknown) {
    const errorText = error?.toString() || '';
    const errorMessage = errorText.includes(SERVER_ERROR_CODES.NoUsersAvailable) ? NO_USERS_AVAILABLE_LABEL : errorText;
    yield put(eventActions.updateEventFail(errorMessage));
  }
}

function* cancelEventSaga(): any {
  try {
    const eventId = yield select(eventSelectors.selectEventId);
    const canceled = yield select(eventSelectors.selectEventCanceled);

    yield call(cancelEvent, { eventId, canceled });
    yield fork(sendNotifications, eventId, EventAction.CANCEL);

    yield put(eventActions.cancelEventSuccess());
    yield put<any>(notificationsActions.showToast(CANCEL_EVENT_SUCCESS_TOAST));

    yield put(eventActions.setEventStep(EventSteps.BOOKED));
  } catch (error: unknown) {
    yield put(eventActions.cancelEventFail(error?.toString() || ''));
    yield put<any>(notificationsActions.showToast(CANCEL_EVENT_ERROR_TOAST));
  }
}

export function* watchEventSaga() {
  yield takeLatest(EventActionTypes.GET_AGENDA_REQUEST, getAgendaSaga);
  yield takeLatest(EventActionTypes.CREATE_EVENT_REQUEST, createEventSaga);
  yield takeLatest(EventActionTypes.UPDATE_EVENT_REQUEST, updateEventSaga);
  yield takeLatest(EventActionTypes.CANCEL_EVENT_REQUEST, cancelEventSaga);
}
