import { memo, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { CustomType, CustomFieldInput, CustomFieldType } from '../../../../API';
import { sortListItemDown, sortListItemUp } from '../../../../store/bookingTemplates';
import { CUSTOM_FIELDS_OPTIONS, DEFAULT_ANSWERS, MULTI_ANSWERS_TYPES } from './constants';
import labels from './labels';
import { MAX_LENGTH_LONG_ANSWER, MAX_LENGTH_QUESTION, MAX_LENGTH_SHORT_ANSWER } from '../../../../types/constants';

interface IEditCustomField {
  customFieldInput: CustomFieldInput;
  onSave: (customField: CustomFieldInput) => void;
  onCancel: () => void;
  onDelete: (id: string) => void;
}

export const EditCustomField = memo(({ customFieldInput, onSave, onCancel, onDelete }: IEditCustomField) => {
  const [customField, setCustomField] = useState<CustomFieldInput>(customFieldInput);

  const isSaveDisabled = useMemo(() => {
    return customField.type && MULTI_ANSWERS_TYPES.includes(customField.type)
      ? !customField.answers?.every(Boolean) || !customField.label
      : !customField.label;
  }, [customField.type, customField.answers, customField.label]);

  const handleLabelChange = (label: string) => {
    setCustomField({ ...customField, label });
  };
  const handleRequiredChange = (required: boolean) => {
    setCustomField({ ...customField, required });
  };
  const handleEnabledChange = (enabled: boolean) => {
    setCustomField({ ...customField, enabled });
  };
  const handleTypeChange = (type: CustomType) => {
    const label = customField.label;
    if (MULTI_ANSWERS_TYPES.includes(type)) {
      setCustomField({
        ...customField,
        type,
        label,
        answers: (customField.answers?.length && customField.answers) || DEFAULT_ANSWERS,
      });
    } else {
      setCustomField({ ...customField, type, label, answers: [] });
    }
  };

  const handleAddAnswer = () => {
    setCustomField({ ...customField, answers: [...(customField.answers || []), ''] });
  };
  const handleRemoveAnswer = (index: number) => {
    setCustomField({
      ...customField,
      answers: (customField.answers || []).filter((answer, i) => i !== index),
      value: null,
    });
  };
  const handleAnswerDown = (index: number) => {
    setCustomField({ ...customField, answers: sortListItemDown(customField.answers || [], index) });
  };
  const handleAnswerUp = (index: number) => {
    setCustomField({ ...customField, answers: sortListItemUp(customField.answers || [], index) });
  };
  const handleAnswerChange = (index: number, value: string) => {
    setCustomField({
      ...customField,
      answers: (customField.answers || []).map((answer, i) => (i === index ? value : answer)),
    });
  };

  const handleSave = () => {
    onSave && onSave(customField);
  };
  const handleDelete = () => {
    if (customField.id) {
      onDelete(customField.id);
    }
  };

  return (
    <>
      <div>{labels.description}</div>

      <div className="mt-4 p-fluid">
        <label className="font-bold" htmlFor="questionLabel">
          {labels.questionLabel}
        </label>
        <InputTextarea
          id="questionLabel"
          autoResize
          rows={3}
          value={customField.label || ''}
          onChange={(e) => handleLabelChange(e.target.value.trimStart())}
          maxLength={MAX_LENGTH_QUESTION}
        />
      </div>

      <div className="flex justify-content-between">
        <div>
          <div>{labels.requiredLabel}</div>
          <InputSwitch
            checked={!!customField.required}
            disabled={customField.fieldType !== CustomFieldType.CUSTOM}
            onChange={(e) => handleRequiredChange(!!e.value)}
          />
        </div>
        <div className="text-right">
          <div>{labels.activeLabel}</div>
          <InputSwitch
            checked={!!customField.enabled}
            disabled={customField.fieldType !== CustomFieldType.CUSTOM}
            onChange={(e) => handleEnabledChange(!!e.value)}
          />
        </div>
      </div>

      <div className="mt-4 p-fluid">
        <div className="font-bold">{labels.typeLabel}</div>
        <Dropdown
          disabled={customField.fieldType !== CustomFieldType.CUSTOM}
          options={CUSTOM_FIELDS_OPTIONS}
          optionLabel="label"
          value={customField.type}
          onChange={(e) => handleTypeChange(e.target.value)}
        />
      </div>

      {Boolean(customField.answers?.length) && (
        <div className="mt-4">
          <div className="font-bold">{labels.answersLabel}</div>
          {customField.type === CustomType.CHECKBOXES ? (
            <div>
              {labels.answersMultipleDescription1}
              <b>{labels.answersMultipleDescription2}</b>
              {labels.answersMultipleDescription3}
            </div>
          ) : (
            <div>
              {labels.answersSingleDescription1}
              <b>{labels.answersSingleDescription2}</b>
              {labels.answersSingleDescription3}
            </div>
          )}
          {customField.answers?.map((answer, index, { length }) => (
            <div key={index} className="flex align-items-center my-2">
              <div className="flex flex-column">
                {index !== 0 && (
                  <Button icon="pi pi-angle-up" className="h-1rem mr-2" text onClick={() => handleAnswerUp(index)} />
                )}
                {index !== length - 1 && (
                  <Button
                    icon="pi pi-angle-down"
                    className="h-1rem mr-2"
                    text
                    onClick={() => handleAnswerDown(index)}
                  />
                )}
              </div>
              <div className="flex-1">
                <InputText
                  className="w-full"
                  value={answer || ''}
                  placeholder={labels.placeholder + ' ' + (index + 1)}
                  onChange={(e) => handleAnswerChange(index, e.target.value.trimStart())}
                  maxLength={
                    [CustomType.TEXT, CustomType.CHECKBOXES, CustomType.RADIO_BUTTONS, CustomType.SELECT].includes(
                      customField.type || CustomType.TEXT
                    )
                      ? MAX_LENGTH_SHORT_ANSWER
                      : MAX_LENGTH_LONG_ANSWER
                  }
                />
              </div>
              {length > 1 && (
                <div className="">
                  <Button icon="pi pi-trash" className="ml-2" text onClick={() => handleRemoveAnswer(index)}></Button>
                </div>
              )}
            </div>
          ))}
          <div className="flex justify-content-center">
            <Button label={labels.addAnswerButton} text onClick={handleAddAnswer}></Button>
          </div>
        </div>
      )}

      <div className="mt-5 flex gap-3">
        {customField.id && customField.fieldType === CustomFieldType.CUSTOM && (
          <Button label={labels.deleteButton} text severity="danger" onClick={handleDelete}></Button>
        )}
        <Button label={labels.cancelButton} className="ml-auto" outlined onClick={onCancel}></Button>
        <Button label={labels.saveButton} disabled={isSaveDisabled} onClick={handleSave}></Button>
      </div>
    </>
  );
});

EditCustomField.displayName = 'EditCustomField';
