import { t } from 'i18next';
import { ToastNotificationOptions } from '../notifications';

export enum UserDataKeys {
  AVATAR = 'avatar',
  COUNTRY = 'country',
  COUNTRY_CODE = 'countryCode',
  DATE_FORMAT = 'dateFormat',
  EMAIL = 'email',
  FULL_NAME = 'fullName',
  ID = 'id',
  LANGUAGE = 'language',
  PHONE_NUMBER = 'phoneNumber',
  TIME_FORMAT = 'timeFormat',
  TIME_ZONE = 'timeZone',
  USER_DEFAULT_AVAILABILITY_ID = 'userDefaultAvailabilityId',
  WELCOME_MESSAGE = 'welcomeMessage',
}

export enum UserSettingsKeys {
  LINK = 'link',
  RECORD_TYPE = 'recordType',
  USER_ID = 'userId',
  TENANT_ID = 'tenantId',
  COOKIES = 'cookies',
  WORKSPACE_ID = 'workspaceId',
  SUMO1_STAFF_DATA = 'Sumo1StaffData',
  QUICK_SETUP_WEEKLY_HOURS = 'QuickSetupAvailability'
}

export const GET_USER_SETTINGS_FAIL: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 3000,
  message: t('UserSettingsToastsNotifications:GET_USER_SETTINGS_FAIL'),
});

export const SAVE_USER_SETTINGS_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('UserSettingsToastsNotifications:SAVE_USER_SETTINGS_SUCCESS_TOAST'),
});
export const SAVE_USER_SETTINGS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('UserSettingsToastsNotifications:SAVE_USER_SETTINGS_ERROR_TOAST'),
});

export const CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('UserSettingsToastsNotifications:CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST'),
});
export const CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('UserSettingsToastsNotifications:CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST'),
});

export const UPDATE_USER_LINK_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('UserSettingsToastsNotifications:UPDATE_USER_LINK_SUCCESS_MESSAGE'),
});
export const UPDATE_USER_LINK_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('UserSettingsToastsNotifications:UPDATE_USER_LINK_ERROR_MESSAGE'),
});

export const CONNECT_ZOOM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('UserSettingsToastsNotifications:CONNECT_ZOOM_SUCCESS_TOAST'),
});
export const CONNECT_ZOOM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('UserSettingsToastsNotifications:CONNECT_ZOOM_ERROR_TOAST'),
});
