import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { Account, CreateAccountInput } from '../../../API';
import { accountsActions, AccountsActionTypes } from './actions';
import { getAccounts, getTenantsByAccountId, upsertAccount, mergeAccounts, removeAccount } from './service';
import { notificationsActions } from '../../notifications';
import { accountsSelectors } from './selectors';
import { AccountWithTenants } from './types';
import { deleteAccountModalActions, mergeAccountsModalActions } from './modal';
import {
  DELETE_ACCOUNT_ERROR_TOAST,
  DELETE_ACCOUNT_SUCCESS_TOAST,
  GET_ACCOUNTS_ERROR_TOAST,
  GET_ACCOUNT_ERROR_TOAST,
  SAVE_ACCOUNT_ERROR_TOAST,
  SAVE_ACCOUNT_SUCCESS_TOAST,
} from './constants';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { handleServiceError } from '../../utils/reduxUtils';

const selectAccountSaveRequest = createSelector(
  accountsSelectors.selectAccount,
  (account) =>
    ({
      id: account.id,
      name: account.name,
      note: account.note,
      tenantIds: account.tenantIds,
      createdBy: account.createdBy,
    } as CreateAccountInput)
);

function* getAccountsSaga(): any {
  try {
    const response: Account[] = yield call(getAccounts);
    yield put(accountsActions.getAccountsSuccess(response));
  } catch (error: unknown) {
    yield put(accountsActions.getAccountsFail(error));
    yield call(handleServiceError, error, GET_ACCOUNTS_ERROR_TOAST, true);
  }
}

function* getAccountDetailsSaga(): any {
  try {
    const accountId: string = yield select(accountsSelectors.selectSelectedAccountId);

    const response: AccountWithTenants = yield call(getTenantsByAccountId, accountId);

    yield put(accountsActions.getAccountDetailsSuccess(response));
  } catch (error: unknown) {
    yield put(accountsActions.getAccountDetailsFail(error));
    yield call(handleServiceError, error, GET_ACCOUNT_ERROR_TOAST, true);
  }
}

function* saveAccountDetailsSaga(): any {
  try {
    const account: CreateAccountInput = yield select(selectAccountSaveRequest);

    yield call(upsertAccount, account);
    yield call(navigationService.navigateTo, Path.OPSConsoleAccounts);

    yield put(accountsActions.saveAccountDetailsSuccess());
    yield put<any>(notificationsActions.showToast(SAVE_ACCOUNT_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(accountsActions.saveAccountDetailsFail(error));
    yield call(handleServiceError, error, SAVE_ACCOUNT_ERROR_TOAST);
  }
}

function* mergeAccountsSaga(): any {
  try {
    const accountId: string = yield select(accountsSelectors.selectSelectedAccountId);
    const mergeIds: string[] = yield select(accountsSelectors.selectMergeIds);

    yield call(mergeAccounts, accountId, mergeIds);

    yield put(accountsActions.mergeAccountsSuccess());
    yield put(mergeAccountsModalActions.closeModal());
  } catch (error: unknown) {
    yield put(accountsActions.mergeAccountsFail(error));
  }
}

function* deleteAccountsSaga(): any {
  try {
    const accountId: string = yield select(accountsSelectors.selectSelectedAccountId);

    yield call(removeAccount, accountId);
    yield call(navigationService.navigateTo, Path.OPSConsoleAccounts);

    yield put(accountsActions.deleteAccountSuccess());
    yield put(accountsActions.getAccountsRequest());
    yield put(deleteAccountModalActions.closeModal());
    yield put<any>(notificationsActions.showToast(DELETE_ACCOUNT_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(accountsActions.deleteAccountFail(error));
    yield call(handleServiceError, error, DELETE_ACCOUNT_ERROR_TOAST);
  }
}

export function* watchAccountsSaga() {
  yield takeLatest(AccountsActionTypes.GET_ACCOUNTS_REQUEST, getAccountsSaga);
  yield takeLatest(AccountsActionTypes.GET_ACCOUNT_DETAILS_REQUEST, getAccountDetailsSaga);
  yield takeLatest(AccountsActionTypes.SAVE_ACCOUNT_DETAILS_REQUEST, saveAccountDetailsSaga);
  yield takeLatest(AccountsActionTypes.MERGE_ACCOUNTS_REQUEST, mergeAccountsSaga);
  yield takeLatest(AccountsActionTypes.DELETE_ACCOUNT_REQUEST, deleteAccountsSaga);
}
