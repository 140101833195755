import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import labels from './labels';
import { useRef } from 'react';
import { deactivateStaffModalActions, deleteStaffModalActions, staffActions } from '../../../../store/opsConsole/staff';
import { useDispatch, useSelector } from 'react-redux';
import { Path } from '../../../../routing';
import { Staff, StaffStatus } from '../../../../API';
import { authentificationSelectors } from '../../../../store/authentification';
import { navigationService } from '../../../../services/NavigationService';

type StaffRowActionsProps = {
  staffRecord: Staff;
  showDelete?: boolean;
};

export const StaffRowActions = ({ staffRecord, showDelete }: StaffRowActionsProps) => {
  const dispatch = useDispatch();
  const menu = useRef<Menu | null>(null);
  const userId = useSelector(authentificationSelectors.selectUserId);

  // Define menu items
  const menuItems = [
    {
      label: labels.edit,
      command: () => {
        dispatch(staffActions.setStaffRecord(staffRecord));
        navigationService.navigateTo(Path.OPSConsoleStaffDetails);
      },
      visible: !!staffRecord.userId,
    },
    {
      label: staffRecord.status === StaffStatus.ACTIVE ? labels.deactivate : labels.activate,
      command: () => {
        dispatch(staffActions.setStaffRecord(staffRecord));
        if (staffRecord.status === StaffStatus.ACTIVE) {
          dispatch(deactivateStaffModalActions.openModal());
        } else {
          dispatch(staffActions.changeStaffStatusRequest());
        }
      },
      visible: !!staffRecord.userId && userId !== staffRecord.userId,
    },
    {
      label: labels.delete,
      command: () => {
        dispatch(staffActions.setStaffRecord(staffRecord));
        dispatch(deleteStaffModalActions.openModal());
      },
      visible: !!showDelete && userId !== staffRecord.userId,
    },
  ];

  return (
    <>
      <Button
        icon="pi pi-ellipsis-h text-color"
        text
        onClick={(e) => {
          return menu.current?.toggle && menu.current.toggle(e);
        }}
      />
      <Menu model={menuItems} popup ref={menu} />
    </>
  );
};
