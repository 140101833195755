import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { Slider } from 'primereact/slider';
import { InputTextarea } from 'primereact/inputtextarea';
import { ColorsAndFonts } from './../../quickSetup';
import EmptyImage from '../../../assets/images/emptyImage.svg';
import labels from './labels';
import { BookingPageStyleInput } from '../../../API';
import { DEFAULT_OPACITY } from '../../../store/bookingPages';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FILE_IMAGE_TYPES, MAX_FILE_SIZE_BYTES } from '../../../types/constants';

type IBookingPageBrandingSettings = {
  onSave?: () => void;
  description?: string;
  style: BookingPageStyleInput;
  uploadLogo: (file: File) => void;
  resetLogo?: () => void;
  clearLogo?: () => void;
  uploadBackground: (file: File) => void;
  resetBackground?: () => void;
  clearBackground?: () => void;
  handleColorsAndFontsChange: (name: string, value: string) => void;
  handleStyleChange: (name: string, value: string | number) => void;
  isFetchingAvatar: boolean;
  isFetchingBackground: boolean;
  isReadOnly?: boolean;
};

export const BookingPageBrandingSettings = ({
  onSave,
  description,
  style,
  uploadLogo,
  resetLogo,
  clearLogo,
  uploadBackground,
  resetBackground,
  clearBackground,
  handleColorsAndFontsChange,
  handleStyleChange,
  isFetchingAvatar,
  isFetchingBackground,
  isReadOnly,
}: IBookingPageBrandingSettings) => {
  const logoRef = useRef<FileUpload>(null);
  const backgroundRef = useRef<FileUpload>(null);

  const [opacity, setOpacity] = useState(style.opacity || DEFAULT_OPACITY);
  const [css, setCss] = useState(style.css || '');
  const [footer, setFooter] = useState(style.footer || '');

  let opacityTimeout: ReturnType<typeof setTimeout> | null = null;

  const buttonOptions = (label: string) => ({
    label,
    icon: 'hidden',
    className: 'p-button-outlined p-button-plain',
  });

  const handleUploadLogo = (file: File) => {
    uploadLogo(file);
    logoRef.current && logoRef.current.clear();
  };

  const handleUploadBackground = (file: File) => {
    uploadBackground(file);
    backgroundRef.current && backgroundRef.current.clear();
  };

  const handleOpacityChange = (opacity: number) => {
    setOpacity(opacity);

    if (opacityTimeout !== null) {
      clearTimeout(opacityTimeout);
    }
    opacityTimeout = setTimeout(() => {
      handleStyleChange('opacity', opacity);
      opacityTimeout = null;
    }, 500);
  };

  const handleCssChange = (css: string) => {
    setCss(css);
  };
  const handleCssBlur = () => {
    handleStyleChange('css', css);
  };

  const handleFooterChange = (footer: string) => {
    setFooter(footer);
  };
  const handleFooterBlur = () => {
    handleStyleChange('footer', footer);
  };

  const handleSave = () => {
    onSave && onSave();
  };

  return (
    <div>
      <div className="flex align-items-center p-3 bg-primary-100">
        <i className="pi pi-pencil text-xl mr-3" />
        <span>{description || labels.description}</span>
      </div>

      <div className="flex mt-4">
        <div className="flex-1">
          <div className="text-lg font-semibold">{labels.uploadLogo}</div>
          <div className="mb-2">{labels.formats}</div>
          <div className="flex gap-3">
            <FileUpload
              ref={logoRef}
              mode="basic"
              auto
              accept={FILE_IMAGE_TYPES}
              maxFileSize={MAX_FILE_SIZE_BYTES}
              chooseOptions={buttonOptions(labels.uploadLogoButton)}
              customUpload
              uploadHandler={(e) => handleUploadLogo(e.files[0])}
              disabled={isReadOnly}
            />
            {resetLogo && <Button label={labels.reset} outlined onClick={() => resetLogo()} disabled={isReadOnly} />}
            {clearLogo && <Button label={labels.clear} outlined onClick={() => clearLogo()} disabled={isReadOnly} />}
          </div>
        </div>
        <div className="sm:w-16rem">
          <div className="relative">
            <Image
              src={style.avatar || EmptyImage}
              alt={labels.uploadLogoButton}
              preview={Boolean(style.avatar)}
              className="flex w-full"
              imageClassName="h-8rem w-full"
              imageStyle={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
            />
            {isFetchingAvatar && (
              <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-content-center align-items-center bg-black-alpha-20">
                <ProgressSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex mt-4">
        <div className="flex-1">
          <div className="text-lg font-semibold">{labels.uploadBackground}</div>
          <div className="mb-2">{labels.formats}</div>
          <div className="flex gap-3">
            <FileUpload
              ref={backgroundRef}
              mode="basic"
              auto
              accept={FILE_IMAGE_TYPES}
              maxFileSize={MAX_FILE_SIZE_BYTES}
              chooseOptions={buttonOptions(labels.uploadBackgroundButton)}
              customUpload
              uploadHandler={(e) => handleUploadBackground(e.files[0])}
              disabled={isReadOnly}
            />
            {resetBackground && (
              <Button label={labels.reset} outlined onClick={() => resetBackground()} disabled={isReadOnly} />
            )}
            {clearBackground && (
              <Button label={labels.clear} outlined onClick={() => clearBackground()} disabled={isReadOnly} />
            )}
          </div>
        </div>
        <div className="sm:w-16rem">
          <div className="relative">
            <Image
              src={style.backgroundImage || EmptyImage}
              alt={labels.uploadBackgroundButton}
              preview={Boolean(style.backgroundImage)}
              className="flex w-full"
              imageClassName="h-8rem w-full"
              imageStyle={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
            />
            {isFetchingBackground && (
              <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-content-center align-items-center bg-black-alpha-20">
                <ProgressSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4">Opacity</div>
      <div className="flex align-items-center w-12 md:w-3">
        <Slider
          className="flex-1 mr-3"
          min={30}
          value={opacity}
          onChange={(e) => handleOpacityChange(e.value as number)}
          disabled={isReadOnly}
        />
        <span>{opacity}</span>
      </div>

      <div className="mt-5">
        <ColorsAndFonts
          lookAndFeel={style.lookAndFeel || {}}
          handleChange={handleColorsAndFontsChange}
          isReadOnly={isReadOnly}
        />
      </div>

      <div className="p-fluid mt-4">
        <div>{labels.css}</div>
        <InputTextarea
          placeholder={labels.cssPlaceholder}
          autoResize
          name="css"
          rows={4}
          value={css || ''}
          onChange={(e) => handleCssChange(e.target.value.trimStart())}
          onBlur={handleCssBlur}
          maxLength={2000}
          disabled={isReadOnly}
        />
      </div>

      <div className="p-fluid mt-4">
        <div>{labels.footerText}</div>
        <InputTextarea
          autoResize
          name="footer"
          rows={4}
          value={footer || ''}
          onChange={(e) => handleFooterChange(e.target.value.trimStart())}
          onBlur={handleFooterBlur}
          maxLength={2000}
          disabled={isReadOnly}
        />
      </div>
      {!isReadOnly && onSave && (
        <div className="flex justify-content-end mt-4">
          <Button label={labels.save} onClick={handleSave} />
        </div>
      )}
    </div>
  );
};
