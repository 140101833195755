import { useDispatch, useSelector } from 'react-redux';
import { bookingPagesActions, bookingPageSelectors, CLONE_MODAL, cloneModalActions } from '../../../store/bookingPages';
import { Modal } from '../../common';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import labels from './labels';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

export const BookingPageCloneModal = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingPageSelectors.selectCloneName);
  const isNameDuplicate = useSelector(bookingPageSelectors.selectIsCloneNameDuplicate);
  const isNameValid = useSelector(bookingPageSelectors.selectIsCloneNameValid);
  const isAdminOnly = useSelector(bookingPageSelectors.selectIsAdminOnly);

  const handleNameChange = (value: string) => {
    dispatch(bookingPagesActions.setCloneName(value));
  };

  const handleCancel = () => {
    dispatch(cloneModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(bookingPagesActions.cloneBookingPageRequest());
    dispatch(cloneModalActions.closeModal());
  };

  return (
    <Modal.Container name={CLONE_MODAL}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="w-25rem p-fluid">
        <div>{labels.name}</div>
        <InputText
          type="text"
          placeholder={labels.namePlaceholder}
          value={name}
          onChange={(e) => handleNameChange(e.target.value.trimStart())}
          className={(!isNameValid && 'p-invalid') || ''}
          maxLength={MAX_LENGTH_NAME}
        />
        {isNameDuplicate && <div className="text-red-500 mt-2">{labels.duplicate}</div>}
        {isAdminOnly && <div className=" mt-2">{labels.cloneNotification}</div>}
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined />
          <Button label={labels.save} onClick={handleSave} autoFocus disabled={!isNameValid} />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
