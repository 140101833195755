import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingPagesActions, bookingPageSelectors, deleteBookingPagesModalActions } from '../../store/bookingPages';
import { Path } from '../../routing';
import { Search, SectionTitle } from '../../components/common';
import { BookingPageCard } from '../../components/bookingPages/bookingPageCard/BookingPageCard';
import { BookingPageCloneModal } from '../../components/bookingPages/bookingPageCloneModal/BookingPageCloneModal';
import { BookingPageDeleteModal } from '../../components/bookingPages/bookingPageDeleteModal/BookingPageDeleteModal';
import labels from './labels';
import { Button } from 'primereact/button';
import { styles } from './styles';
import { Divider } from 'primereact/divider';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../types/constants';
import { userSettingsSelectors } from '../../store/userSettings';
import { BookingStats, CalendarWarning, InvitePeople } from '../../components/homePage/';
import { BookingPageAddToWebsiteModal } from '../../components/bookingPages/bookingPageAddToWebsiteModal/BookingPageAddToWebsiteModal';
import { authentificationSelectors } from '../../store/authentification';
import { navigationService } from '../../services/NavigationService';
import { usersSelectors } from '../../store/users';
import { Dropdown } from 'primereact/dropdown';
import { globalSelectors } from '../../store/global';
import { useUserNavigation } from '../../hooks/useUserNavigation';
import { BookingPageCardSkeleton } from '../../components/bookingPages/bookingPageCardSkeleton/BookingPageCardSkeleton';

export const BookingPages = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const isFetching = useSelector(bookingPageSelectors.selectIsFetching);
  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const bookingPages = useSelector(bookingPageSelectors.selectFilteredBookingPages);
  const personalBookingPages = useSelector(bookingPageSelectors.selectPersonalSearchedBookingPages);
  const sharedBookingPages = useSelector(bookingPageSelectors.selectSharedSearchedBookingPages);
  const searchString = useSelector(globalSelectors.selectSearchString);
  const selectedBookingPages = useSelector(bookingPageSelectors.selectSelectedBookingPages);
  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);
  const usersOptions = useSelector(usersSelectors.selectLoggedUsersOptions(labels.defaultFilter));
  const filterByUser = useSelector(bookingPageSelectors.selectFilterByUser);
  const allBookingPagesRead = useSelector(userSettingsSelectors.selectAllBookingPagesRead);
  const SKELETON_LENGTH = 2;

  useEffect(() => {
    if (isUserSettingsReceived && isLicenseActive) {
      dispatch(bookingPagesActions.getBookingPagesPageRequest(isInitialVisit));
    }
  }, [isUserSettingsReceived, isInitialVisit]);

  const handleCheckboxChange = (id: string, selected: boolean | undefined) => {
    selected
      ? dispatch(bookingPagesActions.selectBookingPage(id))
      : dispatch(bookingPagesActions.unselectBookingPage(id));
  };

  const handleNewBookingPage = () => {
    navigationService.navigateTo(Path.EditBookingPage.replace(':bookingPageId', 'new'));
  };

  const handleCancelSelection = () => {
    dispatch(bookingPagesActions.unselectAllBookingPage());
  };

  const handleOpenDeleteModal = () => {
    dispatch(deleteBookingPagesModalActions.openModal());
  };

  const handleFilterByUserChange = (value: string) => {
    dispatch(bookingPagesActions.setFilterByUser(value));
  };

  return (
    <div>
      <BookingPageCloneModal />
      <BookingPageDeleteModal />
      {/* <InstallChromeExtension /> */}
      {/* <StartEasyBooking /> */}
      <CalendarWarning />
      <InvitePeople />
      <BookingStats />
      <BookingPageAddToWebsiteModal />

      <div className="card flex justify-content-between align-items-center sumo-header-bg border-noround-bottom mb-0 py-2 line-height-4">
        <SectionTitle id="bookingPagesTitle" icon="pi-desktop" title={labels.title} />
        <div className="-my-2 p-accordion-header-text">
          <Button label={labels.newBookingPage} icon="pi pi-calendar-plus" onClick={handleNewBookingPage} />
        </div>
      </div>

      <div className="card border-noround-top">
        <div className="grid mb-2">
          {allBookingPagesRead && usersOptions.length > 1 && (
            <div className="col-12 sm:col-6 lg:col-4 mb-3 p-inputgroup">
              <Dropdown
                options={usersOptions}
                value={filterByUser}
                onChange={(e) => handleFilterByUserChange(e.target.value)}
              />
            </div>
          )}
          {bookingPages.length >= MIN_ITEMS_FOR_SEARCH && (
            <div className="col mb-3 p-inputgroup">
              <Search
                placeholder={labels.search}
                value={searchString}
                paths={[Path.BookingPages, Path.EditBookingPage]}
                maxTextLength={MAX_LENGTH_NAME}
              />
            </div>
          )}
        </div>
        {isFetching ? (
          <div className="grid -mb-2">
            {[...Array(SKELETON_LENGTH)].map((value, index) => (
              <div className="col-12 md:col-6 lg:col-4" key={index}>
                <BookingPageCardSkeleton />
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="grid -mb-2">
              {personalBookingPages.map((bookingPage) => (
                <div className="col-12 md:col-6 lg:col-4" key={bookingPage.id}>
                  <BookingPageCard bookingPage={bookingPage} handleCheckboxChange={handleCheckboxChange} />
                </div>
              ))}
            </div>
            {!!sharedBookingPages.length && (
              <div className="grid -mb-2">
                <div className="col-12 text-xl mt-2">{labels.sharedLabel}</div>
                {sharedBookingPages.map((bookingPage) => (
                  <div className="col-12 md:col-6 lg:col-4" key={bookingPage.id}>
                    <BookingPageCard bookingPage={bookingPage} handleCheckboxChange={handleCheckboxChange} />
                  </div>
                ))}
              </div>
            )}
            {!personalBookingPages.length && !sharedBookingPages.length && (
              <div className="mt-3">{labels.noResults}</div>
            )}
          </>
        )}
      </div>

      {selectedBookingPages.length > 0 && (
        <div style={styles.container}>
          <div style={styles.wrapper}>
            <div style={styles.selected}>
              <Button onClick={handleCancelSelection}>X</Button>
              <div className="pr-2">{`${selectedBookingPages.length} ${labels.selected}`}</div>
            </div>
            <Divider layout="vertical" />
            <Button onClick={handleOpenDeleteModal} icon="pi pi-trash" className="px-4" />
          </div>
        </div>
      )}
    </div>
  );
};
