export const ConnectIntegrationPage = Object.freeze({
  BENEFITS_TITLE: `Benefits`,
  DISCONNECT_DESCRIPTION: `Are you sure you would like to disconnect `,
  GOOGLE_CALENDAR_BENEFITS: `Automatically book and reschedule meetings on your Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `You need to grant access to your Google Calendar.`,
  GOOGLE_MEET_BENEFITS: `Automatically create Google Meet meetings at the time an event is scheduled. Instantly share unique conferencing details upon confirmation.`,
  GOOGLE_MEET_REQUIREMENTS: `Depending on your configuration, your Google Meet account administrator may be required to pre-approve SUMO1 in the Google Marketplace.`,
  MICROSOFT_CALENDAR_BENEFITS: `Automatically book and reschedule meetings on your Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `You need to grant access to your Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Automatically create Microsoft Teams meetings at the time an event is scheduled. Instantly share unique conferencing details upon confirmation.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Depending on your configuration, your Microsoft Teams account administrator may be required to pre-approve SUMO1 in the Microsoft Marketplace.`,
  REQUIREMENTS_TITLE: `Requirements`,
  ZOOM_BENEFITS: `Automatically create Zoom meetings at the time an event is scheduled. Instantly share unique conferencing details upon confirmation.`,
  ZOOM_REQUIREMENTS: `Depending on your configuration, your Zoom account administrator may be required to pre-approve SUMO1 in the Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Save more time by automating video conferencing, calendaring, and more.`,
  FILTER_ALL: `All`,
  FILTER_CALENDARS: `Calendars`,
  FILTER_VIDEO_CONFERENCE: `Video Conference`,
  GOOGLE_CALENDAR_DESC: `Automatically book and reschedule meetings on your Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MEET_DESC: `Automatically add Google Meet details to your meetings.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_CALENDAR_DESC: `Automatically book and reschedule meetings on your Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Automatically add Microsoft Teams details to your meetings.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `No Items found.`,
  REQUIREMENTS_TITLE: `Requirements`,
  TITLE: `Integration`,
  ZOOM_DESC: `Automatically add Zoom details in your SUMO meetings.`,
  ZOOM_TITLE: `Zoom Meeting`,
});
