import { combineReducers } from 'redux';
import { AccountsAction, AccountsActionTypes } from './actions';
import { GlobalAction, GlobalActionTypes } from '../../global/actions';
import { DEFAULT_ACCOUNT } from './constants';
import { Account } from '../../../API';

const accounts = (state: Array<Account> = [], action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.GET_ACCOUNTS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const account = (state = DEFAULT_ACCOUNT, action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_SUCCESS:
      return action.payload;
    case AccountsActionTypes.UPDATE_ACCOUNT:
      return { ...state, ...action.payload };
    case AccountsActionTypes.GET_ACCOUNTS_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_ACCOUNT;
    default:
      return state;
  }
};

const hasUpdates = (state: boolean = false, action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.UPDATE_ACCOUNT:
      return true;
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_REQUEST:
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_SUCCESS:
    case AccountsActionTypes.SAVE_ACCOUNT_DETAILS_SUCCESS:
    case AccountsActionTypes.DELETE_ACCOUNT_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const mergeSearchString = (state: string = '', action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.SET_MERGE_SEARCH_STRING:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const selectedAccountId = (state: string = '', action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.SELECT_ACCOUNT:
      return action.payload;
    case AccountsActionTypes.MERGE_ACCOUNTS_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const mergeIds = (state: string[] = [], action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.SET_MERGE_ACCOUNT_IDS:
      return action.payload;
    case AccountsActionTypes.MERGE_ACCOUNTS_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.GET_ACCOUNTS_REQUEST:
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_REQUEST:
    case AccountsActionTypes.SAVE_ACCOUNT_DETAILS_REQUEST:
    case AccountsActionTypes.MERGE_ACCOUNTS_REQUEST:
    case AccountsActionTypes.DELETE_ACCOUNT_REQUEST:
      return true;
    case AccountsActionTypes.GET_ACCOUNTS_SUCCESS:
    case AccountsActionTypes.GET_ACCOUNTS_FAIL:
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_SUCCESS:
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_FAIL:
    case AccountsActionTypes.SAVE_ACCOUNT_DETAILS_SUCCESS:
    case AccountsActionTypes.SAVE_ACCOUNT_DETAILS_FAIL:
    case AccountsActionTypes.MERGE_ACCOUNTS_SUCCESS:
    case AccountsActionTypes.MERGE_ACCOUNTS_FAIL:
    case AccountsActionTypes.DELETE_ACCOUNT_SUCCESS:
    case AccountsActionTypes.DELETE_ACCOUNT_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: AccountsAction | GlobalAction) => {
  switch (action.type) {
    case AccountsActionTypes.GET_ACCOUNTS_FAIL:
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_FAIL:
    case AccountsActionTypes.SAVE_ACCOUNT_DETAILS_FAIL:
    case AccountsActionTypes.MERGE_ACCOUNTS_FAIL:
      return action.error;
    case AccountsActionTypes.GET_ACCOUNTS_REQUEST:
    case AccountsActionTypes.GET_ACCOUNT_DETAILS_REQUEST:
    case AccountsActionTypes.SAVE_ACCOUNT_DETAILS_REQUEST:
    case AccountsActionTypes.MERGE_ACCOUNTS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  accounts,
  account,
  hasUpdates,
  mergeSearchString,
  selectedAccountId,
  mergeIds,
  isFetching,
  error,
});
