import { IntegrationType } from '../../../API';
import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  connect: t('Common:CONNECT'),
  disconnect: t('Common:DISCONNECT'),
  benefitsTitle: t('ConnectIntegrationPage:BENEFITS_TITLE'),
  requirementsTitle: t('ConnectIntegrationPage:REQUIREMENTS_TITLE'),
  benefitsDesc: {
    [IntegrationType.GOOGLE_CALENDAR]: t('ConnectIntegrationPage:GOOGLE_CALENDAR_BENEFITS'),
    [IntegrationType.GOOGLE_MEET]: t('ConnectIntegrationPage:GOOGLE_MEET_BENEFITS'),
    [IntegrationType.MICROSOFT_CALENDAR]: t('ConnectIntegrationPage:MICROSOFT_CALENDAR_BENEFITS'),
    [IntegrationType.MICROSOFT_TEAMS]: t('ConnectIntegrationPage:MICROSOFT_TEAMS_BENEFITS'),
    [IntegrationType.ZOOM]: t('ConnectIntegrationPage:ZOOM_BENEFITS'),
  },
  requirementsDesc: {
    [IntegrationType.GOOGLE_CALENDAR]: t('ConnectIntegrationPage:GOOGLE_CALENDAR_REQUIREMENTS'),
    [IntegrationType.GOOGLE_MEET]: t('ConnectIntegrationPage:GOOGLE_MEET_REQUIREMENTS'),
    [IntegrationType.MICROSOFT_CALENDAR]: t('ConnectIntegrationPage:MICROSOFT_CALENDAR_REQUIREMENTS'),
    [IntegrationType.MICROSOFT_TEAMS]: t('ConnectIntegrationPage:MICROSOFT_TEAMS_REQUIREMENTS'),
    [IntegrationType.ZOOM]: t('ConnectIntegrationPage:ZOOM_REQUIREMENTS'),
  },
};
