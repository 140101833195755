import { combineReducers } from 'redux';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { TeamsAction, TeamsActionTypes } from './actions';
import { TeamRecord } from './types';

const teams = (state: TeamRecord[] = [], action: TeamsAction | GlobalAction) => {
  switch (action.type) {
    case TeamsActionTypes.GET_TEAMS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const team = (state = {} as TeamRecord, action: TeamsAction | GlobalAction) => {
  switch (action.type) {
    case TeamsActionTypes.SET_SELECTED_TEAM:
      return action.payload;
    case TeamsActionTypes.UPDATE_SELECTED_TEAM:
      return { ...state, ...action.payload };
    case TeamsActionTypes.GET_TEAMS_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as TeamRecord;
    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: TeamsAction | GlobalAction) => {
  switch (action.type) {
    case TeamsActionTypes.GET_TEAMS_REQUEST:
    case TeamsActionTypes.GET_TEAMS_PAGE_REQUEST:
    case TeamsActionTypes.SAVE_TEAM_REQUEST:
    case TeamsActionTypes.DELETE_TEAM_REQUEST:
    case TeamsActionTypes.DEACTIVATE_TEAM_REQUEST:
      return true;
    case TeamsActionTypes.GET_TEAMS_SUCCESS:
    case TeamsActionTypes.GET_TEAMS_FAIL:
    case TeamsActionTypes.SAVE_TEAM_SUCCESS:
    case TeamsActionTypes.SAVE_TEAM_FAIL:
    case TeamsActionTypes.DELETE_TEAM_SUCCESS:
    case TeamsActionTypes.DELETE_TEAM_FAIL:
    case TeamsActionTypes.DEACTIVATE_TEAM_SUCCESS:
    case TeamsActionTypes.DEACTIVATE_TEAM_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: TeamsAction | GlobalAction) => {
  switch (action.type) {
    case TeamsActionTypes.GET_TEAMS_FAIL:
    case TeamsActionTypes.SAVE_TEAM_FAIL:
    case TeamsActionTypes.DELETE_TEAM_FAIL:
    case TeamsActionTypes.DEACTIVATE_TEAM_FAIL:
      return action.error;
    case TeamsActionTypes.GET_TEAMS_PAGE_REQUEST:
    case TeamsActionTypes.GET_TEAMS_REQUEST:
    case TeamsActionTypes.SAVE_TEAM_REQUEST:
    case TeamsActionTypes.DELETE_TEAM_REQUEST:
    case TeamsActionTypes.DEACTIVATE_TEAM_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ isFetching, error, teams, team });
