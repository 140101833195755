import { Editor } from 'primereact/editor';
import { useMountEffect } from 'primereact/hooks';
import { CSSProperties, useState } from 'react';
import { Preloader } from '../preloader/Preloader';

type TextEditorProps = {
  value: string;
  setValue: (newValue: string) => void;
  className?: string;
  style?: CSSProperties;
};

export const TextEditor = ({ value, setValue, className, style }: TextEditorProps) => {
  const [hidden, setHidden] = useState(true);

  useMountEffect(() => {
    setHidden(true);
  });
  const headerTemplate = () => {
    return (
      <>
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
          <button className="ql-strike" aria-label="Strike"></button>
        </span>

        <span className="ql-formats">
          <button className="ql-list" value="ordered" type="button"></button>
          <button className="ql-list" value="bullet" type="button"></button>
        </span>

        {/* <span className="ql-formats">
          <button className="ql-align" value="" type="button"></button>
          <button className="ql-align" value="right" type="button"></button>
          <button className="ql-align" value="center" type="button"></button>
          <button className="ql-align" value="justify" type="button"></button>
        </span>

        <span className="ql-formats">
          <button className="ql-clean" aria-label="Remove Styles" type="button"></button>
        </span> */}
      </>
    );
  };
  const header = headerTemplate();

  return (
    <>
      {hidden && (
        <>
          <Preloader />
          <div className={className} style={style}></div>
        </>
      )}
      <Editor
        className={className}
        style={style}
        headerTemplate={header}
        hidden={hidden}
        onLoad={() => setHidden(false)}
        value={value}
        onTextChange={(e) => setValue(e.htmlValue || '')}
      />
    </>
  );
};
