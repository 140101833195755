import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DateRangeType, TimeUnit } from '../../../../../API';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  SCHEDULE_BUFFER_OPTIONS,
} from '../../../../../store/bookingTemplates';
import labels from './labels';
import { bookingPageSelectors, bookingPagesActions } from '../../../../../store/bookingPages';
import i18n from '../../../../../i18n/i18n';

type DateRangeSettingsProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const DateRangeSettings = ({ selectors, actions, isReadOnly }: DateRangeSettingsProps) => {
  const dispatch = useDispatch();
  const dateRangeType = useSelector(selectors.selectDateRangeType);
  const dateRangeCount = useSelector(selectors.selectDateRangeCount);
  const dateRangeFrom = useSelector(selectors.selectDateRangeFrom);
  const dateRangeTo = useSelector(selectors.selectDateRangeTo);
  const scheduleBufferCount = useSelector(selectors.selectScheduleBufferCount);
  const scheduleBufferTimeUnit = useSelector(selectors.selectScheduleBufferTimeUnit);
  const isDateRangeCountValid = useSelector(selectors.selectIsDateRangeCountValid);
  const isDateRangeFromValid = useSelector(selectors.selectIsDateRangeFromValid);
  const isDateRangeToValid = useSelector(selectors.selectIsDateRangeToValid);
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const handleDateRangeTypeChange = (dateRangeType: DateRangeType) => {
    dispatch(actions.updateWhenDateRange({ type: dateRangeType }));
  };

  const handleDateRangeCountChange = (value: number | null | undefined) => {
    dispatch(actions.updateWhenDateRange({ count: value }));
  };

  const handleDateRangeFromChange = (value: Date) => {
    dispatch(actions.updateWhenDateRange({ from: dayjs(value).format() }));
  };

  const handleDateRangeToChange = (value: Date) => {
    dispatch(actions.updateWhenDateRange({ to: dayjs(value).format() }));
  };

  const handleScheduleBufferCountChange = (value: number | null | undefined) => {
    dispatch(actions.updateWhenScheduleBuffer({ count: value }));
  };

  const handleScheduleBufferTimeUnitChange = (timeUnit: TimeUnit) => {
    dispatch(actions.updateWhenScheduleBuffer({ timeUnit }));
  };

  return (
    <div>
      <div className="grid p-fluid">
        <div className="col-12 md:col-8">
          <div className="mb-3 font-bold">{labels.inviteesCanSchedule}</div>
          <div className="field-radiobutton mb-0 h-3rem">
            <RadioButton
              inputId="daysInFutureOption"
              value={DateRangeType.DAYS_IN_FUTURE}
              checked={dateRangeType === DateRangeType.DAYS_IN_FUTURE}
              onChange={(e) => handleDateRangeTypeChange(e.target.value as DateRangeType)}
              disabled={isReadOnly}
            />
            {dateRangeType === DateRangeType.DAYS_IN_FUTURE && (
              <InputNumber
                className={`w-7rem ml-2 ${!isDateRangeCountValid && 'p-invalid'}`}
                showButtons
                min={1}
                max={365}
                value={dateRangeCount}
                onValueChange={(e) => handleDateRangeCountChange(e.target.value)}
                disabled={isReadOnly}
              ></InputNumber>
            )}
            <label htmlFor="daysInFutureOption" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
              {labels.radioGroupDaysInFutureOption}
            </label>
          </div>
          <div className="field-radiobutton mb-0 h-3rem">
            <RadioButton
              inputId="specificDatesOption"
              value={DateRangeType.SPECIFIC_DATES}
              checked={dateRangeType === DateRangeType.SPECIFIC_DATES}
              onChange={(e) => handleDateRangeTypeChange(e.target.value as DateRangeType)}
              disabled={isReadOnly}
            />
            <label htmlFor="specificDatesOption" className="ml-0 py-1 pl-2 cursor-pointer hover:text-primary">
              {labels.radioGroupSpecificDatesOption}
            </label>
            {dateRangeType === DateRangeType.SPECIFIC_DATES && (
              <>
                <span className="ml-1 mr-2">{labels.from}</span>
                <Calendar
                  className={`w-10rem ${!isDateRangeFromValid && 'p-invalid'}`}
                  showIcon
                  locale={i18n.language}
                  minDate={new Date()}
                  maxDate={dateRangeTo}
                  value={dateRangeFrom}
                  onChange={(e) => handleDateRangeFromChange(e.target.value as Date)}
                  disabled={isReadOnly}
                ></Calendar>
                <span className="mx-2">{labels.to}</span>
                <Calendar
                  className={`w-10rem ${!isDateRangeToValid && 'p-invalid'}`}
                  showIcon
                  locale={i18n.language}
                  minDate={dateRangeFrom}
                  maxDate={oneYearFromNow}
                  value={dateRangeTo}
                  onChange={(e) => handleDateRangeToChange(e.target.value as Date)}
                  disabled={isReadOnly}
                ></Calendar>
              </>
            )}
          </div>
          <div className="field-radiobutton mb-0 h-3rem">
            <RadioButton
              inputId="unlimitedOption"
              value={DateRangeType.UNLIMITED}
              checked={dateRangeType === DateRangeType.UNLIMITED}
              onChange={(e) => handleDateRangeTypeChange(e.target.value as DateRangeType)}
              disabled={isReadOnly}
            />
            <label htmlFor="unlimitedOption" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
              {labels.radioGroupUnlimitedOption}
            </label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="grid">
            <div className="col-12 font-bold">{labels.inviteesCanNotSchedule}</div>
            <div className="col-6">
              <InputNumber
                id="inputduration"
                showButtons
                min={0}
                max={999}
                value={scheduleBufferCount}
                onValueChange={(e) => handleScheduleBufferCountChange(e.target.value)}
                disabled={isReadOnly}
              ></InputNumber>
            </div>
            <div className="col-6">
              <Dropdown
                value={scheduleBufferTimeUnit}
                onChange={(e) => handleScheduleBufferTimeUnitChange(e.target.value)}
                options={SCHEDULE_BUFFER_OPTIONS}
                optionLabel="label"
                disabled={isReadOnly}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DateRangeSettings.displayName = 'DateRangeSettings';
