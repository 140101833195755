import { createSelector } from 'reselect';
import { State } from '../../rootStore';
import dayjs from 'dayjs';
import { ORG_STATUS_VALUES, ORG_TYPE_OPTIONS, TERM_FILTER_VALUES } from './constants';
import { LicenseType, TenantStatus, OrgType } from '../../../API';
import { opsConsoleSelectors } from '../common/selectors';
import { getCurrencyLabelByCode } from '../../billing';

const orgsState = (state: State) => state.OPSConsoleOrgs;

const selectIsFetching = createSelector(orgsState, (state) => state.isFetching);
const selectError = createSelector(orgsState, (state) => state.error);
const selectOrgs = createSelector(orgsState, (state) => state.orgs);
const selectOrgDetails = createSelector(orgsState, (state) => state.orgDetails);
const selectLicense = createSelector(orgsState, (state) => state.license);
const selectIsEdited = createSelector(orgsState, (state) => state.isEdited);
const selectFilter = createSelector(orgsState, (state) => state.filter);
const selectFilterStatus = createSelector(selectFilter, (filter) => filter.status);
const selectFilterTerm = createSelector(selectFilter, (filter) => filter.term);

const selectSearchOrgs = createSelector(
  selectOrgs,
  opsConsoleSelectors.selectSearchString,
  selectFilterStatus,
  selectFilterTerm,
  (orgs, searchString, filterStatus, filterTerm) =>
    orgs
      .filter(
        (org) =>
          (filterStatus === ORG_STATUS_VALUES.All || org.status === filterStatus) &&
          (filterTerm === TERM_FILTER_VALUES.All || org.term === filterTerm) &&
          (org.accountName?.toLowerCase().includes(searchString.trim().toLowerCase()) ||
            org.tenantId.toLowerCase().includes(searchString.trim().toLowerCase()) ||
            org.userEmails.some(email => email.includes(searchString.trim().toLowerCase())))
      )
      .sort((orgA, orgB) => (orgA.createdAt < orgB.createdAt ? 1 : -1))
);

const selectTenantDetails = createSelector(selectOrgDetails, (details) => details.tenantRecord);
const selectTenantId = createSelector(selectTenantDetails, (tenant) => tenant?.tenantId);
const selectTenantName = createSelector(selectTenantDetails, (tenant) => tenant?.name);
const selectTenantType = createSelector(selectTenantDetails, (tenant) => tenant?.type);
const selectTenantStatus = createSelector(selectTenantDetails, (tenant) => tenant?.status);
const selectTenantIsActive = createSelector(selectTenantStatus, (status) => status === TenantStatus.ACTIVE);
const selectTenantIsTrial = createSelector(selectTenantType, (type) => type === OrgType.TRIAL);
const selectTenantNote = createSelector(selectTenantDetails, (tenant) => tenant?.note || '');
const selectAmountPerUser = createSelector(
  selectTenantDetails,
  (tenant) =>
    getCurrencyLabelByCode(tenant?.subscription?.currency_code) +
    (tenant?.subscription?.subscription_items[0]?.unit_price / 100 || 0)
);

const selectLicensesDetails = createSelector(selectOrgDetails, (details) => details.licenseRecords);
const selectLicenseId = createSelector(selectLicense, (license) => license.id);
const selectStartDate = createSelector(selectLicense, (license) => dayjs(license?.startDate).toDate());
const selectEndDate = createSelector(selectLicense, (license) => dayjs(license?.endDate).toDate());
const selectLicenseDuration = createSelector(selectStartDate, selectEndDate, (startDate, endDate) =>
  dayjs(endDate).diff(startDate, 'day')
);
const selectRolesDetails = createSelector(selectOrgDetails, (details) => details.roleRecords);
const selectDaysRemaining = createSelector(selectEndDate, (endDate) =>
  dayjs(endDate).diff(dayjs().format('YYYY-MM-DD'), 'day').toString()
);
const selectLicenseAssigned = createSelector(selectLicense, (license) => license.assigned || 0);

const selectWeeklyAdoptionPercent = createSelector( selectOrgDetails, (org) => org.percentOfWeekAdoption
);
const selectMonthlyAdoptionPercent = createSelector( selectOrgDetails, (org) => org.percentOfMonthAdoption
);
const selectLicenseTerm = createSelector(selectLicense, (license) => license?.type || LicenseType.TRIAL);

const selectAccountId = createSelector(selectOrgDetails, (details) => details.accountId);

const selectBookedMeetings = createSelector(selectOrgDetails, (details) => '' + (details.bookedMeetings || 0));

const selectOrgStatus = createSelector(
  selectTenantIsActive,
  selectDaysRemaining,
  selectLicenseTerm,
  (tenantIsActive, daysRemaining, licenseTerm) =>
    (licenseTerm === LicenseType.TRIAL && +daysRemaining <= 0) || !tenantIsActive
      ? ORG_STATUS_VALUES.Expired
      : licenseTerm !== LicenseType.TRIAL && +daysRemaining <= 0
      ? ORG_STATUS_VALUES.PaymentLate
      : ORG_STATUS_VALUES.Active
);

const selectOrgTypeDesc = createSelector(
  selectTenantType,
  (orgType) => ORG_TYPE_OPTIONS.find((item) => item.value === orgType)?.label || ''
);

const selectIsPaginatorEnabled = createSelector(selectSearchOrgs, (orgs) => orgs.length > 10);
const selectPayLaterDetails = createSelector(orgsState, (state) => state.payLaterDetails);
const selectPayLaterEmail = createSelector(selectPayLaterDetails, (payLater) => payLater.email);
const selectPayLaterTerm = createSelector(selectPayLaterDetails, (payLater) => payLater.term);
const selectPayLaterQuantity = createSelector(selectPayLaterDetails, (payLater) => payLater.quantity);

// TODO: rename to OPSConsoleOrgsSelectors
export const orgsSelectors = {
  selectIsFetching,
  selectError,
  selectOrgs,
  selectOrgDetails,
  selectLicense,
  selectIsEdited,
  selectFilterStatus,
  selectFilterTerm,
  selectSearchOrgs,

  selectTenantDetails,
  selectTenantId,
  selectTenantName,
  selectTenantType,
  selectTenantIsActive,
  selectTenantIsTrial,
  selectTenantNote,
  selectAmountPerUser,

  selectOrgStatus,
  selectOrgTypeDesc,

  selectLicensesDetails,
  selectLicenseId,
  selectLicenseDuration,
  selectRolesDetails,
  selectStartDate,
  selectEndDate,
  selectDaysRemaining,

  selectAccountId,
  selectBookedMeetings,

  selectWeeklyAdoptionPercent,
  selectMonthlyAdoptionPercent,
  selectIsPaginatorEnabled,
  selectLicenseAssigned,
  selectLicenseTerm,

  selectPayLaterEmail,
  selectPayLaterTerm,
  selectPayLaterQuantity,
};
