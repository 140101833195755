import { createSelector } from 'reselect';
import { State } from '../../rootStore';
import { AccountOption } from './types';
import { DateFormat } from '../../../store/userSettings';
import dayjs from 'dayjs';
import { opsConsoleSelectors } from '../common/selectors';

const accountsState = (state: State) => state.OPSConsoleAccounts;

const selectIsFetching = createSelector(accountsState, (state) => state.isFetching);
const selectError = createSelector(accountsState, (state) => state.error);
const selectAccounts = createSelector(accountsState, (state) => state.accounts);
const selectAccount = createSelector(accountsState, (state) => state.account);
const selectHasUpdates = createSelector(accountsState, (state) => state.hasUpdates);
const selectMergeSearchString = createSelector(accountsState, (state) => state.mergeSearchString);
const selectSelectedAccountId = createSelector(accountsState, (state) => state.selectedAccountId);
const selectMergeIds = createSelector(accountsState, (state) => state.mergeIds);

const selectMergeAccountsOptions = createSelector(selectAccounts, (accounts) =>
  accounts.map((account) => ({ name: account.name, id: account.id } as AccountOption))
);

const selectFilteredAccounts = createSelector(selectAccounts, opsConsoleSelectors.selectSearchString, (accounts, searchString) =>
  searchString.trim().length
    ? accounts.filter((account) => account?.name?.toLowerCase().includes(searchString.trim().toLowerCase()))
    : accounts
);

const selectFilteredMergeAccountsOptions = createSelector(
  selectMergeAccountsOptions,
  selectMergeSearchString,
  selectSelectedAccountId,
  selectMergeIds,
  (accounts, searchString, accountId, mergeIds) => {
    const excludeIds = [accountId, ...mergeIds];
    const filteredAccounts = accounts.filter((account) => !excludeIds.includes(account.id));

    return searchString.trim().length
      ? filteredAccounts.filter((account) => account?.name?.toLowerCase().includes(searchString.trim().toLowerCase()))
      : filteredAccounts;
  }
);

const selectSelectedMergeAccountsOptions = createSelector(
  selectMergeAccountsOptions,
  selectMergeIds,
  (accounts, mergeIds) => accounts.filter((account) => mergeIds.includes(account.id))
);

const selectIsMergeButtonDisabled = createSelector(selectMergeIds, (mergeIds) => !mergeIds.length);
const selectIsPaginatorEnabled = createSelector(selectFilteredAccounts, (accounts) => accounts.length > 10);

const selectAccountId = createSelector(selectAccount, (account) => account.id || '');
const selectAccountName = createSelector(selectAccount, (account) => account.name || '');
const selectAccountNote = createSelector(selectAccount, (account) => account.note || '');
const selectAccountCreatedBy = createSelector(selectAccount, (account) => account.createdBy || '');
const selectAccountCreatedAt = createSelector(selectAccount, (account) => account.createdAt);
const selectAccountCreatedAtFormatted = createSelector(
  selectAccountCreatedAt,
  selectIsFetching,
  (createdAt, isFetching) => (isFetching ? '' : dayjs(createdAt).format(DateFormat.default))
);
const selectAccountTenants = createSelector(selectAccount, (account) => account.tenants || []);
const selectIsTenantsPaginatorEnabled = createSelector(selectAccountTenants, (tenants) => tenants.length > 10);
const selectIsAccountHasTenants = createSelector(selectAccountTenants, (tenants) => !!tenants.length);

const selectSelectedAccountName = createSelector(
  selectAccounts,
  selectSelectedAccountId,
  selectAccountName,
  (accounts, accountId, accountName) => accounts.find((account) => account.id === accountId)?.name || accountName
);

const selectIsAccountNameDuplicate = createSelector(
  selectAccounts,
  selectAccountName,
  selectAccountId,
  (accounts, accountName, accountId) =>
    accounts.some((item) => item.id !== accountId && item.name?.toLowerCase() === accountName.toLowerCase())
);

const selectIsAccountNameValid = createSelector(
  selectAccountName,
  selectIsAccountNameDuplicate,
  (accountName, isDuplicate) => Boolean(accountName) && !isDuplicate
);

const selectIsSaveAvailable = createSelector(
  selectHasUpdates,
  selectIsAccountNameValid,
  (hasUpdates, isAccountNameValid) => hasUpdates && isAccountNameValid
);

const selectAccountOptions = createSelector(selectAccounts, (accounts) =>
  accounts.map((account) => ({ label: account.name, value: account.id }))
);

const selectAccountByAccountId = (accountId: string) => createSelector(selectAccounts, (accounts) =>
  accounts.find(account => account.id === accountId)
);
const selectAccountByTenantId = (tenantId: string) => createSelector(selectAccounts, (accounts) =>
  accounts.find(account => account.tenantIds?.includes(tenantId))
);

export const accountsSelectors = {
  selectIsFetching,
  selectError,
  selectAccounts,
  selectAccount,
  selectMergeSearchString,
  selectSelectedAccountId,
  selectMergeIds,
  selectFilteredAccounts,
  selectFilteredMergeAccountsOptions,
  selectSelectedMergeAccountsOptions,
  selectIsMergeButtonDisabled,
  selectIsPaginatorEnabled,
  selectAccountName,
  selectAccountNote,
  selectAccountCreatedBy,
  selectAccountCreatedAt,
  selectAccountCreatedAtFormatted,
  selectAccountTenants,
  selectIsTenantsPaginatorEnabled,
  selectIsAccountHasTenants,
  selectSelectedAccountName,
  selectIsAccountNameDuplicate,
  selectIsSaveAvailable,

  selectAccountOptions,
  selectAccountByAccountId,
  selectAccountByTenantId,
};
