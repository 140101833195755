import { createSelector } from 'reselect';
import { ModalName } from './typings';
import { State } from '../rootStore';

const modalsState = (state: State) => state.modals;

const selectIsModalOpen = (key: ModalName) => createSelector(modalsState, (modals) => modals[key]?.open || false);
const selectModalPayload = (key: ModalName) => createSelector(modalsState, (modals) => modals[key]?.payload);

export const modalsSelectors = {
  selectIsModalOpen,
  selectModalPayload,
};

export const modalsSelectorsFactory = (key: ModalName) => ({
  selectIsModalOpen: selectIsModalOpen(key),
  selectModalPayload: selectModalPayload(key),
});
