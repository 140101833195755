import { combineReducers } from 'redux';
import { ToastNotification } from './typings';
import { NotificationsActionTypes, NotificationsAction } from './actions';
import { GlobalAction, GlobalActionTypes } from '../global/actions';

const toastNotificationsQueue = (state: ToastNotification[] = [], action: NotificationsAction | GlobalAction) => {
  switch (action.type) {
    case NotificationsActionTypes.ADD_TOAST_TO_QUEUE:
      return [...state, action.payload];
    case NotificationsActionTypes.REMOVE_TOAST_FROM_QUEUE:
      return state.filter((toast) => toast.id !== action.payload);
    // case GlobalActionTypes.RESET_WHOLE_STORE:
    //   return [];
    default:
      return state;
  }
};

export default combineReducers({ toastNotificationsQueue });
