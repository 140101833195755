import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import labels from './labels';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { UserSettingsKeys } from '../../../store/userSettings';
import { Sidebar } from 'primereact/sidebar';
import './styles.css';
import { authentificationActions, authentificationSelectors } from '../../../store/authentification';

export const CookieConsent = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(authentificationSelectors.selectIsCookieOpen);

  const [cookies, setCookies] = useState({
    necessary: true,
    performance: true,
    functional: true,
    targeting: true,
  });

  const handleChange = (event: CheckboxChangeEvent) => {
    setCookies({ ...cookies, [event.target.value]: event.target.checked });
  };

  const handleNecessaryOnly = () => {
    setCookies({
      necessary: true,
      performance: false,
      functional: false,
      targeting: false,
    });
    handleClose();
  };

  const handleClose = () => {
    dispatch(authentificationActions.setCookieOpen(false));
    localStorage.setItem(UserSettingsKeys.COOKIES, JSON.stringify(cookies));
  };

  // do not open cookie automatically in v1, used only in account
  // useEffect(() => {
  //   const storedCookies = localStorage.getItem(UserSettingsKeys.COOKIES);
  //   if (!storedCookies) {
  //     dispatch(authentificationActions.setCookieOpen(true));
  //   } else {
  //     setCookies(JSON.parse(storedCookies));
  //   }
  // }, []);

  return (
    <div>
      <Sidebar
        visible={isOpen}
        position="bottom"
        dismissable={false}
        showCloseIcon={false}
        blockScroll={true}
        baseZIndex={1000000}
        onHide={handleClose}
        maskClassName="p-sidebar-mask"
        className="custom-sidebar-mask"
      >
        <div className="text-2xl md:text-3xl mb-3">{labels.manageTitle}</div>
        <div className="flex flex-column md:flex-row gap-4 mb-4">
          <div className="flex-1">
            <p>{labels.descriptionFirst}</p>
            <p>{labels.descriptionSecond}</p>
            <p>{labels.descriptionThird}</p>
          </div>
          <div>
            <div className="justify-content-end field-checkbox font-semibold">
              <label htmlFor="necessary">{labels.strictlyName}</label>
              <Checkbox
                inputId="necessary"
                name="option"
                value="necessary"
                checked={cookies.necessary}
                onChange={handleChange}
                disabled={true}
                className="ml-3"
              />
            </div>
            <div className="justify-content-end field-checkbox font-semibold">
              <label htmlFor="performance">{labels.performanceName}</label>
              <Checkbox
                inputId="performance"
                name="option"
                value="performance"
                checked={cookies.performance}
                onChange={handleChange}
                className="ml-3"
              />
            </div>
            <div className="justify-content-end field-checkbox font-semibold">
              <label htmlFor="functional">{labels.functionalName}</label>
              <Checkbox
                inputId="functional"
                name="option"
                value="functional"
                checked={cookies.functional}
                onChange={handleChange}
                className="ml-3"
              />
            </div>
            <div className="justify-content-end field-checkbox font-semibold">
              <label htmlFor="targeting">{labels.targetingName}</label>
              <Checkbox
                inputId="targeting"
                name="option"
                value="targeting"
                checked={cookies.targeting}
                onChange={handleChange}
                className="ml-3"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-content-end gap-4">
          <Button text label={labels.necessaryOnly} onClick={handleNecessaryOnly}></Button>
          <Button label={labels.agreeClose} onClick={handleClose}></Button>
        </div>
      </Sidebar>
    </div>
  );
};
