import { Button, ButtonProps } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';

type ConfirmationModalProps = {
  visible: boolean;
  title: string;
  description?: string;
  additionalText?: string[];
  confirmButtonLabel?: string;
  cancelButtonLabel: string;
  onConfirm?: () => void;
  onCancel: () => void;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  onClose: () => void;
  CustomConfirmButton?: ReactNode;
  modalClassName?: string;
  ExtraElements?: ReactNode;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible,
  title,
  description,
  additionalText,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  confirmButtonProps,
  cancelButtonProps,
  onClose,
  CustomConfirmButton,
  modalClassName,
  ExtraElements,
}) => {
  return (
    <Dialog
      visible={visible}
      onHide={onClose}
      header={<div className="text-3xl">{title}</div>}
      footer={
        <div>
          <Button onClick={onCancel} outlined className="w-6rem" label={cancelButtonLabel} {...cancelButtonProps} />
          {CustomConfirmButton ||
            (confirmButtonLabel && (
              <Button
                severity="danger"
                className="w-6rem ml-3"
                label={confirmButtonLabel}
                {...confirmButtonProps}
                onClick={onConfirm}
              />
            ))}
        </div>
      }
      resizable={false}
      focusOnShow={false}
      className={modalClassName || 'w-25rem'}
    >
      <div className="font-bold">{description}</div>
      {additionalText &&
        additionalText.map((text, index) => (
          <div className={`${index === 0 ? 'mt-2' : ''}`} key={index}>
            {text}
          </div>
        ))}
      {ExtraElements}
    </Dialog>
  );
};
