import { API } from 'aws-amplify';
import {
  ENCRYPTED_USER_ID,
  ROLE_DEACTIVATED_ERROR_TOAST,
  SWITCH_ACCOUNT_ERROR_TOAST,
  TRIAL_LIMIT_ERROR_TOAST,
  USER_DEACTIVATED_ERROR_TOAST,
  authentificationActions, USER_CONTEXT_PARAM
} from "../authentification";
import { ToastNotificationOptions, notificationsActions } from '../notifications';
import { put } from 'redux-saga/effects';
import { DELETE_ROLE_ASSIGNED_ERROR_TOAST } from '../roles';
import { SERVER_ERROR_CODES } from '../../types/constants';
import { errorActions } from '../error/actions';
import { ErrorCodes } from '../error/types';
import { Path } from '../../routing';

export const APIPostRequest = async (path: string, parameters: { [key: string]: any }) => {
  const requestBody = { ...parameters };

  const encryptedUserId = localStorage.getItem(ENCRYPTED_USER_ID);
  if (encryptedUserId && !path.includes('/opsconsole/')) {
    requestBody.encryptedUserId = encryptedUserId;
  }
  requestBody[USER_CONTEXT_PARAM] = localStorage.getItem(USER_CONTEXT_PARAM) || '';
  const result = await API.post('publicApi', path, {
    body: requestBody,
  });
  if (result[USER_CONTEXT_PARAM]) {
    localStorage.setItem(USER_CONTEXT_PARAM, result[USER_CONTEXT_PARAM]);
  }
  return result;
};

export function* handleServiceError(error: unknown, toast: ToastNotificationOptions, isGlobalError?: boolean) {
  const errorMessage = error?.toString() || '';
  if (errorMessage.includes(SERVER_ERROR_CODES.LicenseExpired)) {
    // expire license
    yield put(authentificationActions.expireLicense());
  } else if (errorMessage.includes(SERVER_ERROR_CODES.RoleDeactivated)) {
    // role deactivated
    yield put(authentificationActions.logoutUserRequest(Path.Landing));
    yield put<any>(notificationsActions.showToast(ROLE_DEACTIVATED_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.UserDeactivate)) {
    // user deactivated
    yield put(authentificationActions.logoutUserRequest(Path.Landing));
    yield put<any>(notificationsActions.showToast(USER_DEACTIVATED_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.DeleteRole)) {
    // try to delete role with users assigned
    yield put<any>(notificationsActions.showToast(DELETE_ROLE_ASSIGNED_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.TrialLimit)) {
    // trial limit
    yield put<any>(notificationsActions.showToast(TRIAL_LIMIT_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.SwitchAccount)) {
    // switch account
    yield put<any>(notificationsActions.showToast(SWITCH_ACCOUNT_ERROR_TOAST));
  } else {
    if (isGlobalError) {
      if (errorMessage.includes(SERVER_ERROR_CODES.Forbidden)) {
        yield put(errorActions.setTheError(ErrorCodes.CODE_403));
      } else if (errorMessage.includes(SERVER_ERROR_CODES.ServerError)) {
        yield put(errorActions.setTheError(ErrorCodes.CODE_500));
      } else {
        yield put(errorActions.setTheError(ErrorCodes.CODE_404));
      }
    }
    yield put<any>(notificationsActions.showToast(toast));
  }
}
