export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `The following users are assigned to use only this workspace`,
  NOTES: `You must first assign these users to another workspace, before deleting this one.`,
  TITLE: `Reassign Users`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Workspace activate/inactive request fail`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully activated`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Delete workspace request fail`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully deleted`,
  GET_WORKSPACES_ERROR_MESSAGE: `Get workspaces request fail`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully deactivated`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Save workspace request fail`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully saved`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Admins`,
  ADMIN_DESCRIPTION: `The following people may admin this workspace.`,
  INVITE_SETTINGS_TITLE: `Invite Settings`,
  NOTES_PART_1: `Alternatively, you may use the `,
  NOTES_PART_2: `User Management`,
  NOTES_PART_3: ` section to find a user and add them to a workspace.`,
  PHONE_NUMBER_DESCRIPTION: `If you plan to use SUMO to book phone calls, it is recommended that you keep it enabled.`,
  PHONE_NUMBER_TITLE: `Make Users "Phone Number" field required.`,
  SEARCH_ADD_ADMINS: `Search & Add Admins`,
  SEARCH_ADD_USERS: `Search & Add Users`,
  SUPER_ADMINS_DESCRIPTION: `Super Admins will be always prepopulated`,
  USERS_DESCRIPTION: `The following people may use this workspace.`,
  USERS_TITLE: `Users`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Active`,
  ADDITIONAL_OPTIONS: `Additional Options`,
  ADMIN_TITLE: `Admin`,
  BRANDING_DESCRIPTION: `By default all booking pages of this workspace will use this branding settings. However, you may override the settings for this specific booking page after.`,
  BRANDING_TITLE_PART_1: `What will the`,
  BRANDING_TITLE_PART_2: `branding`,
  BRANDING_TITLE_PART_3: `settings be for this workspace?`,
  DEFAULT_WORKSPACE: `Default Workspace`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  EDIT_LABELS_DESCRIPTION: `Edit the labels for all Booking Pages in this workspace. You may override these settings on specific Booking Pages as needed.`,
  INACTIVE: `Inactive`,
  NAME_TITLE: `Workspace Name`,
  NEW_WORKSPACE: `New Workspace`,
  PREVIEW_BUTTON: `View Booking Page & Edit Labels`,
  SELECTED_WORKSPACES: `selected workspaces`,
  TITLE: `Workspaces`,
  TOOLTIP: `Manage Workspaces, which give departments or teams unique global settings`,
  WHAT_TITLE_PART_1: `What`,
  WHAT_TITLE_PART_2: `workspace is this?`,
  WHO_TITLE_PART_1: `Who`,
  WHO_TITLE_PART_2: `can use this workspace?`,
});
