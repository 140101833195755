import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PhoneCallType, InPersonType, LocationType } from '../../../../API';
import {
  DEFAULT_INPUT_LOCATION_FIELD,
  DEFAULT_INPUT_PHONE_FIELD,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../../store/bookingTemplates';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
// import { PickList, PickListChangeEvent } from 'primereact/picklist';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { MAX_LENGTH_EMAIL } from '../../../../types/constants';
import { PhoneNumber } from '../../../common';
import labels from './labels';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { AppDispatch } from '../../../../store/rootStore';

type WhereStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const WhereStep = ({ selectors, actions, isReadOnly }: WhereStepProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const locationTypes = useSelector(selectors.selectLocationTypes);
  const defaultLocationType = useSelector(selectors.selectDefaultLocationType);
  const locationTypesOptions = useSelector(selectors.selectLocationTypesOptions);
  const inPersonType = useSelector(selectors.selectInPersonType);
  const inPersonCustomAddress = useSelector(selectors.selectInPersonCustomAddress);
  // const inPersonLocations = useSelector(selectors.selectInPersonLocations);
  // const availableLocations = useSelector(selectors.selectAvailableLocations);
  const phoneCallType = useSelector(selectors.selectPhoneCallType);
  const phoneCallCustomPhoneNumber = useSelector(selectors.selectPhoneCallCustomPhoneNumber);
  const phoneCallCustomPhoneCode = useSelector(selectors.selectPhoneCallCustomPhoneCode);
  // const skipWhereStep = useSelector(selectors.selectSkipWhereStep);
  // const isZoomValid = useSelector(selectors.selectIsZoomValid);
  // const isGoogleMeetValid = useSelector(selectors.selectIsGoogleMeetValid);
  // const isMicrosoftTeamsValid = useSelector(selectors.selectIsMicrosoftTeamsValid);
  // const isSkipWhereDisabled = useSelector(selectors.selectIsSkipWhereDisabled);
  const videoConferenceNotification = useSelector(selectors.selectVideoConferenceNotification);
  const hostPhoneNotification = useSelector(selectors.selectHostPhoneNotification);
  const isCustomPhoneValid = useSelector(selectors.selectIsCustomPhoneValid);
  const isCustomAddressValid = useSelector(selectors.selectIsCustomAddressValid);
  // const isCompanyAddressValid = useSelector(selectors.selectIsCompanyAddressValid);
  const isDefaultLocationTypeValid = useSelector(selectors.selectIsDefaultLocationTypeValid);
  const phoneInputField = useSelector(selectors.selectPhoneInputFields);
  const locationInputField = useSelector(selectors.selectLocationInputFields);
  const smsReminderOptionEnabled = useSelector(selectors.selectSmsReminderOptionEnabled);

  const [customAddress, setCustomAddress] = useState(inPersonCustomAddress);
  const [customPhone, setCustomPhone] = useState(phoneCallCustomPhoneNumber);

  useEffect(() => {
    // select In-Person and Invite Location and no location custom field, than add it
    if (
      locationTypes.includes(LocationType.IN_PERSONAL) &&
      inPersonType === InPersonType.INVITEE_LOCATION &&
      !locationInputField
    ) {
      dispatch(actions.addCustomField(DEFAULT_INPUT_LOCATION_FIELD));
    }

    // unselect In-Person or Invite Location and there is location custom field, than remove it
    if (
      (!locationTypes.includes(LocationType.IN_PERSONAL) || inPersonType !== InPersonType.INVITEE_LOCATION) &&
      locationInputField &&
      locationInputField.id
    ) {
      dispatch(actions.removeCustomField(locationInputField.id));
    }

    // select Phone Call and Prompt Invite
    if (locationTypes.includes(LocationType.PHONE_CALL) && phoneCallType === PhoneCallType.PROMPT_INVITE) {
      if (phoneInputField && !phoneInputField.required) {
        // if field already exist and no required(from SMS reminder), than update
        dispatch(actions.updateCustomField({ ...phoneInputField, required: true }));
      } else if (!phoneInputField) {
        // if no phone field, than add
        dispatch(actions.addCustomField(DEFAULT_INPUT_PHONE_FIELD));
      }
    }

    // unselect Phone Call or Prompt Invite and there is phone custom field
    if (
      (!locationTypes.includes(LocationType.PHONE_CALL) || phoneCallType !== PhoneCallType.PROMPT_INVITE) &&
      phoneInputField &&
      phoneInputField.id
    ) {
      if (smsReminderOptionEnabled) {
        // if sms reminder turn on, than update field to no required
        dispatch(actions.updateCustomField({ ...phoneInputField, required: false }));
      } else {
        // if sms reminder turn off, than remove field
        dispatch(actions.removeCustomField(phoneInputField.id));
      }
    }
  }, [locationTypes, inPersonType, phoneCallType]);

  const handleLocationTypeChange = (e: CheckboxChangeEvent) => {
    dispatch(actions.toggleWhereLocationType(e.value));
    if (e.value === LocationType.VIDEO_CONFERENCE && 'setDefaultVideoConference' in actions) {
      dispatch(actions.setDefaultVideoConference());
    }
  };

  const handleDefaultLocationTypeChange = (value: LocationType) => {
    dispatch(actions.updateWhereStep({ defaultLocationType: value }));
  };

  const handleInPersonTypeChange = (value: InPersonType) => {
    dispatch(actions.updateWhereStep({ inPersonType: value }));
  };

  const handleAddressChange = (value: string) => {
    setCustomAddress(value);
  };

  const handleAddressBlur = () => {
    dispatch(actions.updateWhereStep({ customAddress }));
  };

  // const handleLocationsChange = (e: PickListChangeEvent) => {
  //   dispatch(actions.updateWhereStep({ locations: e.target }));
  // };

  const handlePhoneCallTypeChange = (value: PhoneCallType) => {
    dispatch(actions.updateWhereStep({ phoneCallType: value }));
  };

  const handlePhoneChange = (value: string) => {
    setCustomPhone(value);
  };

  const handlePhoneBlur = () => {
    dispatch(actions.updateWhereStep({ customPhone }));
  };

  const handlePhoneCodeChange = (value: string) => {
    dispatch(actions.updateWhereStep({ customCountryCode: value }));
  };

  // const handleSkipWhereStepChange = (value: boolean) => {
  //   dispatch(actions.updateWhereStep({ skipTheWhereStep: value }));
  // };

  return (
    <div className="grid align-items-center p-fluid">
      <div className="col-12">
        <div className="font-bold">{labels.title}</div>
      </div>
      <div className="col-12 md:col-6 field-checkbox mb-0 h-3rem">
        <Checkbox
          inputId="videoConference"
          value={LocationType.VIDEO_CONFERENCE}
          checked={locationTypes.includes(LocationType.VIDEO_CONFERENCE)}
          onChange={handleLocationTypeChange}
          disabled={isReadOnly}
        />
        <label htmlFor="videoConference" className="mx-2 py-1 cursor-pointer hover:text-primary font-bold">
          {labels.videoConference}
        </label>
      </div>
      <div className="col-12 md:col-6">
        {videoConferenceNotification && <div className="text-red-500">{videoConferenceNotification}</div>}
      </div>

      {/* <div className="col-12 md:col-6 field-checkbox md:pl-5 mb-0 h-3rem">
        <Checkbox
          inputId="skipWhereStep"
          value={true}
          checked={!!skipWhereStep}
          disabled={isSkipWhereDisabled}
          onChange={(e) => handleSkipWhereStepChange(!!e.checked)}
        />
        <label htmlFor="skipWhereStep" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
          {labels.skipWhereStep}
        </label>
      </div>
      <div className="col-12 md:col-6 text-gray-500">{labels.skipWhereStepDescription}</div> */}

      <Divider className="mx-2" />

      <div className="col-12 field-checkbox">
        <Checkbox
          inputId="phoneCall"
          value={LocationType.PHONE_CALL}
          checked={locationTypes.includes(LocationType.PHONE_CALL)}
          onChange={handleLocationTypeChange}
          disabled={isReadOnly}
        />
        <label htmlFor="phoneCall" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary font-bold">
          {labels.phoneCall}
        </label>
      </div>

      <div className="col-12 md:col-6 field-radiobutton md:pl-5 mb-0 h-3rem">
        <RadioButton
          inputId="hostsPhone"
          value={PhoneCallType.HOST_PHONE_NUMBER}
          checked={phoneCallType === PhoneCallType.HOST_PHONE_NUMBER}
          disabled={!locationTypes.includes(LocationType.PHONE_CALL) || isReadOnly}
          onChange={(e) => handlePhoneCallTypeChange(e.target.value as PhoneCallType)}
        />
        <label htmlFor="hostsPhone" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
          {labels.hostsPhone}
        </label>
      </div>
      <div className="col-12 md:col-6">
        {!hostPhoneNotification ? (
          <div className="text-gray-500">{labels.hostsPhoneDescription}</div>
        ) : (
          <div className="text-red-500">{hostPhoneNotification}</div>
        )}
      </div>

      <div className="col-12 md:col-6 field-radiobutton md:pl-5 mb-0 h-3rem">
        <RadioButton
          inputId="inviteePhone"
          value={PhoneCallType.PROMPT_INVITE}
          checked={phoneCallType === PhoneCallType.PROMPT_INVITE}
          disabled={!locationTypes.includes(LocationType.PHONE_CALL) || isReadOnly}
          onChange={(e) => handlePhoneCallTypeChange(e.target.value as PhoneCallType)}
        />
        <label htmlFor="inviteePhone" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
          {labels.inviteePhone}
        </label>
      </div>
      <div className="col-12 md:col-6 text-gray-500">{labels.inviteePhoneDescription}</div>

      <div className="col-12 md:col-6 field-radiobutton md:pl-5 mb-0 h-3rem">
        <RadioButton
          inputId="customPhone"
          value={PhoneCallType.CUSTOM_PHONE}
          checked={phoneCallType === PhoneCallType.CUSTOM_PHONE}
          disabled={!locationTypes.includes(LocationType.PHONE_CALL) || isReadOnly}
          onChange={(e) => handlePhoneCallTypeChange(e.target.value as PhoneCallType)}
        />
        <label htmlFor="customPhone" className="flex-none ml-0 py-1 px-2 cursor-pointer hover:text-primary">
          {labels.customPhone}
        </label>
      </div>
      <div className="col-12 md:col-6 text-gray-500">{labels.customPhoneDescription}</div>
      <div className="col-12 md:col-6 md:pl-7">
        <PhoneNumber
          disabled={
            !locationTypes.includes(LocationType.PHONE_CALL) ||
            phoneCallType !== PhoneCallType.CUSTOM_PHONE ||
            isReadOnly
          }
          placeholder={labels.customPhonePlaceholder}
          countryCode={phoneCallCustomPhoneCode || ''}
          handleChangeCode={handlePhoneCodeChange}
          phoneNumber={customPhone || ''}
          handleChangePhone={handlePhoneChange}
          handleBlurPhone={handlePhoneBlur}
          inputClassName={(!isCustomPhoneValid && 'p-invalid') || ''}
        />
      </div>
      <Divider className="mx-2" />

      <div className="col-12 field-checkbox">
        <Checkbox
          inputId="inPerson"
          value={LocationType.IN_PERSONAL}
          checked={locationTypes.includes(LocationType.IN_PERSONAL)}
          onChange={handleLocationTypeChange}
          disabled={isReadOnly}
        />
        <label htmlFor="inPerson" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary font-bold">
          {labels.inPerson}
        </label>
      </div>

      <div className="col-12 md:col-6 field-radiobutton md:pl-5 mb-0">
        <RadioButton
          inputId="customAddress"
          value={InPersonType.CUSTOM_ADDRESS}
          checked={inPersonType === InPersonType.CUSTOM_ADDRESS}
          disabled={!locationTypes.includes(LocationType.IN_PERSONAL) || isReadOnly}
          onChange={(e) => handleInPersonTypeChange(e.target.value as InPersonType)}
        />
        <label htmlFor="customAddress" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
          {labels.customAddress}
        </label>
      </div>
      <div className="col-12 md:col-6 text-gray-500">{labels.customAddressDescription}</div>

      <div className="col-12 md:col-6 md:pl-7 mb-2">
        <InputText
          type="text"
          placeholder={labels.customAddressPlaceholder}
          value={customAddress || ''}
          disabled={!locationTypes.includes(LocationType.IN_PERSONAL) || isReadOnly}
          onChange={(e) => handleAddressChange(e.target.value.trimStart())}
          onBlur={handleAddressBlur}
          className={(!isCustomAddressValid && 'p-invalid') || ''}
          maxLength={MAX_LENGTH_EMAIL}
        />
      </div>
      <div className="col-12 md:col-6"></div>

      {/* <div className="col-12 md:col-6 field-radiobutton md:pl-5 mb-0">
        <RadioButton
          inputId="location"
          value={InPersonType.LOCATION}
          checked={inPersonType === InPersonType.LOCATION}
          disabled={!locationTypes.includes(LocationType.IN_PERSONAL)}
          onChange={(e) => handleInPersonTypeChange(e.target.value as InPersonType)}
        />
        <label htmlFor="location" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
          {labels.location}
        </label>
      </div>
      <div className="col-12 md:col-6 text-gray-500">{labels.locationDescription}</div> */}

      {/* <div className="col-12 md:pl-7">
        <PickList
          breakpoint="768px"
          sourceStyle={{ height: '200px', borderRadius: '4px', padding: 0 }}
          targetStyle={{ height: '200px', borderRadius: '4px', padding: 0 }}
          showSourceControls={false}
          showTargetControls={false}
          source={availableLocations}
          target={inPersonLocations || []}
          onChange={handleLocationsChange}
          itemTemplate={(item) => <div>{item}</div>}
          className={`${locationTypes.includes(LocationType.IN_PERSONAL) ? '' : 'p-disabled'} ${isCompanyAddressValid ? '' : 'p-invalid'}`}
        ></PickList>
      </div> */}

      <div className="col-12 md:col-6 field-radiobutton md:pl-5 mb-0">
        <RadioButton
          inputId="invitee_location"
          value={InPersonType.INVITEE_LOCATION}
          checked={inPersonType === InPersonType.INVITEE_LOCATION}
          disabled={!locationTypes.includes(LocationType.IN_PERSONAL) || isReadOnly}
          onChange={(e) => handleInPersonTypeChange(e.target.value as InPersonType)}
        />
        <label htmlFor="invitee_location" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
          {labels.inviteeLocation}
        </label>
      </div>
      <div className="col-12 md:col-6 text-gray-500">{labels.inviteeLocationDescription}</div>

      <Divider className="mx-2" />

      <div className="col-12 md:col-6 mb-2">
        <label>{labels.defaultLocation}</label>
        <Dropdown
          className={(!isDefaultLocationTypeValid && 'p-invalid') || ''}
          optionLabel="label"
          options={locationTypesOptions}
          value={defaultLocationType}
          onChange={(e) => handleDefaultLocationTypeChange(e.target.value)}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );
};
