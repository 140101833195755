import { Path } from '../../../routing';
import { Authentication } from '../../common';
import labels from './labels';
import { navigationService } from '../../../services/NavigationService';

export const SignUp = () => {
  const userLogin = () => {
    navigationService.navigateTo(Path.Login);
  };

  return (
    <Authentication
      title={labels.title}
      subtitle={labels.subtitle}
      comment={labels.comment}
      typeTitle={labels.register}
      icon="pi pi-user-plus"
      linkSubtext={labels.haveAnAccount}
      linkText={labels.login}
      linkAction={userLogin}
      googleLabel={labels.googleSignUp}
      microsoftLabel={labels.microsoftSignUp}
      login={false}
    />
  );
};
