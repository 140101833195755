import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { notificationsSelectors, notificationsActions } from '../../../store/notifications';
import { Toast, ToastMessage } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import labels from './labels';
import { InviteExpiredToast } from '../../public/inviteExpiredToast/InviteExpiredToast';

export const ToastNotification = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toastNotification = useRef<Toast>(null);
  const toastNotificationsQueue = useSelector(notificationsSelectors.selectToastNotificationsQueue);

  const severityToTitle = {
    error: labels.error,
    success: labels.success,
    info: labels.info,
    warn: labels.warning,
  };
  const severityToIcon = {
    error: 'pi pi-times-circle',
    success: 'pi pi-check',
    info: 'pi pi-exclamation-circle',
    warn: 'pi pi-exclamation-triangle',
  };

  useEffect(() => {
    const toastMessages: ToastMessage[] = toastNotificationsQueue.map((item) => ({
      id: item.id,
      severity: item.color,
      summary: item.title ? item.title : item.color ? severityToTitle[item.color] : '',
      detail: item.message,
      life: item.autoHideDuration,
      className: 'lg:ml-8 lg:-mr-8',
      icon: item.color ? severityToIcon[item.color] : null,
      content: item.isInviteExpired ? InviteExpiredToast() : undefined,
    }));

    if (toastNotification.current) {
      toastNotification.current.replace(toastMessages);
    }
  }, [toastNotificationsQueue]);

  const handleClose = (toastId: string | undefined) => {
    toastId && dispatch(notificationsActions.hideToast(toastId));
  };

  return <Toast ref={toastNotification} position="bottom-left" onRemove={(el) => handleClose(el.id)} />;
};
