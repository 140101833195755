import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { IntegrationFilter } from './types';
import { INTEGRATION_CATEGORIES } from './constants';

const integrationState = (state: State) => state.integration;
const selectIntegrationList = createSelector(integrationState, (state) => state.integration);
const selectIntegrationFilter = createSelector(integrationState, (state) => state.filter);
const selectFilteredIntegrations = createSelector(selectIntegrationList,selectIntegrationFilter, (integrations, filter) =>
    integrations.filter(
      (item) => filter === IntegrationFilter.ALL || INTEGRATION_CATEGORIES[filter].includes(item.type)
    )
  );
const selectSearchIntegrations = ( searchString: string) =>
  createSelector(selectFilteredIntegrations, (integrations) =>
    integrations.filter((item) => item.label.toLowerCase().includes(searchString))
  );

const selectConnectIntegration = createSelector(integrationState, (state) => state.connectIntegration);

export const integrationSelectors = { selectIntegrationList,selectIntegrationFilter, selectSearchIntegrations, selectConnectIntegration };
