import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { eventActions, eventSelectors, CANCEL_TYPE_OPTIONS } from '../../../store/publicBookingPage';
import { CanceledType } from '../../../API';
import { Label } from '../../common';
import labels from './labels';
import { Dropdown } from 'primereact/dropdown';

export const PublicBookingPageCancel = () => {
  const dispatch = useDispatch();
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const subHeaderStyle = useSelector(eventSelectors.selectSubHeaderStyle);
  const mainTextStyle = useSelector(eventSelectors.selectMainTextStyle);
  const cancelLabel = useSelector(eventSelectors.selectCancelLabel);
  const type = useSelector(eventSelectors.selectEventCanceledType);
  const note = useSelector(eventSelectors.selectEventCanceledNote);

  const [localNote, setLocalNote] = useState(note || '');

  useEffect(() => {
    if (!type) {
      dispatch(eventActions.updateCanceled({ type: CanceledType.UNEXPECTED }));
    }
  }, []);

  const handleChangeNote = (note: string) => {
    setLocalNote(note);
  };

  const handleBlurNote = () => {
    dispatch(eventActions.updateCanceled({ note: localNote }));
  };

  const handleChangeType = (type: CanceledType) => {
    dispatch(eventActions.updateCanceled({ type }));
  };

  const handleCancelLabelChange = (cancelLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ cancelLabel }));
  };

  const renderSubHeaderLabel = (value: string, onChange: (value: string) => void) => (
    <Label
      editClassName="hover:text-primary"
      textClassName="text-xl font-semibold"
      textStyle={subHeaderStyle}
      editable={isEditable}
      value={value}
      onBlurUpdate={onChange}
    />
  );

  return (
    <>
      <div className="pl-3 ml-1 mt-3">{renderSubHeaderLabel(cancelLabel, handleCancelLabelChange)}</div>

      <div className="card mb-0 mt-1 border-1 surface-border shadow-none" style={mainTextStyle}>
        <div className="grid p-fluid -mb-2">
          <div className="col-12 md:col-4">
            <label htmlFor="cancelType">{labels.type}</label>
            <Dropdown
              id="cancelType"
              value={type}
              options={CANCEL_TYPE_OPTIONS}
              onChange={(e) => handleChangeType(e.value)}
            />
          </div>
          <div className="col-12">
            <label htmlFor="cancelReason">{labels.reason}</label>
            <InputTextarea
              id="cancelReason"
              autoResize
              rows={4}
              value={localNote}
              onChange={(e) => handleChangeNote(e.target.value.trimStart())}
              onBlur={handleBlurNote}
              maxLength={1000}
            />
          </div>
        </div>
      </div>
    </>
  );
};
