import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ConfirmationsPageType } from '../../../../API';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import {
  CONFIRMATION_TYPE_OPTIONS,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  sortListItemDown,
  sortListItemUp,
} from '../../../../store/bookingTemplates';
import { MAX_LENGTH_NAME } from '../../../../types/constants';
import labels from './labels';
// import { Tooltip } from 'primereact/tooltip';
// import { InputTextarea } from 'primereact/inputtextarea';

type ConfirmationStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const ConfirmationStep = ({ selectors, actions, isReadOnly }: ConfirmationStepProps) => {
  const dispatch = useDispatch();
  const type = useSelector(selectors.selectConfirmationType);
  const externalLink = useSelector(selectors.selectConfirmationExternalLink);
  const anotherEnabled = useSelector(selectors.selectConfirmationAnotherEnabled);
  const rescheduleEnabled = useSelector(selectors.selectConfirmationRescheduleEnabled);
  const cancelEnabled = useSelector(selectors.selectConfirmationCancelEnabled);
  // const cancelationPolicy = useSelector(selectors.selectCancelationPolicy);
  const customLinks = useSelector(selectors.selectConfirmationCustomLinks);
  const isLinkValid = useSelector(selectors.selectIsExternalLinkValid);

  const [externalLinkValue, setExternalLinkValue] = useState(externalLink);
  // const [cancelationPolicyValue, setCancelationPolicyValue] = useState(cancelationPolicy);
  const [customLinksValue, setCustomLinksValue] = useState(customLinks || []);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number | null>(null);
  const [selectedLinkLabel, setSelectedLinkLabel] = useState<string | null>(null);
  const [selectedLinkUrl, setSelectedLinkUrl] = useState<string | null>(null);

  // const handleCancelationPolicyBlur = () => {
  //   dispatch(actions.updateConfirmationStep({ cancelationPolicy: cancelationPolicyValue }));
  // };

  const handleTypeChange = (type: ConfirmationsPageType) => {
    dispatch(actions.updateConfirmationStep({ type }));
  };

  const handleExteranlLinkChange = (value: string) => {
    setExternalLinkValue(value);
  };

  const handleExteranlLinkBlur = () => {
    dispatch(actions.updateConfirmationStep({ externalLink: externalLinkValue || null }));
  };

  const handleAnotherEnabledChange = (anotherEventEnabled: boolean) => {
    dispatch(actions.updateConfirmationStep({ anotherEventEnabled }));
  };

  const handleRescheduleEnabledChange = (rescheduleEnabled: boolean) => {
    dispatch(actions.updateConfirmationStep({ rescheduleEnabled }));
  };

  const handleCancelEnabledChange = (cancelEnabled: boolean) => {
    dispatch(actions.updateConfirmationStep({ cancelEnabled }));
  };

  const handleLinkLabelChange = (value: string) => {
    setSelectedLinkLabel(value);
  };

  const handleLinkUrlChange = (value: string) => {
    setSelectedLinkUrl(value);
  };

  const handleAddLink = () => {
    setSelectedLinkIndex(null);
    setSelectedLinkLabel(null);
    setSelectedLinkUrl(null);
    setModalOpened(true);
  };

  const handleAnswerDown = (index: number) => {
    setCustomLinksValue(sortListItemDown(customLinksValue, index));
  };
  const handleAnswerUp = (index: number) => {
    setCustomLinksValue(sortListItemUp(customLinksValue, index));
  };

  const handleEditLink = (index: number) => {
    const selectedLink = customLinksValue[index];
    setSelectedLinkIndex(index);
    setSelectedLinkLabel(selectedLink?.name || null);
    setSelectedLinkUrl(selectedLink?.link || null);
    setModalOpened(true);
  };

  const handleRemoveLink = (index: number) => {
    setCustomLinksValue([...customLinksValue.slice(0, index), ...customLinksValue.slice(index + 1)]);
    dispatch(actions.updateConfirmationStep({ customLinks: customLinksValue }));
  };

  const handleSaveLink = () => {
    const index = selectedLinkIndex === null ? customLinksValue.length : selectedLinkIndex;
    setCustomLinksValue([
      ...customLinksValue.slice(0, index),
      { name: selectedLinkLabel, link: selectedLinkUrl },
      ...customLinksValue.slice(index + 1),
    ]);
    dispatch(actions.updateConfirmationStep({ customLinks: customLinksValue }));
    setModalOpened(false);
  };

  const handleCloseLink = () => {
    setSelectedLinkIndex(null);
    setSelectedLinkLabel(null);
    setSelectedLinkUrl(null);
    setModalOpened(false);
  };

  return (
    <div className="grid align-items-center p-fluid">
      <div className="col-12 md:col-6">
        <label htmlFor="pageType">{labels.typeLabel}</label>
        <Dropdown
          id="pageType"
          value={type}
          onChange={(e) => handleTypeChange(e.target.value)}
          options={CONFIRMATION_TYPE_OPTIONS}
          optionLabel="label"
          disabled={isReadOnly}
        />
      </div>

      {type === ConfirmationsPageType.REDIRECT && (
        <div className="col-12 md:col-6">
          <label htmlFor="meetingTypeName">{labels.externalLinkLabel}</label>
          <InputText
            id="meetingTypeName"
            type="text"
            placeholder={labels.externalLinkPlaceholder}
            value={externalLinkValue || ''}
            onChange={(e) => handleExteranlLinkChange(e.target.value.trimStart())}
            onBlur={handleExteranlLinkBlur}
            className={`${!isLinkValid && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
            disabled={isReadOnly}
          />
        </div>
      )}

      <div className="col-12 pt-4 flex flex-column gap-4  ">
        <div className="flex align-items-center gap-3">
          <InputSwitch
            checked={!!anotherEnabled}
            onChange={(e) => handleAnotherEnabledChange(!!e.value)}
            disabled={isReadOnly}
          />
          <div>{labels.displayBookAnotherButtonLabel}</div>
        </div>
        <div className="flex align-items-center gap-3">
          <InputSwitch
            checked={!!rescheduleEnabled}
            onChange={(e) => handleRescheduleEnabledChange(!!e.value)}
            disabled={isReadOnly}
          />
          <div>{labels.allowRescheduleLabel}</div>
        </div>
        <div className="flex align-items-center gap-3">
          <InputSwitch
            checked={!!cancelEnabled}
            onChange={(e) => handleCancelEnabledChange(!!e.value)}
            disabled={isReadOnly}
          />
          <div>{labels.allowCancelLabel}</div>
        </div>
      </div>

      {/* <div className="col-12 md:col-6 pt-4">
        <label>{labels.cancelationPolicy}</label>
        <Tooltip target=".cancelation-policy" className="max-w-30rem" />
        <i
          className="cancelation-policy pi pi-info-circle ml-2"
          data-pr-position="top"
          data-pr-my="center bottom-10"
          data-pr-tooltip={labels.cancelationPolicyTooltip}
        ></i>
        <InputTextarea
          autoResize
          rows={4}
          value={cancelationPolicyValue || ''}
          onChange={(e) => setCancelationPolicyValue(e.target.value.trimStart())}
          onBlur={handleCancelationPolicyBlur}
          maxLength={2000}
          disabled={isReadOnly}
        />
      </div> */}

      <Divider className="mx-2 my-5" />

      <div className="col-fixed">
        <Button label={labels.addCustomLinkLabel} onClick={handleAddLink} disabled={isReadOnly} />
      </div>
      <div className="col">{labels.addCustomLinkDescription}</div>

      <div className="col-12 pt-4 flex flex-column">
        {customLinksValue.map((link, index, { length }) => (
          <div key={index}>
            {index !== 0 && <Divider className="m-0" />}
            <div className="grid align-items-center my-0">
              <div className="col">
                <Link to={link?.link || ''} target="_blank" className="text-primary hover:underline">
                  {link?.name}
                </Link>
              </div>
              <div className="col-fixed flex flex-column">
                {index !== 0 && (
                  <Button
                    icon="pi pi-angle-up"
                    className="h-1rem mr-2"
                    text
                    onClick={() => handleAnswerUp(index)}
                    disabled={isReadOnly}
                  ></Button>
                )}
                {index !== length - 1 && (
                  <Button
                    icon="pi pi-angle-down"
                    className="h-1rem mr-2"
                    text
                    onClick={() => handleAnswerDown(index)}
                    disabled={isReadOnly}
                  ></Button>
                )}
              </div>
              <div className="col-fixed">
                <Button
                  icon="pi pi-file-edit"
                  rounded
                  text
                  onClick={() => handleEditLink(index)}
                  disabled={isReadOnly}
                />
              </div>
              <div className="col-fixed">
                <Button icon="pi pi-trash" rounded text onClick={() => handleRemoveLink(index)} disabled={isReadOnly} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog
        className="w-30rem p-fluid"
        header={selectedLinkIndex === null ? labels.addCustomLinkTitle : labels.editCustomLinkTitle}
        visible={modalOpened}
        modal
        closable
        draggable={false}
        position="center"
        onHide={handleCloseLink}
      >
        <div>{labels.addCustomLinkDescription}</div>
        <div className="mt-4">
          <label htmlFor="linkLabel" className="font-semibold">
            {labels.customLinkLabel}
          </label>
          <InputText
            id="linkLabel"
            type="text"
            placeholder={labels.customLinkPlaceholder}
            value={selectedLinkLabel || ''}
            onChange={(e) => handleLinkLabelChange(e.target.value.trimStart())}
            className={`${!selectedLinkLabel && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="linkUrl" className="font-semibold">
            {labels.linkUrlLabel}
          </label>
          <InputText
            id="linkUrl"
            type="text"
            placeholder={labels.linkUrlPlaceholder}
            value={selectedLinkUrl || ''}
            onChange={(e) => handleLinkUrlChange(e.target.value.trimStart())}
            className={`${!selectedLinkUrl && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="grid justify-content-end mt-5">
          <div className="col-fixed">
            <Button label={labels.cancel} outlined onClick={handleCloseLink} />
          </div>
          <div className="col-fixed">
            <Button label={labels.save} disabled={!selectedLinkLabel || !selectedLinkUrl} onClick={handleSaveLink} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
