import { Dayjs } from "dayjs";

export const constructExpireTrialEmail = () => {
    const subject = 'SUMO1 Trial Expired';
    const body = `
      <style>.link:hover { background: #0b7ad1 !important; }</style>
      <p>Your SUMO1 trial has expired.</p>
      <p>You may continue by purchasing at least 1 user licence on a month-to-month basis by clicking</p>
      <a href="${process.env.REACT_APP_INVITE_LINK}users" target="_blank" style="text-decoration: none;">
          <div class="link" style="background: #2196F3; color: #FFF; font-size: 20px; width: 200px; padding: 8px 0; border-radius: 4px; text-align: center; cursor: pointer;">
            Buy Licenses
          </div>
      </a>
      <br>
      <img src="${process.env.REACT_APP_INVITE_LINK}images/sumoLogo.png" alt="SUMO1 Logo" width="200px">
    `;
    return { subject, body };
}

export const constructExtendTrialEmail = (extendedDate: Dayjs) => {
    const subject = `SUMO1 Trial Expires: ${extendedDate.format('ddd, MMM. D')}`;
    const body = `
      <p>Your SUMO1 trial has been granted a one-time 15-day extension and now expires on ${extendedDate.format('ddd, MMM. D')}.</p>
      <p>You may purchase SUMO1 licenses by clicking</p>
      <a href="${process.env.REACT_APP_INVITE_LINK}users" target="_blank" style="text-decoration: none;">
          <div class="link" style="background: #2196F3; color: #FFF; font-size: 20px; width: 200px; padding: 8px 0; border-radius: 4px; text-align: center; cursor: pointer;">
            Buy Licenses
          </div>
      </a>
      <br>
      <img src="${process.env.REACT_APP_INVITE_LINK}images/sumoLogo.png" alt="SUMO1 Logo" width="200px">
    `;
    return { subject, body };
}