import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CANCEL_EVENT_MODAL_NAME, cancelEventModalActions } from '../../../store/scheduledMeetings/modal';
import { Modal } from '../../common';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { scheduledMeetingsSelectors } from '../../../store/scheduledMeetings/selectors';
import labels from './labels';
import { scheduledMeetingsActions, CANCEL_TYPE_OPTIONS } from '../../../store/scheduledMeetings';
import { CanceledType } from '../../../API';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

export const CancelScheduledMeetingModal = () => {
  const dispatch = useDispatch();
  const cancelType = useSelector(scheduledMeetingsSelectors.selectEventCancelType);
  const cancelNote = useSelector(scheduledMeetingsSelectors.selectEventCancelNote);
  const [localNote, setLocalNote] = useState(cancelNote || '');

  const handleClose = () => {
    setLocalNote('');
    dispatch(cancelEventModalActions.closeModal());
  };

  const handleCancel = () => {
    dispatch(scheduledMeetingsActions.cancelScheduledMeetingRequest());
    handleClose();
  };

  const handleChangeType = (type: CanceledType) => {
    dispatch(scheduledMeetingsActions.updateCancelReason(type));
  };

  const handleChangeNote = (note: string) => {
    setLocalNote(note);
  };

  const handleBlurNote = () => {
    dispatch(scheduledMeetingsActions.updateCancelNote(localNote));
  };

  return (
    <Modal.Container name={CANCEL_EVENT_MODAL_NAME}>
      <Modal.Header>
        <div>{labels.cancelTitle}</div>
      </Modal.Header>
      <div className="grid p-fluid md:w-30rem">
        <div className="col-12">
          <div>{labels.cancelReason}</div>
          <Dropdown
            value={cancelType}
            onChange={(e) => handleChangeType(e.value as CanceledType)}
            options={CANCEL_TYPE_OPTIONS}
          />
        </div>
        <div className="col-12">
          <div>{labels.cancelDesc}</div>
          <InputTextarea
            autoResize
            rows={4}
            value={localNote || ''}
            onChange={(e) => handleChangeNote(e.target.value)}
            onBlur={handleBlurNote}
          />
        </div>
      </div>

      <Modal.Buttons>
        <div className="flex justify-content-between">
          <Button label={labels.close} onClick={handleClose} outlined className="w-6rem" />
          <Button label={labels.cancelTitle} onClick={handleCancel} outlined className="m-0" severity="danger" />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
