import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import sumoIconLight from '../../../assets/images/sumoIconLight.png';
import labels from './labels';
import urlConstants from '../../../shared/JSON/urlConstants.json';

const AppFooter = () => {
  return (
    <div className="flex flex-column align-items-center mt-5">
      <div className="px-4">
        <span className="pr-1">{`${labels.footerTextPart1}${dayjs().year()}${labels.footerTextPart2}`}</span>|
        <Link to={urlConstants.PRIVACY_POLICY_URL} target="_blank" className="px-1 text-color hover:underline">
          {labels.privacyPolicy}
        </Link>
        |
        <Link to={urlConstants.SECURITY_POLICY_URL} target="_blank" className="px-1 text-color hover:underline">
          {labels.securityPolicy}
        </Link>
        |
        <Link to={urlConstants.TERMS_OF_USER_URL} target="_blank" className="px-1 text-color hover:underline">
          {labels.terms}
        </Link>
      </div>
      <div className="py-2">
        <img src={sumoIconLight} alt="Logo" className="h-4rem w-4rem" />
      </div>
    </div>
  );
};

export default AppFooter;
