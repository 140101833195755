import dayjs from 'dayjs';
import { LicenseType, TenantStatus } from '../../../API';
import { converLocalDateTimeObjectToDate } from '../../../services/DateService';
import { UserDetails, getRoleNameByRole } from '../users';
import { OPSConsoleRole, OPSConsoleTenant, OrgDetailsUserRecord, OrgsDataTableType, OrgsDataType } from './types';
import { ORG_STATUS_VALUES } from './constants';
import { getCurrencyLabelByCode } from '../../billing';

export const assignRoleToUser = (tenant: OPSConsoleTenant, users: OrgDetailsUserRecord[], roles: OPSConsoleRole[]) => {
  const usersWithRoles: UserDetails[] = users.map((user) => {
    const role = roles.find((role) => role.id === user.roleId);

    return {
      userId: user.userId,
      userName: user.name,
      lastActive: user.lastActive,
      email: user.email,
      accountId: '', // TODO add accountId
      accountName: '', // TODO add accountName
      tenantId: tenant.tenantId,
      status: user.status,
      role: role || ({} as OPSConsoleRole),
      roleName: role && getRoleNameByRole(role),
    };
  });

  return usersWithRoles;
};

export const convertToOrgsDataTableType = (orgsData: OrgsDataType[]) => {
  const convertedData: OrgsDataTableType[] = orgsData.map((org) => {
    const lastLicense = org.licenses.sort((a, b) => (a.endDate > b.endDate ? 1 : -1))[0];

    const daysLeft = dayjs(lastLicense.endDate).diff(dayjs().format('YYYY-MM-DD'), 'day');
    const status =
      (lastLicense.type === LicenseType.TRIAL && daysLeft <= 0) || org.status === TenantStatus.EXPIRED
        ? ORG_STATUS_VALUES.Expired
        : lastLicense.type !== LicenseType.TRIAL && daysLeft <= 0
        ? ORG_STATUS_VALUES.PaymentLate
        : ORG_STATUS_VALUES.Active;

    let bookedMeetings = 0;
    const userEmails: string[] = [];
    for (const user of org.userDataList) {
      bookedMeetings += user.statistics?.eventCreated || 0;
      user.userSettings?.email && userEmails.push(user.userSettings?.email.toLowerCase());
    }

    return {
      tenantId: org.tenantId,
      name: org.name,
      status,
      type: org.type,
      term: lastLicense.type,
      owned: lastLicense.owned,
      assigned: lastLicense.assigned,
      percentOfWeekAdoption: (+org.percentOfWeekAdoption * 100).toFixed(),
      percentOfMonthAdoption: (+org.percentOfMonthAdoption * 100).toFixed(),
      createdAt: converLocalDateTimeObjectToDate(org.createdAt).toISOString(),
      updatedAt: converLocalDateTimeObjectToDate(lastLicense.updatedAt).toISOString(),
      accountId: org.accountId,
      accountName: org.accountName,
      note: org.note,
      amount:
        getCurrencyLabelByCode(org?.subscription?.currency_code) +
        (org?.subscription?.subscription_items[0]?.amount / 100 || 0),
      amountPerUser:
        getCurrencyLabelByCode(org?.subscription?.currency_code) +
        (org?.subscription?.subscription_items[0]?.unit_price / 100 || 0),
      bookedMeetings: bookedMeetings,
      startDate: lastLicense.startDate,
      endDate: lastLicense.endDate,
      daysLeft: daysLeft,
      userEmails,
    };
  });

  return convertedData;
};
