import { IntegrationType } from '../../API';

export type IntegrationDataType = {
  type: IntegrationType;
  label: string;
  icon: string;
  description: string;
};

export enum IntegrationFilter {
  ALL = 'ALL',
  CALENDARS = 'CALENDARS',
  //EXTENSIONS = 'Extensions',
  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
}
