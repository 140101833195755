import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader, Search, SectionTitle } from '../../components/common';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../types/constants';
import labels from './labels';
import { DEFAULT_WORKSPACE, workspacesSelectors } from '../../store/workspaces';
import { workspacesActions } from '../../store/workspaces/actions';
import { DeleteWorkspaceModal, WorkspaceCard } from '../../components/workspaces';
import { Path } from '../../routing';
import { usersActions, usersSelectors } from '../../store/users';
import { rolesActions, rolesSelectors } from '../../store/roles';
import { CannotDeleteWorkspaceModal } from '../../components/workspaces/cannotDeleteWorkspaceModal/CannotDeleteWorkspaceModal';
import { userSettingsSelectors } from '../../store/userSettings';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { navigationService } from '../../services/NavigationService';
import urlConstants from '../../shared/JSON/urlConstants.json';
import { globalSelectors } from '../../store/global';
import { useUserNavigation } from '../../hooks/useUserNavigation';

export const Workspaces = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const isFetching = useSelector(workspacesSelectors.selectIsFetching);
  const isUsersFetching = useSelector(usersSelectors.selectIsFetching);
  const isRolesFetching = useSelector(rolesSelectors.selectIsFetching);
  const userWorkspacesIds =
    useSelector(usersSelectors.selectUserWorkspacesIds).filter((id): id is string => id !== null) || [];
  const availableWorkspaces = useSelector(workspacesSelectors.selectWorkspacesByIds(userWorkspacesIds));
  const filteredWorkspaces = useSelector(workspacesSelectors.selectFilteredWorkspacesByIds(userWorkspacesIds));
  const searchString = useSelector(globalSelectors.selectSearchString);
  const isWorkspacesRead = useSelector(userSettingsSelectors.selectWorkspacesRead);
  const isWorkspacesCreate = useSelector(userSettingsSelectors.selectWorkspacesCreate);
  const superAdminUsers = useSelector(usersSelectors.selectActiveSuperAdmins);
  const currentUser = useSelector(usersSelectors.selectCurrentUser);
  const workspaceRoleId = useSelector(rolesSelectors.selectWorkspaceId);

  useEffect(() => {
    if (isWorkspacesRead) {
      if (!isInitialVisit) {
        dispatch(workspacesActions.getWorkspacesRequest());
      }
      dispatch(usersActions.getUsersRequest());
      dispatch(rolesActions.getRolesRequest());
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, [isInitialVisit]);

  const handleCreateNew = () => {
    const defaultAdminList = [...superAdminUsers]; // add all super admins

    // if current user is workspace admin let add him too
    if (currentUser.roleId === workspaceRoleId) {
      defaultAdminList.push(currentUser);
    }

    // TODO: add isDefault to workspace schema? now using first workspace as default
    dispatch(
      workspacesActions.setWorkspace({
        ...DEFAULT_WORKSPACE,
        adminList: defaultAdminList,
        labels: filteredWorkspaces[0].labels,
        style: filteredWorkspaces[0].style,
      })
    );
    navigationService.navigateTo(Path.EditWorkspace);
  };

  return (
    <div>
      {(isFetching || isUsersFetching || isRolesFetching) && <Preloader />}

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle
          id="workspacesPageTitle"
          icon="pi-globe"
          title={labels.title}
          tooltip={labels.tooltip}
          learnMoreURL={urlConstants.KB_WORKSPACES_URL}
        />
        {isWorkspacesCreate && (
          <div className="-my-2 p-accordion-header-text">
            <Button
              label={labels.newWorkspace}
              icon="pi pi-globe"
              onClick={handleCreateNew}
              disabled={isRolesFetching}
            />
          </div>
        )}
      </div>

      <div className="card">
        {availableWorkspaces.length >= MIN_ITEMS_FOR_SEARCH && (
          <div className="mb-3 pb-1 p-inputgroup">
            <Search
              placeholder={labels.search}
              value={searchString}
              paths={[Path.Workspaces, Path.EditWorkspace]}
              maxTextLength={MAX_LENGTH_NAME}
            />
          </div>
        )}
        <div className="grid -mb-2">
          {filteredWorkspaces.map((workspace) => (
            <WorkspaceCard key={workspace.id} workspace={workspace} />
          ))}
        </div>
        {!filteredWorkspaces.length && <div className="mt-3">{labels.noResults}</div>}
      </div>

      <DeleteWorkspaceModal />
      <CannotDeleteWorkspaceModal />
    </div>
  );
};
