import { t } from '../../../i18n/i18n';

export default {
  description: t('QuickSetupCalendarConnected:DESCRIPTION'),
  instructionTitle: t('QuickSetupCalendarConnected:INSTRUCTION_TITLE'),
  instructionStepA1: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_A1'),
  instructionStepA2: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_A2'),
  instructionStepB: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_B'),
  instructionStepC1: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_C1'),
  instructionStepC2: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_C2'),
  instructionStepC3: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_C3'),
  instructionNote: t('QuickSetupCalendarConnected:INSTRUCTION_NOTE'),
  message: t('QuickSetupCalendarConnected:MESSAGE'),
  next: t('Common:NEXT'),
  title: t('QuickSetupCalendarConnected:TITLE'),
};
