import { useDispatch, useSelector } from 'react-redux';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  CLONE_MODAL,
  cloneModalActions,
} from '../../../store/bookingTemplates';
import { Modal } from '../../common';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import labels from './labels';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

export const BookingTemplateCloneModal = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingTemplatesSelectors.selectCloneName);
  const isNameDuplicate = useSelector(bookingTemplatesSelectors.selectIsCloneNameDuplicate);
  const isNameValid = useSelector(bookingTemplatesSelectors.selectIsCloneNameValid);

  const handleNameChange = (value: string) => {
    dispatch(bookingTemplatesActions.setCloneName(value));
  };

  const handleCancel = () => {
    dispatch(cloneModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(bookingTemplatesActions.cloneBookingTemplateRequest());
    dispatch(cloneModalActions.closeModal());
  };

  return (
    <Modal.Container name={CLONE_MODAL}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="w-25rem p-fluid">
        <div>{labels.name}</div>
        <InputText
          type="text"
          value={name}
          onChange={(e) => handleNameChange(e.target.value.trimStart())}
          className={(!isNameValid && 'p-invalid') || ''}
          maxLength={MAX_LENGTH_NAME}
        />
        {isNameDuplicate && <div className="text-red-500 mt-2">{labels.duplicate}</div>}
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined />
          <Button label={labels.save} onClick={handleSave} autoFocus disabled={!isNameValid} />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
