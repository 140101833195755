export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Fondo`,
  BUTTONS_LABEL: `Botones`,
  FONT_COLORS_TITLE: `Colores de fuente`,
  FONT_FAMILIES_TITLE: `Familias de fuentes`,
  HEADER_FONT_LABEL: `Fuente de encabezado`,
  HEADER_LABEL: `Encabezado`,
  MAIN_TEXT_FONT_LABEL: `Fuente del texto principal`,
  MAIN_TEXT_LABEL: `Texto principal`,
  PRIMARY_LABEL: `Primario`,
  SITE_COLORS_TITLE: `Colores del sitio`,
  SUB_HEADER_FONT_LABEL: `Fuente del subencabezado`,
  SUB_HEADER_LABEL: `Subencabezado`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `¡Tu calendario está conectado!`,
  INSTRUCTION_NOTE: `La mayoría de los administradores suelen usar SUMO para su uso personal y de prueba. Sin embargo, siempre puedes desconectar tu calendario más tarde en el menú en la esquina superior derecha.`,
  INSTRUCTION_STEP_A1: `Vamos a revisar `,
  INSTRUCTION_STEP_A2: ` en busca de conflictos.`,
  INSTRUCTION_STEP_B: `Añadiremos reuniones al calendario para `,
  INSTRUCTION_STEP_C1: `Encriptaremos `,
  INSTRUCTION_STEP_C2: `todos los datos`,
  INSTRUCTION_STEP_C3: `en tránsito y ofrecerle opciones con respecto a sus datos.`,
  INSTRUCTION_TITLE: `Así es como SUMO funcionará con `,
  MESSAGE: `SUMO revisará automáticamente tu calendario en busca de momentos ocupados y agregará nuevas reuniones a medida que se programen.`,
  TITLE: `Calendario`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Aceptar términos`,
  ACCEPT_TERMS_PART2: `y`,
  ACCEPT_TERMS_PART3: `Política de privacidad`,
  BENEFITS_DESCRIPTION: `La mejor experiencia con SUMO1 es usar SUMO desde tu bandeja de entrada mediante la instalación de la extensión para Chrome. Usa SUMO en:`,
  BENEFITS_LIST_PART1: `Google Gmail y Calendario`,
  BENEFITS_LIST_PART2: `Microsoft Inbox y Calendario`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Beneficios`,
  CHROME_EXTENSION: `Extensión para Chrome`,
  REQUIREMENTS_DESCRIPTION: `Debes utilizar el navegador Google Chrome.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SUMO1_FOR_GOOGLE: `Instalar "SUMO1 para Gmail"`,
  SUMO1_FOR_MICROSOFT: `Instalar "SUMO1 para Outlook"`,
  TITLE: `Instalar extensión para Chrome`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  ADMIN_DESCRIPTION: `¿Cuándo está disponible típicamente tu equipo para citas?`,
  MESSAGE: `CONSEJO: Puedes configurar múltiples horarios y excepciones más tarde.`,
  MINIMUM_DAYS_ERROR_TEXT: `Seleccione al menos un día con disponibilidad horaria`,
  SETUP_DAYS_INPUT_LABEL: `Días disponibles`,
  SETUP_HOURS_INPUT_LABEL: `Horas disponibles`,
  TITLE: `Disponibilidad`,
  USER_DESCRIPTION: `¿Cuándo estás típicamente disponible para citas?`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Cuando SUMO reserva automáticamente una llamada telefónica para ti, ¿a qué número de teléfono debería llamar el invitado para comunicarse contigo?`,
  MESSAGE: `Este es un paso importante en el proceso de configuración de SUMO. La mayoría de los usuarios ingresan su número de teléfono móvil para recibir también alertas por SMS. Por supuesto, siempre puedes cambiar esto más tarde en Configuración de usuario (menú en la esquina superior derecha).`,
  PHONE_DETAILS_LABELS: `Detalles del teléfono`,
  PHONE_DETAILS_PLACEHOLDER: `Agrega información adicional o instrucciones aquí.\r\nEjemplo: Espero con interés la reunión. Envíame un SMS en cualquier momento.`,
  PHONE_LABEL: `Tu número de teléfono`,
  PHONE_PLACEHOLDER: `Ingresa tu número de teléfono aquí`,
  TITLE: `Llamadas telefónicas`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Error al guardar la configuración rápida`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Ahora estás listo para programar fácilmente.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `¡Configuración completa!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  COMPLETED_BUTTON_LABEL: `He completado esto.`,
  DEFAULT_DESCRIPTION: `Conferencia de Video Predeterminada`,
  DESCRIPTION: `Conecta tus cuentas de videoconferencia.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MESSAGE: `Opcional, pero recomendado para reuniones virtuales.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  TITLE: `Videoconferencia`,
  ZOOM_DESC: `Agrega automáticamente detalles de Zoom en tus reuniones de SUMO.`,
  ZOOM_REQUIREMENTS: `Según tu configuración, es posible que el administrador de tu cuenta de Zoom deba aprobar previamente SUMO1 en el Mercado de Zoom.`,
  ZOOM_STEP_1: `Inicia sesión en tu cuenta corporativa de ZOOM como administrador.`,
  ZOOM_STEP_2: `Haz clic en Configuración en la esquina superior derecha.`,
  ZOOM_STEP_3: `Busca en el mercado de ZOOM "SUMO1".`,
  ZOOM_STEP_4: `Haz clic en "Autorizar SUMO1".`,
  ZOOM_STEP_5: `Haz clic en Aceptar.`,
  ZOOM_TITLE: `Reuniones ZOOM`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  COLORS_NOTES: `No te preocupes, puedes modificar y probar estos ajustes en las páginas de reservas más tarde.`,
  COLORS_TITLE: `Colores y fuentes`,
  BACKGROUND: `Fondo`,
  DESCRIPTION: `Haz que la experiencia de reserva refleje la marca de tu empresa.`,
  LOGO: `Logotipo`,
  LOGO_DESCRIPTION: `La marca de tu empresa aparecerá en todas las páginas de reserva en este espacio de trabajo.`,
  LOGO_TITLE: `Tu logotipo y fondo`,
  PREVIEW_TOOLTIP: `Después de ajustar los ajustes, haz clic en Vista previa para ver una página de reserva de muestra.`,
  SAVE_BUTTON: `Guardar y completar la configuración`,
  TITLE: `Tu marca`,
  UPLOAD_BACKGROUND: `Subir fondo`,
  UPLOAD_LOGO: `Subir logotipo`,
  YOUR_BACKGROUND: `Tu fondo`,
  YOUR_LOGO: `Tu logotipo`,
});
