import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import labels from './labels';

import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { ColorPickerList } from '../../common/colorPickerList/ColorPickerList';

export const BookingTemplateWhatStep = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingTemplatesSelectors.selectWhatName);
  const color = useSelector(bookingTemplatesSelectors.selectWhatColor);
  const isNameDuplicate = useSelector(bookingTemplatesSelectors.selectIsNameDuplicate);
  const isNameValid = useSelector(bookingTemplatesSelectors.selectIsNameValid);
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);

  const [nameValue, setNamevalue] = useState(name);

  const handleNameChange = (name: string) => {
    setNamevalue(name);
  };

  const handleNameBlur = () => {
    dispatch(bookingTemplatesActions.updateWhatStep({ customName: nameValue || '' }));
  };

  const handleColorChange = (color: string) => {
    dispatch(bookingTemplatesActions.updateWhatStep({ color }));
  };

  return (
    <div>
      <div className="grid  p-fluid">
        <div className="col-12 md:col-6">
          <div>
            <label htmlFor="bookingTemplateName">{labels.eventNameInputLabel}</label>
            <InputText
              id="bookingTemplateName"
              type="text"
              required
              value={nameValue || ''}
              onChange={(e) => handleNameChange(e.target.value.trimStart())}
              onBlur={handleNameBlur}
              className={`${!isNameValid && 'p-invalid'}`}
              maxLength={MAX_LENGTH_NAME}
              disabled={isReadOnly}
            />
            {isNameDuplicate && <div className="pl-2 text-red-500">{labels.duplicate}</div>}
          </div>

          <div className="mt-4">
            <ColorPickerList color={color} handleColorChange={handleColorChange} />
          </div>
        </div>
      </div>
    </div>
  );
};
