import { combineReducers } from 'redux';
import { DEFAULT_FILTERING, FilteringType } from './types';
import { OpsConsoleAction, OpsConsoleActionTypes } from './actions';
import { GlobalAction, GlobalActionTypes } from '../../global/actions';

const filtering = (state: FilteringType = DEFAULT_FILTERING, action: OpsConsoleAction | GlobalAction) => {
  switch (action.type) {
    case OpsConsoleActionTypes.SET_FILTERING:
      return { ...action.payload, searchString: state.searchString };
    case OpsConsoleActionTypes.SET_SEARCH_STRING:
      return { ...state, searchString: action.payload };
    case OpsConsoleActionTypes.RESET_FILTERING:
      return DEFAULT_FILTERING;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_FILTERING;
    default:
      return state;
  }
};

export default combineReducers({ filtering });
