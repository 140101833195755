import { UpdateBookingTemplateInput, UpdateBookingPageInput, UpdateUserEventInput, CanceledEventInput } from '../../API';

export type GetAgendaRequestType = {
  bookingPageId?: string;
  excludeEventId?: string;
  eventId?: string;
  timeZone?: string;
  startTime?: number;
  endTime?: number;
};

export type HostData = {
  name?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  phoneDetails?: string;
  isTeam?: boolean;
};

export type AgendaType = {
  bookingPage: UpdateBookingPageInput;
  event?: UpdateUserEventInput;
  spots?: string[];
  initialHost?: HostData;
  initialCohosts?: HostData[];
  host?: HostData;
  cohosts?: HostData[];
};

export type RescheduleData = {
  event: UpdateUserEventInput;
  bookingPage?: UpdateBookingPageInput;
};

export type PreviewData = {
  bookingPage?: UpdateBookingPageInput;
  bookingTemplate?: UpdateBookingTemplateInput;
};

export enum EventSteps {
  // WHAT = 'WHAT',
  // WHERE = 'WHERE',
  WHEN = 'WHEN',
  INFO = 'INFO',
  BOOKED = 'BOOKED',
  CANCEL = 'CANCEL',
}

export enum EventAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL',
}

export type ViewSettings = {
  editKey: string | null;
  hideHeader: boolean;
  hidePageDetails: boolean;
  hideCookie: boolean;
  iframe: boolean;
};

export type CancelEventRequestType = {
  eventId: string;
  canceled?: CanceledEventInput | null;
};

export type CreateUpdateEventResponse = {
  event: UpdateUserEventInput;
  host?: HostData;
  cohosts?: HostData[];
};
