import { ChargeeBeeResponse, Subscription } from './types';

export enum billingActionTypes {
  GET_BILLING_REQUEST = 'billing/GET_BILLING_REQUEST',
  GET_BILLING_SUCCESS = 'billing/GET_BILLING_SUCCESS',
  GET_BILLING_FAIL = 'billing/GET_BILLING_FAIL',
  CHANGE_SEATS_REQUEST = 'billing/CHANGE_SEATS_REQUEST',
  CHANGE_SEATS_SUCCESS = 'billing/CHANGE_SEATS_SUCCESS',
  CHANGE_SEATS_FAIL = 'billing/CHANGE_SEATS_FAIL',
  CHANGE_TERM_REQUEST = 'billing/CHANGE_TERM_REQUEST',
  CHANGE_TERM_SUCCESS = 'billing/CHANGE_TERM_SUCCESS',
  CHANGE_TERM_FAIL = 'billing/CHANGE_TERM_FAIL',
  DELETE_SCHEDULED_CHANGE_REQUEST = 'billing/DELETE_SCHEDULED_CHANGE_REQUEST',
  DELETE_SCHEDULED_CHANGE_SUCCESS = 'billing/DELETE_SCHEDULED_CHANGE_SUCCESS',
  DELETE_SCHEDULED_CHANGE_FAIL = 'billing/DELETE_SCHEDULED_CHANGE_FAIL',
  SET_ADD_OR_REMOVE_SEATS = 'billing/SET_ADD_OR_REMOVE_SEATS',
  SET_CHANGE_SEATS = 'billing/SET_CHANGE_SEATS',
  SET_TERM = 'billing/SET_TERM',
}

export type billingAction =
  | { type: billingActionTypes.GET_BILLING_REQUEST }
  | { type: billingActionTypes.GET_BILLING_SUCCESS; payload: Subscription }
  | { type: billingActionTypes.GET_BILLING_FAIL; error: string }
  | { type: billingActionTypes.CHANGE_SEATS_REQUEST }
  | { type: billingActionTypes.CHANGE_SEATS_SUCCESS; payload: ChargeeBeeResponse }
  | { type: billingActionTypes.CHANGE_SEATS_FAIL; error: string }
  | { type: billingActionTypes.CHANGE_TERM_REQUEST }
  | { type: billingActionTypes.CHANGE_TERM_SUCCESS; payload: ChargeeBeeResponse }
  | { type: billingActionTypes.CHANGE_TERM_FAIL; error: string }
  | { type: billingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST; payload: string }
  | { type: billingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS }
  | { type: billingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL; error: string }
  | { type: billingActionTypes.SET_ADD_OR_REMOVE_SEATS; payload: boolean }
  | { type: billingActionTypes.SET_CHANGE_SEATS; payload: number }
  | { type: billingActionTypes.SET_TERM; payload: string };

const getBillingRequest = (): billingAction => ({ type: billingActionTypes.GET_BILLING_REQUEST });
const getBillingSuccess = (payload: Subscription): billingAction => ({
  type: billingActionTypes.GET_BILLING_SUCCESS,
  payload,
});
const getBillingFail = (error: string): billingAction => ({ type: billingActionTypes.GET_BILLING_FAIL, error });

const changeSeatsRequest = (): billingAction => ({ type: billingActionTypes.CHANGE_SEATS_REQUEST });
const changeSeatsSuccess = (payload: ChargeeBeeResponse): billingAction => ({
  type: billingActionTypes.CHANGE_SEATS_SUCCESS,
  payload,
});
const changeSeatsFail = (error: string): billingAction => ({ type: billingActionTypes.CHANGE_SEATS_FAIL, error });

const changeTermRequest = (): billingAction => ({ type: billingActionTypes.CHANGE_TERM_REQUEST });
const changeTermSuccess = (payload: ChargeeBeeResponse): billingAction => ({
  type: billingActionTypes.CHANGE_TERM_SUCCESS,
  payload,
});
const changeTermFail = (error: string): billingAction => ({ type: billingActionTypes.CHANGE_TERM_FAIL, error });

const deleteScheduledChangeRequest = (payload: string): billingAction => ({
  type: billingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST,
  payload,
});
const deleteScheduledChangeSuccess = (): billingAction => ({
  type: billingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS,
});
const deleteScheduledChangeFail = (error: string): billingAction => ({
  type: billingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL,
  error,
});

const setAddOrRemoveSeats = (payload: boolean): billingAction => ({
  type: billingActionTypes.SET_ADD_OR_REMOVE_SEATS,
  payload,
});

const setChangeSeats = (payload: number): billingAction => ({
  type: billingActionTypes.SET_CHANGE_SEATS,
  payload,
});

const setTerm = (payload: string): billingAction => ({
  type: billingActionTypes.SET_TERM,
  payload,
});

export const billingActions = {
  getBillingRequest,
  getBillingSuccess,
  getBillingFail,
  changeSeatsRequest,
  changeSeatsSuccess,
  changeSeatsFail,
  changeTermRequest,
  changeTermSuccess,
  changeTermFail,
  deleteScheduledChangeRequest,
  deleteScheduledChangeSuccess,
  deleteScheduledChangeFail,
  setAddOrRemoveSeats,
  setChangeSeats,
  setTerm,
};
