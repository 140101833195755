import { SelectItemOptionsType } from 'primereact/selectitem';
import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import { Subscription } from './types';

export const DEFAULT_SUBSCRIPTION: Subscription = {
  billing_period: 0,
  billing_period_unit: '',
  status: '',
  current_term_start: 0,
  current_term_end: 0,
  next_billing_at: 0,
  created_at: 0,
  updated_at: 0,
  has_scheduled_changes: false,
  currency_code: '',
  subscription_items: [
    {
      item_price_id: '',
      item_type: '',
      quantity: 0,
      unit_price: 0,
      amount: 0,
    },
  ],
};

export const DEFAULT_SEATS_AMOUNT = 1;
export const MAX_SEATS_AMOUNT = 1000;
export const CHARGEBEE_STATUS_ACTIVE = 'active';
export const CHARGEBEE_MONTH_UNIT = 'month';
export const CHARGEBEE_YEAR_UNIT = 'year';

export const CHARGEBEE_MONTHLY_TERM = process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Monthly';
export const CHARGEBEE_YEARLY_TERM = process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Yearly';

// TODO: request tiers from ChargeBee before buy a fisrt licence
export const TIERS_DEFAULT_YEARLY = [
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 1,
      "ending_unit": 50,
      "price": 15600,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 51,
      "ending_unit": 150,
      "price": 13200,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 151,
      "ending_unit": 250,
      "price": 12000,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 251,
      "ending_unit": 500,
      "price": 10800,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 501,
      "ending_unit": 1000,
      "price": 9600,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 1001,
      "ending_unit": 2500,
      "price": 7200,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 2501,
      "ending_unit": 5000,
      "price": 6000,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Yearly",
      "starting_unit": 5001,
      "price": 4800,
      "object": "item_tier"
  }
]

export const TIERS_DEFAULT_MONTHLY = [
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 1,
      "ending_unit": 50,
      "price": 1600,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 51,
      "ending_unit": 150,
      "price": 1400,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 151,
      "ending_unit": 250,
      "price": 1300,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 251,
      "ending_unit": 500,
      "price": 1100,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 501,
      "ending_unit": 1000,
      "price": 1000,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 1001,
      "ending_unit": 2500,
      "price": 800,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 2501,
      "ending_unit": 5000,
      "price": 600,
      "object": "item_tier"
  },
  {
      "item_price_id": "SUMO1-Silver-USD-Monthly",
      "starting_unit": 5001,
      "price": 500,
      "object": "item_tier"
  }
]

export const expireMessage1Label = t('Billing:EXPIRE_MESSAGE_PART_1');
export const expireMessage2Label = t('Billing:EXPIRE_MESSAGE_PART_2');
export const expireMessage3Label = t('Billing:EXPIRE_MESSAGE_PART_3');
export const seatsLabel = t('Billing:SEATS');
export const teamsMonthlyLabel = t('Billing:TEAMS_MONTHLY');
export const teamsAnnualLabel = t('Billing:TEAMS_ANNUAL');
export const scheduledChanges1Label = t('Billing:SCHEDULED_SEATS_CHANGE_PART_1');
export const scheduledChanges2Label = t('Billing:SCHEDULED_SEATS_CHANGE_PART_2');
export const termChangeLabel = t('Billing:SCHEDULED_TERM_CHANGE');
export const billIsLatePart1Label = t('Billing:BILL_IS_LATE_PART_1');
export const billIsLatePart2SingleLabel = t('Billing:BILL_IS_LATE_PART_2_SINGLE');
export const billIsLatePart2MultipleLabel = t('Billing:BILL_IS_LATE_PART_2_MULTIPLE');

export const CHARGEBEE_TERM_OPTIONS: SelectItemOptionsType = [
  { value: CHARGEBEE_MONTHLY_TERM, label: teamsMonthlyLabel },
  { value: CHARGEBEE_YEARLY_TERM, label: teamsAnnualLabel },
];

export const GET_BILLING_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('Billing:GET_BILLING_ERROR_TOAST'),
});

export const ADD_SEATS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('Billing:ADD_SEATS_ERROR_TOAST'),
});
export const REMOVE_SEATS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('Billing:REMOVE_SEATS_ERROR_TOAST'),
});

export const CHANGE_TERM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('Billing:CHANGE_TERM_SUCCESS_TOAST'),
});
export const CHANGE_TERM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('Billing:CHANGE_TERM_ERROR_TOAST'),
});

export const DELETE_CHANGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('Billing:DELETE_CHANGE_SUCCESS_TOAST'),
});
export const DELETE_CHANGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('Billing:DELETE_CHANGE_ERROR_TOAST'),
});

export const getAddSeatsSuccessToast = (seats: number) =>
  ({
    color: 'success',
    autoHideDuration: 6000,
    title: `${seats} ${t('Billing:ADD_SEATS_SUCCESS_TOAST_TITLE')}`,
    message: t('Billing:ADD_SEATS_SUCCESS_TOAST'),
  } as ToastNotificationOptions);
export const getRemoveSeatsSuccessToast = (seats: number) =>
  ({
    color: 'success',
    autoHideDuration: 3000,
    title: `${seats} ${t('Billing:REMOVE_SEATS_SUCCESS_TOAST_TITLE')}`,
    message: t('Billing:REMOVE_SEATS_SUCCESS_TOAST'),
  } as ToastNotificationOptions);
