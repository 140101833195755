import { t } from '../../i18n/i18n';

export default {
  search: t('Common:SEARCH'),
  selected: t('Common:SELECTED'),
  title: t('BookingPages:TITLE'),
  newBookingPage: t('BookingPages:NEW_BOOKING_PAGE'),
  confirmDelete: t('Common:CONFIRM_DELETE'),
  deleteAccountDialogCancel: t('Common:CANCEL'),
  deleteAccountDialogDelete: t('Common:DELETE'),
  defaultFilter: t('BookingPages:DEFAULT_FILTER'),
  noResults: t('Common:NO_RESULTS_FOUND'),
  sharedLabel: t('BookingPages:SHARED_LABEL'),
};
