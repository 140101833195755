import { CreateUserEventInput, UpdateUserEventInput } from '../../API';
import { APIPostRequest } from '../utils/reduxUtils';

export async function fetchEvents() {
  try {
    const response = (await APIPostRequest('/api/js/getScheduledMeetings', {})) as CreateUserEventInput[];

    if (response) {
      return response;
    } else {
      throw new Error('scheduled meetings not found');
    }
  } catch (error: unknown) {
    console.error('Redux scheduledMeetings service; Error fetching scheduled meetings:', error);
    throw error;
  }
}

export async function updateEvent(input: UpdateUserEventInput) {
  try {
    await APIPostRequest('/api/js/editUserEvent', { data: input });
  } catch (error: unknown) {
    console.error('Redux scheduledMeetings service; Error update scheduled meeting:', error);
    throw error;
  }
}
