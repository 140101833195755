import React, { CSSProperties, ReactNode, isValidElement, memo } from 'react';
import { useSelector } from 'react-redux';
import { quickSetupNavigationSelectors } from '../../../store/quickSetup';
import { ProgressBar } from 'primereact/progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import labels from './labels';

interface QuickSetupScreenButtons {
  children: ReactNode;
}

interface QuickSetupScreenContainer {
  children: ReactNode;
  title?: string;
  style?: CSSProperties;
}

const Buttons = memo(({ children }: QuickSetupScreenButtons) => <>{children}</>);

const Container = memo(({ children, title, style }: QuickSetupScreenContainer) => {
  const progressValue = useSelector(quickSetupNavigationSelectors.selectCurrentProgressValue);

  let buttons: ReactNode;
  const content: ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (!isValidElement(child)) return;

    if (child.type === Buttons) {
      buttons = child;
    } else {
      content.push(child);
    }
  });

  return (
    <div style={style}> 
      <div className="card sumo-header-bg mb-3 p-0">
        <ProgressBar value={progressValue} showValue={false} className="border-noround-bottom"/>
        <div className="flex align-items-center w-full p-4">
          <div className="md:w-4 flex">
            <FontAwesomeIcon icon={faRocket} className="mr-3 text-2xl" />
            <h5 className="m-0">{labels.title}</h5>
          </div>
          <div className="flex-grow-1 md:flex-grow-0 md:w-4 text-center text-xl uppercase">{title}</div>
        </div>
      </div>
      <div className="card flex flex-column justify-content-between" style={{ minHeight: '500px' }}>
        {content}
        {buttons && <div className="mt-4">{buttons}</div>}
      </div>
    </div>
  );
});

export const QuickSetupScreen = { Buttons, Container };

Buttons.displayName = 'QuickSetupScreen.Buttons';
Container.displayName = 'QuickSetupScreen.Container';
