export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Hintergrund`,
  BUTTONS_LABEL: `Schaltflächen`,
  FONT_COLORS_TITLE: `Schriftfarben`,
  FONT_FAMILIES_TITLE: `Schriftarten`,
  HEADER_FONT_LABEL: `Header-Schrift`,
  HEADER_LABEL: `Kopfzeile`,
  MAIN_TEXT_FONT_LABEL: `Hauptschriftart`,
  MAIN_TEXT_LABEL: `Haupttext`,
  PRIMARY_LABEL: `Primär`,
  SITE_COLORS_TITLE: `Website-Farben`,
  SUB_HEADER_FONT_LABEL: `Unterkopfzeile Schriftart`,
  SUB_HEADER_LABEL: `Unterkopfzeile`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `Ihr Kalender ist verbunden!`,
  INSTRUCTION_NOTE: `Die meisten Administratoren verwenden SUMO gerne für ihren persönlichen und Testzweck. Sie können Ihren Kalender jedoch jederzeit über das Menü oben rechts trennen.`,
  INSTRUCTION_STEP_A1: `Wir überprüfen `,
  INSTRUCTION_STEP_A2: ` auf Konflikte.`,
  INSTRUCTION_STEP_B: `Wir fügen dem Kalender Meetings für `,
  INSTRUCTION_STEP_C1: `Wir verschlüsseln `,
  INSTRUCTION_STEP_C2: `alle Daten`,
  INSTRUCTION_STEP_C3: `sich auf dem Weg befindet und Ihnen Optionen zu Ihren Daten bietet.`,
  INSTRUCTION_TITLE: `So wird SUMO mit `,
  MESSAGE: `SUMO überprüft automatisch Ihren Kalender auf geschäftige Zeiten und fügt neue Meetings hinzu, wenn sie geplant sind.`,
  TITLE: `Kalender`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Allgemeine Geschäftsbedingungen`,
  ACCEPT_TERMS_PART2: `und`,
  ACCEPT_TERMS_PART3: `Datenschutzrichtlinien akzeptieren`,
  BENEFITS_DESCRIPTION: `Die beste SUMO1-Erfahrung besteht darin, SUMO über die Chrome-Erweiterung direkt aus Ihrem Posteingang zu verwenden. Verwenden Sie SUMO in:`,
  BENEFITS_LIST_PART1: `Google Gmail & Kalender`,
  BENEFITS_LIST_PART2: `Microsoft Posteingang & Kalender`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Vorteile`,
  CHROME_EXTENSION: `Chrome-Erweiterung`,
  REQUIREMENTS_DESCRIPTION: `Sie müssen den Google Chrome-Browser verwenden.`,
  REQUIREMENTS_TITLE: `Anforderungen`,
  SUMO1_FOR_GOOGLE: `Installieren Sie "SUMO1 für Gmail"`,
  SUMO1_FOR_MICROSOFT: `Installieren Sie "SUMO1 für Outlook"`,
  TITLE: `Chrome-Erweiterung installieren`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  ADMIN_DESCRIPTION: `Wann ist Ihr Team normalerweise für Termine verfügbar?`,
  MESSAGE: `TIPP: Sie können später mehrere Zeitpläne und Ausnahmen festlegen.`,
  MINIMUM_DAYS_ERROR_TEXT: `Bitte wählen Sie mindestens einen Tag mit verfügbaren Stunden`,
  SETUP_DAYS_INPUT_LABEL: `Verfügbare Tage`,
  SETUP_HOURS_INPUT_LABEL: `Verfügbare Stunden`,
  TITLE: `Verfügbarkeit`,
  USER_DESCRIPTION: `Wann sind Sie normalerweise für Termine verfügbar?`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Wenn SUMO automatisch einen Telefonanruf für Sie bucht, unter welcher Telefonnummer soll der Gast Sie erreichen?`,
  MESSAGE: `Dies ist ein wichtiger Schritt im SUMO-Setup-Prozess. Die meisten Benutzer geben ihre Mobiltelefonnummer ein, um auch SMS-Benachrichtigungen zu erhalten. Natürlich können Sie dies später in den Benutzereinstellungen (oben rechts im Menü) ändern.`,
  PHONE_DETAILS_LABELS: `Telefonangaben`,
  PHONE_DETAILS_PLACEHOLDER: `Fügen Sie hier zusätzliche Informationen oder Anweisungen hinzu.\r\nBeispiel: Ich freue mich auf das Treffen. SMS mir jederzeit.`,
  PHONE_LABEL: `Ihre Telefonnummer`,
  PHONE_PLACEHOLDER: `Geben Sie hier Ihre Telefonnummer ein`,
  TITLE: `Telefonanrufe`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Fehler bei der Anforderung zum Speichern der Schnelleinrichtung`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Sie sind jetzt bereit für eine einfache Terminplanung.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Einrichtung abgeschlossen!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  COMPLETED_BUTTON_LABEL: `Ich habe das abgeschlossen.`,
  DEFAULT_DESCRIPTION: `Standard Videokonferenz`,
  DESCRIPTION: `Verbinden Sie Ihre Videokonferenzkonten.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MESSAGE: `Optional, aber empfohlen für virtuelle Meetings.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  TITLE: `Videokonferenz`,
  ZOOM_DESC: `Fügen Sie Zoom-Details automatisch in Ihre SUMO-Meetings ein.`,
  ZOOM_REQUIREMENTS: `Je nach Ihrer Konfiguration muss der Administrator Ihres Zoom-Kontos SUMO1 möglicherweise im Zoom Marketplace vorab genehmigen.`,
  ZOOM_STEP_1: `Melden Sie sich als Administrator bei Ihrem Unternehmenskonto bei ZOOM an.`,
  ZOOM_STEP_2: `Klicken Sie oben rechts auf Einstellungen.`,
  ZOOM_STEP_3: `Suchen Sie im ZOOM-Marktplatz nach "SUMO1".`,
  ZOOM_STEP_4: `Klicken Sie auf "SUMO1 autorisieren".`,
  ZOOM_STEP_5: `Klicken Sie auf Akzeptieren.`,
  ZOOM_TITLE: `ZOOM-Meetings`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  COLORS_NOTES: `Keine Sorge, Sie können diese Einstellungen später auf den Buchungsseiten ändern und testen.`,
  COLORS_TITLE: `Farben und Schriftarten`,
  BACKGROUND: `Hintergrund`,
  DESCRIPTION: `Gestalten Sie das Buchungserlebnis entsprechend dem Markenbild Ihres Unternehmens.`,
  LOGO: `Logo`,
  LOGO_DESCRIPTION: `Ihre Unternehmensmarke wird auf allen Buchungsseiten in diesem Arbeitsbereich angezeigt.`,
  LOGO_TITLE: `Ihr Logo & Hintergrund`,
  PREVIEW_TOOLTIP: `Nachdem Sie die Einstellungen angepasst haben, klicken Sie auf Vorschau, um eine Beispielseite für Buchungen anzuzeigen.`,
  SAVE_BUTTON: `Speichern und Einrichtung abschließen `,
  TITLE: `Ihre Marke`,
  UPLOAD_BACKGROUND: `Hintergrund hochladen`,
  UPLOAD_LOGO: `Logo hochladen`,
  YOUR_BACKGROUND: `Ihr Hintergrund`,
  YOUR_LOGO: `Ihr Logo`,
});
