import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { userSettingsSelectors } from '../../store/userSettings';
import { CookieConsent, Preloader, SectionTitle } from '../../components/common';
import { AccountMenuItem } from '../../types/types';
import { Path } from '../../routing';
import labels from './labels';
import { ListBox } from 'primereact/listbox';
import { timeHandlerService } from '../../services/TimeHandlerService';
import { authentificationActions } from '../../store/authentification';

type MenuItem = {
  name: string;
  path?: string;
  isModal?: boolean;
};

export const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const [selectedMenu, setSelectedMenu] = useState<AccountMenuItem>();
  const { pathname } = useLocation();

  const menuItems: AccountMenuItem[] = [
    { name: labels.titleAccount, path: Path.Account },
    { name: labels.titleLogin, path: Path.MyLogin },
    { name: labels.titleMyRole, path: Path.MyRole },
    { name: labels.titleCookies, isModal: true },
  ];

  useEffect(() => {
    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, []);

  useEffect(() => {
    setSelectedMenu(menuItems.find((item) => item.path && pathname.match(new RegExp(`${item.path}$`))));
  }, [pathname]);

  const handleMenuItemClick = (item: MenuItem) => {
    if (!item) {
      return;
    }
    if (item.isModal) {
      dispatch(authentificationActions.setCookieOpen(true));
    } else {
      if (item.path) {
        setSelectedMenu(item);
        navigate(item.path);
      }
    }
  };

  const menuItemTemplate = (item: MenuItem) => {
    return (
      <div className="">
        <span>{item.name}</span>
      </div>
    );
  };

  return (
    <div className="grid">
      {isFetching && <Preloader />}
      <CookieConsent />

      <div className="col-12">
        <div className="card mb-1 flex justify-content-between align-items-center sumo-header-bg">
          <SectionTitle
            id="accountTitle"
            icon="pi-cog"
            title={labels.titleProfile}
          />
        </div>
      </div>

      <div className="col-12 md:col-4">
        <div className="card p-fluid">
          <ListBox
            value={selectedMenu}
            onChange={(e) => handleMenuItemClick(e.value)}
            options={menuItems}
            optionLabel="name"
            itemTemplate={menuItemTemplate}
            className="border-none font-semibold"
            listClassName="flex flex-column"
          />
        </div>
      </div>

      <div className="col-12 md:col-8">
        <div className="card p-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
