import { Path } from '../../../routing/pathes';
import { navigationService } from '../../../services/NavigationService';
import labels from './labels';

export const InviteExpiredToast = () => {
  const handleSignUp = () => {
    navigationService.navigateTo(Path.Landing);
    window.location.reload();
  };

  return (
    <div className="flex flex-column align-items-left">
      <div className="font-bold text-lg">{labels.title}</div>
      <div className="my-2">
        {labels.messagePart1}
        <span className="cursor-pointer hover:underline text-primary-900" onClick={handleSignUp}>
          {labels.messagePart2}
        </span>
        .
      </div>
    </div>
  );
};
