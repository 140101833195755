export const PublicBookingPage = Object.freeze({
  HOST: `Anfitrião:`,
  HOST_PHONE: `Telefone anfitrião`,
  EDIT_BUTTON: `Editar`,
  MORE: `Mais`,
  TIME_ZONE_LABEL: `Fuso Horário`,
  NAME_FIELD_LABEL: `Nome`,
  EMAIL_FIELD_LABEL: `E-mail`,
  INVITE_OTHERS_FIELD_LABEL: `Convidar Outros`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Convide outros inserindo seus endereços de e-mail separados por vírgulas`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Nota para os Participantes`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Digite uma nota para todos os participantes`,
  NO_USERS_AVAILABLE_LABEL: `Não há usuários disponíveis no horário selecionado. Por favor, selecione outro horário.`,
  CANCEL_TYPE_LABEL: `Por que cancelar?`,
  CANCEL_REASON_LABEL: `Motivo do Cancelamento:`,
  IN_PERSON_LABEL: `Presencial`,
  PHONE_LABEL: `Telefone`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Erro`,
  VIDEO_CONFERENCE_LABEL: `Videoconferência`,
  SLOTS_NO_FOUND: `Slots não encontradas`,
  VIEW_NEXT_MONTH: `Ver próximo mês`,
  ALLOW_SMS: `Receber lembretes de compromissos por SMS?`,
});

export const ScheduledMeetings = Object.freeze({
  ATTENDEES: `Convidados`,
  CANCELED: 'Cancelado',
  CANCEL_MEETING_TITLE: `Cancelar Reunião`,
  CANCEL_MEETING_DESCRIPTION: `Por que cancelar?`,
  CANCEL_REASON: `Motivo do Cancelamento:`,
  DETAILS: `Detalhes`,
  BOOKED: `Reservado`,
  BOOKING_PAGE: `Página de Reserva`,
  BOOKING_PAGE_PLACEHOLDER: `Selecionar página de reserva`,
  EDIT_ATTENDEE_LIMIT: `Editar Limite de Participantes`,
  GOOGLE_MEET: `Google Meet`,
  HOSTS: `Anfitriões`,
  IN_PERSON: `Presencial`,
  INVITEE_ANSWERS: `Respostas dos convidados`,
  LOCATION_TITLE: `Localização`,
  NO_SCHEDULED_MEETINGS: `Você não tem reuniões agendadas`,
  NO_LOCATION_GIVEN: `Nenhuma localização fornecida`,
  NO_SHOW_LABEL: `Não Compareceu`,
  MARK_AS_NO_SHOW: `Marcar como Não Compareceu`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Tem certeza de que deseja marcar`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `como Não Compareceu?`,
  MARK_AS_NO_SHOW_TOOLTIP: `O evento ainda não começou.`,
  MEETING_NOTES: `Notas Internas da Reunião`,
  MEETING_NOTES_DESC: `Somente o anfitrião verá essas notas.`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FOR_ATTENDEES: `Nota para os Participantes`,
  PAST: `Antigos`,
  PHONE_CALL: `Ligação Telefônica`,
  RESCHEDULE: `Reagendar`,
  REASON_UNEXPECTED: `Conflito Inesperado`,
  REASON_INVITE: `Precisa convidar outros`,
  REASON_TIME: `Precisa de tempo para se preparar`,
  REASON_OTHER: `Outro Motivo`,
  STATUS: `Status`,
  STATUS_PLACEHOLDER: `Selecionar estado`,
  TEAM: `Equipe`,
  TITLE: `Reuniões agendadas`,
  VIEW_ATTENDEES: `Ver Participantes`,
  VIEW_ATTENDEES_DESC: `Estes são os participantes atuais para esta reunião`,
  VIEW_ATTENDEES_NOTE: `O anfitrião pode adicionar ou remover participantes de seu calendário conectado.`,
  UNDO_NO_SHOW: `Desfazer Não Compareceu`,
  UPCOMING: `Próximos`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Videoconferência`,
});

export const ScheduledMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Falha na solicitação de eventos agendados`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Falha na solicitação de cancelamento de evento`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Solicitação de cancelamento de evento bem-sucedida`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Falha na solicitação de atualização de evento`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Solicitação de exclusão de evento bem-sucedida`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Falha na solicitação de exclusão de evento`,
});

export const HostNotificationTemplates = Object.freeze({
  HOST_CONFIRMATION_TEMPLATE_SUBJECT: `Nova reunião: <Meeting Name> com <Invitee Name> às <Meeting Time>, <Meeting Date>`,
  HOST_RESCHEDULE_SUBJECT: `Reagendado: <Meeting Name> com <Invitee Name>`,
  HOST_CANCELATION_SUBJECT: `Cancelado: <Meeting Name> com <Invitee Name> às <Meeting Time>, <Meeting Date>`,
  HOST_CONFIRMATION_TEMPLATE_BODY: `Olá <Host Name>,

Sua nova reunião <Meeting Name> está agendada.

<b>Convidado</b>
<Invitee Name> ( <Invitee Email> )

<b>Hora e Data</b>
<Meeting Time>, <Meeting Date>

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_RESCHEDULE_BODY: `Olá <Host Name>,

Sua reunião <Meeting Name> foi reagendada.

<b>Convidado</b>
<Invitee Name> ( <Invitee Email> )

<b>Hora e Data atualizadas</b>
<Meeting Time>, <Meeting Date>

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_CANCELATION_BODY: `Olá <Host Name>,

Sua reunião <Meeting Name> foi cancelada.

<b>Convidado</b>
<Invitee Name> ( <Invitee Email> )

<b>Hora e Data</b>
<Meeting Time>, <Meeting Date>

<b>Local</b>
<Meeting Location>

<b>Motivo do cancelamento</b>
<Cancel Reason>`,
});
