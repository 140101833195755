export const OPSConsoleStaff = Object.freeze({
  ACTIVE: `Activo`,
  ADD_NEW_MEMBER: `Agregar nuevo miembro`,
  ADMIN_TITLE: `Administrador principal`,
  INACTIVE: `Inactivo`,
  SUPPORT_TITLE: `Soporte`,
  OPERATIONS_TITLE: `Operaciones`,
  TITLE: `Personal`,
  ADD_STAFF_TITLE: `Crear miembro del personal`,
  DUPLICATE_EMAIL: `Este correo electrónico ya ha sido agregado.`,
});

export const StaffToastsNotifications = Object.freeze({
  GET_STAFF_FAIL_MESSAGE: `Error al solicitar personal`,
  SAVE_STAFF_SUCCESS_MESSAGE: `Éxito al guardar la solicitud de personal`,
  SAVE_STAFF_FAIL_MESSAGE: `Error al solicitar personal`,
  CHANGE_STAFF_STATUS_FAIL_MESSAGE: `Error al solicitar cambio de estado del personal`,
  ACTIVATE_STAFF_SUCCESS_MESSAGE: `Éxito al activar el registro del personal`,
  DEACTIVATE_STAFF_SUCCESS_MESSAGE: `Éxito al desactivar el registro del personal`,
  DELETE_STAFF_SUCCESS_MESSAGE: `Éxito al eliminar el registro del personal`,
  DELETE_STAFF_FAIL_MESSAGE: `Error al solicitar eliminar el personal`,
  VIEW_AS_USER_FAIL_MESSAGE: `Error al solicitar la vista como usuario`,
});
