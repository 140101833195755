import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors } from '../../../store/bookingPages';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  DELETE_BOOKING_TEMPLATES_MODAL_NAME,
  deleteBookingTemplatesModalActions,
} from '../../../store/bookingTemplates';
import { Modal } from '../../common';
import labels from './labels';

import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { globalActions } from '../../../store/global';

export const BookingTemplateDeleteModal = () => {
  const dispatch = useDispatch();
  const bookingTemplateIds = useSelector(bookingTemplatesSelectors.selectSelectedBookingTemplates);
  const bookingPages = useSelector(bookingPageSelectors.selectBookingPages);

  const usedBookingPages = bookingPages.filter(
    (page) => page.what?.bookingTemplateId && bookingTemplateIds.includes(page.what?.bookingTemplateId)
  );

  const handleSave = () => {
    dispatch(bookingTemplatesActions.deleteBookingTemplatesRequest());
    dispatch(deleteBookingTemplatesModalActions.closeModal());
    dispatch(bookingTemplatesActions.unselectAllBookingTemplate());
    dispatch(globalActions.setSearch({ searchString: '' })); // clear the search value
  };

  const handleCancel = () => {
    dispatch(deleteBookingTemplatesModalActions.closeModal());
    dispatch(bookingTemplatesActions.unselectAllBookingTemplate());
  };

  return (
    <Modal.Container name={DELETE_BOOKING_TEMPLATES_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="w-25rem p-fluid">
        {usedBookingPages.length === 0 ? (
          <div>{bookingTemplateIds.length > 1 ? labels.confirmMultipleDelete : labels.confirmDelete}</div>
        ) : (
          <>
            <div className="mb-2">{labels.usedBookingPages}</div>
            {usedBookingPages.map((page) => (
              <div key={page.id}>
                <Link
                  to={Path.EditBookingPage.replace(':bookingPageId', page.id || '')}
                  className="text-color underline hover:text-primary"
                >
                  {page.what?.customName || 'empty name'}
                  {/* TODO: customName should be mandatory */}
                </Link>
              </div>
            ))}
          </>
        )}
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined />
          <Button label={labels.delete} onClick={handleSave} autoFocus disabled={Boolean(usedBookingPages.length)} />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
