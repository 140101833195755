import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import { OrgsActionTypes, orgsActions } from './actions';
import { GetOrgDetailsResponse, OPSConsoleLicense, OPSConsoleTenant, OrgsDataTableType, OrgsDataType } from './types';
import { notificationsActions } from '../../notifications';
import {
  convertToPayLater,
  getOrgDetailById,
  getOrgs,
  updateLicense,
  updateTenant,
  removeTenant,
  sendUIEmails,
} from './service';
import {
  EXTEND_LICENSE_FAIL_TOAST,
  EXTEND_LICENSE_SUCCESS_TOAST,
  GET_ORGS_FAIL_TOAST,
  GET_ORG_DETAILS_FAIL_TOAST,
  SAVE_ORG_DETAILS_FAIL_TOAST,
  SAVE_ORG_DETAILS_SUCCESS_TOAST,
  EXPIRE_TRIAL_FAIL_TOAST,
  EXPIRE_TRIAL_SUCCESS_TOAST,
  CONVERT_TO_TEST_SUCCESS_TOAST,
  CONVERT_TO_TEST_FAIL_TOAST,
  DELETE_ORG_SUCCESS_TOAST,
  DELETE_ORG_FAIL_TOAST,
  CONVERT_TO_PAY_LATER_FAIL_TOAST,
  TERM_FILTER_VALUES,
  CHARGEBEE_TERM_VALUES,
  BUY_LICENSE_PAYLATER_SUCCESS_TOAST
} from './constants';
import { assignRoleToUser, convertToOrgsDataTableType } from './utils';
import { orgsSelectors } from './selectors';
import { Account, CreateAccountInput, OrgType } from '../../../API';
import dayjs from 'dayjs';
import { accountsActions, accountsSelectors } from '../accounts';
import { upsertAccount } from '../accounts/service';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { deleteOrgModalActions, payLaterModalActions } from './modal';
import { authentificationSelectors } from '../../authentification';
import { OPSConsoleUsersActions } from '../users';
import { handleServiceError } from '../../utils/reduxUtils';
import { getDateByNumberOfDays } from '../../../services/DateService';
import { constructExpireTrialEmail, constructExtendTrialEmail } from '../../../services/EmailService';

const getAccountUpdateRequest = (accountRecord: Account, tenantId: string) => {
  const updatedTenantIds = accountRecord.tenantIds?.includes(tenantId)
    ? accountRecord.tenantIds?.filter((id) => id !== tenantId)
    : accountRecord.tenantIds
    ? [...accountRecord.tenantIds, tenantId]
    : [tenantId];
  return {
    id: accountRecord.id,
    tenantIds: updatedTenantIds,
  } as CreateAccountInput;
};

function* getOrgsSaga(): any {
  try {
    const orgs: OrgsDataType[] = yield call(getOrgs);
    const convertedOrgs: OrgsDataTableType[] = convertToOrgsDataTableType(orgs);

    yield put(orgsActions.getOrgsSuccess(convertedOrgs));
  } catch (error: unknown) {
    yield put(orgsActions.getOrgsFail(error?.toString()));
    yield call(handleServiceError, error, GET_ORGS_FAIL_TOAST, true);
  }
}

function* getOrgDetailsSaga(action: ReturnType<typeof orgsActions.getOrgDetailsRequest>): any {
  try {
    if (action.type === OrgsActionTypes.GET_ORG_DETAILS_REQUEST) {
      const orgDetails: GetOrgDetailsResponse = yield call(getOrgDetailById, action.payload);

      const usersWithRoles = assignRoleToUser(orgDetails.tenantRecord, orgDetails.userRecords, orgDetails.roleRecords);
      yield put(OPSConsoleUsersActions.getUsersSuccess(usersWithRoles));
      yield put(accountsActions.getAccountsSuccess(orgDetails.accountList));
      yield put(
        orgsActions.getOrgDetailsSuccess({
          tenantRecord: orgDetails.tenantRecord,
          licenseRecords: orgDetails.licenseRecords,
          roleRecords: orgDetails.roleRecords,
          accountId:
            orgDetails.accountList.find((account) => account.tenantIds?.includes(orgDetails.tenantRecord.tenantId))
              ?.id || '',
          bookedMeetings: orgDetails.userRecords.reduce(
            (amount, user) => amount + (user?.statistics?.eventCreated || 0),
            0
          ),
          percentOfWeekAdoption: null,
          percentOfMonthAdoption: null
        })
      );
    }
  } catch (error: unknown) {
    yield put(orgsActions.getOrgDetailsFail(error?.toString()));
    yield call(handleServiceError, error, GET_ORG_DETAILS_FAIL_TOAST, true);
  }
}

function* saveOrgDetailsSaga(): any {
  try {
    const isMainAdminOrOperations: boolean = yield select(authentificationSelectors.selectIsMainAdminOrOperations);
    const tenant: OPSConsoleTenant = yield select(orgsSelectors.selectTenantDetails);
    const updateTenantRequest = call(updateTenant, tenant.tenantId, {
      status: tenant.status,
      note: tenant.note,
    });

    const requests = [updateTenantRequest];

    if (isMainAdminOrOperations) {
      const license: OPSConsoleLicense = yield select(orgsSelectors.selectLicense);
      const updateLicenseRequest = call(updateLicense, license.tenantId, license.id, {
        endDate: license.endDate,
      });
      requests.push(updateLicenseRequest);
    }

    const prevAccountData: Account = yield select(accountsSelectors.selectAccountByTenantId(tenant.tenantId));
    const accountId: string = yield select(orgsSelectors.selectAccountId);
    if (prevAccountData.id !== accountId) {
      const newAccountData: Account = yield select(accountsSelectors.selectAccountByAccountId(accountId));
      const accountRemoveFrom = getAccountUpdateRequest(prevAccountData, tenant.tenantId);
      const accountAddTo = getAccountUpdateRequest(newAccountData, tenant.tenantId);

      requests.push(yield upsertAccount(accountRemoveFrom));
      requests.push(yield upsertAccount(accountAddTo));
    }

    yield all(requests);

    yield put(orgsActions.saveOrgDetailsSuccess());
    yield put<any>(notificationsActions.showToast(SAVE_ORG_DETAILS_SUCCESS_TOAST));
    yield put(orgsActions.getOrgDetailsRequest(tenant.tenantId));
  } catch (error: unknown) {
    yield put(orgsActions.saveOrgDetailsFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_ORG_DETAILS_FAIL_TOAST);
  }
}

function* deleteOrgSaga(): any {
  try {
    const tenantId: string = yield select(orgsSelectors.selectTenantId);

    yield call(removeTenant, tenantId);

    yield put(orgsActions.deleteOrgSuccess());
    yield put(orgsActions.getOrgsRequest());
    yield put<any>(notificationsActions.showToast(DELETE_ORG_SUCCESS_TOAST));
    yield put(deleteOrgModalActions.closeModal());

    yield call(navigationService.navigateTo, Path.OPSConsoleOrgs);
  } catch (error: unknown) {
    yield put(orgsActions.deleteOrgFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_ORG_FAIL_TOAST);
  }
}

function* extendLicenseSaga(action: ReturnType<typeof orgsActions.extendLicenseRequest>): any {
  try {
    if (action.type === OrgsActionTypes.EXTEND_LICENSE_REQUEST) {
      const { expirationDate, extendDays, isFromDetails, superAdminsEmails } = action.payload;
      const extendedDate = getDateByNumberOfDays(expirationDate, 15);
      const { subject, body } = constructExtendTrialEmail(extendedDate);

      const licenseDetails: OPSConsoleLicense = yield select(orgsSelectors.selectLicense);
      // add additional days to the start date if the Org is Active, to today if the Org is Expired
      const today = dayjs();
      const endDate = dayjs(licenseDetails.endDate);
      const addToDate = endDate.isAfter(today.format('YYYY-MM-DD'), 'day') ? endDate : today;
      const newExpirationDate = addToDate.add(extendDays, 'day').format('YYYY-MM-DD');
      yield call(updateLicense, licenseDetails.tenantId, licenseDetails.id, { endDate: newExpirationDate });

      yield put(orgsActions.extendLicenseSuccess());
      yield put<any>(notificationsActions.showToast(EXTEND_LICENSE_SUCCESS_TOAST));
      isFromDetails
        ? yield put(orgsActions.getOrgDetailsRequest(licenseDetails.tenantId))
        : yield put(orgsActions.getOrgsRequest());

      yield call(sendUIEmails, superAdminsEmails, subject, body);
    }
  } catch (error: unknown) {
    yield put(orgsActions.extendLicenseFail(error?.toString()));
    yield call(handleServiceError, error, EXTEND_LICENSE_FAIL_TOAST);
  }
}

function* expireTrialSaga(action: ReturnType<typeof orgsActions.expireTrialRequest>): any {
  try {
    if (action.type === OrgsActionTypes.EXPIRE_TRIAL_REQUEST) {
      const { subject, body } = constructExpireTrialEmail();
      const { isFromDetails, superAdminsEmails } = action.payload;

      const tenantId: string = yield select(orgsSelectors.selectTenantId);
      const licenseId: string = yield select(orgsSelectors.selectLicenseId);

      yield call(updateLicense, tenantId, licenseId, { endDate: dayjs().format('YYYY-MM-DD') });

      yield put(orgsActions.expireTrialSuccess());
      yield put<any>(notificationsActions.showToast(EXPIRE_TRIAL_SUCCESS_TOAST));
      isFromDetails ? yield put(orgsActions.getOrgDetailsRequest(tenantId)) : yield put(orgsActions.getOrgsRequest());
      yield call(sendUIEmails, superAdminsEmails, subject, body);
    }
  } catch (error: unknown) {
    yield put(orgsActions.expireTrialFail(error?.toString()));
    yield call(handleServiceError, error, EXPIRE_TRIAL_FAIL_TOAST);
  }
}

function* convertToTestSaga(): any {
  try {
    const tenantId: string = yield select(orgsSelectors.selectTenantId);
    yield call(updateTenant, tenantId, { type: OrgType.TEST });

    yield put(orgsActions.convertToTestSuccess());
    yield put<any>(notificationsActions.showToast(CONVERT_TO_TEST_SUCCESS_TOAST));

    yield put(orgsActions.getOrgDetailsRequest(tenantId));
  } catch (error: unknown) {
    yield put(orgsActions.expireTrialFail(error?.toString()));
    yield call(handleServiceError, error, CONVERT_TO_TEST_FAIL_TOAST);
  }
}

function* convertToPayLaterSaga(): any {
  try {
    const tenantId: string = yield select(orgsSelectors.selectTenantId);
    const email: string = yield select(orgsSelectors.selectPayLaterEmail);
    const term: string = yield select(orgsSelectors.selectPayLaterTerm);
    const quantity: number = yield select(orgsSelectors.selectPayLaterQuantity);

    yield call(convertToPayLater, tenantId, email, term, quantity);
    yield call(navigationService.navigateTo, Path.OPSConsoleOrgs);

    yield put(orgsActions.convertToPayLaterSuccess());
    yield put(orgsActions.getOrgsRequest());
    // yield put<any>(notificationsActions.showToast(CONVERT_TO_PAY_LATER_SUCCESS_TOAST));
    yield put<any>(notificationsActions.showToast(BUY_LICENSE_PAYLATER_SUCCESS_TOAST));
    yield put(payLaterModalActions.closeModal());

    yield take(OrgsActionTypes.GET_ORGS_SUCCESS);

    const orgTerm = term == CHARGEBEE_TERM_VALUES.SILVER_MONTHLY ? TERM_FILTER_VALUES.MONTHLY : TERM_FILTER_VALUES.ANNUAL

    const orgs: OrgsDataTableType[] = yield select(orgsSelectors.selectOrgs);
    yield put(
      orgsActions.getOrgsSuccess(
        orgs.map((org) =>
          org.tenantId == tenantId ? { ...org, type: OrgType.CUSTOMER, term: orgTerm, owned: quantity } : org
        )
      )
    );
  } catch (error: unknown) {
    yield put(orgsActions.convertToPayLaterFail(error?.toString()));
    yield call(handleServiceError, error, CONVERT_TO_PAY_LATER_FAIL_TOAST);
  }
}

export function* watchOrgsSaga() {
  yield takeLatest(OrgsActionTypes.GET_ORGS_REQUEST, getOrgsSaga);
  yield takeLatest(OrgsActionTypes.GET_ORG_DETAILS_REQUEST, getOrgDetailsSaga);
  yield takeLatest(OrgsActionTypes.SAVE_ORG_DETAILS_REQUEST, saveOrgDetailsSaga);
  yield takeLatest(OrgsActionTypes.DELETE_ORG_REQUEST, deleteOrgSaga);
  yield takeLatest(OrgsActionTypes.EXTEND_LICENSE_REQUEST, extendLicenseSaga);
  yield takeLatest(OrgsActionTypes.EXPIRE_TRIAL_REQUEST, expireTrialSaga);
  yield takeLatest(OrgsActionTypes.CONVERT_TO_TEST_REQUEST, convertToTestSaga);
  yield takeLatest(OrgsActionTypes.CONVERT_TO_PAY_LATER_REQUEST, convertToPayLaterSaga);
}
