import { combineReducers } from 'redux';
import { billingActionTypes, billingAction } from './actions';
import { DEFAULT_SUBSCRIPTION, DEFAULT_SEATS_AMOUNT, CHARGEBEE_MONTHLY_TERM } from './constants';
import { GlobalAction, GlobalActionTypes } from '../global/actions';

const subscription = (state = DEFAULT_SUBSCRIPTION, action: billingAction | GlobalAction) => {
  switch (action.type) {
    case billingActionTypes.GET_BILLING_SUCCESS:
      return action.payload;
    case billingActionTypes.CHANGE_TERM_SUCCESS:
    case billingActionTypes.CHANGE_SEATS_SUCCESS:
      return { ...state, ...action.payload.subscription };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_SUBSCRIPTION;
    default:
      return state;
  }
};

const addOrRemoveSeats = (state = false, action: billingAction | GlobalAction) => {
  switch (action.type) {
    case billingActionTypes.SET_ADD_OR_REMOVE_SEATS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const changeSeats = (state = DEFAULT_SEATS_AMOUNT, action: billingAction | GlobalAction) => {
  switch (action.type) {
    case billingActionTypes.SET_CHANGE_SEATS:
      return action.payload;
    case billingActionTypes.CHANGE_SEATS_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_SEATS_AMOUNT;
    default:
      return state;
  }
};

const term = (state = CHARGEBEE_MONTHLY_TERM, action: billingAction | GlobalAction) => {
  switch (action.type) {
    case billingActionTypes.SET_TERM:
      return action.payload;
    case billingActionTypes.GET_BILLING_SUCCESS:
      return action.payload?.subscription_items?.[0]?.item_price_id || CHARGEBEE_MONTHLY_TERM;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return CHARGEBEE_MONTHLY_TERM;
    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: billingAction | GlobalAction) => {
  switch (action.type) {
    case billingActionTypes.GET_BILLING_REQUEST:
    case billingActionTypes.CHANGE_SEATS_REQUEST:
    case billingActionTypes.CHANGE_TERM_REQUEST:
    case billingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST:
      return true;
    case billingActionTypes.GET_BILLING_SUCCESS:
    case billingActionTypes.GET_BILLING_FAIL:
    case billingActionTypes.CHANGE_SEATS_SUCCESS:
    case billingActionTypes.CHANGE_SEATS_FAIL:
    case billingActionTypes.CHANGE_TERM_SUCCESS:
    case billingActionTypes.CHANGE_TERM_FAIL:
    case billingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS:
    case billingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: string = '', action: billingAction | GlobalAction) => {
  switch (action.type) {
    case billingActionTypes.GET_BILLING_REQUEST:
    case billingActionTypes.CHANGE_SEATS_REQUEST:
    case billingActionTypes.CHANGE_TERM_REQUEST:
    case billingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST:
      return '';
    case billingActionTypes.GET_BILLING_FAIL:
    case billingActionTypes.CHANGE_SEATS_FAIL:
    case billingActionTypes.CHANGE_TERM_FAIL:
    case billingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  subscription,
  changeSeats,
  term,
  addOrRemoveSeats,
  isFetching,
  error,
});
