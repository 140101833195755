import { useCallback, useEffect, useRef, useContext } from 'react';
import { classNames, DomHandler } from 'primereact/utils';
import PrimeReact from 'primereact/api';
import { useEventListener, useMountEffect, useResizeListener, useUnmountEffect } from 'primereact/hooks';
import { LayoutContext } from '../../common/context/layoutcontext';
import AppTopbarUnAuth from '../appTopbar/AppTopbar';
import AppSearch from '../../common/app/AppSearch';
import AppBreadCrumb from '../../common/app/AppBreadCrumb';
import AppRightMenu from '../../common/app/AppRightMenu';
import AppFooter from '../appFooter/AppFooter';

const Layout = (props) => {
  const { layoutConfig, layoutState, setLayoutState, isSlim, isCompact, isHorizontal, isDesktop } =
    useContext(LayoutContext);
  const topbarRef = useRef(null);
  const sidebarRef = useRef(null);
  const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
    type: 'click',
    listener: (event) => {
      const isOutsideClicked = !(
        sidebarRef.current.isSameNode(event.target) ||
        sidebarRef.current.contains(event.target) ||
        topbarRef.current.menubutton.isSameNode(event.target) ||
        topbarRef.current.menubutton.contains(event.target)
      );

      if (isOutsideClicked) {
        hideMenu();
      }
    },
  });

  const [bindDocumentResizeListener, unbindDocumentResizeListener] = useResizeListener({
    listener: () => {
      if (isDesktop() && !DomHandler.isTouchDevice()) {
        hideMenu();
      }
    },
  });

  const hideMenu = useCallback(() => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      overlayMenuActive: false,
      overlaySubmenuActive: false,
      staticMenuMobileActive: false,
      menuHoverActive: false,
      menuClick: false,
      resetMenu: (isSlim() || isCompact() || isHorizontal()) && isDesktop(),
    }));
  }, [isSlim, isHorizontal, isDesktop, setLayoutState]);

  const blockBodyScroll = () => {
    DomHandler.addClass('blocked-scroll');
  };

  const unblockBodyScroll = () => {
    DomHandler.removeClass('blocked-scroll');
  };

  useMountEffect(() => {
    PrimeReact.ripple = true;
  });

  useEffect(() => {
    if (layoutState.overlayMenuActive || layoutState.staticMenuMobileActive || layoutState.overlaySubmenuActive) {
      bindMenuOutsideClickListener();
    }

    if (layoutState.staticMenuMobileActive) {
      blockBodyScroll();
      (isSlim() || isCompact() || isHorizontal()) && bindDocumentResizeListener();
    }

    return () => {
      unbindMenuOutsideClickListener();
      unbindDocumentResizeListener();
      unblockBodyScroll();
    };
  }, [layoutState.overlayMenuActive, layoutState.staticMenuMobileActive, layoutState.overlaySubmenuActive]);

  useUnmountEffect(() => {
    unbindMenuOutsideClickListener();
  });

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-static': layoutConfig.menuMode === 'static',
      'layout-slim': layoutConfig.menuMode === 'slim',
      'layout-horizontal': layoutConfig.menuMode === 'horizontal',
      'layout-drawer': layoutConfig.menuMode === 'drawer',
      'layout-overlay': layoutConfig.menuMode === 'overlay',
      'layout-compact': layoutConfig.menuMode === 'compact',
      'layout-reveal': layoutConfig.menuMode === 'reveal',
      'layout-sidebar-dim': layoutConfig.colorScheme === 'dim',
      'layout-sidebar-dark': layoutConfig.colorScheme === 'dark',
      'layout-overlay-active': layoutState.overlayMenuActive,
      'layout-mobile-active': layoutState.staticMenuMobileActive,
      'layout-static-inactive': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
      'p-input-filled': layoutConfig.inputStyle === 'filled',
      'p-ripple-disabled': !layoutConfig.ripple,
      'layout-sidebar-active': layoutState.sidebarActive,
      'layout-sidebar-anchored': layoutState.anchored,
    },
    layoutConfig.colorScheme === 'light' ? 'layout-sidebar-' + layoutConfig.menuTheme : ''
  );

  return (
    <div className={containerClassName} data-theme={layoutConfig.colorScheme}>
      <div className="ml-0 layout-content-wrapper layout-content-wrapper-light" style={{ minHeight: 'auto' }}>
        <AppTopbarUnAuth ref={topbarRef} sidebarRef={sidebarRef} />
        <div className="layout-content">
          <AppBreadCrumb />

          {props.children}
        </div>
        <AppFooter />
      </div>

      <AppSearch />
      <AppRightMenu />
      <div className="layout-mask"></div>
    </div>
  );
};

export default Layout;
