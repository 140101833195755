import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  EDIT_USERS_MODAL_NAME,
  confirmUsersModalActions,
  editUsersModalActions,
  editUsersModalSelectors,
} from '../../../store/users/modal';
import { modalsActions } from '../../../store/modals';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { UserStatus } from '../../../store/users/typings';
import labels from './labels';
import { usersActions, usersSelectors } from '../../../store/users';
import { Tooltip } from 'primereact/tooltip';
import { MAX_LENGTH_EMAIL, MAX_LENGTH_NAME } from '../../../types/constants';
import { PhoneNumber } from '../../common';
import { InputSwitch } from 'primereact/inputswitch';
import { workspacesSelectors } from '../../../store/workspaces';
import { userSettingsSelectors } from '../../../store/userSettings';
import { rolesSelectors } from '../../../store/roles';
import { authentificationSelectors } from '../../../store/authentification';
import { usePrevious } from '../../../services/customHooks';
import { UpdateBookingTemplateInput } from '../../../API';
import { TeamRecord } from '../../../store/teams';

export const EditUser = () => {
  const dispatch = useDispatch();
  const open = useSelector(editUsersModalSelectors.selectIsModalOpen);
  const workspaceOptions = useSelector(workspacesSelectors.selectWorkspaceOptions);
  const currentUserWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const userRecord = useSelector(usersSelectors.selectSelectedUser);
  const roles = useSelector(rolesSelectors.selectRoles);
  const supportedRoles = useSelector(userSettingsSelectors.selectSupportedRoles);
  const remainingLicenses = useSelector(authentificationSelectors.selectRemainingLicenses);
  const supportedRolesId = useSelector(userSettingsSelectors.selectSupportedRolesId);
  const isPhoneInvalid = useSelector(usersSelectors.selectIsUserPhoneInvalid);
  const superSuperAdminId = useSelector(rolesSelectors.selectSuperAdminId);
  const currentUserRoleId = useSelector(userSettingsSelectors.selectUserRoleId);
  const superAdmins = useSelector(usersSelectors.selectActiveSuperAdmins) || [];
  const userId = useSelector(authentificationSelectors.selectUserId);
  const bookingTemplates = useSelector(
    usersSelectors.selectBookingTemplateOptionsByWorkspaces(userRecord.workspaceIds || [])
  );
  const teamList = useSelector(usersSelectors.selectTeamsOptionsByWorkspaces(userRecord.workspaceIds || []));

  const [oldUserData, setOldUserData] = useState(userRecord);
  const [isEditable, setIsEditable] = useState(supportedRolesId.includes(userRecord.roleId));

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: EDIT_USERS_MODAL_NAME, options: {} }));
    return () => {
      dispatch(modalsActions.deleteModal(EDIT_USERS_MODAL_NAME));
    };
  }, []);

  useEffect(() => {
    setOldUserData(userRecord);
  }, [userRecord.email]);

  useEffect(() => {
    setIsEditable(supportedRolesId.includes(userRecord.roleId));
  }, [supportedRolesId, userRecord.email]);

  const handleClose = () => {
    dispatch(editUsersModalActions.closeModal());
  };

  const previousRoleId = usePrevious(userRecord.roleId);

  useEffect(() => {
    // checking all Workspaces if roleId is superSuperAdminId
    if (userRecord.roleId === superSuperAdminId) {
      dispatch(usersActions.updateUserRecord({ workspaceIds: workspaceOptions.map((option) => option.id) }));
    }
    // back to initial reslut if user back from roleId is superSuperAdminId
    else if (previousRoleId === superSuperAdminId) {
      dispatch(usersActions.updateUserRecord({ workspaceIds: oldUserData.workspaceIds }));
    }
  }, [userRecord.roleId, superSuperAdminId, workspaceOptions, previousRoleId, dispatch, oldUserData.workspaceIds]);

  const handleSave = () => {
    if (oldUserData.status === UserStatus.Active && userRecord.status === UserStatus.Inactive) {
      dispatch(confirmUsersModalActions.openModal());
    } else {
      const myRoleIsChanged = oldUserData.roleId !== userRecord.roleId && userRecord.userId === userId; // need to request Authentication
      dispatch(usersActions.editUserRecordRequest(myRoleIsChanged));
    }
  };

  const confirmFooter = () => {
    return (
      <div className="flex justify-content-end gap-3">
        <Button label={labels.cancel} outlined className="w-6rem" onClick={handleClose} />
        <Button
          label={labels.save}
          className="w-8rem"
          onClick={handleSave}
          disabled={
            !userRecord.workspaceIds?.length ||
            !isEditable ||
            (userRecord.status !== UserStatus.InviteSent && isPhoneInvalid)
          }
        />
      </div>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | DropdownChangeEvent) => {
    const { name, value } = event.target;
    dispatch(usersActions.updateUserRecord({ [name]: value }));
  };

  const handlePhoneChange = (phoneNumber: string) => {
    dispatch(usersActions.updateUserRecord({ phoneNumber }));
  };

  const handleCodeChange = (countryCode: string) => {
    dispatch(usersActions.updateUserRecord({ countryCode }));
  };

  const handleStatusChange = (checked: boolean | undefined) => {
    dispatch(usersActions.updateUserRecord({ status: checked ? UserStatus.Active : UserStatus.Inactive }));
  };

  const handleTemplatesChange = (selectedWorkspaceIds: string[]) => {
    // filter all selected templates according to new list of workspaces
    const listTemplates: UpdateBookingTemplateInput[] = [];
    if (bookingTemplates) {
      for (const option of bookingTemplates) {
        if (selectedWorkspaceIds.includes(option.workspaceId)) {
          listTemplates.push(...option.bookingTemplates);
        }
      }
      const actualTemplates = userRecord.bookingTemplateIds?.filter((id) =>
        listTemplates.some((template) => template.id === id)
      );
      dispatch(usersActions.updateUserRecord({ bookingTemplateIds: actualTemplates }));
    }
  };

  const handleTeamsChange = (selectedWorkspaceIds: string[]) => {
    // filter all selected teams according to new list of workspaces
    const teams: TeamRecord[] = [];
    if (teamList) {
      for (const option of teamList) {
        if (selectedWorkspaceIds.includes(option.workspaceId)) {
          teams.push(...option.teams);
        }
      }
      const actualTeams = userRecord.team?.filter((id) => teams.some((team) => team.id === id));
      dispatch(usersActions.updateUserRecord({ team: actualTeams }));
    }
  };

  const handleWorkspaceChange = (e: DropdownChangeEvent) => {
    if (userRecord.roleId !== superSuperAdminId) {
      handleTemplatesChange(e.target.value);
      handleTeamsChange(e.target.value);
      handleChange(e);
    } else {
      // Prevent unchecking if roleId is superSuperAdminId
      const currentValues = userRecord.workspaceIds ?? [];
      const newValue = e.value;
      if (newValue.length < currentValues.length) {
        e.preventDefault();
      } else {
        handleTemplatesChange(e.target.value);
        handleTeamsChange(e.target.value);
        handleChange(e);
      }
    }
  };

  const disableDropdownRole = () => {
    return (
      !isEditable || (superAdmins.length < 2 && currentUserRoleId === superSuperAdminId && userRecord.userId === userId)
    );
  };

  const disableInputSwitchStatus = () => {
    return (
      userRecord.status === UserStatus.InviteSent ||
      (oldUserData.status === UserStatus.Inactive && remainingLicenses < 1) ||
      userRecord.userId === userId ||
      !isEditable
    );
  };

  return (
    <Dialog
      header={`${labels.title} ${userRecord.fullName ? '(' + userRecord.fullName + ')' : ''}`}
      headerClassName="text-center text-900"
      footer={confirmFooter}
      visible={open}
      onHide={handleClose}
      className="w-11 md:w-6"
      focusOnShow={false}
    >
      <div>
        {!isEditable && (
          <div className="bg-blue-100 border-round mb-3 p-2">
            {labels.higherRoleDesc} {labels.readonlyDesc}
          </div>
        )}

        <div className="surface-50 text-sm p-2 uppercase">{labels.contactDetail}</div>
        <div className="grid p-fluid p-2">
          <div className="flex flex-column col-12 md:col-6">
            <label htmlFor="username">{labels.fullName}</label>
            <InputText
              id="username"
              name="fullName"
              disabled={userRecord.status === UserStatus.InviteSent || !isEditable}
              value={userRecord.fullName ? userRecord.fullName : ''}
              onChange={handleChange}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>
          <div className="flex flex-column col-12 md:col-6">
            <label htmlFor="email">{labels.email}</label>
            <InputText id="email" value={userRecord.email || ''} maxLength={MAX_LENGTH_EMAIL} disabled />
          </div>
          <div className="col-12 lg:col-8">
            <PhoneNumber
              label={labels.phoneNumber}
              countryCode={userRecord.countryCode || ''}
              handleChangeCode={handleCodeChange}
              phoneNumber={userRecord.phoneNumber || ''}
              handleChangePhone={handlePhoneChange}
              disabled={userRecord.status === UserStatus.InviteSent || !isEditable}
              inputClassName={`${
                userRecord.status !== UserStatus.InviteSent && isEditable && isPhoneInvalid ? 'p-invalid' : ''
              }`}
            />
          </div>
        </div>

        <div className="surface-50 text-sm p-2 uppercase">{labels.access}</div>
        <div className="flex flex-wrap">
          <div className="flex flex-column col-12 md:col-6 user-role-dropdown">
            <label htmlFor="roleId">{labels.role}</label>
            <Dropdown
              id="roleId"
              name="roleId"
              options={isEditable ? supportedRoles : roles}
              optionLabel="name"
              optionValue="id"
              value={userRecord.roleId}
              onChange={handleChange}
              disabled={disableDropdownRole()}
            />
            {superAdmins.length < 2 && currentUserRoleId === superSuperAdminId && userRecord.userId === userId && (
              <Tooltip target=".user-role-dropdown" content={labels.onlyOneSuperAdmin} position="bottom" />
            )}
          </div>
          <div
            className={`flex col-12 md:col-6 pb-2 ${
              userRecord.status === UserStatus.InviteSent ? 'align-self-end align-items-center' : ''
            }`}
          >
            {userRecord.status === UserStatus.InviteSent ? (
              <div>{userRecord.status}</div>
            ) : (
              <div className="flex flex-column ml-2 user-status-switch">
                <label>{labels.active}</label>
                <InputSwitch
                  className="mt-2"
                  checked={userRecord.status !== UserStatus.Inactive}
                  disabled={disableInputSwitchStatus()}
                  onChange={(e) => handleStatusChange(!!e.value)}
                />
                {userRecord.roleId === currentUserRoleId && (
                  <Tooltip target=".user-status-switch" content={labels.deactivateHimself} />
                )}
              </div>
            )}

            <div className="pl-2 pb-3">
              <Tooltip target=".exclamation-mark" position="top" mouseTrack>
                {userRecord.status === UserStatus.InviteSent ? labels.inviteSentTooltip : labels.activeTooltip}
              </Tooltip>
              <i className="exclamation-mark pi pi-exclamation-circle" />
            </div>
          </div>
        </div>

        <div className="surface-50 text-sm p-2 uppercase">{labels.userOfWorkspace}</div>
        <div className="flex flex-column col-12 md:col-8">
          <label htmlFor="workspaceIds">{labels.workspaceMess}:</label>
          <MultiSelect
            id="workspaceIds"
            name="workspaceIds"
            options={
              isSuperAdmin || !isEditable
                ? workspaceOptions
                : workspaceOptions.filter((option) => currentUserWorkspaces.includes(option.id))
            }
            optionLabel="name"
            optionValue="id"
            value={userRecord.workspaceIds}
            onChange={handleWorkspaceChange}
            display="chip"
            filter
            className={`${!userRecord.workspaceIds?.length && 'p-invalid'}`}
            disabled={!isEditable}
          />
          {userRecord.roleId === superSuperAdminId && <div className="m-1">{labels.superAdminAccessDescription}</div>}
        </div>
        {userRecord.status === UserStatus.InviteSent && (
          <>
            <div className="surface-50 text-sm p-2 uppercase">{labels.teams}</div>
            <div className="flex flex-column col-12 md:col-8">
              <label htmlFor="team">{labels.teamsMess}:</label>
              {teamList ? (
                <MultiSelect
                  id="team"
                  name="team"
                  value={userRecord.team}
                  options={teamList}
                  optionLabel="name"
                  optionValue="id"
                  optionGroupLabel="label"
                  optionGroupChildren="teams"
                  onChange={handleChange}
                  placeholder={labels.teamsPlaceholder}
                  display="chip"
                  filter
                  disabled={!isEditable || !userRecord.workspaceIds?.length || !teamList.length}
                />
              ) : (
                /* show spinner during loading the data */
                <div className="bg-white border-1 border-black-alpha-10 border-round p-1 text-center text-xl">
                  <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
                </div>
              )}
            </div>
            <div className="surface-50 text-sm p-2 uppercase">{labels.bookingTemplates}</div>
            <div className="flex flex-column col-12 md:col-8">
              <label htmlFor="bookingTemplateIds">{labels.bookingTemplatesMess}:</label>
              {bookingTemplates ? (
                <MultiSelect
                  id="bookingTemplateIds"
                  name="bookingTemplateIds"
                  value={userRecord.bookingTemplateIds}
                  options={bookingTemplates}
                  optionLabel="what.customName"
                  optionValue="id"
                  optionGroupLabel="label"
                  optionGroupChildren="bookingTemplates"
                  onChange={handleChange}
                  display="chip"
                  filter
                  disabled={!isEditable || !userRecord.workspaceIds?.length || !bookingTemplates.length}
                  placeholder={labels.assignBookingTemplatesPlaceholder}
                />
              ) : (
                /* show spinner during loading the data */
                <div className="bg-white border-1 border-black-alpha-10 border-round p-1 text-center text-xl">
                  <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
