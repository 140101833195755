import { combineReducers } from 'redux';
import { ScheduledMeetingsActionTypes, ScheduledMeetingsAction } from './actions';
import { CreateUserEventInput } from '../../API';
import { SortMethods, Statuses } from './typings';
import dayjs from 'dayjs';
import { GlobalAction, GlobalActionTypes } from '../global/actions';

const scheduledMeetings = (state: CreateUserEventInput[] = [], action: ScheduledMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS:
      return action.payload;
    case ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS:
      return [...state.filter((record) => record.eventId !== action.payload.eventId), action.payload];
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: ScheduledMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST:
    case ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST:
    case ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST:
      return true;
    case ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS:
    case ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL:
    case ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS:
    case ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL:
    case ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_SUCCESS:
    case ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: ScheduledMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST:
    case ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST:
    case ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST:
      return null;
    case ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL:
    case ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL:
    case ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const sortMethod = (state: string = SortMethods.UPCOMING, action: ScheduledMeetingsAction | GlobalAction): string => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.SET_SORT_METHOD:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return SortMethods.UPCOMING;
    default:
      return state;
  }
};

const dateRange = (state: string[] = [], action: ScheduledMeetingsAction | GlobalAction): string[] => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.SET_DATE_RANGE:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const status = (state: string = Statuses.ALL, action: ScheduledMeetingsAction | GlobalAction): string => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.SET_STATUS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return Statuses.ALL;
    default:
      return state;
  }
};

const selectedBookingPages = (state: string[] = [], action: ScheduledMeetingsAction | GlobalAction): string[] => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.SET_SELECTED_BOOKING_PAGES:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const selectedScheduledMeeting = (
  state: CreateUserEventInput = {} as CreateUserEventInput,
  action: ScheduledMeetingsAction | GlobalAction
): CreateUserEventInput => {
  switch (action.type) {
    case ScheduledMeetingsActionTypes.SELECT_SCHEDULED_MEETING:
      return action.payload;
    case ScheduledMeetingsActionTypes.UPDATE_CANCEL_REASON:
      return {
        ...state,
        canceled: {
          ...state.canceled,
          isCanceled: state.canceled?.isCanceled ?? true,
          type: action.reason,
        },
      };
    case ScheduledMeetingsActionTypes.UPDATE_CANCEL_NOTE:
      return {
        ...state,
        canceled: {
          ...state.canceled,
          isCanceled: state.canceled?.isCanceled ?? true,
          note: action.note,
        },
      };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as CreateUserEventInput;
    default:
      return state;
  }
};

export default combineReducers({
  scheduledMeetings,
  isFetching,
  error,
  sortMethod,
  dateRange,
  status,
  selectedBookingPages,
  selectedScheduledMeeting,
});
