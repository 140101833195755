import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { CountryData } from '../../../types/interfaces';
import { CountryCode, getCountryCallingCode, getCountries as getPhoneCountries } from 'libphonenumber-js';
import Flag from 'react-world-flags';
import countries from 'i18n-iso-countries';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { ChangeEvent, useEffect, useState } from 'react';
import { CURRENT_TIME_ZONE, MAX_LENGTH_PHONE } from '../../../types/constants';
import { countriesTimeZonesService } from '../../../services/CountriesTimeZoneService';

type PhoneNumberProps = {
  label?: string;
  placeholder?: string;
  countryCode: string;
  handleChangeCode: (code: string) => void;
  phoneNumber: string;
  handleChangePhone: (phone: string) => void;
  handleBlurPhone?: () => void;
  disabled?: boolean;
  inputClassName?: string;
};

(async () => {
  const localeData = await import('i18n-iso-countries/langs/en.json');
  countries.registerLocale(localeData);
})();

const getCountries = (): CountryData[] => {
  const countryCodes = countries.getAlpha2Codes();
  const phoneCountries = getPhoneCountries();

  return phoneCountries
    .map((code) => ({
      code: `${code}_${getCountryCallingCode(code)}`, // e.g. BY_375
      name: countryCodes[code],
      phoneCode: '+' + getCountryCallingCode(code),
    }))
    .filter((country) => country.name);
};

export const PhoneNumber: React.FC<PhoneNumberProps> = ({
  label,
  placeholder,
  countryCode,
  handleChangeCode,
  phoneNumber,
  handleChangePhone,
  handleBlurPhone,
  disabled,
  inputClassName,
}) => {
  const userCountryCode = useSelector(userSettingsSelectors.selectCountryCode);
  const timeZone = useSelector(userSettingsSelectors.selectTimeZone);
  const countryList = getCountries();
  const [localCountryCode, setLocalCountryCode] = useState('');

  // autodetect country code if it's empty
  useEffect(() => {
    if (countryCode) {
      setLocalCountryCode(countryCode);
    } else {
      let autoCountryCode: string;
      if (userCountryCode) {
        autoCountryCode = userCountryCode;
      } else {
        const timezoneInf = countriesTimeZonesService.getTimezoneByName(timeZone || CURRENT_TIME_ZONE);
        autoCountryCode = timezoneInf ? timezoneInf.countries[0] : '';
        autoCountryCode = `${autoCountryCode}_${getCountryCallingCode(autoCountryCode as CountryCode)}`;
      }
      handleChangeCode(autoCountryCode);
    }
  }, [countryCode]);

  const handleCountryCodeChange = (code: string) => {
    setLocalCountryCode(code);
    handleChangeCode(code);
  };

  const localPhoneChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    // to prevent autocomplete with incorrect values
    /^([\d-])*$/.test(value) && value.length <= MAX_LENGTH_PHONE && handleChangePhone(value);
  };

  const timeFormatTemplate = (country: CountryData) => {
    return (
      country && (
        <div className="flex align-items-center">
          <div className="mr-1">
            <div style={{ width: '20px', height: '20px' }}>
              <Flag code={country.code.split('_')[0]} />
            </div>
          </div>
          <span>{`${country.name} (${country.phoneCode})`}</span>
        </div>
      )
    );
  };

  return (
    <div className="flex gap-3 align-items-end">
      <div className="flex-none">
        {label && <label className="mr-1">{label}</label>}
        <Dropdown
          value={localCountryCode}
          onChange={(e) => handleCountryCodeChange(e.target.value as string)}
          options={countryList}
          optionLabel="name"
          optionValue="code"
          valueTemplate={timeFormatTemplate}
          itemTemplate={timeFormatTemplate}
          disabled={disabled}
        />
      </div>
      <div className="flex-grow-1">
        <InputText
          keyfilter="int" // support digits and -
          value={phoneNumber}
          placeholder={placeholder}
          onChange={localPhoneChangeHandle}
          onBlur={handleBlurPhone}
          disabled={disabled}
          maxLength={MAX_LENGTH_PHONE}
          className={inputClassName}
        />
      </div>
    </div>
  );
};
