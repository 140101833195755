import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../../store/bookingTemplates';
import { bookingPageSelectors, bookingPagesActions, previewModalActions } from '../../../../store/bookingPages';
import { ImageType } from '../../../../types/types';
import { BookingPageBrandingSettings } from '../../../bookingPages';
import labels from './labels';

type HowStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const HowStep = ({ selectors, actions, isReadOnly }: HowStepProps) => {
  const dispatch = useDispatch();
  const bookingPageStyle = useSelector(selectors.selectStyle);
  const avatar = useSelector(selectors.selectFinalLogoImage);
  const backgroundImage = useSelector(selectors.selectFinalBackgroundImage);
  const isFetchingAvatar = useSelector(selectors.selectIsFetchingAvatar);
  const isFetchingBackground = useSelector(selectors.selectIsFetchingBackground);

  const [localStyle, setLocalStyle] = useState(bookingPageStyle || {});
  const [brandingOpened, setBrandingOpened] = useState(false);

  useEffect(() => {
    setLocalStyle((prev) => ({ ...prev, backgroundImage }));
  }, [backgroundImage]);

  useEffect(() => {
    setLocalStyle((prev) => ({ ...prev, avatar }));
  }, [avatar]);

  const handlePreviewOpen = () => {
    dispatch(previewModalActions.openModal());
  };

  const uploadLogo = (file: File) => {
    dispatch(actions.uploadAvatarFileRequest({ imageType: ImageType.avatar, file }));
  };
  const resetLogo = () => {
    dispatch(actions.resetImage(ImageType.avatar));
  };
  const clearLogo = () => {
    dispatch(actions.clearImage(ImageType.avatar));
  };

  const uploadBackground = (file: File) => {
    dispatch(actions.uploadBackgroundFileRequest({ imageType: ImageType.backgroundImage, file }));
  };
  const resetBackground = () => {
    dispatch(actions.resetImage(ImageType.backgroundImage));
  };
  const clearBackground = () => {
    dispatch(actions.clearImage(ImageType.backgroundImage));
  };

  const handleSave = () => {
    dispatch(
      actions.updateStyleStep({
        lookAndFeel: localStyle?.lookAndFeel,
        opacity: localStyle?.opacity,
        css: localStyle?.css,
        footer: localStyle?.footer,
      })
    );
    setBrandingOpened(false);
  };

  const handleColorsAndFontsChange = (name: string, value: string) => {
    setLocalStyle((prev) => ({ ...prev, lookAndFeel: { ...prev?.lookAndFeel, [name]: value } }));
  };

  const handleStyleChange = (name: string, value: string | number) => {
    setLocalStyle((prev) => ({ ...prev, [name]: value }));
  };

  const handleHide = () => {
    setBrandingOpened(false);
    if (bookingPageStyle) {
      setLocalStyle(bookingPageStyle);
    }
  };

  return (
    <div>
      <div className="grid align-items-center p-fluid">
        <div className="col-12 sm:col-5 md:col-4 lg:col-3">
          <Button
            label={labels.brandingButton}
            outlined
            onClick={() => setBrandingOpened(true)}
            disabled={isReadOnly}
          />
        </div>
        <div className="col-12 sm:col-7 md:col-8 lg:col-9">{labels.brandingDescription}</div>
        <div className="col-12 sm:col-5 md:col-4 lg:col-3">
          <Button label={labels.previewButton} outlined onClick={handlePreviewOpen} disabled={isReadOnly} />
        </div>
        <div className="col-12 sm:col-7 md:col-8 lg:col-9">{labels.previewDescription}</div>
      </div>

      <Dialog
        header={labels.brandingTitle}
        headerClassName="text-center"
        visible={brandingOpened}
        draggable={false}
        position="center"
        onHide={handleHide}
      >
        <BookingPageBrandingSettings
          onSave={handleSave}
          style={localStyle}
          uploadLogo={uploadLogo}
          resetLogo={resetLogo}
          clearLogo={clearLogo}
          uploadBackground={uploadBackground}
          resetBackground={resetBackground}
          clearBackground={clearBackground}
          handleColorsAndFontsChange={handleColorsAndFontsChange}
          handleStyleChange={handleStyleChange}
          isFetchingAvatar={isFetchingAvatar}
          isFetchingBackground={isFetchingBackground}
        />
      </Dialog>
    </div>
  );
};
