import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../../types/constants';
import { Button } from 'primereact/button';
import { UserAdminDataFullRecord } from '../../../store/users/typings';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { usersActions, usersSelectors } from '../../../store/users';
import { TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { rolesSelectors } from '../../../store/roles';
import { formatDateUTC, formatDateHHMM } from '../../../services/DateService';
import { inviteUsersModalActions } from '../../../store/users/modal';
import { EditUser } from '../editUser/EditUser';
import { InviteDialog } from '../inviteDialog/InviteDialog';
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';
import { Checkbox } from 'primereact/checkbox';
import { UserRowActions } from '../userRowActions/UserRowActions';
import { Search } from '../../common';
import { authentificationSelectors } from '../../../store/authentification';
import { StatusLabels } from '../../../store/users/constants';

export const UsersList = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');
  const users = useSelector(usersSelectors.selectUsers);
  const filteredUsers = useSelector(usersSelectors.selectSearchedUsers(searchString));
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const roles = useSelector(rolesSelectors.selectRoles);
  const remainingLicenses = useSelector(authentificationSelectors.selectRemainingLicenses);
  const hideInactive = useSelector(usersSelectors.selectHideInactive);
  const isAddUsers = useSelector(userSettingsSelectors.selectUsersAdd);

  const [isDisableUser, setIsDisableUser] = useState(false);
  const [isEditConfirm, setIsEditConfirm] = useState(false);

  const setConfirmDialogProps = (isEdit: boolean, isDisable: boolean) => {
    setIsEditConfirm(isEdit);
    setIsDisableUser(isDisable);
  };

  const getRoleNameById = (id: string) => {
    return roles.find((role) => role.id === id)?.name || 'None';
  };

  const handleCheckboxChange = (value: boolean) => {
    dispatch(usersActions.setHideInactive(value));
  };

  const handleSearchChange = (value: string) => {
    setSearchString(value.toLowerCase());
  };

  const handleInvite = () => {
    dispatch(usersActions.getInviteUsersOptionsRequest());
    dispatch(inviteUsersModalActions.openModal());
  };

  return (
    <>
      <div className="grid mb-3 justify-content-between">
        <div className="flex col-12 align-items-center justify-content-between md:col-7">
          {users.length >= MIN_ITEMS_FOR_SEARCH && (
            <div className="p-inputgroup mr-3">
              <Search
                placeholder={labels.search}
                value={searchString}
                onChange={handleSearchChange}
                maxTextLength={MAX_LENGTH_NAME}
              />
            </div>
          )}
          <div className="flex align-items-center justify-content-start w-7">
            <Checkbox
              inputId="hideInactive"
              checked={hideInactive}
              onChange={(e) => handleCheckboxChange(!!e.target.checked)}
            />
            <label htmlFor="hideInactive" className="ml-2">
              {labels.hideInactiveUsers}
            </label>
          </div>
        </div>
        <div className="col-5 md:col-3 flex align-items-start justify-content-end">
          <Button
            label={labels.inviteTitle}
            icon="pi pi-plus"
            onClick={handleInvite}
            disabled={remainingLicenses < 1 || !isAddUsers}
            tooltip={remainingLicenses < 1 ? labels.remainingMessage : ''}
            tooltipOptions={{ position: 'left', showOnDisabled: true }}
          />
        </div>
      </div>

      <DataTable
        value={filteredUsers.sort((userA, userB) => (userB.createdAt > userA.createdAt ? 1 : -1))}
        paginator={filteredUsers.length > 10}
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        size="small"
        showGridlines
        removableSort
        resizableColumns
        emptyMessage={labels.noResults}
      >
        <Column field="fullName" header={labels.name} sortable bodyClassName="text-overflow-ellipsis max-w-10rem" />
        <Column field="email" header={labels.email} sortable bodyClassName="text-overflow-ellipsis max-w-10rem" />
        <Column
          body={(data: UserAdminDataFullRecord) => getRoleNameById(data.roleId)}
          field="roleId"
          header={labels.role}
          sortable
          bodyClassName="text-overflow-ellipsis"
          sortFunction={(e) =>
            [...e.data].sort((a: UserAdminDataFullRecord, b: UserAdminDataFullRecord) =>
              getRoleNameById(a.roleId) > getRoleNameById(b.roleId) ? e.order || 0 : -(e.order || 0)
            )
          }
        />
        <Column
          field="status"
          header={labels.status}
          sortable
          bodyClassName="text-overflow-ellipsis"
          body={(data: UserAdminDataFullRecord) => StatusLabels[data.status || ''] || data.status}
        />
        <Column
          field="updatedAt"
          header={labels.lastActive}
          body={(data: UserAdminDataFullRecord) =>
            formatDateUTC(data.updatedAt, dateFormat) + ' ' + formatDateHHMM(new Date(data.updatedAt), timeFormat)
          }
          sortable
          bodyClassName="text-overflow-ellipsis"
          frozen
        />
        <Column
          body={(data) => UserRowActions({ data, setConfirmDialogProps })}
          className="w-4rem"
          align="center"
          frozen
        />
      </DataTable>
      <InviteDialog key="InviteDialog" />
      <EditUser key="EditUserDialog" />
      <ConfirmDialog
        key="DisableUserDialog"
        isDisableUser={isDisableUser}
        isEditConfirm={isEditConfirm}
        setSearchString={setSearchString}
      />
    </>
  );
};
