import { UpdateBookingTemplateInput } from '../../API';
import { APIPostRequest } from '../utils/reduxUtils';

// ***
// When changing functions, do not forget to specify new data
// in src/docs/BookingTemplates.md
// ***

// ***
// When changing functions, do not forget to specify new data
// in src/docs/BookingTemplates.md
// ***

export async function getBookingTemplates(workspaceId: string) {
  try {
    const response = (await APIPostRequest('/api/public/getBookingTemplate', {
      workspaceId,
    })).bookingTemplates as UpdateBookingTemplateInput[];
    if (response) {
      return response;
    } else {
      throw new Error('BookingTemplates not found');
    }
  } catch (error: unknown) {
    console.error('Redux bookingTemplates service; Error fetching BookingTemplates:', error);
    throw error;
  }
}

export async function getBookingTemplate(BookingTemplateId: string) {
  try {
    const response = (await APIPostRequest('/api/public/getBookingTemplate', {
      BookingTemplateId,
    })).bookingTemplates as UpdateBookingTemplateInput[];
    if (response && response.length > 0) {
      return response[0];
    } else {
      throw new Error('BookingTemplate not found');
    }
  } catch (error: unknown) {
    console.error('Redux bookingTemplate service; Error fetching BookingTemplate:', error);
    throw error;
  }
}

export async function postBookingTemplate(bookingTemplate: UpdateBookingTemplateInput) {
  try {
    await APIPostRequest('/api/js/upsertBookingTemplate', { data: bookingTemplate });
  } catch (error: unknown) {
    console.error('Redux bookingTemplates service; Error saving BookingTemplates:', error);
    throw error;
  }
}

export const deleteBookingTemplates = async (bookingTemplateIds: string[], workspaceId: string) => {
  try {
    await APIPostRequest('/api/js/deleteBookingTemplate', {
      data: bookingTemplateIds.map((id) => ({ id, workspaceId })),
    });
  } catch (error: unknown) {
    console.error('Redux bookingTemplates service; Error deleting BookingTemplates:', error);
    throw error;
  }
};
