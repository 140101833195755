import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import { OrgExpireTrialModal, OrgsFilters, OrgsTable } from '../../../components/opsConsole';
import labels from './labels';
import { Divider } from 'primereact/divider';
import { useDispatch } from 'react-redux';
import { orgsActions, orgsSelectors } from '../../../store/opsConsole/orgs';
import { Preloader, SectionTitle } from '../../../components/common';
import { useSelector } from 'react-redux';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { OrgExtendTrialModal } from '../../../components/opsConsole/orgs/orgExtendTrialModal/OrgExtendTrialModal';
import { opsConsoleSelectors } from '../../../store/opsConsole/common/selectors';
import { opsConsoleActions } from '../../../store/opsConsole/common/actions';

export const Orgs = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(orgsSelectors.selectIsFetching);
  const searchString = useSelector(opsConsoleSelectors.selectSearchString);

  useEffect(() => {
    dispatch(orgsActions.getOrgsRequest());
  }, []);

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle
          id="orgsTitle"
          icon="pi-cloud"
          title={labels.title}
        />
      </div>
      <div className="card">
        <div className="my-3 pb-1 p-inputgroup w-12 md:w-6">
          <span className="p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </span>
          <InputText
            placeholder={labels.search}
            value={searchString}
            onChange={(e) => dispatch(opsConsoleActions.setSearchString(e.target.value))}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <Divider />
        <OrgsFilters />
        <OrgsTable />
      </div>
      <OrgExtendTrialModal />
      <OrgExpireTrialModal />
    </div>
  );
};
