import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import {
  NotificationTypes,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../../store/bookingTemplates';
import { Divider } from 'primereact/divider';
import labels from './labels';
import { NotificationItem } from '../notificationItem/NotificationItem';

type NotificationStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const NotificationStep = ({ selectors, actions, isReadOnly }: NotificationStepProps) => {
  const dispatch = useDispatch();
  const confirmation = useSelector(selectors.selectConfirmation);
  const reschedule = useSelector(selectors.selectReschedule);
  const reminder = useSelector(selectors.selectReminder);
  const reminderDuplicateTimerIndexes = useSelector(selectors.selectReminderDuplicateTimerIndexes);
  const cancelation = useSelector(selectors.selectCancelation);
  const followUp = useSelector(selectors.selectFollowUp);
  const sms = useSelector(selectors.selectSmsReminder);
  const smsDuplicateTimerIndexes = useSelector(selectors.selectSmsDuplicateTimerIndexes);

  return (
    <div className="p-fluid pb-2">
      <NotificationItem
        type={NotificationTypes.CONFIRMATION}
        notification={confirmation}
        onChange={(confirmations) => dispatch(actions.updateNotificationsStep({ confirmations }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.RESCHEDULE}
        notification={reschedule}
        onChange={(reschedule) => dispatch(actions.updateNotificationsStep({ reschedule }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.REMINDER}
        notification={reminder}
        timerDuplicateIndexes={reminderDuplicateTimerIndexes}
        onChange={(reminders) => dispatch(actions.updateNotificationsStep({ reminders }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.CANCELATION}
        notification={cancelation}
        onChange={(cancelations) => dispatch(actions.updateNotificationsStep({ cancelations }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.FOLLOW_UP}
        notification={followUp}
        onChange={(followUp) => dispatch(actions.updateNotificationsStep({ followUp }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />

      <Divider />

      <div className="w-12 flex align-items-center p-2 mb-3 border-round" style={{ background: '#e7f4fd' }}>
        <span className="pi pi-info-circle mr-2"></span>
        <span className="font-bold">{labels.smsLabelPart1}</span>
        &nbsp;- {labels.smsLabelPart2}
      </div>
      <NotificationItem
        type={NotificationTypes.SMS}
        notification={sms}
        timerDuplicateIndexes={smsDuplicateTimerIndexes}
        onChange={(smsReminder) => dispatch(actions.updateNotificationsStep({ smsReminder }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
    </div>
  );
};
