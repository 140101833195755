import { useDispatch, useSelector } from 'react-redux';
import labels from './labels';
import { Dropdown } from 'primereact/dropdown';
import { NONE_VALUE, TIME_OPTIONS } from './constants';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../../../store/bookingTemplates';
import { bookingPageSelectors, bookingPagesActions } from '../../../../../store/bookingPages';

type BufferTimeSettingsProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const BufferTimeSettings = ({ selectors, actions, isReadOnly }: BufferTimeSettingsProps) => {
  const dispatch = useDispatch();
  const beforeStartTime = useSelector(selectors.selectTimeBeforeStartTime);
  const afterEndTime = useSelector(selectors.selectTimeAfterEndTime);

  const handleBeforeSelectChange = (value: string) => {
    const beforeStartTime = value === NONE_VALUE ? null : value;
    dispatch(actions.updateWhenStep({ beforeStartTime }));
  };

  const handleAfterSelectChange = (value: string) => {
    const afterEndTime = value === NONE_VALUE ? null : value;
    dispatch(actions.updateWhenStep({ afterEndTime }));
  };

  return (
    <div className="grid align-items-end p-fluid pb-2">
      <div className="col-12 font-bold">{labels.title}</div>
      <div className="col-12 md:col-6 flex flex_column">
        <div className="w-12 md:w-4">
          <label>{labels.beforeCheckboxLabel}</label>
          <Dropdown
            className="mb-3"
            value={beforeStartTime || NONE_VALUE}
            options={TIME_OPTIONS}
            optionLabel="label"
            onChange={(e) => handleBeforeSelectChange(e.target.value)}
            disabled={isReadOnly}
          />
          <label>{labels.afterCheckboxLabel}</label>
          <Dropdown
            value={afterEndTime || NONE_VALUE}
            options={TIME_OPTIONS}
            optionLabel="label"
            onChange={(e) => handleAfterSelectChange(e.target.value)}
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="col-12 md:col-6">
        <div className="p-4 border-1 border-round border-gray-300">
          <div className="h-1rem bg-blue-200" style={{ display: beforeStartTime ? 'block' : 'none' }}></div>
          <div className="h-5rem bg-gray-200 font-bold flex align-items-center justify-content-center">
            {labels.eventTime}
          </div>
          <div className="h-1rem bg-blue-200" style={{ display: afterEndTime ? 'block' : 'none' }}></div>
        </div>
      </div>
    </div>
  );
};
