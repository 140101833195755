import { t } from '../../../../i18n/i18n';

export default {
  description: t('EditBookingTemplateCustomField:DESCRIPTION'),
  questionLabel: t('EditBookingTemplateCustomField:QUESTION_LABEL'),
  requiredLabel: t('EditBookingTemplateCustomField:REQUIRED_LABEL'),
  activeLabel: t('EditBookingTemplateCustomField:ACTIVE_LABEL'),
  typeLabel: t('EditBookingTemplateCustomField:TYPE_LABEL'),
  typeCheckboxes: t('EditBookingTemplateCustomField:TYPE_CHECKBOXES'),
  typeRadioButtons: t('EditBookingTemplateCustomField:TYPE_RADIO_BUTTONS'),
  typeSelect: t('EditBookingTemplateCustomField:TYPE_SELECT'),
  typeTel: t('EditBookingTemplateCustomField:TYPE_TEL'),
  typeText: t('EditBookingTemplateCustomField:TYPE_TEXT'),
  typeTextarea: t('EditBookingTemplateCustomField:TYPE_TEXTAREA'),
  answersLabel: t('EditBookingTemplateCustomField:ANSWERS_LABEL'),
  answersSingleDescription1: t('EditBookingTemplateCustomField:ANSWERS_SINGLE_DESCRIPTION_1'),
  answersSingleDescription2: t('EditBookingTemplateCustomField:ANSWERS_SINGLE_DESCRIPTION_2'),
  answersSingleDescription3: t('EditBookingTemplateCustomField:ANSWERS_SINGLE_DESCRIPTION_3'),
  answersMultipleDescription1: t('EditBookingTemplateCustomField:ANSWERS_MULTIPLE_DESCRIPTION_1'),
  answersMultipleDescription2: t('EditBookingTemplateCustomField:ANSWERS_MULTIPLE_DESCRIPTION_2'),
  answersMultipleDescription3: t('EditBookingTemplateCustomField:ANSWERS_MULTIPLE_DESCRIPTION_3'),
  placeholder: t('EditBookingTemplateCustomField:ANSWER_PLACEHOLDER'),
  addAnswerButton: t('EditBookingTemplateCustomField:ADD_ANSWER_BUTTON'),
  deleteButton: t('EditBookingTemplateCustomField:DELETE_BUTTON'),
  cancelButton: t('EditBookingTemplateCustomField:CANCEL_BUTTON'),
  saveButton: t('EditBookingTemplateCustomField:SAVE_BUTTON'),
};
