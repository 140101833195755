import { CHARGEBEE_YEARLY_TERM, TIERS_DEFAULT_MONTHLY, TIERS_DEFAULT_YEARLY } from './constants';
import { SubscriptionTier } from './types';

export const getPricePerUser = (quantity: number, tiers: SubscriptionTier[], term: string) => {
  const defindedTiers = tiers.length ? tiers : term && term === CHARGEBEE_YEARLY_TERM ? TIERS_DEFAULT_YEARLY : TIERS_DEFAULT_MONTHLY;

  for (let i = 0; i < defindedTiers.length; i++) {
    const tier = defindedTiers[i];
    const startingUnit = tier.starting_unit;
    const endingUnit = tier.ending_unit || Infinity; // Assume no upper limit if ending_unit is not defined

    if (quantity >= startingUnit && quantity <= endingUnit) {
      return tier.price / 100; // in dollars (not cents)
    }
  }

  return defindedTiers[defindedTiers.length - 1].price; // Return the price of the last tier if quantity exceeds all defined tiers
};

export const getTotalAmount = (quantity: number, term: string, tiers: SubscriptionTier[]) => {
  const defindedTiers = tiers.length ? tiers : term && term === CHARGEBEE_YEARLY_TERM ? TIERS_DEFAULT_YEARLY : TIERS_DEFAULT_MONTHLY;
  let totalAmount = 0;
  let remainingQuantity = quantity;

  for (const tier of defindedTiers) {
    if (remainingQuantity === 0) break;

    const pricePerUser = tier.price / 100; // Convert price to dollars
    const startingUnit = tier.starting_unit;
    const endingUnit = tier.ending_unit || Infinity; // Assume no upper limit if ending_unit is not defined

    if (remainingQuantity >= startingUnit) {
      const applicableQuantity = Math.min(remainingQuantity, endingUnit) - startingUnit + 1;
      totalAmount += pricePerUser * Math.min(applicableQuantity, remainingQuantity);
      // remainingQuantity -= applicableQuantity;
    }

  }
  return totalAmount;
};

export const getCurrencyLabelByCode = (code: string) => {
  switch (code) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
    default:
      return '$';
  }
};
