import { Tooltip } from 'primereact/tooltip';
import labels from '../../../pages/workspaces/labels';

type SectionTitleProps = {
  icon: string;
  id: string;
  title: string;
  tooltip?: string;
  learnMoreURL?: string;
};

export const SectionTitle = ({ icon, id, title, tooltip, learnMoreURL }: SectionTitleProps) => {

  return (
    <div className="flex align-items-center">
      <i className={`pi ${icon} text-2xl mr-3`}></i>
      <Tooltip target={`.${id}`} className="max-w-30rem" />
      <div
        className={`${id} text-xl font-medium`}
        style={{ 'cursor': 'default' }}
        data-pr-position="top"
        data-pr-my="center bottom-10"
        data-pr-tooltip={tooltip || undefined}
      >
        {title}
      </div>
      {learnMoreURL &&
        <a target="blank" href={learnMoreURL} className="ml-3 text-color underline">
          {labels.learnMore}
        </a>
      }
    </div>
  );
};
