import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Path } from '.';

interface RedirectProps {
  path: Path;
}

export function Redirect({ path }: RedirectProps) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate({
      pathname: path,
      search: location.search,
    });
  }, [navigate, path, location.search]);

  return null;
}
