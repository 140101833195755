import React, { useState } from 'react';
import { changeTheme } from '../../../services/utils';

export const LayoutContext = React.createContext();

export const DiamondLayoutProvider = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [layoutConfig, setLayoutConfig] = useState({
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'drawer',
    menuTheme: 'white',
    colorScheme: 'light',
    theme: 'blue',
    scale: 14,
  });

  const changeColorScheme = (newTheme) => {
    setLayoutConfig((prev) => ({
      ...prev,
      colorScheme: newTheme,
    }));
    changeTheme(newTheme);
  };

  const [layoutState, setLayoutState] = useState({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    overlaySubmenuActive: false,
    rightMenuVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    searchBarActive: false,
    resetMenu: false,
    sidebarActive: false,
    anchored: false,
  });

  const onMenuToggle = (event) => {
    if (isOverlay()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        overlayMenuActive: !prevLayoutState.overlayMenuActive,
      }));
    }
    if (isDesktop()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive,
      }));
    } else {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
      }));

      event.preventDefault();
    }
  };

  const hideOverlayMenu = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      overlayMenuActive: false,
      staticMenuMobileActive: false,
    }));
  };

  const toggleSearch = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      searchBarActive: !layoutState.searchBarActive,
    }));
  };

  const onSearchHide = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      searchBarActive: false,
    }));
  };

  const onRightMenuButtonClick = (event) => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      rightMenuVisible: !layoutState.rightMenuVisible,
    }));
    hideOverlayMenu();

    event.preventDefault();
  };

  const isOverlay = () => {
    return layoutConfig.menuMode === 'overlay';
  };

  const isSlim = () => {
    return layoutConfig.menuMode === 'slim';
  };

  const isCompact = () => {
    return layoutConfig.menuMode === 'compact';
  };

  const isHorizontal = () => {
    return layoutConfig.menuMode === 'horizontal';
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const value = {
    layoutConfig,
    setLayoutConfig,
    layoutState,
    setLayoutState,
    isSlim,
    isCompact,
    isHorizontal,
    isDesktop,
    onMenuToggle,
    toggleSearch,
    onSearchHide,
    onRightMenuButtonClick,
    breadcrumbs,
    setBreadcrumbs,
    changeColorScheme,
  };

  return <LayoutContext.Provider value={value}>{props.children}</LayoutContext.Provider>;
};
