import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent } from 'react';

type ColorPickerInputProps = {
  name: string;
  value: string;
  label?: string;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleColorPickerChange: (e: ColorPickerChangeEvent) => void;
  disabled?: boolean;
};

export const ColorPickerInput: React.FC<ColorPickerInputProps> = ({
  name,
  value,
  label,
  handleInputChange,
  handleColorPickerChange,
  disabled,
}) => (
  <>
    {label && <div className="pt-2">{label}</div>}
    <div className="flex flex-row align-items-center">
      <ColorPicker name={name} value={value} onChange={(e) => handleColorPickerChange(e)} disabled={disabled} />
      <InputText
        name={`${name}_input`}
        value={value}
        onChange={(e) => handleInputChange(e)}
        maxLength={7}
        className="w-7rem ml-2 uppercase"
        disabled={disabled}
      />
    </div>
  </>
);
