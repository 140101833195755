export const Account = Object.freeze({
  MY_ROLE_TITLE: `Mi Rol`,
  ROLE_DESCRIPTION: `Su rol actual - `,
  TITLE: `Configuración de la Cuenta`,
});

export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Puede conectar 1 calendario`, // Para aumentar este límite, contacte a ventas para actualizar su nivel de licencia.
  DESCRIPTION: `Conecte su calendario para que SUMO sepa cuándo está disponible y actualice su calendario a medida que se reservan citas.`,
  DISCONNECT_MESSAGE: `¿Está seguro de que desea desconectar su calendario?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Las citas no se eliminarán ni cancelarán.`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 o 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendario de Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendario de Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Calendario de Office 365, Outlook.com, Live.com o Hotmail`,
  MICROSOFT_CALENDAR_TITLE: `Calendario de Office 365`,
  SELECT_CALENDAR_TITLE: `Seleccionar Calendario`,
  TITLE: `Conexiones de Calendario`,
  TOOLTIP: `Conecta tu calendario de Google o Microsoft a SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Aceptar y Cerrar`,
  DESCRIPTION: `Cuando visita un sitio web, tiene la capacidad de almacenar o recuperar cierta información en su navegador. Esta información, a menudo en forma de cookies, puede ser sobre sus preferencias, dispositivo u otros detalles. Su propósito principal es garantizar que el sitio web funcione como usted esperaría. Aunque esta información generalmente no lo identifica directamente, puede mejorar su experiencia de navegación personalizando el contenido. Entendemos y valoramos su derecho a la privacidad, por eso le ofrecemos la opción de controlar y personalizar sus preferencias de cookies. Al hacer clic en las diversas categorías, puede obtener más información sobre cada tipo de cookie y modificar nuestras configuraciones predeterminadas. Tenga en cuenta que bloquear ciertos tipos de cookies puede afectar su experiencia general en el sitio y limitar los servicios que podemos ofrecerle.`,
  DESCRIPTION_FIRST_PART: `Cuando visita un sitio web, tiene la capacidad de almacenar o recuperar cierta información en su navegador. Esta información, a menudo en forma de cookies, puede ser sobre sus preferencias, dispositivo u otros detalles. Su propósito principal es garantizar que el sitio web funcione como usted esperaría.`,
  DESCRIPTION_SECOND_PART: `Aunque esta información generalmente no lo identifica directamente, puede mejorar su experiencia de navegación personalizando el contenido. Entendemos y valoramos su derecho a la privacidad, por eso le ofrecemos la opción de controlar y personalizar sus preferencias de cookies.`,
  DESCRIPTION_THIRD_PART: `Al hacer clic en las diversas categorías, puede obtener más información sobre cada tipo de cookie y modificar nuestras configuraciones predeterminadas. Tenga en cuenta que bloquear ciertos tipos de cookies puede afectar su experiencia general en el sitio y limitar los servicios que podemos ofrecerle.`,
  FUNCTIONAL_NAME: `Cookies Funcionales`,
  MANAGE_TITLE: `Administrar Preferencias de Consentimiento`,
  NECESSARY_ONLY: `Solo estrictamente necesario`,
  PERFORMANCE_NAME: `Cookies de Rendimiento`,
  STRICTLY_NAME: `Cookies Estrictamente Necesarias`,
  TARGETING_NAME: `Cookies de Segmentación`,
  TITLE: `Configuración de Cookies`,
});

export const Login = Object.freeze({
  CONTACT_US: `Por favor, póngase en contacto con el soporte si necesita ayuda`,
  DESCRIPTION: `Inicia sesión en SUMO1 con la cuenta conectada siguiente`,
  GOOGLE_ACCOUNT: `Cuenta de Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Cambiar Cuentas de Google`,
  GOOGLE_SWITCH: `Cambiar a Inicio de Sesión de Google`,
  GOOGLE_SWITCH_DESC: `Cambiar de autenticación de Microsoft a Google`,
  MICROSOFT_ACCOUNT: `Cuenta de Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Cambiar Cuentas de Microsoft`,
  MICROSOFT_SWITCH: `Cambiar a Inicio de Sesión de Microsoft`,
  MICROSOFT_SWITCH_DESC: `Cambiar de autenticación de Google a Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Su cuenta de SUMO1 perderá acceso a datos solo disponibles para usted a través de su proveedor original. También puede ser necesario volver a otorgar acceso a integraciones y calendarios.`,
  SWITCH_DESCRIPTION_PART1: `Cambiar de autenticación `,
  SWITCH_DESCRIPTION_PART2: ` a `,
  TITLE: `Iniciar Sesión`,
});

export const MyLink = Object.freeze({
  LINK: `Nombre del Enlace`,
  LINK_HELPER_TEXT: `Mínimo 3 símbolos. Utilice solo caracteres a-z, 0-9 y -.&=_'-+,<>`,
  SUBTITLE: `Cambiar su URL de SUMO1 significa que todos los enlaces copiados ya no funcionarán y se actualizarán`,
  TITLE: `Mi Enlace`,
});

export const Phone = Object.freeze({
  LABEL: `Número de Teléfono`,
  TITLE: `Número(s) de Teléfono`,
});

export const Profile = Object.freeze({
  DATE_FORMAT: `Formato de Fecha`,
  DELETE_ACCOUNT: `Eliminar Cuenta`,
  DELETE_ACCOUNT_DIALOG_TEXT: `¿Está seguro de que desea eliminar su cuenta? Su solicitud se enviará al administrador.`,
  DELETE_ACCOUNT_REQUEST_TEXT: `La solicitud de eliminación se ha enviado al administrador.`,
  DELETE_ACCOUNT_REQUEST_TITLE: `Enviado`,
  LANGUAGE: `Idioma`,
  NAME: `Nombre`,
  WELCOME_MESSAGE: `Mensaje de Bienvenida`,
  PHONE_DETAILS_LABELS: `Detalles del teléfono`,
  PHONE_DETAILS_PLACEHOLDER: `Agrega información adicional o instrucciones aquí.\r\nEjemplo: Espero con interés la reunión. Envíame un SMS en cualquier momento.`,
  TIME_FORMAT: `Formato de Hora`,
  TIME_ZONE: `Zona Horaria`,
  TITLE: `Perfil`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: `Falló la solicitud de nuevo calendario`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: `Nuevo calendario conectado con éxito`,
  CONNECT_ZOOM_ERROR_TOAST: `Falló la solicitud de conexión de Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST: `Zoom conectado con éxito`,
  GET_USER_SETTINGS_FAIL: `No se encontraron datos de usuario, por favor, contacte al soporte`,
  SAVE_USER_SETTINGS_ERROR_TOAST: `Falló la solicitud de guardar configuraciones`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST: `¡Sus configuraciones se guardaron exitosamente!`,
  UPDATE_USER_LINK_ERROR_MESSAGE: `El enlace ya está en uso`,
  UPDATE_USER_LINK_SUCCESS_MESSAGE: `¡Su enlace se actualizó exitosamente!`,
});
