import { Button } from 'primereact/button';
import labels from './labels';
import { Modal } from '../../common';
import {
  BUY_LICENSE_MODAL,
  CHARGEBEE_TERM_OPTIONS,
  CHARGEBEE_MONTHLY_TERM,
  buyLicenseModalActions,
  getTotalAmount,
  CHARGEBEE_YEARLY_TERM,
  billingSelectors,
} from '../../../store/billing';
import { useEffect, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authentificationActions, authentificationSelectors } from '../../../store/authentification';
import { subscribe } from '../../../services/chargebee';

export const BuyLicenseButton = () => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(userSettingsSelectors.selectCustomerInfo);
  const licensesAssigned = useSelector(authentificationSelectors.selectAssigned);
  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);
  const tenantId = useSelector(authentificationSelectors.selectTenantId);
  const licenseFetching = useSelector(authentificationSelectors.selectLicensesIsFetching);
  const currencyLabel = useSelector(billingSelectors.selectCurrencyLabel);
  const [quantity, setQuantity] = useState<number | null>(licensesAssigned);
  const [term, setTerm] = useState(CHARGEBEE_MONTHLY_TERM);
  const [amount, setAmount] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const tires = useSelector(billingSelectors.selectSubscriptionTiers) || [];
  let remainingQuantity = quantity ?? 0;

  useEffect(() => {
    setQuantity(licensesAssigned);
  }, [licensesAssigned]);

  useEffect(() => {
    setAmount(getTotalAmount(quantity || 0, term, tires));
  }, [quantity, term]);

  const resetState = () => {
    setQuantity(licensesAssigned);
    setTerm(CHARGEBEE_MONTHLY_TERM);
  };

  const handleOpenModal = () => {
    dispatch(buyLicenseModalActions.openModal());
  };

  const handleClose = () => {
    dispatch(buyLicenseModalActions.closeModal());
    resetState();
  };

  const chargeBeeCheckoutSuccess = () => {
    setIsSuccess(true);
    dispatch(authentificationActions.refreshTrialLicenseRequest());
  };

  const handlePurchase = () => {
    if (quantity) {
      subscribe(quantity, term, customerInfo, tenantId, chargeBeeCheckoutSuccess);
      handleClose();
    }
  };

  return (
    <>
      <Modal.Container name={BUY_LICENSE_MODAL}>
        <Modal.Header>
          <div className="w-25rem text-center">{labels.buyLicenses}</div>
        </Modal.Header>
        <div className="p-fluid pb-2">
          <label htmlFor="quantity">{labels.numberLicenses}</label>
          <InputNumber
            showButtons
            inputId="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.value)}
            placeholder={labels.enterNumber}
            min={0}
            className={!quantity || quantity < licensesAssigned ? 'p-invalid' : ''}
          />
          {!!quantity && quantity < licensesAssigned && (
            <small id="quantity-help" className="p-error block w-25rem">
              {`${labels.licenseMessagePart1} ${licensesAssigned} ${labels.licenseMessagePart2}${
                isLicenseActive ? labels.licenseMessagePart3 : ''
              }.`}
            </small>
          )}
        </div>
        <div className="p-fluid pb-2">
          <label htmlFor="term">{labels.term}</label>
          <Dropdown
            inputId="term"
            value={term}
            options={CHARGEBEE_TERM_OPTIONS}
            onChange={(e) => setTerm(e.target.value)}
          />
        </div>
        <div className="pt-2">
          {`${labels.buyLicensesMessage} ${currencyLabel}${amount} ${
            term === CHARGEBEE_YEARLY_TERM ? labels.annually : labels.monthly
          }.`}
        </div>
        <div className="text-sm">
          {tires.map((tier, index) => {
            const endingUnit = tier.ending_unit ?? 0;
            const tierQuantity =
              remainingQuantity > endingUnit
                ? endingUnit - tier.starting_unit + 1
                : remainingQuantity - tier.starting_unit + 1;
            if (tierQuantity <= 0) return null;

            const tierPrice = tier.price / 100;
            const tierAmount = tierPrice * tierQuantity * (term === CHARGEBEE_YEARLY_TERM ? 12 : 1);

            remainingQuantity = remainingQuantity - tierQuantity; // Ensure remainingQuantity is not null by defaulting to 0
            return (
              <div key={index}>
                {`${tierQuantity} ${labels.licenses} X ${currencyLabel}${tierPrice} X ${
                  term === CHARGEBEE_YEARLY_TERM ? 12 : 1
                } ${labels.month} = ${currencyLabel}${tierAmount}`}
              </div>
            );
          })}
        </div>
        <Modal.Buttons>
          <div className="flex justify-content-between -mr-2">
            <Button onClick={handleClose} outlined>
              {labels.cancel}
            </Button>
            <Button
              onClick={handlePurchase}
              autoFocus
              disabled={!quantity || quantity < licensesAssigned}
              className="px-4"
            >
              {labels.next}
            </Button>
          </div>
        </Modal.Buttons>
      </Modal.Container>
      <Button
        label={labels.buyLicenses}
        icon="pi pi-shopping-cart"
        disabled={isSuccess || licenseFetching}
        onClick={handleOpenModal}
      />
    </>
  );
};
