import { t } from 'i18next';
import { ToastNotificationOptions } from '../notifications';
import { CanceledType, IntegrationType, LocationType } from '../../API';
import { Statuses } from './typings';
import { SelectItemOptionsType } from 'primereact/selectitem';

export const editorTextRegex = /(<([^>]+)>)/gi;
export const statusList = [
  { label: t('Common:ALL'), value: Statuses.ALL },
  { label: t('ScheduledMeetings:BOOKED'), value: Statuses.BOOKED },
  { label: t('ScheduledMeetings:CANCELED'), value: Statuses.CANCELED },
];

export const locationIcon = {
  [LocationType.IN_PERSONAL]: 'pi pi-map-marker',
  [LocationType.PHONE_CALL]: 'pi pi-phone',
  [IntegrationType.GOOGLE_MEET]: 'pi pi-google',
};

export const locationLabels = {
  [LocationType.IN_PERSONAL]: t('ScheduledMeetings:IN_PERSON'),
  [LocationType.PHONE_CALL]: t('ScheduledMeetings:PHONE_CALL'),
  [LocationType.VIDEO_CONFERENCE]: t('ScheduledMeetings:VIDEO_CONFERENCE'),
};

export const cancelReasons = {
  [CanceledType.UNEXPECTED]: t('ScheduledMeetings:REASON_UNEXPECTED'),
  [CanceledType.INVITE_OTHERS]: t('ScheduledMeetings:REASON_INVITE'),
  [CanceledType.TIME]: t('ScheduledMeetings:REASON_TIME'),
  [CanceledType.OTHER]: t('ScheduledMeetings:REASON_OTHER')
}

export const CANCEL_TYPE_OPTIONS: SelectItemOptionsType = [
  { value: CanceledType.UNEXPECTED, label: t('ScheduledMeetings:REASON_UNEXPECTED') },
  { value: CanceledType.INVITE_OTHERS, label: t('ScheduledMeetings:REASON_INVITE') },
  { value: CanceledType.TIME, label: t('ScheduledMeetings:REASON_TIME') },
  { value: CanceledType.OTHER, label: t('ScheduledMeetings:REASON_OTHER') },
];

export const GET_SCHEDULED_MEETINGS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('ScheduledMeetingsToastsNotifications:GET_SCHEDULED_MEETINGS_ERROR_TOAST'),
});

export const CANCEL_EVENT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('ScheduledMeetingsToastsNotifications:CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE'),
});
export const CANCEL_EVENT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 3000,
  message: t('ScheduledMeetingsToastsNotifications:CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE'),
});

export const UPDATE_SCHEDULED_MEETING_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('ScheduledMeetingsToastsNotifications:UPDATE_SCHEDULED_MEETING_ERROR_TOAST'),
});
