import { useDispatch, useSelector } from 'react-redux';
import { VideoConferenceType } from '../../../API';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { Checkbox } from 'primereact/checkbox';
import labels from './labels';

type DefaultVideoConferenceProps = {
  videoConferenceType: VideoConferenceType;
};
export const DefaultVideoConference = ({ videoConferenceType }: DefaultVideoConferenceProps) => {
  const dispatch = useDispatch();
  const defaultVideoConference = useSelector(userSettingsSelectors.selectDefaultVideoIntegration);

  const handleDefaultClick = (videoConferenceType: VideoConferenceType) => {
    if (videoConferenceType !== defaultVideoConference) {
      dispatch(userSettingsActions.updateUserSettings({ defaultVideoIntegration: videoConferenceType }));
      dispatch(userSettingsActions.saveUserSettingsRequest());
    }
  };

  return (
    <div className="mt-auto pb-4">
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          inputId={`default${videoConferenceType}`}
          checked={defaultVideoConference === videoConferenceType}
          onChange={() => handleDefaultClick(videoConferenceType)}
        />
        <label htmlFor={`default${videoConferenceType}`} className="cursor-pointer hover:text-primary ml-2">
          {labels.defaultDescription}
        </label>
      </div>
    </div>
  );
};
