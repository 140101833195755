import { useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { SettingsStep } from '../../common/meeting/settingsStep/SettingsStep';

export const BookingTemplateSettingsStep = () => {
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);

  return (
    <SettingsStep selectors={bookingTemplatesSelectors} actions={bookingTemplatesActions} isReadOnly={isReadOnly} />
  );
};
