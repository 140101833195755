import { useDispatch, useSelector } from 'react-redux';
import { USERS_CSV_HEADERS, getRoleNameByRole, OPSConsoleUsersActions, OPSConsoleUsersSelectors } from '../../../store/opsConsole/users';
import { Preloader, SectionTitle } from '../../../components/common';
import labels from './labels';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { useEffect } from 'react';
import { Divider } from 'primereact/divider';
import { UsersTable } from '../../../components/opsConsole';
import { Button } from 'primereact/button';
import { convertUnixToFormattedDate, formatDateTimeThroughGap } from '../../../services/DateService';
import { DEFAULT_PRICING_TIER } from '../../../store/opsConsole/orgs';
import dayjs from 'dayjs';
import { DateFormat, TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { downloadCSV } from '../../../services/utils';
import { opsConsoleSelectors } from '../../../store/opsConsole/common/selectors';
import { opsConsoleActions } from '../../../store/opsConsole/common/actions';

export const OPSConsoleUsers = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(OPSConsoleUsersSelectors.selectIsFetching);
  const searchString = useSelector(opsConsoleSelectors.selectSearchString);
  const users = useSelector(OPSConsoleUsersSelectors.selectUsers);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat) || DateFormat.default;
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;

  useEffect(() => {
    dispatch(OPSConsoleUsersActions.getUsersRequest());
  }, []);

  // utility to Convert Users Array to CSV
  const convertArrayToCSV = (): string => {
    const rows = users.map((user) =>
      [
        user.userName,
        user.email,
        user.accountId,
        user.accountName,
        user.tenantId,
        user.roleName || getRoleNameByRole(user.role),
        user.status,
        user.firstLogin && formatDateTimeThroughGap(user.firstLogin),
        user.lastActive && convertUnixToFormattedDate(Number(user.lastActive), dateFormat, timeFormat),
        user.bookedMeetings || 0,
        DEFAULT_PRICING_TIER,
        user?.license?.type || '',
        user?.license?.endDate ? dayjs(user?.license?.endDate).format(DateFormat.DDMMYYYY) : '',
        user?.license?.assigned || 0,
      ].join(',')
    );

    return [USERS_CSV_HEADERS.join(',').toUpperCase(), ...rows].join('\n');
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle
          id="OPSConsoleUsersTitle"
          icon="pi-user"
          title={labels.title}
        />
      </div>
      <div className="card">
        <div className="flex justify-content-between align-items-center my-3 flex-wrap">
          <div className="p-inputgroup w-12 md:w-6">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              placeholder={labels.search}
              value={searchString}
              onChange={(e) => dispatch(opsConsoleActions.setSearchString(e.target.value))}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>
          <Button
            icon="pi pi-cloud-download"
            label={labels.export}
            text
            className="mt-2 md:mt-0"
            onClick={() => downloadCSV(convertArrayToCSV(), 'users.csv')}
          />
        </div>
        <Divider />
        <UsersTable />
      </div>
    </div>
  );
};
