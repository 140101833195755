import { CreateAdminDataInput } from '../../API';
import { UserAdminDataFullRecord } from './typings';
import { APIPostRequest } from '../utils/reduxUtils';

export const getUsers = async (tenantId: string) => {
  try {
    const response = (await APIPostRequest('/api/public/getAdminDataByTenant', {
      tenantId,
    })).users as UserAdminDataFullRecord[];

    if (response) {
      return response;
    } else {
      throw new Error('AdminUserData not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching admin users:', error);
    throw error;
  }
};

export const inviteUsers = async (data: CreateAdminDataInput[], expirationInviteDays: number) => {
  try {
    await APIPostRequest('/api/js/sendUsersInviteLinks', {
      data,
      expirationInviteDays,
    });
  } catch (error: unknown) {
    console.error('Error invite users', error);
    throw error;
  }
};

export const editUser = async (data: UserAdminDataFullRecord[]) => {
  try {
    await APIPostRequest('/api/js/editAdminData', { data });
  } catch (error: unknown) {
    console.error('Error edit user', error);
    throw error;
  }
};

export const deleteUser = async (email: string, tenantId: string) => {
  try {
    await APIPostRequest('/api/js/deleteUserData', {
      email,
      tenantId,
    });
  } catch (error: unknown) {
    console.error('Error delete user', error);
    throw error;
  }
};
