import {
  DEFAULT_EXTEND_DAYS,
  EXTEND_DAYS_OPTIONS,
  EXTEND_TRIAL_MODAL_NAME,
  MAX_TRIAL_DURATION,
  extendTrialModalActions,
  orgsActions,
  orgsSelectors,
} from '../../../../store/opsConsole/orgs';
import { Modal } from '../../../common';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import labels from './labels';
import { OPSConsoleUsersSelectors } from '../../../../store/opsConsole/users';

type OrgExtendTrialModalProps = {
  isFromDetails?: boolean;
};

export const OrgExtendTrialModal = ({ isFromDetails }: OrgExtendTrialModalProps) => {
  const dispatch = useDispatch();
  const superAdminsEmails = useSelector(OPSConsoleUsersSelectors.selectSuperAdminsEmails);
  const duration = useSelector(orgsSelectors.selectLicenseDuration);
  const endDate = useSelector(orgsSelectors.selectEndDate);
  const [extendDays, setExtendDays] = useState(DEFAULT_EXTEND_DAYS);

  const handleClose = () => {
    dispatch(extendTrialModalActions.closeModal());
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    dispatch(orgsActions.extendLicenseRequest({ expirationDate: endDate, extendDays, isFromDetails, superAdminsEmails }));
    handleClose();
  };

  return (
    <Modal.Container name={EXTEND_TRIAL_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="max-w-30rem">
        <div className="w-full py-2 px-3 mb-2 uppercase sumo-header-bg-dark">{labels.rules}</div>
        <ul>
          {labels.rulesDesc.map((desc, index) => (
            <li key={`rules-${index}`}>{desc}</li>
          ))}
        </ul>
        <div className="w-full py-2 px-3 mb-2 uppercase sumo-header-bg-dark">{labels.extend}</div>
        <div className="flex flex-column mx-2">
          <label htmlFor="numDays">{labels.extendDesc}</label>
          <Dropdown
            value={extendDays}
            options={EXTEND_DAYS_OPTIONS}
            id="numDays"
            className="w-12 md:w-6"
            onChange={(e) => setExtendDays(e.target.value)}
          />
        </div>
        {duration >= MAX_TRIAL_DURATION && <div className="m-2 p-error">{labels.extendMessage}</div>}
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined />
          <Button label={labels.saveExtend} onClick={handleSave} autoFocus disabled={duration >= MAX_TRIAL_DURATION} />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
