export const Account = Object.freeze({
  MY_ROLE_TITLE: `Mon Rôle`,
  ROLE_DESCRIPTION: `Votre rôle actuel - `,
  TITLE: `Paramètres du Compte`,
});

export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Vous pouvez connecter 1 calendrier`, // Pour augmenter cette limite, contactez les ventes pour mettre à niveau votre niveau de licence.
  DESCRIPTION: `Connectez votre calendrier pour que SUMO sache quand vous êtes disponible et mette à jour votre calendrier lorsque des rendez-vous sont réservés.`,
  DISCONNECT_MESSAGE: `Êtes-vous sûr de vouloir déconnecter votre calendrier ?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Les rendez-vous ne seront ni supprimés ni annulés.`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Serveur Exchange 2013, 2016 ou 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendrier Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Calendrier Office 365, Outlook.com, Live.com ou Hotmail`,
  MICROSOFT_CALENDAR_TITLE: `Calendrier Office 365`,
  SELECT_CALENDAR_TITLE: `Sélectionner un Calendrier`,
  TITLE: `Connexions de Calendrier`,
  TOOLTIP: `Connectez votre calendrier Google ou Microsoft à SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Accepter et Fermer`,
  DESCRIPTION: `Lorsque vous visitez un site Web, il a la possibilité de stocker ou de récupérer certaines informations sur votre navigateur. Cette information, souvent sous forme de cookies, peut concerner vos préférences, votre appareil ou d'autres détails. Son objectif principal est de s'assurer que le site fonctionne comme vous vous y attendez.`,
  DESCRIPTION_FIRST_PART: `Lorsque vous visitez un site Web, il a la possibilité de stocker ou de récupérer certaines informations sur votre navigateur. Cette information, souvent sous forme de cookies, peut concerner vos préférences, votre appareil ou d'autres détails. Son objectif principal est de s'assurer que le site fonctionne comme vous vous y attendez.`,
  DESCRIPTION_SECOND_PART: `Bien que ces informations ne vous identifient généralement pas directement, elles peuvent améliorer votre expérience de navigation en personnalisant le contenu. Nous comprenons et respectons votre droit à la vie privée, c'est pourquoi nous vous donnons la possibilité de contrôler et de personnaliser vos préférences en matière de cookies.`,
  DESCRIPTION_THIRD_PART: `En cliquant sur les différentes catégories, vous pouvez en savoir plus sur chaque type de cookie et modifier nos paramètres par défaut. Veuillez noter que le blocage de certains types de cookies peut affecter votre expérience globale sur le site et limiter les services que nous pouvons vous offrir.`,
  FUNCTIONAL_NAME: `Cookies Fonctionnels`,
  MANAGE_TITLE: `Gérer les Préférences de Consentement`,
  NECESSARY_ONLY: `Uniquement Strictement Nécessaire`,
  PERFORMANCE_NAME: `Cookies de Performance`,
  STRICTLY_NAME: `Cookies Strictement Nécessaires`,
  TARGETING_NAME: `Cookies de Ciblage`,
  TITLE: `Paramètres des Cookies`,
});

export const Login = Object.freeze({
  CONTACT_US: `Veuillez contacter le support si vous avez besoin d'aide`,
  DESCRIPTION: `Vous vous connectez à SUMO1 avec le compte connecté suivant`,
  GOOGLE_ACCOUNT: `Compte Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Changer de comptes Google`,
  GOOGLE_SWITCH: `Passer à la connexion Google`,
  GOOGLE_SWITCH_DESC: `Passer de l'authentification Microsoft à Google`,
  MICROSOFT_ACCOUNT: `Compte Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Changer de comptes Microsoft`,
  MICROSOFT_SWITCH: `Passer à la connexion Microsoft`,
  MICROSOFT_SWITCH_DESC: `Passer de l'authentification Google à Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Votre compte SUMO1 perdra l'accès aux données uniquement disponibles pour vous via votre fournisseur d'origine. Vous devrez peut-être également réautoriser l'accès aux intégrations et aux calendriers.`,
  SWITCH_DESCRIPTION_PART1: `Changer de l'authentification `,
  SWITCH_DESCRIPTION_PART2: ` à `,
  TITLE: `Connexion`,
});

export const MyLink = Object.freeze({
  LINK: `Nom du Lien`,
  LINK_HELPER_TEXT: `Minimum 3 symboles. Veuillez utiliser uniquement des caractères a-z, 0-9 et -.&=_'-+,<>`,
  SUBTITLE: `Changer votre URL SUMO1 signifie que tous vos liens copiés ne fonctionneront plus et seront mis à jour`,
  TITLE: `Mon Lien`,
});

export const Phone = Object.freeze({
  LABEL: `Numéro de Téléphone`,
  TITLE: `Numéro(s) de Téléphone`,
});

export const Profile = Object.freeze({
  DATE_FORMAT: `Format de Date`,
  DELETE_ACCOUNT: `Supprimer le Compte`,
  DELETE_ACCOUNT_DIALOG_TEXT: `Êtes-vous sûr de vouloir supprimer votre compte ? Votre demande sera envoyée à l'administrateur.`,
  DELETE_ACCOUNT_REQUEST_TEXT: `La demande de suppression a été envoyée à l'administrateur.`,
  DELETE_ACCOUNT_REQUEST_TITLE: `Envoyé`,
  LANGUAGE: `Langue`,
  NAME: `Nom`,
  PHONE_DETAILS_LABELS: `Détails du téléphone`,
  PHONE_DETAILS_PLACEHOLDER: `Ajoutez des informations ou des instructions supplémentaires ici.\r\nEx : J'ai hâte de vous rencontrer. Envoyez-moi un SMS à tout moment.`,
  TIME_FORMAT: `Format d'Heure`,
  TIME_ZONE: `Fuseau Horaire`,
  TITLE: `Profil`,
  WELCOME_MESSAGE: `Message de Bienvenue`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: `Échec de la demande de connexion d'un nouveau calendrier`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: `Nouveau calendrier connecté avec succès !`,
  CONNECT_ZOOM_ERROR_TOAST: `Échec de la connexion à Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST: `Zoom connecté avec succès !`,
  GET_USER_SETTINGS_FAIL: `Données utilisateur non trouvées, veuillez contacter le support`,
  SAVE_USER_SETTINGS_ERROR_TOAST: `Échec de la demande d'enregistrement des paramètres`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST: `Vos paramètres ont été enregistrés avec succès !`,
  UPDATE_USER_LINK_ERROR_MESSAGE: `Le lien est déjà pris`,
  UPDATE_USER_LINK_SUCCESS_MESSAGE: `Votre lien a été mis à jour avec succès !`,
});
