export const PublicBookingPage = Object.freeze({
  HOST: `Host:`,
  HOST_PHONE: `Host phone`,
  EDIT_BUTTON: `Edit`,
  MORE: `More`,
  TIME_ZONE_LABEL: `Time Zone`,
  NAME_FIELD_LABEL: `Name`,
  EMAIL_FIELD_LABEL: `Email`,
  INVITE_OTHERS_FIELD_LABEL: `Additional Attendees`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Invite others by entering their email addresses separated by commas`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Note for Attendees`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Enter a note for all attendees`,
  NO_USERS_AVAILABLE_LABEL: `There are no available users at the selected time. Please select another time.`,
  CANCEL_TYPE_LABEL: `Why cancel?`,
  CANCEL_REASON_LABEL: `Cancel reason:`,
  IN_PERSON_LABEL: `In-Person`,
  PHONE_LABEL: `Phone`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Error`,
  VIDEO_CONFERENCE_LABEL: `Video Conference`,
  SLOTS_NO_FOUND: `Slots not found`,
  VIEW_NEXT_MONTH: `View next month`,
  ALLOW_SMS: `Receive appointment reminders via text?`,
});

export const ScheduledMeetings = Object.freeze({
  ATTENDEES: `Guests`,
  CANCELED: 'Canceled',
  CANCEL_MEETING_TITLE: `Cancel Meeting`,
  CANCEL_MEETING_DESCRIPTION: `Why cancel?`,
  CANCEL_REASON: `Cancel reason:`,
  DETAILS: `Details`,
  BOOKED: `Booked`,
  BOOKING_PAGE: `Booking page`,
  BOOKING_PAGE_PLACEHOLDER: `Select Booking page`,
  EDIT_ATTENDEE_LIMIT: `Edit Attendee Limit`,
  GOOGLE_MEET: `Google Meet`,
  HOSTS: `Hosts`,
  IN_PERSON: `In Person`,
  INVITEE_ANSWERS: `Invitee answers`,
  LOCATION_TITLE: `Location`,
  NO_SCHEDULED_MEETINGS: `You have no booked meetings`,
  NO_LOCATION_GIVEN: `No location given`,
  NO_SHOW_LABEL: `No Show`,
  MARK_AS_NO_SHOW: `Mark as No-Show`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Are you sure you want to mark`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `as a no-show?`,
  MARK_AS_NO_SHOW_TOOLTIP: `The meeting has not started yet.`,
  MEETING_NOTES: `Internal Meeting Notes`,
  MEETING_NOTES_DESC: `Only the host will see these notes.`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FOR_ATTENDEES: `Note for Attendees`,
  PAST: `Past`,
  PHONE_CALL: `Phone Call`,
  RESCHEDULE: `Reschedule`,
  REASON_UNEXPECTED: `Unexpected conflict`,
  REASON_INVITE: `Need to invite others`,
  REASON_TIME: `Need time to prepare`,
  REASON_OTHER: `Other`,
  STATUS: `Status`,
  STATUS_PLACEHOLDER: `Select Status`,
  TEAM: `Team`,
  TITLE: `Booked Meetings`,
  VIEW_ATTENDEES: `View Attendees`,
  VIEW_ATTENDEES_DESC: `These are current attendees for this meeting`,
  VIEW_ATTENDEES_NOTE: `The host may add or remove attendees from their connected calendar.`,
  UNDO_NO_SHOW: `Undo No-Show`,
  UPCOMING: `Upcoming`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Video Conference`,
});

export const ScheduledMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Get scheduled meeting request fail`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Cancel meeting request fail`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Meeting successfully canceled`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Update meeting request fail`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Meeting successfully deleted`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Delete meeting request fail`,
});

export const HostNotificationTemplates = Object.freeze({
  HOST_CONFIRMATION_TEMPLATE_SUBJECT: `New Meeting: <Meeting Name> with <Invitee Name> at <Meeting Time>, <Meeting Date>`,
  HOST_RESCHEDULE_SUBJECT: `Rescheduled: <Meeting Name> with <Invitee Name>`,
  HOST_CANCELATION_SUBJECT: `Cancelled: <Meeting Name> with <Invitee Name> at <Meeting Time>, <Meeting Date>`,
  HOST_CONFIRMATION_TEMPLATE_BODY: `Hi <Host Name>,

Your new meeting <Meeting Name> is scheduled.

<b>Invitee</b>
<Invitee Name> ( <Invitee Email> )

<b>Time and Date</b>
<Meeting Time>, <Meeting Date>

<b>Location</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_RESCHEDULE_BODY: `Hi <Host Name>,

Your meeting <Meeting Name> is rescheduled.

<b>Invitee</b>
<Invitee Name> ( <Invitee Email> )

<b>Updated Time and Date</b>
<Meeting Time>, <Meeting Date>

<b>Location</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_CANCELATION_BODY: `Hi <Host Name>,

Your meeting <Meeting Name> has been cancelled.

<b>Invitee</b>
<Invitee Name> ( <Invitee Email> )

<b>Time and Date</b>
<Meeting Time>, <Meeting Date>

<b>Location</b>
<Meeting Location>


<b>Cancellation Reason</b>
<Cancel Reason>`,
});
