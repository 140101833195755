export const Common = Object.freeze({
  ACCEPT_INVITE: `Einladung annehmen`,
  ACCESS_RESTRICT: `Entschuldigung, Sie sind nicht berechtigt, auf diese Seite zuzugreifen`,
  ACTIVATE: `Aktivieren`,
  ALL: `Alle`,
  ARE_YOU_SURE: `Sind Sie sicher?`,
  AUTHORISE: `Autorisieren`,
  AVAILABLE: `Verfügbar`,
  BACK: `Zurück`,
  CALENDAR_DAYS: `Kalendertage`,
  CANCEL: `Abbrechen`,
  CHOOSE_DATE: `Datum wählen`,
  CLEAR: `Löschen`,
  CLONE: `Klonen`,
  CLOSE: `Schließen`,
  COMMON: `Gemeinsam`,
  COMPLETE: `Abschließen`,
  CONNECT: `Verbinden`,
  CONNECTED: `Verbunden`,
  CONFIRM_DELETE: `Löschen bestätigen`,
  COPY: `Kopieren`,
  COPY_LINK: `Link kopieren`,
  COPIED_LINK: `Kopiert!`,
  COUNTINUE: `Fortfahren`,
  COUNTRY: `Land`,
  CUSTOM: `Benutzerdefiniert`,
  DATE: `Datum`,
  DATE_RANGE: `Datumsbereich`,
  DAYS: `Tage`,
  DEACTIVATE: `Deaktivieren`,
  DEFAULT: `Standard`,
  DELETE: `Löschen`,
  DESCRIPTION: `Beschreibung`,
  DISABLE: `Deaktivieren`,
  DISCONNECT: `Trennen`,
  DOWNLOAD: `Herunterladen`,
  EDIT: `Bearbeiten`,
  EMAIL: `E-Mail`,
  EMAIL_ALL_SMALL: `E-Mail`,
  EMPTY_ERROR: `Darf nicht leer sein`,
  ENABLE: `Aktivieren`,
  ERROR: `Fehler`,
  EXPORT: `Exportieren`,
  FILTER: `Filtern`,
  FRIDAYS: `Freitage`,
  FROM: `von`,
  GOOGLE: `Google`,
  HOURS: `Stunden`,
  HOUR_SHORTENED: `Std`,
  INFO: `Information`,
  INTERNAL: `Intern`,
  LEARN_MORE: `Mehr erfahren`,
  LOCKED: `Gesperrt`,
  LOGIN: `Anmelden`,
  LOG_OUT: `Abmelden`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `Min`,
  MINUTES: `Minuten`,
  MONDAYS: `Montage`,
  MY_ACCOUNT: `Mein Konto`,
  NAME_IS_DUPLICATE: `Hoppla! Dieser Name wurde bereits verwendet. Bitte geben Sie einen anderen ein.`,
  NEXT: `Weiter`,
  NONE: `Keine`,
  NO: `Nein`,
  NO_RESULTS_FOUND: `Keine Ergebnisse gefunden`,
  NEVER: 'Niemals',
  OFF: `Aus`,
  ON: `Ein`,
  ONLY_ONE_ACCOUNT: `Sie dürfen nur ein SUMO1-Konto pro E-Mail-Adresse haben.`,
  PASSWORD: `Passwort`,
  PHONE_NUMBER: `Telefonnummer`,
  PICTURE_FORMATS: `SVG, JPG, GIF oder PNG. Maximale Größe von 5 MB.`,
  PREVIEW: `Vorschau`,
  QUICK_SETUP: `Schnelle Einrichtung`,
  REGISTER: `Registrieren`,
  RESET: `Zurücksetzen`,
  ROLES: `Rollen`,
  SATURDAYS: `Samstage`,
  SAVE: `Speichern`,
  SAVE_CHANGES: `Änderungen speichern`,
  SEARCH: `Suchen`,
  SECOND_SHORTENED: `Sek`,
  SELECTED: `Ausgewählt`,
  SHARE: `Teilen`,
  SIGN_OUT: `Abmelden`,
  SIGN_UP: `Registrieren`,
  SUCCESS: `Erfolg`,
  SUNDAYS: `Sonntage`,
  TIMEZONE: `Zeitzone`,
  THURSDAYS: `Donnerstage`,
  TIMES: `Zeiten`,
  TODAY: `Heute`,
  TUESDAYS: `Dienstage`,
  UNAVAILABLE: `Nicht verfügbar`,
  UNLINK: `Verbindung aufheben`,
  UNSAVED_CHANGES: `Alle nicht gespeicherten Änderungen gehen verloren.`,
  UPLOAD: `Hochladen`,
  UPLOAD_PICTURE: `Bild hochladen`,
  USER: `Benutzer`,
  USERS: `Benutzer`,
  VIEW: `Anzeigen`,
  YES: `Ja`,
  YES_CANCEL: `Ja, Abbrechen`,
  WARNING: `Warnung`,
  WEDNESDAYS: `Mittwoche`,
  WEEKDAYS: `Wochentage`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Zugriffsverweigerungsfehler`,
  TITLE_404: `404 - Seite nicht gefunden`,
  TITLE_500: `500 - Interner Serverfehler`,
  MESSAGE_403_1: `Sie haben keine Berechtigung zum Zugriff auf `,
  MESSAGE_403_2: ` auf diesem Server.`,
  MESSAGE_404_1: `Die angeforderte URL `,
  MESSAGE_404_2: ` wurde auf diesem Server nicht gefunden.`,
  MESSAGE_500_1: `Es gab einen Fehler. Das ist alles, was wir wissen. Bitte warten Sie und `,
  MESSAGE_500_2: `versuchen Sie erneut zu laden`,
  MESSAGE_500_3: `.`,
  HOME_BUTTON: `Startseite`,
});

export const PrivateRoute = Object.freeze({
  ACCOUNT: `Konto`,
  ACCOUNT_SETTINGS: `Kontoeinstellungen`,
  PROFILE: `Profil`,
  ADMIN_SETTINGS: `Admin-Einstellungen`,
  AVAILABILITY: `Verfügbarkeit`,
  CALENDAR_CONNECTIONS: `Kalenderverbindungen`,
  HELP_CENTER: `Hilfe-Center`,
  HOME: `Startseite`,
  USER_SETUP: `Benutzereinrichtung`,
  USER_SETTINGS: `Benutzereinstellungen`,
  INTEGRATION: `Integration`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Urheberrecht 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc.  Alle Rechte vorbehalten.`,
  PRIVACY_POLICY: `Datenschutzrichtlinie`,
  SECURITY_POLICY: `Sicherheitsrichtlinie`,
  TERMS: `Nutzungsbedingungen`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Konten`,
  ADMIN_SETTINGS_TITLE: `Admin-Einstellungen`,
  ADMIN_TOOLS_TITLE: `Admin-Tools`,
  BILLING_TITLE: `Abrechnung`,
  BOOKING_TOOLS_TITLE: `Buchungstools`,
  DASHBOARD_TITLE: `Dashboard`,
  MORE_TOOLS_TITLE: `Weitere Tools`,
  OPERATIONS_TITLE: `Operationen`,
  ORGS_TITLE: `Organisationen`,
  STAFF_TITLE: `Mitarbeiter`,
  SUMO1_FOR_GMAIL: `SUMO1 für Gmail`,
  SUPPORT_TITLE: `Support`,
  OPEN_MY_SUMO1: `Mein SUMO1 öffnen`,
  OPEN_OPS_CONSOLE: `OPS-Konsole öffnen`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Klicken Sie hier, um einen Anruf oder ein virtuelles Meeting mit einem Experten zu buchen.`,
  BOOK_MEETING_TITLE: `Meeting buchen`,
  CALL_DESCRIPTION: `Rufen Sie uns jederzeit von Montag bis Freitag von 8 bis 17 Uhr (EST) an.`,
  CALL_TITLE: `RUFEN SIE 800-708-1790 AN`,
  CHAT_DESCRIPTION: `Klicken Sie auf den Link "Chat mit uns" unten rechts, um zu sehen, ob wir verfügbar sind.`,
  CHAT_TITLE: `Jetzt chatten`,
  DESCRIPTION: `Sie können sich auch an Vertrieb oder Support wenden, um Unterstützung zu erhalten.`,
  EMAIL_DESCRIPTION: `Senden Sie eine E-Mail an experts@sumoscheduler.com für Antworten.`,
  EMAIL_TITLE: `E-Mail an uns`,
  HEADER_TITLE: `Anmeldung fehlgeschlagen`,
  HELP_CENTER_DESCRIPTION: `Hilfsvideos, Artikel der Wissensdatenbank und mehr.`,
  HELP_CENTER_TITLE: `Hilfezentrum & Wissensdatenbank`,
  LOG_CASE_DESCRIPTION: `Kunden können hier klicken, um einen Fall beim Kundensupport zu melden.`,
  LOG_CASE_TITLE: `Fall melden`,
  MESSAGE: `Ihr "Super-Admin" kann sich anmelden, um Lizenzen zu kaufen und die Nutzung von SUMO1 fortzusetzen.`,
  TITLE: `Lizenz abgelaufen`,
  TITLE_TRIAL: `Kostenloses Testabonnement abgelaufen`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Stornierungen`,
  MEETINGS_BOOKED: `Gebuchte Meetings`,
  REMINDERS_SENT: `Erinnerungen gesendet`,
  RESCHEDULES: `Neuzeitplanungen`,
  TITLE: `Meine Buchungsstatistiken`,
  INSTALL_CHROME_EXTENSION_PART1: `Installieren Sie die SUMO1 Chrome-Erweiterung`,
  INSTALL_CHROME_EXTENSION_PART2: `um E-Mail-Einladungslinks an Personen aus Ihrer Inbox, Ihrem Kalender oder CRM zu senden.`,
  INVITE_PEOPLE_DESCRIPTION: `Fügen Sie Benutzer zu SUMO1 hinzu, um von Co-Hosting, Team-Buchung, Buchung im Auftrag und mehr zu profitieren.`,
  INVITE_PEOPLE_TITLE: `Personen einladen`,
  CALENDAR_WARNING_DESCRIPTION: `Ihr Kalender ist nicht verbunden. Sie riskieren eine doppelte Buchung, wenn Sie keinen Kalender verbinden.`,
  CALENDAR_WARNING_BUTTON: `Kalender verbinden`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Fügen Sie einen "Buchen Sie mit mir"-Link zu Ihrer E-Mail-Signatur hinzu.`,
  STEP1_TITLE: `E-Mail-Signatur einrichten`,
  STEP2_DESCRIPTION: `Senden Sie eine Einladung zu einem Meeting an jemanden mit der Chrome-Erweiterung.`,
  STEP2_TITLE: `Einladung in einem Klick senden`,
  STEP3_DESCRIPTION: `Was sollten sie sonst noch am ersten Tag testen?`,
  STEP3_TITLE: `Eine andere Funktion testen`,
  TITLE: `Einfache Buchung starten`,
});
