export const AuthentificationToastsNotifications = Object.freeze({
  CHOOSE_AN_ACCOUNT_ERROR_TOAST: `Choose an account fail`,
  DELETE_USER_ERROR_MESSAGE: `Delete user request fail`,
  DELETE_USER_SUCCESS_MESSAGE: `User successfully deleted`,
  GET_USER_AUTH_ERROR_MESSAGE: `User login request fail`,
  GET_USER_LOGIN_ERROR_MESSAGE: `No account exists with such email`,
  GET_USER_LOGOUT_ERROR_MESSAGE: `User logout request fail`,
  GET_MAIN_DATA_ERROR_MESSAGE: `Authentication request fail`,
  ROLE_DEACTIVATED_ERROR_MESSAGE: `Role has been deactivated.  Please contact your SUMO1 administrator.`,
  USER_DEACTIVATED_ERROR_MESSAGE: `User has been deactivated.  Please contact your SUMO1 administrator.`,
  TRIAL_LIMIT_ERROR_MESSAGE: `You have exceeded the limit of 30 items for the Trial version`,
  INVITE_EXPIRED_ERROR_TITLE: `Invite Expired`,
  INVITE_EXPIRED_ERROR_MESSAGE_PART1: `Your invite has expired. Please contact your administrator to get a new link or try to `,
  INVITE_EXPIRED_ERROR_MESSAGE_PART2: `sign up without an invite`,
  SWITCH_ACCOUNT_ERROR_MESSAGE: `Another account already associated with this email`,
});

export const Authorise = Object.freeze({
  DOESNT_HAVE_AN_ACCOUNT: `Don't have an account?`,
  GOOGLE_SING_IN: `Login with Google`,
  GOOGLE_SING_UP: `Sign up with Google`,
  HAVE_AN_ACCOUNT: `Already have an account?`,
  MICROSOFT_SING_IN: `Login with Microsoft`,
  MICROSOFT_SING_UP: `Sign up with Microsoft`,
  SUBTITLE: `Start using your free personal appointment link`,
  SUBTITLE_SING_IN: `Login to your scheduling hub.`,
  TITLE_SING_IN: `Welcome back to SUMO`,
  CONNECTING_TO_GOOGLE: `Connecting to Google...`,
  CONNECTING_TO_MICROSOFT: `Connecting to Microsoft...`,
  SYNCING_CALENDAR: `Syncing calendar...`,
});

export const DialogLeaveAccount = Object.freeze({
  ACCEPT_TEXT: 'Would you like to accept this invite and leave the other SUMO1 account?',
  INVITE_TEXT_PART_1: `You've been invited by`,
  INVITE_TEXT_PART_2: `to join SUMO1, but your email is already associated with another SUMO1 account.`,
  LAST_SUPER_ADMIN_NOTE: `You are the only Super Admin in the org. You must first login to your existing org and transfer "Super Admin" to another user, before accepting this invite. Contact SUMO Support for assistance.`,
  TITLE: `Leave Account`,
});

export const DialogUserAccounts = Object.freeze({
  ACCEPT_TEXT: `Would you like to accept this invite and leave the other SUMO1 account?`,
  INVITE_FROM: `Invite from:`,
  INVITE_TEXT: `You've been invited to join SUMO1, but you are trying to register for a new SUMO1 account.`,
  SELECT_TEXT: `Select below if you would like to accept this invite instead of creating a new SUMO1 company account.`,
  STAY_IN_MY_ACCOUNT: `Stay in my account`,
  TITLE: `Accept Invite`,
});

export const Register = Object.freeze({
  COMMENT: `*SUMO1 does not require a Salesforce license.`,
  SUBTITLE: `Online Scheduling & More`,
  TITLE: `15-day Free Trial`,
});
