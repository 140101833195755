import { t } from '../../../i18n/i18n';

export default {
  acceptTerms_step1: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART1'),
  acceptTerms_step2: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART2'),
  acceptTerms_step3: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART3'),
  colorsNotes: t('QuickSetupYourBrandStep:COLORS_NOTES'),
  colorsTitle: t('QuickSetupYourBrandStep:COLORS_TITLE'),
  back: t('Common:BACK'),
  background: t('QuickSetupYourBrandStep:BACKGROUND'),
  description: t('QuickSetupYourBrandStep:DESCRIPTION'),
  logo: t('QuickSetupYourBrandStep:LOGO'),
  logoDesc: t('QuickSetupYourBrandStep:LOGO_DESCRIPTION'),
  logoTitle: t('QuickSetupYourBrandStep:LOGO_TITLE'),
  next: t('Common:NEXT'),
  pictureFormats: t('Common:PICTURE_FORMATS'),
  preview: t('Common:PREVIEW'),
  previewTooltip: t('QuickSetupYourBrandStep:PREVIEW_TOOLTIP'),
  save: t('QuickSetupYourBrandStep:SAVE_BUTTON'),
  title: t('QuickSetupYourBrandStep:TITLE'),
  upload: t('Common:UPLOAD'),
  yourBackground: t('QuickSetupYourBrandStep:YOUR_BACKGROUND'),
  yourLogo: t('QuickSetupYourBrandStep:YOUR_LOGO'),

  fontColorsTitle: t('LookAndFeel:FONT_COLORS_TITLE'),
  headerLabel: t('LookAndFeel:HEADER_LABEL'),
  subHeaderLabel: t('LookAndFeel:SUB_HEADER_LABEL'),
  mainTextLabel: t('LookAndFeel:MAIN_TEXT_LABEL'),
  fontFamiliesTitle: t('LookAndFeel:FONT_FAMILIES_TITLE'),
  headerFontLabel: t('LookAndFeel:HEADER_FONT_LABEL'),
  subHeaderFontLabel: t('LookAndFeel:SUB_HEADER_FONT_LABEL'),
  mainTextFontLabel: t('LookAndFeel:MAIN_TEXT_FONT_LABEL'),
  siteColorTitle: t('LookAndFeel:SITE_COLORS_TITLE'),
  primaryLabel: t('LookAndFeel:PRIMARY_LABEL'),
  buttonsLabel: t('LookAndFeel:BUTTONS_LABEL'),
  backgroundLabel: t('LookAndFeel:BACKGROUND_LABEL'),
};
