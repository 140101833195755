import { useSelector } from 'react-redux';
import { VIDEO_CONFERENCES, integrationActions, integrationLabels, integrationSelectors } from '../../store/integration';
import { ConnectIntegration } from '../../components/integration';
import { useEffect, useRef, useState } from 'react';
import { Path } from '../../routing';
import labels from './labels';
import { useDispatch } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../store/userSettings';
import { IntegrationType, VideoConferenceType } from '../../API';
import { ConfirmationModal, Preloader, SectionTitle } from '../../components/common';
import { GoogleAuthButton, MicrosoftAuthButton } from '../../components/public';
import { CodeResponse } from '@react-oauth/google';
import {
  MicrosoftCodeResponse,
  ThirdPartyType,
  authentificationActions,
  authentificationSelectors,
} from '../../store/authentification';
import { navigationService } from '../../services/NavigationService';
import { ZoomAuthButton } from '../../components/private/zoomAuthButton/ZoomAuthButton';
import { generateVideoConference } from '../../store/integration/utils';

export const ConnectIntegrationPage = () => {
  const dispatch = useDispatch();
  const integrationType = useSelector(integrationSelectors.selectConnectIntegration);
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const isZoomConnected = useSelector(userSettingsSelectors.selectIsZoomConnected);
  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const isAuthentificationFetching = useSelector(authentificationSelectors.selectIsFetching);
  const userSettingsError = useSelector(userSettingsSelectors.selectError);
  const defaultVideoConference = useSelector(userSettingsSelectors.selectDefaultVideoIntegration);
  const integrations = useSelector(userSettingsSelectors.selectIntegrations);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const initialLoad = useRef(true); // Use a ref to track the initial load

  const handleGoogleAuthSuccess = (codeResponse: CodeResponse, integrationType: IntegrationType) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        codeResponse,
        type: ThirdPartyType.GOOGLE,
        integrationType,
        switchAccount: false,
        login: false,
      })
    );
  };

  const handleMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse, integrationType: IntegrationType) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        codeResponse,
        type: ThirdPartyType.MICROSOFT,
        integrationType,
        switchAccount: false,
        login: false,
      })
    );
  };

  const handleGetZoomCodeRedirect = () => {
    dispatch(authentificationActions.getZoomCodeRedirect(window.location.href));
  };

  const connectInfo = {
    [IntegrationType.GOOGLE_CALENDAR]: {
      ConnectButton: GoogleAuthButton,
      handleAuthSuccess: (codeResponse: CodeResponse) => handleGoogleAuthSuccess(codeResponse, IntegrationType.GOOGLE_CALENDAR),
    },
    [IntegrationType.GOOGLE_MEET]: {
      ConnectButton: GoogleAuthButton,
      handleAuthSuccess: (codeResponse: CodeResponse) => handleGoogleAuthSuccess(codeResponse, IntegrationType.GOOGLE_MEET),
    },
    [IntegrationType.MICROSOFT_CALENDAR]: {
      ConnectButton: MicrosoftAuthButton,
      handleAuthRedirect: (codeResponse: MicrosoftCodeResponse) => handleMicrosoftAuthRedirect(codeResponse, IntegrationType.MICROSOFT_CALENDAR),
    },
    [IntegrationType.MICROSOFT_TEAMS]: {
      ConnectButton: MicrosoftAuthButton,
      handleAuthRedirect: (codeResponse: MicrosoftCodeResponse) => handleMicrosoftAuthRedirect(codeResponse, IntegrationType.MICROSOFT_TEAMS),
    },
    [IntegrationType.ZOOM]: {
      ConnectButton: ZoomAuthButton,
      handleAuthRedirect: handleGetZoomCodeRedirect,
    },
  };

  const handleDisconnect = (integrationType: IntegrationType) => {
    if (defaultVideoConference === integrationType as unknown as VideoConferenceType) {
      const newDefaultVideoIntegration = integrations && integrations.find(integration =>
        integration && VIDEO_CONFERENCES.includes(integration.type) && integration.type !== integrationType
      );
      if (newDefaultVideoIntegration) {
        dispatch(userSettingsActions.updateUserSettings({ defaultVideoIntegration: generateVideoConference(newDefaultVideoIntegration.type) }));
      }
    }
    dispatch(userSettingsActions.removeIntegration(integrationType));
    dispatch(userSettingsActions.saveUserSettingsRequest());
    setIsDisconnectModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsDisconnectModalOpen(true);
  };

  const handleBack = () => {
    navigationService.navigateTo(Path.Integration);
  };

  useEffect(() => {
    if (isUserSettingsReceived && !isAuthentificationFetching && !userSettingsError) {
      // TODO: move all that code to Zoom service, leave just dispacth
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const url = new URL(window.location.href);
      url.search = ''; // clear all query params
      const redirectURI = url.href; // current page URL without params

      // if there's an authCode and Zoom is not connected, dispatch actions to connect to Zoom and navigate
      if (code && !isZoomConnected) {
        dispatch(userSettingsActions.connectZoomRequest({
          code,
          redirectURI,
        }));
        dispatch(integrationActions.setConnectIntegrationType(IntegrationType.ZOOM));
        // Check if Zoom is the first video conference in the user's integrations to make it the default
        const hasOtherVideoConferences = integrations && integrations.some(integration =>
          integration && VIDEO_CONFERENCES.includes(integration.type) && integration.type !== IntegrationType.ZOOM
        );
        if (!hasOtherVideoConferences) {
          dispatch(userSettingsActions.updateUserSettings({ defaultVideoIntegration: generateVideoConference(IntegrationType.ZOOM) }));
        }
        navigationService.navigateTo(Path.ConnectIntegration); // clear the URL from code
        initialLoad.current = false; // after navigating, mark that the initial load is complete
      } else if (!code && !integrationType && initialLoad.current) {
        handleBack();
      }
    }
  }, [isUserSettingsReceived, isAuthentificationFetching, userSettingsError, integrationType, isZoomConnected]);

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle
          id="connectIntegrationPageTitle"
          icon="pi-link"
          title={labels.title}
        />
      </div>

      <div className="card">
        {integrationType && (
          <ConnectIntegration
            integrationType={integrationType}
            handleBack={handleBack}
            handleDisconnect={handleOpenModal}
            {...connectInfo[integrationType]}
          />
        )}
      </div>
      {integrationType && (
        <ConfirmationModal
          visible={isDisconnectModalOpen}
          title={labels.disconnect}
          description={`${labels.disconnectDesc}${integrationLabels[integrationType]} ?`}
          confirmButtonLabel={labels.yes}
          cancelButtonLabel={labels.no}
          onConfirm={() => handleDisconnect(integrationType)}
          onCancel={() => setIsDisconnectModalOpen(false)}
          onClose={() => setIsDisconnectModalOpen(false)}
        />
      )}
    </div>
  );
};
