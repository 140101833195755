import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { bookingPagesActions, bookingPageSelectors } from '../../../store/bookingPages';
import { BookingTemplateOption, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Path } from '../../../routing';
import labels from './labels';
import { ColorPickerList } from '../../common/colorPickerList/ColorPickerList';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { userSettingsSelectors } from '../../../store/userSettings';
import { Divider } from 'primereact/divider';
import { AppDispatch } from '../../../store/rootStore';
import { Tooltip } from 'primereact/tooltip';

export const BookingPageWhatStep = () => {
  const dispatch: AppDispatch = useDispatch();
  const customName = useSelector(bookingPageSelectors.selectCustomName);
  const active = useSelector(bookingPageSelectors.selectIsActive);
  const adminOnly = useSelector(bookingPageSelectors.selectIsAdminOnly);
  const color = useSelector(bookingPageSelectors.selectWhatColor);
  const isUserRequiredHost = useSelector(bookingPageSelectors.selectIsUserRequiredHost);
  const bookingTemplateId = useSelector(bookingPageSelectors.selectBookingTemplateId);
  // const isNameDuplicate = useSelector(bookingPageSelectors.selectIsNameDuplicate);
  const isNameValid = useSelector(bookingPageSelectors.selectIsNameValid);
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const bookingTemplateOptions = useSelector(
    bookingTemplatesSelectors.selectBookingTemplatesOptions(isBookingPageLocked ? false : isUserRequiredHost)
  );
  const isAdmin = useSelector(userSettingsSelectors.selectIsAdminRole);

  const [localName, setLocalName] = useState(customName);

  useEffect(() => {
    setLocalName(customName);
  }, [customName]);

  const handleNameChange = (name: string) => {
    setLocalName(name);
  };

  const handleNameBlur = () => {
    dispatch(bookingPagesActions.updateWhatStep({ customName: localName }));
  };

  const handleActiveChange = (value: boolean) => {
    dispatch(bookingPagesActions.updateWhatStep({ isActive: value }));
  };

  const handleAdminOnlyChange = (value: boolean) => {
    dispatch(bookingPagesActions.updateRecord({ adminOnly: value }));
  };

  const handleColorChange = (color: string) => {
    dispatch(bookingPagesActions.updateWhatStep({ color }));
  };

  const handleTemplateChange = (e: DropdownChangeEvent) => {
    const bookingTemplateId: string = e.value;
    dispatch(
      bookingPagesActions.updateWhatStep({
        bookingTemplateId,
        customName: customName || bookingTemplateOptions.find((option) => option.id === bookingTemplateId)?.name || '',
      })
    );
    dispatch(bookingPagesActions.uploadBookingTemplate());
  };

  const selectedItemTemplate = (item: BookingTemplateOption) =>
    item && (
      <div>
        <Link
          className="text-color hover:underline hover:text-primary"
          target="_blank"
          to={Path.EditBookingTemplate.replace(':bookingTemplateId', item.id)}
        >
          {item.name}
        </Link>
      </div>
    );

  return (
    <div>
      <div className="grid p-fluid">
        <div className="col-12 md:col-7 flex flex-column">
          {/* <div className="pb-2 font-semibold">{labels.meetingName}</div> */}
          <div className="flex flex-row align-items-center">
            <div className="flex align-items-center w-12rem mr-2">
              <label htmlFor="customName">
                {labels.customName}
              </label>
              <Tooltip target=".name-info" className="max-w-30rem" />
              <i
                className="name-info pi pi-info-circle ml-1"
                data-pr-position="top"
                data-pr-my="center bottom-10"
                data-pr-tooltip={labels.customNameTooltip}
              ></i>
            </div>
            <InputText
              type="text"
              placeholder={labels.customNamePlaceholder}
              value={localName || ''}
              onChange={(e) => handleNameChange(e.target.value.trimStart())}
              onBlur={handleNameBlur}
              className={`${!isNameValid ? 'p-invalid' : ''}`}
              maxLength={MAX_LENGTH_NAME}
              disabled={isBookingPageLocked}
            />
          </div>
          {/* {isNameDuplicate && <div className="pl-2 text-red-500">{labels.duplicate}</div>} */}
          <div className="flex flex-row align-items-center mt-3">
            <label htmlFor="bookingTemplate" className="w-12rem mr-2">
              {labels.bookingTemplate}
            </label>
            <Dropdown
              value={bookingTemplateId}
              options={bookingTemplateOptions}
              optionLabel="name"
              optionValue="id"
              onChange={handleTemplateChange}
              filter
              filterBy="name"
              valueTemplate={bookingTemplateId ? selectedItemTemplate : undefined}
              showClear
              placeholder={labels.bookingTemplatePlaceholder}
              className="w-full"
              disabled={isBookingPageLocked}
            />
          </div>
        </div>

        <div className="col-12 md:col-5 flex">
          <Divider layout="vertical" className="hidden md:block" />
          <div className="flex-1 flex flex-column md:ml-2">
            <div className="pb-3 font-semibold">{labels.bookingPage}</div>
            <div className="flex justify-content-between gap-2">
              <div className="flex align-items-center">
                <label className="mr-3">{labels.active}</label>
                <InputSwitch
                  checked={!!active}
                  onChange={(e) => handleActiveChange(!!e.value)}
                  disabled={isBookingPageLocked}
                />
              </div>
              <div className="flex align-items-center">
                <label>{labels.adminOnly}</label>
                <Tooltip target=".adminOnly" className="max-w-30rem" />
                <i
                  className="adminOnly pi pi-info-circle ml-1"
                  data-pr-position="top"
                  data-pr-my="center bottom-10"
                  data-pr-tooltip={labels.adminOnlyPlaceholder}
                ></i>
                <InputSwitch
                  className="ml-2"
                  checked={!!adminOnly}
                  onChange={(e) => handleAdminOnlyChange(!!e.value)}
                  disabled={!isAdmin || isBookingPageLocked}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6">
          <ColorPickerList color={color} handleColorChange={handleColorChange} listClassName="mt-2" />
        </div>
      </div>
    </div>
  );
};
