import { useEffect, useMemo, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import { PickList, PickListChangeEvent } from 'primereact/picklist';
import { TimeFormat, TimeZoneType } from '../../../../API';
import {
  bookingPageSelectors,
  bookingPagesActions,
  INTERVAL_OPTIONS,
  TimeZoneList,
} from '../../../../store/bookingPages';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../../store/bookingTemplates';
import { userSettingsSelectors } from '../../../../store/userSettings';
import labels from './labels';

type CalendarStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const CalendarStep = ({ selectors, actions, isReadOnly }: CalendarStepProps) => {
  const dispatch = useDispatch();
  const timeFormat = useSelector(selectors.selectTimeFormat);
  const timeInterval = useSelector(selectors.selectTimeInterval);
  const topOfInterval = useSelector(selectors.selectTopOfInterval);
  const timeZoneType = useSelector(selectors.selectTimeZoneType);
  const defaultTimeZone = useSelector(selectors.selectDefaultTimeZone);
  const availableTimeZones = useSelector(selectors.selectAvailableTimeZonesOptions);
  const assignedTimeZones = useSelector(selectors.selectAssignedTimeZonesOptions);
  const isTimeZonesValid = useSelector(selectors.selectIsTimeZonesValid);
  const userTimeZone = useSelector(userSettingsSelectors.selectTimeZone);
  const buttonsClass = useMemo(
    () => (timeZoneType === TimeZoneType.DETECT_INVITEE ? 'p-disabled' : ''),
    [timeZoneType]
  );
  const containerStyle: CSSProperties = useMemo(
    () =>
      timeZoneType === TimeZoneType.DETECT_INVITEE
        ? {
            pointerEvents: 'none',
            opacity: '0.5',
          }
        : {},
    [timeZoneType]
  );

  useEffect(() => {
    // when defaultTimeZone is null or when we revert to automatic TimeZone detection
    if (timeZoneType === TimeZoneType.DETECT_INVITEE || (!defaultTimeZone && userTimeZone)) {
      dispatch(actions.updateCalendarStep({ defaultTimeZone: userTimeZone }));
    }
    // when we get to the list of selected time zones and the list is not empty, select the first one
    if (timeZoneType === TimeZoneType.DISPLAY_SELECTED && !!assignedTimeZones.length) {
      dispatch(actions.updateCalendarStep({ defaultTimeZone: assignedTimeZones[0].timeZone }));
    }
  }, [userTimeZone, defaultTimeZone, timeZoneType]);

  const handleTimeFormatChange = (value: TimeFormat) => {
    dispatch(actions.updateCalendarStep({ timeFormat: value }));
  };

  const handleTimeIntervalChange = (value: number) => {
    dispatch(actions.updateCalendarStep({ timeInterval: value }));
  };

  const handleTopOfIntervalChange = (value: boolean) => {
    dispatch(actions.updateCalendarStep({ topOfInterval: value }));
  };

  const handleTimeZoneTypeChange = (value: TimeZoneType) => {
    dispatch(actions.updateCalendarStep({ timeZoneType: value }));
  };

  const handleTimeZoneChange = (e: PickListChangeEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedTimeZones = e.target.map((zone: any) => zone.value); // TODO: replace any
    dispatch(actions.selectTimeZones(selectedTimeZones));
  };

  const handleDefaultTimeZoneChange = (value: string) => {
    dispatch(actions.updateCalendarStep({ defaultTimeZone: value }));
  };

  const timeZoneItemTemplate = (item: TimeZoneList) => {
    return (
      <div className="flex justify-content-between">
        <div>
          {item.utcOffsetStr} - {item.city}
        </div>
        <div>{item.time}</div>
      </div>
    );
  };

  return (
    <div className="grid pb-2">
      <div className="col-12 md:col-6">
        <div className="font-bold mb-2">{labels.timeFormat}</div>
        <div className="field-radiobutton mb-2">
          <RadioButton
            inputId="timeFormat12"
            value={TimeFormat.HOUR_12}
            checked={timeFormat === TimeFormat.HOUR_12}
            onChange={(e) => handleTimeFormatChange(e.target.value as TimeFormat)}
            disabled={isReadOnly}
          />
          <label htmlFor="timeFormat12" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
            {labels.hour12}
          </label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            inputId="timeFormat24"
            value={TimeFormat.HOUR_24}
            checked={timeFormat === TimeFormat.HOUR_24}
            onChange={(e) => handleTimeFormatChange(e.target.value as TimeFormat)}
            disabled={isReadOnly}
          />
          <label htmlFor="timeFormat24" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
            {labels.hour24}
          </label>
        </div>
      </div>

      <div className="col-12">
        <div className="font-bold mb-2">{labels.startTimeIntervals}</div>
        <div className="grid">
          <div className="col-12 md:col-6 mb-2">
            <div className="flex align-items-center">
              <label>{labels.startTimeIntervalsDescription}</label>
              <Tooltip target=".time-interval" className="max-w-30rem" />
              <i
                className="time-interval pi pi-info-circle ml-2"
                data-pr-position="top"
                data-pr-my="center bottom-10"
                data-pr-tooltip={labels.startTimeIntervalsTooltip}
              ></i>
            </div>
            <Dropdown
              value={timeInterval}
              onChange={(e) => handleTimeIntervalChange(e.target.value)}
              options={INTERVAL_OPTIONS}
              optionLabel="label"
              disabled={isReadOnly}
            />
          </div>
          <div className="col-12 md:col-6 mb-2">
            <div className="flex align-items-center">
              <label>{labels.topOfTheInterval}</label>
              <Tooltip target=".top-of-interval" className="max-w-30rem" />
              <i
                className="top-of-interval pi pi-info-circle ml-2"
                data-pr-position="top"
                data-pr-my="center bottom-10"
                data-pr-tooltip={labels.topOfTheIntervalTooltip}
              ></i>
            </div>
            <InputSwitch
              checked={!!topOfInterval}
              onChange={(e) => handleTopOfIntervalChange(!!e.value)}
              disabled={isReadOnly}
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="flex align-items-center mb-2">
          <label className="font-bold">{labels.timeZones}</label>
          <Tooltip target=".time-interval" className="max-w-30rem" />
          <i
            className="time-interval pi pi-info-circle ml-2"
            data-pr-position="top"
            data-pr-my="center bottom-10"
            data-pr-tooltip={labels.timeZonesTooltip}
          ></i>
        </div>
        <div className="field-radiobutton mb-2">
          <RadioButton
            inputId="timeZoneDetect"
            value={TimeZoneType.DETECT_INVITEE}
            checked={timeZoneType === TimeZoneType.DETECT_INVITEE}
            onChange={(e) => handleTimeZoneTypeChange(e.target.value)}
            disabled={isReadOnly}
          />
          <label htmlFor="timeZoneDetect" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
            {labels.timeZoneDetect}
          </label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            inputId="timeZoneDisplay"
            value={TimeZoneType.DISPLAY_SELECTED}
            checked={timeZoneType === TimeZoneType.DISPLAY_SELECTED}
            onChange={(e) => handleTimeZoneTypeChange(e.target.value)}
            disabled={isReadOnly}
          />
          <label htmlFor="timeZoneDisplay" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
            {labels.timeZoneDisplay}
          </label>
        </div>
      </div>

      <div className="col-12">
        <div className={`picklist-container`} style={containerStyle}>
          <PickList
            className={isTimeZonesValid ? '' : 'p-invalid'}
            sourceStyle={{ height: '200px', borderRadius: '4px', padding: 0 }}
            targetStyle={{ height: '200px', borderRadius: '4px', padding: 0 }}
            showSourceControls={false}
            showTargetControls={false}
            source={availableTimeZones}
            target={assignedTimeZones}
            onChange={handleTimeZoneChange}
            itemTemplate={timeZoneItemTemplate}
            pt={{
              moveAllToSourceButton: { root: { className: buttonsClass } },
              moveAllToTargetButton: { root: { className: buttonsClass } },
              moveToSourceButton: { root: { className: buttonsClass } },
              moveToTargetButton: { root: { className: buttonsClass } },
            }}
          ></PickList>
        </div>
      </div>

      <div className="col-12 md:col-6 md:pr-5 p-fluid">
        <div>{labels.defaultTimeZone}</div>
        <Dropdown
          value={defaultTimeZone}
          onChange={(e) => handleDefaultTimeZoneChange(e.target.value)}
          options={timeZoneType === TimeZoneType.DETECT_INVITEE ? availableTimeZones : assignedTimeZones}
          itemTemplate={timeZoneItemTemplate}
          valueTemplate={(item) => (
            <div>
              {item?.city} - {item?.time}
            </div>
          )}
          optionLabel="city"
          disabled={isReadOnly}
          emptyMessage={labels.noResults}
        />
      </div>
    </div>
  );
};
