import { NavigationsRecord, NavigationSettings, FindStepIndex } from './typings';

export const checkNavigation = (state: NavigationsRecord, payload: NavigationSettings) => {
  if (state.hasOwnProperty(payload.key)) {
    console.error('Navigation.Container settings must have uniq key property.');
    return state;
  }

  return { ...state, [payload.key]: { ...payload.state } };
};

export const findNextStepIndex: FindStepIndex = (currentStepIndex, steps) =>
  steps.findIndex((step, index) => index >= currentStepIndex + 1 && !step.skip);

export const findPreviousStepIndex: FindStepIndex = (currentStepIndex, steps) => {
  let previousStepIndex = currentStepIndex;
  for (let index = currentStepIndex - 1; index >= 0; index--) {
    if (index <= currentStepIndex && !steps[index].skip) {
      previousStepIndex = index;
      break;
    }
  }
  return previousStepIndex;
};
