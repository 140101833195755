import { GetOrgDetailsResponse, GetOrgsResponse, OPSConsoleLicense, OPSConsoleTenant } from './types';
import { APIPostRequest } from "../../utils/reduxUtils";

export const getOrgs = async () => {
  try {
    const response = (await APIPostRequest('/api/public/opsconsole/getOrgs', {})) as GetOrgsResponse;
    if (response.tenants) {
      return response.tenants;
    } else {
      throw new Error('Orgs not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching orgs:', error);
    throw error;
  }
};

export const getOrgDetailById = async (tenantId: string) => {
  try {
    const response = (await APIPostRequest('/api/public/opsconsole/getOrgDetails', {
      tenantId,
    })) as GetOrgDetailsResponse;
    if (response) {
      return response;
    } else {
      throw new Error('Org Details not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching org details:', error);
    throw error;
  }
};

export const updateLicense = async (tenantId: string, id: string, payload: Partial<OPSConsoleLicense>) => {
  try {
    await APIPostRequest('/api/js/updateLicense', {
      tenantId, id, ...payload,
    });
  } catch (error: unknown) {
    console.error('Error update license:', error);
    throw error;
  }
};

export const updateTenant = async (tenantId: string, payload: Partial<OPSConsoleTenant>) => {
  try {
    await APIPostRequest('/api/js/updateTenant', {
      tenant: { tenantId, ...payload }, fromOPSConsole: true,
    });
  } catch (error: unknown) {
    console.error('Error update tenant:', error);
    throw error;
  }
};

export const removeTenant = async (tenantId: string) => {
  try {
    await APIPostRequest('/api/js/deleteTenant', {
      tenantId,
    });
  } catch (error: unknown) {
    console.error('Error remove tenant:', error);
    throw error;
  }
};

export const convertToPayLater = async (tenantId: string, email: string, term: string, quantity: number) => {
  try {
    await APIPostRequest('/api/js/billing/payLater', {
      tenantId, email, term, quantity,
    });
  } catch (error: unknown) {
    console.error('Error setting pay later license:', error);
    throw error;
  }
};

export const sendUIEmails = async (toAddresses: string[], subjectText: string, bodyText: string) => {
  try {
    await APIPostRequest('/api/public/sendUIEmails', {
      toAddresses,
      subjectText,
      bodyText,
    });
  } catch (error: unknown) {
    console.error('Error sendUIEmails:', error);
    throw error;
  }
};