import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { eventActions, eventSelectors, EventSteps } from '../../../store/publicBookingPage';
import labels from './labels';
import { Link } from 'react-router-dom';
import { CustomFieldType } from '../../../API';

export const PublicBookingPageBookedInfo = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(eventSelectors.selectIsMobile);
  const isRedirect = useSelector(eventSelectors.selectIsRedirect);
  const externalLink = useSelector(eventSelectors.selectExternalLink);
  const name = useSelector(eventSelectors.selectAttendeeName);
  const email = useSelector(eventSelectors.selectAttendeeEmail);
  const inputFields = useSelector(eventSelectors.selectFilteredInputFields);
  const event = useSelector(eventSelectors.selectEvent);
  const inviteOthers = useSelector(eventSelectors.selectInviteOthers);
  const enterNote = useSelector(eventSelectors.selectEnterNote);
  const customLinks = useSelector(eventSelectors.selectCustomLinks);
  const mainTextStyle = useSelector(eventSelectors.selectMainTextStyle);
  const outlinedButtonStyle = useSelector(eventSelectors.selectOutlinedButtonStyle);
  const showInfoEditButton = useSelector(eventSelectors.selectShowInfoEditButton);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isRedirect) {
      window.location.href = externalLink || '';
    }
  }, []);

  const handleEdit = () => {
    dispatch(eventActions.setEventStep(EventSteps.INFO));
  };

  return (
    <>
      <div className="flex justify-content-center mt-3">
        <div
          className="flex align-items-center cursor-pointer hover:text-primary"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <span>{isOpen ? labels.close : labels.more}</span>
          <i className="pi pi-angle-down ml-2"></i>
        </div>
      </div>
      {isOpen && (
        <div className="card mb-0 mt-2 border-1 surface-border shadow-none" style={mainTextStyle}>
          <div className="grid">
            <div className="col">
              <div className="font-bold">{name}</div>
              <div>{email}</div>
            </div>

            <div className="col-fixed">
              {showInfoEditButton &&
                (isMobile ? (
                  <Button style={outlinedButtonStyle} label={labels.edit} outlined onClick={handleEdit} />
                ) : (
                  <Button
                    style={outlinedButtonStyle}
                    className="w-2rem h-2rem p-0"
                    icon="pi pi-pencil"
                    outlined
                    tooltip={labels.edit}
                    tooltipOptions={{ position: 'top' }}
                    onClick={handleEdit}
                  />
                ))}
            </div>

            <div className="col-12">
              {inputFields?.map(
                (customField) =>
                  customField?.fieldType !== CustomFieldType.NAME &&
                  customField?.fieldType !== CustomFieldType.EMAIL && (
                    <div key={customField.id} className="mt-3">
                      <div className="font-bold">{customField.label}</div>
                      <div>{customField.value}</div>
                    </div>
                  )
              )}
            </div>

            {(inviteOthers || enterNote) && (
              <div className="col-12 py-0">
                <Divider />
              </div>
            )}

            {inviteOthers && (
              <div className="col-12 md:col-6">
                <div className="font-bold">{labels.inviteOthers}</div>
                {(event.guestEmails || []).map((email, index) => (
                  <div key={index}>{email}</div>
                ))}
              </div>
            )}

            {enterNote && (
              <div className="col-12 md:col-6">
                <div className="font-bold">{labels.noteForAttendees}</div>
                <div>{event.note}</div>
              </div>
            )}

            {customLinks.map((link, index) => (
              <div key={index} className="col-12 mt-2">
                <Link to={link?.link || ''} target="_blank" className="text-primary hover:underline">
                  {link?.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
