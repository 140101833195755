export const DefaultBookingPage = Object.freeze({
  NAME: `30-minütiges Treffen`,
  BOOK_A_MEETING: `Treffen buchen`,
  MEETING_DETAILS: `Treffendetails`,
  SELECT_DATE: `Datum wählen`,
  SELECT_TIME: `Uhrzeit wählen`,
  YOUR_INFORMATION: `Ihre Informationen`,
  MORE_OPTIONS: `Weitere Optionen`,
  CANCEL: `Abbrechen`,
  BOOK_MEETING_BUTTON: `Treffen buchen`,
  BOOK_ANOTHER_MEETING_BUTTON: `Ein weiteres Treffen buchen`,
  CANCEL_MEETING_BUTTON: `Treffen absagen`,
  SAVE_MEETING_BUTTON: `Treffen verlegen`,
  MEETING_BOOKED_TITLE: `Treffen gebucht!`,
  MEETING_BOOKED_DESCRIPTION: `Eine Bestätigungs-E-Mail wurde an Ihren Posteingang gesendet.`,
  MEETING_RESCHEDULED_TITLE: `Treffen verlegt!`,
  MEETING_RESCHEDULED_DESCRIPTION: `Eine Bestätigungs-E-Mail wurde an Ihren Posteingang gesendet.`,
  MEETING_CANCELED_TITLE: `Treffen abgesagt!`,
  MEETING_CANCELED_DESCRIPTION: `Eine Bestätigungs-E-Mail wurde an Ihren Posteingang gesendet.`,
});

export const BookingPagesEditBookingPageBufferTimeSettings = Object.freeze({
  AFTER_CHECKBOX_LABEL: `Nach dem Ereignis`,
  BEFORE_CHECKBOX_LABEL: `Vor dem Ereignis`,
  DESCRIPTION: `Vor- und Nachpuffertimes einstellen`,
  EVENT_TIME: `Ereigniszeit`,
  TITLE: `Kalenderzeit vor oder nach dieser Buchungsvorlage automatisch blockieren?`,
});

export const BookingPagesEditBookingPageDateRangeSettings = Object.freeze({
  INVITEES_CAN_SCHEDULE: `Teilnehmer können planen ...`,
  INVITEES_CAN_NOT_SCHEDULE: `Teilnehmer können nicht weniger als planen...`,
  RADIO_GROUP_DAYS_IN_FUTURE_OPTION: `Tage in die Zukunft`,
  RADIO_GROUP_SPECIFIC_DATES_OPTION: `Innerhalb eines Datumsbereichs`,
  RADIO_GROUP_UNLIMITED_OPTION: `Unbegrenzt in die Zukunft`,
});

export const BookingPagesEditBookingPageDurationSettings = Object.freeze({
  DURATION: `Dauer`,
  DURATION_TOOLTIP: `Legen Sie fest, wie lange Ihr Meeting dauern soll. Maximal 12 Stunden.`,
});

export const BookingPagesEditBookingPageWhatStep = Object.freeze({
  EVENT_COLOR_INPUT_LABEL: `Farbe`,
  EVENT_DESCRIPTION_INPUT_LABEL: `Beschreibung/Anweisungen`,
  EVENT_DESCRIPTION_INPUT_PLACEHOLDER: `Verfassen Sie eine Zusammenfassung und alle Details, die Ihr Teilnehmer über das Ereignis wissen sollte.`,
  EVENT_NAME_INPUT_LABEL: `Name`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Fehler beim Anfordern des Klonens der Buchungsseite`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Erfolgreiche Anforderung zum Klonen der Buchungsseite`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Fehler beim Anfordern des Löschen der Buchungsseiten`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Erfolgreiche Anforderung zum Löschen der Buchungsseiten`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Konnte die Buchungsseite nicht abrufen, sie könnte gelöscht worden sein`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Fehler beim Anfordern der Buchungsseiten`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Fehler beim Anfordern des Speicherns der Buchungsseite`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Erfolgreiche Anforderung zum Speichern der Buchungsseite`,
});

export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Neue Buchungsseite`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Möchten Sie diese Buchungsseite wirklich löschen?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Möchten Sie diese Buchungsseiten wirklich löschen?`,
  TITLE: `Buchungsseiten`,
  ADD_TO_SITE: `Zur Website hinzufügen`,
  CLONE_MODAL_TITLE: `Buchungsseite klonen`,
  DEFAULT_FILTER: `Meine Buchungsseiten`,
  SHARED_LABEL: `Geteilt`,
  CLONE_NOTIFICATION: `Bitte beachten Sie, dass die Eigenschaft "Nur für Admins" standardmäßig für einen geklonten Datensatz deaktiviert ist.`,
  TOOLTIP: `Erstellen Sie Buchungslinks, um sich mit Ihnen und/oder anderen Nutzern zu treffen`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE_PART_1: `Was`,
  WHAT_TITLE_PART_2: `ist der Zweck dieser Buchungsseite?`,
  WHO_TITLE_PART_1: `Wer`,
  WHO_TITLE_PART_2: `kann hosten?`,
  HOW_TITLE_PART_1: `Wie`,
  HOW_TITLE_PART_2: `soll sie aussehen?`,
  HOW_DESCRIPTION: `Branding, Etiketten`,
  ADDITIONAL_OPTIONS: `Zusätzliche Optionen`,
  CALENDAR_TITLE_PART_1: `Kalender`,
  CALENDAR_TITLE_PART_2: `Einstellungen`,
  PAGE_TITLE_PART_1: `Seiten`,
  PAGE_TITLE_PART_2: `Einstellungen`,
  BOOKING_TEMPLATE_TITLE: `Mehr Buchungsseiteneinstellungen?`,
  BOOKING_TEMPLATE_PART_1: `Jeder `,
  BOOKING_TEMPLATE_PART_2: `"Buchungsvorlage"`,
  BOOKING_TEMPLATE_PART_3: ` enthält Einstellungen, die sich auf die zugehörigen Buchungsseiten auswirken. Beispielsweise kann jede "Bestätigungsseite" für den Buchungsvorlage einzigartig sein, daher sind diese Einstellungen in der "Buchungsvorlage" zu finden.`,
  VIEW_BOOKING_PAGE: 'Buchungsseite ansehen',
  COPY_LINK: 'Link kopieren',
  ADD_TO_WEBSITE: 'Zur Webseite hinzufügen',
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Aufzeichnung gesperrt. Diese Einstellungen stammen aus der Buchungsvorlage mit dem Titel: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Klicken Sie hier, um diese Einstellungen zu überschreiben`,
  MAIN_HOST: `Haupt-Host`,
  CO_HOST: `Co-Host`,
  HOST_NOTIFICATION: `Der Haupt-Gastgeber hat noch keine Standortoptionen verbunden. Diese Buchungsseite wird erst funktionieren, wenn er dies tut.`,
  LOCKED_ADMIN_ONLY: `Nur ein Administrator kann diese Einstellungen ändern. Zuletzt geändert von:`,
  LOCKED_NO_PERMISSIONS: `Sie haben keine Berechtigung, diese Buchungsseite zu bearbeiten. Zuletzt geändert von:`,
  BACK_TO_LIST: 'Zurück zur Liste',
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Name der Buchungsseite`,
  NAME_PLACEHOLDER: `Interner Name für diese Buchungsseite`,
  NAME_TOOLTIP: `Dieser Text wird den Teilnehmern des Meetings in ihrem Kalender und in E-Mails angezeigt. Zum Beispiel "30min Meeting" oder "Zahnuntersuchung".`,
  ACTIVE: `Aktiv`,
  INACTIVE: `Inaktiv`,
  BOOKING_TEMPLATE: `Buchungsvorlage`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Suchen und Buchungsvorlage hinzufügen`,
  MEETING_NAME_LABEL: `Meeting-Name`,
  CUSTOM_NAME_LABEL: `Anzeigename`,
  CUSTOM_NAME_PLACEHOLDER: `Benutzerdefinierter Name für diese Buchungsseite`,
  BOOKING_PAGE: `Buchungsseite`,
  ADMIN_ONLY: `Nur für Admin`,
  ADMIN_ONLY_PLACEHOLDER: `Aktivieren, damit nur ein Administrator diese Einstellungen ändern kann.`,
});

export const BookingPageHowStep = Object.freeze({
  PREVIEW_DESCRIPTION: `Sie können hier das aktuelle Erscheinungsbild dieser Buchungsseite anzeigen und Beschriftungen überschreiben.`,
  PREVIEW_BUTTON: `Beschriftungen anzeigen und überschreiben`,
  PREVIEW_TITLE: `Beschriftungen überschreiben`,
  BRANDING_DESCRIPTION: `Sie können hier die Markeneinstellungen überschreiben.`,
  BRANDING_BUTTON: `Markeneinstellungen überschreiben`,
  BRANDING_TITLE: `Markeneinstellungen überschreiben`,
});

export const BookingPageBranding = Object.freeze({
  DESCRIPTION: `Standardmäßig verwendet diese Buchungsseite die für diesen Arbeitsbereich festgelegten Markeneinstellungen. Sie können jedoch die Einstellungen für diese spezielle Buchungsseite unten überschreiben.`,
  UPLOAD_LOGO: `Ihr Logo hochladen`,
  UPLOAD_LOGO_BUTTON: `Logo hochladen`,
  UPLOAD_BACKGROUND: `Ihren Hintergrund hochladen`,
  UPLOAD_BACKGROUND_BUTTON: `Hintergrund hochladen`,
  OPACITY: `Deckkraft`,
  CSS: `CSS`,
  CSS_PLACEHOLDER: `Hier kann benutzerdefiniertes CSS für Überschreibungen hinzugefügt werden`,
  FOOTER_TEXT: `Fußzeile`,
  SAVE: `Speichern`,
});

export const BookingPageCalendarStep = Object.freeze({
  TIME_FORMAT: `Zeitformat`,
  HOUR_12: `12 Stunden`,
  HOUR_24: `24 Stunden`,
  CALENDAR_FORMAT: `Kalenderformat`,
  MONTH_VIEW: `Monatsansicht`,
  WEEK_VIEW: `Wochenansicht`,
  START_TIME_INTERVALS: `Startzeitintervalle`,
  START_TIME_INTERVALS_DESCRIPTION: `Zeigen Sie verfügbare Zeitslots in Intervallen von...`,
  START_TIME_INTERVALS_TOOLTIP: `Wählen Sie die Dauer der Intervalle aus, die Sie anzeigen möchten, wenn Sie einen Termin planen. Standardmäßig haben wir 15 Minuten ausgewählt, um die Verfügbarkeit von Slots zu maximieren. Diese Option zeigt verfügbare Slots nach diesem Intervall an.`,
  INTERVALS_15_MIN: `15 Min`,
  INTERVALS_20_MIN: `20 Min`,
  INTERVALS_30_MIN: `30 Min`,
  INTERVALS_60_MIN: `60 Min`,
  TOP_OF_THE_INTERVAL: `Oben im Intervall`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Aktivieren Sie "Oben im Intervall", wenn Sie möchten, dass alle Termine unabhängig von der Dauer der Buchungsvorlage immer am Anfang des Intervalls beginnen. Die Slot-Dauer wäre beispielsweise um 8:00 Uhr, 9:00 Uhr, 10:00 Uhr usw. verfügbar. HINWEIS: Durch die Aktivierung dieser Funktion kann der Benutzer die Verfügbarkeit nicht maximieren.`,
  TIME_ZONES: `Zeitzonen`,
  TIME_ZONES_TOOLTIP: `Die folgenden Zeitzonen stehen auf der kundenorientierten Buchungsseite zur Verfügung. Wenn Sie nur eine Zeitzone auswählen, wird sie festgelegt, sodass das Feld für die Zeitzone ausgegraut ist und der Benutzer es nicht ändern kann.`,
  TIME_ZONE_DETECT: `Zeitzonenerkennung und Anzeige von Zeitslots in der Zeitzone des Eingeladenen automatisch.`,
  TIME_ZONE_DISPLAY: `Nur die folgenden Zeitzonen anzeigen (ideal für persönliche Veranstaltungen)`,
  DEFAULT_TIME_ZONE: `Standardzeitzone`,
});

export const BookingPageSettingsStep = Object.freeze({
  INVITE_OTHERS_LABEL: `Einladen von anderen Teilnehmern erlauben`,
  INVITE_OTHERS_TOOLTIP: `Wenn Sie diese Funktion aktivieren, wird auf der Buchungsseite ein Feld "Andere einladen" angezeigt, auf dem der Eingeladene andere Personen zur Teilnehmerliste hinzufügen kann, indem er E-Mail-Adressen durch Kommas getrennt eingibt.`,
  INVITE_OTHERS_SHORT: `Andere einladen`,
  ENTER_NOTE_LABEL: `Teilnehmern erlauben, eine Notiz einzugeben`,
  ENTER_NOTE_TOOLTIP: `Wenn Sie diese Funktion aktivieren, wird ein Feld "Notiz für Teilnehmer" angezeigt, auf dem der Eingeladene eine Notiz eingeben kann, die auf dem gebuchten Kalendertermin für alle Teilnehmer angezeigt wird.`,
  ENTER_NOTE_SHORT: `Notiz eingeben`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Unten links`,
  BOTTOM_RIGHT: `Unten rechts`,
  BUTTON_POPUP_TITLE: `Schaltfläche zum Pop-up`,
  COPY_CODE_EMBED_DESCRIPTION: `Betten Sie Ihr SUMO-Widget in Ihre Website ein, indem Sie den HTML-Code an der gewünschten Stelle einfügen.`,
  COPY_CODE_LINK_DESCRIPTION: `Zeigen Sie eine schwebende Buchungsschaltfläche auf den gewünschten Seiten an, indem Sie diesen Code in Ihr HTML einfügen.`,
  COPY_CODE_POPUP_DESCRIPTION: `Zeigen Sie Ihren Buchungslink überall an, indem Sie diesen HTML-Code in Ihre Seiten, E-Mail-Signaturen und mehr einfügen.`,
  COPY_CODE_TITLE: `Code kopieren`,
  CUSTOMIZE_DESCRIPTION: `Passen Sie das Aussehen an, damit es nahtlos in Ihre Website passt.`,
  CUSTOMIZE_TITLE: `Anpassen`,
  EMBED_DESCRIPTION: `Zeigen Sie Ihre SUMO1-Buchungsseite in einem iFrame auf Ihrer Website an.`,
  EMBED_TITLE: `Einbetten`,
  FLOATING_BUTTON_DESCRIPTION: `Schaltfläche auf der Seite schwebend machen`,
  HIDE_PAGE_DETAILS: `Seitendetails ausblenden`,
  HIDE_COOKIE_BANNER: `Cookie-Banner ausblenden`,
  LABEL: `Label`,
  LINK_DESCRIPTION: `Fügen Sie Ihrer Website einen Textlink hinzu, der Ihre SUMO1-Buchungsseite in einem Popup startet.`,
  LINK_TITLE: `Textlink`,
  LOCATION: `Ort`,
  POPUP_DESCRIPTION: `Zeigen Sie Ihre SUMO1-Buchungsseite in einem Popup an, wenn auf eine Schaltfläche geklickt wird.`,
  POPUP_TITLE: `Pop-up`,
  TITLE: `Wie fügen Sie SUMO zu Ihrer Website hinzu?`,
  TOP_LEFT: `Oben links`,
  TOP_RIGHT: `Oben rechts`,
});

export const EditBookingPageWhereStep = Object.freeze({
  HOSTS_PHONE_NOTIFICATION: `Ausgewählter Host hat keine Telefonnummer`,
  VIDEO_CONFERENCE_NOTIFICATION: `Ausgewählter Host hat keine Videokonferenz`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Bestätigen Sie die Überschreibung`,
  DESCRIPTION: `Wenn Sie die Einstellungen einer Buchungsvorlage überschreiben, verwenden Sie technisch gesehen nicht mehr diese Buchungsvorlage,
  sondern erstellen stattdessen eine benutzerdefinierte Buchungsseite, für die ein Name erforderlich ist.`,
});
