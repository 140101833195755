export const DefaultBookingTemplate = Object.freeze({
  NAME: `30 Minute Meeting`,
  CONFIRMATION_SUBJECT: 'New Meeting: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Rescheduled: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Reminder: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Cancelled: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Follow-Up: <Meeting Name> with <Host Name>',
  CONFIRMATION_BODY: `Hi <Invitee Name>,

Your new meeting <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date> is scheduled.

<b>Location</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Hi <Invitee Name>,

Your meeting <Meeting Name> with <Host Name> is rescheduled.

<b>Updated Time and Date</b>
<Meeting Time>, <Meeting Date>

<b>Location</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Hi <Invitee Name>,

This is a friendly reminder that your <Meeting Name> with <Host Name> is at <Meeting Time>, <Meeting Date>.

<b>Location</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Hi <Invitee Name>,

Your meeting <Meeting Name> with <Host Name> is at <Meeting Time>, <Meeting Date> has been cancelled.`,
  FOLLOW_UP_BODY: `Hi <Invitee Name>,

We hope your meeting "<Meeting Name>" with <Host Name> was productive.

If you have any feedback or need further assistance, please feel free to reach out to us.`,
  SMS_TEXT:
    'This is a friendly reminder that your <Meeting Name> with <Host Name> is at <Meeting Time>, <Meeting Date>. View or edit meeting <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `New Template`,
  TITLE: `Booking Templates`,
  VIEW_DETAILS: `View Details`,
  MINUTE: `m`,
  HOUR: `h`,
  CLONE_MODAL_TITLE: `Clone Booking Template`,
  DELETE_MODAL_CONFIRM_TEXT: `Are you sure you want to delete this Booking Template?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `Are you sure you want to delete these Booking Templates?`,
  DELETE_MODAL_USED_PAGES_TEXT: `First you have to remove these Booking Templates from the following Booking Pages:`,
  FILTER_MY_TEMPLATES: `My Templates`,
  FILTER_ALL_TEMPLATES: `All Templates`,
  TOOLTIP: `Create Booking Templates for Booking Pages`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Cloning booking template failed`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Booking template successfully cloned`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Removing booking template failed`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Booking template successfully deleted`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `Failed to get booking template`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Saving booking template failed`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Booking template successfully saved`,
});

export const EditBookingTemplate = Object.freeze({
  ADDITIONAL_TITLE: `Additional Options`,
  WHAT_TITLE_PART_1: `What`,
  WHAT_TITLE_PART_2: ` event is this?`,
  WHO_TITLE_PART_1: `Who`,
  WHO_TITLE_PART_2: ` can attend?`,
  WHERE_TITLE_PART_1: `Where`,
  WHERE_TITLE_PART_2: ` will this event take place?`,
  WHEN_TITLE_PART_1: `When`,
  WHEN_TITLE_PART_2: ` can people book this event?`,
  INVITEE_TITLE_PART_1: `Invitee `,
  INVITEE_TITLE_PART_2: `Questions`,
  INVITEE_SUBTITLE: `Name, Email, Custom Questions`,
  NOTIFICATIONS_TITLE_PART_1: `Notifications`,
  NOTIFICATIONS_TITLE_PART_2: ``,
  CONFIRMATION_TITLE_PART_1: `Confirmation`,
  CONFIRMATION_TITLE_PART_2: ` Page`, // & Cancelation Policy
  LOCKED_TOOLTIP: `Record Locked. You don't have permissions to edit. Last modified by:`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  TEAMS: `Potential Teams`,
  HOSTS: `Hosts`,
  POTENTIAL_HOSTS: `Potential Hosts`,
  HOSTS_TOOLTIP: `Select one or multiple hosts for this booking template. If you select multiple hosts they will be added to the booked meeting.`,
  RESCHEDULE_WITH: `Reschedule with`,
  RESCHEDULE_WITH_TOOLTIP: `If the invitee reschedules the meeting, who should SUMO reschedule them with:`,
  ASSIGNED_HOST: `Assigned Host`,
  ROUND_ROBIN_TO_NEW_HOST: `Round-Robin to new Host`,
  HOST_VIDEO_CONFERENCE: `Host Video Conference`,
  HOST_LIST_REQUIREMENTS: `You should be in the host list, you don't have permission to All Booking Pages.`,
  HOSTS_LABEL: `Choose one person, multiple people, or a team (where one member will be selected via round-robin) to attend this meeting.`,
  HOSTS_PLACEHOLDER: `Search hosts or teams`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Select one or more options to be presented to the invitee when self-scheduling on the booking page:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Skip the Where Step for Virtual Meetings`,
  SKIP_WHERE_STEP_DESCRIPTION: `Video conference link will be auto-added to the meeting.`,
  PHONE_CALL: `Phone Call`,
  HOSTS_PHONE: `Host's Phone Number`,
  HOSTS_PHONE_DESCRIPTION: `Host's phone # will be auto-added to the meeting.`,
  HOSTS_PHONE_NOTIFICATION: `One or more hosts do not have Phone Number`,
  INVITEE_PHONE: `Prompt Invitee to Enter their Phone Number`,
  INVITEE_PHONE_DESCRIPTION: `Invitee will be prompted to add their phone number.`,
  CUSTOM_PHONE: `Custom Phone Number`,
  CUSTOM_PHONE_PLACEHOLDER: `Enter phone number`,
  CUSTOM_PHONE_DESCRIPTION: `This phone # will be auto-added to the meeting.`,
  IN_PERSON: `In-Person`,
  CUSTOM_ADDRESS: `Custom Address`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Enter meeting address`,
  CUSTOM_ADDRESS_DESCRIPTION: `This address will be auto-added to the meeting.`,
  LOCATION: `Company Location`,
  LOCATION_DESCRIPTION: `Invitee will be prompted to choose one of this locations.`,
  INVITEE_LOCATION: `Invitee's Location`,
  INVITEE_LOCATION_DESCRIPTION: `Invitee will be prompted to add a meeting address.`,
  DEFAULT_LOCATION: `Default Location`,
  VIDEO_CONFERENCE: `Video Conference`,
  VIDEO_CONFERENCE_NOTIFICATION: `One or more hosts do not have any Video Conference`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Full Name`,
  EMAIL_LABEL: `Email`,
  ADD_QUESTION_BUTTON: `Add New Question`,
  QUESTION_TITLE_NEW: `New Question`,
  QUESTION_TITLE_EDIT: `Edit Question`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  DESCRIPTION: `Add a question to be added to the Booking Page for the invitee to complete while booking the meeting.`,
  QUESTION_LABEL: `Question`,
  REQUIRED_LABEL: `Required`,
  ACTIVE_LABEL: `Active`,
  TYPE_LABEL: `Answer Type`,
  TYPE_CHECKBOXES: `Checkboxes`,
  TYPE_RADIO_BUTTONS: `Radio buttons`,
  TYPE_SELECT: `Dropdown`,
  TYPE_TEL: `Phone number`,
  TYPE_TEXT: `Single line`,
  TYPE_TEXTAREA: `Multiple lines`,
  ANSWERS_LABEL: `Answers`,
  ANSWERS_SINGLE_DESCRIPTION_1: `Invitee can select `,
  ANSWERS_SINGLE_DESCRIPTION_2: `one`,
  ANSWERS_SINGLE_DESCRIPTION_3: ` of the following`,
  ANSWERS_MULTIPLE_DESCRIPTION_1: `Invitee can select one or `,
  ANSWERS_MULTIPLE_DESCRIPTION_2: `many`,
  ANSWERS_MULTIPLE_DESCRIPTION_3: ` of the following`,
  ANSWER_PLACEHOLDER: `Answer`,
  ADD_ANSWER_BUTTON: `Add answer`,
  DELETE_BUTTON: `Delete`,
  CANCEL_BUTTON: `Cancel`,
  SAVE_BUTTON: `Save`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `Email Confirmations`,
  CONFIRMATION_DESCRIPTION: `An appointment will always be created on SUMO1 host's calendar and their invitee will be added as an attendee.
By enabling this feature, SUMO1 will also send an email confirmation.`,
  RESCHEDULE_TITLE: `Email Reschedule`,
  RESCHEDULE_DESCRIPTION: `The invitee will receive an email notification of any changes to their meeting.`,
  REMINDER_TITLE: `Email Reminders`,
  REMINDER_DESCRIPTION: `The invitee will receive a reminder email before the booked meeting.`,
  CANCELATION_TITLE: `Email Cancelations`,
  CANCELATION_DESCRIPTION: `An email notification will be sent to your invitee if you cancel the meeting.`,
  FOLLOW_UP_TITLE: `Email Follow-Up`,
  FOLLOW_UP_DESCRIPTION: `Automate follow-up of next steps with an email sent after the meeting takes places.`,
  SMS_TITLE: `SMS Text Reminder`,
  SMS_DESCRIPTION: `The invitee will have the option of receiving text reminders before a booked meeting.`,
  SMS_BODY: `SMS Body`,
  SMS_LABEL_PART1: `U.S. & Canada Only`,
  SMS_LABEL_PART2: `Currently text reminders are free and only available for U.S. and Canada invitee phone numbers.`,
  PERSONALIZE: `Personalize`,
  EMAIL_SUBJECT: `Email Subject`,
  EMAIL_BODY: `Email Body`,
  MERGE_VARIABLES: `Merge Variables`,
  TIMING: `Timing`,
  RESET: `Reset`,
  ADD_ANOTHER_REMINDER: `Add Another Reminder`,
  CANCELATION_POLICY: `Cancelation Policy`,
  CANCELATION_POLICY_TOOLTIP: `Any text you enter here will appear in all email notifications sent to the invitee.`,
  TIME_UNIT_DAYS_BEFORE: `day(s) before`,
  TIME_UNIT_HOURS_BEFORE: `hour(s) before`,
  TIME_UNIT_MINUTES_BEFORE: `minute(s) before`,
  TIME_UNIT_DAYS_AFTER: `day(s) after`,
  TIME_UNIT_HOURS_AFTER: `hour(s) after`,
  TIME_UNIT_MINUTES_AFTER: `minute(s) after`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `After Booking`,
  TYPE_DISPLAY_SUMO: `Display SUMO Confirmation Page`,
  TYPE_DISPLAY_EXTERNAL: `Redirect to an External Page`,
  EXTERNAL_LINK_LABEL: `External Redirect URL`,
  EXTERNAL_LINK_PLACEHOLDER: `https://redirect-here.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Display "Book Another Meeting" button`,
  ALLOW_RESCHEDULE_LABEL: `Allow Reschedule`,
  ALLOW_CANCEL_LABEL: `Allow Cancel`,
  ADD_CUSTOM_LINK_LABEL: `Add Custom Link`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Add a custom link to the Confirmation Page, like a link to a survey.`,
  ADD_CUSTOM_LINK_TITLE: `Add Custom Link`,
  EDIT_CUSTOM_LINK_TITLE: `Edit Custom Link`,
  CUSTOM_LINK_LABEL: `Custom Link Label`,
  CUSTOM_LINK_PLACEHOLDER: `Example Link`,
  LINK_URL_LABEL: `Link URL`,
  LINK_URL_PLACEHOLDER: `https://example-link.com`,
});
