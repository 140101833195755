import { IntegrationType } from "../../API";

export type BookingTemplateOption = {
  name: string;
  id: string;
};

export enum NotificationTypes {
  CONFIRMATION = 'CONFIRMATION',
  RESCHEDULE = 'RESCHEDULE',
  REMINDER = 'REMINDER',
  CANCELATION = 'CANCELATION',
  FOLLOW_UP = 'FOLLOW_UP',
  SMS = 'SMS',
}

export enum FilterType {
  MY = 'MY',
  ALL = 'ALL',
}

export type BookingTemplateWhoOption = {
  value: string,
  label: string,
  phoneNumber: boolean,
  integrationTypes: IntegrationType[],
  teamActive?: boolean,
}