import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from "../modals";

export const CHOOSE_THE_ACCOUNT: ModalName = 'chooseTheAccount';

export const chooseTheAccountModalActions = modalsActionsFactory(CHOOSE_THE_ACCOUNT);
export const chooseTheAccountModalSelectors = modalsSelectorsFactory(CHOOSE_THE_ACCOUNT);


export const LEAVE_THE_ACCOUNT: ModalName = 'leaveTheAccount';

export const leaveTheAccountModalActions = modalsActionsFactory(LEAVE_THE_ACCOUNT);
export const leaveTheAccountModalSelectors = modalsSelectorsFactory(LEAVE_THE_ACCOUNT);
