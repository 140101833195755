import { t } from '../../../i18n/i18n';

export default {
  formats: t('Common:PICTURE_FORMATS'),
  description: t('BookingPageBranding:DESCRIPTION'),
  uploadLogo: t('BookingPageBranding:UPLOAD_LOGO'),
  uploadLogoButton: t('BookingPageBranding:UPLOAD_LOGO_BUTTON'),
  uploadBackground: t('BookingPageBranding:UPLOAD_BACKGROUND'),
  uploadBackgroundButton: t('BookingPageBranding:UPLOAD_BACKGROUND_BUTTON'),
  opacity: t('BookingPageBranding:OPACITY'),
  css: t('BookingPageBranding:CSS'),
  cssPlaceholder: t('BookingPageBranding:CSS_PLACEHOLDER'),
  footerText: t('BookingPageBranding:FOOTER_TEXT'),
  save: t('BookingPageBranding:SAVE'),
  reset: t('Common:RESET'),
  clear: t('Common:CLEAR')
};
