import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { authentificationSelectors } from '../authentification';
import { rolesSelectors } from '../roles';
import { UserOption, UserStatus } from './typings';
import { userSettingsSelectors } from '../userSettings';
import { workspacesSelectors } from '../workspaces';
import { validatePhoneNumber } from '../../services/utils';
import { adminDataStatus } from '../../shared/backend/constants';
import { FILTER_OPTION_TEAMS } from '../bookingPages/constants';
import { t } from '../../i18n/i18n';

const adminState = (state: State) => state.users;

const selectIsFetching = createSelector(adminState, (admin) => admin.isFetching);
const selectError = createSelector(adminState, (admin) => admin.error);
const selectUsers = createSelector(adminState, (admin) => admin.users);
const selectSelectedUser = createSelector(adminState, (admin) => admin.selectedUser);
const selectHideInactive = createSelector(adminState, (admin) => admin.hideInactive);
const selectInviteUsersOptions = createSelector(adminState, (admin) => admin.inviteUsersOptions);

const selectSearchedUsers = (searchString: string) =>
  createSelector(
    selectUsers,
    selectHideInactive,
    userSettingsSelectors.selectIsSuperAdmin,
    authentificationSelectors.selectWorkspace,
    (users, hideInactive, isSuperAdmin, workspaceId) =>
      users.filter(
        (user) =>
          (isSuperAdmin || user.workspaceIds?.includes(workspaceId)) &&
          (!hideInactive || user.status !== UserStatus.Inactive) &&
          (user.fullName?.toLowerCase().includes(searchString) || user.email.toLowerCase().includes(searchString))
      )
  );

const selectUsersEmails = createSelector(selectUsers, (users) => users.map((user) => user.email.toLowerCase()));

const selectActiveSuperAdmins = createSelector(
  selectUsers,
  rolesSelectors.selectSuperAdminId,
  (users, superAdminRole) =>
    users.filter(
      (user) =>
        superAdminRole === user.roleId &&
        user.status !== adminDataStatus.inactive &&
        user.status !== adminDataStatus.inviteSent
    )
);
const selectAdminUsers = createSelector(selectUsers, rolesSelectors.selectAdminsRolesId, (users, rolesId) =>
  users.filter((user) => rolesId.includes(user.roleId))
);
const selectStandardUsers = createSelector(selectUsers, rolesSelectors.selectAdminsRolesId, (users, rolesId) =>
  users.filter((user) => !rolesId.includes(user.roleId))
);

const selectCurrentUser = createSelector(
  selectUsers,
  authentificationSelectors.selectUserId,
  (users, currentUserId) => users.filter((user) => (currentUserId ? user.userId?.includes(currentUserId) : false))[0]
);

const selectCurrentUserNameOrEmail = createSelector(
  selectCurrentUser,
  (user) => user?.fullName || user?.email || ''
);

const selectUserWorkspacesIds = createSelector(selectCurrentUser, (user) => user?.workspaceIds || []);

const selectUsersByWorkspace = (workspaceId: string) =>
  createSelector(selectUsers, (userList) => userList.filter((record) => record.workspaceIds?.includes(workspaceId)));

const selectUsersInCurrentWorkspace = createSelector(
  selectUsers,
  authentificationSelectors.selectWorkspace,
  (userList, workspaceId) => userList.filter((record) => record.workspaceIds?.includes(workspaceId))
);
const selectLoggedUsersInCurrentWorkspace = createSelector(selectUsersInCurrentWorkspace, (userList) =>
  userList.filter((record) => !!record.userId)
);
const selectLoggedUsersInCurrentWorkspaceOptions =
  createSelector(
    selectLoggedUsersInCurrentWorkspace,
    (usersList) => {
      const options:UserOption[] = [];
      for (const user of usersList) {
        if (user.userId) {
          options.push({
            value: user.userId,
            label: user.fullName || user.email,
            workspaceIds: user.workspaceIds || [],
          });
        }
      }
      return options;
    }
  );
const selectIsMultipleUsers = createSelector(selectLoggedUsersInCurrentWorkspace, (usersList) => usersList.length > 1);

const selectLoggedUsersOptions = (label: string) =>
  createSelector(
    selectLoggedUsersInCurrentWorkspace,
    authentificationSelectors.selectUserId,
    (usersList, currentUserId) => {
      const options = [{ value: currentUserId, label }, {value: FILTER_OPTION_TEAMS, label: t("Teams:TITLE")}];
      for (const user of usersList) {
        if (user.userId && user.userId !== currentUserId) {
          options.push({
            value: user.userId,
            label: user.fullName || user.email,
          });
        }
      }
      return options;
    }
  );
const selectLoggedUsers = createSelector(selectUsers, (userList) =>
    userList.filter((record) => !!record.userId)
);

const selectLoggedUserListOptions =
  createSelector(
    selectLoggedUsers,
    (usersList) => {
      const options:UserOption[] = [];
      for (const user of usersList) {
        if (user.userId) {
          options.push({
            value: user.userId,
            label: user.fullName || user.email,
            workspaceIds: user.workspaceIds || [],
          });
        }
      }
      return options;
    }
  );

const selectAdminUsersByWorkspace = (workspaceId: string) =>
  createSelector(selectAdminUsers, (adminList) =>
    adminList.filter((record) => record.workspaceIds?.includes(workspaceId))
  );

const selectStandardUsersByWorkspace = (workspaceId: string) =>
  createSelector(selectStandardUsers, (standardUserList) =>
    standardUserList.filter((record) => record.workspaceIds?.includes(workspaceId))
  );

const selectUserNamesById = createSelector(selectUsers, (users) =>
  users.reduce((namesById, user) => {
    if (user.userId) namesById[user.userId] = user.fullName || user.email;
    return namesById;
  }, {} as Record<string, string>)
);

const selectUserInfoById = createSelector(selectUsers, (users) =>
  users.reduce((namesById, user) => {
    if (user.userId) {
      namesById[user.userId] = { email: user.email, fullName: user.fullName || '' };
    }
    return namesById;
  }, {} as Record<string, { email: string; fullName: string }>)
);

const selectUserNameById = (userId: string) =>
  createSelector(selectUsers, (users) => {
    const user = users.find((user) => user.userId === userId);
    return user ? user.fullName || user.email : '';
  });

const selectUserNamesByIds = (userIds: (string | null)[] | null | undefined) =>
  createSelector(selectUsers, (users) =>
    users.reduce((result, user) => {
      if (user && userIds?.includes(user.userId || '')) {
        result.push(user.fullName || user.email);
      }
      return result;
    }, [] as string[])
  );

const selectSelectedUserPhone = createSelector(selectSelectedUser, (user) => user.phoneNumber);
const selectIsUserPhoneInvalid = createSelector(
  selectSelectedUserPhone,
  workspacesSelectors.selectIsWorkspacePhoneRequired,
  (phoneNumber, isPhoneRequired) => isPhoneRequired && !validatePhoneNumber(phoneNumber)
);

const selectBookingTemplateOptions = createSelector(selectInviteUsersOptions, (options) => options ? options.bookingTemplates : null);
const selectBookingTemplateOptionsByWorkspaces = (workspaceIds: Array<string | null>) =>
  createSelector(selectBookingTemplateOptions, workspacesSelectors.selectWorkspaces, (templateOptions, workspaces) =>
    templateOptions
      ? templateOptions
          .filter((option) => workspaceIds.includes(option.workspaceId))
          .map((option) => ({
            ...option,
            label: workspaces.find((record) => record.id === option.workspaceId)?.name || '',
          }))
      : null
  );

  const selectTeamsOptions = createSelector(selectInviteUsersOptions, (options) => options ? options.teams : null);
  const selectTeamsOptionsByWorkspaces = (workspaceIds: Array<string | null>) =>
    createSelector(selectTeamsOptions, workspacesSelectors.selectWorkspaces, (teamsOptions, workspaces) =>
      teamsOptions
        ? teamsOptions
            .filter((option) => workspaceIds.includes(option.workspaceId))
            .map((option) => ({
              ...option,
              label: workspaces.find((record) => record.id === option.workspaceId)?.name || '',
            }))
        : null
    );

export const usersSelectors = {
  selectIsFetching,
  selectError,
  selectUsers,
  selectSelectedUser,
  selectActiveSuperAdmins,
  selectUsersEmails,
  selectSearchedUsers,
  selectAdminUsers,
  selectStandardUsers,
  selectUsersByWorkspace,
  selectUsersInCurrentWorkspace,
  selectLoggedUsersInCurrentWorkspace,
  selectLoggedUsersInCurrentWorkspaceOptions,
  selectIsMultipleUsers,
  selectLoggedUsersOptions,
  selectLoggedUsers,
  selectLoggedUserListOptions,
  selectAdminUsersByWorkspace,
  selectStandardUsersByWorkspace,
  selectUserNameById,
  selectUserNamesByIds,
  selectUserNamesById,
  selectUserInfoById,
  selectHideInactive,
  selectIsUserPhoneInvalid,
  selectUserWorkspacesIds,
  selectBookingTemplateOptionsByWorkspaces,
  selectTeamsOptionsByWorkspaces,
  selectCurrentUser,
  selectCurrentUserNameOrEmail,
};
