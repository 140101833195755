import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  CreateUserEventInput,
  CustomFieldInput,
  CustomFieldType,
  InPersonType,
  LocationInput,
  LocationType,
  PhoneCallType,
  TimeUnit,
  UpdateBookingPageInput,
  WhereInput,
} from '../../API';
import { CURRENT_TIME_ZONE } from '../../types/constants';
import { AgendaType } from './types';
import { validateEmail, validatePhoneNumber } from '../../services/utils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const mapTimeUnitToDayjs = (unit: TimeUnit): dayjs.ManipulateType => {
  switch (unit) {
    case TimeUnit.DAY:
      return 'day';
    case TimeUnit.HOUR:
      return 'hour';
    case TimeUnit.MINUTE:
    default:
      return 'minute'; // Default to 'minute' if unknown unit
  }
};

// Calculates the updated event start and end time based on the provided agenda, event, and start time
export const calculateUpdatedEvent = (agenda: AgendaType, event: CreateUserEventInput, startTimeValue: Date) => {
  const timeZone = event?.timeZone || CURRENT_TIME_ZONE;
  const startTime = dayjs(startTimeValue).tz(timeZone);
  const durationCount = agenda?.bookingPage?.when?.duration?.count || 0;
  const durationTimeUnit = agenda?.bookingPage?.when?.duration?.timeUnit || TimeUnit.MINUTE;

  // map the TimeUnit to a format accepted by dayjs
  const dayjsUnit = mapTimeUnitToDayjs(durationTimeUnit);

  // calculate endTime based on duration
  const endTime = startTime.add(durationCount, dayjsUnit);

  return { startTime: startTime.format(), endTime: endTime.format(), timeZone } as Partial<CreateUserEventInput>;
};

export const calculateViewDate = (eventDate: string) => dayjs(eventDate).startOf('month').valueOf();

// export const startDateOfSpots = (viewDate: Date) => {
//   // if the view date is the same as the current day, round up to the nearest 15 minutes and return the timestamp
//   return dayjs().isSame(dayjs(viewDate), 'day')
//     ? dayjs()
//         .startOf('hour')
//         .add(Math.ceil(dayjs().minute() / 15) * 15, 'minute')
//         .valueOf()
//     : dayjs(viewDate).startOf('day').valueOf(); // Otherwise, return the start of the view date
// };

// export const endDateOfSpots = (viewDate: Date) => {
//   // return the end of the month for the given view date as a timestamp
//   return dayjs(viewDate).endOf('month').valueOf();
// };

export const agendaStartOfMonth = (viewDate: Date, timeZone: string) =>
  dayjs(viewDate).tz(timeZone).startOf('month').valueOf();

export const agendaEndOfMonth = (viewDate: Date, timeZone: string) =>
  dayjs(viewDate).tz(timeZone).endOf('month').valueOf();

// export const calculateAvailableLocationTypes = (
//   locationTypes: (LocationType | null)[] | null | undefined,
//   hostVideoIntegrations: string[] | undefined
// ) =>
//   (locationTypes || []).filter(
//     (type) =>
//       type &&
//       (!Object.values(IntegrationType)
//         .map((integrationType) => integrationType.toString())
//         .includes(type.toString()) ||
//         hostVideoIntegrations?.includes(type.toString()))
//   );

export const formatPhoneNumber = (countryCode: string, phoneNumber: string) =>
  `+${countryCode.slice(3)}-${phoneNumber}`;

export const calculateEventDate = (agenda: AgendaType, stateEventDate: string) => {
  const timeZone = agenda.event?.timeZone || CURRENT_TIME_ZONE;
  const eventDate = agenda.event?.eventDate || stateEventDate || dayjs().tz(timeZone).format('YYYY-MM-DD');
  const spotsLength = agenda.spots?.length || 0;

  if (spotsLength === 0) {
    return eventDate;
  }

  const eventDateValue = dayjs.tz(eventDate, timeZone).valueOf();
  const startTime = (agenda.spots && parseInt(agenda.spots[0])) || eventDateValue;
  const endTime = (agenda.spots && parseInt(agenda.spots[spotsLength - 1])) || eventDateValue;

  return eventDateValue < startTime || eventDateValue > endTime
    ? dayjs.tz(startTime, timeZone).format('YYYY-MM-DD')
    : eventDate;
};

export const calculateInitialEventLocation = (bookingPage: UpdateBookingPageInput) => ({
  type:
    bookingPage?.where?.defaultLocationType ||
    (bookingPage.where?.locationTypes?.length && bookingPage.where?.locationTypes[0]) ||
    LocationType.VIDEO_CONFERENCE,
  settings:
    bookingPage?.where?.defaultLocationType === LocationType.IN_PERSONAL &&
    bookingPage?.where?.inPersonType === InPersonType.LOCATION &&
    bookingPage?.where?.locations?.length
      ? bookingPage?.where?.locations[0]
      : bookingPage?.where?.defaultLocationType === LocationType.IN_PERSONAL &&
        bookingPage?.where?.inPersonType === InPersonType.CUSTOM_ADDRESS &&
        bookingPage?.where?.customAddress
      ? bookingPage?.where?.customAddress
      : bookingPage?.where?.defaultLocationType === LocationType.PHONE_CALL &&
        bookingPage?.where?.phoneCallType === PhoneCallType.CUSTOM_PHONE &&
        bookingPage?.where?.customPhone
      ? bookingPage?.where?.customPhone
      : null,
});

export const validateCustomField = (field: CustomFieldInput) => {
  if (!field.required && field.fieldType !== CustomFieldType.PHONE) {
    return true;
  }
  if (!field.value) {
    return false;
  }
  switch (field.fieldType) {
    case CustomFieldType.EMAIL:
      return validateEmail(field.value);
    case CustomFieldType.PHONE: {
      const phone = field.value.slice(field.value.indexOf('-') + 1);
      return (!field.required && phone.length === 0) || validatePhoneNumber(phone);
    }
    case CustomFieldType.NAME:
    case CustomFieldType.LOCATION:
    case CustomFieldType.CUSTOM:
      return Boolean(field.value);
  }
};

export const calculateEventLocationSettings = (
  location: LocationInput | null | undefined,
  BookingPageWhere: WhereInput | null | undefined,
  hostPhone: string | null | undefined,
  customPhone: string | null | undefined,
  inviteePhone: string | null | undefined,
  inviteeLocation: string | null | undefined
) => {
  if (!location || !BookingPageWhere) {
    return null;
  }
  switch (location.type) {
    case LocationType.IN_PERSONAL:
      switch (BookingPageWhere.inPersonType) {
        case InPersonType.CUSTOM_ADDRESS:
          return BookingPageWhere.customAddress;
        case InPersonType.INVITEE_LOCATION:
          return inviteeLocation;
        case InPersonType.LOCATION:
        default:
          return null;
      }
    case LocationType.PHONE_CALL:
      switch (BookingPageWhere.phoneCallType) {
        case PhoneCallType.CUSTOM_PHONE:
          return customPhone;
        case PhoneCallType.HOST_PHONE_NUMBER:
          return hostPhone;
        case PhoneCallType.PROMPT_INVITE:
          return inviteePhone;
        default:
          return null;
      }
    case LocationType.VIDEO_CONFERENCE:
    default:
      return null;
  }
};
