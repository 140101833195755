import { call, put, select, takeLatest } from 'redux-saga/effects';
import { CreateTenantInput, WorkspaceInput } from '../../API';
import { workspacesActionTypes, workspacesActions } from './actions';
import { authentificationActions, authentificationSelectors } from '../authentification';
import { workspacesSelectors } from './selectors';
import { CreateWorkspaceResponse, WorkspaceActions, WorkspaceData } from './types';
import { usersActions, usersSelectors } from '../users';
import { UserAdminDataFullRecord } from '../users/typings';
import { notificationsActions } from '../notifications';
import {
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_TOAST,
  ACTIVATE_WORKSPACE_SUCCESS_TOAST,
  DELETE_WORKSPACE_ERROR_TOAST,
  DELETE_WORKSPACE_SUCCESS_TOAST,
  GET_WORKSPACES_ERROR_TOAST,
  INACTIVATE_WORKSPACE_SUCCESS_TOAST,
  SAVE_WORKSPACE_ERROR_TOAST,
  SAVE_WORKSPACE_SUCCESS_TOAST,
} from './constants';
import { uploadPublicFile } from '../userSettings/service';
import { editUser } from '../users/service';
import { getTenantInfo, updateWorkspaceList } from './service';
import { userSettingsActions, userSettingsSelectors } from '../userSettings';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { createSelector } from 'reselect';
import { handleServiceError } from '../utils/reduxUtils';

const filterUserRecords = (mainList: UserAdminDataFullRecord[], subList: UserAdminDataFullRecord[]) =>
  mainList.filter((record) => !subList.some((subrecord) => subrecord.email === record.email));

const selectWorkspaceRequest = () =>
  createSelector(
    workspacesSelectors.selectWorkspace,
    workspacesSelectors.selectFinalLogoImage,
    workspacesSelectors.selectFinalBackgroundImage,
    (workspace, logo, background) => ({
      ...workspace,
      style: {
        ...workspace.style,
        avatar: logo,
        backgroundImage: background
      }
    })
  );

function* getWorkspacesSaga() {
  try {
    const tenantId: string = yield select(authentificationSelectors.selectTenantId);
    const tenantInfo: CreateTenantInput = yield call(getTenantInfo, tenantId);
    let workspaces: WorkspaceInput[] = [];
    if (tenantInfo.workspace) {
      workspaces = tenantInfo.workspace.filter((workspace): workspace is WorkspaceInput => Boolean(workspace));
    }
    /* if (tenantInfo.tenantStyle) {
      yield put(workspacesActions.setTenantStyle(tenantInfo.tenantStyle));
    } */
    yield put(workspacesActions.getWorkspacesSuccess(workspaces));
  } catch (error: unknown) {
    yield put(workspacesActions.getWorkspacesFail(error?.toString()));
    yield call(handleServiceError, error, GET_WORKSPACES_ERROR_TOAST, true);
  }
}

function* activateWorkspaceSaga({ payload }: any) {
  try {
    const tenantId: string = yield select(authentificationSelectors.selectTenantId);
    const id: string = payload;
    const workspace: WorkspaceInput | undefined = yield select(workspacesSelectors.selectWorkspaceById(id));
    if (workspace) {
      const updatedWorkspaces = { ...workspace, isActive: !workspace.isActive };
      yield call(updateWorkspaceList, {
        type: WorkspaceActions.updateWorkspace,
        tenant: {
          tenantId,
          workspace: updatedWorkspaces,
        },
      });

      yield call(navigationService.navigateTo, Path.Workspaces);

      yield put(workspacesActions.activateWorkspaceSuccess());
      yield put(workspacesActions.getWorkspacesRequest());
      yield put<any>(
        notificationsActions.showToast(
          updatedWorkspaces.isActive ? ACTIVATE_WORKSPACE_SUCCESS_TOAST : INACTIVATE_WORKSPACE_SUCCESS_TOAST
        )
      );
    }
  } catch (error: unknown) {
    yield put(workspacesActions.activateWorkspaceFail(error?.toString()));
    yield call(handleServiceError, error, ACTIVATE_INACTIVATE_WORKSPACE_ERROR_TOAST);
  }
}

function* updateCurrentUserWorkspacesList(
  usersList: UserAdminDataFullRecord[],
  workspaceId: string,
  action: 'add' | 'delete'
) {
  const userId: string = yield select(authentificationSelectors.selectUserId);
  const userWorkspaces: string[] = yield select(userSettingsSelectors.selectUserWorkspaces);
  const currentWorkspace: string = yield select(authentificationSelectors.selectWorkspace);

  if (usersList.some((user) => user.userId === userId)) {
    const userNewWorkspaces =
      action === 'add' ? [...userWorkspaces, workspaceId] : userWorkspaces.filter((id) => id !== workspaceId);
    yield put(userSettingsActions.updateUserDetails({ workspaceIds: userNewWorkspaces }));
    if (action === 'delete' && currentWorkspace === workspaceId) {
      yield put(authentificationActions.updateUserDataCore({ workspaceId: userNewWorkspaces[0] }));
    }
  }
}

function* deleteWorkspaceSaga() {
  try {
    const tenantId: string = yield select(authentificationSelectors.selectTenantId);
    const workspaceData: WorkspaceData = yield select(workspacesSelectors.selectWorkspace);
    const id = workspaceData.id;
    const usersRemoveFromWorkspace = [...workspaceData.adminList, ...workspaceData.userList].map((userRecord) => ({
      ...userRecord,
      workspaceIds: userRecord.workspaceIds?.filter((workspaceId) => workspaceId !== workspaceData.id),
    }));

    // TODO: collect and make this calls at the same time for more speed
    yield call(updateWorkspaceList, {
      type: WorkspaceActions.deleteWorkspace,
      tenant: {
        tenantId,
        workspace: {
          id,
        },
      },
    });

    if (usersRemoveFromWorkspace.length) {
      yield call(editUser, usersRemoveFromWorkspace);
    }
    yield call(updateCurrentUserWorkspacesList, usersRemoveFromWorkspace, id, 'delete');

    yield call(navigationService.navigateTo, Path.Workspaces);

    yield put(workspacesActions.deleteWorkspacesSuccess());
    yield put(workspacesActions.getWorkspacesRequest());
    yield put(usersActions.getUsersRequest());
    yield put<any>(notificationsActions.showToast(DELETE_WORKSPACE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(workspacesActions.deleteWorkspacesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_WORKSPACE_ERROR_TOAST);
  }
}

function* updateWorkspace(tenantId: string, workspaceData: WorkspaceData) {
  yield call(updateWorkspaceList, {
    type: WorkspaceActions.updateWorkspace,
    tenant: {
      tenantId,
      workspace: {
        id: workspaceData.id,
        name: workspaceData.name,
        isActive: workspaceData.isActive,
        isPhoneRequired: workspaceData.isPhoneRequired,
        style: workspaceData.style,
        labels: workspaceData.labels,
      },
    },
  });

  const workspaceUsers: UserAdminDataFullRecord[] = yield select(usersSelectors.selectUsersByWorkspace(workspaceData.id));
  const userList = [...workspaceData.adminList, ...workspaceData.userList];
  // email from workspaceUsers not in userList
  const usersRemoveFromWorkspace = filterUserRecords(workspaceUsers, userList).map((userRecord) => ({
    ...userRecord,
    workspaceIds: userRecord.workspaceIds?.filter((workspaceId) => workspaceId !== workspaceData.id),
  }));

  // email from userList not in workspaceUsers
  const usersAddToWorkspace = filterUserRecords(userList, workspaceUsers).map((userRecord) => ({
    ...userRecord,
    workspaceIds: userRecord.workspaceIds?.length ? [...userRecord.workspaceIds, workspaceData.id] : [workspaceData.id],
  }));

  const userRecordsForUpdate = [...usersRemoveFromWorkspace, ...usersAddToWorkspace];
  if (userRecordsForUpdate.length) {
    yield call(editUser, userRecordsForUpdate);
  }

  // TODO: make this calls at the same time
  yield call(updateCurrentUserWorkspacesList, usersAddToWorkspace, workspaceData.id, 'add');
  yield call(updateCurrentUserWorkspacesList, usersRemoveFromWorkspace, workspaceData.id, 'delete');
}

function* createWorkspace(tenantId: string, workspaceData: WorkspaceData) {
  const response: CreateWorkspaceResponse = yield call(updateWorkspaceList, {
    type: WorkspaceActions.createWorkspace,
    tenant: {
      tenantId,
      workspace: {
        name: workspaceData.name,
        isActive: workspaceData.isActive,
        isPhoneRequired: workspaceData.isPhoneRequired,
        style: workspaceData.style,
        labels: workspaceData.labels,
      },
    },
  });

  const userList = [...workspaceData.adminList, ...workspaceData.userList].map((userRecord) => ({
    ...userRecord,
    workspaceIds: userRecord.workspaceIds?.length ? [...userRecord.workspaceIds, response.id] : [response.id],
  }));
  if (userList.length) {
    yield call(editUser, userList);
  }
  yield call(updateCurrentUserWorkspacesList, userList, response.id, 'add');
}

function* saveWorkspaceSaga() {
  try {
    const tenantId: string = yield select(authentificationSelectors.selectTenantId);
    const workspaceData: WorkspaceData = yield select(selectWorkspaceRequest());

    if (workspaceData.id) {
      //update workspace record
      yield call(updateWorkspace, tenantId, workspaceData);
    } else {
      //create workspace record
      yield call(createWorkspace, tenantId, workspaceData);
    }

    yield call(navigationService.navigateTo, Path.Workspaces);

    yield put(workspacesActions.saveWorkspaceSuccess());
    yield put<any>(notificationsActions.showToast(SAVE_WORKSPACE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(workspacesActions.saveWorkspaceFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_WORKSPACE_ERROR_TOAST);
  }
}

function* uploadAvatarFileSaga({ payload }: any) {
  try {
    const path: string = yield select(authentificationSelectors.selectTenantFileFolderPath);
    const imageLink: string = yield call(uploadPublicFile, payload.file, path);
    yield put(workspacesActions.uploadAvatarFileSuccess({ imageType: payload.imageType, imageLink }));
  } catch (error: unknown) {
    yield put(workspacesActions.uploadAvatarFileFail(error));
  }
}

function* uploadBackgroundFileSaga({ payload }: any) {
  try {
    const path: string = yield select(authentificationSelectors.selectTenantFileFolderPath);
    const imageLink: string = yield call(uploadPublicFile, payload.file, path);
    yield put(workspacesActions.uploadBackgroundFileSuccess({ imageType: payload.imageType, imageLink }));
  } catch (error: unknown) {
    yield put(workspacesActions.uploadBackgroundFileFail(error));
  }
}

export function* watchWorkspacesSaga() {
  yield takeLatest(workspacesActionTypes.GET_WORKSPACES_REQUEST, getWorkspacesSaga);
  yield takeLatest(workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST, activateWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.SAVE_WORKSPACE_REQUEST, saveWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.DELETE_WORKSPACES_REQUEST, deleteWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.UPLOAD_AVATAR_FILE_REQUEST, uploadAvatarFileSaga);
  yield takeLatest(workspacesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST, uploadBackgroundFileSaga);
}

export const workspacesSaga = {
  getWorkspaces: getWorkspacesSaga,
};
