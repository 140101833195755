import { t } from '../../../i18n/i18n';

export default {
  close: t('Common:CLOSE'),
  viewAttendees: t('ScheduledMeetings:VIEW_ATTENDEES'),
  viewAttendeesDesc: t('ScheduledMeetings:VIEW_ATTENDEES_DESC'),
  viewAttendeesNote: t('ScheduledMeetings:VIEW_ATTENDEES_NOTE'),
  attendees: t('ScheduledMeetings:ATTENDEES'),
  hosts: t('ScheduledMeetings:HOSTS'),
};
