import { AvailabilityInput, DateTimeFromToInput, WeeklyHoursInput } from '../API';
import { FormattedZone } from './interfaces';

export type CustomHubPayload = {
  payload: {
    event: string;
    data?: any;
    message?: string;
  };
};

export type AccountMenuItem = {
  name: string;
  path?: string;
  isModal?: boolean;
};

export type FormattedZoneMap = Map<string, FormattedZone[]>;

export type modifyAvailabilityData = (
  data: AvailabilityInput | null | undefined
) => AvailabilityInput | null | undefined;

export type ModifyWeeklyHours = (day: WeeklyHoursInput | null) => WeeklyHoursInput | null;

export type DateTimeFromToPartial = Partial<DateTimeFromToInput['from']> | Partial<DateTimeFromToInput['to']>;

export enum ImageType {
  avatar = 'avatar',
  backgroundImage = 'backgroundImage',
}

export type UploadImageRequestPayload = {
  imageType: ImageType;
  file: any;
};

export type UploadImageSuccessPayload = {
  imageType: ImageType;
  imageLink: string;
};

export type UploadedImage = {
  imageType: ImageType;
  imageLink: string | null; // null => use workspace image, '' => use no image, 'link' - use given image
};
