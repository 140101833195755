import { CanceledType, CreateUserEventInput } from '../../API';
import { UpdateScheduledMeeting } from './typings';

export enum ScheduledMeetingsActionTypes {
  GET_SCHEDULED_MEETINGS_SUCCESS = 'scheduledMeetings/GET_SCHEDULED_MEETINGS_SUCCESS',
  GET_SCHEDULED_MEETINGS_REQUEST = 'scheduledMeetings/GET_SCHEDULED_MEETINGS_REQUEST',
  GET_SCHEDULED_MEETINGS_FAIL = 'scheduledMeetings/GET_SCHEDULED_MEETINGS_FAIL',
  SET_SORT_METHOD = 'scheduledMeetings/SET_SORT_METHOD',
  SET_DATE_RANGE = 'scheduledMeetings/SET_DATE_RANGE',
  SET_STATUS = 'scheduledMeetings/SET_STATUS',
  SET_SELECTED_BOOKING_PAGES = 'scheduledMeetings/SET_SELECTED_BOOKING_PAGES',
  UPDATE_SCHEDULED_MEETING_REQUEST = 'scheduledMeetings/UPDATE_SCHEDULED_MEETING_REQUEST',
  UPDATE_SCHEDULED_MEETING_SUCCESS = 'scheduledMeetings/UPDATE_SCHEDULED_MEETING_SUCCESS',
  UPDATE_SCHEDULED_MEETING_FAIL = 'scheduledMeetings/UPDATE_SCHEDULED_MEETING_FAIL',
  SELECT_SCHEDULED_MEETING = 'scheduledMeetings/SELECT_SCHEDULED_MEETING',
  UPDATE_CANCEL_REASON = 'scheduledMeetings/UPDATE_CANCEL_REASON',
  UPDATE_CANCEL_NOTE = 'scheduledMeetings/UPDATE_CANCEL_NOTE',
  CANCEL_SCHEDULED_MEETING_REQUEST = 'scheduledMeetings/CANCEL_SCHEDULED_MEETING_REQUEST',
  CANCEL_SCHEDULED_MEETING_SUCCESS = 'scheduledMeetings/CANCEL_SCHEDULED_MEETING_SUCCESS',
  CANCEL_SCHEDULED_MEETING_FAIL = 'scheduledMeetings/CANCEL_SCHEDULED_MEETING_FAIL',
}

export type ScheduledMeetingsAction =
  | { type: ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST }
  | { type: ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS; payload: CreateUserEventInput[] }
  | { type: ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL; error: unknown }
  | { type: ScheduledMeetingsActionTypes.SET_SORT_METHOD; payload: string }
  | { type: ScheduledMeetingsActionTypes.SET_DATE_RANGE; payload: string[] }
  | { type: ScheduledMeetingsActionTypes.SET_STATUS; payload: string }
  | { type: ScheduledMeetingsActionTypes.SET_SELECTED_BOOKING_PAGES; payload: string[] }
  | { type: ScheduledMeetingsActionTypes.SELECT_SCHEDULED_MEETING; payload: CreateUserEventInput }
  | { type: ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST; payload: UpdateScheduledMeeting }
  | { type: ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS; payload: CreateUserEventInput }
  | { type: ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL; error: unknown }
  | { type: ScheduledMeetingsActionTypes.UPDATE_CANCEL_REASON; reason: CanceledType }
  | { type: ScheduledMeetingsActionTypes.UPDATE_CANCEL_NOTE; note: string }
  | { type: ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST }
  | { type: ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_SUCCESS }
  | { type: ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL; error: string };

const getScheduledMeetingsRequest = (): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST,
});
const getScheduledMeetingsSuccess = (payload: CreateUserEventInput[]): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS,
  payload,
});
const getScheduledMeetingsFail = (error: unknown): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL,
  error,
});
const setSortMethod = (payload: string): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.SET_SORT_METHOD,
  payload,
});
const setDateRange = (payload: string[]): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.SET_DATE_RANGE,
  payload,
});
const setStatus = (payload: string): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.SET_STATUS,
  payload,
});
const setSelectedBookingPages = (payload: string[]): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.SET_SELECTED_BOOKING_PAGES,
  payload,
});

const selectScheduledMeeting = (payload: CreateUserEventInput): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.SELECT_SCHEDULED_MEETING,
  payload,
});

const updateScheduledMeetingRequest = (payload: UpdateScheduledMeeting): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST,
  payload,
});
const updateScheduledMeetingSuccess = (payload: CreateUserEventInput): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS,
  payload,
});
const updateScheduledMeetingFail = (error: unknown): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL,
  error,
});
const updateCancelReason = (reason: CanceledType): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.UPDATE_CANCEL_REASON,
  reason,
});
const updateCancelNote = (note: string): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.UPDATE_CANCEL_NOTE,
  note,
});

const cancelScheduledMeetingRequest = (): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST,
});
const cancelScheduledMeetingSuccess = (): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_SUCCESS,
});
const cancelScheduledMeetingFail = (error: string): ScheduledMeetingsAction => ({
  type: ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL,
  error,
});

export const scheduledMeetingsActions = {
  updateCancelReason,
  getScheduledMeetingsRequest,
  getScheduledMeetingsSuccess,
  getScheduledMeetingsFail,
  setSortMethod,
  setDateRange,
  setStatus,
  setSelectedBookingPages,
  updateScheduledMeetingRequest,
  updateScheduledMeetingSuccess,
  updateScheduledMeetingFail,
  selectScheduledMeeting,
  updateCancelNote,
  cancelScheduledMeetingRequest,
  cancelScheduledMeetingSuccess,
  cancelScheduledMeetingFail,
};
