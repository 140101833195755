import { Staff } from '../../../API';

export enum StaffActionTypes {
  GET_STAFF_REQUEST = 'staff/GET_STAFF_REQUEST',
  GET_STAFF_SUCCESS = 'staff/GET_STAFF_SUCCESS',
  GET_STAFF_FAIL = 'staff/GET_STAFF_FAIL',
  SET_SEARCH_STRING = 'staff/SET_SEARCH_STRING',
  SET_STAFF_RECORD = 'staff/SET_STAFF_RECORD',
  UPDATE_STAFF_RECORD = 'staff/UPDATE_STAFF_RECORD',
  SET_IS_ADD_NEW = 'staff/SET_IS_ADD_NEW',
  SAVE_STAFF_REQUEST = 'staff/SAVE_STAFF_REQUEST',
  SAVE_STAFF_SUCCESS = 'staff/SAVE_STAFF_SUCCESS',
  SAVE_STAFF_FAIL = 'staff/SAVE_STAFF_FAIL',
  CHANGE_STAFF_STATUS_REQUEST = 'staff/CHANGE_STAFF_STATUS_REQUEST',
  CHANGE_STAFF_STATUS_SUCCESS = 'staff/CHANGE_STAFF_STATUS_SUCCESS',
  CHANGE_STAFF_STATUS_FAIL = 'staff/CHANGE_STAFF_STATUS_FAIL',
  DELETE_STAFF_REQUEST = 'staff/DELETE_STAFF_REQUEST',
  DELETE_STAFF_SUCCESS = 'staff/DELETE_STAFF_SUCCESS',
  DELETE_STAFF_FAIL = 'staff/DELETE_STAFF_FAIL',
}

export type StaffAction =
  | { type: StaffActionTypes.GET_STAFF_REQUEST }
  | { type: StaffActionTypes.GET_STAFF_SUCCESS; payload: Staff[] }
  | { type: StaffActionTypes.GET_STAFF_FAIL; error: unknown }
  | { type: StaffActionTypes.SET_SEARCH_STRING; payload: string }
  | { type: StaffActionTypes.SET_STAFF_RECORD; payload: Staff }
  | { type: StaffActionTypes.UPDATE_STAFF_RECORD; payload: Partial<Staff> }
  | { type: StaffActionTypes.SET_IS_ADD_NEW; payload: boolean }
  | { type: StaffActionTypes.SAVE_STAFF_REQUEST }
  | { type: StaffActionTypes.SAVE_STAFF_SUCCESS }
  | { type: StaffActionTypes.SAVE_STAFF_FAIL; error: unknown }
  | { type: StaffActionTypes.CHANGE_STAFF_STATUS_REQUEST }
  | { type: StaffActionTypes.CHANGE_STAFF_STATUS_SUCCESS }
  | { type: StaffActionTypes.CHANGE_STAFF_STATUS_FAIL; error: unknown }
  | { type: StaffActionTypes.DELETE_STAFF_REQUEST }
  | { type: StaffActionTypes.DELETE_STAFF_SUCCESS }
  | { type: StaffActionTypes.DELETE_STAFF_FAIL; error: unknown };

const getStaffRequest = (): StaffAction => ({ type: StaffActionTypes.GET_STAFF_REQUEST });
const getStaffSuccess = (payload: Staff[]): StaffAction => ({
  type: StaffActionTypes.GET_STAFF_SUCCESS,
  payload,
});
const getStaffFail = (error: unknown): StaffAction => ({ type: StaffActionTypes.GET_STAFF_FAIL, error });

const setSearchString = (payload: string): StaffAction => ({
  type: StaffActionTypes.SET_SEARCH_STRING,
  payload,
});

const setStaffRecord = (payload: Staff): StaffAction => ({ type: StaffActionTypes.SET_STAFF_RECORD, payload });

const updateStaffRecord = (payload: Partial<Staff>): StaffAction => ({
  type: StaffActionTypes.UPDATE_STAFF_RECORD,
  payload,
});

const setIsAddNew = (payload: boolean): StaffAction => ({
  type: StaffActionTypes.SET_IS_ADD_NEW,
  payload,
});

const saveStaffRequest = (): StaffAction => ({
  type: StaffActionTypes.SAVE_STAFF_REQUEST,
});
const saveStaffSuccess = (): StaffAction => ({
  type: StaffActionTypes.SAVE_STAFF_SUCCESS,
});
const saveStaffFail = (error: unknown): StaffAction => ({ type: StaffActionTypes.SAVE_STAFF_FAIL, error });

const changeStaffStatusRequest = (): StaffAction => ({
  type: StaffActionTypes.CHANGE_STAFF_STATUS_REQUEST,
});
const changeStaffStatusSuccess = (): StaffAction => ({
  type: StaffActionTypes.CHANGE_STAFF_STATUS_SUCCESS,
});
const changeStaffStatusFail = (error: unknown): StaffAction => ({
  type: StaffActionTypes.CHANGE_STAFF_STATUS_FAIL,
  error,
});

const deleteStaffRequest = (): StaffAction => ({
  type: StaffActionTypes.DELETE_STAFF_REQUEST,
});
const deleteStaffSuccess = (): StaffAction => ({
  type: StaffActionTypes.DELETE_STAFF_SUCCESS,
});
const deleteStaffFail = (error: unknown): StaffAction => ({
  type: StaffActionTypes.DELETE_STAFF_FAIL,
  error,
});

export const staffActions = {
  getStaffRequest,
  getStaffSuccess,
  getStaffFail,
  setSearchString,
  setStaffRecord,
  updateStaffRecord,
  setIsAddNew,
  saveStaffRequest,
  saveStaffSuccess,
  saveStaffFail,
  changeStaffStatusRequest,
  changeStaffStatusSuccess,
  changeStaffStatusFail,
  deleteStaffRequest,
  deleteStaffSuccess,
  deleteStaffFail,
};
