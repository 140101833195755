import { createSelector } from 'reselect';
import { State } from '../rootStore';

const globalState = (state: State) => state.global;

const selectIsFetching = createSelector(globalState, (global) => global.isFetching);

const selectIsError  = createSelector(globalState, (global) => global.error);

const selectSearch  = createSelector(globalState, (global) => global.search);
const selectSearchString  = createSelector(selectSearch, (search) => search.searchString);
const selectSearchPaths  = createSelector(selectSearch, (search) => search.paths);

export const globalSelectors = {
  selectIsFetching,
  selectIsError,

  selectSearch,
  selectSearchString,
  selectSearchPaths,
};
