import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScheduledChangesInput } from '../../API';
import { Preloader, SectionTitle } from '../../components/common';
import { BillingChangeSeatsModal, BillingChangeTermModal, BuyLicenseButton } from '../../components/billing';
import { changeSeatsModalActions, billingActions, billingSelectors, changeTermModalActions } from '../../store/billing';
import { userSettingsSelectors } from '../../store/userSettings';
import { authentificationSelectors } from '../../store/authentification';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import labels from './labels';
import { openPortal } from '../../services/chargebee';
import { formatDateTimeThroughGap } from '../../services/DateService';

export const Billing = () => {
  const dispatch = useDispatch();
  const isUserSettingReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isTenantExpired = useSelector(authentificationSelectors.selectIsTenantExpired);
  const isTrialLicense = useSelector(authentificationSelectors.selectIsTrialLicense);
  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);
  const isNexBillingReceived = useSelector(billingSelectors.selectIsNextBillingReceived);
  const isCancelScheduled = useSelector(billingSelectors.selectIsCancelScheduled);
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const isRemoveSeatsAvailable = useSelector(billingSelectors.selectIsRemoveSeatsAvailable);
  const hasScheduledChanges = useSelector(billingSelectors.selectHasScheduledChanges);
  const hasScheduledChangeTheTerm = useSelector(billingSelectors.selectHasScheduledChangeTheTerm);
  const quantity = useSelector(billingSelectors.selectSubscriptionQuantity);
  const lastUpdatedDate = useSelector(billingSelectors.selectLastUpdatedDate);
  const nextBillingDate = useSelector(billingSelectors.selectNextBillingDate);
  const cancelDate = useSelector(billingSelectors.selectCancelDate);
  // const expireMessage = useSelector(billingSelectors.selectExpireMessage);
  const termLabel = useSelector(billingSelectors.selectTermLabel);
  const nextBillAmountLabel = useSelector(billingSelectors.selectNextBillAmountLabel);
  const nextBillLateLabel = useSelector(billingSelectors.selectNextBillLateLabel);
  const scheduledChanges = useSelector(billingSelectors.selectSumoScheduledChanges);
  const licenseFetching = useSelector(authentificationSelectors.selectLicensesIsFetching);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat);
  // const [licenseTableUpdating, setLicenseTableUpdating] = useState(false);

  useEffect(() => {
    if (isUserSettingReceived) {
      if (isSuperAdmin && !isTrialLicense) {
        dispatch(billingActions.getBillingRequest());
      } else if (!(isSuperAdmin && isTrialLicense && !isLicenseActive)) {
        dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
      }
    }
  }, [isUserSettingReceived]);

  const handleDeleteScheduledChange = (id: string) => {
    dispatch(billingActions.deleteScheduledChangeRequest(id));
  };

  const handleChangeTerm = () => {
    dispatch(changeTermModalActions.openModal());
  };

  const handleAddSeats = () => {
    dispatch(billingActions.setAddOrRemoveSeats(true));
    dispatch(changeSeatsModalActions.openModal());
  };

  const handleRemoveSeats = () => {
    dispatch(billingActions.setAddOrRemoveSeats(false));
    dispatch(changeSeatsModalActions.openModal());
  };

  const handleOpenPortal = () => {
    openPortal();
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <BillingChangeSeatsModal />
      <BillingChangeTermModal />

      <>
        {isTenantExpired && (
          <div className="card mb-3 bg-red-200 text-center">
            {labels.accountSuspendedPart1} <b>{labels.accountSuspendedPart2}</b> {labels.accountSuspendedPart3}
          </div>
        )}

        <div className="card mb-3 flex align-items-center sumo-header-bg">
          <SectionTitle id="billingPageTitle" icon="pi-dollar" title={labels.title} />
        </div>

        {isTrialLicense && !isNexBillingReceived ? (
          <div className="card text-center">
            <div className="pb-3">{labels.trialLicenseExpired}</div>
            <div className="flex justify-content-center align-items-center">
              <BuyLicenseButton />
              {licenseFetching && <i className="pi pi-spin pi-spinner" style={{ marginLeft: '10px' }} />}
            </div>
          </div>
        ) : (
          <div className="grid">
            <div className="col-12 md:col-7">
              <div className="card h-full">
                <div className="text-xl mb-4">{labels.yourSubscription}</div>
                <div className="flex justify-content-between gap-2 text-xl font-bold">
                  <div>{termLabel}</div>
                  <div>{`${quantity} ${labels.seats}`}</div>
                </div>
                <div className="text-gray-500 mt-1">{`${labels.lastUpdated} ${lastUpdatedDate}`}</div>
                <div className="flex gap-3 mt-4">
                  <Button
                    label={labels.changeTerm}
                    outlined
                    onClick={handleChangeTerm}
                    disabled={hasScheduledChanges}
                  />
                  <Button
                    label={labels.addSeats}
                    className="ml-auto"
                    outlined
                    onClick={handleAddSeats}
                    disabled={hasScheduledChangeTheTerm}
                  />
                  <Button
                    label={labels.removeSeats}
                    outlined
                    onClick={handleRemoveSeats}
                    disabled={!isRemoveSeatsAvailable || hasScheduledChangeTheTerm}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 md:col-5">
              <div className="card h-full flex flex-column">
                <div className="text-xl mb-4">{labels.yourNextBill}</div>
                {isCancelScheduled && (
                  <div className="text-xl ">
                    {labels.cancellationScheduled}
                    <b>{cancelDate}</b>.
                  </div>
                )}
                {isNexBillingReceived && (
                  <div>
                    <div className="text-xl font-bold">
                      <span>{nextBillAmountLabel}</span>
                      {/* <span className="text-gray-500 ml-4">{nextBillChangeLabel}</span> */}
                    </div>
                    <div className="text-gray-500 mt-1">{`${labels.dueOn} ${nextBillingDate}`}</div>
                  </div>
                )}
                {isNexBillingReceived && nextBillLateLabel && (
                  <div className="flex align-items-center gap-3 mt-4">
                    <div className="text-red-500 font-semibold">{nextBillLateLabel}</div>
                    <Button label={labels.payNow} className="ml-auto" onClick={handleOpenPortal} />
                  </div>
                )}
              </div>
            </div>

            {Boolean(scheduledChanges?.length) && (
              <div className="col-12">
                <div className="card">
                  <div className="text-xl mb-2">{`${labels.scheduledChanges} ${nextBillingDate}`}</div>
                  <div className="mb-5">{labels.scheduledChangesDescription}</div>
                  <DataTable
                    className="cursor-pointer"
                    size="small"
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    showGridlines
                    removableSort
                    rowHover
                    value={scheduledChanges}
                  >
                    <Column
                      header={labels.scheduledColumnLabel}
                      body={(data: ScheduledChangesInput) =>
                        data.plan
                          ? `${labels.termChangeLabel} ${data.plan}`
                          : `${labels.scheduledChanges1Label} ${data.seats} ${labels.scheduledChanges2Label}`
                      }
                    />
                    <Column field="changeBy" align="center" header={labels.scheduledColumnChangeBy} sortable />
                    <Column
                      field="requestDate"
                      align="center"
                      header={labels.scheduledColumnDate}
                      sortable
                      body={(data: ScheduledChangesInput) =>
                        data?.requestDate &&
                        formatDateTimeThroughGap(data.requestDate, dateFormat || '', timeFormat || '')
                      }
                    />
                    <Column
                      header={labels.scheduledColumnOptions}
                      align="center"
                      body={(data: ScheduledChangesInput) => (
                        <div className="-m-1">
                          <Button
                            label={labels.cancel}
                            className="w-full"
                            text
                            onClick={() => handleDeleteScheduledChange(data.id || '')}
                          />
                        </div>
                      )}
                    />
                  </DataTable>
                </div>
              </div>
            )}

            <div className="col-12">
              <div className="card">
                <div className="text-xl mb-4">{labels.billingDetails}</div>
                <div className="align-items-center grid">
                  <div className="col-fixed">
                    <Button label={labels.chargeBeeButton} outlined onClick={handleOpenPortal} />
                  </div>
                  <div className="col">{labels.billingPortalDescription}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
