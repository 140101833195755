import { PermissionAction } from '../../API';
import { t } from '../../i18n/i18n';
import { adminRoles } from '../../shared/backend/constants';
import { ToastNotificationOptions } from '../notifications';

export const GET_ROLES_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 3000,
  message: t('RoleToastsNotifications:GET_ROLES_FAIL_MESSAGE'),
});

export const SAVE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('RoleToastsNotifications:SAVE_ROLE_SUCCESS_MESSAGE'),
});
export const SAVE_ROLE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('RoleToastsNotifications:SAVE_ROLE_ERROR_MESSAGE'),
});

export const DELETE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('RoleToastsNotifications:DELETE_ROLE_SUCCESS_MESSAGE'),
});
export const DELETE_ROLE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('RoleToastsNotifications:DELETE_ROLE_ERROR_MESSAGE'),
});
export const DELETE_ROLE_ASSIGNED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('RoleToastsNotifications:DELETE_ROLE_ASSIGNED_ERROR_MESSAGE'),
});

export const ACTIVATE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('RoleToastsNotifications:ACTIVATE_ROLE_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('RoleToastsNotifications:DEACTIVATE_ROLE_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_ACTIVATE_ROLE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('RoleToastsNotifications:DEACTIVATE_ACTIVATE_ROLE_ERROR_MESSAGE'),
});

export const FULL_PERMISSION_ACTION_LIST = [
  PermissionAction.READ,
  PermissionAction.CREATE,
  PermissionAction.EDIT,
  PermissionAction.DELETE,
];

export const DEFAULT_ROLE_DATA = {
  __typename: 'Role' as const,
  id: '',
  tenantId: '',
  name: t('Roles:NEW_ROLE'),
  isCustom: true,
  isActive: true,
  hasChromeAccess: true,
  users: [PermissionAction.READ],
  availability: FULL_PERMISSION_ACTION_LIST,
  myBookingPages: FULL_PERMISSION_ACTION_LIST,
  allBookingPages: [PermissionAction.READ],
  bookingTemplates: [PermissionAction.READ],
  resources: [PermissionAction.READ],
  teams: [PermissionAction.READ],
  isAccessToAllWorkspaces: false,
  workspaceAdminActions: [],
  createdAt: '',
  updatedAt: '',
};

export const PERMISSION_LIST = [
  'users',
  //  'availability',
  'myBookingPages',
  'allBookingPages',
  'bookingTemplates',
  'resources',
  'teams',
] as const;

export const FULL_PERMISSION_LIST = [...PERMISSION_LIST, 'workspaceAdminActions'] as const;

export const DISABLED_PERMISSIONS = [
  { permission: 'availability', action: [PermissionAction.READ] },
  { permission: 'myBookingPages', action: [PermissionAction.READ, PermissionAction.CREATE] },
  { permission: 'bookingTemplates', action: [PermissionAction.READ] },
];
