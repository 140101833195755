import { useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { WhereStep } from '../../common/meeting/whereStep/WhereStep';

export const BookingPageWhereStep = () => {
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);
  return (
    <WhereStep
      selectors={bookingPageSelectors}
      actions={bookingPagesActions}
      isReadOnly={isBookingPageLocked || hasTemplate}
    />
  );
};
