export const EditRole = Object.freeze({
  ACCESS_TO_ALL_WORKSPACES: `Access to all workspaces and Roles`,
  ACTIVE: `Active`,
  ADMIN_TITLE: `Admin`,
  MY_BOOKING_PAGE: `My Booking Pages`,
  ALL_BOOKING_PAGE: `All Booking Pages`,
  BOOKING_TEMPLATES: `Booking Templates`,
  CHROME_EXTENSION_TITLE: `SUMO1 Inbox (Chrome Extension)`,
  CREATE: `Create`,
  DELETE: `Delete`,
  EDIT: `Edit`,
  FEATURE_ACCESS_TITLE: `Feature Access`,
  PERMISSIONS_TITLE: `Permissions`,
  PRIMARY_TITLE: `Primary`,
  READ: `Read`,
  RESOURCES_TITLE: `Resources(Locations, Rooms)`,
  ROLE_NAME: `Role Name`,
  SEARCH_ADD_WORKSPACE: `Search & Add Workspace`,
  STANDARD_USER: `Standard User`,
  SUPER_ADMIN: `Super Admin`,
  TEAMS: `Teams`,
  EDIT_TITLE: 'Edit Role',
  NEW_TITLE: 'New Role',
  WORKSPACE_ADMIN: `Workspace Admin`,
  WORKSPACE_ADMIN_DESCRIPTION: `By default any admin with this role may admin all workspaces, unless you specify below specific workspaces they may only admin:`,
});

export const RoleToastsNotifications = Object.freeze({
  ACTIVATE_ROLE_SUCCESS_MESSAGE: `Role activated`,
  DEACTIVATE_ACTIVATE_ROLE_ERROR_MESSAGE: `Role activate/inactive request fail`,
  DEACTIVATE_ROLE_SUCCESS_MESSAGE: `Role deactivated`,
  DELETE_ROLE_ASSIGNED_ERROR_MESSAGE: `Can not be deleted. Users are assigned to this role.`,
  DELETE_ROLE_ERROR_MESSAGE: `Delete role request fail`,
  DELETE_ROLE_SUCCESS_MESSAGE: `Role successfully deleted`,
  GET_ROLES_FAIL_MESSAGE: `Get roles request fail`,
  SAVE_ROLE_ERROR_MESSAGE: `Save role request fail`,
  SAVE_ROLE_SUCCESS_MESSAGE: `Role successfully saved`,
});

export const Roles = Object.freeze({
  ACTIVE: `Active`,
  CUSTOM: `Custom`,
  DEACTIVATE_DESCRIPTION: `Are you sure you would like to deactivate`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  DELETE_MESSAGE: `Users will not be deleted, but may lose access requiring a role assignment.`,
  LAST_MODIFIED: `Last Modified`,
  LAST_MODIFIED_BY: `Last Modified By`,
  NEW_ROLE: `New Role`,
  ROLE: `Role`,
  TOOLTIP: `Manage Roles to give users permissions to view, create/edit, or delete various features`,
});
