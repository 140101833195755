import { t } from 'i18next';
import { WeekDay, UserRecordType, AvailabilityInput } from '../../API';
import { ToastNotificationOptions } from '../notifications';
import { UserDataInputCreatedAt } from '../global/types';
import { SelectItemOptionsType } from 'primereact/selectitem';

export const CLONE_NAME_POSTFIX = ' (clone)';
export const DEFAULT_AVAILABILITY_TIME = { from: '09:00', to: '17:00' };

export const DEFAULT_AVAILABILITY_DATA: AvailabilityInput = {
  id: '',
  name: 'New Schedule',
  isDefault: false,
  weeklyHours: [
    { type: WeekDay.SUN, time: [] },
    { type: WeekDay.MON, time: [DEFAULT_AVAILABILITY_TIME] },
    { type: WeekDay.TUE, time: [DEFAULT_AVAILABILITY_TIME] },
    { type: WeekDay.WED, time: [DEFAULT_AVAILABILITY_TIME] },
    { type: WeekDay.THU, time: [DEFAULT_AVAILABILITY_TIME] },
    { type: WeekDay.FRI, time: [DEFAULT_AVAILABILITY_TIME] },
    { type: WeekDay.SAT, time: [] },
  ],
};

export const DEFAULT_AVAILABILITY: UserDataInputCreatedAt = {
  userId: '',
  tenant: '',
  link: '',
  recordType: UserRecordType.AVAILABILITY,
  availabilityData: DEFAULT_AVAILABILITY_DATA,
  createdAt: '',
};

export const WEEKDAY_LABEL_BY_TYPE = new Map<WeekDay, string>([
  [WeekDay.SUN, t('AvailabilityWeekDays:SUNDAY_SHORT')],
  [WeekDay.MON, t('AvailabilityWeekDays:MONDAY_SHORT')],
  [WeekDay.TUE, t('AvailabilityWeekDays:TUESDAY_SHORT')],
  [WeekDay.WED, t('AvailabilityWeekDays:WEDNESDAY_SHORT')],
  [WeekDay.THU, t('AvailabilityWeekDays:THURSDAY_SHORT')],
  [WeekDay.FRI, t('AvailabilityWeekDays:FRIDAY_SHORT')],
  [WeekDay.SAT, t('AvailabilityWeekDays:SATURDAY_SHORT')],
]);

export const WEEKDAY_OPTIONS: SelectItemOptionsType = [
  { value: WeekDay.SUN, label: WEEKDAY_LABEL_BY_TYPE.get(WeekDay.SUN)},
  { value: WeekDay.MON, label: WEEKDAY_LABEL_BY_TYPE.get(WeekDay.MON)},
  { value: WeekDay.TUE, label: WEEKDAY_LABEL_BY_TYPE.get(WeekDay.TUE)},
  { value: WeekDay.WED, label: WEEKDAY_LABEL_BY_TYPE.get(WeekDay.WED)},
  { value: WeekDay.THU, label: WEEKDAY_LABEL_BY_TYPE.get(WeekDay.THU)},
  { value: WeekDay.FRI, label: WEEKDAY_LABEL_BY_TYPE.get(WeekDay.FRI)},
  { value: WeekDay.SAT, label: WEEKDAY_LABEL_BY_TYPE.get(WeekDay.SAT)},
];

export const GET_AVAILABILITY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AvailabilityToastsNotifications:GET_AVAILABILITY_ERROR_MESSAGE'),
});
export const SAVE_AVAILABILITY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('AvailabilityToastsNotifications:SAVE_AVAILABILITY_SUCCESS_MESSAGE'),
});
export const SAVE_AVAILABILITY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AvailabilityToastsNotifications:SAVE_AVAILABILITY_ERROR_MESSAGE'),
});
export const DELETE_AVAILABILITY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('AvailabilityToastsNotifications:DELETE_AVAILABILITY_SUCCESS_MESSAGE'),
});
export const DELETE_AVAILABILITY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AvailabilityToastsNotifications:DELETE_AVAILABILITY_ERROR_MESSAGE'),
});

export const SET_DEFAULT_AVAILABILITY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AvailabilityToastsNotifications:SET_DEFAULT_AVAILABILITY_ERROR_MESSAGE'),
});
export const SET_DEFAULT_AVAILABILITY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('AvailabilityToastsNotifications:SET_DEFAULT_AVAILABILITY_SUCCESS_MESSAGE'),
});

export const CREATE_AVAILABILITY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AvailabilityToastsNotifications:CREATE_AVAILABILITY_ERROR_MESSAGE'),
});
export const CREATE_AVAILABILITY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('AvailabilityToastsNotifications:CREATE_AVAILABILITY_SUCCESS_MESSAGE'),
});
export const LIMIT_30_ITEMS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AvailabilityToastsNotifications:LIMIT_30_ITEMS'),
});