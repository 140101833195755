import { NavigationsRecord, NavigationState, Step } from './typings';
import { checkNavigation } from './utils';
import { NavigationsActionTypes, NavigationsAction } from './actions';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { INITIAL_CURRENT_STEP, INITIAL_NAVIGATION, INITIAL_NAVIGATIONS_RECORD, INITIAL_STEPS } from './constants';


const currentStep = (state: number = 0, action: NavigationsAction | GlobalAction) => {
  switch (action.type) {
    case NavigationsActionTypes.SET_CURRENT_STEP:
      return action.stepIndex;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return INITIAL_CURRENT_STEP;
    default:
      return state;
  }
};

const steps = (state: Step[] = INITIAL_STEPS, action: NavigationsAction | GlobalAction) => {
  switch (action.type) {
    case NavigationsActionTypes.UPDATE_STEP:
      return state.map((step) => (step.name === action.step.name ? { ...step, ...action.step } : step));
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [...INITIAL_STEPS];
    default:
      return state;
  }
};

const navigation = (state: NavigationState = INITIAL_NAVIGATION, action: NavigationsAction | GlobalAction) => {
  switch (action.type) {
    case NavigationsActionTypes.SET_CURRENT_STEP:
      return { ...state, currentStep: currentStep(state.currentStep, action) };
    case NavigationsActionTypes.UPDATE_STEP:
      return { ...state, navigationDisabled: action.step.isError || false, steps: steps(state.steps, action) };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return { ...INITIAL_NAVIGATION };
    default:
      return state;
  }
};

export default (state: NavigationsRecord = INITIAL_NAVIGATIONS_RECORD, action: NavigationsAction | GlobalAction) => {
  switch (action.type) {
    case NavigationsActionTypes.INITIALIZE_NAVIGATION:
      return checkNavigation(state, action.payload);
    case NavigationsActionTypes.DELETE_NAVIGATION:
      const newState = { ...state };
      delete newState[action.key];
      return newState;
    case NavigationsActionTypes.SET_CURRENT_STEP:
    case NavigationsActionTypes.UPDATE_STEP:
      return { ...state, [action.key]: navigation(state[action.key], action) };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return { ...INITIAL_NAVIGATIONS_RECORD };
    default:
      return state;
  }
};
