import { CONFIRM_USERS_MODAL_NAME, confirmUsersModalActions } from '../../../store/users/modal';
import { ConfirmationModal } from '../../common';
import labels from './labels';
import { UserStatus } from '../../../store/users/typings';
import { useDispatch } from 'react-redux';
import { usersActions, usersSelectors } from '../../../store/users';
import { useEffect } from 'react';
import { modalsActions, modalsSelectors } from '../../../store/modals';
import { useSelector } from 'react-redux';

type ConfirmDialogProps = {
  isDisableUser: boolean;
  isEditConfirm: boolean;
  setSearchString: (value: string) => void;
};

export const ConfirmDialog = ({ isDisableUser, isEditConfirm, setSearchString }: ConfirmDialogProps) => {
  const dispatch = useDispatch();
  const visible = useSelector(modalsSelectors.selectIsModalOpen(CONFIRM_USERS_MODAL_NAME));
  const user = useSelector(usersSelectors.selectSelectedUser);

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: CONFIRM_USERS_MODAL_NAME }));
    return () => {
      dispatch(modalsActions.deleteModal(CONFIRM_USERS_MODAL_NAME));
    };
  }, []);

  const handleAgreement = () => {
    if (isEditConfirm) {
      dispatch(usersActions.editUserRecordRequest(false)); // role didn't changed
      dispatch(confirmUsersModalActions.closeModal());
    } else if (isDisableUser) {
      dispatch(usersActions.updateUserRecord({ status: UserStatus.Inactive }));
      dispatch(usersActions.disableUserRecordRequest());
    } else {
      dispatch(usersActions.deleteUserRecordRequest());
      setSearchString(''); // clear the search value
    }
  };

  const handleClose = () => {
    dispatch(confirmUsersModalActions.closeModal());
  };

  return (
    <ConfirmationModal
      visible={visible}
      title={isDisableUser ? labels.confirmDisableTitle : labels.confirmDeleteTitle}
      description={`${isDisableUser ? labels.confirmDisableDesc : labels.confirmDeleteDesc}
      "${user.fullName ? user.fullName : user.email}"?`}
      additionalText={[
        isDisableUser ? labels.confirmDisableCommentF : labels.confirmDeleteCommentF,
        labels.confirmDisableCommentS,
      ]}
      confirmButtonLabel={labels.yes}
      cancelButtonLabel={labels.no}
      onConfirm={handleAgreement}
      onCancel={handleClose}
      onClose={handleClose}
      ExtraElements={
        !isDisableUser && (
          <div className="mt-3 text-color text-sm ">
            <div>{labels.confirmDeleteNoteTitle}</div>
            <div>{labels.confirmDeleteNoteDesc}</div>
          </div>
        )
      }
    />
  );
};
