import { Button } from 'primereact/button';
import { COLORS } from '../../../store/bookingTemplates';
import labels from './labels';

type ColorPickerListProps = {
  color: string | null | undefined;
  handleColorChange: (color: string) => void;
  listClassName?: string;
};
export const ColorPickerList = ({ color, handleColorChange, listClassName }: ColorPickerListProps) => {
  return (
    <>
      <label htmlFor="meetingTypeColor" className="pb-2">
        {labels.colorPickerLabel}
      </label>
      <div className={`flex flex-wrap justify-content-between ${listClassName}`}>
        {COLORS.map((item) => (
          <div key={item}>
            <Button
              rounded
              icon={item === color ? 'pi pi-check' : 'pi'}
              style={{ backgroundColor: item, borderColor: item }}
              onClick={() => handleColorChange(item)}
            />
          </div>
        ))}
      </div>
    </>
  );
};
