export const DefaultBookingTemplate = Object.freeze({
  NAME: `Réunion de 30 Minutes`,
  CONFIRMATION_SUBJECT: 'Nouvelle réunion : <Meeting Name> avec <Host Name> à <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Reprogrammé : <Meeting Name> avec <Host Name> à <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Rappel : <Meeting Name> avec <Host Name> à <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Annulé : <Meeting Name> avec <Host Name> à <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Suivi : <Meeting Name> avec <Host Name>',
  CONFIRMATION_BODY: `Bonjour <Invitee Name>,

Votre nouvelle réunion <Meeting Name> avec <Host Name> à <Meeting Time>, <Meeting Date> est prévue.

<b>Lieu</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Bonjour <Invitee Name>,

Votre réunion <Meeting Name> avec <Host Name> a été reprogrammée.

<b>Heure et Date mises à jour</b>
<Meeting Time>, <Meeting Date>

<b>Lieu</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Bonjour <Invitee Name>,

Ceci est un rappel amical que votre <Meeting Name> avec <Host Name> est à <Meeting Time>, <Meeting Date>.

<b>Lieu</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Bonjour <Invitee Name>,

Votre réunion <Meeting Name> avec <Host Name> à <Meeting Time>, <Meeting Date> a été annulée.`,
  FOLLOW_UP_BODY: `Bonjour <Invitee Name>,

Nous espérons que votre réunion "<Meeting Name>" avec <Host Name> a été productive.

Si vous avez des commentaires ou avez besoin d'une assistance supplémentaire, n'hésitez pas à nous contacter.`,
  SMS_TEXT:
    'Ceci est un rappel amical que votre <Meeting Name> avec <Host Name> est à <Meeting Time>, <Meeting Date>. Voir ou modifier la réunion <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `Nouveau modèle`,
  TITRE: `Modèles de réservation`,
  VIEW_DETAILS: `Voir les détails`,
  MINUTE: `min`,
  HOUR: `h`,
  CLONE_MODAL_TITLE: `Modèle de réservation de clonage`,
  DELETE_MODAL_CONFIRM_TEXT: `Êtes-vous sûr de vouloir supprimer ce modèle de réservation ?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `Êtes-vous sûr de vouloir supprimer ces modèles de réservation ?`,
  DELETE_MODAL_USED_PAGES_TEXT: `Vous devez d'abord supprimer ces modèles de réservation des pages de réservation suivantes:`,
  FILTER_MY_TEMPLATES: `Mes modèles`,
  FILTER_ALL_TEMPLATES: `Tous les modèles`,
  TOOLTIP: `Créer des modèles de réservation pour les pages de réservation.`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Échec du modèle de réservation de clonage`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Modèle de réservation cloné avec succès`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Échec de la suppression du modèle de réservation`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Modèle de réservation supprimé avec succès`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `Échec de l'obtention du modèle de réservation`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Échec de l'enregistrement du modèle de réservation`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Modèle de réservation enregistré avec succès`,
});

export const EditBookingTemplate = Object.freeze({
  ADDITIONAL_TITLE: `Options supplémentaires`,
  WHAT_TITLE_PART_1: `Quel`,
  WHAT_TITLE_PART_2: ` événement est-ce?`,
  WHO_TITLE_PART_1: `Qui`,
  WHO_TITLE_PART_2: `peut assister ?`,
  WHERE_TITLE_PART_1: `Où`,
  WHERE_TITLE_PART_2: ` se déroulera cet événement?`,
  WHEN_TITLE_PART_1: `Quand`,
  WHEN_TITLE_PART_2: ` les gens peuvent-ils réserver cet événement?`,
  INVITEE_TITLE_PART_1: `Invite `,
  INVITEE_TITLE_PART_2: `Questions`,
  INVITEE_SUBTITLE: `Nom, E-mail, Questions personnalisées`,
  NOTIFICATIONS_TITLE_PART_1: `Notifications`,
  NOTIFICATIONS_TITLE_PART_2: ``,
  CONFIRMATION_TITLE_PART_1: `Confirmation`,
  CONFIRMATION_TITLE_PART_2: ` Page`, // et politique d'annulation
  LOCKED_TOOLTIP: `Enregistrement verrouillé. Vous n'avez pas les autorisations pour modifier. Dernière modification par :`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  TEAMS: `Équipes potentielles`,
  HOSTS: `Hôtes`,
  HOSTS_TOOLTIP: `Sélectionnez un ou plusieurs hôtes pour ce modèle de réservation. Si vous sélectionnez plusieurs hôtes, ils seront ajoutés à la réunion réservée.`,
  RESCHEDULE_WITH: `Reportez avec`,
  RESCHEDULE_WITH_TOOLTIP: `Si l'invité reporte la réunion, avec qui SUMO doit-il les replanifier:`,
  ASSIGNED_HOST: `Hôte assigné`,
  ROUND_ROBIN_TO_NEW_HOST: `Round-Robin vers un nouvel hôte`,
  POTENTIAL_HOSTS: `Hôtes potentiels`,
  HOST_VIDEO_CONFERENCE: `Conférence vidéo de l'hôte`,
  HOST_LIST_REQUIREMENTS: `Vous devez être dans la liste des hôtes, vous n'avez pas la permission pour toutes les pages de réservation.`,
  HOSTS_LABEL: `Choisissez une personne, plusieurs personnes ou une équipe (où un membre sera sélectionné via le Round-Robin) pour assister à cette réunion.`,
  HOSTS_PLACEHOLDER: `Rechercher des hôtes ou des équipes`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Sélectionnez une ou plusieurs options à présenter à l'invité lors de la réservation automatique sur la page de réservation:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Passer l'étape "Où" pour les réunions virtuelles`,
  SKIP_WHERE_STEP_DESCRIPTION: `Le lien de la vidéoconférence sera automatiquement ajouté à la réunion.`,
  PHONE_CALL: `Appel téléphonique`,
  HOSTS_PHONE: `Numéro de téléphone de l'hôte`,
  HOSTS_PHONE_DESCRIPTION: `Le numéro de téléphone de l'hôte sera automatiquement ajouté à la réunion.`,
  HOSTS_PHONE_NOTIFICATION: `Un ou plusieurs hôtes n'ont pas de numéro de téléphone`,
  INVITEE_PHONE: `Demander à l'invité d'entrer son numéro de téléphone`,
  INVITEE_PHONE_DESCRIPTION: `L'invité sera invité à ajouter son numéro de téléphone.`,
  CUSTOM_PHONE: `Numéro de téléphone personnalisé`,
  CUSTOM_PHONE_PLACEHOLDER: `Entrer le numéro de téléphone`,
  CUSTOM_PHONE_DESCRIPTION: `Ce numéro de téléphone sera automatiquement ajouté à la réunion.`,
  IN_PERSON: `En personne`,
  CUSTOM_ADDRESS: `Adresse personnalisée`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Entrer l'adresse de la réunion`,
  CUSTOM_ADDRESS_DESCRIPTION: `Cette adresse sera automatiquement ajoutée à la réunion.`,
  LOCATION: `Emplacement de l'entreprise`,
  LOCATION_DESCRIPTION: `L'invité sera invité à choisir l'un de ces emplacements.`,
  INVITEE_LOCATION: `Emplacement de l'invité`,
  INVITEE_LOCATION_DESCRIPTION: `L'invité sera invité à ajouter une adresse de réunion.`,
  DEFAULT_LOCATION: 'Emplacement par défaut',
  VIDEO_CONFERENCE: `Conférence vidéo`,
  VIDEO_CONFERENCE_NOTIFICATION: `Un ou plusieurs hôtes n'ont aucune conférence vidéo`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Nom et prénom`,
  EMAIL_LABEL: `E-mail`,
  ADD_QUESTION_BUTTON: `Ajouter une nouvelle question`,
  QUESTION_TITLE_NEW: `Nouvelle question`,
  QUESTION_TITLE_EDIT: `Modifier la question`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  DESCRIPTION: `Ajoutez une question qui sera affichée sur la page de réservation pour que l'invité la remplisse lors de la réservation du rendez-vous.`,
  QUESTION_LABEL: `Question`,
  REQUIRED_LABEL: `Requis`,
  ACTIVE_LABEL: `Actif`,
  TYPE_LABEL: `Type de réponse`,
  TYPE_CHECKBOXES: `Cases à cocher`,
  TYPE_RADIO_BUTTONS: `Boutons radio`,
  TYPE_SELECT: `Menu déroulant`,
  TYPE_TEL: `Numéro de téléphone`,
  TYPE_TEXT: `Ligne unique`,
  TYPE_TEXTAREA: `Plusieurs lignes`,
  ANSWERS_LABEL: `Réponses`,
  ANSWERS_SINGLE_DESCRIPTION_1: `L'invité peut sélectionner `,
  ANSWERS_SINGLE_DESCRIPTION_2: `une seule`,
  ANSWERS_SINGLE_DESCRIPTION_3: ` parmi les options suivantes`,
  ANSWERS_MULTIPLE_DESCRIPTION_1: `L'invité peut sélectionner une ou `,
  ANSWERS_MULTIPLE_DESCRIPTION_2: `plusieurs`,
  ANSWERS_MULTIPLE_DESCRIPTION_3: ` parmi les options suivantes`,
  ANSWER_PLACEHOLDER: `Réponse`,
  ADD_ANSWER_BUTTON: `Ajouter une réponse`,
  DELETE_BUTTON: `Supprimer`,
  CANCEL_BUTTON: `Annuler`,
  SAVE_BUTTON: `Enregistrer`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `Confirmations par e-mail`,
  CONFIRMATION_DESCRIPTION: `Un rendez-vous sera toujours créé dans le calendrier de l'hôte SUMO1 et son invité sera ajouté en tant que participant.
En activant cette fonctionnalité, SUMO1 enverra également une confirmation par e-mail.`,
  RESCHEDULE_TITLE: `Replanification d'e-mail`,
  RESCHEDULE_DESCRIPTION: `L'invité recevra une notification par e-mail de toute modification apportée à sa réunion.`,
  REMINDER_TITLE: `Rappels par e-mail`,
  REMINDER_DESCRIPTION: `L'invité recevra un e-mail de rappel avant le rendez-vous réservé.`,
  CANCELATION_TITLE: `Annulations par e-mail`,
  CANCELATION_DESCRIPTION: `Une notification par e-mail sera envoyée à votre invité si vous annulez le rendez-vous.`,
  FOLLOW_UP_TITLE: `Suivi par e-mail`,
  FOLLOW_UP_DESCRIPTION: `Automatisez le suivi des prochaines étapes avec un e-mail envoyé après le rendez-vous.`,
  SMS_TITLE: `Rappel par SMS`,
  SMS_DESCRIPTION: `L'invité aura la possibilité de recevoir des rappels par SMS avant un rendez-vous réservé.`,
  SMS_BODY: `SMS Corps du message`,
  SMS_LABEL_PART1: `États-Unis et Canada uniquement`,
  SMS_LABEL_PART2: `Actuellement, les rappels par SMS sont gratuits et uniquement disponibles pour les numéros de téléphone des invités aux États-Unis et au Canada.`,
  PERSONALIZE: `Personnaliser`,
  EMAIL_SUBJECT: `Objet de l'e-mail`,
  EMAIL_BODY: `Corps de l'e-mail`,
  MERGE_VARIABLES: `Fusionner les variables`,
  TIMING: `Timing`,
  RESET: `Réinitialiser`,
  ADD_ANOTHER_REMINDER: `Ajouter un autre rappel`,
  CANCELATION_POLICY: `Politique d'annulation`,
  CANCELATION_POLICY_TOOLTIP: `Tout texte que vous saisissez ici apparaîtra dans toutes les notifications par e-mail envoyées à l'invité.`,
  TIME_UNIT_DAYS_BEFORE: `jour(s) avant`,
  TIME_UNIT_HOURS_BEFORE: `heure(s) avant`,
  TIME_UNIT_MINUTES_BEFORE: `minute(s) avant`,
  TIME_UNIT_DAYS_AFTER: `jour(s) après`,
  TIME_UNIT_HOURS_AFTER: `heure(s) après`,
  TIME_UNIT_MINUTES_AFTER: `minute(s) après`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `Après la réservation`,
  TYPE_DISPLAY_SUMO: `Afficher la page de confirmation SUMO`,
  TYPE_DISPLAY_EXTERNAL: `Rediriger vers une page externe`,
  EXTERNAL_LINK_LABEL: `URL de redirection externe`,
  EXTERNAL_LINK_PLACEHOLDER: `https://redirection-ici.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Afficher le bouton "Réserver un autre rendez-vous"`,
  ALLOW_RESCHEDULE_LABEL: `Autoriser le report`,
  ALLOW_CANCEL_LABEL: `Autoriser l'annulation`,
  ADD_CUSTOM_LINK_LABEL: `Ajouter un lien personnalisé`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Ajoutez un lien personnalisé à la page de confirmation, comme un lien vers une enquête.`,
  ADD_CUSTOM_LINK_TITLE: `Ajouter un lien personnalisé`,
  EDIT_CUSTOM_LINK_TITLE: `Modifier le lien personnalisé`,
  CUSTOM_LINK_LABEL: `Libellé du lien personnalisé`,
  CUSTOM_LINK_PLACEHOLDER: `Lien exemple`,
  LINK_URL_LABEL: `URL du lien`,
  LINK_URL_PLACEHOLDER: `https://exemple-lien.com`,
});
