import { memo, useEffect, useRef, useState } from 'react';
import { MessageTemplateInput, TimeInput, TimeUnit } from '../../../../API';
import {
  DEFAULT_SMS_TIMING,
  DEFAULT_INPUT_SMS_FIELD,
  FOLLOW_UP_TIME_UNIT_OPTIONS,
  MAX_SMS_REMINDERS_COUNT,
  MERGE_FIELD_OPTIONS,
  NotificationTypes,
  REMINDER_TIME_UNIT_OPTIONS,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  getDefaultBodyByNotificationType,
  getDefaultSubjectByNotificationType,
} from '../../../../store/bookingTemplates';
import { Nullable } from 'primereact/ts-helpers';
import labels from './labels';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

type NotificationItemProps = {
  type: NotificationTypes;
  notification: MessageTemplateInput;
  timerDuplicateIndexes?: number[];
  onChange: (value: MessageTemplateInput) => void;
  disabled?: boolean;
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
};

export const NotificationItem = memo(
  ({ type, notification, timerDuplicateIndexes, onChange, disabled, selectors, actions }: NotificationItemProps) => {
    const dispatch = useDispatch();
    const subjectRef = useRef<HTMLInputElement>(null);
    const bodyRef = useRef<HTMLTextAreaElement>(null);
    const [cursorPosition, setCursorPosition] = useState<Nullable<number>>(undefined);
    const [subjectOrBody, setSubjectOrBody] = useState(false);

    const [subjectValue, setSubjectValue] = useState(notification.subject);
    const [bodyValue, setBodyValue] = useState(notification.body);

    const isSubjectValid = !notification.enabled || type === NotificationTypes.SMS || Boolean(notification.subject);
    const isBodyValid = !notification.enabled || Boolean(notification.body);
    const hasTiming =
      type === NotificationTypes.REMINDER || type === NotificationTypes.FOLLOW_UP || type === NotificationTypes.SMS;
    const hasAddTimingButton =
      hasTiming && type !== NotificationTypes.FOLLOW_UP && (notification.timing?.length || 0) < 3;
    const isTimingValid = !notification.enabled || !hasTiming || !timerDuplicateIndexes?.length;

    const phoneInputField = useSelector(selectors.selectPhoneInputFields);
    const smsReminderOptionEnabled = useSelector(selectors.selectSmsReminderOptionEnabled);
    const IsPromptInviteCallType = useSelector(selectors.selectIsPromptInviteCallType);

    useEffect(() => {
      // if we enable SMS reminder let's add required custom filed "Phone number"
      if (type === NotificationTypes.SMS) {
        if (smsReminderOptionEnabled && !phoneInputField) {
          dispatch(actions.addCustomField(DEFAULT_INPUT_SMS_FIELD));
        } else if (!smsReminderOptionEnabled && phoneInputField && phoneInputField.id && !IsPromptInviteCallType) {
          dispatch(actions.removeCustomField(phoneInputField.id));
        }
      }
    }, [smsReminderOptionEnabled, type, phoneInputField]);

    const timers =
      notification.timing?.map((time) => {
        const timeUnit = !time || time % 1440 === 0 ? TimeUnit.DAY : time % 60 === 0 ? TimeUnit.HOUR : TimeUnit.MINUTE;
        const count = !time
          ? 0
          : timeUnit === TimeUnit.DAY
          ? time / 1440
          : timeUnit === TimeUnit.HOUR
          ? time / 60
          : time;
        return { count, timeUnit } as TimeInput;
      }) || [];

    const convertTimeInputToTiming = (timeInput: TimeInput) =>
      !timeInput.count
        ? 0
        : timeInput.count *
          (timeInput.timeUnit === TimeUnit.DAY ? 1440 : timeInput.timeUnit === TimeUnit.HOUR ? 60 : 1);

    const handleEnabledChange = (value: boolean) => {
      onChange({ ...notification, enabled: value });
    };

    const handleSubjectChange = (value: string) => {
      setSubjectValue(value);
    };

    const handleSubjectBlur = () => {
      onChange({ ...notification, subject: subjectValue });
      setCursorPosition(subjectRef.current?.selectionStart);
      setSubjectOrBody(true);
    };

    const handleBodyChange = (value: string) => {
      setBodyValue(value);
    };

    const handleBodyBlur = () => {
      onChange({ ...notification, body: bodyValue });
      setCursorPosition(bodyRef.current?.selectionStart);
      setSubjectOrBody(false);
    };

    const handleResetSubject = () => {
      const subject = getDefaultSubjectByNotificationType(type);
      setSubjectValue(subject);
      onChange({ ...notification, subject });
    };

    const handleResetBody = () => {
      const body = getDefaultBodyByNotificationType(type);
      setBodyValue(body);
      onChange({ ...notification, body });
    };

    const handleTimingValueChange = (value: Nullable<number>, index: number) => {
      onChange({
        ...notification,
        timing: [
          ...(notification.timing || []).slice(0, index),
          convertTimeInputToTiming({ ...timers[index], count: value }),
          ...(notification.timing || []).slice(index + 1),
        ],
      });
    };

    const handleTimeUnitChange = (value: TimeUnit, index: number) => {
      onChange({
        ...notification,
        timing: [
          ...(notification.timing || []).slice(0, index),
          convertTimeInputToTiming({ ...timers[index], timeUnit: value }),
          ...(notification.timing || []).slice(index + 1),
        ],
      });
    };

    const handleAddTiming = () => {
      if ((notification.timing || []).length >= MAX_SMS_REMINDERS_COUNT) {
        return;
      }
      onChange({
        ...notification,
        timing: [...(notification.timing || []), DEFAULT_SMS_TIMING],
      });
    };

    const handleRemoveTiming = (index: number) => {
      onChange({
        ...notification,
        timing: [...(notification.timing || []).slice(0, index), ...(notification.timing || []).slice(index + 1)],
      });
    };

    const addMergeVariable = (value: string) => {
      if (!cursorPosition) {
        return;
      }

      const mergeValue = '<' + value + '>';
      const newCursorPosition = cursorPosition + mergeValue.length;

      if (subjectOrBody) {
        const subject =
          subjectValue?.substring(0, cursorPosition) + mergeValue + subjectValue?.substring(cursorPosition);
        setSubjectValue(subject);
        subjectRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
        onChange({ ...notification, subject });
      } else {
        const body = bodyValue?.substring(0, cursorPosition) + mergeValue + bodyValue?.substring(cursorPosition);
        setBodyValue(body);
        bodyRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
        onChange({ ...notification, body });
      }

      setCursorPosition(newCursorPosition);
    };

    const getIconClassNameByType = (type: NotificationTypes) => {
      switch (type) {
        case NotificationTypes.CONFIRMATION:
          return 'pi pi-check text-3xl text-green-500';
        case NotificationTypes.RESCHEDULE:
          return 'pi pi-clock text-3xl text-green-500';
        case NotificationTypes.REMINDER:
          return 'pi pi-bell text-3xl text-blue-500';
        case NotificationTypes.CANCELATION:
          return 'pi pi-times text-3xl text-pink-500';
        case NotificationTypes.FOLLOW_UP:
          return 'pi pi-reply text-3xl text-purple-500';
        case NotificationTypes.SMS:
          return 'pi pi-comment text-3xl ';
      }
    };

    const getTitleByType = (type: NotificationTypes) => {
      switch (type) {
        case NotificationTypes.CONFIRMATION:
          return labels.confirmationTitle;
        case NotificationTypes.RESCHEDULE:
          return labels.rescheduleTitle;
        case NotificationTypes.REMINDER:
          return labels.reminderTitle;
        case NotificationTypes.CANCELATION:
          return labels.cancelationTitle;
        case NotificationTypes.FOLLOW_UP:
          return labels.followUpTitle;
        case NotificationTypes.SMS:
          return labels.smsTitle;
      }
    };

    const getDescriptionByType = (type: NotificationTypes) => {
      switch (type) {
        case NotificationTypes.CONFIRMATION:
          return labels.confirmationDescription;
        case NotificationTypes.RESCHEDULE:
          return labels.rescheduleDescription;
        case NotificationTypes.REMINDER:
          return labels.reminderDescription;
        case NotificationTypes.CANCELATION:
          return labels.cancelationDescription;
        case NotificationTypes.FOLLOW_UP:
          return labels.followUpDescription;
        case NotificationTypes.SMS:
          return labels.smsDescription;
      }
    };

    const panelHeaderTemplate = (options: PanelHeaderTemplateOptions) => {
      const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
      return (
        <div
          className="flex align-items-center cursor-pointer w-fit text-primary hover:text-primary-700"
          onClick={options.onTogglerClick}
        >
          <i className={toggleIcon}></i>
          <span className="ml-2">{options.props.header}</span>
        </div>
      );
    };

    return (
      <div className="grid grid-wrap gap-1 mb-3">
        <div className="col-fixed">
          <InputSwitch
            checked={Boolean(notification.enabled)}
            onChange={(e) => handleEnabledChange(Boolean(e.value))}
            disabled={disabled}
          />
        </div>
        <div className="col-fixed">
          <i className={getIconClassNameByType(type)}></i>
        </div>
        <div className="col-12 md:col">
          <div className="font-bold">{getTitleByType(type)}</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{getDescriptionByType(type)}</div>
          <Panel
            className="mt-1"
            pt={{ content: { className: 'border-0 px-0' } }}
            header={labels.personalize}
            headerTemplate={panelHeaderTemplate}
            toggleable
            collapsed={isSubjectValid && isBodyValid && isTimingValid}
          >
            <div className="grid">
              <div className="col-12 md:col-8 flex flex-column justify-content-between">
                {type !== NotificationTypes.SMS && (
                  <div>
                    <div className="flex justify-content-between mb-1">
                      <div className="font-semibold">{labels.emailSubject}</div>
                      <div
                        className={`text-primary ${disabled ? '' : 'cursor-pointer hover:text-primary-700'}`}
                        onClick={disabled ? undefined : handleResetSubject}
                      >
                        {labels.reset}
                      </div>
                    </div>
                    <InputText
                      ref={subjectRef}
                      type="text"
                      value={subjectValue || ''}
                      onChange={(e) => handleSubjectChange(e.target.value.trimStart())}
                      onBlur={handleSubjectBlur}
                      className={`${!isSubjectValid && 'p-invalid'}`}
                      maxLength={80}
                      disabled={disabled}
                    />
                  </div>
                )}
                <div className="flex flex-column mt-3">
                  <div className="flex justify-content-between mb-1">
                    <div className="font-semibold">
                      {type === NotificationTypes.SMS ? labels.smsBody : labels.emailBody}
                    </div>
                    <div
                      className={`text-primary ${disabled ? '' : 'cursor-pointer hover:text-primary-700'}`}
                      onClick={disabled ? undefined : handleResetBody}
                    >
                      {labels.reset}
                    </div>
                  </div>
                  <InputTextarea
                    ref={bodyRef}
                    autoResize
                    rows={type === NotificationTypes.SMS ? 4 : 10}
                    value={bodyValue || ''}
                    onChange={(e) => handleBodyChange(e.target.value.trimStart())}
                    onBlur={handleBodyBlur}
                    className={`${!isBodyValid && 'p-invalid'}`}
                    maxLength={2000}
                    disabled={disabled}
                  />
                </div>
                {hasTiming && (
                  <div className="flex flex-column">
                    <div className="mt-2 mb-1 font-semibold">{labels.timing}</div>
                    {timers.map((time, index, { length }) => (
                      <div key={index} className="grid align-items-center">
                        <div className="col-5 md:col-2">
                          <InputNumber
                            className={timerDuplicateIndexes?.includes(index) ? 'p-invalid' : ''}
                            showButtons
                            min={1}
                            max={999}
                            value={time?.count}
                            onValueChange={(e) => handleTimingValueChange(e.target.value, index)}
                            disabled={disabled}
                          ></InputNumber>
                        </div>
                        <div className="col md:col-4">
                          <Dropdown
                            className={timerDuplicateIndexes?.includes(index) ? 'p-invalid' : ''}
                            options={
                              type === NotificationTypes.FOLLOW_UP
                                ? FOLLOW_UP_TIME_UNIT_OPTIONS
                                : REMINDER_TIME_UNIT_OPTIONS
                            }
                            optionLabel="label"
                            value={time?.timeUnit}
                            onChange={(e) => handleTimeUnitChange(e.target.value, index)}
                            disabled={disabled}
                          />
                        </div>
                        {length > 1 && (
                          <div className="col-fixed">
                            <Button
                              icon="pi pi-times"
                              rounded
                              text
                              onClick={() => handleRemoveTiming(index)}
                              disabled={disabled}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                    {hasAddTimingButton && (
                      <div
                        className={`flex align-items-center w-fit text-primary ${
                          disabled ? '' : 'cursor-pointer hover:text-primary-700'
                        }`}
                        onClick={disabled ? undefined : handleAddTiming}
                      >
                        <i className="pi pi-plus"></i>
                        <span className="ml-2">{labels.addAnotherReminder}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-12 md:col-4 flex flex-column">
                <div className="mb-1 font-semibold">{labels.mergeVariables}</div>
                <ListBox
                  onChange={(e) => addMergeVariable(e.value.name)}
                  listClassName="h-18rem"
                  options={MERGE_FIELD_OPTIONS}
                  optionLabel="name"
                  filter
                  disabled={disabled}
                />
              </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
);

NotificationItem.displayName = 'NotificationItem';
