import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { errorActions } from '../../../store/error/actions';
import { ErrorCodes } from '../../../store/error/types';
import { authentificationActions, authentificationSelectors } from '../../../store/authentification';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from '../../../components/common';
import { Path } from '../../../routing';

export const ViewAsUser = () => {
  const { tenantId, userId } = useParams();
  const dispatch = useDispatch();
  const isViewAsUserMode = useSelector(authentificationSelectors.selectIsViewAsUser);

  useEffect(() => {
    if (tenantId && userId) {
      !isViewAsUserMode && dispatch(authentificationActions.viewAsUserRequest({ tenantId, userId }));
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  return !isViewAsUserMode ? <Preloader /> : <Navigate to={Path.BookingPages} />;
};
