import { Divider } from 'primereact/divider';
import { IntegrationType } from '../../../API';
import { Button } from 'primereact/button';
import { integrationLabels, integrationIcon } from '../../../store/integration';
import sumoLogo from '../../../assets/images/sumoIconLightCircle.png';
import labels from './labels';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { CodeResponse } from '@react-oauth/google';
import { MicrosoftCodeResponse } from '../../../store/authentification';

type ConnectIntegrationProps = {
  integrationType: IntegrationType;
  handleBack: () => void;
  handleDisconnect: () => void;
  ConnectButton: React.ElementType;
  handleAuthSuccess?: (codeResponse: CodeResponse) => void;
  handleAuthRedirect?: (codeResponse: MicrosoftCodeResponse) => void;
};
export const ConnectIntegration: React.FC<ConnectIntegrationProps> = ({
  integrationType,
  handleBack,
  handleDisconnect,
  ConnectButton,
  handleAuthSuccess,
  handleAuthRedirect,
}) => {
  const isConnected = useSelector(userSettingsSelectors.selectIsIntegrationConnected(integrationType));

  return (
    <div className="col-12">
      <div className="border-1 border-100 shadow-1 p-4">
        <div className="font-bold text-xl">{integrationLabels[integrationType]}</div>
        <div className="grid p-4">
          <div className="col-12 md:col-4 flex flex-row align-items-center justify-content-center p-3">
            <img src={sumoLogo} className="w-7rem" />
            <span className="text-5xl font-bold">+</span>
            <img src={integrationIcon[integrationType]} className="mx-4 w-5rem" />
          </div>
          <Divider layout="vertical" className="hidden md:block col-1 m-0" />
          <div className="col-12 md:col-7 flex flex-column p-3">
            <div className="text-lg mb-2">{labels.benefitsTitle}</div>
            <div>{labels.benefitsDesc[integrationType]}</div>
            <div className="text-lg mt-5 mb-2">{labels.requirementsTitle}</div>
            <div>{labels.requirementsDesc[integrationType]}</div>
          </div>
        </div>
        <div className="flex flex-row justify-content-between">
          <Button label={labels.back} icon="pi pi-angle-left" outlined onClick={handleBack} />
          {isConnected ? (
            <Button label={labels.disconnect} className="w-10rem" severity="danger" onClick={handleDisconnect} />
          ) : (
            <ConnectButton
              label={labels.connect}
              className="w-10rem"
              onSuccess={handleAuthSuccess}
              onRedirect={handleAuthRedirect}
            />
          )}
        </div>
      </div>
    </div>
  );
};
