import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { UsersActionTypes, usersActions } from './actions';
import { deleteUser, editUser, getUsers, inviteUsers } from './service';
import { authentificationSelectors } from '../authentification';
import { notificationsActions } from '../notifications';
import {
  DELETE_USER_ERROR_TOAST,
  DELETE_USER_SUCCESS_TOAST,
  DISABLE_USER_ERROR_TOAST,
  DISABLE_USER_SUCCESS_TOAST,
  EDIT_USER_ERROR_TOAST,
  EDIT_USER_SUCCESS_TOAST,
  ENABLE_USER_SUCCESS_TOAST,
  GET_USERS_ERROR_TOAST,
  INVITE_USERS_ERROR_TOAST,
  INVITE_USERS_SUCCESS_TOAST,
} from './constants';
import { confirmUsersModalActions, editUsersModalActions, inviteUsersModalActions } from './modal';
import { CreateAdminDataInput, UpdateBookingTemplateInput } from '../../API';
import { BookingTemplateOptions, TeamsOptions, UserAdminDataFullRecord, UserStatus } from './typings';
import { usersSelectors } from './selectors';
import { globalActions } from '../global';
import { getBookingTemplates } from '../bookingTemplates/service';
import { handleServiceError } from '../utils/reduxUtils';
import { GetTeamsResponse } from '../teams';
import { getTeams } from '../teams/service';
import { authentificationActions } from '../../store/authentification';

function* getUsersSaga(): any {
  try {
    const tenantId = yield select(authentificationSelectors.selectTenantId);
    const adminDataRecords = yield call(getUsers, tenantId);

    yield put(usersActions.getUsersSuccess(adminDataRecords));
  } catch (error: unknown) {
    yield put(usersActions.getUsersFail(error?.toString()));
    yield call(handleServiceError, error, GET_USERS_ERROR_TOAST, true);
  }
}

function* getUsersPageDataSaga(): any {
  try {
    const tenantId = yield select(authentificationSelectors.selectTenantId);
    // const isSuperAdmin = yield select(userSettingsSelectors.selectIsSuperAdmin);

    // const requestsList = [
    //   call(getUsers, tenantId),
    //   call(workspacesSaga.getWorkspaces),
    //   call(rolesSagas.getRoles),
    //   ...(isSuperAdmin ? [call(authenticationSagas.getLicense)] : []),
    // ];
    // const [adminDataRecords] = yield all(requestsList);

    const adminDataRecords = yield call(getUsers, tenantId)

    yield put(usersActions.getUsersSuccess(adminDataRecords));
  } catch (error: unknown) {
    yield put(usersActions.getUsersFail(error?.toString()));
    yield call(handleServiceError, error, GET_USERS_ERROR_TOAST, true);
  }
}

function* inviteUsersSaga(action: ReturnType<typeof usersActions.inviteUsersRequest>): any {
  try {
    if (action.type === UsersActionTypes.INVITE_USERS_REQUEST) {
      const tenantId = yield select(authentificationSelectors.selectTenantId);
      const data: CreateAdminDataInput[] = action.payload.data.map((record) => ({ ...record, tenantId }));
      yield call(inviteUsers, data, action.payload.expirationInviteDays);

      yield put(usersActions.inviteUsersSuccess());
      yield put(inviteUsersModalActions.closeModal());
      yield put<any>(notificationsActions.showToast(INVITE_USERS_SUCCESS_TOAST));
      yield put(usersActions.getUsersPageDataRequest());
      yield put(authentificationActions.getLicenseRequest());
    }
  } catch (error: unknown) {
    yield put(usersActions.inviteUsersFail(error?.toString()));
    yield call(handleServiceError, error, INVITE_USERS_ERROR_TOAST);
  }
}

function* editUserSage(action: ReturnType<typeof usersActions.editUserRecordRequest>): any {
  try {
    if (action.type === UsersActionTypes.EDIT_USER_RECORD_REQUEST) {
      const userRecord: UserAdminDataFullRecord = yield select(usersSelectors.selectSelectedUser);
      yield call(editUser, [userRecord]);

      yield put(usersActions.editUserRecordSuccess());

      if (action.roleChanged) {
        yield all([put(globalActions.getMainDataRequest()), put(usersActions.getUsersPageDataRequest())]);
      } else {
        yield put(usersActions.getUsersPageDataRequest());
      }

      yield put(editUsersModalActions.closeModal());
      yield put<any>(notificationsActions.showToast(EDIT_USER_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(usersActions.editUserRecordFail(error?.toString()));
    yield call(handleServiceError, error, EDIT_USER_ERROR_TOAST);
  }
}

function* disableUserSage(): any {
  try {
    const userRecord: UserAdminDataFullRecord = yield select(usersSelectors.selectSelectedUser);
    yield call(editUser, [userRecord]);

    yield put(usersActions.disableUserRecordSuccess());
    const user = userRecord.fullName || userRecord.email;
    if (userRecord.status === UserStatus.Active) {
      yield put<any>(
        notificationsActions.showToast({
          ...ENABLE_USER_SUCCESS_TOAST,
          message: '"' + user + '" ' + ENABLE_USER_SUCCESS_TOAST.message,
        })
      );
    } else {
      yield put(confirmUsersModalActions.closeModal());
      yield put<any>(
        notificationsActions.showToast({
          ...DISABLE_USER_SUCCESS_TOAST,
          message: '"' + user + '" ' + DISABLE_USER_SUCCESS_TOAST.message,
        })
      );
    }

    yield put(usersActions.getUsersPageDataRequest());
    yield put(authentificationActions.getLicenseRequest());
  } catch (error: unknown) {
    yield put(usersActions.disableUserRecordFail(error?.toString()));
    yield call(handleServiceError, error, DISABLE_USER_ERROR_TOAST);
  }
}

function* deleteUserSage(): any {
  try {
    const user: UserAdminDataFullRecord = yield select(usersSelectors.selectSelectedUser);
    const tenantId: string = yield select(authentificationSelectors.selectTenantId);
    yield call(deleteUser, user.email, tenantId);

    yield put(usersActions.deleteUserRecordSuccess());
    yield put(confirmUsersModalActions.closeModal());
    yield put<any>(
      notificationsActions.showToast({
        ...DELETE_USER_SUCCESS_TOAST,
        message: '"' + (user.fullName ? user.fullName : user.email) + '" ' + DELETE_USER_SUCCESS_TOAST.message,
      })
    );

    yield put(usersActions.getUsersPageDataRequest());
    yield put(authentificationActions.getLicenseRequest());
  } catch (error: unknown) {
    yield put(usersActions.deleteUserRecordFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_USER_ERROR_TOAST);
  }
}

function* processBookingTemplatesByWorkspaceId(
  workspaceId: string,
  callback: (item: BookingTemplateOptions) => void
): any {
  const bookingTemplates: UpdateBookingTemplateInput[] = yield call(getBookingTemplates, workspaceId);
  return bookingTemplates.length && callback({ workspaceId, bookingTemplates } as BookingTemplateOptions);
}

function* processTeamsByWorkspaceId(
  workspaceId: string,
  callback: (item: TeamsOptions) => void
): any {
  const teamsResponse: GetTeamsResponse = yield call(getTeams, workspaceId);
  return teamsResponse.createdTeams?.length && callback({ workspaceId, teams:teamsResponse.createdTeams } as TeamsOptions);
}

function* getInviteUsersOptionsSage(): any {
  try {
    const workspaceIds = yield select(usersSelectors.selectUserWorkspacesIds);

    const bookingTemplates: BookingTemplateOptions[] = [];
    const teams: TeamsOptions[] = [];
    const requestsList = [];
    for (const id of workspaceIds) {
      requestsList.push(processBookingTemplatesByWorkspaceId(id, (item) => bookingTemplates.push(item)));
      requestsList.push(processTeamsByWorkspaceId(id, (item) => teams.push(item)));
    }
    yield all(requestsList);

    yield put(usersActions.getInviteUsersOptionsSuccess({teams, bookingTemplates}));
  } catch (error: unknown) {
    yield put(usersActions.getInviteUsersOptionsFail(error?.toString()));
  }
}

export function* watchAdminSaga() {
  yield takeLatest(UsersActionTypes.GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST, getUsersPageDataSaga);
  yield takeLatest(UsersActionTypes.INVITE_USERS_REQUEST, inviteUsersSaga);
  yield takeLatest(UsersActionTypes.EDIT_USER_RECORD_REQUEST, editUserSage);
  yield takeLatest(UsersActionTypes.DISABLE_USER_RECORD_REQUEST, disableUserSage);
  yield takeLatest(UsersActionTypes.DELETE_USER_RECORD_REQUEST, deleteUserSage);
  yield takeLatest(UsersActionTypes.GET_INVITE_USERS_OPTIONS_REQUEST, getInviteUsersOptionsSage);
}

export const usersSaga = {
  getUsers: getUsersSaga,
};
