import { memo } from 'react';
import { useSelector } from 'react-redux';
import { scheduledMeetingsSelectors } from '../../../store/scheduledMeetings/selectors';
import { ScheduledMeeting } from '../scheduledMeeting/ScheduledMeeting';
import { CreateUserEventInput } from '../../../API';

interface IScheduledDayMeetings {
  day: string;
}

const ScheduledDayMeetingsComponent = ({ day }: IScheduledDayMeetings) => {
  const eventsList = useSelector(scheduledMeetingsSelectors.selectEventsByDay(day));

  return (
    <>
      {eventsList.map((scheduledEvent: CreateUserEventInput) => (
        <ScheduledMeeting event={scheduledEvent} key={scheduledEvent.eventId} />
      ))}
    </>
  );
};

export const ScheduledDayMeetings = memo(ScheduledDayMeetingsComponent);

ScheduledDayMeetings.displayName = 'ScheduledDayMeetings';