import { Account, CreateAccountInput } from '../../../API';
import { AccountWithTenants } from './types';
import { APIPostRequest } from '../../utils/reduxUtils';

export const getAccounts = async () => {
  try {
    return (await APIPostRequest('/api/js/opsconsole/getAllAccounts', {})) as Account[];
  } catch (error: unknown) {
    console.error('Error fetching accounts:', error);
    throw error;
  }
};

export const getTenantsByAccountId = async (id: string) => {
  try {
    return (await APIPostRequest('/api/js/opsconsole/getTenantsByAccountId', { id })) as AccountWithTenants;
  } catch (error: unknown) {
    console.error('Error fetching account details:', error);
    throw error;
  }
};

export const upsertAccount = async (account: CreateAccountInput) => {
  try {
    return (await APIPostRequest('/api/js/upsertAccount', { account })) as string;
  } catch (error: unknown) {
    console.error('Error saving account:', error);
    throw error;
  }
};

export const mergeAccounts = async (mainId: string, mergeIds: string[]) => {
  try {
    await APIPostRequest('/api/js/mergeAccounts', {
      mainId,
      mergeIds,
    });
  } catch (error: unknown) {
    console.error('Error merging accounts:', error);
    throw error;
  }
};

export const removeAccount = async (id: string) => {
  try {
    await APIPostRequest('/api/js/removeAccount', { id });
  } catch (error: unknown) {
    console.error('Error deleting account:', error);
    throw error;
  }
};
