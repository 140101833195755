import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import labels from './labels';
import { MultiSelect } from 'primereact/multiselect';
import { CreateAdminDataInput, UpdateBookingTemplateInput } from '../../../API';
import { useSelector } from 'react-redux';
import { workspacesSelectors } from '../../../store/workspaces';
import { userSettingsSelectors } from '../../../store/userSettings';
import { rolesSelectors } from '../../../store/roles';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { usersSelectors } from '../../../store/users';
import { authentificationSelectors } from '../../../store/authentification';
import { TeamRecord } from '../../../store/teams';

type InviteDialogOptionsProps = {
  formData: CreateAdminDataInput;
  setFormData: Dispatch<SetStateAction<CreateAdminDataInput>>;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement> | DropdownChangeEvent) => void;
};
export const InviteDialogOptions = ({ formData, setFormData, handleChange }: InviteDialogOptionsProps) => {
  const workspaceOptions = useSelector(workspacesSelectors.selectWorkspaceOptions);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const supportedRoles = useSelector(userSettingsSelectors.selectSupportedRoles);
  const superSuperAdminId = useSelector(rolesSelectors.selectSuperAdminId);
  const userWorkspace = useSelector(authentificationSelectors.selectWorkspace);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const bookingTemplates = useSelector(
    usersSelectors.selectBookingTemplateOptionsByWorkspaces(formData.workspaceIds || [])
  );
  const teamList = useSelector(usersSelectors.selectTeamsOptionsByWorkspaces(formData.workspaceIds || []));

  useEffect(() => {
    // when we only have one workspace, we don't show the workspace input, just pre-populate the first one.
    if (userWorkspaces.length === 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        workspaceIds: [workspaceOptions[0].id],
      }));
    }
    // if we have more
    if (userWorkspaces.length > 1) {
      // checking all Workspaces if roleId is superSuperAdminId
      if (formData.roleId === superSuperAdminId) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          workspaceIds: workspaceOptions.map((option) => option.id),
        }));
      }
      // if we don't select if before set the current workspace
      if (!formData.workspaceIds?.length && formData.roleId !== superSuperAdminId) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          workspaceIds: [userWorkspace],
        }));
      }
    }
  }, [formData.roleId, superSuperAdminId, workspaceOptions, userWorkspaces]);

  const handleTemplatesChange = (selectedWorkspaceIds: string[]) => {
    // filter all selected templates according to new list of workspaces
    const listTemplates: UpdateBookingTemplateInput[] = [];
    if (bookingTemplates) {
      for (const option of bookingTemplates) {
        if (selectedWorkspaceIds.includes(option.workspaceId)) {
          listTemplates.push(...option.bookingTemplates);
        }
      }
      const actualTemplates = formData.bookingTemplateIds?.filter((id) =>
        listTemplates.some((template) => template.id === id)
      );
      setFormData((prev) => ({ ...prev, bookingTemplateIds: actualTemplates }));
    }
  };

  const handleTeamsChange = (selectedWorkspaceIds: string[]) => {
    // filter all selected teams according to new list of workspaces
    const teams: TeamRecord[] = [];
    if (teamList) {
      for (const option of teamList) {
        if (selectedWorkspaceIds.includes(option.workspaceId)) {
          teams.push(...option.teams);
        }
      }
      const actualTeams = formData.team?.filter((id) => teams.some((team) => team.id === id));
      setFormData((prev) => ({ ...prev, team: actualTeams }));
    }
  };

  const handleWorkspaceChange = (e: DropdownChangeEvent) => {
    if (formData.roleId !== superSuperAdminId) {
      handleTemplatesChange(e.target.value);
      handleTeamsChange(e.target.value);
      handleChange(e);
    } else {
      // Prevent unchecking if roleId is superSuperAdminId
      const currentValues = formData.workspaceIds ?? [];
      const newValue = e.value;
      if (newValue.length < currentValues.length) {
        e.preventDefault();
      } else {
        handleTemplatesChange(e.target.value);
        handleTeamsChange(e.target.value);
        handleChange(e);
      }
    }
  };

  return (
    <div className="w-12 md:w-5">
      <div className="flex flex-column">
        {(userWorkspaces?.length || 0) > 1 && (
          <div className="p-3 mx-1 my-2 surface-50">
            <label htmlFor="workspaceIds" className="font-semibold">
              {labels.assignWorkspace}
            </label>
            <MultiSelect
              id="workspaceIds"
              name="workspaceIds"
              options={
                isSuperAdmin
                  ? workspaceOptions
                  : workspaceOptions.filter((option) => userWorkspaces.includes(option.id))
              }
              optionLabel="name"
              optionValue="id"
              value={formData.workspaceIds}
              onChange={handleWorkspaceChange}
              display="chip"
              filter
              className={`${!formData.workspaceIds?.length && 'p-invalid'}`}
            />
            {formData.roleId === superSuperAdminId && <div className="m-1">{labels.superAdminAccessDescription}</div>}
          </div>
        )}
        <div className="p-3 mx-1 my-2 surface-50">
          <label htmlFor="roleId" className="font-semibold">
            {labels.assignRole}
          </label>
          <Dropdown
            id="roleId"
            name="roleId"
            value={formData.roleId}
            options={supportedRoles}
            optionLabel="name"
            optionValue="id"
            onChange={handleChange}
            className={`${!formData.roleId && 'p-invalid'}`}
          />
        </div>
        <div className="p-3 mx-1 my-2 surface-50">
          <label htmlFor="team" className="font-semibold">
            {labels.addToTeam} <span className="font-normal">({labels.optional})</span>
          </label>
          {teamList ? (
            <MultiSelect
              id="team"
              name="team"
              value={formData.team}
              options={teamList}
              optionLabel="name"
              optionValue="id"
              optionGroupLabel="label"
              optionGroupChildren="teams"
              onChange={handleChange}
              placeholder={labels.addToTeamPlaceholder}
              display="chip"
              filter
              disabled={!formData.workspaceIds?.length || !teamList.length}
            />
          ) : (
            /* show spinner during loading the data */
            <div className="bg-white border-1 border-black-alpha-10 border-round p-1 text-center text-xl">
              <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
            </div>
          )}
        </div>
        <div className="p-3 mx-1 my-2 surface-50">
          <label htmlFor="bookingTemplateIds" className="font-semibold">
            {labels.assignBookingTemplates} <span className="font-normal">({labels.optional})</span>
          </label>
          {bookingTemplates ? (
            <MultiSelect
              id="bookingTemplateIds"
              name="bookingTemplateIds"
              value={formData.bookingTemplateIds}
              options={bookingTemplates}
              optionLabel="what.customName"
              optionValue="id"
              optionGroupLabel="label"
              optionGroupChildren="bookingTemplates"
              onChange={handleChange}
              placeholder={labels.assignBookingTemplatesPlaceholder}
              display="chip"
              filter
              disabled={!formData.workspaceIds?.length || !bookingTemplates.length}
            />
          ) : (
            /* show spinner during loading the data */
            <div className="bg-white border-1 border-black-alpha-10 border-round p-1 text-center text-xl">
              <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
