import { API } from 'aws-amplify';
import { CreateRoleInput, Role } from '../../API';
import { APIPostRequest } from '../utils/reduxUtils';

export const getRoles = async (tenantId: string) => {
  try {
    const response = (await APIPostRequest('/api/js/getRolesByTenantId', {
      tenantId,
    })) as Role[];
    if (response) {
      return response;
    } else {
      throw new Error('Roles not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};

export const saveRole = async (role: CreateRoleInput) => {
  try {
    await APIPostRequest('/api/js/upsertRole', { data: role });
  } catch (error: unknown) {
    console.error('Error save role:', error);
    throw error;
  }
};

export const deleteRole = async (tenantId: string, roleId: string) => {
  try {
    await APIPostRequest('/api/js/deleteRole', {
      tenantId,
      roleId,
    });
  } catch (error: unknown) {
    console.error('Error delete role:', error);
    throw error;
  }
};
