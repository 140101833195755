import { CHARGEBEE_MONTHLY_TERM, SubscribeCustomerInfo } from "../store/billing";
import { APIPostRequest } from "../store/utils/reduxUtils";

declare global {
    const Chargebee: any;
}

const getCBInstance = () => {
    return Chargebee.inited
        ? Chargebee.getInstance()
        : Chargebee.init({
            site: process.env.REACT_APP_CHARGEBEE_SITE,
            publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLIC_KEY,
            isItemsModel: true,
        });
};

export const openPortal = async () => {
    const cbInstance = getCBInstance();
    cbInstance.setPortalSession(() => APIPostRequest('/api/js/billing/createPortalSession', {}));
    const cbPortal = cbInstance.createChargebeePortal();
    cbPortal.open();
};

export const subscribe = (quantity = 10, term = CHARGEBEE_MONTHLY_TERM, customerInfo: SubscribeCustomerInfo, tenantId: string, onCheckoutSuccess: () => void) => {
    const cbInstance = getCBInstance();
    const cart = cbInstance.getCart();
    const product = cbInstance.initializeProduct(term);
    product.setPlanQuantity(quantity);
    product.setCustomData({
        cf_tenantId: tenantId,
    });
    cart.replaceProduct(product);
    if (customerInfo) cart.setCustomer(customerInfo);

    cbInstance.setCheckoutCallbacks(function () {
        return {
            // loaded: function () {
            // },
            // close: function () {
            // },
            success: function () {
                onCheckoutSuccess();
            },
        };
    });

    cart.proceedToCheckout();
};
