import { t } from '../../../i18n/i18n';

export default {
  account: t('PrivateRoute:ACCOUNT'),
  accountSettings: t('PrivateRoute:ACCOUNT_SETTINGS'),
  availability: t('PrivateRoute:AVAILABILITY'),
  calendarConnections: t('PrivateRoute:CALENDAR_CONNECTIONS'),
  helpCenter: t('PrivateRoute:HELP_CENTER'),
  integration: t('PrivateRoute:INTEGRATION'),
  logOut: t('Common:LOG_OUT'),
  profile: t('PrivateRoute:PROFILE'),
  userSettings: t('PrivateRoute:USER_SETTINGS'),
  userSetup: t('PrivateRoute:USER_SETUP'),
};
