import { CreateAdminDataInput } from '../../API';
import { InviteUsersOptions, UserAdminDataFullRecord } from "./typings";

export enum UsersActionTypes {
  GET_USERS_REQUEST = 'users/GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'users/GET_USERS_SUCCESS',
  GET_USERS_FAIL = 'users/GET_USERS_FAIL',
  GET_USERS_PAGE_DATA_REQUEST = 'users/GET_USERS_PAGE_DATA_REQUEST',
  INVITE_USERS_REQUEST = 'users/INVITE_USERS_REQUEST',
  INVITE_USERS_SUCCESS = 'users/INVITE_USERS_SUCCESS',
  INVITE_USERS_FAIL = 'users/INVITE_USERS_FAIL',
  EDIT_USER_RECORD_REQUEST = 'users/EDIT_USER_RECORD_REQUEST',
  EDIT_USER_RECORD_SUCCESS = 'users/EDIT_USER_RECORD_SUCCESS',
  EDIT_USER_RECORD_FAIL = 'users/EDIT_USER_RECORD_FAIL',
  DISABLE_USER_RECORD_REQUEST = 'users/DISABLE_USER_RECORD_REQUEST',
  DISABLE_USER_RECORD_SUCCESS = 'users/DISABLE_USER_RECORD_SUCCESS',
  DISABLE_USER_RECORD_FAIL = 'users/DISABLE_USER_RECORD_FAIL',
  DELETE_USER_RECORD_REQUEST = 'users/DELETE_USER_RECORD_REQUEST',
  DELETE_USER_RECORD_SUCCESS = 'users/DELETE_USER_RECORD_SUCCESS',
  DELETE_USER_RECORD_FAIL = 'users/DELETE_USER_RECORD_FAIL',
  SET_USER_RECORD = 'users/SET_USER_RECORD',
  UPDATE_USER_RECORD = 'users/UPDATE_USER_RECORD',
  SET_HIDE_INACTIVE = 'users/SET_HIDE_INACTIVE',
  GET_INVITE_USERS_OPTIONS_REQUEST = 'users/GET_INVITE_USERS_OPTIONS_REQUEST',
  GET_INVITE_USERS_OPTIONS_SUCCESS = 'users/GET_INVITE_USERS_OPTIONS_SUCCESS',
  GET_INVITE_USERS_OPTIONS_FAIL = 'users/GET_INVITE_USERS_OPTIONS_FAIL',
}

export type UsersAction =
  | { type: UsersActionTypes.GET_USERS_REQUEST }
  | { type: UsersActionTypes.GET_USERS_SUCCESS; payload: UserAdminDataFullRecord[] }
  | { type: UsersActionTypes.GET_USERS_FAIL; error: unknown }
  | { type: UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST }
  | { type: UsersActionTypes.INVITE_USERS_REQUEST; payload: {data: CreateAdminDataInput[], expirationInviteDays: number} }
  | { type: UsersActionTypes.INVITE_USERS_SUCCESS }
  | { type: UsersActionTypes.INVITE_USERS_FAIL; error: unknown }
  | { type: UsersActionTypes.EDIT_USER_RECORD_REQUEST; roleChanged: boolean }
  | { type: UsersActionTypes.EDIT_USER_RECORD_SUCCESS }
  | { type: UsersActionTypes.EDIT_USER_RECORD_FAIL; error: unknown }
  | { type: UsersActionTypes.DISABLE_USER_RECORD_REQUEST }
  | { type: UsersActionTypes.DISABLE_USER_RECORD_SUCCESS }
  | { type: UsersActionTypes.DISABLE_USER_RECORD_FAIL; error: unknown }
  | { type: UsersActionTypes.DELETE_USER_RECORD_REQUEST }
  | { type: UsersActionTypes.DELETE_USER_RECORD_SUCCESS }
  | { type: UsersActionTypes.DELETE_USER_RECORD_FAIL; error: unknown }
  | { type: UsersActionTypes.SET_USER_RECORD; payload: UserAdminDataFullRecord }
  | { type: UsersActionTypes.UPDATE_USER_RECORD; payload: Partial<UserAdminDataFullRecord> }
  | { type: UsersActionTypes.SET_HIDE_INACTIVE; payload: boolean }
  | { type: UsersActionTypes.GET_INVITE_USERS_OPTIONS_REQUEST }
  | { type: UsersActionTypes.GET_INVITE_USERS_OPTIONS_SUCCESS; payload: InviteUsersOptions}
  | { type: UsersActionTypes.GET_INVITE_USERS_OPTIONS_FAIL; error: unknown };

const getUsersRequest = (): UsersAction => ({ type: UsersActionTypes.GET_USERS_REQUEST });
const getUsersSuccess = (payload: UserAdminDataFullRecord[]): UsersAction => ({
  type: UsersActionTypes.GET_USERS_SUCCESS,
  payload,
});
const getUsersFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.GET_USERS_FAIL, error });
const getUsersPageDataRequest = (): UsersAction => ({ type: UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST });

const inviteUsersRequest = (data: CreateAdminDataInput[], expirationInviteDays: number): UsersAction => ({
  type: UsersActionTypes.INVITE_USERS_REQUEST,
  payload: {data, expirationInviteDays},
});
const inviteUsersSuccess = (): UsersAction => ({
  type: UsersActionTypes.INVITE_USERS_SUCCESS,
});
const inviteUsersFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.INVITE_USERS_FAIL, error });

const editUserRecordRequest = (roleChanged: boolean): UsersAction => ({
  type: UsersActionTypes.EDIT_USER_RECORD_REQUEST,
  roleChanged,
});
const editUserRecordSuccess = (): UsersAction => ({
  type: UsersActionTypes.EDIT_USER_RECORD_SUCCESS,
});
const editUserRecordFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.EDIT_USER_RECORD_FAIL, error });

const disableUserRecordRequest = (): UsersAction => ({
  type: UsersActionTypes.DISABLE_USER_RECORD_REQUEST,
});
const disableUserRecordSuccess = (): UsersAction => ({
  type: UsersActionTypes.DISABLE_USER_RECORD_SUCCESS,
});
const disableUserRecordFail = (error: unknown): UsersAction => ({
  type: UsersActionTypes.DISABLE_USER_RECORD_FAIL,
  error,
});

const deleteUserRecordRequest = (): UsersAction => ({
  type: UsersActionTypes.DELETE_USER_RECORD_REQUEST,
});
const deleteUserRecordSuccess = (): UsersAction => ({
  type: UsersActionTypes.DELETE_USER_RECORD_SUCCESS,
});
const deleteUserRecordFail = (error: unknown): UsersAction => ({
  type: UsersActionTypes.DELETE_USER_RECORD_FAIL,
  error,
});

const setUserRecord = (payload: UserAdminDataFullRecord): UsersAction => ({
  type: UsersActionTypes.SET_USER_RECORD,
  payload,
});

const updateUserRecord = (payload: Partial<UserAdminDataFullRecord>): UsersAction => ({
  type: UsersActionTypes.UPDATE_USER_RECORD,
  payload,
});

const setHideInactive = (payload: boolean): UsersAction => ({
  type: UsersActionTypes.SET_HIDE_INACTIVE,
  payload,
});

const getInviteUsersOptionsRequest = (): UsersAction => ({
  type: UsersActionTypes.GET_INVITE_USERS_OPTIONS_REQUEST,
});
const getInviteUsersOptionsSuccess = (payload: InviteUsersOptions): UsersAction => ({
  type: UsersActionTypes.GET_INVITE_USERS_OPTIONS_SUCCESS,
  payload,
});
const getInviteUsersOptionsFail = (error: unknown): UsersAction => ({
  type: UsersActionTypes.GET_INVITE_USERS_OPTIONS_FAIL,
  error,
});

export const usersActions = {
  getUsersRequest,
  getUsersSuccess,
  getUsersFail,
  getUsersPageDataRequest,
  inviteUsersRequest,
  inviteUsersSuccess,
  inviteUsersFail,
  editUserRecordRequest,
  editUserRecordSuccess,
  editUserRecordFail,
  disableUserRecordRequest,
  disableUserRecordSuccess,
  disableUserRecordFail,
  deleteUserRecordRequest,
  deleteUserRecordSuccess,
  deleteUserRecordFail,
  setUserRecord,
  updateUserRecord,
  setHideInactive,
  getInviteUsersOptionsRequest,
  getInviteUsersOptionsSuccess,
  getInviteUsersOptionsFail,
};
