import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {
  DEFAULT_CUSTOM_FEILD,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../../store/bookingTemplates';
import { CustomFieldInput, CustomFieldType } from '../../../../API';
import labels from './labels';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { CustomField } from '../customField/CustomField';
import { EditCustomField } from '../editCustomField/EditCustomField';
import { v4 as getId } from 'uuid';

type AdditionalRulesStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const AdditionalRulesStep = ({ selectors, actions, isReadOnly }: AdditionalRulesStepProps) => {
  const dispatch = useDispatch();
  const inputFields = useSelector(selectors.selectInputFields);
  const [selectedCustomField, setSelectedCustomField] = useState<CustomFieldInput | null>(null);

  const handleSortDown = (index: number) => {
    dispatch(actions.moveCustomFieldDown(index));
  };

  const handleSortUp = (index: number) => {
    dispatch(actions.moveCustomFieldUp(index));
  };

  const handleSave = (customField: CustomFieldInput) => {
    if (customField.id) {
      dispatch(actions.updateCustomField(customField));
    } else {
      customField.id = getId();
      dispatch(actions.addCustomField(customField));
    }
    setSelectedCustomField(null);
  };

  const handleDelete = (id: string) => {
    dispatch(actions.removeCustomField(id));
    setSelectedCustomField(null);
  };

  return (
    <>
      <div className="grid p-fluid -m-3">
        {inputFields?.map(
          (customField, index, { length }) =>
            customField && (
              <div key={customField?.id} className="col-12 md:col-6 p-3">
                <div key={customField?.id} className="flex flex-column align-items-center">
                  <div className="w-12">
                    <CustomField customField={customField} onChange={handleSave} disabled></CustomField>
                  </div>
                  <div className="flex align-items-center pt-1">
                    <Button
                      icon="pi pi-pencil"
                      text
                      onClick={() => setSelectedCustomField(customField)}
                      disabled={isReadOnly}
                    ></Button>
                    {customField.fieldType === CustomFieldType.CUSTOM && (
                      <Button
                        icon="pi pi-trash"
                        text
                        onClick={() => customField.id && handleDelete(customField.id)}
                        disabled={isReadOnly}
                      ></Button>
                    )}
                    <div className="flex flex-column">
                      {index !== 0 && (
                        <Button
                          icon="pi pi-angle-up"
                          className="h-1rem mr-2"
                          text
                          onClick={() => handleSortUp(index)}
                          disabled={isReadOnly}
                        ></Button>
                      )}
                      {index !== length - 1 && (
                        <Button
                          icon="pi pi-angle-down"
                          className="h-1rem mr-2"
                          text
                          onClick={() => handleSortDown(index)}
                          disabled={isReadOnly}
                        ></Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>

      <div className="my-4">
        <Button
          label={labels.addQuestionButton}
          icon="pi pi-plus"
          onClick={() => setSelectedCustomField(DEFAULT_CUSTOM_FEILD)}
          className="p-mb-2"
          disabled={isReadOnly}
        />
      </div>

      <Dialog
        className="w-30rem"
        header={selectedCustomField?.id ? labels.titleEdit : labels.titleNew}
        visible={!!selectedCustomField}
        draggable={false}
        position="center"
        blockScroll
        onHide={() => setSelectedCustomField(null)}
      >
        <EditCustomField
          customFieldInput={selectedCustomField || DEFAULT_CUSTOM_FEILD}
          onSave={handleSave}
          onCancel={() => setSelectedCustomField(null)}
          onDelete={handleDelete}
        ></EditCustomField>
      </Dialog>
    </>
  );
};
