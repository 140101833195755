import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { deleteAvailability, getUserAvailability, postAvailability, updateAvailability } from './service';
import { UserAvailabilityActionTypes, UserAvailabilityActions } from './actions';
import { CreateUserDataInput, DeleteUserDataInput, UserRecordType } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CREATE_AVAILABILITY_ERROR_TOAST,
  CREATE_AVAILABILITY_SUCCESS_TOAST,
  DELETE_AVAILABILITY_ERROR_TOAST,
  DELETE_AVAILABILITY_SUCCESS_TOAST,
  SAVE_AVAILABILITY_ERROR_TOAST,
  SAVE_AVAILABILITY_SUCCESS_TOAST,
  SET_DEFAULT_AVAILABILITY_ERROR_TOAST,
  SET_DEFAULT_AVAILABILITY_SUCCESS_TOAST,
} from './constants';
import { v4 as uuidv4 } from 'uuid';
import { userAvailabilitySelectors } from './selectors';
import { authentificationSelectors } from '../authentification';
import { userAvailabilityActions } from '.';
import { UserDataInputCreatedAt } from '../global/types';
import { handleServiceError } from '../utils/reduxUtils';

const selectCreateAvailabilityRequest = createSelector(
  authentificationSelectors.selectUserId,
  authentificationSelectors.selectTenantId,
  userAvailabilitySelectors.selectUserAvailability,
  (userId, tenant, availability) => ({
    userId,
    tenant,
    link: uuidv4(),
    recordType: UserRecordType.AVAILABILITY,
    availabilityData: {
      ...availability.availabilityData,
      id: uuidv4(),
    },
  })
);

const selectUpdateAvailabilityRequest = createSelector(
  userAvailabilitySelectors.selectUserAvailability,
  (availability) => ({
    userId: availability.userId,
    tenant: availability.tenant,
    link: availability.link,
    recordType: UserRecordType.AVAILABILITY,
    availabilityData: availability.availabilityData,
  })
);

const selectCloneAvailabilityRequest = createSelector(
  authentificationSelectors.selectUserId,
  authentificationSelectors.selectTenantId,
  userAvailabilitySelectors.selectUserAvailability,
  userAvailabilitySelectors.selectCloneName,
  (userId, tenant, availability, cloneName) => ({
    userId,
    tenant,
    link: uuidv4(),
    recordType: UserRecordType.AVAILABILITY,
    availabilityData: {
      ...availability.availabilityData,
      id: uuidv4(),
      name: cloneName,
      isDefault: false,
    },
  })
);

// TODO: find out how to describe function generator with typeScript
function* getUserAvailabilitySaga() {
  try {
    const userId: string = yield select(authentificationSelectors.selectUserId)
    const response: UserDataInputCreatedAt[] = yield call(getUserAvailability, userId);

    yield put(UserAvailabilityActions.getUserAvailabilitySuccess(response));
  } catch (error: unknown) {
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST, true);
    yield put(UserAvailabilityActions.getUserAvailabilityFail(error?.toString()));
  }
}

function* createUserAvailabilitySaga() {
  try {
    const request: CreateUserDataInput = yield select(selectCreateAvailabilityRequest);
    yield call(postAvailability, request);

    yield put<any>(notificationsActions.showToast(CREATE_AVAILABILITY_SUCCESS_TOAST));
    yield put(UserAvailabilityActions.createUserAvailabilitySuccess({...request, createdAt: ''}));
    yield put(userAvailabilityActions.getUserAvailabilityRequest());
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.createUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST);
  }
}

function* cloneUserAvailabilitySaga() {
  try {
    const request: CreateUserDataInput = yield select(selectCloneAvailabilityRequest);
    yield call(postAvailability, request);

    yield put<any>(notificationsActions.showToast(CREATE_AVAILABILITY_SUCCESS_TOAST));
    yield put(UserAvailabilityActions.cloneUserAvailabilitySuccess({...request, createdAt: ''}));
    yield put(userAvailabilityActions.getUserAvailabilityRequest())
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.cloneUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST);
  }
}

function* updateUserAvailabilitySaga() {
  try {
    const request: CreateUserDataInput = yield select(selectUpdateAvailabilityRequest);
    yield call(updateAvailability, request);

    yield put(UserAvailabilityActions.saveUserAvailabilitySuccess());
    yield put(UserAvailabilityActions.getUserAvailabilityRequest());
    yield put<any>(notificationsActions.showToast(SAVE_AVAILABILITY_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.saveUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_AVAILABILITY_ERROR_TOAST);
  }
}

function* deleteAvailabilitySaga(action: ReturnType<typeof UserAvailabilityActions.deleteUserAvailabilityRequest>) {
  try {
    if (action.type === UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST) {
      const userId: string = yield select(authentificationSelectors.selectUserId);
      const tenant: string = yield select(authentificationSelectors.selectTenantId);
      const link: string = action.payload;
      const defaultAvailability: UserDataInputCreatedAt = yield select(
        userAvailabilitySelectors.selectDefaultAvailability
      );

      const input = {
        userId,
        tenant,
        link,
      } as DeleteUserDataInput;

      yield call(deleteAvailability, input);
      yield put(UserAvailabilityActions.deleteUserAvailabilitySuccess(link));
      yield put<any>(notificationsActions.showToast(DELETE_AVAILABILITY_SUCCESS_TOAST));

      if (defaultAvailability && defaultAvailability.link === link) {
        const oldestRecord: UserDataInputCreatedAt = yield select(
          userAvailabilitySelectors.selectOldestAvailability(link)
        );
        yield put(UserAvailabilityActions.setUserAvailability(oldestRecord));
        yield put(UserAvailabilityActions.setDefaultAvailabilityRequest(false));
      } else {
        if (defaultAvailability) {
          yield put(UserAvailabilityActions.setUserAvailability(defaultAvailability));
        }
        yield put(UserAvailabilityActions.getUserAvailabilityRequest());
      }
    }
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.deleteUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_AVAILABILITY_ERROR_TOAST);
  }
}

function* setDefaultAvailabilitySaga({ payload }: any) {
  try {
    const currentDefault: CreateUserDataInput | undefined = yield select(
      userAvailabilitySelectors.selectDefaultAvailability
    );

    if (payload && currentDefault) {
      yield call(updateAvailability, {
        userId: currentDefault.userId,
        tenant: currentDefault.tenant,
        link: currentDefault.link,
        recordType: UserRecordType.AVAILABILITY,
        availabilityData: { ...currentDefault.availabilityData, isDefault: false },
      } as CreateUserDataInput);
    }

    const selected: CreateUserDataInput = yield select(userAvailabilitySelectors.selectUserAvailability);

    yield call(updateAvailability, {
      userId: selected.userId,
      tenant: selected.tenant,
      link: selected.link,
      recordType: UserRecordType.AVAILABILITY,
      availabilityData: { ...selected.availabilityData, isDefault: true },
    } as CreateUserDataInput);

    yield put(UserAvailabilityActions.setDefaultAvailabilitySuccess());
    yield put(UserAvailabilityActions.getUserAvailabilityRequest());
    yield put<any>(notificationsActions.showToast(SET_DEFAULT_AVAILABILITY_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.setDefaultAvailabilityFail(error));
    yield call(handleServiceError, error, SET_DEFAULT_AVAILABILITY_ERROR_TOAST);
  }
}

export function* watchUserAvailabilitySaga() {
  yield takeLatest(UserAvailabilityActionTypes.GET_USER_AVAILABILITY_REQUEST, getUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_REQUEST, createUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_REQUEST, cloneUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_REQUEST, updateUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST, deleteAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST, setDefaultAvailabilitySaga);
}

export const userAvailabilitySagas = {
  getUserAvailability: getUserAvailabilitySaga,
  updateUserAvailability: updateUserAvailabilitySaga,
};
