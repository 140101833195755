import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../../store/bookingTemplates';
import { BufferTimeSettings } from './bufferTimeSettings/BufferTimeSettings';
import { DateRangeSettings } from './dateRangeSettings/DateRangeSettings';
import { DurationSettings } from './durationSettings/DurationSettings';

type WhenStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const WhenStep = ({ selectors, actions, isReadOnly }: WhenStepProps) => {
  return (
    <div>
      <DurationSettings selectors={selectors} actions={actions} isReadOnly={!!isReadOnly} />
      <div className="border-top-1 border-gray-200 my-3 -mx-4" />
      <DateRangeSettings selectors={selectors} actions={actions} isReadOnly={!!isReadOnly} />
      <div className="border-top-1 border-gray-200 my-3 -mx-4" />
      <BufferTimeSettings selectors={selectors} actions={actions} isReadOnly={!!isReadOnly} />
    </div>
  );
};
