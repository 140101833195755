import { t } from '../../../i18n/i18n';

export default {
  teams: t('EditBookingTemplateWhoStep:TEAMS'),
  potentialHosts: t('EditBookingTemplateWhoStep:POTENTIAL_HOSTS'),
  phoneCall: t('EditBookingTemplateWhereStep:PHONE_CALL'),
  zoom: t('EditBookingTemplateWhereStep:ZOOM'),
  googleMeet: t('EditBookingTemplateWhereStep:GOOGLE_MEET'),
  microsoftTeams: t('EditBookingTemplateWhereStep:MICROSOFT_TEAMS'),
  rescheduleWith: t('EditBookingTemplateWhoStep:RESCHEDULE_WITH'),
  rescheduleWithTooltip: t('EditBookingTemplateWhoStep:RESCHEDULE_WITH_TOOLTIP'),
  assignedHost: t('EditBookingTemplateWhoStep:ASSIGNED_HOST'),
  roundRobinToNewHost: t('EditBookingTemplateWhoStep:ROUND_ROBIN_TO_NEW_HOST'),
  available: t('Common:AVAILABLE'),
  selected: t('Common:SELECTED'),
};
