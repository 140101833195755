export const Account = Object.freeze({
  MY_ROLE_TITLE: `Meu Cargo`,
  ROLE_DESCRIPTION: `Seu cargo atual - `,
  TITLE: `Configurações da Conta`,
});

export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Você pode conectar 1 calendário`, // Para aumentar esse limite, entre em contato com vendas para atualizar seu nível de licença.
  DESCRIPTION: `Conecte seu calendário para que o SUMO saiba quando você está disponível e atualize seu calendário conforme as marcações são feitas.`,
  DISCONNECT_MESSAGE: `Tem certeza de que deseja desconectar seu calendário?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `As marcações não serão excluídas nem canceladas.`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 ou 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendário do Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendário do Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Calendário do Office 365, Outlook.com, Live.com ou Hotmail`,
  MICROSOFT_CALENDAR_TITLE: `Calendário do Office 365`,
  SELECT_CALENDAR_TITLE: `Selecionar Calendário`,
  TITLE: `Conexões de Calendário`,
  TOOLTIP: `Ligue o seu calendário Google ou Microsoft ao SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Concordar e Fechar`,
  DESCRIPTION: `Quando você visita um site, ele tem a capacidade de armazenar ou recuperar certas informações no seu navegador. Essas informações, frequentemente na forma de cookies, podem ser sobre suas preferências, dispositivo ou outros detalhes. Seu objetivo principal é garantir que o site funcione como você esperaria. Embora essas informações geralmente não o identifiquem diretamente, podem aprimorar sua experiência de navegação personalizando o conteúdo. Entendemos e valorizamos seu direito à privacidade, por isso oferecemos a opção de controlar e personalizar suas preferências de cookies. Ao clicar nas diversas categorias, você pode obter mais informações sobre cada tipo de cookie e modificar nossas configurações padrão. Observe que bloquear certos tipos de cookies pode afetar sua experiência geral no site e limitar os serviços que podemos oferecer.`,
  DESCRIPTION_FIRST_PART: `Quando você visita um site, ele tem a capacidade de armazenar ou recuperar certas informações no seu navegador. Essas informações, frequentemente na forma de cookies, podem ser sobre suas preferências, dispositivo ou outros detalhes. Seu objetivo principal é garantir que o site funcione como você esperaria.`,
  DESCRIPTION_SECOND_PART: `Embora essas informações geralmente não o identifiquem diretamente, podem aprimorar sua experiência de navegação personalizando o conteúdo. Entendemos e valorizamos seu direito à privacidade, por isso oferecemos a opção de controlar e personalizar suas preferências de cookies.`,
  DESCRIPTION_THIRD_PART: `Ao clicar nas diversas categorias, você pode obter mais informações sobre cada tipo de cookie e modificar nossas configurações padrão. Observe que bloquear certos tipos de cookies pode afetar sua experiência geral no site e limitar os serviços que podemos oferecer.`,
  FUNCTIONAL_NAME: `Cookies Funcionais`,
  MANAGE_TITLE: `Gerenciar Preferências de Consentimento`,
  NECESSARY_ONLY: `Apenas Estritamente Necessário`,
  PERFORMANCE_NAME: `Cookies de Desempenho`,
  STRICTLY_NAME: `Cookies Estritamente Necessários`,
  TARGETING_NAME: `Cookies de Segmentação`,
  TITLE: `Configurações de Cookies`,
});

export const Login = Object.freeze({
  CONTACT_US: `Por favor, entre em contato com o suporte se precisar de ajuda`,
  DESCRIPTION: `Você faz login no SUMO1 com a conta conectada a seguir`,
  GOOGLE_ACCOUNT: `Conta do Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Trocar Contas do Google`,
  GOOGLE_SWITCH: `Trocar para Login do Google`,
  GOOGLE_SWITCH_DESC: `Mudar da autenticação da Microsoft para o Google`,
  MICROSOFT_ACCOUNT: `Conta da Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Trocar Contas da Microsoft`,
  MICROSOFT_SWITCH: `Trocar para Login da Microsoft`,
  MICROSOFT_SWITCH_DESC: `Mudar da autenticação do Google para a Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Sua conta do SUMO1 perderá acesso a dados disponíveis apenas para você por meio do seu provedor original. Você também pode precisar conceder acesso novamente a integrações e calendários.`,
  SWITCH_DESCRIPTION_PART1: `Mudar da autenticação `,
  SWITCH_DESCRIPTION_PART2: ` para `,
  TITLE: `Login`,
});

export const MyLink = Object.freeze({
  LINK: `Nome do Link`,
  LINK_HELPER_TEXT: `Mínimo de 3 símbolos. Use apenas caracteres de a-z, 0-9 e -.&=_'-+,<>`,
  SUBTITLE: `Mudar sua URL do SUMO1 significa que todos os links copiados não funcionarão mais e serão atualizados`,
  TITLE: `Meu Link`,
});

export const Phone = Object.freeze({
  LABEL: `Número de Telefone`,
  TITLE: `Número(s) de Telefone`,
});

export const Profile = Object.freeze({
  DATE_FORMAT: `Formato de Data`,
  DELETE_ACCOUNT: `Excluir Conta`,
  DELETE_ACCOUNT_DIALOG_TEXT: `Tem certeza de que deseja excluir sua conta? Sua solicitação será enviada ao administrador.`,
  DELETE_ACCOUNT_REQUEST_TEXT: `A solicitação de exclusão foi enviada ao administrador.`,
  DELETE_ACCOUNT_REQUEST_TITLE: `Enviado`,
  LANGUAGE: `Idioma`,
  NAME: `Nome`,
  WELCOME_MESSAGE: `Mensagem de Boas-Vindas`,
  PHONE_DETAILS_LABELS: `Detalhes do telefone`,
  PHONE_DETAILS_PLACEHOLDER: `Adicione informações adicionais ou instruções aqui.\r\nExemplo: Estou ansioso para a reunião. Envie-me um SMS a qualquer momento.`,
  TIME_FORMAT: `Formato de Hora`,
  TIME_ZONE: `Fuso Horário`,
  TITLE: `Perfil`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: `Falha na solicitação de novo calendário`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: `Novo calendário conectado com sucesso`,
  CONNECT_ZOOM_ERROR_TOAST: `Falha na solicitação de conexão do Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST: `Zoom conectado com sucesso`,
  GET_USER_SETTINGS_FAIL: `Dados do usuário não encontrados, entre em contato com o suporte`,
  SAVE_USER_SETTINGS_ERROR_TOAST: `Falha na solicitação de salvar configurações`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST: `Suas configurações foram salvas com sucesso!`,
  UPDATE_USER_LINK_ERROR_MESSAGE: `O link já está em uso`,
  UPDATE_USER_LINK_SUCCESS_MESSAGE: `Seu link foi atualizado com sucesso!`,
});
