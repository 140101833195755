import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import countries from 'i18n-iso-countries';
import {
  CURRENT_TIME_ZONE,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_NAME,
  FILE_IMAGE_TYPES,
  MAX_FILE_SIZE_BYTES,
  languageNames,
} from '../../../types/constants';
import { DateFormat, userSettingsActions, userSettingsSelectors, TimeFormat } from '../../../store/userSettings';
import { PhoneNumber, TimeZoneSelector, UserAvatar } from '../../common';
import labels from './labels';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { PhoneNumberObject } from '../../../types/interfaces';
import { countriesTimeZonesService } from '../../../services/CountriesTimeZoneService';
import { InputTextarea } from 'primereact/inputtextarea';
import { validatePhoneNumber } from '../../../services/utils';
import { workspacesSelectors } from '../../../store/workspaces';

(async () => {
  const localeEn = await import('i18n-iso-countries/langs/en.json');
  const localeFr = await import('i18n-iso-countries/langs/fr.json');
  const localeEs = await import('i18n-iso-countries/langs/es.json');
  const localeDe = await import('i18n-iso-countries/langs/de.json');
  const localePt = await import('i18n-iso-countries/langs/pt.json');
  countries.registerLocale(localeEn);
  countries.registerLocale(localeFr);
  countries.registerLocale(localeEs);
  countries.registerLocale(localeDe);
  countries.registerLocale(localePt);
})();

export const Profile: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const phoneInfo = useSelector(userSettingsSelectors.selectPhoneInfo);
  const fullName = useSelector(userSettingsSelectors.selectFullName);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const timeZone = useSelector(userSettingsSelectors.selectTimeZone);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat);
  const phoneDetails = useSelector(userSettingsSelectors.selectPhoneDetails);
  const country = useSelector(userSettingsSelectors.selectCountry);
  const avatarLink = useSelector(userSettingsSelectors.selectAvatar);
  const isPhoneRequired = useSelector(workspacesSelectors.selectIsWorkspacePhoneRequired);

  const [localPhoneNumber, setLocalPhoneNumber] = useState<PhoneNumberObject>(phoneInfo);
  const [localFullName, setLocalFullName] = useState(fullName);
  const [localPhoneDetails, setLocalPhoneDetails] = useState(phoneDetails);
  const [localCountry, setLocalCountry] = useState(country);
  const [language, setLanguage] = useState(i18n.language);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUploadRef: any = useRef(null); // TODO: replace any
  // const deleteReqSentToast = useRef<Toast>(null);
  const fileButtonOptions = {
    label: labels.uploadPicture,
    icon: 'hidden',
    className: 'p-button-outlined',
  };

  useEffect(() => {
    setLocalFullName(fullName);
  }, [fullName]);

  useEffect(() => {
    setLocalPhoneDetails(phoneDetails);
  }, [phoneDetails]);

  useEffect(() => {
    if (phoneInfo.countryCode) {
      setLocalPhoneNumber(phoneInfo);
    }
  }, [phoneInfo]);

  useEffect(() => {
    if (country) {
      setLocalCountry(country);
    } else {
      const timezoneInf = countriesTimeZonesService.getTimezoneByName(timeZone || CURRENT_TIME_ZONE);
      const autoCountryCode = timezoneInf ? timezoneInf.countries[0] : '';

      setLocalCountry(autoCountryCode);
    }
  }, [country]);

  const handleSaveChanges = async () => {
    const updatePromises = [];

    if (localFullName) {
      updatePromises.push(dispatch(userSettingsActions.updateUserSettings({ fullName: localFullName })));
    }
    if (localPhoneDetails || localPhoneDetails === '') {
      updatePromises.push(dispatch(userSettingsActions.updateUserSettings({ phoneDetails: localPhoneDetails })));
    }
    if (localPhoneNumber.phoneNumber || localPhoneNumber.phoneNumber === '') {
      updatePromises.push(
        dispatch(userSettingsActions.updateUserSettings({ phoneNumber: localPhoneNumber.phoneNumber }))
      );
    }
    await Promise.all(updatePromises);

    dispatch(userSettingsActions.saveUserSettingsRequest());

    if (i18n.language !== language) {
      i18n.changeLanguage(language);
      window.location.reload();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileUpload = (files: any) => {
    // TODO: replace any
    files?.length && dispatch(userSettingsActions.uploadAvatarFileRequest(files[0]));
    fileUploadRef.current.clear();
  };

  const handleChangeFullName = (fullName: string) => {
    setLocalFullName(fullName);
  };

  const handleChangePhoneDetails = (phoneDetails: string) => {
    setLocalPhoneDetails(phoneDetails);
  };

  const handleLanguageChange = (language: string) => {
    if (language) {
      //i18n.changeLanguage(language);
      setLanguage(language);
      dispatch(userSettingsActions.updateUserSettings({ language }));
    }
  };

  const handleChangeTimeFormat = (timeFormat: string) => {
    timeFormat && dispatch(userSettingsActions.updateUserSettings({ timeFormat: timeFormat }));
  };

  const handleChangeDateFormat = (dateFormat: string) => {
    dateFormat && dispatch(userSettingsActions.updateUserSettings({ dateFormat: dateFormat }));
  };

  const handleChangeTimeZone = (timeZone: string) => {
    timeZone && dispatch(userSettingsActions.updateUserSettings({ timeZone }));
  };

  const handleChangeCountryCode = (countryCode: string) => {
    if (countryCode) {
      setLocalPhoneNumber((prevState) => ({ ...prevState, countryCode }));
      dispatch(userSettingsActions.updateUserSettings({ countryCode }));
    }
  };

  const handleChangeCountry = (country: string) => {
    if (country) {
      setLocalCountry(country);
      dispatch(userSettingsActions.updateUserSettings({ country }));
    }
  };

  const handlePhoneChange = (phoneNumber: string) => {
    setLocalPhoneNumber((prevState) => ({ ...prevState, phoneNumber }));
  };

  const handleAvatarRemove = () => {
    dispatch(userSettingsActions.clearAvatarFileRequest());
  };

  return (
    <div className="grid p-fluid">
      <div className="col-fixed mb-1">
        <UserAvatar large />
      </div>
      <div className="col mb-2 ml-1">
        <div className="flex">
          <FileUpload
            ref={fileUploadRef}
            mode="basic"
            auto
            accept={FILE_IMAGE_TYPES}
            maxFileSize={MAX_FILE_SIZE_BYTES}
            chooseOptions={fileButtonOptions}
            customUpload
            uploadHandler={(e) => handleFileUpload(e.files)}
          />
          <Button icon="pi pi-trash" outlined className="ml-2" onClick={handleAvatarRemove} disabled={!avatarLink} />
        </div>
        <div className="mt-1">{labels.pictureFormats}</div>
      </div>

      <div className="col-12">
        <label htmlFor="inputname">{labels.name}</label>
        <InputText
          id="inputname"
          type="text"
          value={localFullName || ''}
          onChange={(e) => handleChangeFullName(e.target.value.trimStart())}
          maxLength={MAX_LENGTH_NAME}
        />
      </div>

      <div className="col-12">
        <PhoneNumber
          label={labels.phoneNumber}
          countryCode={localPhoneNumber.countryCode || ''}
          handleChangeCode={handleChangeCountryCode}
          phoneNumber={localPhoneNumber.phoneNumber || ''}
          handleChangePhone={handlePhoneChange}
          inputClassName={`${isPhoneRequired && !validatePhoneNumber(localPhoneNumber.phoneNumber) ? 'p-invalid' : ''}`}
        />
      </div>

      <div className="col-12">
        <label htmlFor="inputPhoneDetails">{labels.phoneDetailsLabel}</label>
        <InputTextarea
          id="inputPhoneDetails"
          autoResize
          rows={4}
          value={localPhoneDetails || ''}
          onChange={(e) => handleChangePhoneDetails(e.target.value.trimStart())}
          placeholder={labels.phoneDetailsPlaceholder}
          maxLength={MAX_LENGTH_DESCRIPTION}
        />
      </div>

      <div className="col-12">
        <label htmlFor="inputlanguage">{labels.language}</label>
        <Dropdown
          id="inputlanguage"
          value={language}
          onChange={(e) => handleLanguageChange(e.target.value as string)}
          optionLabel="label"
          optionValue="value"
          options={
            i18n.options.resources &&
            Object.keys(i18n.options.resources).map((language: string) => ({
              value: language,
              label: languageNames[language],
            }))
          }
        />
      </div>

      <div className="col-12">
        <label htmlFor="inputtimeformat">{labels.timeFormat}</label>
        <Dropdown
          id="inputtimeformat"
          value={timeFormat || TimeFormat.default}
          onChange={(e) => handleChangeTimeFormat(e.target.value as string)}
          options={Object.values(TimeFormat)}
        />
      </div>

      <div className="col-12">
        <label htmlFor="inputdateformat">{labels.dateFormat}</label>
        <Dropdown
          id="inputdateformat"
          value={dateFormat || DateFormat.default}
          onChange={(e) => handleChangeDateFormat(e.target.value as string)}
          options={Object.values(DateFormat)}
        />
      </div>

      <div className="col-12">
        <label htmlFor="inputtimezone">{labels.timeZone}</label>
        <TimeZoneSelector selectedMode="full" onChange={handleChangeTimeZone} value={timeZone || CURRENT_TIME_ZONE} />
      </div>

      <div className="col-12">
        <label htmlFor="inputcountry">{labels.country}</label>
        <Dropdown
          id="inputcountry"
          value={localCountry}
          onChange={(e) => handleChangeCountry(e.target.value as string)}
          optionLabel="label"
          optionValue="value"
          options={Object.keys(countries.getNames(i18n.language)).map((country: string) => ({
            value: country || null,
            label: countries.getName(country, i18n.language),
          }))}
        />
      </div>

      <div className="col-12 mt-3 flex justify-content-center">
        <div>
          <Button
            label={labels.saveChanges}
            onClick={handleSaveChanges}
            disabled={isPhoneRequired && !validatePhoneNumber(localPhoneNumber.phoneNumber)}
          ></Button>
        </div>
      </div>
    </div>
  );
};
