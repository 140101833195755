import { t } from '../../../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  title: t('OPSConsoleAccountsMergeModal:TITLE'),
  rules: t('OPSConsoleAccountsMergeModal:RULES'),
  rule1: t('OPSConsoleAccountsMergeModal:RULE_1'),
  rule2: t('OPSConsoleAccountsMergeModal:RULE_2'),
  mergeTitle1: t('OPSConsoleAccountsMergeModal:MERGE_TITLE_PART_1'),
  mergeTitle2: t('OPSConsoleAccountsMergeModal:MERGE_TITLE_PART_2'),
  searchPlaceholder: t('OPSConsoleAccountsMergeModal:SEARCH_PLACEHOLDER'),
  note: t('OPSConsoleAccountsMergeModal:NOTE'),
};
