import { t } from '../../../../../i18n/i18n';

export default {
  inviteesCanSchedule: t('BookingPagesEditBookingPageDateRangeSettings:INVITEES_CAN_SCHEDULE'),
  inviteesCanNotSchedule: t('BookingPagesEditBookingPageDateRangeSettings:INVITEES_CAN_NOT_SCHEDULE'),
  radioGroupDaysInFutureOption: t('BookingPagesEditBookingPageDateRangeSettings:RADIO_GROUP_DAYS_IN_FUTURE_OPTION'),
  radioGroupSpecificDatesOption: t('BookingPagesEditBookingPageDateRangeSettings:RADIO_GROUP_SPECIFIC_DATES_OPTION'),
  radioGroupUnlimitedOption: t('BookingPagesEditBookingPageDateRangeSettings:RADIO_GROUP_UNLIMITED_OPTION'),
  from: t('Common:FROM'),
  to: t('Common:TO'),
};
