import { emailRegex, languageNames } from '../types/constants';

export const getBaseUrl = () => window.location.origin;

export const checkLink = (link: string) => {
  const validPattern = /^[a-z0-9-.&=_'-+,<>]*$/; // check if it only contains a-z, 0-9 and -.&=_'-+,<>
  return link.length > 2 && validPattern.test(link);
};

export const languageOptions = Object.keys(languageNames).map((key) => ({
  label: languageNames[key],
  value: key,
}));

export const validateEmail = (email: string) => emailRegex.test(email);

export const validateEmails = (emails: string[]) => {
  for (let i = 0; i < emails.length; i++) {
    if (!validateEmail(emails[i])) {
      return false;
    }
  }
  return true;
};

export const changeTheme = (theme: string) => {
  const themeEl = document.getElementById('theme-link') as HTMLLinkElement;
  if (themeEl) {
    themeEl.href = `${process.env.PUBLIC_URL}/theme/theme-${theme}/blue/theme.css`;
  }
};

// handler to download CSV
export const downloadCSV = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function isEqualArrays<T>(arr1: T[], arr2: T[]) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

export const appendPostfixToFileName = (fileName: string, postfix: string) => {
  const index = fileName.lastIndexOf('.');
  return fileName.substring(0, index) + postfix + fileName.substring(index);
};

export const validatePhoneNumber = (phoneNumber: string | null | undefined) => {
  const phoneLength = phoneNumber?.replaceAll('-', '').length || 0;
  return phoneLength >= 4 && phoneLength <= 16;
};
