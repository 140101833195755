import { t } from '../../../i18n/i18n';

export default {
  title: t('Authorise:TITLE_SING_IN'),
  subtitle: t('Authorise:SUBTITLE_SING_IN'),
  googleSignIn: t('Authorise:GOOGLE_SING_IN'),
  microsoftSignIn: t('Authorise:MICROSOFT_SING_IN'),
  doesntHaveAnAccount: t('Authorise:DOESNT_HAVE_AN_ACCOUNT'),
  signUp: t('Common:SIGN_UP'),
  login: t('Common:LOGIN'),
};
