import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { UserSettingsKeys } from '../../store/userSettings';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { excludeUrls } from '../../types/constants';

export const ChargebeePoC = () => {
  const dispatch = useDispatch();

  const tenantId = localStorage.getItem(UserSettingsKeys.TENANT_ID);

  const [quantity, setQuantity] = useState(10);
  const [increment, setIncrement] = useState(10);
  const [decrement, setDecrement] = useState(10);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (excludeUrls.some((url) => window.location.href.includes(url))) {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  const getCBInstance = () => {
    return Chargebee.inited
      ? Chargebee.getInstance()
      : Chargebee.init({
          site: process.env.REACT_APP_CHARGEBEE_SITE,
          publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLIC_KEY,
          isItemsModel: true,
        });
  };

  const subscribe = (term) => () => {
    const cbInstance = getCBInstance();
    const cart = cbInstance.getCart();
    const product = cbInstance.initializeProduct(process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + term);
    product.setPlanQuantity(quantity);
    product.setCustomData({ cf_tenantId: tenantId });
    cart.replaceProduct(product);
    cart.setCustomer({
      company: 'Test company',
      first_name: 'John',
      last_name: 'Smith',
      email: 'antonovich@sumoscheduler.com',
    });
    cart.proceedToCheckout();
  };

  const openPortal = async () => {
    const cbInstance = getCBInstance();
    cbInstance.setPortalSession(() => API.get('publicApi', '/api/js/billing/createPortalSession', {}));
    const cbPortal = cbInstance.createChargebeePortal();
    cbPortal.open();
  };

  const getBillingDetails = async () => {
    console.log(await API.get('publicApi', '/api/public/billing/getSubscriptionDetails', {}));
  };

  const incrementSeats = async () => {
    console.log(
      await API.post('publicApi', '/api/js/billing/changeSeats', {
        body: {
          change: increment,
        },
      })
    );
  };

  const decrementSeats = async () => {
    console.log(
      await API.post('publicApi', '/api/js/billing/changeSeats', {
        body: {
          change: -decrement,
        },
      })
    );
  };

  const changeToMonth = async () => {
    console.log(
      await API.post('publicApi', '/api/js/billing/changeTerm', {
        body: {
          newTerm: process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Monthly',
        },
      })
    );
  };

  const changeToYear = async () => {
    console.log(
      await API.post('publicApi', '/api/js/billing/changeTerm', {
        body: {
          newTerm: process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Yearly',
        },
      })
    );
  };

  const payLaterMonthly = async () => {
    console.log(
      await API.post('publicApi', '/api/js/billing/payLater', {
        body: {
          email: email,
          quantity: quantity,
          term: process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Monthly',
        },
      })
    );
  };

  const payLaterYearly = async () => {
    console.log(
      await API.post('publicApi', '/api/js/billing/payLater', {
        body: {
          email: email,
          quantity: quantity,
          term: process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Yearly',
        },
      })
    );
  };

  return (
    <div className="flex flex-wrap gap-6 p-6">
      <div className="flex flex-column align-items-center gap-1">
        <InputText
          id="quantity"
          type="text"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          placeholder="Quantity"
        />
        <Button label="Subscribe monthly" onClick={subscribe('Monthly')}></Button>
        <Button label="Subscribe yearly" onClick={subscribe('Yearly')}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <Button label="Go to portal" onClick={openPortal}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <Button label="Get billing details" onClick={getBillingDetails}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <InputText
          id="increment"
          type="text"
          value={increment}
          onChange={(e) => setIncrement(e.target.value)}
          placeholder="Increment"
        />
        <Button label="Increment" onClick={incrementSeats}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <InputText
          id="decrement"
          type="text"
          value={decrement}
          onChange={(e) => setDecrement(e.target.value)}
          placeholder="Decrement"
        />
        <Button label="Decrement" onClick={decrementSeats}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <Button label="Change to month" onClick={changeToMonth}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <Button label="Change to year" onClick={changeToYear}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <InputText
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <Button label="Subscribe Pay later. Monthly" onClick={payLaterMonthly}></Button>
        <Button label="Subscribe Pay later. Yearly" onClick={payLaterYearly}></Button>
      </div>
    </div>
  );
};
