export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Die folgenden Benutzer sind zugewiesen, um nur diesen Arbeitsbereich zu verwenden`,
  NOTES: `Sie müssen diese Benutzer zuerst einem anderen Arbeitsbereich zuweisen, bevor Sie diesen löschen können.`,
  TITLE: `Benutzer neu zuweisen`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Fehler beim Aktivieren/Deaktivieren des Arbeitsbereichs`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Arbeitsbereich aktiviert`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Fehler beim Löschen des Arbeitsbereichs`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Anfrage zum Löschen des Arbeitsbereichs erfolgreich`,
  GET_WORKSPACES_ERROR_MESSAGE: `Fehler bei der Abfrage von Arbeitsbereichen`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Arbeitsbereich deaktiviert`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Fehler beim Speichern des Arbeitsbereichs`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Arbeitsbereich erfolgreich gespeichert`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administratoren`,
  ADMIN_DESCRIPTION: `Die folgenden Personen dürfen diesen Arbeitsbereich verwalten.`,
  INVITE_SETTINGS_TITLE: `Einladungseinstellungen`,
  NOTES_PART_1: `Alternativ können Sie den `,
  NOTES_PART_2: `Benutzermanagement`,
  NOTES_PART_3: ` Abschnitt verwenden, um einen Benutzer zu finden und ihn einem Arbeitsbereich hinzuzufügen.`,
  PHONE_NUMBER_DESCRIPTION: `Wenn Sie SUMO für die Buchung von Telefonanrufen verwenden möchten, wird empfohlen, dies aktiviert zu lassen.`,
  PHONE_NUMBER_TITLE: `Benutzern das Feld "Telefonnummer" erforderlich machen.`,
  SEARCH_ADD_ADMINS: `Administrator suchen und hinzufügen`,
  SEARCH_ADD_USERS: `Benutzer suchen und hinzufügen`,
  SUPER_ADMINS_DESCRIPTION: `Super Admins werden immer vorab ausgefüllt`,
  USERS_DESCRIPTION: `Die folgenden Personen dürfen diesen Arbeitsbereich nutzen.`,
  USERS_TITLE: `Benutzer`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Aktiv`,
  ADDITIONAL_OPTIONS: `Zusätzliche Optionen`,
  ADMIN_TITLE: `Admin`,
  BRANDING_DESCRIPTION: `Standardmäßig verwenden alle Buchungsseiten dieses Arbeitsbereichs diese Branding-Einstellungen. Sie können die Einstellungen jedoch für diese spezielle Buchungsseite überschreiben.`,
  BRANDING_TITLE_PART_1: `Wie lauten die`,
  BRANDING_TITLE_PART_2: `Branding-Einstellungen`,
  BRANDING_TITLE_PART_3: `für diesen Arbeitsbereich?`,
  DEFAULT_WORKSPACE: `Standardarbeitsbereich`,
  DELETE_DESCRIPTION: `Sind Sie sicher, dass Sie löschen möchten`,
  EDIT_LABELS_DESCRIPTION: `Bearbeiten Sie die Bezeichnungen für alle Buchungsseiten in diesem Arbeitsbereich. Sie können diese Einstellungen bei Bedarf für bestimmte Buchungsseiten überschreiben.`,
  INACTIVE: `Inaktiv`,
  NAME_TITLE: `Arbeitsbereichsname`,
  NEW_WORKSPACE: `Neuer Arbeitsbereich`,
  PREVIEW_BUTTON: `Buchungsseite anzeigen und Bezeichnungen bearbeiten`,
  SELECTED_WORKSPACES: `ausgewählte Arbeitsbereiche`,
  TITLE: `Arbeitsbereiche`,
  TOOLTIP: `Verwalten Sie Arbeitsbereiche, die Abteilungen oder Teams einzigartige globale Einstellungen bieten.`,
  WHAT_TITLE_PART_1: `Was sind die`,
  WHAT_TITLE_PART_2: `Einstellungen dieses Arbeitsbereichs?`,
  WHO_TITLE_PART_1: `Wer kann diesen`,
  WHO_TITLE_PART_2: `Arbeitsbereich nutzen?`,
});
