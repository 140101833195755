export const ConnectIntegrationPage = Object.freeze({
  BENEFITS_TITLE: `Benefícios`,
  DISCONNECT_DESCRIPTION: `Tem certeza de que deseja desconectar `,
  GOOGLE_CALENDAR_BENEFITS: `Reserve e reagende automaticamente reuniões em seu Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Você precisa conceder acesso ao seu Google Calendar.`,
  GOOGLE_MEET_BENEFITS: `Crie automaticamente reuniões do Google Meet no momento do agendamento. Compartilhe instantaneamente detalhes exclusivos da conferência ao confirmar.`,
  GOOGLE_MEET_REQUIREMENTS: `Dependendo da sua configuração, seu administrador de conta do Google Meet pode precisar aprovar antecipadamente o SUMO1 no Google Marketplace.`,
  MICROSOFT_CALENDAR_BENEFITS: `Reserve e reagende automaticamente reuniões em seu Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Você precisa conceder acesso ao seu Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Crie automaticamente reuniões do Microsoft Teams no momento do agendamento. Compartilhe instantaneamente detalhes exclusivos da conferência ao confirmar.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Dependendo da sua configuração, seu administrador de conta do Microsoft Teams pode precisar aprovar antecipadamente o SUMO1 no Microsoft Marketplace.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  ZOOM_BENEFITS: `Crie automaticamente reuniões do Zoom no momento do agendamento. Compartilhe instantaneamente detalhes exclusivos da conferência ao confirmar.`,
  ZOOM_REQUIREMENTS: `Dependendo da sua configuração, seu administrador de conta do Zoom pode precisar aprovar antecipadamente o SUMO1 no Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Economize mais tempo automatizando videoconferências, calendários e muito mais.`,
  FILTER_ALL: `Todos`,
  FILTER_CALENDARS: `Calendários`,
  FILTER_VIDEO_CONFERENCE: `Videoconferência`,
  GOOGLE_CALENDAR_DESC: `Reserve e reagende automaticamente reuniões em seu Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MEET_DESC: `Adicione automaticamente detalhes do Google Meet às suas reuniões.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_CALENDAR_DESC: `Reserve e reagende automaticamente reuniões em seu Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Adicione automaticamente detalhes do Microsoft Teams às suas reuniões.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `Nenhum item encontrado.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  TITLE: `Integração`,
  ZOOM_DESC: `Adicione automaticamente detalhes do Zoom às suas reuniões do SUMO.`,
  ZOOM_TITLE: `Zoom Meeting`,
});
