import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { Label } from '../../common';

export const PublicBookingPageHeader = () => {
  const dispatch = useDispatch();
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const isIframe = useSelector(eventSelectors.selectIsIframe);
  const headerStyle = useSelector(eventSelectors.selectHeaderStyle);
  const topHeaderStyle = useSelector(eventSelectors.selectTopHeaderStyle);
  const showBackButton = useSelector(eventSelectors.selectShowBackButton);
  const showNextButton = useSelector(eventSelectors.selectShowNextButton);
  const eventNameLabel = useSelector(eventSelectors.selectEventNameLabel);
  const logo = useSelector(eventSelectors.selectLogo);

  const handleBack = () => {
    dispatch(eventActions.setPreviousStep());
  };

  const handleNext = () => {
    dispatch(eventActions.setNextStep());
  };

  const handleEventNameLabelChange = (eventNameLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ eventNameLabel }));
  };

  const renderSubHeaderLabel = (value: string, onChange: (value: string) => void) => (
    <Label
      editClassName="hover:text-primary"
      textClassName="text-xl font-semibold text-center"
      textStyle={headerStyle}
      editable={isEditable}
      value={value}
      onBlurUpdate={onChange}
    />
  );

  return (
    <div
      className={`flex align-items-center px-4 py-2 ${isIframe ? '' : 'md:border-round-top-xl'}`}
      style={topHeaderStyle}
    >
      {showBackButton && (
        <div className="fixed md:relative left-0 md:left-auto md:-ml-4 h-full">
          <Button
            className="h-full p-0 border-noround-left"
            style={{ width: '1.5rem' }}
            icon="pi pi-angle-left"
            text
            onClick={handleBack}
          />
        </div>
      )}
      <div className="w-fit md:w-3 flex align-items-center pr-2">
        <img src={logo || ''} className="max-h-4rem" />
      </div>
      <div className="flex-1 text-center text-xl font-semibold">
        {renderSubHeaderLabel(eventNameLabel, handleEventNameLabelChange)}
      </div>
      <div className="w-0 md:w-3"></div>
      {showNextButton && (
        <div className="fixed md:relative right-0 md:right-auto md:-mr-4 h-full">
          <Button
            className="h-full p-0 border-noround-right"
            style={{ width: '1.5rem' }}
            icon="pi pi-angle-right"
            text
            onClick={handleNext}
          />
        </div>
      )}
    </div>
  );
};
