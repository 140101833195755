import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Preloader, SectionTitle } from '../../../components/common';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { accountsActions, accountsSelectors } from '../../../store/opsConsole/accounts';
import { AccountsDeleteModal } from '../../../components/opsConsole/accounts/accountsDeleteModal/AccountsDeleteModal';
import { TenantsTable } from '../../../components/opsConsole/accounts/tenantsTable/TenantsTable';
import labels from './labels';
import { AccountsTableRowActions } from '../../../components/opsConsole/accounts/accountsTableRowActions/AccountsTableRowActions';
import { InputTextarea } from 'primereact/inputtextarea';
import { Path } from '../../../routing';
import { authentificationSelectors } from '../../../store/authentification';
import { navigationService } from '../../../services/NavigationService';

export const AccountDetails = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();

  const isFetching = useSelector(accountsSelectors.selectIsFetching);
  const accountName = useSelector(accountsSelectors.selectAccountName);
  const accountNote = useSelector(accountsSelectors.selectAccountNote);
  const accountCreatedBy = useSelector(accountsSelectors.selectAccountCreatedBy);
  const accountCreatedAt = useSelector(accountsSelectors.selectAccountCreatedAtFormatted);
  const isSaveAvailable = useSelector(accountsSelectors.selectIsSaveAvailable);
  const isDuplicate = useSelector(accountsSelectors.selectIsAccountNameDuplicate);
  const isAccountHasTenants = useSelector(accountsSelectors.selectIsAccountHasTenants);
  const isMainAdmin = useSelector(authentificationSelectors.selectIsMainAdmin);
  const staffNameOrEmail = useSelector(authentificationSelectors.selectStaffNameOrEmail);

  const [name, setName] = useState<string>(accountName);
  const [note, setNote] = useState<string>(accountNote);

  const isExisting = accountId && accountId !== 'new';

  useEffect(() => {
    setName(accountName);
  }, [accountName]);

  useEffect(() => {
    setNote(accountNote);
  }, [accountNote]);

  useEffect(() => {
    if (isExisting) {
      dispatch(accountsActions.selectAccount(accountId));
      dispatch(accountsActions.getAccountDetailsRequest());
    } else {
      dispatch(accountsActions.updateAccount({ createdBy: staffNameOrEmail }));
    }
  }, []);

  const handleBack = () => {
    navigationService.navigateTo(Path.OPSConsoleAccounts);
  };

  const handleSave = () => {
    dispatch(accountsActions.saveAccountDetailsRequest());
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleNameBlur = () => {
    dispatch(accountsActions.updateAccount({ name: name.trim() }));
  };

  const handleNoteChange = (value: string) => {
    setNote(value);
  };

  const handleNoteBlur = () => {
    dispatch(accountsActions.updateAccount({ note: note.trim() }));
  };

  return (
    <div>
      {isFetching && <Preloader />}
      {/* <AccountsMergeModal /> */}
      <AccountsDeleteModal />

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle id="accountDetailsTitle" icon="pi-building" title={labels.title} />
      </div>

      <div className="card">
        <div className="grid">
          <div className="col-12 flex align-items-center gap-3 mb-4">
            <Button icon="pi pi-arrow-left" label={labels.back} onClick={handleBack} />
            <div className="text-xl mr-auto">{accountName}</div>
            {isExisting && isMainAdmin && (
              <AccountsTableRowActions accountId={accountId} showEdit={false} disableDelete={isAccountHasTenants} />
            )}
            <Button label={labels.save} onClick={handleSave} disabled={!isSaveAvailable} />
          </div>

          <div className="col-12 md:col-6 p-fluid">
            <label>{labels.accountName}</label>
            <InputText
              className={`${isDuplicate && 'p-invalid'}`}
              maxLength={MAX_LENGTH_NAME}
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              onBlur={handleNameBlur}
            />
            {isDuplicate && (
              <small id="accountName-help" className="p-error block">
                {labels.duplicateNameMessage}
              </small>
            )}
          </div>

          <div className="col-12 p-fluid">
            <label>{labels.accountNote}</label>
            <InputTextarea
              autoResize
              rows={4}
              value={note}
              onChange={(e) => handleNoteChange(e.target.value)}
              onBlur={handleNoteBlur}
            />
          </div>

          <div className="col-12 md:col-6 p-fluid">
            <label>{labels.createdBy}</label>
            <InputText value={accountCreatedBy} disabled />
          </div>

          <div className="col-12 md:col-6 p-fluid">
            <label>{labels.createdAt}</label>
            <InputText value={accountCreatedAt} disabled />
          </div>

          <div className="col-12 mt-4">
            <div className="text-xl font-semibold mb-1">{labels.tenants}</div>
            <TenantsTable />
          </div>
        </div>
      </div>
    </div>
  );
};
