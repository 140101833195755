import { CreateUserDataInput, DeleteUserDataInput, UpdateUserDataInput, UserRecordType } from '../../API';
import { getUserDataById } from '../userSettings/service';
import { APIPostRequest } from '../utils/reduxUtils';

// ***
// When changing functions, do not forget to specify new data
// in src/docs/Availability.md
// ***

export const getUserAvailability = async (userId: string) => {
  try {
    const response: CreateUserDataInput[] = await getUserDataById(userId);
    if (response) {
      return response.filter((record) => record.recordType === UserRecordType.AVAILABILITY);;
    } else {
      throw new Error('Availability not found');
    }
  } catch (error: unknown) {
    console.error('Redux userAvailability service; Error fetching availability:', error);
    throw error;
  }
};

export const postAvailability = async (input: CreateUserDataInput) => {
  try {
    await APIPostRequest('/api/js/createAvailability', {
      data: input,
    });
  } catch (error: unknown) {
    console.error('Redux userAvailability service; Error posting availability:', error);
    throw error;
  }
};

export const updateAvailability = async (input: UpdateUserDataInput) => {
  try {
    await APIPostRequest('/api/public/updateUserData', {
      data: input,
    });
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error('Redux userAvailability service; Error saving availability:', error.message);
    } else {
      console.error('Redux userAvailability service; Error saving availability:', 'An unknown error occurred');
    }
    throw error;
  }
};

export const deleteAvailability = async (input: DeleteUserDataInput) => {
  try {
    await APIPostRequest('/api/js/deleteAvailability', {
      data: input,
    });
  } catch (error: unknown) {
    console.error('Redux userAvailability service; Error deleting availability:', error);
    throw error;
  }
};
