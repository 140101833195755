import { useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { CalendarStep } from '../../common/meeting/calendarStep/CalendarStep';

export const BookingTemplateCalendarStep = () => {
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);

  return (
    <CalendarStep selectors={bookingTemplatesSelectors} actions={bookingTemplatesActions} isReadOnly={isReadOnly} />
  );
};
