import { useDispatch, useSelector } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../../store/bookingTemplates';
import labels from './labels';

type SettingsStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const SettingsStep = ({ selectors, actions, isReadOnly }: SettingsStepProps) => {
  const dispatch = useDispatch();
  const inviteOthers = useSelector(selectors.selectInviteOthers);
  const enterNote = useSelector(selectors.selectEnterNote);

  const handleInviteOthersChange = (value: boolean) => {
    dispatch(actions.updateRecord({ inviteOthers: value }));
  };

  const handleEnterNoteChange = (value: boolean) => {
    dispatch(actions.updateRecord({ enterNote: value }));
  };

  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="flex align-items-center">
            <label>{labels.inviteOthersLabel}</label>
            <Tooltip target=".invite-others" className="max-w-30rem" />
            <i
              className="invite-others pi pi-info-circle ml-2"
              data-pr-position="top"
              data-pr-my="center bottom-10"
              data-pr-tooltip={labels.inviteOthersTooltip}
            ></i>
          </div>
          <InputSwitch
            checked={!!inviteOthers}
            onChange={(e) => handleInviteOthersChange(!!e.value)}
            disabled={isReadOnly}
          />
        </div>

        <div className="col-12 md:col-6">
          <div className="flex align-items-center">
            <label>{labels.enterNoteLabel}</label>
            <Tooltip target=".enter-note" className="max-w-30rem" />
            <i
              className="enter-note pi pi-info-circle ml-2"
              data-pr-position="top"
              data-pr-my="center bottom-10"
              data-pr-tooltip={labels.enterNoteTooltip}
            ></i>
          </div>
          <InputSwitch checked={!!enterNote} onChange={(e) => handleEnterNoteChange(!!e.value)} disabled={isReadOnly} />
        </div>
      </div>
    </div>
  );
};
