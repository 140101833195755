import { t } from '../../../i18n/i18n';

export default {
  email: t('Users:EMAIL'),
  hideInactiveUsers: t('Users:HIDE_INACTIVE_USERS'),
  inviteTitle: t('Users:INVITE_TITLE'),
  lastActive: t('Users:LAST_ACTIVE'),
  name: t('Users:NAME'),
  noResults: t('Common:NO_RESULTS_FOUND'),
  remainingMessage: t('License:REMAINING_MESSAGE'),
  role: t('Users:ROLE'),
  search: t('Common:SEARCH'),
  status: t('Users:STATUS'),
};
