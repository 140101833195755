import { Button } from 'primereact/button';
import { UserAdminDataFullRecord, UserStatus } from '../../../store/users/typings';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from '../../../store/users';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import labels from './labels';
import { confirmUsersModalActions, editUsersModalActions } from '../../../store/users/modal';
import { authentificationSelectors } from '../../../store/authentification';
import { userSettingsSelectors } from '../../../store/userSettings';

type UserRowActionsProps = {
  data: UserAdminDataFullRecord;
  setConfirmDialogProps: (isEdit: boolean, isDisable: boolean) => void;
};

export const UserRowActions = ({ data, setConfirmDialogProps }: UserRowActionsProps) => {
  const dispatch = useDispatch();
  const remainingLicenses = useSelector(authentificationSelectors.selectRemainingLicenses);
  const currentUserId = useSelector(authentificationSelectors.selectUserId);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isEditUsers = useSelector(userSettingsSelectors.selectUsersEdit);
  const isDeleteUsers = useSelector(userSettingsSelectors.selectUsersDelete);
  const supportedRolesId = useSelector(userSettingsSelectors.selectSupportedRolesId);
  const userId = useSelector(authentificationSelectors.selectUserId);
  const menu = useRef<Menu | null>(null);

  // Define menu items
  const menuItems = [
    {
      label: labels.edit,
      icon: 'pi pi-pencil',
      command: () => {
        setConfirmDialogProps(true, true);
        if (data && data.status === UserStatus.InviteSent) {
          dispatch(usersActions.getInviteUsersOptionsRequest());
        }
        dispatch(editUsersModalActions.openModal());
      },
      visible: isEditUsers,
    },
    {
      label: labels.deactivate,
      icon: 'pi pi-ban',
      command: () => {
        setConfirmDialogProps(false, true);
        dispatch(confirmUsersModalActions.openModal());
      },
      visible:
        data &&
        data.status === UserStatus.Active &&
        isEditUsers &&
        data.userId !== userId &&
        supportedRolesId.includes(data.roleId),
    },
    {
      label: labels.activate,
      icon: 'pi pi-check-circle',
      command: () => {
        dispatch(usersActions.updateUserRecord({ status: UserStatus.Active }));
        dispatch(usersActions.disableUserRecordRequest());
      },
      visible:
        data &&
        data.status === UserStatus.Inactive &&
        isEditUsers &&
        supportedRolesId.includes(data.roleId),
      disabled: remainingLicenses < 1,
    },
    {
      label: labels.delete,
      icon: 'pi pi-trash',
      command: () => {
        setConfirmDialogProps(false, false);
        dispatch(confirmUsersModalActions.openModal());
      },
      visible:
        data.userId !== userId &&
        (isSuperAdmin ||
          (isDeleteUsers &&
            data.status === UserStatus.InviteSent &&
            data.invitedById === currentUserId)),
    },
  ];

  return (
    <>
      {(isEditUsers ||
        isSuperAdmin ||
        (isDeleteUsers &&
          data.status === UserStatus.InviteSent &&
          data.invitedById === currentUserId)) && (
        <>
          <Button
            icon="pi pi-ellipsis-h text-color"
            text
            onClick={(e) => {
              dispatch(usersActions.setUserRecord(data));
              return menu.current?.toggle && menu.current.toggle(e);
            }}
          />
          <Menu model={menuItems} popup ref={menu} />
        </>
      )}
    </>
  );
};
