import { IntegrationType } from '../../API';
import { IntegrationFilter } from './types';

export enum IntegrationActionTypes {
  SET_DEFAULT_INTEGRATIONS = 'Integration/SET_DEFAULT_INTEGRATIONS',
  SET_CONNECT_INTEGRATION_TYPE = 'Integration/SET_CONNECT_INTEGRATION_TYPE',
  SET_INTEGRATION_FILTER = 'Integration/SET_INTEGRATION_FILTER',
}

export type IntegrationAction =
  | { type: IntegrationActionTypes.SET_DEFAULT_INTEGRATIONS }
  | { type: IntegrationActionTypes.SET_CONNECT_INTEGRATION_TYPE; payload: IntegrationType }
  | { type: IntegrationActionTypes.SET_INTEGRATION_FILTER; payload: IntegrationFilter };

const setDefaultIntegration = (): IntegrationAction => ({
  type: IntegrationActionTypes.SET_DEFAULT_INTEGRATIONS,
});

const setConnectIntegrationType = (payload: IntegrationType): IntegrationAction => ({
  type: IntegrationActionTypes.SET_CONNECT_INTEGRATION_TYPE,
  payload,
});

const setIntegrationFilter = (payload: IntegrationFilter): IntegrationAction => ({
  type: IntegrationActionTypes.SET_INTEGRATION_FILTER,
  payload,
});

export const integrationActions = { setDefaultIntegration, setConnectIntegrationType, setIntegrationFilter };
