export const CalendarsEnum = {
  GOOGLE_CALENDAR: 'GOOGLE_CALENDAR',
  MICROSOFT_CALENDAR: 'MICROSOFT_CALENDAR',
};

export const VideoConferencesEnum = {
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS',
  GOOGLE_MEET: 'GOOGLE_MEET',
  ZOOM: 'ZOOM',
};

export const calendars = [
  // TODO: remove and use only CalendarsEnum
  CalendarsEnum.GOOGLE_CALENDAR,
  CalendarsEnum.MICROSOFT_CALENDAR,
];

export const videoConferences = [
  // TODO: remove and use only VideoConferencesEnum
  VideoConferencesEnum.MICROSOFT_TEAMS,
  VideoConferencesEnum.GOOGLE_MEET,
  VideoConferencesEnum.ZOOM,
];

export const integrationsByPriority = [...videoConferences, ...calendars];

export const replacedNotificationKeys = {
  meetingName: 'Meeting Name',
  meetingDate: 'Meeting Date',
  meetingTime: 'Meeting Time',
  meetingLocation: 'Meeting Location',
  meetingDescription: 'Meeting Description',
  meetingLink: 'Meeting Link',
  viewEditMeetingLink: 'View or Edit Meeting Link',
  cancelReason: 'Cancel Reason',
  hostName: 'Host Name',
  hostEmail: 'Host Email',
  hostPhone: 'Host Phone',
  hostPhoneDetails: 'Host Phone Details',
  inviteeName: 'Invitee Name',
  inviteeEmail: 'Invitee Email',
};

export const eventActions = {
  create: 'CREATE',
  cancel: 'CANCEL',
  update: 'UPDATE',
};

export const workspaceActions = {
  createWorkspace: 'create workspace',
  updateWorkspace: 'update workspace',
  deleteWorkspace: 'delete workspace',
};

export const adminRoles = {
  sumoAdmin: 'Sumo Admin',
  superAdmin: 'Super Admin',
  workspaceAdmin: 'Workspace Admin',
  standardUser: 'Standard User',
};

export const adminDataStatus = {
  inviteSent: 'Invite Sent',
  active: 'Active',
  inactive: 'Inactive',
};

export const licenseType = {
  TRIAL: 'TRIAL',
  ANNUAL: 'ANNUAL',
  MONTHLY: 'MONTHLY',
};

export const tenantStatus = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
};

export const STAFF_ROLES = {
  MAIN_ADMIN: 'MAIN_ADMIN',
  OPERATIONS: 'OPERATIONS',
  SUPPORT: 'SUPPORT',
};

export const SUMO1TenantId = 'SUMO1';

export const verifiedSESEmails = {
  noReplySumoEmail: 'noreply@sumoscheduler.com',
};

export const SUMO1InvitedBy = 'SUMO1 Admin';

export const integrationsCompatibility = {
  MICROSOFT_TEAMS: [VideoConferencesEnum.MICROSOFT_TEAMS, VideoConferencesEnum.ZOOM, CalendarsEnum.MICROSOFT_CALENDAR],
  MICROSOFT_CALENDAR: [
    VideoConferencesEnum.MICROSOFT_TEAMS,
    VideoConferencesEnum.ZOOM,
    CalendarsEnum.MICROSOFT_CALENDAR,
  ],
  GOOGLE_MEET: [VideoConferencesEnum.GOOGLE_MEET, VideoConferencesEnum.ZOOM, CalendarsEnum.GOOGLE_CALENDAR],
  GOOGLE_CALENDAR: [VideoConferencesEnum.GOOGLE_MEET, VideoConferencesEnum.ZOOM, CalendarsEnum.GOOGLE_CALENDAR],
  ZOOM: [
    VideoConferencesEnum.GOOGLE_MEET,
    VideoConferencesEnum.ZOOM,
    CalendarsEnum.GOOGLE_CALENDAR,
    VideoConferencesEnum.MICROSOFT_TEAMS,
    CalendarsEnum.MICROSOFT_CALENDAR,
  ],
};

export const DEFAULT_STYLE = {
  avatar: null,
  backgroundImage: null,
  opacity: 60,
  css: null,
  footer: `<a href="https://sumoscheduler.com" style="color: rgb(11, 122, 209)" target="_blank">Powered by SUMO</a>`,
  lookAndFeel: {
    siteColorPrimary: '#FFFFFF',
    siteColorButtons: '#2196F3',
    siteColorBackground: '#F5F5F5',
    fontColorHeader: '#495057',
    fontColorSubHeader: '#495057',
    fontColorMainText: '#495057',
    fontHeader: 'Segoe UI',
    fontSubheader: 'Segoe UI',
    fontMainText: 'Segoe UI',
  },
};

export const DEFAULT_LABELS = {
  additionalLabel: 'More Options',
  bookButtonLabel: 'Book Meeting',
  bookAnotherButtonLabel: 'Book Another Meeting',
  bookDescription: 'An email confirmation has been sent to your inbox.',
  bookTitle: 'Meeting Booked!',
  cancelButtonLabel: 'Cancel Meeting',
  cancelLabel: 'Cancel',
  dateLabel: 'Select a Date',
  detailsLabel: 'Meeting Details',
  eventNameLabel: 'Book a Meeting',
  informationLabel: 'Your Information',
  timeLabel: 'Select a Time',
  updateButtonLabel: 'Reschedule',
};

export const CRUD = {
  READ: 'READ',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const TENANT_TYPE = {
  TRIAL: 'TRIAL',
  TEST: 'TEST',
  CUSTOMER: 'CUSTOMER',
};

export const cryptoSalt = 'ab$45';

export const getLink = (link, label) => `
  <a href="${link}" target="_blank" style="text-decoration: none;">
    ${label}
  </a>
`;
