import { t } from '../../../i18n/i18n';

export default {
  acceptTerms_step1: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART1'),
  acceptTerms_step2: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART2'),
  acceptTerms_step3: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART3'),
  back: t('Common:BACK'),
  description: t('QuickSetupSetupPhoneCallsStep:DESCRIPTION'),
  message: t('QuickSetupSetupPhoneCallsStep:MESSAGE'),
  next: t('Common:NEXT'),
  phoneDetailsLabel: t('QuickSetupSetupPhoneCallsStep:PHONE_DETAILS_LABELS'),
  phoneDetailsPlaceholder: t('QuickSetupSetupPhoneCallsStep:PHONE_DETAILS_PLACEHOLDER'),
  phoneLabel: t('QuickSetupSetupPhoneCallsStep:PHONE_LABEL'),
  phonePlaceholder: t('QuickSetupSetupPhoneCallsStep:PHONE_PLACEHOLDER'),
  save: t('QuickSetupYourBrandStep:SAVE_BUTTON'),
  title: t('QuickSetupSetupPhoneCallsStep:TITLE'),
};
