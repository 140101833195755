import ct from 'countries-and-timezones';

class CountriesTimeZonesService {
  getCountryById(id: ct.CountryCode): ct.Country {
    return ct.getCountry(id);
  }

  getTimezoneByName(name: string): ct.Timezone | null {
    return ct.getTimezone(name);
  }

  getAllTimeZones(): ct.Timezone[] {
    const timeZonesObject: Record<ct.TimezoneName, ct.Timezone> = ct.getAllTimezones();
    return Object.values(timeZonesObject);
  }
}

export const countriesTimeZonesService = new CountriesTimeZonesService();
