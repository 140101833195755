import { InputText } from 'primereact/inputtext';
import { ConfirmationModal, Preloader, SectionTitle } from '../../../components/common';
import labels from './labels';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import {
  NEW_DEFAULT_STAFF_RECORD,
  STAFF_ROLE_OPTIONS,
  STAFF_STATUS_OPTIONS,
  deactivateStaffModalActions,
  deactivateStaffModalSelectors,
  deleteStaffModalActions,
  deleteStaffModalSelectors,
  staffActions,
  staffSelectors,
} from '../../../store/opsConsole/staff';
import { Column } from 'primereact/column';
import { formatDateTimeThroughGap } from '../../../services/DateService';
import { useEffect } from 'react';
import { StaffRowActions, Table } from '../../../components/opsConsole';
import { Path } from '../../../routing';
import { Button } from 'primereact/button';
import { Staff } from '../../../API';
import { authentificationSelectors } from '../../../store/authentification';
import { navigationService } from '../../../services/NavigationService';
import { opsConsoleSelectors } from '../../../store/opsConsole/common/selectors';
import { opsConsoleActions } from '../../../store/opsConsole/common/actions';
import { DataTableRowClickEvent } from 'primereact/datatable';

export const StaffPage = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(staffSelectors.selectIsFetching);
  const searchString = useSelector(opsConsoleSelectors.selectSearchString);
  const staff = useSelector(staffSelectors.selectSearchStaff);
  const staffName = useSelector(staffSelectors.selectStaffNameOrEmail);
  const isPaginatorEnabled = useSelector(staffSelectors.selectIsPaginatorEnabled);
  const isMainAdmin = useSelector(authentificationSelectors.selectIsMainAdmin);
  const isMainAdminOrOperations = useSelector(authentificationSelectors.selectIsMainAdminOrOperations);
  const userId = useSelector(authentificationSelectors.selectUserId);

  const deactivateModalOpen = useSelector(deactivateStaffModalSelectors.selectIsModalOpen);
  const deleteModalOpen = useSelector(deleteStaffModalSelectors.selectIsModalOpen);

  useEffect(() => {
    dispatch(staffActions.getStaffRequest());
  }, []);

  const openStaffDetails = (staffRecord: Staff) => {
    if (isMainAdminOrOperations && staffRecord.userId) {
      dispatch(staffActions.setStaffRecord(staffRecord));
      navigationService.navigateTo(Path.OPSConsoleStaffDetails);
    }
  };

  const handleAddNew = () => {
    dispatch(staffActions.setStaffRecord(NEW_DEFAULT_STAFF_RECORD));
    dispatch(staffActions.setIsAddNew(true));
    navigationService.navigateTo(Path.OPSConsoleStaffDetails);
  };

  const handleDeactivateClose = () => {
    dispatch(deactivateStaffModalActions.closeModal());
  };

  const handleDeleteClose = () => {
    dispatch(deleteStaffModalActions.closeModal());
  };

  const renderStaffRowActions = (data: Staff, isMainAdminOrOperations: boolean, isMainAdmin: boolean, userId: string) => {
    const shouldShowActions = (isMainAdminOrOperations && !!data.userId) || (isMainAdmin && userId !== data.userId);
    return shouldShowActions ? <StaffRowActions staffRecord={data} showDelete={isMainAdmin} /> : null;
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle
          id="staffPageTitle"
          icon="pi-users"
          title={labels.title}
        />
        {isMainAdminOrOperations && (
          <div className="-my-2 p-accordion-header-text">
            <Button label={labels.addStaff} onClick={handleAddNew} />
          </div>
        )}
      </div>
      <div className="card">
        <div className="my-3 pb-1 p-inputgroup w-12 md:w-6">
          <span className="p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </span>
          <InputText
            placeholder={labels.search}
            value={searchString}
            onChange={(e) => dispatch(opsConsoleActions.setSearchString(e.target.value))}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <Divider />
        <Table
          value={staff}
          paginator={isPaginatorEnabled}
          className="pt-3"
          onRowClick={(e: DataTableRowClickEvent) => openStaffDetails(e.data as Staff)}
          rowClassName={(data) => (isMainAdminOrOperations && data.userId ? 'cursor-pointer' : '')}
          rowHover
          paths={[Path.OPSConsoleStaff, Path.OPSConsoleStaffDetails]}
        >
          <Column field="userName" header={labels.name} sortable />
          <Column field="email" header={labels.email} sortable />
          <Column
            field="roleName"
            body={(data: Staff) => STAFF_ROLE_OPTIONS.find((role) => role.value === data.roleName)?.label}
            header={labels.role}
            sortable
          />
          <Column
            field="status"
            body={(data: Staff) => STAFF_STATUS_OPTIONS.find((status) => status.value === data.status)?.label}
            header={labels.status}
            sortable
          />

          <Column
            field="updatedAt"
            header={labels.lastActive}
            sortable
            body={(data: Staff) => (data.userId ? formatDateTimeThroughGap(data.updatedAt) : '')}
          />
          <Column
            body={(data: Staff) => renderStaffRowActions(data, isMainAdminOrOperations, isMainAdmin, userId)}
            className="w-4rem"
            align="center"
            frozen
          />
        </Table>
      </div>
      <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateMessage} "${staffName}"?`}
        visible={deactivateModalOpen}
        onClose={handleDeactivateClose}
        onCancel={handleDeactivateClose}
        onConfirm={() => {
          dispatch(staffActions.changeStaffStatusRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTitle}
        description={`${labels.deleteMessage} "${staffName}"?`}
        visible={deleteModalOpen}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={() => {
          dispatch(staffActions.deleteStaffRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
    </div>
  );
};
