export const EditTeam = Object.freeze({
  AVAILABLE_FOR_USE: `Disponível para uso`,
  AVAILABLE_FOR_USE_TOOLTIP: `Isso ativará ou desativará este time em todas as ferramentas de reserva, incluindo páginas de reserva, modelos de reserva e muito mais.`,
  EDIT_TEAM: `Editar equipe`,
  MEMBERS_LABEL: `Adicionar membros da equipe`,
  MEMBERS_PLACEHOLDER: `Pesquisar e adicionar membro da equipe`,
  ROUND_ROBIN_DESC_PART1: `Round-Robin`,
  ROUND_ROBIN_DESC_PART2: `Para que o SUMO1 atribua automaticamente um anfitrião a uma reunião, você deve criar uma equipe (também chamada de pool de recursos) de usuários SUMO1. Em seguida, os usuários e administradores podem adicionar equipes às reuniões por meio das páginas de reserva ou da caixa de entrada do SUMO (extensão do Chrome).`,
  TEAM_NAME: `Nome de exibição`,
  TEAM_NAME_TOOLTIP: `Este texto será apresentado aos convidados na página pública de reservas. Por exemplo: “Equipa de engenheiros de vendas” ou “Equipa de dentistas” seriam bons nomes.`,
  TEAM_NAME_PLACEHOLDER: `Digite um nome de equipe`,
  WORKSPACES: `Espaços de trabalho`,
  WORKSPACES_LABEL: `Espaço de trabalho padrão`,
  WORKSPACES_PLACEHOLDER: `Pesquisar e adicionar espaço de trabalho`,
  DEFAULT_WORKSPACE_TOOLTIP: `Espaço de trabalho onde a equipe foi criada e pode ser modificada ou excluída.`,
});

export const TeamsToastsNotifications = Object.freeze({
  ACTIVATE_TEAM_SUCCESS_MESSAGE: `Equipe ativada com sucesso`,
  DEACTIVATE_ACTIVATE_TEAM_ERROR_MESSAGE: `Falha na desativação/ativação da equipe`,
  DEACTIVATE_TEAM_SUCCESS_MESSAGE: `Equipe desativada com sucesso`,
  DELETE_TEAM_ERROR_MESSAGE: `Falha na exclusão da equipe`,
  DELETE_TEAM_SUCCESS_MESSAGE: `Equipe excluída com sucesso`,
  GET_TEAMS_FAIL_MESSAGE: `Falha na solicitação de equipes`,
  SAVE_TEAM_ERROR_MESSAGE: `Falha na solicitação de salvar a equipe`,
  SAVE_TEAM_SUCCESS_MESSAGE: `Equipe salva com sucesso`,
});

export const Teams = Object.freeze({
  CREATE_TEAM: `Criar equipe`,
  CREATED_BY: `Criado por`,
  CREATED_DATE: `Data de criação`,
  DEACTIVATE_TEAM_DESCRIPTION: `Tem certeza de que deseja desativar`,
  DEACTIVATE_TEAM_TITLE: `Desativar equipe`,
  DELETE_TEAM_DESCRIPTION: `Tem certeza de que deseja excluir`,
  DELETE_TEAM_TITLE: `Excluir equipe`,
  MEMBERS: `Membros`,
  NAME: `Nome`,
  TITLE: `Equipes`,
  TOOLTIP: `As equipes são importantes para definir se você deseja que o SUMO atribua automaticamente um anfitrião ou coanfitrião a uma reunião, com base na lógica de atribuição (Round-Robin, etc.).`,
  WORKSPACE: `Espaço de trabalho`,
});
