import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { Avatar } from 'primereact/avatar';

interface UserAvatarProps {
  large?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ large }) => {
  const avatarLink = useSelector(userSettingsSelectors.selectAvatar);
  const fullName = useSelector(userSettingsSelectors.selectFullName);

  return (
    <Avatar
      image={avatarLink || ''}
      label={fullName?.charAt(0).toUpperCase() || 'A'}
      shape="circle"
      className={`surface-400 text-white font-semibold ${large ? 'w-4rem h-4rem text-2xl' : ''}`}
    />
  );
};
