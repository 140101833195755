import { InitializeModalSettings, ModalName } from './typings';

export enum ModalsActionTypes {
  INITIALIZE_MODAL = 'modals/INITIALIZE_MODAL',
  DELETE_MODAL = 'modals/DELETE_MODAL',
  OPEN_MODAL = 'modals/OPEN_MODAL',
  CLOSE_MODAL = 'modals/CLOSE_MODAL',
}

export type ModalsAction =
  | { type: ModalsActionTypes.INITIALIZE_MODAL; payload: InitializeModalSettings }
  | { type: ModalsActionTypes.DELETE_MODAL; key: ModalName }
  | { type: ModalsActionTypes.OPEN_MODAL; key: ModalName; payload?: Object }
  | { type: ModalsActionTypes.CLOSE_MODAL; key: ModalName }
  | { type: 'never' }; // resolves typing error inside rootStore;

const initializeModal = (payload: InitializeModalSettings): ModalsAction => ({
  type: ModalsActionTypes.INITIALIZE_MODAL,
  payload,
});
const deleteModal = (key: ModalName): ModalsAction => ({ type: ModalsActionTypes.DELETE_MODAL, key });
const openModal =
  (key: ModalName) =>
  (payload?: Object): ModalsAction => ({
    type: ModalsActionTypes.OPEN_MODAL,
    key,
    payload,
  });
const closeModal = (key: ModalName) => (): ModalsAction => ({
  type: ModalsActionTypes.CLOSE_MODAL,
  key,
});

export const modalsActions = {
  initializeModal,
  deleteModal,
  closeModal,
};

export const modalsActionsFactory = (key: ModalName) => ({
  openModal: openModal(key),
  closeModal: closeModal(key),
});
