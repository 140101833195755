import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Modal } from '../../../common';
import {
  DELETE_ACCOUNT_MODAL_NAME,
  accountsActions,
  accountsSelectors,
  deleteAccountModalActions,
} from '../../../../store/opsConsole/accounts';
import labels from './labels';

export const AccountsDeleteModal = () => {
  const dispatch = useDispatch();
  const accountName = useSelector(accountsSelectors.selectSelectedAccountName);

  const handleCancel = () => {
    dispatch(deleteAccountModalActions.closeModal());
  };

  const handleDelete = () => {
    dispatch(accountsActions.deleteAccountRequest());
  };

  return (
    <Modal.Container name={DELETE_ACCOUNT_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="max-w-30rem">
        <div className="mb-3">
          {`${labels.title} "${accountName}" ?`}
        </div>
        <div className="mb-3">
          {labels.description}
        </div>
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined autoFocus />
          <Button label={labels.title} onClick={handleDelete} severity="danger"/>
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
