import { t } from '../../i18n/i18n';

export default {
  learnMore: t('Common:LEARN_MORE'),
  newWorkspace: t('Workspaces:NEW_WORKSPACE'),
  search: t('Common:SEARCH'),
  selected: t('Common:SELECTED'),
  noResults: t('Common:NO_RESULTS_FOUND'),
  title: t('Workspaces:TITLE'),
  tooltip: t('Workspaces:TOOLTIP'),
};
