import { FC, Fragment } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { calcFromToTimeError, checkTimeOverlapping, formatDateHHMM } from '../../../services/DateService';
import { TimeFromToInput, WeekDay, WeeklyHoursInput } from '../../../API';
import { ModifyWeeklyHours } from '../../../types/types';
import { DEFAULT_AVAILABILITY_TIME, WEEKDAY_LABEL_BY_TYPE } from '../../../store/availability';
import { TimeFormat } from '../../../store/userSettings/typings';
import { styles } from './styles';
import labels from './labels';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';

interface AvailabilityTableProps {
  weeklyHours: Array<WeeklyHoursInput | null>;
  onChange: (weeklyHours: Array<WeeklyHoursInput | null>) => void;
  readonly?: boolean;
}

export const AvailabilityTable: FC<AvailabilityTableProps> = ({ weeklyHours, onChange, readonly }) => {
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;

  const modifyWeeklyHours = (type: WeekDay, callback: ModifyWeeklyHours) => {
    onChange(weeklyHours.map((day: WeeklyHoursInput | null) => (day?.type === type ? callback(day) : day)) || []);
  };

  const handleTimeFromChange = (type: WeekDay, index: number, value: Date) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;

      return {
        ...day,
        time:
          day.time?.map((time: TimeFromToInput | null, i: number) => {
            if (!time) return null;
            return i === index ? { ...time, from: formatDateHHMM(value, TimeFormat.military) } : time;
          }) || [],
      };
    });
  };

  const handleTimeToChange = (type: WeekDay, index: number, value: Date) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;
      const updatedDay = {
        ...day,
        time:
          day.time?.map((time: TimeFromToInput | null, i: number) => {
            if (!time) return null;
            return i === index ? { ...time, to: formatDateHHMM(value, TimeFormat.military) } : time;
          }) || [],
      };
      return updatedDay;
    });
  };

  const addInterval = (type: WeekDay) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;

      return {
        ...day,
        time: [...(day.time || []), DEFAULT_AVAILABILITY_TIME],
      };
    });
  };

  const removeInterval = (type: WeekDay, index: number) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;

      return {
        ...day,
        time: day.time?.filter((_: TimeFromToInput | null, i: number) => i !== index) || [],
      };
    });
  };

  return (
    <div className="flex flex-column justify-content-between">
      {weeklyHours.map((day, idx, arr) => (
        <Fragment key={day?.type}>
          <div className="flex gap-3 align-items-center justify-content-between">
            <div className="flex-1 flex align-items-center">
              <div className="mr-2 font-semibold" style={styles.dayType}>
                {WEEKDAY_LABEL_BY_TYPE.get(day?.type || WeekDay.MON)}
              </div>

              <div className="flex-1 flex flex-column gap-3">
                {day?.time?.length ? (
                  day?.time.map((time, index) => (
                    <div key={index}>
                      <div style={styles.timeContainer} className="flex gap-3">
                        <Calendar
                          inputClassName="text-center"
                          style={styles.timeInput}
                          dateFormat="HH:mm"
                          hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
                          value={new Date(`1970-01-01T${time?.from}:00`)}
                          timeOnly
                          onChange={(e) => {
                            e.target.value instanceof Date &&
                              handleTimeFromChange(day?.type as WeekDay, index, e.target.value);
                          }}
                          disabled={readonly}
                        />
                        <span>-</span>
                        <Calendar
                          inputClassName="text-center"
                          style={styles.timeInput}
                          dateFormat="HH:mm"
                          hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
                          value={new Date(`1970-01-01T${time?.to}:00`)}
                          timeOnly
                          onChange={(e) => {
                            e.target.value instanceof Date &&
                              handleTimeToChange(day?.type as WeekDay, index, e.target.value);
                          }}
                          disabled={readonly}
                        />
                        <Button
                          icon="pi pi-trash"
                          text
                          onClick={() => removeInterval(day?.type as WeekDay, index)}
                          disabled={readonly}
                        />
                      </div>
                      {calcFromToTimeError(time?.from, time?.to) && (
                        <div id="from-to-time-help" className="p-error block">
                          {labels.timeInValidText}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="unavailable-text">{labels.unavailable}</div>
                )}
                {day?.time && checkTimeOverlapping(day?.time) && (
                  <div id="from-to-time-help" className="p-error block">
                    {labels.timeOverlappingText}
                  </div>
                )}
              </div>
            </div>

            <Button icon="pi pi-plus" text onClick={() => addInterval(day?.type as WeekDay)} disabled={readonly} />
          </div>
          {idx !== arr.length - 1 && <div className="border-top-1 border-gray-200" style={{ margin: '1rem -1rem' }} />}
        </Fragment>
      ))}
    </div>
  );
};
