export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Background`,
  BUTTONS_LABEL: `Buttons`,
  FONT_COLORS_TITLE: `Font Colors`,
  FONT_FAMILIES_TITLE: `Font Families`,
  HEADER_FONT_LABEL: `Header Font`,
  HEADER_LABEL: `Header`,
  MAIN_TEXT_FONT_LABEL: `Main Text Font`,
  MAIN_TEXT_LABEL: `Main Text`,
  PRIMARY_LABEL: `Primary`,
  SITE_COLORS_TITLE: `Site Colors`,
  SUB_HEADER_FONT_LABEL: `Sub-Header Font`,
  SUB_HEADER_LABEL: `Sub-Header`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `Your calendar is connected!`,
  INSTRUCTION_NOTE: `Most Admins like to use SUMO for their own personal and testing use. However, you may always disconnect your calendar later in the top right menu.`,
  INSTRUCTION_STEP_A1: `We'll check `,
  INSTRUCTION_STEP_A2: ` for conflicts.`,
  INSTRUCTION_STEP_B: `We'll add meetings to the calendar for `,
  INSTRUCTION_STEP_C1: `We'll encrypt `,
  INSTRUCTION_STEP_C2: `all data`,
  INSTRUCTION_STEP_C3: ` in transit and give you options regarding your data.`,
  INSTRUCTION_TITLE: `Here's how SUMO will work with `,
  MESSAGE: `SUMO will automatically check your calendar for busy times and add new meetings as they are scheduled.`,
  TITLE: `Calendar`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Accept Terms`,
  ACCEPT_TERMS_PART2: `and`,
  ACCEPT_TERMS_PART3: `Privacy Policy`,
  BENEFITS_DESCRIPTION: `The best SUMO1 Experience is using SUMO from within your inbox, by installing the Chrome Extension. Use SUMO in:`,
  BENEFITS_LIST_PART1: `Google Gmail & Calendar`,
  BENEFITS_LIST_PART2: `Microsoft Inbox & Calendar`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Benefits`,
  CHROME_EXTENSION: `Chrome Extension`,
  REQUIREMENTS_DESCRIPTION: `You must use the Google Chrome Browser.`,
  REQUIREMENTS_TITLE: `Requirements`,
  SUMO1_FOR_GOOGLE: `Install "SUMO1 for Gmail"`,
  SUMO1_FOR_MICROSOFT: `Install "SUMO1 for Outlook"`,
  TITLE: `Install Chrome Extension`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  ADMIN_DESCRIPTION: `When is your team typically available for appointments?`,
  MESSAGE: `TIP: You can set multiple schedules and overrides later.`,
  MINIMUM_DAYS_ERROR_TEXT: `Please select at least one day with available hours`,
  SETUP_DAYS_INPUT_LABEL: `Available days`,
  SETUP_HOURS_INPUT_LABEL: `Available hours`,
  TITLE: `Availability`,
  USER_DESCRIPTION: `When are you typically available for appointments?`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `When SUMO automatically books a phone call for you, what phone number should the guest call to reach you?`,
  MESSAGE: `This is an important step in the SUMO setup process. Most users input their mobile phone number, so they may also receive SMS alerts. Of course, you may always change this later in User Settings (top right menu).`,
  PHONE_DETAILS_LABELS: `Phone Details`,
  PHONE_DETAILS_PLACEHOLDER: `Add additional info or instructions here.\r\nEx: I look forward to meeting. SMS Text me anytime.`,
  PHONE_LABEL: `Your Phone Number`,
  PHONE_PLACEHOLDER: `Enter your phone number here`,
  TITLE: `Phone Calls`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Quick Setup save request fail`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `You are now ready for easy scheduling.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Setup complete!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  COMPLETED_BUTTON_LABEL: `I have completed this.`,
  DEFAULT_DESCRIPTION: `Default Video Conference`,
  DESCRIPTION: `Connect your video conference account/s.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MESSAGE: `Optional, but recommended for virtual meetings.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  TITLE: `Video Conference`,
  ZOOM_DESC: `Automatically add Zoom details in your SUMO meetings.`,
  ZOOM_REQUIREMENTS: `Depending on your configuration, your Zoom account administrator may be required to pre-approve SUMO1 in the Zoom Marketplace.`,
  ZOOM_STEP_1: `Login to your corporate ZOOM account as an Admin.`,
  ZOOM_STEP_2: `Click on settings in the top right.`,
  ZOOM_STEP_3: `Search the ZOOM marketplace for "SUMO1."`,
  ZOOM_STEP_4: `Click "Authorize SUMO1.`,
  ZOOM_STEP_5: `Click accept.`,
  ZOOM_TITLE: `ZOOM Meetings`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  COLORS_NOTES: `Don't worry, you can modify and test these settings on Booking Pages later.`,
  COLORS_TITLE: `Colors & Fonts`,
  BACKGROUND: `Background`,
  DESCRIPTION: `Make the booking experience reflect your company brand.`,
  LOGO: `Logo`,
  LOGO_DESCRIPTION: `Your company branding will appear on all booking pages in this workspace.`,
  LOGO_TITLE: `Your Logo & Background`,
  PREVIEW_TOOLTIP: `After adjusting settings, click Preview to view a sample booking page.`,
  SAVE_BUTTON: `Save & Complete Setup `,
  TITLE: `Your Brand`,
  UPLOAD_BACKGROUND: `Upload Background`,
  UPLOAD_LOGO: `Upload Logo`,
  YOUR_BACKGROUND: `Your Background`,
  YOUR_LOGO: `Your Logo`,
});
