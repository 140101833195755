import { API } from 'aws-amplify';
import { CreateStaffInput, Staff } from '../../../API';
import { APIPostRequest } from '../../utils/reduxUtils';

export const getStaff = async () => {
  try {
    const response = (await APIPostRequest('/api/js/opsconsole/getStaffList', {})) as Staff[];
    if (response) {
      return response;
    } else {
      throw new Error('Staff not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching staff:', error);
    throw error;
  }
};

export const saveStaff = async (staffRecord: CreateStaffInput) => {
  try {
    await APIPostRequest('/api/js/opsconsole/upsertStaff', {
      staffRecord,
    });
  } catch (error: unknown) {
    console.error('Error update staff:', error);
    throw error;
  }
};

export const deleteStaff = async (email: string) => {
  try {
    await APIPostRequest('/api/js/opsconsole/deleteStaff', {
      email,
    });
  } catch (error: unknown) {
    console.error('Error delete staff:', error);
    throw error;
  }
};
