import { LicenseType } from '../../../API';
import { t } from '../../../i18n/i18n';
import { ToastNotificationOptions } from '../../notifications';

export const TERM_OPTIONS = [
  {
    value: LicenseType.TRIAL,
    label: t('OrgDetailsUsers:TERM_TRIAL'),
  },
  {
    value: LicenseType.MONTHLY,
    label: t('OPSConsoleOrgs:TERM_MONTHLY'),
  },
  {
    value: LicenseType.ANNUAL,
    label: t('OPSConsoleOrgs:TERM_ANNUAL'),
  },
];

export const USERS_CSV_HEADERS = [
  t('Users:FULL_NAME'),
  t('Common:EMAIL'),
  t('OPSConsoleUsers:ACCOUNT_ID'),
  t('OPSConsoleOrgDetails:ACCOUNT_NAME'),
  t('OPSConsoleOrgs:ORG_ID_TITLE'),
  t('Users:ROLE'),
  t('Users:STATUS'),
  t('OPSConsoleUsers:REGISTRATION_DATE'),
  t('Users:LAST_ACTIVE'),
  t('OPSConsoleOrgDetails:BOOKED_NUMBER'),
  t('OPSConsoleOrgDetails:FEATURE_PRICING_TIER'),
  t('OPSConsoleOrgs:TERM_TITLE'),
  t('OPSConsoleUsers:RENEWAL_DATE'),
  t('OPSConsoleOrgDetails:ACTIVE_USERS_NUMBER'),
];

export const GET_USERS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('OPSConsoleUsersToastsNotifications:GET_USERS_FAIL_MESSAGE'),
});

export const GET_USER_DETAILS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('OPSConsoleUsersToastsNotifications:GET_USER_DETAILS_FAIL_MESSAGE'),
});

export const ACTIVATE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OPSConsoleUsersToastsNotifications:ACTIVATE_USER_SUCCESS_MESSAGE'),
});

export const DEACTIVATE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OPSConsoleUsersToastsNotifications:DEACTIVATE_USER_SUCCESS_MESSAGE'),
});

export const DEACTIVATE_USER_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('OPSConsoleUsersToastsNotifications:DEACTIVATE_USER_FAIL_MESSAGE'),
});

export const DELETE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OPSConsoleUsersToastsNotifications:DELETE_USER_SUCCESS_MESSAGE'),
});

export const DELETE_USER_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('OPSConsoleUsersToastsNotifications:DELETE_USER_FAIL_MESSAGE'),
});
