export const ConnectIntegrationPage = Object.freeze({
  BENEFITS_TITLE: `Vorteile`,
  DISCONNECT_DESCRIPTION: `Möchten Sie sich wirklich abmelden `,
  GOOGLE_CALENDAR_BENEFITS: `Automatisches Buchen und Verschieben von Meetings in Ihrem Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Sie müssen den Zugriff auf Ihren Google Calendar gewähren.`,
  GOOGLE_MEET_BENEFITS: `Automatisches Erstellen von Google Meet-Meetings zum Zeitpunkt der Terminplanung. Sofortige Weitergabe eindeutiger Konferenzdetails nach Bestätigung.`,
  GOOGLE_MEET_REQUIREMENTS: `Je nach Konfiguration muss Ihr Google Meet-Kontoadministrator SUMO1 im Google Marketplace möglicherweise im Voraus genehmigen.`,
  MICROSOFT_CALENDAR_BENEFITS: `Automatisches Buchen und Verschieben von Meetings in Ihrem Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Sie müssen den Zugriff auf Ihren Microsoft Calendar gewähren.`,
  MICROSOFT_TEAMS_BENEFITS: `Automatisches Erstellen von Microsoft Teams-Meetings zum Zeitpunkt der Terminplanung. Sofortige Weitergabe eindeutiger Konferenzdetails nach Bestätigung.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Je nach Konfiguration muss Ihr Microsoft Teams-Kontoadministrator SUMO1 im Microsoft Marketplace möglicherweise im Voraus genehmigen.`,
  REQUIREMENTS_TITLE: `Anforderungen`,
  ZOOM_BENEFITS: `Automatisches Erstellen von Zoom-Meetings zum Zeitpunkt der Terminplanung. Sofortige Weitergabe eindeutiger Konferenzdetails nach Bestätigung.`,
  ZOOM_REQUIREMENTS: `Je nach Konfiguration muss Ihr Zoom-Kontoadministrator SUMO1 im Zoom Marketplace möglicherweise im Voraus genehmigen.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Sparen Sie mehr Zeit durch Automatisierung von Videokonferenzen, Terminplanung und mehr.`,
  FILTER_ALL: `Alle`,
  FILTER_CALENDARS: `Kalender`,
  FILTER_VIDEO_CONFERENCE: `Videokonferenz`,
  GOOGLE_CALENDAR_DESC: `Automatisches Buchen und Verschieben von Meetings in Ihrem Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MEET_DESC: `Automatisches Hinzufügen von Google Meet-Details zu Ihren Meetings.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_CALENDAR_DESC: `Automatisches Buchen und Verschieben von Meetings in Ihrem Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Automatisches Hinzufügen von Microsoft Teams-Details zu Ihren Meetings.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `Keine Elemente gefunden.`,
  REQUIREMENTS_TITLE: `Anforderungen`,
  TITLE: `Integration`,
  ZOOM_DESC: `Automatisches Hinzufügen von Zoom-Details zu Ihren SUMO-Meetings.`,
  ZOOM_TITLE: `Zoom-Meeting`,
});
