export const DefaultBookingPage = Object.freeze({
  NAME: `Reunión de 30 minutos`,
  BOOK_A_MEETING: `Reservar una Reunión`,
  MEETING_DETAILS: `Detalles de la Reunión`,
  SELECT_DATE: `Seleccionar Fecha`,
  SELECT_TIME: `Seleccionar Hora`,
  YOUR_INFORMATION: `Su Información`,
  MORE_OPTIONS: `Más Opciones`,
  CANCEL: `Cancelar`,
  BOOK_MEETING_BUTTON: `Reservar Reunión`,
  BOOK_ANOTHER_MEETING_BUTTON: `Reservar Otra Reunión`,
  CANCEL_MEETING_BUTTON: `Cancelar Reunión`,
  SAVE_MEETING_BUTTON: `Reprogramar`,
  MEETING_BOOKED_TITLE: `¡Reunión Reservada!`,
  MEETING_BOOKED_DESCRIPTION: `Se ha enviado una confirmación por correo electrónico a su bandeja de entrada.`,
  MEETING_RESCHEDULED_TITLE: `Reunión Reprogramada!`,
  MEETING_RESCHEDULED_DESCRIPTION: `Se ha enviado una confirmación por correo electrónico a su bandeja de entrada.`,
  MEETING_CANCELED_TITLE: `Reunión Cancelada!`,
  MEETING_CANCELED_DESCRIPTION: `Se ha enviado una confirmación por correo electrónico a su bandeja de entrada.`,
});

export const BookingPagesEditBookingPageBufferTimeSettings = Object.freeze({
  AFTER_CHECKBOX_LABEL: `Después del evento`,
  BEFORE_CHECKBOX_LABEL: `Antes del evento`,
  DESCRIPTION: `Establecer tiempos de amortiguación antes y después`,
  EVENT_TIME: `Hora del evento`,
  TITLE: `¿Bloquear automáticamente el tiempo del calendario antes o después de esta plantilla de reserva?`,
});

export const BookingPagesEditBookingPageDateRangeSettings = Object.freeze({
  INVITEES_CAN_SCHEDULE: `Los invitados pueden programar...`,
  INVITEES_CAN_NOT_SCHEDULE: `Los invitados no pueden programar menos de...`,
  RADIO_GROUP_DAYS_IN_FUTURE_OPTION: `Días en el futuro`,
  RADIO_GROUP_SPECIFIC_DATES_OPTION: `Dentro de un rango de fechas`,
  RADIO_GROUP_UNLIMITED_OPTION: `Indefinidamente en el futuro`,
});

export const BookingPagesEditBookingPageDurationSettings = Object.freeze({
  DURATION: `Duración`,
  DURATION_TOOLTIP: `Establezca la duración de su reunión. No exceder 12 horas.`,
});

export const BookingPagesEditBookingPageWhatStep = Object.freeze({
  EVENT_COLOR_INPUT_LABEL: `Color`,
  EVENT_DESCRIPTION_INPUT_LABEL: `Descripción/Instrucciones`,
  EVENT_DESCRIPTION_INPUT_PLACEHOLDER: `Escriba un resumen y cualquier detalle que su invitado deba saber sobre el evento.`,
  EVENT_NAME_INPUT_LABEL: `Nombre`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Error al solicitar la clonación de la página de reservas`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Solicitud de clonación de página de reservas exitosa`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Error al solicitar la eliminación de páginas de reservas`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Solicitud de eliminación de páginas de reservas exitosa`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `No se pudo recuperar la página de reserva, podría haber sido eliminada`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Error al solicitar las páginas de reservas`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Error al solicitar el guardado de la página de reservas`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Solicitud de guardado de página de reservas exitosa`,
});

export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Nueva página de reservas`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `¿Estás seguro de que quieres eliminar esta página de reservas?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `¿Estás seguro de que quieres eliminar estas páginas de reservas?`,
  TITLE: `Páginas de Reservas`,
  ADD_TO_SITE: `Agregar a sitio web`,
  CLONE_MODAL_TITLE: `Clonar página de reservas`,
  DEFAULT_FILTER: `Mis Páginas de Reserva`,
  SHARED_LABEL: `Compartido`,
  CLONE_NOTIFICATION: `Tenga en cuenta que la propiedad "Solo para Administradores" está desactivada de forma predeterminada para un registro clonado.`,
  TOOLTIP: `Crear enlaces de reserva para quedar contigo y/o con otros usuarios`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE_PART_1: `¿Para qué`,
  WHAT_TITLE_PART_2: `es esta página de reservas?`,
  WHO_TITLE_PART_1: `¿Quién`,
  WHO_TITLE_PART_2: `puede ser el anfitrión?`,
  HOW_TITLE_PART_1: `¿Cómo`,
  HOW_TITLE_PART_2: `debería lucir?`,
  HOW_DESCRIPTION: `Marca, Etiquetas`,
  ADDITIONAL_OPTIONS: `Opciones adicionales`,
  CALENDAR_TITLE_PART_1: `Configuración de`,
  CALENDAR_TITLE_PART_2: `Calendario`,
  PAGE_TITLE_PART_1: `Configuración de`,
  PAGE_TITLE_PART_2: `Página`,
  BOOKING_TEMPLATE_TITLE: `¿Más configuraciones de la página de reservas?`,
  BOOKING_TEMPLATE_PART_1: `Cada `,
  BOOKING_TEMPLATE_PART_2: `"Plantilla de reserva"`,
  BOOKING_TEMPLATE_PART_3: `contiene configuraciones que afectan las páginas de reservas asociadas. Por ejemplo, cada "Página de confirmación" puede ser exclusiva de la plantilla de reserva, por lo tanto, estas configuraciones se encuentran en la "Plantilla de reserva".`,
  VIEW_BOOKING_PAGE: 'Ver página de reserva',
  COPY_LINK: 'Copiar enlace',
  ADD_TO_WEBSITE: 'Agregar al sitio web',
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Registro bloqueado. Estas configuraciones son del modelo de reserva titulado: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Haga clic aquí para anular estas configuraciones`,
  MAIN_HOST: `Anfitrión Principal`,
  CO_HOST: `Co-Anfitrión`,
  HOST_NOTIFICATION: `El Anfitrión Principal aún no tiene opciones de ubicación conectadas. Esta página de reserva no funcionará hasta que lo haga.`,
  LOCKED_ADMIN_ONLY: `Sólo un administrador puede modificar esta configuración. Última modificación por:`,
  LOCKED_NO_PERMISSIONS: `No tienes permisos para editar esta Página de Reservas. Última modificación por:`,
  BACK_TO_LIST: 'Volver a la lista',
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Nombre de la página de reservas`,
  NAME_PLACEHOLDER: `Nombre interno para esta página de reservas`,
  NAME_TOOLTIP: `Este texto se mostrará a los asistentes a la reunión en su calendario y en los correos electrónicos. Por ejemplo, "Reunión de 30min" o "Examen Dental".`,
  ACTIVE: `Activa`,
  INACTIVE: `Inactiva`,
  BOOKING_TEMPLATE: `Plantilla de Reserva`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Buscar y agregar plantilla de reserva`,
  MEETING_NAME_LABEL: `Nombre de la Reunión`,
  CUSTOM_NAME_LABEL: `Nombre para mostrar`,
  CUSTOM_NAME_PLACEHOLDER: `Nombre personalizado para esta página de reserva`,
  BOOKING_PAGE: `Página de Reserva`,
  ADMIN_ONLY: `Solo para Administradores`,
  ADMIN_ONLY_PLACEHOLDER: `Habilitar para que solo un administrador pueda modificar estos ajustes.`,
});

export const BookingPageHowStep = Object.freeze({
  PREVIEW_DESCRIPTION: `Puede ver la apariencia actual de esta página de reserva y anular las etiquetas aquí.`,
  PREVIEW_BUTTON: `Ver y Anular Etiquetas`,
  PREVIEW_TITLE: `Anular Etiquetas`,
  BRANDING_DESCRIPTION: `Puede anular la configuración de marca aquí.`,
  BRANDING_BUTTON: `Anular Configuración de Marca`,
  BRANDING_TITLE: `Anular Configuración de Marca`,
});

export const BookingPageBranding = Object.freeze({
  DESCRIPTION: `Por defecto, esta página de reserva utilizará la configuración de marca establecida para este espacio de trabajo. Sin embargo, puede anular la configuración específica de esta página de reserva a continuación.`,
  UPLOAD_LOGO: `Subir su logotipo`,
  UPLOAD_LOGO_BUTTON: `Subir logotipo`,
  UPLOAD_BACKGROUND: `Subir su fondo`,
  UPLOAD_BACKGROUND_BUTTON: `Subir fondo`,
  OPACITY: `Opacidad`,
  CSS: `CSS`,
  CSS_PLACEHOLDER: `Aquí se puede agregar CSS personalizado para anular`,
  FOOTER_TEXT: `Texto del pie de página`,
  SAVE: `Guardar`,
});

export const BookingPageCalendarStep = Object.freeze({
  TIME_FORMAT: `Formato de Hora`,
  HOUR_12: `12 Horas`,
  HOUR_24: `24 Horas`,
  CALENDAR_FORMAT: `Formato de Calendario`,
  MONTH_VIEW: `Vista Mensual`,
  WEEK_VIEW: `Vista Semanal`,
  START_TIME_INTERVALS: `Intervalos de Inicio`,
  START_TIME_INTERVALS_DESCRIPTION: `Mostrar franjas horarias disponibles en incrementos de...`,
  START_TIME_INTERVALS_TOOLTIP: `Elija la duración de los intervalos que desea mostrar al programar una cita. Por defecto, tenemos seleccionados 15 minutos para maximizar la disponibilidad de ranuras. Esta opción mostrará las ranuras disponibles por este intervalo.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Parte Superior del Intervalo`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Habilite "Parte superior del intervalo" si requiere que todas las citas comiencen siempre en la parte superior del intervalo, independientemente de la duración de la plantilla de reserva. Por ejemplo, las duraciones de los espacios estarían disponibles a las 8:00 a. m., 9:00 a. m., 10:00 a. m., etc. NOTA: Habilitar esta función no permitirá al usuario maximizar la disponibilidad.`,
  TIME_ZONES: `Zonas Horarias`,
  TIME_ZONES_TOOLTIP: `Las siguientes zonas horarias estarán disponibles en la Página de Reserva orientada al cliente. Si elige solo una zona horaria, será fija, por lo que el campo de zona horaria estará desactivado y el usuario no podrá cambiarlo.`,
  TIME_ZONE_DETECT: `Detectar y mostrar automáticamente franjas horarias en la zona horaria del invitado.`,
  TIME_ZONE_DISPLAY: `Solo mostrar las siguientes zonas horarias (ideal para eventos presenciales)`,
  DEFAULT_TIME_ZONE: `Zona Horaria Predeterminada`,
});

export const BookingPageSettingsStep = Object.freeze({
  INVITE_OTHERS_LABEL: `Permitir que los invitados inviten a otros`,
  INVITE_OTHERS_TOOLTIP: `Activar esta función mostrará un campo "Invitar a Otros" en la Página de Reserva, permitiendo que el invitado agregue a otras personas a la lista de participantes ingresando direcciones de correo electrónico separadas por comas.`,
  INVITE_OTHERS_SHORT: `Invitar a otros`,
  ENTER_NOTE_LABEL: `Permitir que los invitados ingresen una nota para todos los invitados`,
  ENTER_NOTE_TOOLTIP: `Activar esta función mostrará un campo "Nota para los Asistentes", permitiendo que el invitado ingrese una nota para que se muestre en la cita del calendario reservada para que todos los participantes la vean.`,
  ENTER_NOTE_SHORT: `Ingresar una nota`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Inferior Izquierda`,
  BOTTOM_RIGHT: `Inferior Derecha`,
  BUTTON_POPUP_TITLE: `Botón para Pop-up`,
  COPY_CODE_EMBED_DESCRIPTION: `Inserte su widget SUMO en su sitio web pegando el código HTML en la ubicación deseada.`,
  COPY_CODE_LINK_DESCRIPTION: `Muestre un botón de reserva flotante en las páginas deseadas colocando este código en su HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Muestre su enlace de reserva en cualquier lugar colocando este código HTML en sus páginas, firmas de correo electrónico y más.`,
  COPY_CODE_TITLE: `Copiar Código`,
  CUSTOMIZE_DESCRIPTION: `Personalice la apariencia para integrarse perfectamente en su sitio web.`,
  CUSTOMIZE_TITLE: `Personalizar`,
  EMBED_DESCRIPTION: `Muestre su página de reserva SUMO1 en un iFrame en su sitio web.`,
  EMBED_TITLE: `Insertar`,
  FLOATING_BUTTON_DESCRIPTION: `Haga que el botón flote en la página`,
  HIDE_PAGE_DETAILS: `Ocultar Detalles de la Página`,
  HIDE_COOKIE_BANNER: `Ocultar Banner de Cookies`,
  LABEL: `Etiqueta`,
  LINK_DESCRIPTION: `Agregue un enlace de texto a su sitio que abrirá su página de reserva SUMO1 en un pop-up.`,
  LINK_TITLE: `Enlace de Texto`,
  LOCATION: `Ubicación`,
  POPUP_DESCRIPTION: `Muestre su página de reserva SUMO1 en un pop-up cuando se haga clic en un botón.`,
  POPUP_TITLE: `Pop-up`,
  TITLE: `¿Cómo agregará SUMO a su sitio web?`,
  TOP_LEFT: `Superior Izquierda`,
  TOP_RIGHT: `Superior Derecha`,
});

export const EditBookingPageWhereStep = Object.freeze({
  HOSTS_PHONE_NOTIFICATION: `El anfitrión seleccionado no tiene número de teléfono`,
  VIDEO_CONFERENCE_NOTIFICATION: `El anfitrión seleccionado no tiene ninguna videoconferencia`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirmar Anulación`,
  DESCRIPTION: `Al anular las configuraciones de una Plantilla de Reserva, técnicamente ya no está utilizando esta Plantilla de Reserva,
  sino que está creando una Página de Reserva Personalizada, que requiere un nombre.`,
});
