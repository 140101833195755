import { Storage } from 'aws-amplify';
import { CreateUserDataInput, IntegrationInput, Role, UserRecordType } from '../../API';
import { UserDetails } from './typings';
import { APIPostRequest } from '../utils/reduxUtils';
import { appendPostfixToFileName } from '../../services/utils';

// ***
// When changing functions, do not forget to specify new data
// in src/docs/Private.md
// ***

export async function saveUserSettings(input: CreateUserDataInput) {
  try {
    await APIPostRequest('/api/public/updateUserData', { data: input });
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error saving user settings:', error);
    throw error;
  }
}

export const checkUserLink = async (userLink: string) => {
  try {
    return await APIPostRequest('/api/js/CheckUserLink', { userLink });
  } catch (error: unknown) {
    console.error('Redux userSettings service; Check user link', error);
    throw error;
  }
};

export const updateUserLink = async (input: any) => {
  try {
    await APIPostRequest('/api/js/changeGlobalLink', input);
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error update user link', error);
    throw error;
  }
};

export async function uploadPublicFile(file: File, path: string = '') {
  try {
    const result = await Storage.put(appendPostfixToFileName(path + file.name, '_' + Date.now()), file, {
      level: 'public',
      contentType: file.type,
    });
    return (await Storage.get(result.key, { level: 'public' })).split('?')[0]; //remove access-related attributes as /public/* files available for read for everyone
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error uploading file: ', error);
    throw error;
  }
}

export async function uploadProtectedFile(file: File) {
  try {
    const result = await Storage.put(appendPostfixToFileName(file.name, '_' + Date.now()), file, {
      level: 'protected',
      contentType: file.type,
    });
    return (await Storage.get(result.key, { level: 'protected' })).split('?')[0]; //remove access-related attributes as /protected/* files available for read for everyone
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error uploading file: ', error);
    throw error;
  }
}

export async function copyPublicFile(fileKey: string, copyFileKey: string) {
  try {
    const result = await Storage.copy({ key: fileKey, level: 'public' }, { key: copyFileKey, level: 'public' });
    return (await Storage.get(result.key, { level: 'public' })).split('?')[0]; //remove access-related attributes as /protected/* files available for read for everyone
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error copying file: ', error);
    throw error;
  }
}

// TODO: deleting of public objects is forbidden, deleting of unused files should be handled by scheduled script
// export async function removeFile(fileName: string) {
//   try {
//     // cut the file name from the whole path
//     const name = fileName.substring(fileName.lastIndexOf('/') + 1);
//     const result = await Storage.remove(name, { level: 'public' });
//   } catch (error: unknown) {
//     console.error('Redux userSettings service; Error removing file: ', error);
//     throw error;
//   }
// }

// export async function getUserDetails() {
//   try {
//     return (await APIPostRequest('/api/js/getLoggedUserDetails', {})) as UserDetails;
//   } catch (error: unknown) {
//     console.error('Redux userSettings service; Error get User Role:', error?.toString());
//     throw error;
//   }
// }

export async function connectToZoom(code: string, redirectURI: string) {
  try {
    return (await APIPostRequest('/api/js/connectToZoom', {
      code,
      redirectURI,
    })) as IntegrationInput;
  } catch (error) {
    console.error('Redux authentification service; Error connectToZoom func:', error);
    throw error;
  }
}

export async function getUserPermissions(roleId: string) {
  try {
    return (await APIPostRequest('/api/js/getRoleById', {
      roleId,
    })) as Role;
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error get User Role', error?.toString());
    throw error;
  }
}

export async function getUserDataById(userId: string) {
  try {
    const response = (await APIPostRequest('/api/js/getListUserDataByUserId', {
      userId,
    })) as CreateUserDataInput[];
    if (response) {
      return response;
    } else {
      throw new Error('User data not updated');
    }
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error get User Data:', error?.toString());
    throw error;
  }
}

export async function getUserProfile(userId: string) {
  try {
    const response: CreateUserDataInput[] = await getUserDataById(userId);
    if (response) {
      return response.find((record) => record.recordType === UserRecordType.PROFILE);
    } else {
      throw new Error('User data not updated');
    }
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error get User Data:', error?.toString());
    throw error;
  }
}
