import { AdminData, IntegrationType, UpdateBookingTemplateInput, VideoConferenceType } from '../../API';
import { TeamRecord } from '../teams';

export type UserRecordTemplate = {
  email: string;
  role: string;
  team: string;
  bookingTemplateIds: string;
};

export enum UserStatus {
  InviteSent = 'Invite Sent',
  Active = 'Active',
  Inactive = 'Inactive',
}

export type UserAdminDataFullRecord = AdminData & {
  countryCode?: string;
  phoneNumber?: string;
  fullName?: string;
  integrationTypes?: IntegrationType[];
  defaultVideoIntegration?: VideoConferenceType;
};

export type BookingTemplateOptions = {
  workspaceId: string;
  label?: string;
  bookingTemplates: UpdateBookingTemplateInput[];
};

export type UserOption = {
  workspaceIds: Array<string | null>;
  value: string;
  label: string;
};

export type TeamsOptions = {
  workspaceId: string;
  label?: string;
  teams: TeamRecord[];
};

export type InviteUsersOptions = {
  bookingTemplates: BookingTemplateOptions[];
  teams: TeamsOptions[];
}