import { WorkspaceInput } from '../../API';
import { UserAdminDataFullRecord } from '../users/typings';

export type WorkspaceWhoStep = {
  adminList: UserAdminDataFullRecord[];
  userList: UserAdminDataFullRecord[];
};

export type WorkspaceData = WorkspaceInput & WorkspaceWhoStep;

export enum WorkspaceActions {
  createWorkspace = 'create workspace',
  updateWorkspace = 'update workspace',
  deleteWorkspace = 'delete workspace',
}

export type UpdateWorkspaceList = {
  type: WorkspaceActions;
  tenant: {
    tenantId: string;
    workspace: Partial<WorkspaceInput>;
  };
};

export type CreateWorkspaceResponse = {
  id: string;
};

export type WorkspaceOption = {
  id: string;
  name: string;
};
