import { t } from '../../../../i18n/i18n';

export default {
  noResults: t('Common:NO_RESULTS_FOUND'),
  timeFormat: t('BookingPageCalendarStep:TIME_FORMAT'),
  hour12: t('BookingPageCalendarStep:HOUR_12'),
  hour24: t('BookingPageCalendarStep:HOUR_24'),
  calendarFormat: t('BookingPageCalendarStep:CALENDAR_FORMAT'),
  monthView: t('BookingPageCalendarStep:MONTH_VIEW'),
  weekView: t('BookingPageCalendarStep:WEEK_VIEW'),
  startTimeIntervals: t('BookingPageCalendarStep:START_TIME_INTERVALS'),
  startTimeIntervalsDescription: t('BookingPageCalendarStep:START_TIME_INTERVALS_DESCRIPTION'),
  startTimeIntervalsTooltip: t('BookingPageCalendarStep:START_TIME_INTERVALS_TOOLTIP'),
  topOfTheInterval: t('BookingPageCalendarStep:TOP_OF_THE_INTERVAL'),
  topOfTheIntervalTooltip: t('BookingPageCalendarStep:TOP_OF_THE_INTERVAL_TOOLTIP'),
  timeZones: t('BookingPageCalendarStep:TIME_ZONES'),
  timeZonesTooltip: t('BookingPageCalendarStep:TIME_ZONES_TOOLTIP'),
  timeZoneDetect: t('BookingPageCalendarStep:TIME_ZONE_DETECT'),
  timeZoneDisplay: t('BookingPageCalendarStep:TIME_ZONE_DISPLAY'),
  defaultTimeZone: t('BookingPageCalendarStep:DEFAULT_TIME_ZONE'),
};
