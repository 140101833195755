import { Button } from 'primereact/button';
import { QuickSetupScreen } from '../quickSetupScreen/QuickSetupScreen';
import { useDispatch } from 'react-redux';
import { quickSetupActions, quickSetupNavigationActions, quickSetupSelectors } from '../../../store/quickSetup';
import labels from './labels';
import { AppDispatch } from '../../../store/rootStore';
import { PhoneNumber } from '../../common';
import { useSelector } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { MAX_LENGTH_DESCRIPTION } from '../../../types/constants';
import { useEffect } from 'react';
import { workspacesSelectors } from '../../../store/workspaces';
import { userSettingsSelectors } from '../../../store/userSettings';
import { bookingPageSelectors } from '../../../store/bookingPages';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom';

export const SetupPhoneCalls = () => {
  const dispatch = useDispatch<AppDispatch>();
  const workspace = useSelector(quickSetupSelectors.selectDefaultWorkspace);
  const currentWorkspace = useSelector(workspacesSelectors.selectCurrentWorkspaceData);
  const phoneCalls = useSelector(quickSetupSelectors.selectPhoneCalls);
  const phoneNumber = useSelector(quickSetupSelectors.selectPhoneNumber) || '';
  const phoneDetails = useSelector(quickSetupSelectors.selectPhoneDetails) || '';
  const isFirstAdmin = useSelector(userSettingsSelectors.selectIsFirstTenantUser);
  const isPhoneInvalid = useSelector(quickSetupSelectors.selectIsUserPhoneInvalid);
  const isShortLinkFetching = useSelector(bookingPageSelectors.selectIsShortLinkFetching);
  const isAcceptedTerms = useSelector(quickSetupSelectors.selectIsAcceptTerms);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);

  useEffect(() => {
    if (!Object.keys(workspace).length && currentWorkspace) {
      dispatch(quickSetupActions.setDefaultWorkspace(currentWorkspace));
    }
  }, [currentWorkspace]);

  const handleBack = () => {
    dispatch(quickSetupNavigationActions.navigateBack());
  };

  const handleNext = () => {
    dispatch(quickSetupNavigationActions.navigateNext());
  };

  const handleSave = () => {
    dispatch(quickSetupActions.saveQuickSetupRequest());
  };

  const handlePhoneChange = (phoneNumber: string) => {
    dispatch(quickSetupActions.updatePhoneCalls({ phoneNumber }));
  };

  const handleCodeChange = (countryCode: string) => {
    dispatch(quickSetupActions.updatePhoneCalls({ countryCode }));
  };

  const handlePhoneDetails = (phoneDetails: string) => {
    dispatch(quickSetupActions.updatePhoneCalls({ phoneDetails }));
  };

  const handleAcceptTerms = (value: boolean) => {
    dispatch(quickSetupActions.setIsAcceptTerms(value));
  };

  return (
    <QuickSetupScreen.Container title={labels.title}>
      <div className="flex flex-column align-items-center">
        <div className="text-xl mt-3 w-12 md:w-10 lg:w-7 text-center">{labels.description}</div>
        <div className="w-12 md:w-10 lg:w-9 flex mt-3 mb-1 p-2 border-round" style={{ background: '#e7f4fd' }}>
          <i className="pi pi-info-circle text-2xl pr-2" />
          {labels.message}
        </div>

        <div className="w-12 sm:w-8 md:w-6 p-3">
          <div className="mb-2">
            <label htmlFor="phoneNumber">{labels.phoneLabel}</label>
          </div>
          <PhoneNumber
            countryCode={phoneCalls.countryCode || ''}
            handleChangeCode={handleCodeChange}
            phoneNumber={phoneNumber || ''}
            placeholder={labels.phonePlaceholder}
            handleChangePhone={handlePhoneChange}
            inputClassName={`w-full ${isPhoneInvalid ? 'p-invalid' : ''}`}
          />
          <div className="flex flex-column mt-3">
            <div className="mb-2">
              <label htmlFor="phoneDetails">{labels.phoneDetailsLabel}</label>
            </div>
            <InputTextarea
              id="phoneDetails"
              value={phoneDetails}
              onChange={(e) => handlePhoneDetails(e.target.value)}
              placeholder={labels.phoneDetailsPlaceholder}
              rows={4}
              autoResize
              maxLength={MAX_LENGTH_DESCRIPTION}
            />
          </div>
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <div className="flex justify-content-between">
          <Button label={labels.back} className="w-8rem m-2" onClick={handleBack} outlined />
          {isFirstAdmin ? (
            <Button label={labels.next} className="w-8rem m-2" onClick={handleNext} disabled={isPhoneInvalid} />
          ) : (
            <div className="flex flex-column md:flex-row">
              <div className="align-self-center">
                <Checkbox
                  checked={isAcceptedTerms || !!isQuickSetupFinished}
                  onChange={(e) => handleAcceptTerms(!!e.target.checked)}
                  disabled={!!isQuickSetupFinished}
                />
                <Link
                  to={urlConstants.TERMS_OF_SERVICE_URL}
                  target="_blank"
                  className="ml-2 mr-1 underline text-primary hover:text-primary-700"
                >
                  {labels.acceptTerms_step1}
                </Link>
                {labels.acceptTerms_step2}
                <Link
                  to={urlConstants.PRIVACY_POLICY_URL}
                  target="_blank"
                  className="ml-1 mr-3 underline text-primary hover:text-primary-700"
                >
                  {labels.acceptTerms_step3}
                </Link>
              </div>
              <Button
                label={labels.save}
                className="w-13rem m-2"
                onClick={handleSave}
                disabled={(!isAcceptedTerms && !isQuickSetupFinished) || isShortLinkFetching || isPhoneInvalid}
              />
            </div>
          )}
        </div>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
