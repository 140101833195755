import { t } from '../../../i18n/i18n';

export default {
  teams: t('EditBookingTemplateWhoStep:TEAMS'),
  hosts: t('EditBookingTemplateWhoStep:HOSTS'),
  hostsTooltip: t('EditBookingTemplateWhoStep:HOSTS_TOOLTIP'),
  phoneCall: t('EditBookingTemplateWhereStep:PHONE_CALL'),
  zoom: t('EditBookingTemplateWhereStep:ZOOM'),
  googleMeet: t('EditBookingTemplateWhereStep:GOOGLE_MEET'),
  microsoftTeams: t('EditBookingTemplateWhereStep:MICROSOFT_TEAMS'),
  rescheduleWith: t('EditBookingTemplateWhoStep:RESCHEDULE_WITH'),
  rescheduleWithTooltip: t('EditBookingTemplateWhoStep:RESCHEDULE_WITH_TOOLTIP'),
  assignedHost: t('EditBookingTemplateWhoStep:ASSIGNED_HOST'),
  roundRobinToNewHost: t('EditBookingTemplateWhoStep:ROUND_ROBIN_TO_NEW_HOST'),
  hostVideoConference: t('EditBookingTemplateWhoStep:HOST_VIDEO_CONFERENCE'),
  hostRequirements: t('EditBookingTemplateWhoStep:HOST_LIST_REQUIREMENTS'),
  hostsLabel: t('EditBookingTemplateWhoStep:HOSTS_LABEL'),
  hostsPlaceholder: t('EditBookingTemplateWhoStep:HOSTS_PLACEHOLDER'),
  mainHost: t('EditBookingPage:MAIN_HOST'),
  coHost: t('EditBookingPage:CO_HOST'),
  hostNotification: t('EditBookingPage:HOST_NOTIFICATION'),
};
