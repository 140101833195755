import { t } from '../../../i18n/i18n';

export default {
  duplicate: t('Common:NAME_IS_DUPLICATE'),
  active: t('BookingPageWhatStep:ACTIVE'),
  inactive: t('BookingPageWhatStep:INACTIVE'),
  bookingTemplate: t('BookingPageWhatStep:BOOKING_TEMPLATE'),
  bookingTemplatePlaceholder: t('BookingPageWhatStep:BOOKING_TEMPLATE_PLACEHOLDER'),
  bookingPage: t('BookingPageWhatStep:BOOKING_PAGE'),
  eventColorInputLabel: t('BookingPagesEditBookingPageWhatStep:EVENT_COLOR_INPUT_LABEL'),
  meetingName: t('BookingPageWhatStep:MEETING_NAME_LABEL'),
  customName: t('BookingPageWhatStep:CUSTOM_NAME_LABEL'),
  customNamePlaceholder: t('BookingPageWhatStep:CUSTOM_NAME_PLACEHOLDER'),
  customNameTooltip: t('BookingPageWhatStep:NAME_TOOLTIP'),
  adminOnly: t('BookingPageWhatStep:ADMIN_ONLY'),
  adminOnlyPlaceholder: t('BookingPageWhatStep:ADMIN_ONLY_PLACEHOLDER'),
};
