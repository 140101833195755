import { useSelector } from 'react-redux';
import { RolePermissions } from '../../roles';
import { userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';

export const MyRole = () => {
  const role = useSelector(userSettingsSelectors.selectUserPermissions);
  return (
    <>
      <div className="mb-3 pl-1">
        {labels.roleDesc}
        <span className="text-lg font-medium">{role.name}</span>
      </div>

      <RolePermissions isPreview={true} role={role} />
    </>
  );
};
