import { useDispatch, useSelector } from 'react-redux';
import {
  CONFIRM_OVERRIDE_MODAL,
  bookingPageSelectors,
  bookingPagesActions,
  confirmOverrideModalActions,
} from '../../../store/bookingPages';
import { Modal } from '../../common';
import { Button } from 'primereact/button';
import labels from './labels';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { InputText } from 'primereact/inputtext';

export const BookingPageConfirmOverrideModal = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingPageSelectors.selectCloneName);
  const isNameDuplicate = useSelector(bookingPageSelectors.selectIsCloneNameDuplicate);
  const isNameValid = useSelector(bookingPageSelectors.selectIsCloneNameValid);

  const handleNameChange = (value: string) => {
    dispatch(bookingPagesActions.setCloneName(value));
  };

  const handleCancel = () => {
    dispatch(confirmOverrideModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(bookingPagesActions.updateWhatStep({ customName: name, bookingTemplateId: null }));
    dispatch(confirmOverrideModalActions.closeModal());
  };

  return (
    <Modal.Container name={CONFIRM_OVERRIDE_MODAL}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="w-25rem p-fluid">
        <div className="w-12 flex p-2 mb-3 border-round" style={{ background: '#e7f4fd' }}>
          <span className="pi pi-info-circle mt-1 mr-2" />
          {labels.description}
        </div>
        <div>{labels.customName}</div>
        <InputText
          type="text"
          placeholder={labels.customNamePlaceholder}
          value={name}
          onChange={(e) => handleNameChange(e.target.value.trimStart())}
          className={(!isNameValid && 'p-invalid') || ''}
          maxLength={MAX_LENGTH_NAME}
        />
        {isNameDuplicate && <div className="text-red-500 mt-2">{labels.duplicate}</div>}
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined />
          <Button label={labels.save} onClick={handleSave} autoFocus disabled={!isNameValid} />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
