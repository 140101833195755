import { WorkspaceInput, BookingPageStyleInput } from '../../API';
import { ImageType, UploadImageRequestPayload, UploadImageSuccessPayload } from '../../types/types';
import { WorkspaceData } from './types';

export enum workspacesActionTypes {
  GET_WORKSPACES_REQUEST = 'workspaces/GET_WORKSPACES_REQUEST',
  GET_WORKSPACES_SUCCESS = 'workspaces/GET_WORKSPACES_SUCCESS',
  GET_WORKSPACES_FAIL = 'workspaces/GET_WORKSPACES_FAIL',
  CREATE_WORKSPACE_REQUEST = 'workspaces/CREATE_WORKSPACE_REQUEST',
  CREATE_WORKSPACE_SUCCESS = 'workspaces/CREATE_WORKSPACE_SUCCESS',
  CREATE_WORKSPACE_FAIL = 'workspaces/CREATE_WORKSPACE_FAIL',
  UPDATE_WORKSPACE = 'workspaces/UPDATE_WORKSPACE',
  UPDATE_WORKSPACE_STYLE = 'workspaces/UPDATE_WORKSPACE_STYLE',
  ACTIVATE_WORKSPACE_REQUEST = 'workspaces/ACTIVATE_WORKSPACE_REQUEST',
  ACTIVATE_WORKSPACE_SUCCESS = 'workspaces/ACTIVATE_WORKSPACE_SUCCESS',
  ACTIVATE_WORKSPACE_FAIL = 'workspaces/ACTIVATE_WORKSPACE_FAIL',
  SAVE_WORKSPACE_REQUEST = 'workspaces/SAVE_WORKSPACE_REQUEST',
  SAVE_WORKSPACE_SUCCESS = 'workspaces/SAVE_WORKSPACE_SUCCESS',
  SAVE_WORKSPACE_FAIL = 'workspaces/SAVE_WORKSPACE_FAIL',
  DELETE_WORKSPACES_REQUEST = 'workspaces/DELETE_WORKSPACES_REQUEST',
  DELETE_WORKSPACES_SUCCESS = 'workspaces/DELETE_WORKSPACES_SUCCESS',
  DELETE_WORKSPACES_FAIL = 'workspaces/DELETE_WORKSPACES_FAIL',
  SET_WORKSPACE = 'workspaces/SET_WORKSPACE',
  UPLOAD_AVATAR_FILE_REQUEST = 'workspaces/UPLOAD_AVATAR_FILE_REQUEST',
  UPLOAD_AVATAR_FILE_SUCCESS = 'workspaces/UPLOAD_AVATAR_FILE_SUCCESS',
  UPLOAD_AVATAR_FILE_FAIL = 'workspaces/UPLOAD_AVATAR_FILE_FAIL',
  UPLOAD_BACKGROUND_FILE_REQUEST = 'workspaces/UPLOAD_BACKGROUND_FILE_REQUEST',
  UPLOAD_BACKGROUND_FILE_SUCCESS = 'workspaces/UPLOAD_BACKGROUND_FILE_SUCCESS',
  UPLOAD_BACKGROUND_FILE_FAIL = 'workspaces/UPLOAD_BACKGROUND_FILE_FAIL',
  RESET_IMAGE = 'workspaces/RESET_IMAGE',
  CLEAR_IMAGE = 'workspaces/CLEAR_IMAGE',
}

export type WorkspacesAction =
  | { type: workspacesActionTypes.GET_WORKSPACES_REQUEST }
  | { type: workspacesActionTypes.GET_WORKSPACES_SUCCESS; payload: WorkspaceInput[] }
  | { type: workspacesActionTypes.GET_WORKSPACES_FAIL; error: unknown }
  | { type: workspacesActionTypes.CREATE_WORKSPACE_REQUEST }
  | { type: workspacesActionTypes.CREATE_WORKSPACE_SUCCESS }
  | { type: workspacesActionTypes.CREATE_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST; payload: string }
  | { type: workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS }
  | { type: workspacesActionTypes.ACTIVATE_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.UPDATE_WORKSPACE; payload: Partial<WorkspaceData> }
  | { type: workspacesActionTypes.UPDATE_WORKSPACE_STYLE; payload: Partial<BookingPageStyleInput> }
  | { type: workspacesActionTypes.SAVE_WORKSPACE_REQUEST }
  | { type: workspacesActionTypes.SAVE_WORKSPACE_SUCCESS }
  | { type: workspacesActionTypes.SAVE_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.DELETE_WORKSPACES_REQUEST}
  | { type: workspacesActionTypes.DELETE_WORKSPACES_SUCCESS }
  | { type: workspacesActionTypes.DELETE_WORKSPACES_FAIL; error: unknown }
/*   | { type: workspacesActionTypes.SELECT_WORKSPACE; payload: string }
  | { type: workspacesActionTypes.UNSELECT_WORKSPACE; payload: string }
  | { type: workspacesActionTypes.UNSELECT_ALL_WORKSPACES } */
  | { type: workspacesActionTypes.SET_WORKSPACE; payload: WorkspaceData }
  // | { type: workspacesActionTypes.SET_TENANT_STYLE; payload: BookingPageStyleInput }
  | { type: workspacesActionTypes.UPLOAD_AVATAR_FILE_REQUEST; payload: UploadImageRequestPayload }
  | { type: workspacesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS; payload: UploadImageSuccessPayload }
  | { type: workspacesActionTypes.UPLOAD_AVATAR_FILE_FAIL; error: unknown }
  | { type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST; payload: UploadImageRequestPayload }
  | { type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS; payload: UploadImageSuccessPayload }
  | { type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_FAIL; error: unknown }
  | { type: workspacesActionTypes.RESET_IMAGE; payload: ImageType }
  | { type: workspacesActionTypes.CLEAR_IMAGE; payload: ImageType };

const getWorkspacesRequest = (): WorkspacesAction => ({ type: workspacesActionTypes.GET_WORKSPACES_REQUEST });
const getWorkspacesSuccess = (payload: WorkspaceInput[]): WorkspacesAction => ({
  type: workspacesActionTypes.GET_WORKSPACES_SUCCESS,
  payload,
});
const getWorkspacesFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.GET_WORKSPACES_FAIL,
  error,
});

const createWorkspaceRequest = (): WorkspacesAction => ({
  type: workspacesActionTypes.CREATE_WORKSPACE_REQUEST,
});
const createWorkspaceSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.CREATE_WORKSPACE_SUCCESS,
});
const createWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.CREATE_WORKSPACE_FAIL,
  error,
});
const updateWorkspace = (payload: Partial<WorkspaceData>): WorkspacesAction => ({
  type: workspacesActionTypes.UPDATE_WORKSPACE,
  payload,
});

const updateWorkspaceStyle = (payload: Partial<BookingPageStyleInput>): WorkspacesAction => ({
  type: workspacesActionTypes.UPDATE_WORKSPACE_STYLE,
  payload,
});

const activateWorkspaceRequest = (payload: string): WorkspacesAction => ({
  type: workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST,
  payload,
});
const activateWorkspaceSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS
});
const activateWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.ACTIVATE_WORKSPACE_FAIL,
  error,
});
const saveWorkspaceRequest = (): WorkspacesAction => ({
  type: workspacesActionTypes.SAVE_WORKSPACE_REQUEST,
});
const saveWorkspaceSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.SAVE_WORKSPACE_SUCCESS,
});
const saveWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.SAVE_WORKSPACE_FAIL,
  error,
});
const deleteWorkspacesRequest = (): WorkspacesAction => ({
  type: workspacesActionTypes.DELETE_WORKSPACES_REQUEST
});
const deleteWorkspacesSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.DELETE_WORKSPACES_SUCCESS
});
const deleteWorkspacesFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.DELETE_WORKSPACES_FAIL,
  error,
});

const setWorkspace = (payload: WorkspaceData): WorkspacesAction => ({
  type: workspacesActionTypes.SET_WORKSPACE,
  payload,
});

const uploadAvatarFileRequest = (payload: UploadImageRequestPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_AVATAR_FILE_REQUEST,
  payload,
});
const uploadAvatarFileSuccess = (payload: UploadImageSuccessPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS,
  payload,
});
const uploadAvatarFileFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_AVATAR_FILE_FAIL,
  error,
});
const uploadBackgroundFileRequest = (payload: UploadImageRequestPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST,
  payload,
});
const uploadBackgroundFileSuccess = (payload: UploadImageSuccessPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS,
  payload,
});
const uploadBackgroundFileFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_FAIL,
  error,
});
const resetImage = (payload: ImageType): WorkspacesAction => ({
  type: workspacesActionTypes.RESET_IMAGE,
  payload,
});
const clearImage = (payload: ImageType): WorkspacesAction => ({
  type: workspacesActionTypes.CLEAR_IMAGE,
  payload,
});

export const workspacesActions = {
  getWorkspacesRequest,
  getWorkspacesSuccess,
  getWorkspacesFail,
  createWorkspaceRequest,
  createWorkspaceSuccess,
  createWorkspaceFail,
  updateWorkspace,
  updateWorkspaceStyle,
  activateWorkspaceRequest,
  activateWorkspaceSuccess,
  activateWorkspaceFail,
  saveWorkspaceRequest,
  saveWorkspaceSuccess,
  saveWorkspaceFail,
  deleteWorkspacesRequest,
  deleteWorkspacesSuccess,
  deleteWorkspacesFail,
  setWorkspace,
  uploadAvatarFileRequest,
  uploadAvatarFileSuccess,
  uploadAvatarFileFail,
  uploadBackgroundFileRequest,
  uploadBackgroundFileSuccess,
  uploadBackgroundFileFail,
  resetImage,
  clearImage
};
