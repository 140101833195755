import { useDispatch, useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { ConfirmationModal } from '../../common';
import { Button } from 'primereact/button';
import { MicrosoftCodeResponse, ThirdPartyType, authentificationSelectors } from '../../../store/authentification';
import { CodeResponse } from '@react-oauth/google';
import { GoogleAuthButton, MicrosoftAuthButton } from '../../public';
import { Divider } from 'primereact/divider';
import { useState } from 'react';
import { IntegrationType } from '../../../API';

export const MyLogin: React.FC = () => {
  const dispatch = useDispatch();
  const email = useSelector(userSettingsSelectors.selectEmail);
  const isGoogleConnected = useSelector(authentificationSelectors.selectIsThirdPartyGoogle);
  const isMicrosoftConnected = useSelector(authentificationSelectors.selectIsThirdPartyMicrosoft);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [switchTo, setSwitchTo] = useState<ThirdPartyType | null>(null);

  const handleSwitch = (switchTo: ThirdPartyType) => {
    setSwitchTo(switchTo);
    setIsConfirmModalOpen(true);
  };

  const handleSwitchGoogleAuthSuccess = (codeResponse: CodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        codeResponse,
        type: ThirdPartyType.GOOGLE,
        integrationType: IntegrationType.GOOGLE_CALENDAR,
        switchAccount: true,
        login: false,
      })
    );
    setIsConfirmModalOpen(false);
  };

  const handleSwitchMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        codeResponse,
        type: ThirdPartyType.MICROSOFT,
        integrationType: IntegrationType.MICROSOFT_CALENDAR,
        switchAccount: true,
        login: false,
      })
    );
    setIsConfirmModalOpen(false);
  };

  return (
    <div>
      <span>{labels.description}:</span>
      <Divider />
      <div className="flex justify-content-between align-items-start mb-3">
        <div>
          <h5 className="font-normal mb-2">{labels.googleAccount}</h5>
          {isGoogleConnected && (
            <>
              <div className="font-semibold text-0 w-max pt-1 pb-1 pr-4 pl-4 border-round-3xl bg-green-500">
                {labels.connected}
              </div>
              <p>{email}</p>
            </>
          )}
        </div>
        <Button
          className="w-max"
          outlined
          label={isGoogleConnected ? labels.googleAccountSwitch : labels.googleSwitch}
          onClick={() => handleSwitch(ThirdPartyType.GOOGLE)}
        />
      </div>
      <Divider />
      <div className="flex justify-content-between align-items-start mb-3">
        <div>
          <h5 className="font-normal mb-2">{labels.microsoftAccount}</h5>
          {isMicrosoftConnected && (
            <>
              <div className="font-semibold text-0 w-max pt-1 pb-1 pr-4 pl-4 border-round-3xl bg-green-500">
                {labels.connected}
              </div>
              <p>{email}</p>
            </>
          )}
        </div>
        <Button
          className="w-max"
          outlined
          label={isMicrosoftConnected ? labels.microsoftAccountSwitch : labels.microsoftSwitch}
          onClick={() => handleSwitch(ThirdPartyType.MICROSOFT)}
        />
      </div>
      <ConfirmationModal
        visible={isConfirmModalOpen}
        title={switchTo === ThirdPartyType.MICROSOFT ? labels.microsoftSwitch : labels.googleSwitch}
        description={
          labels.switchDescriptionPart1 +
          (isGoogleConnected ? labels.google : labels.microsoft) +
          labels.switchDescriptionPart2 +
          (switchTo === ThirdPartyType.GOOGLE ? labels.google : labels.microsoft)
        }
        additionalText={[labels.switchAccountMessage]}
        cancelButtonLabel={labels.no}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
        modalClassName={'max-w-30rem'}
        CustomConfirmButton={
          switchTo === ThirdPartyType.MICROSOFT ? (
            <MicrosoftAuthButton
              className="ml-3 w-6rem font-semibold p-button-danger"
              label={labels.yes}
              onRedirect={handleSwitchMicrosoftAuthRedirect}
            />
          ) : (
            <GoogleAuthButton
              className="ml-3 w-6rem font-semibold p-button-danger"
              label={labels.yes}
              onSuccess={handleSwitchGoogleAuthSuccess}
            />
          )
        }
      />
    </div>
  );
};
