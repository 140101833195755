import { Button } from 'primereact/button';
import labels from './labels';
import { Modal } from '../../../common';
import {
  CHARGEBEE_TERM_OPTIONS,
  getTotalAmount,
  getPricePerUser,
  CHARGEBEE_YEARLY_TERM,
  billingSelectors,
} from '../../../../store/billing';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  PAY_LATER_MODAL_NAME,
  orgsActions,
  orgsSelectors,
  payLaterModalActions,
} from '../../../../store/opsConsole/orgs';
import { InputText } from 'primereact/inputtext';

export const OrgLicenseModal = () => {
  const dispatch = useDispatch();
  const licensesAssigned = useSelector(orgsSelectors.selectLicenseAssigned);
  const email = useSelector(orgsSelectors.selectPayLaterEmail);
  const term = useSelector(orgsSelectors.selectPayLaterTerm);
  const quantity = useSelector(orgsSelectors.selectPayLaterQuantity);
  const tires = useSelector(billingSelectors.selectSubscriptionTiers) || [];

  const amount = getTotalAmount(quantity, term, tires);

  const handleEmailChange = (email: string) => {
    dispatch(orgsActions.updatePayLater({ email }));
  };

  const handleTermChange = (term: string) => {
    dispatch(orgsActions.updatePayLater({ term }));
  };

  const handleQuantityChange = (quantity: number) => {
    dispatch(orgsActions.updatePayLater({ quantity }));
  };

  const handleClose = () => {
    dispatch(payLaterModalActions.closeModal());
  };

  const handlePurchase = () => {
    dispatch(orgsActions.convertToPayLaterRequest());
  };

  return (
    <Modal.Container name={PAY_LATER_MODAL_NAME}>
      <Modal.Header>
        <div className="w-25rem text-center">{labels.buyLicenses}</div>
      </Modal.Header>
      <div className="p-fluid pb-2">
        <label>{labels.billingEmail}</label>
        <InputText
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          className={!email ? 'p-invalid' : ''}
        />
      </div>
      <div className="p-fluid pb-2">
        <label htmlFor="quantity">{labels.numberLicenses}</label>
        <InputNumber
          inputId="quantity"
          value={quantity}
          onChange={(e) => handleQuantityChange(e.value || 0)}
          placeholder={labels.enterNumber}
          min={0}
          className={!quantity || quantity < licensesAssigned ? 'p-invalid' : ''}
        />
        {quantity < licensesAssigned && (
          <div className="text-sm p-error w-25rem">
            {`${labels.licenseMessagePart1} ${licensesAssigned} ${labels.licenseMessagePart2}`}
          </div>
        )}
      </div>
      <div className="p-fluid pb-2">
        <label htmlFor="term">{labels.term}</label>
        <Dropdown
          inputId="term"
          value={term}
          options={CHARGEBEE_TERM_OPTIONS}
          onChange={(e) => handleTermChange(e.target.value)}
        />
      </div>
      <div className="pt-2">
        {`${labels.buyLicensesMessage} $${amount} ${term === CHARGEBEE_YEARLY_TERM ? labels.yearly : labels.monthly}.`}
      </div>
      <div className="text-sm">
        {`${quantity || 0} ${labels.licenses} X $${getPricePerUser(quantity, tires, term)} X ${
          term === CHARGEBEE_YEARLY_TERM ? 12 : 1
        } ${labels.month} = $${amount}`}
      </div>
      <Modal.Buttons>
        <div className="flex justify-content-between -mr-2">
          <Button onClick={handleClose} outlined>
            {labels.cancel}
          </Button>
          <Button onClick={handlePurchase} autoFocus disabled={!email || quantity < licensesAssigned} className="px-4">
            {labels.next}
          </Button>
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
