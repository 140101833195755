import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from '../../components/common';
import { PublicBookingPageContainer } from '../../components/publicBookingPage';
import { EventSteps, eventActions, eventSelectors } from '../../store/publicBookingPage';

export const PublicBookingPage = () => {
  const { bookingPageId, eventId } = useParams();
  const dispatch = useDispatch();
  const agendaLoaded = useSelector(eventSelectors.selectAgendaLoaded);
  const error = useSelector(eventSelectors.selectError);
  const style = useSelector(eventSelectors.selectBookingPageStyle);
  const background = useSelector(eventSelectors.selectBackground);

  const [searchParams] = useSearchParams();
  const hideHeader = searchParams.get('hideHeader') === 'true';
  const hideCookie = searchParams.get('hideCookie') === 'true';
  const hidePageDetails = searchParams.get('hidePageDetails') === 'true';
  const iframe = searchParams.get('iframe') === 'true';
  const editKey = searchParams.get('editKey');

  useEffect(() => {
    if (eventId) {
      dispatch(eventActions.setEventId(eventId));
      dispatch(eventActions.setEventStep(EventSteps.BOOKED));
      // searchParams.get('cancel') === 'true' && dispatch(eventActions.setEventStep(EventSteps.CANCEL));
    } else if (bookingPageId) {
      dispatch(eventActions.setBookingPageId(bookingPageId));
    }
    dispatch(
      eventActions.setViewSettings({
        editKey, 
        hideHeader,
        hideCookie,
        hidePageDetails,
        iframe,
      })
    );
    dispatch(eventActions.getAgendaRequest());
  }, []);

  const containerClassName = `flex justify-content-center min-h-screen bg-cover bg-center ${
    iframe ? '' : 'md:pt-6 md:pb-3'
  }`;
  const containerStyle = background
    ? { backgroundImage: `url(${background})` }
    : { backgroundColor: `${style.lookAndFeel?.siteColorBackground}` };

  return agendaLoaded || error ? (
    <div className={containerClassName} style={containerStyle}>
      <PublicBookingPageContainer />
    </div>
  ) : (
    <Preloader />
  );
};
