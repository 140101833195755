import { memo, useState, useEffect, CSSProperties } from 'react';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../../types/constants';

interface ILabel {
  value: string;
  editClassName?: string;
  textClassName?: string;
  inputClassName?: string;
  textStyle?: CSSProperties;
  editable?: boolean;
  onChange?: (value: string) => void;
  onBlurUpdate?: (value: string) => void;
}

export const Label = memo(
  ({ value, editClassName, textClassName, inputClassName, textStyle, editable, onChange, onBlurUpdate }: ILabel) => {
    const [label, setLabel] = useState(value);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => setLabel(value), [value]);

    const handleChange = (value: string) => {
      setLabel(value);
      onChange && onChange(value);
    };

    const handleBlur = () => {
      setEditMode(false);
      onBlurUpdate && onBlurUpdate(label);
    };

    return (
      <>
        {editable ? (
          <div className={`relative w-full ${editClassName}`} style={textStyle}>
            <span className={`w-full ${editMode && 'hidden'}`}>
              <span className={textClassName}>{value}</span>
              <i className="pi pi-pencil font-semibold pl-2 -mr-4" />
            </span>
            <InputText
              type="text"
              className={`w-full border-none shadow-none py-0 -mx-2 bg-transparent ${textClassName} ${inputClassName} ${
                !editMode && 'absolute left-0 opacity-0'
              }`}
              style={textStyle}
              value={label}
              onChange={(e) => handleChange(e.target.value.trimStart())}
              onFocus={() => setEditMode(true)}
              onBlur={handleBlur}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>
        ) : (
          <span className={textClassName} style={textStyle}>{value}</span>
        )}
      </>
    );
  }
);

Label.displayName = 'Label';
