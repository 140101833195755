import { Column } from 'primereact/column';
import {
  DATA_TERM_OPTIONS,
  ORG_STATUS_OPTIONS,
  ORG_TYPE_OPTIONS,
  OrgsDataTableType,
  orgsSelectors,
} from '../../../../store/opsConsole/orgs';
import { OrgsTableRowActions } from '../orgsTableRowActions/OrgsTableRowActions';
import { useSelector } from 'react-redux';
import labels from './labels';
import { formatDateUTC } from '../../../../services/DateService';
import { DateFormat } from '../../../../store/userSettings';
import { Path } from '../../../../routing';
import { navigationService } from '../../../../services/NavigationService';
import { Link } from 'react-router-dom';
import { Table } from '../../table/Table';
import { authentificationSelectors } from '../../../../store/authentification';
import { DataTableRowClickEvent } from 'primereact/datatable';

export const OrgsTable = () => {
  const orgsRecords = useSelector(orgsSelectors.selectSearchOrgs);
  const isPaginatorEnabled = useSelector(orgsSelectors.selectIsPaginatorEnabled);
  const isMainAdminOrOperations = useSelector(authentificationSelectors.selectIsMainAdminOrOperations);

  const openOrgDetails = (e: DataTableRowClickEvent) => {
    navigationService.navigateTo(Path.OPSConsoleOrgDetails.replace(':orgId', e.data.tenantId))
  }

  return (
    <Table
      value={orgsRecords}
      paginator={isPaginatorEnabled}
      resizableColumns
      className="pt-3 cursor-pointer"
      onRowClick={openOrgDetails}
      rowHover
      paths={[Path.OPSConsoleOrgs, Path.OPSConsoleOrgDetails]}
    >
      <Column
        field="accountName"
        header={labels.account}
        body={(data: OrgsDataTableType) => (
          <Link
            className="text-color underline hover:text-primary"
            to={Path.OPSConsoleAccount.replace(':accountId', data.accountId)}
          >
            {data.accountName}
          </Link>
        )}
        sortable
        bodyClassName="text-overflow-ellipsis max-w-10rem"
      />
      <Column field="tenantId" header={labels.orgId} sortable bodyClassName="text-overflow-ellipsis max-w-8rem" />
      <Column
        field="status"
        body={(data) => ORG_STATUS_OPTIONS.find((status) => status.value === data.status)?.label}
        header={labels.status}
        sortable
        bodyClassName="text-overflow-ellipsis"
      />
      <Column
        field="type"
        body={(data) => ORG_TYPE_OPTIONS.find((type) => type.value === data.type)?.label}
        header={labels.type}
        sortable
        bodyClassName="text-overflow-ellipsis"
      />
      <Column
        field="term"
        body={(data) => DATA_TERM_OPTIONS.find((type) => type.value === data.term)?.label}
        header={labels.term}
        sortable
        bodyClassName="text-overflow-ellipsis"
      />
      <Column field="amount" header={labels.arr} sortable bodyClassName="text-overflow-ellipsis" />
      <Column
        field="owned"
        header={labels.licensesNum}
        sortable
        bodyClassName="text-overflow-ellipsis"
        className="max-w-5rem"
      />
      <Column
        field="percentOfWeekAdoption"
        header={labels.adoptionPercent}
        sortable
        bodyClassName="text-overflow-ellipsis"
        className="max-w-5rem"
      />
      <Column field="daysLeft" header={labels.daysLeft} sortable bodyClassName="text-overflow-ellipsis" />
      <Column field="bookedMeetings" header={labels.bookedNumber} sortable bodyClassName="text-overflow-ellipsis" />
      <Column
        field="updatedAt"
        header={labels.lastActive}
        body={(data: OrgsDataTableType) => formatDateUTC(data.updatedAt, DateFormat.default)}
        sortable
        bodyClassName="text-overflow-ellipsis"
        frozen
      />
      <Column
        body={(data: OrgsDataTableType) =>
          OrgsTableRowActions({
            tenantId: data.tenantId,
            orgType: data.type,
            showExpire: isMainAdminOrOperations,
            showExtend: isMainAdminOrOperations,
          })
        }
        className="w-4rem"
        align="center"
        frozen
      />
    </Table>
  );
};
