import { t } from 'i18next';
import { ToastNotificationOptions } from '../../notifications';
import { AccountWithTenants } from './types';

export const DEFAULT_ACCOUNT: Partial<AccountWithTenants> = {
  id: '',
  name: '',
  note: '',
  tenantIds: [],
  tenants: [],
};

export const SAVE_ACCOUNT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 10000,
  message: t('OPSConsoleAccounts:SAVE_ACCOUNT_SUCCESS_TOAST'),
});
export const SAVE_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 5000,
  message: t('OPSConsoleAccounts:SAVE_ACCOUNT_ERROR_TOAST'),
});

export const DELETE_ACCOUNT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 10000,
  message: t('OPSConsoleAccounts:DELETE_ACCOUNT_SUCCESS_TOAST'),
});
export const DELETE_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 5000,
  message: t('OPSConsoleAccounts:DELETE_ACCOUNT_ERROR_TOAST'),
});

export const GET_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 5000,
  message: t('OPSConsoleAccounts:GET_ACCOUNT_ERROR_TOAST'),
});
export const GET_ACCOUNTS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 5000,
  message: t('OPSConsoleAccounts:GET_ACCOUNTS_ERROR_TOAST'),
});
