export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Arrière-plan`,
  BUTTONS_LABEL: `Boutons`,
  FONT_COLORS_TITLE: `Couleurs de police`,
  FONT_FAMILIES_TITLE: `Familles de polices`,
  HEADER_FONT_LABEL: `Police d'en-tête`,
  HEADER_LABEL: `En-tête`,
  MAIN_TEXT_FONT_LABEL: `Police du texte principal`,
  MAIN_TEXT_LABEL: `Texte principal`,
  PRIMARY_LABEL: `Principal`,
  SITE_COLORS_TITLE: `Couleurs du site`,
  SUB_HEADER_FONT_LABEL: `Police du sous-en-tête`,
  SUB_HEADER_LABEL: `Sous-en-tête`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `Votre calendrier est connecté !`,
  INSTRUCTION_NOTE: `La plupart des administrateurs aiment utiliser SUMO pour leur usage personnel et de test. Cependant, vous pouvez toujours déconnecter votre calendrier plus tard dans le menu en haut à droite.`,
  INSTRUCTION_STEP_A1: `Nous vérifierons `,
  INSTRUCTION_STEP_A2: ` pour les conflits.`,
  INSTRUCTION_STEP_B: `Nous ajouterons des réunions au calendrier pour `,
  INSTRUCTION_STEP_C1: `Nous chiffrerons `,
  INSTRUCTION_STEP_C2: `toutes les données`,
  INSTRUCTION_STEP_C3: `en transit et vous propose des options concernant vos données.`,
  INSTRUCTION_TITLE: `Voici comment SUMO fonctionnera avec `,
  MESSAGE: `SUMO vérifiera automatiquement votre calendrier pour les moments occupés et ajoutera de nouvelles réunions au fur et à mesure de leur programmation.`,
  TITLE: `Calendrier`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Accepter les conditions et`,
  ACCEPT_TERMS_PART2: `la`,
  ACCEPT_TERMS_PART3: `Politique de confidentialité`,
  BENEFITS_DESCRIPTION: `La meilleure expérience SUMO1 est d'utiliser SUMO depuis votre boîte de réception en installant l'extension Chrome. Utilisez SUMO dans :`,
  BENEFITS_LIST_PART1: `Google Gmail & Calendar`,
  BENEFITS_LIST_PART2: `Microsoft Inbox & Calendar`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Avantages`,
  CHROME_EXTENSION: `Extension Chrome`,
  REQUIREMENTS_DESCRIPTION: `Vous devez utiliser le navigateur Google Chrome.`,
  REQUIREMENTS_TITLE: `Exigences`,
  SUMO1_FOR_GOOGLE: `Installer "SUMO1 pour Gmail"`,
  SUMO1_FOR_MICROSOFT: `Installer "SUMO1 pour Outlook"`,
  TITLE: `Installer l'extension Chrome`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  ADMIN_DESCRIPTION: `Quand votre équipe est-elle généralement disponible pour des rendez-vous ?`,
  MESSAGE: `ASTUCE : Vous pouvez définir plusieurs plages horaires et remplacements plus tard.`,
  MINIMUM_DAYS_ERROR_TEXT: `Veuillez sélectionner au moins un jour avec des heures disponibles`,
  SETUP_DAYS_INPUT_LABEL: `Jours disponibles`,
  SETUP_HOURS_INPUT_LABEL: `Heures disponibles`,
  TITLE: `Disponibilité`,
  USER_DESCRIPTION: `Quand êtes-vous généralement disponible pour des rendez-vous ?`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Lorsque SUMO réserve automatiquement un appel téléphonique pour vous, quel numéro de téléphone le visiteur doit-il appeler pour vous joindre ?`,
  MESSAGE: `C'est une étape importante dans le processus de configuration de SUMO. La plupart des utilisateurs saisissent leur numéro de téléphone portable, afin de recevoir également des alertes SMS. Bien sûr, vous pouvez toujours le modifier ultérieurement dans les paramètres utilisateur (menu en haut à droite).`,
  PHONE_DETAILS_LABELS: `Détails du téléphone`,
  PHONE_DETAILS_PLACEHOLDER: `Ajoutez des informations ou des instructions supplémentaires ici.\r\nEx : J'ai hâte de vous rencontrer. Envoyez-moi un SMS à tout moment.`,
  PHONE_LABEL: `Votre numéro de téléphone`,
  PHONE_PLACEHOLDER: `Entrez votre numéro de téléphone ici`,
  TITLE: `Appels téléphoniques`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Échec de la demande d'enregistrement de la configuration rapide`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Vous êtes maintenant prêt pour une planification facile.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Configuration terminée !`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  COMPLETED_BUTTON_LABEL: `J'ai terminé cela.`,
  DEFAULT_DESCRIPTION: `Conférence Vidéo par Défaut`,
  DESCRIPTION: `Connectez vos comptes de vidéoconférence.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MESSAGE: `Facultatif, mais recommandé pour les réunions virtuelles.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  TITLE: `Vidéoconférence`,
  ZOOM_DESC: `Ajoutez automatiquement les détails Zoom dans vos réunions SUMO.`,
  ZOOM_REQUIREMENTS: `Selon votre configuration, l'administrateur de votre compte Zoom peut être tenu d'approuver préalablement SUMO1 dans le Zoom Marketplace.`,
  ZOOM_STEP_1: `Connectez-vous à votre compte ZOOM d'entreprise en tant qu'administrateur.`,
  ZOOM_STEP_2: `Cliquez sur Paramètres en haut à droite.`,
  ZOOM_STEP_3: `Recherchez SUMO1 sur le marché ZOOM.`,
  ZOOM_STEP_4: `Cliquez sur "Autoriser SUMO1".`,
  ZOOM_STEP_5: `Cliquez sur accepter.`,
  ZOOM_TITLE: `Réunions ZOOM`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  COLORS_NOTES: `Ne vous inquiétez pas, vous pouvez modifier et tester ces paramètres sur les pages de réservation plus tard.`,
  COLORS_TITLE: `Couleurs et polices`,
  BACKGROUND: `Arrière-plan`,
  DESCRIPTION: `Rendez l'expérience de réservation conforme à l'image de marque de votre entreprise.`,
  LOGO: `Logo`,
  LOGO_DESCRIPTION: `Votre image de marque d'entreprise apparaîtra sur toutes les pages de réservation dans cet espace de travail.`,
  LOGO_TITLE: `Votre logo & arrière-plan`,
  PREVIEW_TOOLTIP: `Après avoir ajusté les paramètres, cliquez sur Aperçu pour voir une page de réservation d'exemple.`,
  SAVE_BUTTON: `Enregistrer et terminer la configuration `,
  TITLE: `Votre image de marque`,
  UPLOAD_BACKGROUND: `Télécharger l'arrière-plan`,
  UPLOAD_LOGO: `Télécharger le logo`,
  YOUR_BACKGROUND: `Votre arrière-plan`,
  YOUR_LOGO: `Votre logo`,
});
