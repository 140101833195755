import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Modal } from '../../../common';
import labels from './labels';
import { DELETE_ORG_MODAL_NAME, orgsActions, deleteOrgModalActions } from '../../../../store/opsConsole/orgs';

export const OrgDeleteModal = () => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(deleteOrgModalActions.closeModal());
  };

  const handleDelete = () => {
    dispatch(orgsActions.deleteOrgRequest());
  };

  return (
    <Modal.Container name={DELETE_ORG_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="max-w-30rem mb-3">{labels.description}</div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined autoFocus />
          <Button label={labels.delete} onClick={handleDelete} severity="danger" />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
