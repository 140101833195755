import { CreateAdminDataInput, CreateLicenseInput, CreateStaffInput, CreateTenantInput } from '../../API';
import { Path } from '../../routing';
import {
  UserDataCoreType,
  ThirdPartyAuthPayload,
  ThirdPartyType,
  InviteToAccount,
  ViewAsUserPayload,
  ResetViewAsUserPayload,
} from './typings';

export enum AuthentificationActionTypes {
  THIRD_PARTY_AUTH_REQUEST = 'authentification/THIRD_PARTY_AUTH_REQUEST',
  THIRD_PARTY_AUTH_SUCCESS = 'authentification/THIRD_PARTY_AUTH_SUCCESS',
  THIRD_PARTY_AUTH_FAIL = 'authentification/THIRD_PARTY_AUTH_FAIL',
  DELETE_USER_REQUEST = 'authentification/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'authentification/DELETE_USER_SUCCESS',
  DELETE_USER_FAIL = 'authentification/USER_DELETE_FAIL',
  LOGOUT_USER_REQUEST = 'authentification/LOGOUT_USER_REQUEST',
  LOGOUT_USER_SUCCESS = 'authentification/LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAIL = 'authentification/LOGOUT_USER_FAIL',
  UPDATE_USER_DATA_CORE = 'authentification/UPDATE_USER_DATA_CORE',
  SET_THIRD_PARTY = 'authentification/SET_THIRD_PARTY',
  SET_IS_QUICK_SETUP_FINISHED = 'authentification/SET_IS_QUICK_SETUP_FINISHED',
  SET_ACCOUNTS = 'authentification/SET_ACCOUNTS',
  CHOOSE_AN_ACCOUNT_REQUEST = 'authentification/CHOOSE_AN_ACCOUNT_REQUEST',
  CHOOSE_AN_ACCOUNT_SUCCESS = 'authentification/CHOOSE_AN_ACCOUNT_SUCCESS',
  CHOOSE_AN_ACCOUNT_FAIL = 'authentification/CHOOSE_AN_ACCOUNT_FAIL',
  SET_INVITE_TO_ACCOUNT = 'authentification/SET_INVITE_TO_ACCOUNT',
  SET_IS_LAST_SUPER_ADMIN = 'authentification/SET_IS_LAST_SUPER_ADMIN',
  GET_ZOOM_CODE_REDIRECT = 'authentification/GET_ZOOM_CODE_REQUEST',
  SET_SUMO1_ADMIN_DATA = 'authentification/SET_SUMO1_ADMIN_DATA',
  GET_TENANT_REQUEST = 'authentification/GET_TENANT_REQUEST',
  GET_TENANT_SUCCESS = 'authentification/GET_TENANT_SUCCESS',
  GET_TENANT_FAIL = 'authentification/GET_TENANT_FAIL',
  VIEW_AS_USER_REQUEST = 'authentification/VIEW_AS_USER_REQUEST',
  VIEW_AS_USER_SUCCESS = 'authentification/VIEW_AS_USER_SUCCESS',
  VIEW_AS_USER_FAIL = 'authentification/VIEW_AS_USER_FAIL',
  RESET_VIEW_AS_USER_REQUEST = 'authentification/RESET_VIEW_AS_USER_REQUEST',
  RESET_VIEW_AS_USER_SUCCESS = 'authentification/RESET_VIEW_AS_USER_SUCCESS',
  RESET_VIEW_AS_USER_FAIL = 'authentification/RESET_VIEW_AS_USER_FAIL',
  GET_LICENSE_REQUEST = 'authentification/GET_LICENSE_REQUEST',
  GET_LICENSE_SUCCESS = 'authentification/GET_LICENSE_SUCCESS',
  GET_LICENSE_FAIL = 'authentification/GET_LICENSE_FAIL',
  REFRESH_TRIAL_LICENSE_REQUEST = 'authentification/REFRESH_TRIAL_LICENSE_REQUEST',
  SET_COOCKIE_OPEN = 'authentification/SET_COOCKIE_OPEN',
  EXPIRE_LICENSE = 'authentification/EXPIRE_LICENSE',
}

export type AuthentificationAction =
  | { type: AuthentificationActionTypes.THIRD_PARTY_AUTH_REQUEST; payload: ThirdPartyAuthPayload }
  | { type: AuthentificationActionTypes.THIRD_PARTY_AUTH_SUCCESS }
  | { type: AuthentificationActionTypes.THIRD_PARTY_AUTH_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.DELETE_USER_REQUEST }
  | { type: AuthentificationActionTypes.DELETE_USER_SUCCESS }
  | { type: AuthentificationActionTypes.DELETE_USER_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.LOGOUT_USER_REQUEST; redirectTo: Path }
  | { type: AuthentificationActionTypes.LOGOUT_USER_SUCCESS }
  | { type: AuthentificationActionTypes.LOGOUT_USER_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.UPDATE_USER_DATA_CORE; payload: Partial<UserDataCoreType> }
  | { type: AuthentificationActionTypes.SET_THIRD_PARTY; payload: ThirdPartyType }
  | { type: AuthentificationActionTypes.SET_IS_QUICK_SETUP_FINISHED; payload: boolean }
  | { type: AuthentificationActionTypes.SET_ACCOUNTS; payload: CreateAdminDataInput[] }
  | { type: AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST; payload: CreateAdminDataInput }
  | { type: AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS }
  | { type: AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.SET_INVITE_TO_ACCOUNT; payload: InviteToAccount }
  | { type: AuthentificationActionTypes.SET_IS_LAST_SUPER_ADMIN; payload: boolean }
  | { type: AuthentificationActionTypes.GET_ZOOM_CODE_REDIRECT; uri: string }
  | { type: AuthentificationActionTypes.SET_SUMO1_ADMIN_DATA; payload: Partial<CreateStaffInput> }
  | { type: AuthentificationActionTypes.GET_TENANT_REQUEST }
  | { type: AuthentificationActionTypes.GET_TENANT_SUCCESS; payload: CreateTenantInput }
  | { type: AuthentificationActionTypes.GET_TENANT_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.VIEW_AS_USER_REQUEST; payload: ViewAsUserPayload }
  | { type: AuthentificationActionTypes.VIEW_AS_USER_SUCCESS }
  | { type: AuthentificationActionTypes.VIEW_AS_USER_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.RESET_VIEW_AS_USER_REQUEST; payload: ResetViewAsUserPayload }
  | { type: AuthentificationActionTypes.RESET_VIEW_AS_USER_SUCCESS }
  | { type: AuthentificationActionTypes.RESET_VIEW_AS_USER_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.GET_LICENSE_REQUEST }
  | { type: AuthentificationActionTypes.GET_LICENSE_SUCCESS; payload: CreateLicenseInput[] }
  | { type: AuthentificationActionTypes.GET_LICENSE_FAIL; error: unknown }
  | { type: AuthentificationActionTypes.REFRESH_TRIAL_LICENSE_REQUEST }
  | { type: AuthentificationActionTypes.SET_COOCKIE_OPEN; payload: boolean }
  | { type: AuthentificationActionTypes.EXPIRE_LICENSE };

const thirdPartyAuthRequest = (payload: ThirdPartyAuthPayload): AuthentificationAction => ({
  type: AuthentificationActionTypes.THIRD_PARTY_AUTH_REQUEST,
  payload,
});
const thirdPartyAuthSuccess = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.THIRD_PARTY_AUTH_SUCCESS,
});
const thirdPartyAuthFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.THIRD_PARTY_AUTH_FAIL,
  error,
});
const deleteUserRequest = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.DELETE_USER_REQUEST,
});
const deleteUserSuccess = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.DELETE_USER_SUCCESS,
});
const deleteUserFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.DELETE_USER_FAIL,
  error,
});
const logoutUserRequest = (redirectTo: Path): AuthentificationAction => ({
  type: AuthentificationActionTypes.LOGOUT_USER_REQUEST,
  redirectTo,
});
const logoutUserSuccess = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.LOGOUT_USER_SUCCESS,
});
const logoutUserFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.LOGOUT_USER_FAIL,
  error,
});
const updateUserDataCore = (payload: Partial<UserDataCoreType>): AuthentificationAction => ({
  type: AuthentificationActionTypes.UPDATE_USER_DATA_CORE,
  payload,
});
const setThirdParty = (payload: ThirdPartyType): AuthentificationAction => ({
  type: AuthentificationActionTypes.SET_THIRD_PARTY,
  payload,
});
const setIsQuickSetupFinished = (payload: boolean): AuthentificationAction => ({
  type: AuthentificationActionTypes.SET_IS_QUICK_SETUP_FINISHED,
  payload,
});
const setAccounts = (payload: CreateAdminDataInput[]): AuthentificationAction => ({
  type: AuthentificationActionTypes.SET_ACCOUNTS,
  payload,
});
const chooseAnAccountRequest = (payload: CreateAdminDataInput): AuthentificationAction => ({
  type: AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST,
  payload,
});
const chooseAnAccountSuccess = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS,
});
const chooseAnAccountFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_FAIL,
  error,
});
const setInviteToAccount = (payload: InviteToAccount): AuthentificationAction => ({
  type: AuthentificationActionTypes.SET_INVITE_TO_ACCOUNT,
  payload,
});
const setIsLastSuperAdmin = (payload: boolean): AuthentificationAction => ({
  type: AuthentificationActionTypes.SET_IS_LAST_SUPER_ADMIN,
  payload,
});
const getZoomCodeRedirect = (uri: string): AuthentificationAction => ({
  type: AuthentificationActionTypes.GET_ZOOM_CODE_REDIRECT,
  uri,
});
const setSUMO1AdminData = (payload: Partial<CreateStaffInput>): AuthentificationAction => ({
  type: AuthentificationActionTypes.SET_SUMO1_ADMIN_DATA,
  payload,
});
const getTenantRequest = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.GET_TENANT_REQUEST,
});
const getTenantSuccess = (payload: CreateTenantInput): AuthentificationAction => ({
  type: AuthentificationActionTypes.GET_TENANT_SUCCESS,
  payload,
});
const getTenantFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.GET_TENANT_FAIL,
  error,
});

const viewAsUserRequest = (payload: ViewAsUserPayload): AuthentificationAction => ({
  type: AuthentificationActionTypes.VIEW_AS_USER_REQUEST,
  payload,
});
const viewAsUserSuccess = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.VIEW_AS_USER_SUCCESS,
});
const viewAsUseFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.VIEW_AS_USER_FAIL,
  error,
});

const resetViewAsUserRequest = (payload: ResetViewAsUserPayload): AuthentificationAction => ({
  type: AuthentificationActionTypes.RESET_VIEW_AS_USER_REQUEST,
  payload,
});
const resetViewAsUserSuccess = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.RESET_VIEW_AS_USER_SUCCESS,
});
const resetViewAsUserFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.RESET_VIEW_AS_USER_FAIL,
  error,
});

const getLicenseRequest = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.GET_LICENSE_REQUEST,
});
const getLicenseSuccess = (payload: CreateLicenseInput[]): AuthentificationAction => ({
  type: AuthentificationActionTypes.GET_LICENSE_SUCCESS,
  payload,
});
const getLicenseFail = (error: unknown): AuthentificationAction => ({
  type: AuthentificationActionTypes.GET_LICENSE_FAIL,
  error,
});

const refreshTrialLicenseRequest = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.REFRESH_TRIAL_LICENSE_REQUEST,
});
const expireLicense = (): AuthentificationAction => ({
  type: AuthentificationActionTypes.EXPIRE_LICENSE,
});

const setCookieOpen = (payload: boolean): AuthentificationAction => ({
  type: AuthentificationActionTypes.SET_COOCKIE_OPEN,
  payload,
});

export const authentificationActions = {
  thirdPartyAuthRequest,
  thirdPartyAuthSuccess,
  thirdPartyAuthFail,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFail,
  getLicenseRequest,
  getLicenseSuccess,
  getLicenseFail,
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFail,
  updateUserDataCore,
  setThirdParty,
  setIsQuickSetupFinished,
  setAccounts,
  chooseAnAccountRequest,
  chooseAnAccountSuccess,
  chooseAnAccountFail,
  setInviteToAccount,
  setIsLastSuperAdmin,
  getZoomCodeRedirect,
  setSUMO1AdminData,
  getTenantRequest,
  getTenantSuccess,
  getTenantFail,
  viewAsUserRequest,
  viewAsUserSuccess,
  viewAsUseFail,
  resetViewAsUserRequest,
  resetViewAsUserSuccess,
  resetViewAsUserFail,
  refreshTrialLicenseRequest,
  setCookieOpen,
  expireLicense,
};
