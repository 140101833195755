export const PublicBookingPage = Object.freeze({
  HOST: `Hôte :`,
  HOST_PHONE: `Téléphone hôte`,
  EDIT_BUTTON: `Éditer`,
  MORE: `Plus`,
  TIME_ZONE_LABEL: `Fuseau horaire`,
  NAME_FIELD_LABEL: `Nom`,
  EMAIL_FIELD_LABEL: `E-mail`,
  INVITE_OTHERS_FIELD_LABEL: `Participants supplémentaires`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Invitez d'autres personnes en saisissant leurs adresses e-mail séparées par des virgules`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Note pour les participants`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Saisissez une note pour tous les participants`,
  NO_USERS_AVAILABLE_LABEL: `Aucun utilisateur n'est disponible à l'heure sélectionnée. Veuillez choisir une autre heure.`,
  CANCEL_TYPE_LABEL: `Pourquoi annuler ?`,
  CANCEL_REASON_LABEL: `Raison de l'annulation :`,
  IN_PERSON_LABEL: `En personne`,
  PHONE_LABEL: `Téléphone`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Erreur`,
  VIDEO_CONFERENCE_LABEL: `Conférence vidéo`,
  SLOTS_NO_FOUND: `Machines à sous non trouvées`,
  VIEW_NEXT_MONTH: `Voir le mois prochain`,
  ALLOW_SMS: `Recevoir des rappels de rendez-vous par SMS ?`,
});

export const ScheduledMeetings = Object.freeze({
  ATTENDEES: `Invités`,
  CANCELED: 'Annulé',
  CANCEL_MEETING_TITLE: `Annuler la réunion`,
  CANCEL_MEETING_DESCRIPTION: `Pourquoi annuler ?`,
  CANCEL_REASON: `Raison de l'annulation :`,
  DETAILS: `Détails`,
  BOOKED: `Réservé`,
  BOOKING_PAGE: `Page de réservation`,
  BOOKING_PAGE_PLACEHOLDER: `Sélectionner la page de réservation`,
  EDIT_ATTENDEE_LIMIT: `Modifier la limite de participants`,
  GOOGLE_MEET: `Google Meet`,
  HOSTS: `Hôtes`,
  IN_PERSON: `En personne`,
  INVITEE_ANSWERS: `Réponses des invités`,
  LOCATION_TITLE: `Emplacement`,
  NO_SCHEDULED_MEETINGS: `Vous n'avez aucune réunion réservée`,
  NO_LOCATION_GIVEN: `Aucun emplacement spécifié`,
  NO_SHOW_LABEL: `Absent`,
  MARK_AS_NO_SHOW: `Marquer comme absent`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Êtes-vous sûr de vouloir marquer`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `comme absent ?`,
  MARK_AS_NO_SHOW_TOOLTIP: `L'événement n'a pas encore commencé.`,
  MEETING_NOTES: `Notes internes de réunion`,
  MEETING_NOTES_DESC: `Seul l'hôte verra ces notes.`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FOR_ATTENDEES: `Note pour les participants`,
  PAST: `Passé`,
  PHONE_CALL: `Appel téléphonique`,
  RESCHEDULE: `Reporter`,
  REASON_UNEXPECTED: `Conflit inattendu`,
  REASON_INVITE: `Besoin d'inviter d'autres personnes`,
  REASON_TIME: `Besoin de temps pour se préparer`,
  REASON_OTHER: `Autre`,
  STATUS: `Statut`,
  STATUS_PLACEHOLDER: `Sélectionner le statut`,
  TEAM: `Équipe`,
  TITLE: `Réunions réservées`,
  VIEW_ATTENDEES: `Voir les participants`,
  VIEW_ATTENDEES_DESC: `Il s'agit des participants actuels à cette réunion`,
  VIEW_ATTENDEES_NOTE: `L'hôte peut ajouter ou supprimer des participants de son calendrier connecté.`,
  UNDO_NO_SHOW: `Annuler l'absence`,
  UPCOMING: `À venir`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Conférence vidéo`,
});

export const ScheduledMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Échec de la demande d'événement planifié`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Échec de la demande d'annulation d'événement`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Demande d'annulation d'événement réussie`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Échec de la demande de mise à jour d'événement`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Demande de suppression d'événement réussie`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Échec de la demande de suppression d'événement`,
});

export const HostNotificationTemplates = Object.freeze({
  HOST_CONFIRMATION_TEMPLATE_SUBJECT: `Nouvelle réunion : <Meeting Name> avec <Invitee Name> à <Meeting Time>, <Meeting Date>`,
  HOST_RESCHEDULE_SUBJECT: `Reprogrammé : <Meeting Name> avec <Invitee Name>`,
  HOST_CANCELATION_SUBJECT: `Annulé : <Meeting Name> avec <Invitee Name> à <Meeting Time>, <Meeting Date>`,
  HOST_CONFIRMATION_TEMPLATE_BODY: `Bonjour <Host Name>,

Votre nouvelle réunion <Meeting Name> est prévue.

<b>Invité</b>
<Invitee Name> ( <Invitee Email> )

<b>Heure et Date</b>
<Meeting Time>, <Meeting Date>

<b>Lieu</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_RESCHEDULE_BODY: `Bonjour <Host Name>,

Votre réunion <Meeting Name> a été reprogrammée.

<b>Invité</b>
<Invitee Name> ( <Invitee Email> )

<b>Heure et Date mises à jour</b>
<Meeting Time>, <Meeting Date>

<b>Lieu</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_CANCELATION_BODY: `Bonjour <Host Name>,

Votre réunion <Meeting Name> a été annulée.

<b>Invité</b>
<Invitee Name> ( <Invitee Email> )

<b>Heure et Date</b>
<Meeting Time>, <Meeting Date>

<b>Lieu</b>
<Meeting Location>

<b>Raison de l'annulation</b>
<Cancel Reason>`,
});
