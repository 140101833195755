import { t } from '../../i18n/i18n';

export default {
  titleAccount: t('Account:TITLE'),
  titleProfile: t('Profile:TITLE'),
  titleMyLink: t('MyLink:TITLE'),
  titleMyRole: t('Account:MY_ROLE_TITLE'),
  titlePhone: t('Phone:TITLE'),
  titleLogin: t('Login:TITLE'),
  titleCookies: t('CookieSettings:TITLE'),
};
