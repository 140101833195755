import { ColorPickerChangeEvent } from 'primereact/colorpicker';
import { ChangeEvent } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { LookAndFeelInput } from '../../../API';
import { ColorPickerInput } from '../../common';
import labels from './labels';

type ColorsAndFontsProps = {
  lookAndFeel: LookAndFeelInput;
  handleChange: (name: string, value: string) => void;
  isReadOnly?: boolean;
};
export const ColorsAndFonts = ({ lookAndFeel, handleChange, isReadOnly }: ColorsAndFontsProps) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = (e.target.value.startsWith('#') ? '' : '#') + e.target.value;
    handleChange(e.target.name.split('_')[0], newValue);
  };

  const handleColorPickerChange = (e: ColorPickerChangeEvent) => {
    handleChange(e.target.name, e.value ? '#' + e.value.toString() : '');
  };

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    handleChange(e.target.name, e.value);
  };

  const fontFamilies = [
    'Arial',
    'Helvetica',
    'Times New Roman',
    'Georgia',
    'Verdana',
    'Courier New',
    'Tahoma',
    'Trebuchet MS',
    'Roboto',
    'Open Sans',
    'Segoe UI',
    'Nunito', // default
  ].sort();

  return (
    <div className="grid">
      <div className="flex flex-column align-items-start col-6 md:col-4">
        <div className="text-lg font-semibold">{labels.siteColorTitle}</div>
        <ColorPickerInput
          label={labels.primaryLabel}
          name="siteColorPrimary"
          value={lookAndFeel.siteColorPrimary || ''}
          handleInputChange={handleInputChange}
          handleColorPickerChange={handleColorPickerChange}
          disabled={isReadOnly}
        />
        <ColorPickerInput
          label={labels.buttonsLabel}
          name="siteColorButtons"
          value={lookAndFeel.siteColorButtons || ''}
          handleInputChange={handleInputChange}
          handleColorPickerChange={handleColorPickerChange}
          disabled={isReadOnly}
        />
        <ColorPickerInput
          label={labels.backgroundLabel}
          name="siteColorBackground"
          value={lookAndFeel.siteColorBackground || ''}
          handleInputChange={handleInputChange}
          handleColorPickerChange={handleColorPickerChange}
          disabled={isReadOnly}
        />
      </div>

      <div className="flex flex-column align-items-start col-6 md:col-4">
        <div className="text-lg font-semibold">{labels.fontColorsTitle}</div>
        <ColorPickerInput
          label={labels.headerLabel}
          name="fontColorHeader"
          value={lookAndFeel.fontColorHeader || ''}
          handleInputChange={handleInputChange}
          handleColorPickerChange={handleColorPickerChange}
          disabled={isReadOnly}
        />
        <ColorPickerInput
          label={labels.subHeaderLabel}
          name="fontColorSubHeader"
          value={lookAndFeel.fontColorSubHeader || ''}
          handleInputChange={handleInputChange}
          handleColorPickerChange={handleColorPickerChange}
          disabled={isReadOnly}
        />
        <ColorPickerInput
          label={labels.mainTextLabel}
          name="fontColorMainText"
          value={lookAndFeel.fontColorMainText || ''}
          handleInputChange={handleInputChange}
          handleColorPickerChange={handleColorPickerChange}
          disabled={isReadOnly}
        />
      </div>

      <div className="flex flex-column align-items-start col-12 md:col-4">
        <div className="text-lg font-semibold">{labels.fontFamiliesTitle}</div>
        <div className="pt-2">{labels.headerFontLabel}</div>
        <Dropdown
          name="fontHeader"
          value={lookAndFeel.fontHeader}
          className="w-full"
          options={fontFamilies}
          onChange={(e) => handleDropdownChange(e)}
          disabled={isReadOnly}
        />
        <div className="pt-2">{labels.subHeaderFontLabel}</div>
        <Dropdown
          name="fontSubheader"
          value={lookAndFeel.fontSubheader}
          className="w-full"
          options={fontFamilies}
          onChange={(e) => handleDropdownChange(e)}
          disabled={isReadOnly}
        />
        <div className="pt-2">{labels.mainTextFontLabel}</div>
        <Dropdown
          name="fontMainText"
          value={lookAndFeel.fontMainText}
          className="w-full"
          options={fontFamilies}
          onChange={(e) => handleDropdownChange(e)}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );
};
