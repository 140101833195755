export const DefaultBookingPage = Object.freeze({
  NAME: `30 Minute Meeting`,
  BOOK_A_MEETING: `Book a Meeting`,
  MEETING_DETAILS: `Meeting Details`,
  SELECT_DATE: `Select a Date`,
  SELECT_TIME: `Select a Time`,
  YOUR_INFORMATION: `Your Information`,
  MORE_OPTIONS: `More Options`,
  CANCEL: `Cancel`,
  BOOK_MEETING_BUTTON: `Book Meeting`,
  BOOK_ANOTHER_MEETING_BUTTON: `Book Another Meeting`,
  CANCEL_MEETING_BUTTON: `Cancel Meeting`,
  SAVE_MEETING_BUTTON: `Reschedule`,
  MEETING_BOOKED_TITLE: `Meeting Booked!`,
  MEETING_BOOKED_DESCRIPTION: `An email confirmation has been sent to your inbox.`,
  MEETING_RESCHEDULED_TITLE: `Meeting Rescheduled!`,
  MEETING_RESCHEDULED_DESCRIPTION: `An email confirmation has been sent to your inbox.`,
  MEETING_CANCELED_TITLE: `Meeting Canceled!`,
  MEETING_CANCELED_DESCRIPTION: `An email confirmation has been sent to your inbox.`,
});

export const BookingPagesEditBookingPageBufferTimeSettings = Object.freeze({
  AFTER_CHECKBOX_LABEL: `After event`,
  BEFORE_CHECKBOX_LABEL: `Before event`,
  DESCRIPTION: `Set before and after buffer times`,
  EVENT_TIME: `Event time`,
  TITLE: `Automatically block calendar time before or after this booking template?`,
});

export const BookingPagesEditBookingPageDateRangeSettings = Object.freeze({
  INVITEES_CAN_SCHEDULE: `Invitees can schedule ...`,
  INVITEES_CAN_NOT_SCHEDULE: `Invitees can not schedule less than...`,
  RADIO_GROUP_DAYS_IN_FUTURE_OPTION: `Days into the future`,
  RADIO_GROUP_SPECIFIC_DATES_OPTION: `Within a date range`,
  RADIO_GROUP_UNLIMITED_OPTION: `Indefinitely into the future`,
});

export const BookingPagesEditBookingPageDurationSettings = Object.freeze({
  DURATION: `Duration`,
  DURATION_TOOLTIP: `Set how long your meeting will be. Not to exceed 12 hours.`,
});

export const BookingPagesEditBookingPageWhatStep = Object.freeze({
  EVENT_COLOR_INPUT_LABEL: `Color`,
  EVENT_DESCRIPTION_INPUT_LABEL: `Description/Instructions`,
  EVENT_DESCRIPTION_INPUT_PLACEHOLDER: `Write a summary and any details your invitee should know about the event.`,
  EVENT_NAME_INPUT_LABEL: `Name`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Clone booking page request fail`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Booking page successfully cloned`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Delete booking pages request fail`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Booking page successfully deleted`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Could not retrieve booking page, it could be deleted`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Get booking pages request fail`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Save booking page request fail`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Booking page successfully saved`,
});

export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `New Booking Page`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Are you sure you want to delete this booking page?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Are you sure you want to delete these booking pages?`,
  TITLE: `Booking Pages`,
  ADD_TO_SITE: `Add to Website`,
  CLONE_MODAL_TITLE: `Clone Booking Page`,
  DEFAULT_FILTER: `My Booking Pages`,
  SHARED_LABEL: `Shared`,
  CLONE_NOTIFICATION: `Please be aware that the "Admin Only" property is switched off by default for a cloned record.`,
  TOOLTIP: `Create booking links to meet with you and/or other users`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE_PART_1: `What`,
  WHAT_TITLE_PART_2: `is this Booking Page for?`,
  WHO_TITLE_PART_1: `Who`,
  WHO_TITLE_PART_2: ` can host?`,
  HOW_TITLE_PART_1: `How`,
  HOW_TITLE_PART_2: `should it look?`,
  HOW_DESCRIPTION: `Branding, Labels`,
  ADDITIONAL_OPTIONS: `Additional Options`,
  CALENDAR_TITLE_PART_1: `Calendar`,
  CALENDAR_TITLE_PART_2: `Settings`,
  PAGE_TITLE_PART_1: `Page`,
  PAGE_TITLE_PART_2: `Settings`,
  BOOKING_TEMPLATE_TITLE: `More Booking Page Settings?`,
  BOOKING_TEMPLATE_PART_1: `Every `,
  BOOKING_TEMPLATE_PART_2: `"Booking Template"`,
  BOOKING_TEMPLATE_PART_3: ` contains settings which impact associated Booking Pages. For example, each "Confirmation Page" may be unique to the booking template, therefore, these settings are found on the "Booking Template".`,
  VIEW_BOOKING_PAGE: `View Booking Page`,
  COPY_LINK: `Copy Link`,
  ADD_TO_WEBSITE: `Add to Website`,
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Record Locked. These settings are from the booking template titled: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Click here to Override these settings`,
  MAIN_HOST: `Main Host`,
  CO_HOST: `Co-Host`,
  HOST_NOTIFICATION: `The Main Host does not have location options connected yet. This Booking Page will not work until he does.`,
  LOCKED_ADMIN_ONLY: `Only an Admin may modify these settings. Last Modified by:`,
  LOCKED_NO_PERMISSIONS: `You don't have permissions to edit this Booking Page. Last Modified by:`,
  BACK_TO_LIST: `Back to list`,
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Booking Page Name`,
  NAME_PLACEHOLDER: `Internal Name for this Booking Page`,
  NAME_TOOLTIP: `This text will be displayed to the meeting attendees on their calendar and in emails. For example "30min Meeting" or "Dental Exam".`,
  ACTIVE: `Active`,
  INACTIVE: `Inactive`,
  BOOKING_TEMPLATE: `Booking Template`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Search & Add Booking Template`,
  MEETING_NAME_LABEL: `Meeting Name`,
  CUSTOM_NAME_LABEL: `Display Name`,
  CUSTOM_NAME_PLACEHOLDER: `Custom Name for this Booking Page`,
  BOOKING_PAGE: `Booking Page`,
  ADMIN_ONLY: `Admin Only`,
  ADMIN_ONLY_PLACEHOLDER: `Only an Admin may modify these settings`,
});

export const BookingPageHowStep = Object.freeze({
  PREVIEW_DESCRIPTION: `You may view the current look & feel of this booking page and override labels here.`,
  PREVIEW_BUTTON: `View & Override Labels`,
  PREVIEW_TITLE: `Override Labels`,
  BRANDING_DESCRIPTION: `You may override branding settings here.`,
  BRANDING_BUTTON: `Override Branding Settings`,
  BRANDING_TITLE: `Override Branding Settings`,
});

export const BookingPageBranding = Object.freeze({
  DESCRIPTION: `By default this booking page will use the branding settings set for this workspace. However, you may override the settings for this specific booking page below.`,
  UPLOAD_LOGO: `Upload your logo`,
  UPLOAD_LOGO_BUTTON: `Upload logo`,
  UPLOAD_BACKGROUND: `Upload your background`,
  UPLOAD_BACKGROUND_BUTTON: `Upload background`,
  OPACITY: `Opacity`,
  CSS: `CSS`,
  CSS_PLACEHOLDER: `Custom css may be added here for overrides`,
  FOOTER_TEXT: `Footer text`,
  SAVE: `Save`,
});

export const BookingPageCalendarStep = Object.freeze({
  TIME_FORMAT: `Time Format`,
  HOUR_12: `12 Hour`,
  HOUR_24: `24 Hour`,
  CALENDAR_FORMAT: `Calendar Format`,
  MONTH_VIEW: `Month View`,
  WEEK_VIEW: `Week View`,
  START_TIME_INTERVALS: `Start Time Intervals`,
  START_TIME_INTERVALS_DESCRIPTION: `Show available time slots in increments of...`,
  START_TIME_INTERVALS_TOOLTIP: `Choose the duration of intervals you wish to show when scheduling an appointment. By default, we have 15 minutes selected to maximize slot availability. This option will display available slots by this interval.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Top of the Interval`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Enable "Top of the Interval" if you require all appointments to always start at the top of the interval regardless of Booking Template duration. For example, slot durations would be available at 8:00am, 9:00am, 10:00am, etc. NOTE: Enabling this feature will not allow user to maximize availability.`,
  TIME_ZONES: `Time Zones`,
  TIME_ZONES_TOOLTIP: `The following Time Zones will be available on the customer facing Booking Page. If you only choose one time zone, then it will be fixed, so the time zone field will be grayed out and the user will be unable to change it.`,
  TIME_ZONE_DETECT: `Automatically detect and display time slots in the invitee's time zone.`,
  TIME_ZONE_DISPLAY: `Only display the following time-zones (ideal for in-person events)`,
  DEFAULT_TIME_ZONE: `Default Time Zone`,
});

export const BookingPageSettingsStep = Object.freeze({
  INVITE_OTHERS_LABEL: `Let Invitees Invite Others`,
  INVITE_OTHERS_TOOLTIP: `Enabling this feature will display a "Invite Others" field on the Booking Page letting the invitee add other people to the participant list by entering in email addresses separated by commas.`,
  INVITE_OTHERS_SHORT: `Invite others`,
  ENTER_NOTE_LABEL: `Let Invitees enter a note for all invitees`,
  ENTER_NOTE_TOOLTIP: `Enabling this feature will display a "Note for Attendees" field, letting the invitee enter in a note to be displayed on the booked calendar appointment for all participants to view.`,
  ENTER_NOTE_SHORT: `Enter a note`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Bottom Left`,
  BOTTOM_RIGHT: `Bottom Right`,
  BUTTON_POPUP_TITLE: `Button to Popup`,
  COPY_CODE_EMBED_DESCRIPTION: `Embed your SUMO widget into your website by pasting the HTML code at the desired location.`,
  COPY_CODE_LINK_DESCRIPTION: `Display a floating booking button on the pages you desire by placing this code in your HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Display your booking link anywhere by placing this HTML code in your pages, email signatures, and more.`,
  COPY_CODE_TITLE: `Copy Code`,
  CUSTOMIZE_DESCRIPTION: `Customize the look to fit seamlessly into your website.`,
  CUSTOMIZE_TITLE: `Customize`,
  EMBED_DESCRIPTION: `Display your SUMO1 booking page in an iFrame on your website.`,
  EMBED_TITLE: `Embed`,
  FLOATING_BUTTON_DESCRIPTION: `Make button floating on page`,
  HIDE_PAGE_DETAILS: `Hide Page Details`,
  HIDE_COOKIE_BANNER: `Hide Cookie Banner`,
  LABEL: `Label`,
  LINK_DESCRIPTION: `Add a text link to your site that will launch your SUMO1 booking page in a popup.`,
  LINK_TITLE: `Text Link`,
  LOCATION: `Location`,
  POPUP_DESCRIPTION: `Display your SUMO1 booking page in a popup when a button is clicked.`,
  POPUP_TITLE: `Popup`,
  TITLE: `How will you add SUMO to your website?`,
  TOP_LEFT: `Top Left`,
  TOP_RIGHT: `Top Right`,
});

export const EditBookingPageWhereStep = Object.freeze({
  HOSTS_PHONE_NOTIFICATION: `Selected Host does not have Phone Number`,
  VIDEO_CONFERENCE_NOTIFICATION: `Selected Host does not have any Video Conference`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirm Override`,
  DESCRIPTION: `When overriding the settings of a Booking Template, you are technically no longer using this Booking Template,
  and are instead creating a Custom Booking Page, which requires a name.`,
});
