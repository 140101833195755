export const PublicBookingPage = Object.freeze({
  HOST: `Anfitrión:`,
  HOST_PHONE: `Teléfono anfitrión`,
  EDIT_BUTTON: `Editar`,
  MORE: `Más`,
  TIME_ZONE_LABEL: `Zona horaria`,
  NAME_FIELD_LABEL: `Nombre`,
  EMAIL_FIELD_LABEL: `Correo electrónico`,
  INVITE_OTHERS_FIELD_LABEL: `Invitar a otros`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Invita a otros ingresando sus direcciones de correo electrónico separadas por comas`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Nota para los asistentes`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Ingresa una nota para todos los asistentes`,
  NO_USERS_AVAILABLE_LABEL: `No hay usuarios disponibles a la hora seleccionada. Por favor, seleccione otra hora.`,
  CANCEL_TYPE_LABEL: `¿Por qué cancelar?`,
  CANCEL_REASON_LABEL: `Motivo de cancelación:`,
  IN_PERSON_LABEL: `En persona`,
  PHONE_LABEL: `Teléfono`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Error`,
  VIDEO_CONFERENCE_LABEL: `Videoconferencia`,
  SLOTS_NO_FOUND: `Ranuras no encontradas`,
  VIEW_NEXT_MONTH: `Ver el próximo mes`,
  ALLOW_SMS: `¿Recibir recordatorios de citas por mensaje de texto?`,
});

export const ScheduledMeetings = Object.freeze({
  ATTENDEES: `Gastgeber`,
  CANCELED: 'Cancelado',
  CANCEL_MEETING_TITLE: `Cancelar reunión`,
  CANCEL_MEETING_DESCRIPTION: `¿Por qué cancelar?`,
  CANCEL_REASON: `Motivo de cancelación:`,
  DETAILS: `Detalles`,
  BOOKED: `Reservado`,
  BOOKING_PAGE: `Página de reserva`,
  BOOKING_PAGE_PLACEHOLDER: `Seleccionar página de reserva`,
  EDIT_ATTENDEE_LIMIT: `Editar límite de asistentes`,
  GOOGLE_MEET: `Google Meet`,
  HOSTS: `Anfitriones`,
  IN_PERSON: `En persona`,
  INVITEE_ANSWERS: `Respuestas de los invitados`,
  LOCATION_TITLE: `Ubicación`,
  NO_SCHEDULED_MEETINGS: `No tienes reuniones reservadas`,
  NO_LOCATION_GIVEN: `No se ha proporcionado ninguna ubicación`,
  NO_SHOW_LABEL: `No presentado`,
  MARK_AS_NO_SHOW: `Marcar como No presentado`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `¿Estás seguro de que deseas marcar`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `como No presentado?`,
  MARK_AS_NO_SHOW_TOOLTIP: `La reunión aún no ha comenzado.`,
  MEETING_NOTES: `Notas Internas de la Reunión`,
  MEETING_NOTES_DESC: `Solo el anfitrión verá estas notas.`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FOR_ATTENDEES: `Nota para los asistentes`,
  PAST: `Pasado`,
  PHONE_CALL: `Llamada telefónica`,
  RESCHEDULE: `Reprogramar`,
  REASON_UNEXPECTED: `Conflicto inesperado`,
  REASON_INVITE: `Necesidad de invitar a otros`,
  REASON_TIME: `Necesidad de tiempo para prepararse`,
  REASON_OTHER: `Otro motivo`,
  STATUS: `Estado`,
  STATUS_PLACEHOLDER: `Seleccionar estado`,
  TEAM: `Equipo`,
  TITLE: `Reuniones reservadas`,
  VIEW_ATTENDEES: `Ver asistentes`,
  VIEW_ATTENDEES_DESC: `Estos son los asistentes actuales para esta reunión`,
  VIEW_ATTENDEES_NOTE: `El anfitrión puede agregar o quitar asistentes de su calendario conectado.`,
  UNDO_NO_SHOW: `Deshacer No presentado`,
  UPCOMING: `Próximos`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Videoconferencia`,
});

export const ScheduledMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Error al obtener la solicitud de eventos programados`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Error en la solicitud de cancelación de evento`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Solicitud de cancelación de evento exitosa`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Error en la solicitud de actualización de evento`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Solicitud de eliminación de evento exitosa`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Error en la solicitud de eliminación de evento`,
});

export const HostNotificationTemplates = Object.freeze({
  HOST_CONFIRMATION_TEMPLATE_SUBJECT: `Nueva reunión: <Meeting Name> con <Invitee Name> a las <Meeting Time>, <Meeting Date>`,
  HOST_RESCHEDULE_SUBJECT: `Reprogramado: <Meeting Name> con <Invitee Name>`,
  HOST_CANCELATION_SUBJECT: `Cancelado: <Meeting Name> con <Invitee Name> a las <Meeting Time>, <Meeting Date>`,
  HOST_CONFIRMATION_TEMPLATE_BODY: `Hola <Host Name>,

Su nueva reunión <Meeting Name> está programada.

<b>Invitado</b>
<Invitee Name> ( <Invitee Email> )

<b>Hora y Fecha</b>
<Meeting Time>, <Meeting Date>

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_RESCHEDULE_BODY: `Hola <Host Name>,

Su reunión <Meeting Name> ha sido reprogramada.

<b>Invitado</b>
<Invitee Name> ( <Invitee Email> )

<b>Hora y Fecha actualizadas</b>
<Meeting Time>, <Meeting Date>

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_CANCELATION_BODY: `Hola <Host Name>,

Su reunión <Meeting Name> ha sido cancelada.

<b>Invitado</b>
<Invitee Name> ( <Invitee Email> )

<b>Hora y Fecha</b>
<Meeting Time>, <Meeting Date>

<b>Ubicación</b>
<Meeting Location>

<b>Motivo de la cancelación</b>
<Cancel Reason>`,
});
