import { all, fork } from 'redux-saga/effects';
import { watchAccountsSaga } from './opsConsole/accounts/sagas';
import { watchAdminSaga } from './users/sagas';
import { watchAuthenticationSaga } from './authentification/sagas';
import { watchBillingSaga } from './billing/sagas';
import { watchBookingPagesSaga } from './bookingPages/sagas';
import { watchEventSaga } from './publicBookingPage/sagas';
import { watchBookingTemplatesSaga } from './bookingTemplates/sagas';
import { watchQuickSetupSaga } from './quickSetup/sagas';
import { watchScheduledMeetingsSaga } from './scheduledMeetings/sagas';
import { watchUserAvailabilitySaga } from './availability/sagas';
import { watchUserSettingsSaga } from './userSettings/sagas';
import { watchWorkspacesSaga } from './workspaces/sagas';
import { watchGlobalSaga } from './global/sagas';
import { watchRolesSaga } from './roles/sagas';
import { watchOrgsSaga } from './opsConsole/orgs/sagas';
import { watchUsersSaga } from './opsConsole/users/sagas';
import { watchStaffSaga } from './opsConsole/staff/sagas';
import { watchTeamsSaga } from './teams/sagas';

export default function* rootSaga() {
  try {
    yield all([
      fork(watchAccountsSaga),
      fork(watchAdminSaga),
      fork(watchAuthenticationSaga),
      fork(watchBillingSaga),
      fork(watchBookingPagesSaga),
      fork(watchEventSaga),
      fork(watchBookingTemplatesSaga),
      fork(watchQuickSetupSaga),
      fork(watchScheduledMeetingsSaga),
      fork(watchUserAvailabilitySaga),
      fork(watchUserSettingsSaga),
      fork(watchWorkspacesSaga),
      fork(watchGlobalSaga),
      fork(watchRolesSaga),
      fork(watchOrgsSaga),
      fork(watchUsersSaga),
      fork(watchStaffSaga),
      fork(watchTeamsSaga),
    ]);
  } catch (error: unknown) {
    // TODO: probably create root settings module and handle errors there
    console.error(error);
  }
}
