import { API } from 'aws-amplify';
import { UpdateWorkspaceList } from './types';
import { CreateTenantInput } from '../../API';
import { APIPostRequest } from '../utils/reduxUtils';

export async function getTenantInfo(tenantId: string) {
  try {
    return (await APIPostRequest('/api/js/getTenantByTenantId', {
      tenantId,
    })) as CreateTenantInput;
  } catch (error) {
    console.error('Redux quick setup; Error getTenantInfo func:', error);
    throw error;
  }
}

export async function updateWorkspaceList(input: UpdateWorkspaceList) {
  try {
    return await APIPostRequest('/api/js/updateTenant', input);
  } catch (error) {
    console.error('Redux quick setup service; Error updateWorkspaceList func:', error);
    throw error;
  }
}
