import { Dialog } from 'primereact/dialog';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_ROLE_MODAL_NAME, editRoleModalActions, editRoleModalSelectors } from '../../../store/roles/modal';
import { modalsActions } from '../../../store/modals';
import { Button } from 'primereact/button';
import labels from './labels';
import { rolesActions, rolesSelectors } from '../../../store/roles';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { InputSwitch } from 'primereact/inputswitch';
import { RolePermissions } from '../rolePermissions/RolePermissions';

export const EditRoleModal = () => {
  const dispatch = useDispatch();
  const open = useSelector(editRoleModalSelectors.selectIsModalOpen);
  const selectedRole = useSelector(rolesSelectors.selectSelectedRole);
  const [localRoleName, setLocalRoleName] = useState(selectedRole.name);

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: EDIT_ROLE_MODAL_NAME, options: {} }));
    return () => {
      dispatch(modalsActions.deleteModal(EDIT_ROLE_MODAL_NAME));
    };
  }, []);

  useEffect(() => {
    setLocalRoleName(selectedRole.name);
  }, [selectedRole]);

  const handleClose = () => {
    dispatch(editRoleModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(rolesActions.saveRoleRequest());
  };

  const handleChange = (name: string, value: boolean) => {
    dispatch(rolesActions.updateSelectedRole({ [name]: value }));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalRoleName(e.target.value);
  };

  const handleOnBlurName = () => {
    dispatch(rolesActions.updateSelectedRole({ name: localRoleName }));
  };

  const confirmFooter = () => {
    return (
      <div className="flex justify-content-end gap-3">
        <Button label={labels.cancel} outlined className="w-6rem" onClick={handleClose} />
        <Button label={labels.save} className="w-8rem" onClick={handleSave} />
      </div>
    );
  };

  return (
    <Dialog
      header={selectedRole.id ? labels.editTitle : labels.newTitle}
      headerClassName="text-center text-900"
      footer={confirmFooter}
      visible={open}
      onHide={handleClose}
      className="w-11 md:w-9 lg:w-6"
      focusOnShow={false}
    >
      <div className="surface-50 text-sm p-2 uppercase">{labels.primaryTitle}</div>
      <div className="p-fluid grid p-2">
        <div className="flex flex-column col-12 md:col-6">
          <label htmlFor="name">{labels.roleName}</label>
          <InputText
            id="name"
            name="name"
            value={localRoleName}
            onChange={handleNameChange}
            onBlur={handleOnBlurName}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="flex flex-column col-12 md:col-6">
          <label>{labels.active}</label>
          <InputSwitch
            className="mt-2"
            checked={selectedRole.isActive}
            name="isActive"
            onChange={(e) => handleChange(e.target.name, !!e.target.value)}
          />
        </div>
      </div>
      <RolePermissions role={selectedRole} />
    </Dialog>
  );
};
