import { useSelector } from 'react-redux';
import { IntegrationDataType, VIDEO_CONFERENCES, integrationActions } from '../../../store/integration';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { useDispatch } from 'react-redux';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { Checkbox } from 'primereact/checkbox';
import { generateVideoConference } from '../../../store/integration/utils';

type IntegrationCardProps = {
  integration: IntegrationDataType;
  containerClassName?: string;
};
export const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration, containerClassName }) => {
  const dispatch = useDispatch();
  const isConnected = useSelector(userSettingsSelectors.selectIsIntegrationConnected(integration.type));
  const defaultVideoConference = useSelector(userSettingsSelectors.selectDefaultVideoIntegration);

  const isVideoIntegration = VIDEO_CONFERENCES.includes(integration.type);
  const videoConferenceType = isVideoIntegration ? generateVideoConference(integration.type) : null;
  const isDefaultVideoConference = Boolean(
    isVideoIntegration && defaultVideoConference && defaultVideoConference === videoConferenceType
  );

  const handleConnect = () => {
    dispatch(integrationActions.setConnectIntegrationType(integration.type));
    navigationService.navigateTo(Path.ConnectIntegration);
  };

  const handleDefaultClick = () => {
    if (isDefaultVideoConference) {
      return;
    }
    dispatch(userSettingsActions.updateUserSettings({ defaultVideoIntegration: videoConferenceType }));
    dispatch(userSettingsActions.saveUserSettingsRequest());
  };

  return (
    <div className={containerClassName}>
      <div
        className="h-12rem md:h-14rem p-3 flex flex-column justify-content-between border-1 border-100 shadow-1 cursor-pointer hover:bg-gray-50"
        onClick={handleConnect}
      >
        <div>
          <div className="font-bold text-xl">{integration.label}</div>
          <div className="flex mt-2">
            <img src={integration.icon} className="align-self-baseline mr-3 mt-1 w-4rem" />
            <div>{integration.description}</div>
          </div>
        </div>
        {isConnected && (
          <div className="w-12 mt-2 flex justify-content-between align-items-center">
            <div className="font-semibold text-0 w-max py-1 px-3 border-round-3xl bg-green-500">{labels.connected}</div>
            {isVideoIntegration && (
              <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  inputId={'default' + integration.type}
                  checked={isDefaultVideoConference}
                  onChange={handleDefaultClick}
                />
                <label htmlFor={'default' + integration.type} className="cursor-pointer hover:text-primary ml-2">
                  {labels.default}
                </label>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
