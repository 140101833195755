import { t } from '../../../../i18n/i18n';

export default {
  addAnotherReminder: t('EditBookingTemplateNotificationsStep:ADD_ANOTHER_REMINDER'),
  cancelationDescription: t('EditBookingTemplateNotificationsStep:CANCELATION_DESCRIPTION'),
  cancelationTitle: t('EditBookingTemplateNotificationsStep:CANCELATION_TITLE'),
  confirmationDescription: t('EditBookingTemplateNotificationsStep:CONFIRMATION_DESCRIPTION'),
  confirmationTitle: t('EditBookingTemplateNotificationsStep:CONFIRMATION_TITLE'),
  emailBody: t('EditBookingTemplateNotificationsStep:EMAIL_BODY'),
  emailSubject: t('EditBookingTemplateNotificationsStep:EMAIL_SUBJECT'),
  followUpDescription: t('EditBookingTemplateNotificationsStep:FOLLOW_UP_DESCRIPTION'),
  followUpTitle: t('EditBookingTemplateNotificationsStep:FOLLOW_UP_TITLE'),
  mergeVariables: t('EditBookingTemplateNotificationsStep:MERGE_VARIABLES'),
  personalize: t('EditBookingTemplateNotificationsStep:PERSONALIZE'),
  reminderDescription: t('EditBookingTemplateNotificationsStep:REMINDER_DESCRIPTION'),
  reminderTitle: t('EditBookingTemplateNotificationsStep:REMINDER_TITLE'),
  rescheduleDescription: t('EditBookingTemplateNotificationsStep:RESCHEDULE_DESCRIPTION'),
  rescheduleTitle: t('EditBookingTemplateNotificationsStep:RESCHEDULE_TITLE'),
  reset: t('EditBookingTemplateNotificationsStep:RESET'),
  smsDescription: t('EditBookingTemplateNotificationsStep:SMS_DESCRIPTION'),
  smsTitle: t('EditBookingTemplateNotificationsStep:SMS_TITLE'),
  smsBody: t('EditBookingTemplateNotificationsStep:SMS_BODY'),
  timing: t('EditBookingTemplateNotificationsStep:TIMING'),
};
