import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from '../../common';
import {
  PublicBookingPageBookedInfo,
  PublicBookingPageBookedMessage,
  PublicBookingPageButtons,
  PublicBookingPageCancel,
  PublicBookingPageDateTime,
  PublicBookingPageError,
  PublicBookingPageHeader,
  PublicBookingPageMeetingDetails,
  PublicBookingPageYourInformation,
} from '..';
import { eventSelectors, eventActions } from '../../../store/publicBookingPage';
import { UpdateBookingPageInput, UpdateUserEventInput } from '../../../API';
import { timeHandlerService } from '../../../services/TimeHandlerService';
import { Button } from 'primereact/button';
// import labels from './labels';

interface IPublicBookingPageContainer {
  previewBookingPage?: UpdateBookingPageInput;
  rescheduleEvent?: UpdateUserEventInput;
  isReadOnly?: boolean;
}

export const PublicBookingPageContainer = ({
  previewBookingPage,
  rescheduleEvent,
  isReadOnly,
}: IPublicBookingPageContainer) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(eventSelectors.selectIsFetching);
  const showError = useSelector(eventSelectors.selectShowError);
  // const showBookingTemplates = useSelector(eventSelectors.selectShowBookingTemplates);
  const showDetails = useSelector(eventSelectors.selectShowDetails);
  const showDateTime = useSelector(eventSelectors.selectShowDateTime);
  const showYourInfo = useSelector(eventSelectors.selectShowYourInfo);
  const showCancel = useSelector(eventSelectors.selectShowCancel);
  const showBookedInfo = useSelector(eventSelectors.selectShowBookedInfo);
  const showActionMessage = useSelector(eventSelectors.selectShowActionMessage);
  const showFooter = useSelector(eventSelectors.selectShowFooter);
  const showBackButton = useSelector(eventSelectors.selectShowBackButton);
  const showNextButton = useSelector(eventSelectors.selectShowNextButton);
  const style = useSelector(eventSelectors.selectBookingPageStyle);
  const isHideHeader = useSelector(eventSelectors.selectIsHideHeader);
  // const isHideCookie = useSelector(eventSelectors.selectIsHideCookie);
  const isIframe = useSelector(eventSelectors.selectIsIframe);
  const step = useSelector(eventSelectors.selectStep);

  const containerStyle = isIframe ? {} : { maxWidth: '800px' };
  const contentClassName = `py-3 flex-1 ${
    isIframe ? 'px-3 md:px-4' : 'px-4 md:py-4 md:flex-none md:border-round-bottom-xl'
  }`;

  useEffect(() => {
    if (rescheduleEvent) {
      dispatch(eventActions.setRescheduleData({ event: rescheduleEvent, bookingPage: previewBookingPage }));
      dispatch(eventActions.getAgendaRequest());
    } else if (previewBookingPage) {
      dispatch(eventActions.setPreviewData({ bookingPage: previewBookingPage }));
      dispatch(eventActions.setReadOnly(!!isReadOnly));
    }

    // define isMobile based on initial window width, 768px corresponds to md: breakpoint
    if (window.innerWidth < 768) {
      dispatch(eventActions.setIsMobile());
    }

    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleBack = () => {
    dispatch(eventActions.setPreviousStep());
  };

  const handleNext = () => {
    dispatch(eventActions.setNextStep());
  };

  // const handleCookieBanner = () => {
  //   dispatch(authentificationActions.setCookieOpen(true));
  // };

  return (
    <div className="w-full flex flex-column" style={containerStyle}>
      <style dangerouslySetInnerHTML={{ __html: style.css || '' }} />

      {isFetching && <Preloader />}

      {isHideHeader ? (
        <>
          {showBackButton && (
            <div className="fixed left-0">
              <Button
                className="h-3rem border-noround-left"
                style={{ width: '1.5rem' }}
                icon="pi pi-angle-left"
                text
                onClick={handleBack}
              />
            </div>
          )}
          {showNextButton && (
            <div className="fixed right-0">
              <Button
                className="h-3rem border-noround-right"
                style={{ width: '1.5rem' }}
                icon="pi pi-angle-right"
                text
                onClick={handleNext}
              />
            </div>
          )}
        </>
      ) : (
        <PublicBookingPageHeader />
      )}

      <div className={contentClassName} style={{ backgroundColor: `rgba(255,255,255,${Number(style.opacity) / 100})` }}>
        {showError && <PublicBookingPageError />}

        {showActionMessage && <PublicBookingPageBookedMessage />}

        {/*{showBookingTemplates && <PublicBookingPageBookingTemplates />}*/}

        {showDetails && <PublicBookingPageMeetingDetails />}

        {showDateTime && <PublicBookingPageDateTime />}

        {showYourInfo && <PublicBookingPageYourInformation />}

        {showCancel && <PublicBookingPageCancel />}

        <PublicBookingPageButtons />

        {showBookedInfo && <PublicBookingPageBookedInfo />}

        {/* {!isHideCookie && (
          <Button text link onClick={handleCookieBanner} label={labels.cookieTitle} className="mt-3 p-0 ml-1" />
        )} */}
      </div>

      {showFooter && (
        <div
          className="hidden md:block mt-auto pt-2 text-center"
          dangerouslySetInnerHTML={{ __html: style.footer || '' }}
        />
      )}
    </div>
  );
};

PublicBookingPageContainer.displayName = 'PublicBookingPageContainer';
