import { BookingPageLabelsInput, LookAndFeelInput, WorkspaceInput } from '../../API';
import { PhoneCallsStep, QuickSetupUploadImageRequestPayload } from './types';
import { UserDataInputCreatedAt } from '../global/types';
import { ImageType } from '../../types/types';

export enum QuickSetupActionTypes {
  GET_DEFAULT_DATA_SUCCESS = 'quickSetup/GET_DEFAULT_DATA_SUCCESS',
  GET_DEFAULT_DATA_FAIL = 'quickSetup/GET_DEFAULT_DATA_FAIL',
  CHECK_USER_LINK_REQUEST = 'quickSetup/CHECK_USER_LINK_REQUEST',
  CHECK_USER_LINK_SUCCESS = 'quickSetup/CHECK_USER_LINK_SUCCESS',
  CHECK_USER_LINK_FAIL = 'quickSetup/CHECK_USER_LINK_FAIL',
  SAVE_REQUEST = 'quickSetup/SAVE_REQUEST',
  SAVE_SUCCESS = 'quickSetup/SAVE_SUCCESS',
  SAVE_FAIL = 'quickSetup/SAVE_FAIL',
  SET_USER_LINK_STATUS = 'quickSetup/SET_USER_LINK_STATUS',
  SET_USER_AVAILABILITY = 'quickSetup/SET_USER_AVAILABILITY',
  SET_DEFAULT_WORKSPACE = 'quickSetup/SET_DEFAULT_WORKSPACE',
  UPDATE_WORKSPACE_LOOK_AND_FEEL = 'quickSetup/UPDATE_WORKSPACE_LOOK_AND_FEEL',
  UPDATE_WORKSPACE_LABELS = 'quickSetup/UPDATE_WORKSPACE_LABELS',
  SAVE_QUICK_SETUP_REQUEST = 'quickSetup/SAVE_QUICK_SETUP_REQUEST',
  SAVE_QUICK_SETUP_SUCCESS = 'quickSetup/SAVE_QUICK_SETUP_SUCCESS',
  SAVE_QUICK_SETUP_FAIL = 'quickSetup/SAVE_QUICK_SETUP_FAIL',
  UPLOAD_IMAGE_FILE = 'quickSetup/UPLOAD_IMAGE_FILE',
  CLEAR_IMAGE_FILE = 'quickSetup/CLEAR_IMAGE_FILE',
  SET_ACCEPT_TERMS = 'quickSetup/SET_ACCEPT_TERMS',
  UPDATE_PHONE_CALLS = 'quickSetup/UPDATE_PHONE_CALLS',
}

export type QuickSetupAction =
  | { type: QuickSetupActionTypes.GET_DEFAULT_DATA_SUCCESS; payload: UserDataInputCreatedAt }
  | { type: QuickSetupActionTypes.GET_DEFAULT_DATA_FAIL; error: unknown }
  | { type: QuickSetupActionTypes.SET_USER_AVAILABILITY; payload: UserDataInputCreatedAt }
  | { type: QuickSetupActionTypes.SET_DEFAULT_WORKSPACE; payload: WorkspaceInput }
  | { type: QuickSetupActionTypes.UPDATE_WORKSPACE_LOOK_AND_FEEL; payload: Partial<LookAndFeelInput> }
  | { type: QuickSetupActionTypes.UPDATE_WORKSPACE_LABELS; payload: BookingPageLabelsInput }
  | { type: QuickSetupActionTypes.SAVE_QUICK_SETUP_REQUEST }
  | { type: QuickSetupActionTypes.SAVE_QUICK_SETUP_SUCCESS }
  | { type: QuickSetupActionTypes.SAVE_QUICK_SETUP_FAIL; error: unknown }
  | { type: QuickSetupActionTypes.UPLOAD_IMAGE_FILE; payload: QuickSetupUploadImageRequestPayload }
  | { type: QuickSetupActionTypes.CLEAR_IMAGE_FILE; payload: ImageType }
  | { type: QuickSetupActionTypes.SET_ACCEPT_TERMS; payload: boolean }
  | { type: QuickSetupActionTypes.UPDATE_PHONE_CALLS; payload: Partial<PhoneCallsStep> };


const getDefaultDataSuccess = (payload: UserDataInputCreatedAt): QuickSetupAction => ({
  type: QuickSetupActionTypes.GET_DEFAULT_DATA_SUCCESS,
  payload,
});
const getDefaultDataFail = (error: unknown): QuickSetupAction => ({
  type: QuickSetupActionTypes.GET_DEFAULT_DATA_FAIL,
  error,
});
const setUserAvailability = (payload: UserDataInputCreatedAt): QuickSetupAction => ({
  type: QuickSetupActionTypes.SET_USER_AVAILABILITY,
  payload,
});
const setDefaultWorkspace = (payload: WorkspaceInput): QuickSetupAction => ({
  type: QuickSetupActionTypes.SET_DEFAULT_WORKSPACE,
  payload,
});

const updateWorkspaceLookAndFeel = (payload: Partial<LookAndFeelInput>): QuickSetupAction => ({
  type: QuickSetupActionTypes.UPDATE_WORKSPACE_LOOK_AND_FEEL,
  payload,
});
const updateWorkspaceLabels = (payload: BookingPageLabelsInput): QuickSetupAction => ({
  type: QuickSetupActionTypes.UPDATE_WORKSPACE_LABELS,
  payload,
});

const saveQuickSetupRequest = (): QuickSetupAction => ({ type: QuickSetupActionTypes.SAVE_QUICK_SETUP_REQUEST });
const saveQuickSetupSuccess = (): QuickSetupAction => ({ type: QuickSetupActionTypes.SAVE_QUICK_SETUP_SUCCESS });
const saveQuickSetupFail = (error: unknown): QuickSetupAction => ({
  type: QuickSetupActionTypes.SAVE_QUICK_SETUP_FAIL,
  error,
});
const uploadImageFile = (payload: QuickSetupUploadImageRequestPayload): QuickSetupAction => ({
  type: QuickSetupActionTypes.UPLOAD_IMAGE_FILE,
  payload,
});
const clearImageFile = (payload: ImageType): QuickSetupAction => ({
  type: QuickSetupActionTypes.CLEAR_IMAGE_FILE,
  payload,
});

const setIsAcceptTerms = (payload: boolean): QuickSetupAction => ({
  type: QuickSetupActionTypes.SET_ACCEPT_TERMS,
  payload,
});

const updatePhoneCalls = (payload: Partial<PhoneCallsStep>): QuickSetupAction => ({
  type: QuickSetupActionTypes.UPDATE_PHONE_CALLS,
  payload,
});

export const quickSetupActions = {
  getDefaultDataSuccess,
  getDefaultDataFail,
  setUserAvailability,
  setDefaultWorkspace,
  updateWorkspaceLookAndFeel,
  updateWorkspaceLabels,
  saveQuickSetupRequest,
  saveQuickSetupSuccess,
  saveQuickSetupFail,
  uploadImageFile,
  clearImageFile,
  setIsAcceptTerms,
  updatePhoneCalls,
};
