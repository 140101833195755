import { ObjectUtils } from 'primereact/utils';
import React from 'react'; // { useContext, useEffect, useState }
// import { LayoutContext } from './context/layoutcontext';

const AppBreadcrumb = () => {
  // const [breadcrumb, setBreadcrumb] = useState({});
  // const { breadcrumbs } = useContext(LayoutContext);
  const breadcrumb = {};

  return (
    <nav className="layout-breadcrumb">
      <ol>
        {ObjectUtils.isNotEmpty(breadcrumb)
          ? breadcrumb.labels.map((label, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && <li className="layout-breadcrumb-chevron"> / </li>}
                  <li key={index}>{label}</li>
                </React.Fragment>
              );
            })
          : null}
      </ol>
    </nav>
  );
};

export default AppBreadcrumb;
