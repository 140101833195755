import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import labels from './labels';
import { useSelector } from 'react-redux';
import { authentificationSelectors } from '../../../store/authentification';
import { CreateLicenseInput } from '../../../API';
import { Tooltip } from 'primereact/tooltip';

export const LicenseList = () => {
  const licenses = useSelector(authentificationSelectors.selectLicenses);

  const remainingFiled = (rowData: CreateLicenseInput) => {
    const owned = rowData.owned ?? 0;
    const assigned = rowData.assigned ?? 0;
    const seatsToRemove = rowData.scheduledChanges?.reduce((result, change) => result + (change?.seats || 0), 0) || 0;

    const remaining = owned - assigned - seatsToRemove;
    return <>{remaining}</>;
  };

  return (
    <>
      <DataTable value={licenses} size="small" showGridlines>
        <Column field="type" header={labels.licenseType} />
        <Column
          field="owned"
          header={labels.owned}
          body={(data: CreateLicenseInput) => {
            const seatsToRemove =
              data.scheduledChanges?.reduce((result, change) => result + (change?.seats || 0), 0) || 0;
            return (
              <div>
                {data.owned}
                {!!seatsToRemove && (
                  <span>
                    {` (-${seatsToRemove})`}
                    <Tooltip target=".removeScheduled" />
                    <i
                      className="removeScheduled pi pi-info-circle vertical-align-top text-sm"
                      data-pr-tooltip={labels.seatsRemovalScheduled}
                    ></i>
                  </span>
                )}
              </div>
            );
          }}
        />
        <Column field="assigned" header={labels.assigned} />
        <Column body={remainingFiled} header={labels.remaining} />
      </DataTable>
    </>
  );
};
