import { Checkbox } from 'primereact/checkbox';
import { Skeleton } from 'primereact/skeleton';

export const BookingPageCardSkeleton = () => {
  return (
    <div className="border-1 border-round surface-border">
      <div
        className="flex flex-column card shadow-none bg-transparent"
        style={{
          margin: '-1px',
        }}
      >
        <Checkbox checked={false} disabled />
        <div className="flex flex-row align-items-center mt-4">
          <Skeleton width="" className="w-6" height="1.5rem" />
          <Skeleton width="1.2rem" height="1.2rem" className="ml-2" />
          <Skeleton width="1.2rem" height="1.2rem" className="ml-2" />
        </div>
        <Skeleton width="" className="w-8 mt-1 mb-5" height="1.2rem" />
        <div className="flex flex-row">
          <Skeleton width="" className="w-4" height="1.85rem" />
          <Skeleton width="" className="w-6 ml-4" height="1.85rem" />
        </div>
      </div>
    </div>
  );
};
