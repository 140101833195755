import { CSSProperties } from 'react';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  InPersonType,
  LocationType,
  TimeFormat,
  TimeZoneType,
  PhoneCallType,
  ConfirmationsPageType,
  CustomFieldType,
  CustomFieldInput,
} from '../../API';
import { CURRENT_TIME_ZONE, TIME_ZONES_BY_NAME } from '../../types/constants';
import { State } from '../rootStore';
import { PREVIEW_AVAILABLE_TIMES, IN_PERSON_LABEL, PHONE_LABEL, VIDEO_CONFERENCE_LABEL } from './constants';
import { EventSteps } from './types';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { userSettingsSelectors } from '../userSettings';
import { converDateStringToCurrentTimezoneDate } from '../../services/DateService';
import { validateEmail } from '../../services/utils';
import { formatPhoneNumber, validateCustomField } from './utils';

dayjs.extend(utc);
dayjs.extend(timezone);

const eventState = (state: State) => state.event;

const selectEvent = createSelector(eventState, (state) => state.event);
const selectAgenda = createSelector(eventState, (state) => state.agenda);
const selectStep = createSelector(eventState, (state) => state.step);
const selectBookingPageId = createSelector(eventState, (state) => state.bookingPageId || '');
const selectScheduledMeetingId = createSelector(eventState, (state) => state.eventId || '');
const selectViewDate = createSelector(eventState, (state) => new Date(state.viewDate));
const selectIsRescheduleMode = createSelector(eventState, (state) => state.isRescheduleMode);
const selectIsPreviewMode = createSelector(eventState, (state) => state.isPreviewMode);
const selectIsReadOnly = createSelector(eventState, (state) => state.isReadOnly);
const selectIsHostReschedule = createSelector(eventState, (state) => state.isHostReschedule);
const selectIsMobile = createSelector(eventState, (state) => state.isMobile);
const selectIsRescheduled = createSelector(eventState, (state) => state.isRescheduled);
const selectIsFetching = createSelector(eventState, (state) => state.isFetching);
const selectError = createSelector(eventState, (state) => state.error);
const selectAgendaLoaded = createSelector(eventState, (state) => state.agendaLoaded);
const selectViewSettings = createSelector(eventState, (state) => state.viewSettings);

const selectAgendaSpots = createSelector(selectAgenda, (state) => state.spots || []);

const selectInitialHost = createSelector(selectAgenda, (state) => state.initialHost);
const selectFinalHost = createSelector(selectAgenda, (state) => state.host);
const selectInitialCohosts = createSelector(selectAgenda, (state) => state.initialCohosts);
const selectFinalCohosts = createSelector(selectAgenda, (state) => state.cohosts);
const selectHost = createSelector(
  selectStep,
  selectInitialHost,
  selectFinalHost,
  (step, initialHost, host) => ([EventSteps.BOOKED, EventSteps.CANCEL].includes(step) && host) || initialHost
);
const selectCohosts = createSelector(
  selectStep,
  selectInitialCohosts,
  selectFinalCohosts,
  (step, initialCohost, cohost) => ([EventSteps.BOOKED, EventSteps.CANCEL].includes(step) && cohost) || initialCohost
);
const selectHostName = createSelector(selectHost, (state) => state?.name);
const selectHostCountryCode = createSelector(selectHost, (state) => state?.countryCode);
const selectHostPhone = createSelector(selectHost, (state) => state?.phone);
const selectHostEmail = createSelector(selectHost, (state) => state?.email);
const selectHostDisplayName = createSelector(selectHostName, selectHostEmail, (name, email) => name || email);
const selectHostIsTeam = createSelector(selectHost, (state) => !!state?.isTeam);
const selectHostsNames = createSelector(selectHostDisplayName, selectCohosts, (name, cohosts) =>
  [name || '', ...(cohosts || []).map((cohost) => cohost?.name || cohost?.email || '')].join(', ')
);
const selectHostFullPhone = createSelector(
  selectHostCountryCode,
  selectHostPhone,
  (countryCode, phone) => phone && formatPhoneNumber(countryCode || '', phone || '')
);

const selectEventId = createSelector(selectEvent, (state) => state.eventId);
const selectIsExisting = createSelector(selectEventId, (state) => Boolean(state));
const selectEventEditKey = createSelector(selectEvent, (state) => state.editKey || null);
const selectEventDateString = createSelector(selectEvent, (state) => state.eventDate);
const selectEventDate = createSelector(selectEventDateString, (eventDate) =>
  eventDate ? converDateStringToCurrentTimezoneDate(eventDate) : new Date()
);
const selectEventTimezone = createSelector(selectEvent, (state) => state.timeZone);
const selectEventCanceled = createSelector(selectEvent, (state) => state.canceled);
const selectEventIsCanceled = createSelector(selectEventCanceled, (canceled) => canceled?.isCanceled);
const selectEventCanceledType = createSelector(selectEventCanceled, (canceled) => canceled?.type);
const selectEventCanceledNote = createSelector(selectEventCanceled, (canceled) => canceled?.note);
const selectStartTime = createSelector(selectEvent, (state) => (state.startTime ? new Date(state.startTime) : null));
const selectEventLocation = createSelector(selectEvent, (event) => event.location);
const selectEventLocationType = createSelector(selectEventLocation, (location) => location?.type);
const selectLocationSettings = createSelector(selectEventLocation, (location) => location?.settings);
const selectIsSMSAllowed = createSelector(selectEvent, (event) => Boolean(event.isSMSAllowed));
const selectInputFields = createSelector(selectEvent, (event) => event.inputFields || []);
const selectAttendeeName = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.NAME)?.value
);
const selectAttendeeEmail = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.EMAIL)?.value
);
const selectAttendeePhone = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.PHONE)?.value
);
const selectAttendeeLocation = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.LOCATION)?.value
);
const selectAttendeePhoneFormatted = createSelector(selectAttendeePhone, (phone) => `+${(phone || '').slice(3)}`);

const selectIsPastEvent = createSelector(selectStartTime, (startTime) => startTime && startTime < new Date());

// const selectBookingTemplates = createSelector(selectAgenda, (agenda) => agenda.meetingTypes);
// const selectBookingTemplate = createSelector(selectAgenda, (agenda) => agenda.meetingType);
const selectBookingPage = createSelector(selectAgenda, (agenda) => agenda.bookingPage);
const selectDateRange = createSelector(selectBookingPage, (bookingPage) => bookingPage?.when?.dateRange);
const selectIsSMSEnabled = createSelector(
  selectBookingPage,
  (bookingPage) => !!bookingPage?.notifications?.smsReminder?.enabled
);

const selectIsRedirect = createSelector(
  selectIsPreviewMode,
  selectBookingPage,
  (isPreviewMode, bookingTemplate) =>
    !isPreviewMode && bookingTemplate?.confirmationsPage?.type === ConfirmationsPageType.REDIRECT
);
const selectExternalLink = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.externalLink
);
const selectCustomLinks = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.customLinks || []
);
const selectBookAnotherEnabled = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.anotherEventEnabled
);
const selectRescheduleEnabled = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.rescheduleEnabled
);
const selectCancelEnabled = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.cancelEnabled
);

// const selectAvailableLocationTypes = createSelector(
//   selectBookingPage,
//   selectHostVideoIntegrations,
//   (bookingTemplate, hostVideoIntegrations) =>
//     calculateAvailableLocationTypes(bookingTemplate?.where?.locationTypes, hostVideoIntegrations)
// );
const selectAvailableLocationTypes = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.locationTypes || []
);

const selectDefaultLocationType = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.defaultLocationType
);
const selectLocationTypeOptions = createSelector(selectAvailableLocationTypes, (locationTypes) =>
  locationTypes?.reduce((options, locationType) => {
    switch (locationType) {
      case LocationType.IN_PERSONAL:
        return [...options, { label: IN_PERSON_LABEL, value: LocationType.IN_PERSONAL }];
      case LocationType.PHONE_CALL:
        return [...options, { label: PHONE_LABEL, value: LocationType.PHONE_CALL }];
      case LocationType.VIDEO_CONFERENCE:
        return [...options, { label: VIDEO_CONFERENCE_LABEL, value: LocationType.VIDEO_CONFERENCE }];
      default:
        return options;
    }
  }, [] as SelectItemOptionsType)
);
const selectIsLocationTypeEdit = createSelector(
  selectIsExisting,
  selectAvailableLocationTypes,
  selectStep,
  (isExisting, locationTypes, step) =>
    !isExisting && Number(locationTypes?.length) > 1 && (step === EventSteps.WHEN || step === EventSteps.INFO)
);

const selectLocations = createSelector(selectBookingPage, (bookingTemplate) => bookingTemplate?.where?.locations || []);
const selectCustomPhoneNumber = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.customPhone || ''
);
const selectCustomCountryCode = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.customCountryCode || ''
);
const selectCustomPhone = createSelector(selectCustomCountryCode, selectCustomPhoneNumber, (countryCode, phoneNumber) =>
  formatPhoneNumber(countryCode, phoneNumber)
);
const selectCustomAddress = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.customAddress
);
const selectPhoneCallType = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.phoneCallType
);
const selectInPersonType = createSelector(selectBookingPage, (bookingTemplate) => bookingTemplate?.where?.inPersonType);
const selectLocationOptions = createSelector(
  selectLocations,
  (locations) => locations.map((location) => ({ label: location, value: location })) || ([] as SelectItemOptionsType[])
);
const selectShowLocationInput = createSelector(
  selectIsExisting,
  selectEventLocationType,
  selectInPersonType,
  selectLocations,
  selectStep,
  (isExisting, locationType, inPersonType, locations, step) =>
    !isExisting &&
    locationType === LocationType.IN_PERSONAL &&
    inPersonType === InPersonType.LOCATION &&
    locations.length &&
    (step === EventSteps.WHEN || step === EventSteps.INFO)
);

const selectShowHostPhone = createSelector(
  selectEventLocationType,
  selectPhoneCallType,
  (locationType, phoneCallType) =>
    locationType === LocationType.PHONE_CALL && phoneCallType === PhoneCallType.HOST_PHONE_NUMBER
);
const selectShowCustomPhone = createSelector(
  selectEventLocationType,
  selectPhoneCallType,
  (locationType, phoneCallType) =>
    locationType === LocationType.PHONE_CALL && phoneCallType === PhoneCallType.CUSTOM_PHONE
);
const selectShowCustomAddress = createSelector(
  selectEventLocationType,
  selectInPersonType,
  (locationType, inPersonType) =>
    locationType === LocationType.IN_PERSONAL && inPersonType === InPersonType.CUSTOM_ADDRESS
);
const selectShowLocationSettings = createSelector(
  selectIsExisting,
  selectEventLocationType,
  selectPhoneCallType,
  selectInPersonType,
  selectStep,
  (isExisting, locationType, phoneCallType, inPersonType, step) =>
    ((locationType === LocationType.PHONE_CALL && phoneCallType === PhoneCallType.PROMPT_INVITE) ||
      (locationType === LocationType.IN_PERSONAL &&
        (inPersonType === InPersonType.LOCATION || inPersonType === InPersonType.INVITEE_LOCATION))) &&
    (isExisting || step === EventSteps.BOOKED || step === EventSteps.CANCEL)
);

const selectLocationType = createSelector(
  selectEventLocation,
  selectDefaultLocationType,
  (location, defaultLocationType) => location?.type || defaultLocationType
);
const selectLocationTypeLabel = createSelector(selectLocationType, (locationType) => {
  switch (locationType) {
    case LocationType.IN_PERSONAL:
      return IN_PERSON_LABEL;
    case LocationType.PHONE_CALL:
      return PHONE_LABEL;
    case LocationType.VIDEO_CONFERENCE:
      return VIDEO_CONFERENCE_LABEL;
    default:
      return IN_PERSON_LABEL;
  }
});

const selectFilteredInputFields = createSelector(
  selectEventLocationType,
  selectPhoneCallType,
  selectInputFields,
  selectIsSMSEnabled,
  selectIsSMSAllowed,
  (locationType, phoneCallType, inputFields, isSMS, isSMSAllowed) =>
    inputFields.reduce((res, field) => {
      if (!field) {
        return res;
      }

      if (field.fieldType === CustomFieldType.LOCATION) {
        if (locationType === LocationType.IN_PERSONAL) {
          res.push(field);
        }
        return res;
      }

      if (field.fieldType === CustomFieldType.PHONE) {
        if (locationType === LocationType.PHONE_CALL) {
          res.push({ ...field, required: phoneCallType === PhoneCallType.PROMPT_INVITE || isSMSAllowed });
        } else if (isSMS) {
          res.push({ ...field, required: isSMSAllowed });
        }
        return res;
      }

      res.push(field);
      return res;
    }, new Array<CustomFieldInput>())
);

const selectPageName = createSelector(selectBookingPage, (state) => state.what?.customName);
const selectInviteOthers = createSelector(selectBookingPage, (state) => state.inviteOthers);
const selectEnterNote = createSelector(selectBookingPage, (state) => state.enterNote);

const selectBookingPageHow = createSelector(selectBookingPage, (state) => state.labels || {});
const selectEventNameLabel = createSelector(selectBookingPageHow, (how) => how.eventNameLabel || '');
const selectBookingTemplateLabel = createSelector(selectBookingPageHow, (how) => how.bookingTemplateLabel || '');
const selectAdditionalLabel = createSelector(selectBookingPageHow, (how) => how.additionalLabel || '');
const selectBookButtonLabel = createSelector(selectBookingPageHow, (how) => how.bookButtonLabel || '');
const selectBookAnotherButtonLabel = createSelector(selectBookingPageHow, (how) => how.bookAnotherButtonLabel || '');
const selectBookTitle = createSelector(selectBookingPageHow, (how) => how.bookTitle || '');
const selectBookDescription = createSelector(selectBookingPageHow, (how) => how.bookDescription || '');
const selectRescheduleTitle = createSelector(selectBookingPageHow, (how) => how.rescheduleTitle || '');
const selectRescheduleDescription = createSelector(selectBookingPageHow, (how) => how.rescheduleDescription || '');
const selectCancelTitle = createSelector(selectBookingPageHow, (how) => how.cancelTitle || '');
const selectCancelDescription = createSelector(selectBookingPageHow, (how) => how.cancelDescription || '');
const selectCancelButtonLabel = createSelector(selectBookingPageHow, (how) => how.cancelButtonLabel || '');
const selectCancelLabel = createSelector(selectBookingPageHow, (how) => how.cancelLabel || '');
const selectDateLabel = createSelector(selectBookingPageHow, (how) => how.dateLabel || '');
const selectDetailsLabel = createSelector(selectBookingPageHow, (how) => how.detailsLabel || '');
const selectInformationLabel = createSelector(selectBookingPageHow, (how) => how.informationLabel || '');
const selectTimeLabel = createSelector(selectBookingPageHow, (how) => how.timeLabel || '');
const selectUpdateButtonLabel = createSelector(selectBookingPageHow, (how) => how.updateButtonLabel || '');

const selectBookingPageStyle = createSelector(selectBookingPage, (state) => state.style || {});
const selectLogo = createSelector(selectBookingPageStyle, (style) => style.avatar);
const selectBackground = createSelector(selectBookingPageStyle, (style) => style.backgroundImage);
const selectTopHeaderStyle = createSelector(
  selectBookingPageStyle,
  (style) =>
    ({
      backgroundColor: style.lookAndFeel?.siteColorPrimary || '',
      borderColor: style.lookAndFeel?.siteColorPrimary || '',
    } as CSSProperties)
);

const selectHeaderStyle = createSelector(
  selectBookingPageStyle,
  (style) =>
    ({
      fontFamily: `"${style.lookAndFeel?.fontHeader}"`,
      color: style.lookAndFeel?.fontColorHeader || '',
    } as CSSProperties)
);
const selectSubHeaderStyle = createSelector(
  selectBookingPageStyle,
  (style) =>
    ({
      fontFamily: `"${style.lookAndFeel?.fontSubheader}"`,
      color: style.lookAndFeel?.fontColorSubHeader || '',
    } as CSSProperties)
);
const selectMainTextStyle = createSelector(
  selectBookingPageStyle,
  (style) =>
    ({
      fontFamily: `"${style.lookAndFeel?.fontMainText}"`,
      color: style.lookAndFeel?.fontColorMainText || '',
    } as CSSProperties)
);
const selectButtonStyle = createSelector(
  selectBookingPageStyle,
  (style) =>
    ({
      backgroundColor: style.lookAndFeel?.siteColorButtons || '',
      borderColor: style.lookAndFeel?.siteColorButtons || '',
    } as CSSProperties)
);
const selectOutlinedButtonStyle = createSelector(
  selectBookingPageStyle,
  (style) => ({ color: style.lookAndFeel?.siteColorButtons || '' } as CSSProperties)
);
const selectFooter = createSelector(selectBookingPageStyle, (style) => style.footer);

const selectBookingPageCalendar = createSelector(selectBookingPage, (state) => state.calendar);
const selectTimeFormat = createSelector(selectBookingPageCalendar, (state) => state?.timeFormat);
const selectTimeZoneType = createSelector(selectBookingPageCalendar, (state) => state?.timeZoneType);
const selectTimeZones = createSelector(selectBookingPageCalendar, (state) => state?.selectedTimeZone || []);
const selectDefaultTimeZone = createSelector(selectBookingPageCalendar, (state) => state?.defaultTimeZone);

const selectIs12Hour = createSelector(selectTimeFormat, (state) => state === TimeFormat.HOUR_12);
const selectHasAdditionalOptions = createSelector(
  selectInviteOthers,
  selectEnterNote,
  (inviteOthers, enterNote) => Boolean(inviteOthers) || Boolean(enterNote)
);
const selectTimeZoneOptions = createSelector(
  selectTimeZoneType,
  selectTimeZones,
  selectIsHostReschedule,
  (type, timeZones, isHostReschedule) => {
    const timeZoneOptions =
      type === TimeZoneType.DISPLAY_SELECTED
        ? timeZones.map((timeZone) => TIME_ZONES_BY_NAME[timeZone || ''])
        : Object.values(TIME_ZONES_BY_NAME);
    // if it's Reschedule from BookedMeetings and there is no Current Timezone in the list - added
    if (isHostReschedule && !timeZoneOptions.some((timeZone) => timeZone.timeZone === CURRENT_TIME_ZONE)) {
      timeZoneOptions.push(TIME_ZONES_BY_NAME[CURRENT_TIME_ZONE]);
    }
    return timeZoneOptions.sort((a, b) => a.offsetHours - b.offsetHours);
  }
);

const selectTimeZoneOptionsList = createSelector(
  selectTimeZoneOptions,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) =>
    timeZones.map((timeZone) => ({
      ...timeZone,
      time: dayjs(UTC)
        .tz(timeZone.timeZone)
        .format(timeFormat !== TimeFormat.HOUR_12 ? 'HH:mm' : 'hh:mm A'),
    }))
);

const selectTimeZone = createSelector(
  selectEventTimezone,
  selectTimeZoneType,
  selectDefaultTimeZone,
  selectIsHostReschedule,
  (timeZone, type, defaultTimeZone, isHostReschedule) =>
    isHostReschedule
      ? CURRENT_TIME_ZONE
      : timeZone || (type === TimeZoneType.DISPLAY_SELECTED && defaultTimeZone) || CURRENT_TIME_ZONE
);

const selectAvailableTimesByDate = createSelector(selectAgendaSpots, selectTimeZone, (spots, timeZone) =>
  spots.reduce((dates, spot) => {
    const spotInt = parseInt(spot);
    const date = dayjs(spotInt).tz(timeZone).format('YYYY-MM-DD');
    dates[date] = [...(dates[date] || []), spotInt];
    return dates;
  }, {} as { [key: string]: number[] })
);

const selectAvailableTimes = createSelector(
  selectIsPreviewMode,
  selectEventDate,
  selectAvailableTimesByDate,
  (isPreviewMode, eventDate, timesByDate) =>
    (isPreviewMode && PREVIEW_AVAILABLE_TIMES) ||
    (eventDate && timesByDate[dayjs(eventDate).format('YYYY-MM-DD')])?.map((spot) => new Date(spot)) ||
    []
);

const selectDisabledDates = createSelector(selectAvailableTimesByDate, selectViewDate, (timesByDate, viewDate) => {
  const year = viewDate.getFullYear();
  const month = viewDate.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const disabledDays: Set<string> = new Set();

  for (let i = 1; i <= daysInMonth; i++) {
    const date = dayjs().year(year).month(month).date(i).format('YYYY-MM-DD');
    if (!timesByDate[date]) {
      disabledDays.add(date);
    }
  }

  return Array.from(disabledDays).map((day) => converDateStringToCurrentTimezoneDate(day));
});

const selectEditKey = createSelector(selectViewSettings, (settings) => settings.editKey);
const selectIsHideHeader = createSelector(selectViewSettings, (settings) => settings.hideHeader);
const selectIsHideCookie = createSelector(selectViewSettings, (settings) => settings.hideCookie);
const selectIsHidePageDetails = createSelector(selectViewSettings, (settings) => settings.hidePageDetails);
const selectIsIframe = createSelector(selectViewSettings, (settings) => settings.iframe);

const selectIsEditKeyValid = createSelector(
  selectEventEditKey,
  selectEditKey,
  (eventEditKey, editKey) => eventEditKey === editKey
);

const selectShowFooter = createSelector(
  selectFooter,
  selectIsIframe,
  (footer, isIframe) => !isIframe && Boolean(footer)
);

const selectShowError = createSelector(selectError, (error) => Boolean(error));
const selectShowBookingTemplates = createSelector(
  selectStep,
  selectShowError,
  (step, error) => false // !error && step == EventSteps.WHAT
);
const selectShowDetails = createSelector(
  selectStep,
  selectShowError,
  (step, error) => true // !error && step !== EventSteps.WHAT
);
const selectShowDateTime = createSelector(
  selectStep,
  selectShowError,
  (step, error) => !error && step === EventSteps.WHEN
);
const selectShowYourInfo = createSelector(
  selectStep,
  selectShowError,
  (step, error) => !error && step === EventSteps.INFO
);
const selectShowCancel = createSelector(
  selectStep,
  selectShowError,
  (step, error) => !error && step === EventSteps.CANCEL
);
const selectShowBookedInfo = createSelector(
  selectStep,
  selectShowError,
  selectIsRescheduleMode,
  (step, error, isRescheduleMode) => !isRescheduleMode && !error && step === EventSteps.BOOKED
);
const selectShowRescheduledMessage = createSelector(
  selectIsPreviewMode,
  selectIsRescheduled,
  (isPreviewMode, isRescheduled) => isPreviewMode || isRescheduled
);
const selectShowCanceledMessage = createSelector(
  selectIsPreviewMode,
  selectEventIsCanceled,
  (isPreviewMode, isCanceled) => isPreviewMode || isCanceled
);
const selectShowBookedMessage = createSelector(
  selectIsPreviewMode,
  selectShowRescheduledMessage,
  selectShowCanceledMessage,
  (isPreviewMode, isRescheduled, isCanceled) => isPreviewMode || (!isRescheduled && !isCanceled)
);
const selectShowActionMessage = createSelector(
  selectIsPreviewMode,
  selectStep,
  selectIsExisting,
  selectShowError,
  (isPreviewMode, step, isExisting, error) => !error && step === EventSteps.BOOKED && (isExisting || isPreviewMode)
);
const selectShowBackButton = createSelector(
  selectStep,
  selectShowError,
  selectIsRescheduleMode,
  (step, error, isRescheduleMode) =>
    !isRescheduleMode && !error && (step === EventSteps.INFO || step === EventSteps.CANCEL)
);
const selectShowNextButton = createSelector(
  selectStep,
  selectEventDate,
  selectStartTime,
  selectShowError,
  selectIsRescheduleMode,
  (step, date, time, error, isRescheduleMode) =>
    !isRescheduleMode && !error && step === EventSteps.WHEN && Boolean(date) && Boolean(time)
);
const selectShowBookButton = createSelector(
  selectStep,
  selectShowError,
  selectIsRescheduleMode,
  (step, error, isRescheduleMode) =>
    !error && (step === EventSteps.INFO || (isRescheduleMode && step === EventSteps.WHEN))
);
const selectShowConfirmCancelButton = createSelector(
  selectStep,
  selectShowError,
  (step, error) => !error && step === EventSteps.CANCEL
);
const selectShowBookAnotherButton = createSelector(
  selectStep,
  selectBookAnotherEnabled,
  selectShowError,
  selectIsHostReschedule,
  (step, enabled, error, isHostReschedule) => !isHostReschedule && !error && step === EventSteps.BOOKED && enabled
);
const selectShowCancelButton = createSelector(
  selectStep,
  selectCancelEnabled,
  selectEventIsCanceled,
  selectShowError,
  selectIsHostReschedule,
  selectIsEditKeyValid,
  selectIsPastEvent,
  (step, enabled, canceled, error, isHostReschedule, isEditKeyValid, isPastEvent) =>
    !error && isEditKeyValid && !isPastEvent && !canceled && !isHostReschedule && step === EventSteps.BOOKED && enabled
);
const selectShowDateTimeEditButton = createSelector(
  selectStep,
  selectRescheduleEnabled,
  selectEventIsCanceled,
  selectIsEditKeyValid,
  selectIsPastEvent,
  (step, enabled, canceled, isEditKeyValid, isPastEvent) =>
    !isPastEvent && isEditKeyValid && !canceled && (step === EventSteps.INFO || (step === EventSteps.BOOKED && enabled))
);
const selectShowInfoEditButton = createSelector(
  selectStep,
  selectRescheduleEnabled,
  selectEventIsCanceled,
  selectIsEditKeyValid,
  (step, enabled, canceled, isEditKeyValid) => isEditKeyValid && !canceled && enabled && step === EventSteps.BOOKED
);

const selectIsFieldsValid = createSelector(selectFilteredInputFields, (fields) => fields.every(validateCustomField));

const selectIsSaveAvailable = createSelector(
  selectStartTime,
  selectIsFieldsValid,
  (startTime, isFieldsValid) => startTime && isFieldsValid
);

const selectIsEditable = createSelector(
  selectIsPreviewMode,
  selectIsReadOnly,
  (isPreview, isReadOnly) => isPreview && !isReadOnly
);

const selectIsBookedStep = createSelector(selectStep, (step) => step === EventSteps.BOOKED);

export const eventSelectors = {
  selectEvent,
  selectAgenda,
  selectBookingPageId,
  selectScheduledMeetingId,
  selectViewDate,
  selectStep,
  selectIsMobile,
  selectIsRescheduleMode,
  selectIsPreviewMode,
  selectIsReadOnly,
  selectIsHostReschedule,
  selectIsFetching,
  selectError,
  selectAgendaLoaded,
  selectViewSettings,
  selectIsSMSAllowed,
  selectIsExisting,
  selectEventId,
  selectEventDateString,
  selectEventDate,
  selectEventCanceled,
  selectEventIsCanceled,
  selectEventCanceledType,
  selectEventCanceledNote,
  selectStartTime,
  selectAttendeeName,
  selectAttendeeEmail,
  selectAttendeePhoneFormatted,
  selectAttendeeLocation,
  selectAttendeePhone,
  selectLocationType,
  selectLocationTypeLabel,
  selectLocationSettings,
  selectInputFields,
  selectFilteredInputFields,
  selectIsRedirect,
  selectExternalLink,
  selectCustomLinks,
  selectLocationTypeOptions,
  selectCustomPhone,
  selectCustomAddress,
  selectLocationOptions,
  selectIsLocationTypeEdit,
  selectShowLocationInput,
  selectShowHostPhone,
  selectShowCustomPhone,
  selectShowCustomAddress,
  selectShowLocationSettings,
  selectBookingPage,
  selectDateRange,
  selectIsSMSEnabled,
  selectPageName,
  selectInviteOthers,
  selectEnterNote,
  selectBookingPageHow,
  selectEventNameLabel,
  selectBookingTemplateLabel,
  selectAdditionalLabel,
  selectBookButtonLabel,
  selectBookAnotherButtonLabel,
  selectBookTitle,
  selectBookDescription,
  selectRescheduleTitle,
  selectRescheduleDescription,
  selectCancelTitle,
  selectCancelDescription,
  selectCancelButtonLabel,
  selectCancelLabel,
  selectDateLabel,
  selectDetailsLabel,
  selectInformationLabel,
  selectTimeLabel,
  selectUpdateButtonLabel,
  selectBookingPageStyle,
  selectLogo,
  selectBackground,
  selectHeaderStyle,
  selectTopHeaderStyle,
  selectSubHeaderStyle,
  selectMainTextStyle,
  selectButtonStyle,
  selectOutlinedButtonStyle,
  selectFooter,
  selectTimeFormat,
  selectIs12Hour,
  selectHasAdditionalOptions,
  selectHostPhone,
  selectHostDisplayName,
  selectHostsNames,
  selectHostFullPhone,
  selectHostIsTeam,
  selectTimeZoneOptions,
  selectTimeZoneOptionsList,
  selectTimeZone,
  selectAvailableTimes,
  selectAvailableTimesByDate,
  selectDisabledDates,
  selectShowError,
  selectShowBookingTemplates,
  selectShowDetails,
  selectShowDateTime,
  selectShowYourInfo,
  selectShowCancel,
  selectShowBookedInfo,
  selectShowRescheduledMessage,
  selectShowCanceledMessage,
  selectShowBookedMessage,
  selectShowActionMessage,
  selectShowBackButton,
  selectShowNextButton,
  selectShowBookButton,
  selectShowConfirmCancelButton,
  selectShowBookAnotherButton,
  selectShowCancelButton,
  selectShowDateTimeEditButton,
  selectShowInfoEditButton,
  selectIsSaveAvailable,
  selectIsHideHeader,
  selectIsHideCookie,
  selectIsHidePageDetails,
  selectIsIframe,
  selectShowFooter,
  selectIsEditable,
  selectIsBookedStep,
};
