import { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import labels from './labels';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import {
  WorkspaceWhoStep,
  cannotDeleteWorkspaceNameModalActions,
  deleteWorkspaceNameModalActions,
} from '../../../store/workspaces';
import { workspacesActions } from '../../../store/workspaces/actions';
import { Path } from '../../../routing';
import { usersSelectors } from '../../../store/users';
import { WorkspaceInput } from '../../../API';
import { navigationService } from '../../../services/NavigationService';
import { userSettingsSelectors } from '../../../store/userSettings';

interface IWorkspaceCard {
  workspace: WorkspaceInput;
}

export const WorkspaceCard = memo(({ workspace }: IWorkspaceCard) => {
  const { id, name, isActive } = workspace;
  const dispatch = useDispatch();
  const menu = useRef<Menu>(null);
  const adminList = useSelector(usersSelectors.selectAdminUsersByWorkspace(id));
  const userList = useSelector(usersSelectors.selectStandardUsersByWorkspace(id));
  const isWorkspacesEdit = useSelector(userSettingsSelectors.selectWorkspacesEdit);
  const isWorkspacesDelete = useSelector(userSettingsSelectors.selectWorkspacesDelete);

  const workspaceInfo: WorkspaceWhoStep = {
    adminList,
    userList,
  };

  const handleEdit = () => {
    dispatch(workspacesActions.setWorkspace({ ...workspace, ...workspaceInfo }));
    navigationService.navigateTo(Path.EditWorkspace);
  };

  const handleActivate = () => {
    dispatch(workspacesActions.activateWorkspaceRequest(workspace.id));
  };

  const isDeletable = () => {
    return (
      !adminList.some((admin) => admin.workspaceIds && admin.workspaceIds.length <= 1) &&
      !userList.some((user) => user.workspaceIds && user.workspaceIds.length <= 1)
    );
  };

  const handleDelete = () => {
    dispatch(workspacesActions.setWorkspace({ ...workspace, ...workspaceInfo }));
    if (isDeletable()) {
      dispatch(deleteWorkspaceNameModalActions.openModal());
    } else {
      dispatch(cannotDeleteWorkspaceNameModalActions.openModal());
    }
  };

  const menuItems = [
    { label: labels.edit, icon: 'pi pi-fw pi-pencil', command: handleEdit, visible: isWorkspacesEdit },
    {
      label: isActive ? labels.deactivate : labels.activate,
      icon: isActive ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open',
      command: handleActivate,
      visible: isWorkspacesEdit,
    },
    { label: labels.delete, icon: 'pi pi-fw pi-trash', command: handleDelete, visible: isWorkspacesDelete },
  ];

  const getAdminInfo = () => {
    if (adminList.length) {
      const name = adminList.find((record) => record.fullName)?.fullName;
      return `${labels.admin}: ${name ? name : adminList[0].email} ${
        adminList.length > 1 ? ` + ${adminList.length - 1}` : ''
      }`;
    } else {
      return `${labels.admin}: ${labels.none}`;
    }
  };

  return (
    <div className="col-12 md:col-6 lg:col-4" key={id}>
      <div
        className={`card shadow-none border-1 surface-border cursor-pointer hover:bg-gray-50 ${
          isActive ? '' : 'bg-gray-100 text-gray-500'
        }`}
        onClick={handleEdit}
      >
        <div className="flex justify-content-between">
          <div className="text-xl font-medium overflow-hidden text-overflow-ellipsis white-space-nowrap">{name}</div>
          {(isWorkspacesEdit || isWorkspacesDelete) && (
            <div className="static sm:relative flex-container -mt-2 -mr-2 mb-2" onClick={(e) => e.stopPropagation()}>
              <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
              <Button rounded text icon="pi pi-cog text-lg" onClick={(event) => menu.current?.toggle(event)} />
            </div>
          )}
        </div>

        <div className="h-3rem text-color overflow-hidden text-overflow-ellipsis mt-3 mb-4">{getAdminInfo()}</div>

        <div className="mt-1 -mb-3 -mx-3">
          {isActive && (
            <Button
              label={isWorkspacesEdit ? labels.edit : labels.view}
              text
              onClick={(e) => {
                e.stopPropagation(); // to prevent card click during click on inner elements
                handleEdit();
              }}
              outlined
            />
          )}
          {isWorkspacesEdit && !isActive && (
            <Button
              label={labels.activate}
              text
              onClick={(e) => {
                e.stopPropagation(); // to prevent card click during click on inner elements
                handleActivate();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});

WorkspaceCard.displayName = 'WorkspaceCard';
