import { t } from '../../../../i18n/i18n';

export default {
  export: t('OPSConsoleOrgs:EXPORT'),
  filterAllOrgs: t('OPSConsoleOrgs:FILTER_ALL_ORGS'),
  filterExpireSoon: t('OPSConsoleOrgs:FILTER_EXPIRED_SOON'),
  filterLowAdopt: t('OPSConsoleOrgs:FILTER_LOW_ADOPT'),
  status: t('OPSConsoleOrgs:STATUS_TITLE'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
};
