import { t } from '../../i18n/i18n';
import { CanceledType, CreateUserEventInput } from '../../API';
import { DEFAULT_BOOKING_PAGE_DATA } from '../bookingPages/constants';
import { ToastNotificationOptions } from '../notifications';
import { AgendaType, EventSteps } from './types'; // HostData
import { SelectItemOptionsType } from 'primereact/selectitem';

export const DEFAULT_EVENT: CreateUserEventInput = {
  userId: '',
  tenant: '',
  eventDate: '',
  eventId: '',
  bookingPageId: '',
  bookingPageName: '',
  startTime: '',
  endTime: '',
  integrations: [],
  location: null,
  guestEmails: [],
  note: '',
  canceled: {
    isCanceled: false,
    type: CanceledType.UNEXPECTED,
  },
};

export const DEFAULT_AGENDA: AgendaType = {
  bookingPage: DEFAULT_BOOKING_PAGE_DATA,
};

// export const PREVIEW_HOST: HostData = {
//   id: '',
//   name: '',
//   phone: '',
//   email: '',
//   videoIntegrations: [ 'MICROSOFT_TEAMS', 'GOOGLE_MEET', 'ZOOM' ],
// };

export const DEFAULT_VIEW_DATE: number = new Date().valueOf();

export const PREVIEW_AVAILABLE_TIMES: Date[] = [
  new Date(2000, 0, 1, 9, 0),
  new Date(2000, 0, 1, 9, 15),
  new Date(2000, 0, 1, 9, 30),
  new Date(2000, 0, 1, 9, 45),
  new Date(2000, 0, 1, 10, 0),
  new Date(2000, 0, 1, 10, 15),
  new Date(2000, 0, 1, 10, 30),
  new Date(2000, 0, 1, 10, 45),
  new Date(2000, 0, 1, 11, 0),
];

export const CANCEL_TYPE_OPTIONS: SelectItemOptionsType = [
  { value: CanceledType.UNEXPECTED, label: t('ScheduledMeetings:REASON_UNEXPECTED') },
  { value: CanceledType.INVITE_OTHERS, label: t('ScheduledMeetings:REASON_INVITE') },
  { value: CanceledType.TIME, label: t('ScheduledMeetings:REASON_TIME') },
  { value: CanceledType.OTHER, label: t('ScheduledMeetings:REASON_OTHER') },
];

export const EVENT_STEPS_ORDER: string[] = Object.values(EventSteps);

export const DELETE_EVENT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('ScheduledMeetingsToastsNotifications:DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE'),
});
export const DELETE_EVENT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 3000,
  message: t('ScheduledMeetingsToastsNotifications:DELETE_SCHEDULED_MEETING_ERROR_MESSAGE'),
});

export const IN_PERSON_LABEL = t('PublicBookingPage:IN_PERSON_LABEL');
export const PHONE_LABEL = t('PublicBookingPage:PHONE_LABEL');
export const VIDEO_CONFERENCE_LABEL = t('PublicBookingPage:VIDEO_CONFERENCE_LABEL');

export const HOST_NOTIFICATION_TEMPLATE = {
  confirmationSubject: t('HostNotificationTemplates:HOST_CONFIRMATION_TEMPLATE_SUBJECT'),
  confirmationBody: t('HostNotificationTemplates:HOST_CONFIRMATION_TEMPLATE_BODY'),
  rescheduleSubject: t('HostNotificationTemplates:HOST_RESCHEDULE_SUBJECT'),
  rescheduleBody: t('HostNotificationTemplates:HOST_RESCHEDULE_BODY'),
  cancelationSubject: t('HostNotificationTemplates:HOST_CANCELATION_SUBJECT'),
  cancelationBody: t('HostNotificationTemplates:HOST_CANCELATION_BODY'),
};

export const NO_USERS_AVAILABLE_LABEL = t('PublicBookingPage:NO_USERS_AVAILABLE_LABEL');
