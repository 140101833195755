import { useSelector } from 'react-redux';
import { UserDetails, OPSConsoleUsersSelectors } from '../../../../store/opsConsole/users';
import { Column } from 'primereact/column';
import labels from './labels';
import { convertUnixToFormattedDate } from '../../../../services/DateService';
import { UserRowActions } from '../userRowActions/UserRowActions';
import { Link } from 'react-router-dom';
import { Path } from '../../../../routing';
import { UserStatus } from '../../../../store/users/typings';
import { navigationService } from '../../../../services/NavigationService';
import { Table } from '../../table/Table';
import { authentificationSelectors } from '../../../../store/authentification';
import { DateFormat, TimeFormat, userSettingsSelectors } from '../../../../store/userSettings';

type UserTableProps = {
  isFromOrgDetails?: boolean;
  isShowLess?: boolean;
};

export const UsersTable = ({ isFromOrgDetails, isShowLess }: UserTableProps) => {
  const users = useSelector(OPSConsoleUsersSelectors.selectSearchUsers);
  const isPaginatorEnabled = useSelector(OPSConsoleUsersSelectors.selectIsPaginatorEnabled);
  const isMainAdminOrSupport = useSelector(authentificationSelectors.selectIsMainAdminOrSupport);
  const currentUserId = useSelector(authentificationSelectors.selectUserId);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat) || DateFormat.default;
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;

  /*   const deactivateModalOpen = useSelector(deactivateUserModalSelectors.selectIsModalOpen);
  const deleteModalOpen = useSelector(deleteUserModalSelectors.selectIsModalOpen);

  const handleDeactivateClose = () => {
    dispatch(deactivateUserModalActions.closeModal());
  };

  const handleDeleteClose = () => {
    dispatch(deleteUserModalActions.closeModal());
  }; */

  const openUserDetails = (tenantId: string, email: string) => {
    navigationService.navigateTo(Path.OPSConsoleUserDetails.replace(':tenantId', tenantId).replace(':email', email));
  };

  return (
    <>
      <Table
        value={isShowLess ? users.slice(0, 3) : users}
        size="small"
        paginator={!isFromOrgDetails && isPaginatorEnabled}
        resizableColumns
        className="cursor-pointer"
        onRowClick={(e) => openUserDetails(e.data.tenantId, e.data.email)}
        rowHover
        paths={[Path.OPSConsoleUsers, Path.OPSConsoleUserDetails]}
      >
        <Column field="userName" header={labels.fullName} sortable bodyClassName="text-overflow-ellipsis max-w-10rem" />
        <Column field="email" header={labels.email} sortable bodyClassName="text-overflow-ellipsis max-w-10rem" />
        {!isFromOrgDetails && (
          <Column
            field="accountName"
            header={labels.account}
            body={(data: UserDetails) => (
              <Link
                className="text-color underline hover:text-primary"
                to={Path.OPSConsoleAccount.replace(':accountId', data.accountId)}
              >
                {data.accountName}
              </Link>
            )}
            sortable
            bodyClassName="text-overflow-ellipsis"
          />
        )}
        <Column field="roleName" header={labels.role} sortable bodyClassName="text-overflow-ellipsis" />
        <Column field="status" header={labels.status} sortable bodyClassName="text-overflow-ellipsis" />
        <Column
          field="lastActive"
          header={labels.lastActive}
          body={(data: UserDetails) => {
            if (data.status !== UserStatus.InviteSent && data?.lastActive) {
              return convertUnixToFormattedDate(Number(data.lastActive), dateFormat, timeFormat);
            } else {
              return labels.never;
            }
          }
          }
          sortable
          bodyClassName="text-overflow-ellipsis"
          frozen
        />
        <Column
          body={(data) =>
            data.status !== UserStatus.InviteSent && isMainAdminOrSupport && data.userId !== currentUserId ? (
              <UserRowActions tenantId={data.tenantId} userId={data.userId} />
            ) : (
              ''
            )
          }
          className="w-4rem"
          align="center"
          frozen
        />
      </Table>
      {/* <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateMessage} "${userNameOrEmail}"?`}
        visible={deactivateModalOpen}
        onClose={handleDeactivateClose}
        onCancel={handleDeactivateClose}
        onConfirm={() => {
          dispatch(usersActions.changeUserStatusRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTitle}
        description={`${labels.deleteMessage} "${userNameOrEmail}"?`}
        visible={deleteModalOpen}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={() => {
          dispatch(usersActions.deleteUserRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      /> */}
    </>
  );
};
