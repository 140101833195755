import { LEAVE_THE_ACCOUNT, leaveTheAccountModalActions } from '../../../store/authentification/modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InviteToAccount, authentificationActions, authentificationSelectors } from '../../../store/authentification';
import { Button } from 'primereact/button';
import { Modal } from '../../common';
import labels from './labels';

export const ModalLeaveAccount = () => {
  const inviteToAccount: InviteToAccount | null = useSelector(authentificationSelectors.selectInviteToAccount);
  const isLastSuperAdmin = useSelector(authentificationSelectors.selectIsLastSuperAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inviteToAccount) {
      dispatch(leaveTheAccountModalActions.openModal());
    }
  }, [inviteToAccount]);

  const handleClosePopup = () => {
    dispatch(leaveTheAccountModalActions.closeModal());
  };

  const handleChooseExistingTenant = () => {
    if (inviteToAccount?.existingTenant) {
      dispatch(authentificationActions.chooseAnAccountRequest(inviteToAccount.existingTenant));
      dispatch(leaveTheAccountModalActions.closeModal());
    }
  };

  const handleChooseNewTenant = () => {
    if (inviteToAccount?.newTenant) {
      dispatch(authentificationActions.chooseAnAccountRequest(inviteToAccount.newTenant));
      dispatch(leaveTheAccountModalActions.closeModal());
    }
  };

  return (
    <Modal.Container name={LEAVE_THE_ACCOUNT}>
      <Modal.Header>
        <div className="flex justify-between align-items-center">
          <span className="font-semibold">{labels.title}</span>
          <Button className="ml-auto" icon="pi pi-times" text onClick={handleClosePopup} />
        </div>
      </Modal.Header>

      <div className="w-24rem mb-3">
        {isLastSuperAdmin && (
          <div className="bg-blue-100 p-3 border-round mb-2">
            <i className="pi pi-info-circle pr-2 vertical-align-middle" />
            {labels.lastSuperAdmin}
          </div>
        )}

        <div>{`${labels.inviteTextPart1} ${inviteToAccount?.newTenant?.invitedByName} ${labels.inviteTextPart2}`}</div>
        <div className="mt-2 mb-2 text-red-500">{labels.onlyOneAccount}</div>
        <div>{labels.acceptText}</div>
      </div>

      <Modal.Buttons>
        <div className="flex gap-2 justify-content-center -mr-2">
          <Button onClick={handleChooseExistingTenant} text outlined className="w-9rem justify-content-center">
            {labels.cancel}
          </Button>
          <Button
            onClick={handleChooseNewTenant}
            color="primary"
            className="w-9rem justify-content-center"
            autoFocus
            disabled={isLastSuperAdmin}
          >
            {labels.acceptInvite}
          </Button>
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
