import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  close: t('Common:CLOSE'),
  bookMeeting: t('DefaultBookingPage:BOOK_MEETING_BUTTON'),
  bottomLeft: t('BookingPageAddToWebsite:BOTTOM_LEFT'),
  bottomRight: t('BookingPageAddToWebsite:BOTTOM_RIGHT'),
  buttonToPopup: t('BookingPageAddToWebsite:BUTTON_POPUP_TITLE'),
  copyCodeDescEmbed: t('BookingPageAddToWebsite:COPY_CODE_EMBED_DESCRIPTION'),
  copyCodeDescLink: t('BookingPageAddToWebsite:COPY_CODE_LINK_DESCRIPTION'),
  copyCodeDescPopup: t('BookingPageAddToWebsite:COPY_CODE_POPUP_DESCRIPTION'),
  copyCodeTitle: t('BookingPageAddToWebsite:COPY_CODE_TITLE'),
  customizeDesc: t('BookingPageAddToWebsite:CUSTOMIZE_DESCRIPTION'),
  customizeTitle: t('BookingPageAddToWebsite:CUSTOMIZE_TITLE'),
  embedDesc: t('BookingPageAddToWebsite:EMBED_DESCRIPTION'),
  embedTitle: t('BookingPageAddToWebsite:EMBED_TITLE'),
  floatingButtonDesc: t('BookingPageAddToWebsite:FLOATING_BUTTON_DESCRIPTION'),
  hidePageDetails: t('BookingPageAddToWebsite:HIDE_PAGE_DETAILS'),
  hideCookieBanner: t('BookingPageAddToWebsite:HIDE_COOKIE_BANNER'),
  label: t('BookingPageAddToWebsite:LABEL'),
  linkDesc: t('BookingPageAddToWebsite:LINK_DESCRIPTION'),
  linkTitle: t('BookingPageAddToWebsite:LINK_TITLE'),
  location: t('BookingPageAddToWebsite:LOCATION'),
  popupDesc: t('BookingPageAddToWebsite:POPUP_DESCRIPTION'),
  popupTitle: t('BookingPageAddToWebsite:POPUP_TITLE'),
  title: t('BookingPageAddToWebsite:TITLE'),
  topLeft: t('BookingPageAddToWebsite:TOP_LEFT'),
  topRight: t('BookingPageAddToWebsite:TOP_RIGHT'),
};
