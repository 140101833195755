import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primereact/autocomplete';
import { Chip } from 'primereact/chip';
import React, { useState } from 'react';

interface AutoCompleteWithChipsProps<T, K> {
  field: string;
  placeholder?: string;
  onSelect: (e: AutoCompleteSelectEvent) => void;
  suggestions: T[];
  completeMethod: (e: AutoCompleteCompleteEvent) => void;
  className?: string;
  valuesList: K[];
  chipLabel: keyof K;
  chipLabelFromList?: (key: string) => string;
  chipKey: keyof K;
  chipOnRemove: (value: string) => void;
  isRemovable?: (value: K) => boolean;
  disabled?: boolean;
}
export const AutoCompleteWithChips = <T, K>({
  field,
  placeholder,
  onSelect,
  suggestions,
  completeMethod,
  className,
  valuesList,
  chipLabel,
  chipLabelFromList,
  chipKey,
  chipOnRemove,
  isRemovable,
  disabled,
}: AutoCompleteWithChipsProps<T, K>) => {
  const [localValue, setLocalValue] = useState('');

  const handleSelect = (e: AutoCompleteSelectEvent) => {
    onSelect(e);
    setLocalValue('');
  };
  return (
    <>
      <AutoComplete
        placeholder={placeholder || ''}
        field={field}
        dropdown
        dropdownMode="current"
        value={localValue}
        onChange={(e) => setLocalValue(e.value)}
        onSelect={handleSelect}
        suggestions={suggestions}
        completeMethod={completeMethod}
        className={className}
        disabled={disabled}
      />
      <div className="mt-1">
        {valuesList.map((record) => {
          const canBeRemoved = !disabled && (!isRemovable || isRemovable(record));
          return (
            <Chip
              key={record[chipKey] as string}
              label={chipLabelFromList ? chipLabelFromList(record[chipKey] as string) : (record[chipLabel] as string)}
              removable={canBeRemoved}
              onClick={() => canBeRemoved && chipOnRemove(record[chipKey] as string)}
              onRemove={() => canBeRemoved && chipOnRemove(record[chipKey] as string)}
              className={`my-2 mr-2 ${canBeRemoved ? 'cursor-pointer' : ''}`}
            />
          );
        })}
      </div>
    </>
  );
};
