import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { UpdateBookingTemplateInput, VideoConferenceType, WorkspaceInput } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CLONE_BOOKING_TEMPLATE_ERROR_TOAST,
  CLONE_BOOKING_TEMPLATE_SUCCESS_TOAST,
  DEFAULT_BOOKING_TEMPLATE_DATA,
  DELETE_BOOKING_TEMPLATES_ERROR_TOAST,
  DELETE_BOOKING_TEMPLATES_SUCCESS_TOAST,
  GET_BOOKING_TEMPLATES_ERROR_TOAST,
  SAVE_BOOKING_TEMPLATE_ERROR_TOAST,
  SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST,
} from './constants';
import { BookingTemplatesActionTypes, bookingTemplatesActions } from './actions';
import { bookingTemplatesSelectors } from './selectors';
import { deleteBookingTemplates, getBookingTemplate, getBookingTemplates, postBookingTemplate } from './service';
import { authentificationSelectors } from '../authentification';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { userSettingsSelectors } from '../userSettings';
import { handleServiceError } from '../utils/reduxUtils';
import { copyPublicFile, uploadPublicFile } from '../userSettings/service';
import { generatePublicFileKey, generatePublicFileKeyCopy } from './utils';
import { usersSaga } from '../users/sagas';
import { teamsSaga } from '../teams/sagas';
import { workspacesSelectors } from '../workspaces';

const selectCreateBookingTemplateRequest = createSelector(
  authentificationSelectors.selectWorkspace,
  bookingTemplatesSelectors.selectBookingTemplate,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingTemplate, lastModify) => ({
    ...bookingTemplate,
    workspaceId,
    lastModify,
  })
);

const selectCloneBookingTemplateRequest = createSelector(
  authentificationSelectors.selectWorkspace,
  bookingTemplatesSelectors.selectBookingTemplate,
  bookingTemplatesSelectors.selectCloneName,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingTemplate, cloneName, lastModify) => ({
    ...bookingTemplate,
    id: '',
    workspaceId,
    lastModify,
    what: {
      ...bookingTemplate.what,
      customName: cloneName,
    },
  })
);

// copy workspace images if required, remove old and temporary images
function* processFilesOnCreateUpdate() {
  const isWorkspaceLogoToUse: string = yield select(bookingTemplatesSelectors.selectIsWorkspaceLogoToUse);
  const isWorkspaceBackgroundToUse: string = yield select(bookingTemplatesSelectors.selectIsWorkspaceBackgroundToUse);
  const logo: string = yield select(bookingTemplatesSelectors.selectFinalLogoImage);
  const background: string = yield select(bookingTemplatesSelectors.selectFinalBackgroundImage);

  const avatar: string = isWorkspaceLogoToUse
    ? yield call(copyPublicFile, generatePublicFileKey(logo), generatePublicFileKeyCopy(logo))
    : logo;

  const backgroundImage: string = isWorkspaceBackgroundToUse
    ? yield call(copyPublicFile, generatePublicFileKey(background), generatePublicFileKeyCopy(background))
    : background;

  yield put(bookingTemplatesActions.updateStyleStep({ avatar, backgroundImage }));
}

// TODO: find out how resolve typings error when thunk is dispatching from saga
function* getBookingTemplatesSaga() {
  try {
    const workspace: string = yield select(authentificationSelectors.selectWorkspace);
    const response: UpdateBookingTemplateInput[] = yield call(getBookingTemplates, workspace);

    yield put(bookingTemplatesActions.getBookingTemplatesSuccess(response));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplatesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* getBookingTemplateSaga({ payload: id }: any) {
  try {
    const currentWorkspaceId: string = yield select(authentificationSelectors.selectWorkspace);
    const currentWorkspace: WorkspaceInput = yield select(
      workspacesSelectors.selectWorkspaceById(currentWorkspaceId)
    );
    const userId: string = yield select(authentificationSelectors.selectUserId);
    const defaultVideoConference: VideoConferenceType = yield select(
      userSettingsSelectors.selectDefaultVideoIntegration
    );

    const requestsList = [
      ...(id ? [call(getBookingTemplate, id)] : []),
      call(usersSaga.getUsers),
      call(teamsSaga.getTeams),
    ];
    let [bookingTemplate]: [UpdateBookingTemplateInput] = yield all(requestsList);

    if (!id) {
      bookingTemplate = {
        ...DEFAULT_BOOKING_TEMPLATE_DATA,
        workspaceId: currentWorkspaceId,
        labels: currentWorkspace.labels,
        style: {
          ...currentWorkspace.style,
          avatar: null,
          backgroundImage: null,
        },
        potentialHosts: [userId],
        where: {
          ...DEFAULT_BOOKING_TEMPLATE_DATA.where,
          videoConferenceType: defaultVideoConference,
        },
      };
    }

    yield put(bookingTemplatesActions.getBookingTemplateSuccess(bookingTemplate));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* createBookingTemplateSaga() {
  try {
    yield call(processFilesOnCreateUpdate);

    const createUserDataInput: UpdateBookingTemplateInput = yield select(selectCreateBookingTemplateRequest);

    yield call(postBookingTemplate, createUserDataInput);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.createBookingTemplatesuccess());
    yield put<any>(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.createBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* cloneBookingTemplateSaga() {
  try {
    yield call(processFilesOnCreateUpdate);

    const createUserDataInput: UpdateBookingTemplateInput = yield select(selectCloneBookingTemplateRequest);

    yield call(postBookingTemplate, createUserDataInput);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.cloneBookingTemplatesuccess());
    yield put<any>(notificationsActions.showToast(CLONE_BOOKING_TEMPLATE_SUCCESS_TOAST));
    yield put(bookingTemplatesActions.getBookingTemplatesRequest());
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.cloneBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

// TODO: find out how resolve typings error when saga has parameters
function* updateBookingTemplateSaga() {
  try {
    yield call(processFilesOnCreateUpdate);

    const bookingTemplate: UpdateBookingTemplateInput = yield select(bookingTemplatesSelectors.selectBookingTemplate);
    const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
    yield call(postBookingTemplate, { ...bookingTemplate, lastModify });

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.saveBookingTemplatesuccess());
    yield put<any>(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.saveBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* activateBookingTemplateSaga({ payload: bookingTemplate }: any) {
  try {
    const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
    yield call(postBookingTemplate, { ...bookingTemplate, lastModify });

    yield put(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
    yield put(bookingTemplatesActions.enableBookingTemplatesuccess());
    yield put<any>(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
    yield put(bookingTemplatesActions.getBookingTemplatesRequest());
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.enableBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* deleteBookingTemplatesSaga() {
  try {
    const ids: string[] = yield select(bookingTemplatesSelectors.selectSelectedBookingTemplates);
    const workspaceId: string = yield select(authentificationSelectors.selectWorkspace);

    yield call(deleteBookingTemplates, ids, workspaceId);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.deleteBookingTemplatesSuccess());
    yield put<any>(notificationsActions.showToast(DELETE_BOOKING_TEMPLATES_SUCCESS_TOAST));
    yield put(bookingTemplatesActions.getBookingTemplatesRequest());
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.deleteBookingTemplatesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_BOOKING_TEMPLATES_ERROR_TOAST);
  }
}

function* uploadAvatarFileSaga({ payload }: any) {
  try {
    const path: string = yield select(authentificationSelectors.selectTenantFileFolderPath);
    const imageLink: string = yield call(uploadPublicFile, payload.file, path);
    yield put(bookingTemplatesActions.uploadAvatarFileSuccess({ imageType: payload.imageType, imageLink }));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.uploadAvatarFileFail(error));
  }
}

function* uploadBackgroundFileSaga({ payload }: any) {
  try {
    const path: string = yield select(authentificationSelectors.selectTenantFileFolderPath);
    const imageLink: string = yield call(uploadPublicFile, payload.file, path);
    yield put(bookingTemplatesActions.uploadBackgroundFileSuccess({ imageType: payload.imageType, imageLink }));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.uploadBackgroundFileFail(error));
  }
}

export function* watchBookingTemplatesSaga() {
  yield takeLatest(BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_REQUEST, getBookingTemplatesSaga);
  yield takeLatest(BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST, getBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_REQUEST, createBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_REQUEST, cloneBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_REQUEST, updateBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST, activateBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_REQUEST, deleteBookingTemplatesSaga);
  yield takeLatest(BookingTemplatesActionTypes.UPLOAD_AVATAR_FILE_REQUEST, uploadAvatarFileSaga);
  yield takeLatest(BookingTemplatesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST, uploadBackgroundFileSaga);
}

export const bookingTemplatesSagas = {
  getBookingTemplates: getBookingTemplatesSaga,
};
