import { t } from '../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  save: t('Common:SAVE'),
  edit: t('Common:EDIT'),
  clone: t('Common:CLONE'),
  delete: t('Common:DELETE'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  activate: t('Common:ACTIVATE'),
  deactivate: t('Common:DEACTIVATE'),
  title: t('BookingTemplates:TITLE'),
  tooltip: t('BookingTemplates:TOOLTIP'),
  locked: t('Common:LOCKED'),
  newBookingTemplate: t('BookingTemplates:NEW_BOOKING_TEMPLATE'),
  inviteeTitlePart1: t('EditBookingTemplate:INVITEE_TITLE_PART_1'),
  inviteeTitlePart2: t('EditBookingTemplate:INVITEE_TITLE_PART_2'),
  inviteeSubTitle: t('EditBookingTemplate:INVITEE_SUBTITLE'),
  whatTitlePart1: t('EditBookingTemplate:WHAT_TITLE_PART_1'),
  whatTitlePart2: t('EditBookingTemplate:WHAT_TITLE_PART_2'),
  whoTitlePart1: t('EditBookingTemplate:WHO_TITLE_PART_1'),
  whoTitlePart2: t('EditBookingTemplate:WHO_TITLE_PART_2'),
  whereTitlePart1: t('EditBookingTemplate:WHERE_TITLE_PART_1'),
  whereTitlePart2: t('EditBookingTemplate:WHERE_TITLE_PART_2'),
  whenTitlePart1: t('EditBookingTemplate:WHEN_TITLE_PART_1'),
  whenTitlePart2: t('EditBookingTemplate:WHEN_TITLE_PART_2'),
  additionalTitle: t('EditBookingTemplate:ADDITIONAL_TITLE'),
  notificationsTitlePart1: t('EditBookingTemplate:NOTIFICATIONS_TITLE_PART_1'),
  notificationsTitlePart2: t('EditBookingTemplate:NOTIFICATIONS_TITLE_PART_2'),
  confirmationTitlePart1: t('EditBookingTemplate:CONFIRMATION_TITLE_PART_1'),
  confirmationTitlePart2: t('EditBookingTemplate:CONFIRMATION_TITLE_PART_2'),
  howTitlePart1: t('EditBookingPage:HOW_TITLE_PART_1'),
  howTitlePart2: t('EditBookingPage:HOW_TITLE_PART_2'),
  howDescription: t('EditBookingPage:HOW_DESCRIPTION'),
  calendarTitlePart1: t('EditBookingPage:CALENDAR_TITLE_PART_1'),
  calendarTitlePart2: t('EditBookingPage:CALENDAR_TITLE_PART_2'),
  pageTitlePart1: t('EditBookingPage:PAGE_TITLE_PART_1'),
  pageTitlePart2: t('EditBookingPage:PAGE_TITLE_PART_2'),
};
