export const DefaultBookingTemplate = Object.freeze({
  NAME: `30-Minuten-Meeting`,
  CONFIRMATION_SUBJECT: 'Neues Meeting: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Verschoben: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Erinnerung: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Abgesagt: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Nachverfolgung: <Meeting Name> mit <Host Name>',
  CONFIRMATION_BODY: `Hallo <Invitee Name>,

Ihr neues Meeting <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> ist geplant.

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Hallo <Invitee Name>,

Ihr Meeting <Meeting Name> mit <Host Name> wurde verschoben.

<b>Aktualisierte Zeit und Datum</b>
<Meeting Time>, <Meeting Date>

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Hallo <Invitee Name>,

Dies ist eine freundliche Erinnerung, dass Ihr <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> stattfindet.

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Hallo <Invitee Name>,

Ihr Meeting <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> wurde abgesagt.`,
  FOLLOW_UP_BODY: `Hallo <Invitee Name>,

Wir hoffen, Ihr Meeting "<Meeting Name>" mit <Host Name> war produktiv.

Falls Sie Feedback haben oder weitere Unterstützung benötigen, zögern Sie bitte nicht, uns zu kontaktieren.`,
  SMS_TEXT:
    'Dies ist eine freundliche Erinnerung, dass Ihr <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> stattfindet. Meeting anzeigen oder bearbeiten <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `Neue Vorlage`,
  TITLE: `Buchungsvorlagen`,
  VIEW_DETAILS: `Details anzeigen`,
  MINUTE: `Min`,
  HOUR: `Std`,
  CLONE_MODAL_TITLE: `Buchungsvorlage klonen`,
  DELETE_MODAL_CONFIRM_TEXT: `Sind Sie sicher, dass Sie diese Buchungsvorlage löschen möchten?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `Sind Sie sicher, dass Sie diese Buchungsvorlagen löschen möchten?`,
  DELETE_MODAL_USED_PAGES_TEXT: `Zuerst müssen Sie diese Buchungsvorlagen von den folgenden Buchungsseiten entfernen:`,
  FILTER_MY_TEMPLATES: `Meine Vorlagen`,
  FILTER_ALL_TEMPLATES: `Alle Vorlagen`,
  TOOLTIP: `Erstellen Sie Buchungsvorlagen für Buchungsseiten.`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Klonen der Buchungsvorlage fehlgeschlagen`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Buchungsvorlage erfolgreich geklont`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Das Entfernen der Buchungsvorlage ist fehlgeschlagen`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Buchungsvorlage erfolgreich entfernt`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `Buchungsvorlage konnte nicht abgerufen werden`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Buchungsvorlage konnte nicht gespeichert werden`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Buchungsvorlage erfolgreich gespeichert`,
});

export const EditBookingTemplate = Object.freeze({
  ADDITIONAL_TITLE: `Zusätzliche Optionen`,
  WHAT_TITLE_PART_1: `Was`,
  WHAT_TITLE_PART_2: ` für ein Ereignis ist dies?`,
  WHO_TITLE_PART_1: `Wer`,
  WHO_TITLE_PART_2: `kann teilnehmen?`,
  WHERE_TITLE_PART_1: `Wo`,
  WHERE_TITLE_PART_2: ` wird dieses Ereignis stattfinden?`,
  WHEN_TITLE_PART_1: `Wann`,
  WHEN_TITLE_PART_2: ` können Menschen dieses Ereignis buchen?`,
  INVITEE_TITLE_PART_1: `Eingeladener `,
  INVITEE_TITLE_PART_2: `Fragen`,
  INVITEE_SUBTITLE: `Name, E-Mail, Benutzerdefinierte Fragen`,
  NOTIFICATIONS_TITLE_PART_1: `Benachrichtigungen`,
  NOTIFICATIONS_TITLE_PART_2: ``,
  CONFIRMATION_TITLE_PART_1: `Bestätigungs-`,
  CONFIRMATION_TITLE_PART_2: `Seite`, // & Stornierungsrichtlinien
  LOCKED_TOOLTIP: `Datensatz gesperrt. Sie haben keine Berechtigung zur Bearbeitung. Zuletzt geändert von:`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  TEAMS: `Potenzielle Teams`,
  HOSTS: `Moderatoren`,
  HOSTS_TOOLTIP: `Wählen Sie einen oder mehrere Gastgeber für diese Buchungsvorlage aus. Wenn Sie mehrere Gastgeber auswählen, werden diese zum gebuchten Meeting hinzugefügt.`,
  RESCHEDULE_WITH: `Neu planen mit`,
  RESCHEDULE_WITH_TOOLTIP: `Wenn der Eingeladene die Besprechung neu plant, mit wem sollte SUMO sie neu planen:`,
  ASSIGNED_HOST: `Zugewiesener Moderator`,
  ROUND_ROBIN_TO_NEW_HOST: `Round-Robin zu neuem Moderator`,
  POTENTIAL_HOSTS: `Potenzielle Gastgeber`,
  HOST_VIDEO_CONFERENCE: `Host-Videokonferenz`,
  HOST_LIST_REQUIREMENTS: `Sie sollten in der Gastgeberliste stehen, Sie haben keine Berechtigung für alle Buchungsseiten.`,
  HOSTS_LABEL: `Wählen Sie eine Person, mehrere Personen oder ein Team (bei dem ein Mitglied über Round-Robin ausgewählt wird), um an diesem Meeting teilzunehmen.`,
  HOSTS_PLACEHOLDER: `Hosts oder Teams suchen`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Wählen Sie eine oder mehrere Optionen aus, die dem Eingeladenen bei der Selbstplanung auf der Buchungsseite angezeigt werden sollen:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Überspringen Sie den "Wo"-Schritt für virtuelle Besprechungen`,
  SKIP_WHERE_STEP_DESCRIPTION: `Der Video-Konferenzlink wird automatisch zur Besprechung hinzugefügt.`,
  PHONE_CALL: `Telefonanruf`,
  HOSTS_PHONE: `Telefonnummer des Moderators`,
  HOSTS_PHONE_DESCRIPTION: `Die Telefonnummer des Moderators wird automatisch zur Besprechung hinzugefügt.`,
  HOSTS_PHONE_NOTIFICATION: `Ein oder mehrere Gastgeber haben keine Telefonnummer`,
  INVITEE_PHONE: `Den Eingeladenen auffordern, ihre Telefonnummer einzugeben`,
  INVITEE_PHONE_DESCRIPTION: `Der Eingeladene wird aufgefordert, seine Telefonnummer hinzuzufügen.`,
  CUSTOM_PHONE: `Benutzerdefinierte Telefonnummer`,
  CUSTOM_PHONE_PLACEHOLDER: `Telefonnummer eingeben`,
  CUSTOM_PHONE_DESCRIPTION: `Diese Telefonnummer wird automatisch zur Besprechung hinzugefügt.`,
  IN_PERSON: `Persönlich`,
  CUSTOM_ADDRESS: `Benutzerdefinierte Adresse`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Meeting-Adresse eingeben`,
  CUSTOM_ADDRESS_DESCRIPTION: `Diese Adresse wird automatisch zur Besprechung hinzugefügt.`,
  LOCATION: `Firmenstandort`,
  LOCATION_DESCRIPTION: `Der Eingeladene wird aufgefordert, einen dieser Standorte auszuwählen.`,
  INVITEE_LOCATION: `Standort des Eingeladenen`,
  INVITEE_LOCATION_DESCRIPTION: `Der Eingeladene wird aufgefordert, eine Meeting-Adresse hinzuzufügen.`,
  DEFAULT_LOCATION: 'Standardort',
  VIDEO_CONFERENCE: `Videokonferenz`,
  VIDEO_CONFERENCE_NOTIFICATION: `Ein oder mehrere Gastgeber haben keine Videokonferenz`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Vollständiger Name`,
  EMAIL_LABEL: `E-Mail`,
  ADD_QUESTION_BUTTON: `Neue Frage hinzufügen`,
  QUESTION_TITLE_NEW: `Neue Frage`,
  QUESTION_TITLE_EDIT: `Frage bearbeiten`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  DESCRIPTION: `Fügen Sie eine Frage hinzu, die auf der Buchungsseite angezeigt wird und die der Gast ausfüllen muss, wenn er das Meeting bucht.`,
  QUESTION_LABEL: `Frage`,
  REQUIRED_LABEL: `Erforderlich`,
  ACTIVE_LABEL: `Aktiv`,
  TYPE_LABEL: `Antworttyp`,
  TYPE_CHECKBOXES: `Kontrollkästchen`,
  TYPE_RADIO_BUTTONS: `Radioschaltflächen`,
  TYPE_SELECT: `Dropdown`,
  TYPE_TEL: `Telefonnummer`,
  TYPE_TEXT: `Einzeilig`,
  TYPE_TEXTAREA: `Mehrzeilig`,
  ANSWERS_LABEL: `Antworten`,
  ANSWERS_SINGLE_DESCRIPTION_1: `Der Gast kann `,
  ANSWERS_SINGLE_DESCRIPTION_2: `eine`,
  ANSWERS_SINGLE_DESCRIPTION_3: ` der folgenden Optionen auswählen`,
  ANSWERS_MULTIPLE_DESCRIPTION_1: `Der Gast kann eine oder `,
  ANSWERS_MULTIPLE_DESCRIPTION_2: `mehrere`,
  ANSWERS_MULTIPLE_DESCRIPTION_3: ` der folgenden Optionen auswählen`,
  ANSWER_PLACEHOLDER: `Antwort`,
  ADD_ANSWER_BUTTON: `Antwort hinzufügen`,
  DELETE_BUTTON: `Löschen`,
  CANCEL_BUTTON: `Abbrechen`,
  SAVE_BUTTON: `Speichern`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `E-Mail-Bestätigungen`,
  CONFIRMATION_DESCRIPTION: `Es wird immer ein Termin im Kalender des SUMO1-Hosts erstellt, und der Gast wird als Teilnehmer hinzugefügt.
Durch Aktivieren dieser Funktion sendet SUMO1 auch eine Bestätigung per E-Mail.`,
  RESCHEDULE_TITLE: `E-Mail-Neuplanung`,
  RESCHEDULE_DESCRIPTION: `Der Gast erhält eine E-Mail-Benachrichtigung über alle Änderungen an seiner Besprechung.`,
  REMINDER_TITLE: `E-Mail-Erinnerungen`,
  REMINDER_DESCRIPTION: `Der Gast erhält eine Erinnerungs-E-Mail vor dem gebuchten Meeting.`,
  CANCELATION_TITLE: `E-Mail-Stornierungen`,
  CANCELATION_DESCRIPTION: `Es wird eine E-Mail-Benachrichtigung an Ihren Gast gesendet, wenn Sie das Meeting stornieren.`,
  FOLLOW_UP_TITLE: `E-Mail-Nachverfolgung`,
  FOLLOW_UP_DESCRIPTION: `Automatisieren Sie die Nachverfolgung der nächsten Schritte mit einer E-Mail, die nach dem Meeting gesendet wird.`,
  SMS_TITLE: `SMS-Texterinnerung`,
  SMS_DESCRIPTION: `Der Gast hat die Möglichkeit, vor einem gebuchten Meeting SMS-Erinnerungen zu erhalten.`,
  SMS_BODY: `SMS-Text`,
  SMS_LABEL_PART1: `Nur U.S. und Kanada`,
  SMS_LABEL_PART2: `Derzeit sind Texterinnerungen kostenlos und nur für Teilnehmernummern in den USA und Kanada verfügbar.`,
  PERSONALIZE: `Personalisieren`,
  EMAIL_SUBJECT: `Betreff der E-Mail`,
  EMAIL_BODY: `E-Mail-Text`,
  MERGE_VARIABLES: `Variablen zusammenführen`,
  TIMING: `Zeitpunkt`,
  RESET: `Zurücksetzen`,
  ADD_ANOTHER_REMINDER: `Weitere Erinnerung hinzufügen`,
  CANCELATION_POLICY: `Stornierungsrichtlinie`,
  CANCELATION_POLICY_TOOLTIP: `Jeder Text, den Sie hier eingeben, wird in allen per E-Mail an den Gast gesendeten Benachrichtigungen angezeigt.`,
  TIME_UNIT_DAYS_BEFORE: `Tag(e) vorher`,
  TIME_UNIT_HOURS_BEFORE: `Stunde(n) vorher`,
  TIME_UNIT_MINUTES_BEFORE: `Minute(n) vorher`,
  TIME_UNIT_DAYS_AFTER: `Tag(e) später`,
  TIME_UNIT_HOURS_AFTER: `Stunde(n) später`,
  TIME_UNIT_MINUTES_AFTER: `Minute(n) später`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `Nach der Buchung`,
  TYPE_DISPLAY_SUMO: `SUMO Bestätigungsseite anzeigen`,
  TYPE_DISPLAY_EXTERNAL: `Weiterleitung zu einer externen Seite`,
  EXTERNAL_LINK_LABEL: `Externe Weiterleitungs-URL`,
  EXTERNAL_LINK_PLACEHOLDER: `https://weiterleitung-hier.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Schaltfläche "Weitere Buchung" anzeigen`,
  ALLOW_RESCHEDULE_LABEL: `Neu planen erlauben`,
  ALLOW_CANCEL_LABEL: `Stornieren erlauben`,
  ADD_CUSTOM_LINK_LABEL: `Benutzerdefinierten Link hinzufügen`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Fügen Sie der Bestätigungsseite einen benutzerdefinierten Link hinzu, wie z.B. einen Link zu einer Umfrage.`,
  ADD_CUSTOM_LINK_TITLE: `Benutzerdefinierten Link hinzufügen`,
  EDIT_CUSTOM_LINK_TITLE: `Benutzerdefinierten Link bearbeiten`,
  CUSTOM_LINK_LABEL: `Beschriftung des benutzerdefinierten Links`,
  CUSTOM_LINK_PLACEHOLDER: `Beispiel-Link`,
  LINK_URL_LABEL: `Link-URL`,
  LINK_URL_PLACEHOLDER: `https://beispiel-link.com`,
});
