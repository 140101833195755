import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import labels from './labels';
import { workspacesSelectors } from '../../../store/workspaces';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workspacesActions } from '../../../store/workspaces/actions';
import { userSettingsSelectors } from '../../../store/userSettings';

export const WorkspaceWhatStep = () => {
  const dispatch = useDispatch();
  const name = useSelector(workspacesSelectors.selectName);
  const enabled = useSelector(workspacesSelectors.selectIsActive);
  const isNameDuplicate = useSelector(workspacesSelectors.selectIsNameDuplicate);
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);

  const [localName, setLocalName] = useState(name);

  const handleNameChange = (name: string) => {
    setLocalName(name);
  };

  const handleNameBlur = () => {
    dispatch(workspacesActions.updateWorkspace({ name: localName }));
  };

  const handleEnabledChange = (value: boolean) => {
    dispatch(workspacesActions.updateWorkspace({ isActive: value }));
  };

  return (
    <div>
      <div className="grid p-fluid">
        <div className="col-12 md:col-6">
          <label htmlFor="workspaceName">{labels.name}</label>
          <InputText
            id="workspaceName"
            type="text"
            value={localName || ''}
            onChange={(e) => handleNameChange(e.target.value.trimStart())}
            onBlur={handleNameBlur}
            className={`${!localName && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
            disabled={isWorkspacesReadOnly}
          />
          {isNameDuplicate && <div className="text-red-500 mt-2">{labels.duplicate}</div>}
        </div>

        <div className="col-12 md:col-6 flex md:justify-content-center">
          <div className="flex flex-column">
            <label>{labels.active}</label>
            <InputSwitch
              checked={!!enabled}
              onChange={(e) => handleEnabledChange(!!e.value)}
              disabled={isWorkspacesReadOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
