export const styles = {
  wrapper: {
    width: '980px',
    maxWidth: '980px',
    margin: '10px auto 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
};
