import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { globalActions } from '../../../store/global/actions';
import { EventSteps, eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { Label } from '../../common';

export const PublicBookingPageButtons = () => {
  const dispatch = useDispatch();
  const bookingPageId = useSelector(eventSelectors.selectBookingPageId);
  const isPreviewMode = useSelector(eventSelectors.selectIsPreviewMode);
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const isExisting = useSelector(eventSelectors.selectIsExisting);
  const bookButtonLabel = useSelector(eventSelectors.selectBookButtonLabel);
  const updateButtonLabel = useSelector(eventSelectors.selectUpdateButtonLabel);
  const bookAnotherButtonLabel = useSelector(eventSelectors.selectBookAnotherButtonLabel);
  const cancelButtonLabel = useSelector(eventSelectors.selectCancelButtonLabel);
  const showBookButton = useSelector(eventSelectors.selectShowBookButton);
  const showConfirmCancelButton = useSelector(eventSelectors.selectShowConfirmCancelButton);
  const showBookAnotherButton = useSelector(eventSelectors.selectShowBookAnotherButton);
  const showCancelButton = useSelector(eventSelectors.selectShowCancelButton);
  const buttonStyle = useSelector(eventSelectors.selectButtonStyle);
  const outlinedButtonStyle = useSelector(eventSelectors.selectOutlinedButtonStyle);
  const isSaveAvailable = useSelector(eventSelectors.selectIsSaveAvailable);
  const viewSettings = useSelector(eventSelectors.selectViewSettings);

  const handleBookMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    // TODO: remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch<any>(eventActions.saveEventThunk());
  };

  const handleCancelMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    dispatch(eventActions.setEventStep(EventSteps.CANCEL));
  };

  const handleConfirmCancelMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    dispatch(eventActions.cancelEventRequest());
  };

  const handleBookAnotherMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    if (bookingPageId) {
      dispatch(globalActions.resetWholeStore());
      dispatch(eventActions.setBookingPageId(bookingPageId));
      dispatch(eventActions.setViewSettings(viewSettings));
      dispatch(eventActions.getAgendaRequest());
    }
  };

  const handleBookLabelChange = (value: string) => {
    dispatch(
      eventActions.setPreviewBookingPageHow(isExisting ? { updateButtonLabel: value } : { bookButtonLabel: value })
    );
  };

  const handleCancelLabelChange = (cancelButtonLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ cancelButtonLabel }));
  };

  const handleBookAnotherLabelChange = (bookAnotherButtonLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ bookAnotherButtonLabel }));
  };

  return (
    <>
      {showBookButton && (
        <div className="flex justify-content-center pt-5">
          <Button
            style={buttonStyle}
            className={`w-12 md:w-5 lg:w-4 justify-content-center`}
            disabled={!isSaveAvailable && !isPreviewMode}
            onClick={handleBookMeeting}
          >
            <Label
              textClassName="text-center"
              inputClassName={`py-2 -my-2 text-white`}
              editable={isEditable}
              value={isExisting ? updateButtonLabel : bookButtonLabel}
              onBlurUpdate={handleBookLabelChange}
            />
          </Button>
        </div>
      )}

      {showConfirmCancelButton && (
        <div className="flex justify-content-center pt-5">
          <Button
            style={buttonStyle}
            className={`w-12 md:w-5 lg:w-4 justify-content-center`}
            onClick={handleConfirmCancelMeeting}
          >
            <Label
              textClassName="text-center"
              inputClassName={`py-2 -my-2 text-white`}
              editable={isEditable}
              value={cancelButtonLabel}
              onBlurUpdate={handleCancelLabelChange}
            />
          </Button>
        </div>
      )}

      {(showCancelButton || showBookAnotherButton) && (
        <div className="flex flex-wrap justify-content-between gap-3 pt-5">
          {showCancelButton && (
            <div className="w-12 md:w-5 lg:w-4">
              <Button
                style={outlinedButtonStyle}
                className={`w-full bg-white justify-content-center`}
                outlined
                onClick={handleCancelMeeting}
              >
                <Label
                  textClassName="text-center"
                  inputClassName={`py-2 -my-2 text-primary`}
                  editable={isEditable}
                  value={cancelButtonLabel}
                  onBlurUpdate={handleCancelLabelChange}
                />
              </Button>
            </div>
          )}

          {showBookAnotherButton && (
            <div className="w-12 md:w-5 lg:w-4 ml-auto">
              <Button
                style={buttonStyle}
                className={`w-full justify-content-center`}
                onClick={handleBookAnotherMeeting}
              >
                <Label
                  textClassName="text-center"
                  inputClassName={`py-2 -my-2 text-white`}
                  editable={isEditable}
                  value={bookAnotherButtonLabel}
                  onBlurUpdate={handleBookAnotherLabelChange}
                />
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
