import { combineReducers } from 'redux';
import { DEFAULT_INTEGRATION_STATE } from './constants';
import { IntegrationAction, IntegrationActionTypes } from './actions';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { IntegrationType } from '../../API';
import { IntegrationFilter } from './types';

const integration = (state = DEFAULT_INTEGRATION_STATE, action: IntegrationAction | GlobalAction) => {
  switch (action.type) {
    case IntegrationActionTypes.SET_DEFAULT_INTEGRATIONS:
      return DEFAULT_INTEGRATION_STATE;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_INTEGRATION_STATE;
    default:
      return state;
  }
};

const connectIntegration = (state: IntegrationType | null = null, action: IntegrationAction | GlobalAction) => {
  switch (action.type) {
    case IntegrationActionTypes.SET_CONNECT_INTEGRATION_TYPE:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const filter = (state: IntegrationFilter = IntegrationFilter.ALL, action: IntegrationAction | GlobalAction) => {
  switch (action.type) {
    case IntegrationActionTypes.SET_INTEGRATION_FILTER:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return IntegrationFilter.ALL;
    default:
      return state;
  }
};

export default combineReducers({ integration, connectIntegration, filter });
