import { CreateStaffInput, CreateTenantInput } from '../../API';
import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import { UserSettingsKeys } from '../userSettings/constants';
import { ThirdPartyType, UserDataCoreType } from './typings';

export const EXPIRATION_PERIOD_DAYS = 15;
export const USER_WARNING_DAYS = 3;
// export const DEFAULT_LINK = 'myname';
// export const LICENSE_IS_ACTIVE = 'licenseIsActive';
// export const LICENSE_LAST_CHECK = 'licenseLastCheck';
// export const LICENSE_END_DATE = 'licenseEndDate';
// export const LICENSE_TYPE = 'licenseType';
export const ENCRYPTED_USER_ID = 'encryptedUserId';
export const USER_CONTEXT_PARAM = 'userContext';

const userId = localStorage.getItem(UserSettingsKeys.USER_ID) || '';
const tenantId = localStorage.getItem(UserSettingsKeys.TENANT_ID) || '';
const link = localStorage.getItem(UserSettingsKeys.LINK) || '';
const workspaceId = localStorage.getItem(UserSettingsKeys.WORKSPACE_ID) || '';

export const DEFAULT_USER_DATA_CORE: UserDataCoreType = {
  userId,
  tenantId,
  link,
  workspaceId,
};
export const THIRD_PARTY = 'thirdParty';
export const defaultThirdParty = (localStorage.getItem(THIRD_PARTY) as ThirdPartyType) || null;
// const licenseIsActive = localStorage.getItem(LICENSE_IS_ACTIVE) === 'true';
// const licenseLastCheck = localStorage.getItem(LICENSE_LAST_CHECK) || '';
// const licenseEndDate = localStorage.getItem(LICENSE_END_DATE) || '';
// const licenseType = (localStorage.getItem(LICENSE_TYPE) as LicenseType) || LicenseType.TRIAL;
// export const DEFAULT_LICENSE_DATA: LicenseDataType = {
//   isActive: licenseIsActive,
//   type: licenseType,
//   lastCheck: licenseLastCheck,
//   endDate: licenseEndDate,
// };

export const EMPTY_SUMO1_ADMIN: CreateStaffInput = {
  email: '',
  userId: null,
  userName: null,
  roleName: null,
  status: null,
};

export const DEFAULT_SUMO1_DATA: CreateStaffInput = JSON.parse(
  localStorage.getItem(UserSettingsKeys.SUMO1_STAFF_DATA) || JSON.stringify(EMPTY_SUMO1_ADMIN)
);

export const EMPTY_USER_DATA_CORE = {
  userId: '',
  tenant: '',
  link: '',
  workspaceId: '',
};
// export const EMPTY_LICENSE_DATA: LicenseDataType = {
//   isActive: false,
//   type: LicenseType.TRIAL,
//   lastCheck: '',
//   endDate: '',
// };

export const DEFAULT_TENANT_INFO: CreateTenantInput = {
  tenantId: '',
};

export const GET_USER_AUTH_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:GET_USER_AUTH_ERROR_MESSAGE'),
});
export const GET_USER_LOGIN_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:GET_USER_LOGIN_ERROR_MESSAGE'),
});
export const GET_USER_LOGOUT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:GET_USER_LOGOUT_ERROR_MESSAGE'),
});

export const DELETE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('AuthentificationToastsNotifications:DELETE_USER_SUCCESS_MESSAGE'),
});
export const DELETE_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:DELETE_USER_ERROR_MESSAGE'),
});

export const CHOOSE_AN_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:CHOOSE_AN_ACCOUNT_ERROR_TOAST'),
});

export const GET_MAIN_DATA_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:GET_MAIN_DATA_ERROR_MESSAGE'),
});
export const ROLE_DEACTIVATED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:ROLE_DEACTIVATED_ERROR_MESSAGE'),
});
export const USER_DEACTIVATED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:USER_DEACTIVATED_ERROR_MESSAGE'),
});
export const TRIAL_LIMIT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:TRIAL_LIMIT_ERROR_MESSAGE'),
});
export const SWITCH_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('AuthentificationToastsNotifications:SWITCH_ACCOUNT_ERROR_MESSAGE'),
});

export const INVITE_EXPIRED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 10000,
  message: t('AuthentificationToastsNotifications:INVITE_EXPIRED_ERROR_TITLE'),
  isInviteExpired: true
});
