import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import sumoLogo from '../../../assets/images/sumoLogo.svg';
import { Path } from '../../../routing';
import { languageOptions } from '../../../services/utils';
import { navigationService } from '../../../services/NavigationService';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { useDispatch } from 'react-redux';
import { authentificationActions } from '../../../store/authentification';
import { errorActions } from '../../../store/error/actions';

const AppTopbarUnauth = forwardRef((props, ref) => {
  const menubuttonRef = useRef(null);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const handleLogoClick = () => {
    dispatch(authentificationActions.logoutUserRequest(Path.Landing));
    dispatch(errorActions.resetTheError());
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    window.location.reload();
  };

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
  }));

  return (
    <div className="layout-topbar justify-content-center">
      <div className="flex justify-content-between" style={{ width: 'min(100%, 980px + 2rem)' }}>
        <div className="topbar-right flex">
          <img src={sumoLogo} className="h-3rem cursor-pointer" onClick={handleLogoClick} />
        </div>
        <div className="topbar-left flex gap-2">
          <i className="pi pi-globe text-xl" />
          <Dropdown
            value={i18n.language}
            onChange={(event) => handleLanguageChange(event.target.value)}
            options={languageOptions}
            placeholder="English"
          />
          <Link to={urlConstants.KNOWLEDGE_BASE_URL} target="_blank" className="-mb-1 text-color">
            <i className="pi pi-question-circle text-xl" />
          </Link>
        </div>
      </div>
    </div>
  );
});
AppTopbarUnauth.displayName = 'AppTopbar';

export default AppTopbarUnauth;
