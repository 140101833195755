import { CreateAdminDataInput } from '../../API';
import { t } from '../../i18n/i18n';
import { adminDataStatus, adminRoles } from '../../shared/backend/constants';
import { ToastNotificationOptions } from '../notifications';

export const DEFAULT_INVITE_DATA: CreateAdminDataInput = {
  email: '',
  tenantId: '',
  workspaceIds: [],
  roleId: '',
  status: '',
  team: [],
  expirationDate: null,
};

export const InviteUsersTemplateLabels = ['email', 'role', 'team', 'bookingTemplate'];
export const INVITE_SEND_STATUS = 'Invite Sent';

export const roleList = [adminRoles.superAdmin, adminRoles.workspaceAdmin, adminRoles.standardUser];
export const sumoAdminList = [adminRoles.sumoAdmin];

export const defaultExpirationDays = 30;

export const potentialExpirationDays = {
  days_30: 30,
  days_20: 20,
  days_15: 15,
  days_10: 10,
};

export const StatusLabels = {
  [adminDataStatus.inviteSent]: t('Users:STATUS_INVITE_SENT'),
  [adminDataStatus.active]: t('Users:STATUS_ACTIVE'),
  [adminDataStatus.inactive]: t('Users:STATUS_INACTIVE'),
};

export const INVITE_USERS_EMAIL_INVALID: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('UsersToastsNotifications:INVITE_USERS_EMAIL_INVALID'),
});

export const INVITE_USERS_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('UsersToastsNotifications:INVITE_USERS_SUCCESS_TOAST'),
});

export const INVITE_USERS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('UsersToastsNotifications:INVITE_USERS_ERROR_TOAST'),
});

export const EDIT_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('UsersToastsNotifications:EDIT_USER_SUCCESS_TOAST'),
});

export const EDIT_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('UsersToastsNotifications:EDIT_USER_ERROR_TOAST'),
});

export const DISABLE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  title: t('UsersToastsNotifications:DISABLE_USER_TOAST_HEADER'),
  autoHideDuration: 4000,
  message: t('UsersToastsNotifications:DISABLE_USER_SUCCESS_TOAST'),
});

export const DISABLE_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('UsersToastsNotifications:DISABLE_USER_ERROR_TOAST'),
});

export const ENABLE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  title: t('UsersToastsNotifications:ENABLE_USER_TOAST_HEADER'),
  autoHideDuration: 4000,
  message: t('UsersToastsNotifications:ENABLE_USER_SUCCESS_TOAST'),
});

export const DELETE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 4000,
  title: t('UsersToastsNotifications:DELETE_USER_TOAST_HEADER'),
  message: t('UsersToastsNotifications:DELETE_USER_SUCCESS_TOAST'),
});

export const DELETE_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  title: t('UsersToastsNotifications:DELETE_USER_TOAST_HEADER'),
  message: t('UsersToastsNotifications:DELETE_USER_ERROR_TOAST'),
});

export const GET_USERS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('UsersToastsNotifications:GET_USERS_ERROR_MESSAGE'),
});

export const BUY_LICENSE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 6000,
  title: t('Billing:BUY_LICENSE_SUCCESS_TOAST'),
  message: t('Billing:ADD_SEATS_SUCCESS_TOAST'),
});

// export const GET_LICENSES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
//   color: 'error',
//   autoHideDuration: 6000,
//   message: t('UsersToastsNotifications:GET_LICENSES_ERROR_MESSAGE'),
// });
