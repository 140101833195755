import { Button } from 'primereact/button';
import labels from './labels';
import { useSelector } from 'react-redux';
import {
  STAFF_ROLE_OPTIONS,
  STAFF_STATUS_OPTIONS,
  deactivateStaffModalActions,
  deactivateStaffModalSelectors,
  staffActions,
  staffSelectors,
} from '../../../store/opsConsole/staff';
import { Path } from '../../../routing';
import { useEffect, useState } from 'react';
import { ConfirmationModal, Preloader, SectionTitle } from '../../../components/common';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { StaffRoles, StaffStatus } from '../../../API';
import { authentificationSelectors } from '../../../store/authentification';
import { navigationService } from '../../../services/NavigationService';

export const StaffDetails = () => {
  const dispatch = useDispatch();
  const staffRecord = useSelector(staffSelectors.selectStaffRecord);
  const lastActive = useSelector(staffSelectors.selectLastActive);
  const isFetching = useSelector(staffSelectors.selectIsFetching);
  const isEdited = useSelector(staffSelectors.selectIsEdited);
  const isAddNew = useSelector(staffSelectors.selectIsAddNew);
  const isEmailValid = useSelector(staffSelectors.selectIsEmailValid);
  const isDuplicateEmail = useSelector(staffSelectors.selectIsDuplicateEmail);
  const staffName = useSelector(staffSelectors.selectStaffNameOrEmail);
  const myStaffRole = useSelector(authentificationSelectors.selectSumo1AdminRole);

  const userId = useSelector(authentificationSelectors.selectUserId);

  const deactivateModalOpen = useSelector(deactivateStaffModalSelectors.selectIsModalOpen);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [localName, setLocalName] = useState(staffRecord.userName);
  const [localEmail, setLocalEmail] = useState(staffRecord.email);
  const [oldStaffStatus, setOldStaffStatus] = useState(staffRecord.status);

  useEffect(() => {
    if (!Object.keys(staffRecord).length) {
      navigateBack();
    }
  }, []);

  useEffect(() => {
    setLocalName(staffRecord.userName);
  }, [staffRecord.userName]);

  useEffect(() => {
    setLocalEmail(staffRecord.email);
  }, [staffRecord.email]);

  useEffect(() => {
    setOldStaffStatus(staffRecord.status);
  }, [staffRecord.email]);

  const navigateBack = () => {
    navigationService.navigateTo(Path.OPSConsoleStaff);
  };

  const handleBack = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      navigateBack();
    }
  };

  const handleSave = () => {
    if (!isAddNew && oldStaffStatus === StaffStatus.ACTIVE && staffRecord.status === StaffStatus.INACTIVE) {
      dispatch(deactivateStaffModalActions.openModal());
    } else {
      dispatch(staffActions.saveStaffRequest());
    }
  };

  const handleStaffRoleChange = (roleName: StaffRoles) => {
    dispatch(staffActions.updateStaffRecord({ roleName }));
  };

  const handleStaffStatusChange = (status: StaffStatus) => {
    dispatch(staffActions.updateStaffRecord({ status }));
  };

  const handleNameChange = (userName: string) => {
    setLocalName(userName);
  };

  const handleNameBlur = () => {
    dispatch(staffActions.updateStaffRecord({ userName: localName }));
  };

  const handleEmailChange = (email: string) => {
    setLocalEmail(email);
  };

  const handleEmailBlur = () => {
    dispatch(staffActions.updateStaffRecord({ email: localEmail }));
  };

  const handleDeactivateClose = () => {
    dispatch(deactivateStaffModalActions.closeModal());
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        cancelButtonProps={{ className: 'w-auto mr-0' }}
        confirmButtonProps={{ className: 'w-auto ml-4 mr-0' }}
        onConfirm={navigateBack}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle
          id="staffDetailsTitle"
          icon="pi-users"
          title={labels.title}
        />
      </div>
      <div className="card">
        <div className="flex justify-content-between align-items-center mb-3">
          <div className="flex align-items-center">
            <Button label={labels.back} icon="pi pi-arrow-left" onClick={handleBack} outlined />
            <div className="ml-3 text-xl">{isAddNew ? `Add new member` : `${localName} (${staffRecord.email})`}</div>
          </div>
          <div className="flex align-items-center">
            <Button
              label={labels.save}
              onClick={handleSave}
              className="w-6rem"
              disabled={!isEdited || !isEmailValid || isDuplicateEmail}
            />
          </div>
        </div>
        <div className="border-1 border-round p-4">
          <div className="grid justify-content-between">
            <div className="col-12 md:col-5 p-fluid">
              <label htmlFor="userName">{labels.name}</label>
              <InputText
                value={localName || ''}
                id="userName"
                onChange={(e) => handleNameChange(e.target.value)}
                onBlur={handleNameBlur}
                disabled={isAddNew}
              />
            </div>
            <div className="col-12 md:col-5 p-fluid">
              <label htmlFor="email">{labels.email}</label>
              <InputText
                value={localEmail}
                id="email"
                onChange={(e) => handleEmailChange(e.target.value)}
                onBlur={handleEmailBlur}
                disabled={!isAddNew}
                className={!isEmailValid || isDuplicateEmail ? 'p-invalid' : ''}
              />
              {isDuplicateEmail && (
                <small id="email-help" className="p-error block">
                  {labels.duplicateEmail}
                </small>
              )}
            </div>
            <div className="col-12 md:col-5 p-fluid">
              <label htmlFor="role">{labels.role}</label>
              <Dropdown
                value={staffRecord.roleName}
                options={myStaffRole === StaffRoles.OPERATIONS ? STAFF_ROLE_OPTIONS.filter(role => role.value !== StaffRoles.MAIN_ADMIN) : STAFF_ROLE_OPTIONS}
                id="role"
                onChange={(e: DropdownChangeEvent) => handleStaffRoleChange(e.value)}
                disabled={staffRecord.userId === userId}
              />
            </div>
            <div className="col-12 md:col-5 p-fluid">
              <label htmlFor="status">{labels.status}</label>
              <Dropdown
                value={staffRecord.status}
                options={STAFF_STATUS_OPTIONS}
                id="status"
                onChange={(e) => handleStaffStatusChange(e.target.value)}
                disabled={staffRecord.userId === userId}
              />
            </div>
            <div className="col-12 md:col-5 col-offset-0 md:col-offset-7 p-fluid">
              <label htmlFor="lastActive">{labels.lastActive}</label>
              <InputText value={lastActive} id="lastActive" disabled />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateMessage} "${staffName}"?`}
        visible={deactivateModalOpen}
        onClose={handleDeactivateClose}
        onCancel={handleDeactivateClose}
        onConfirm={() => {
          handleDeactivateClose();
          dispatch(staffActions.saveStaffRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
    </div>
  );
};
