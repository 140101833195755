import { CodeResponse as GoogleCodeResponse } from '@react-oauth/google';
import { CreateAdminDataInput, CreateStaffInput } from '../../API';

export interface UserDataCoreType {
  userId: string;
  tenantId: string;
  workspaceId: string;
  link: string;
}

export enum ThirdPartyType {
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export type ThirdPartyAuthPayload = {
  type: ThirdPartyType;
  codeResponse: CodeThirdPartyResponse;
  userTimeZone?: string;
  switchAccount: boolean;
  updateIntegration?: boolean;
  integrationType: string,
  inviteTenantId?: string | null | undefined;
  login: boolean;
};

export type MicrosoftCodeResponse = {
  code: string;
  redirectUrl: string;
};

export type CodeThirdPartyResponse = GoogleCodeResponse | MicrosoftCodeResponse;

// export type LicenseDataType = CreateLicenseInput & {remaining: number};

// export type LicenseDataType = {
//   isActive: boolean;
//   type: LicenseType;
//   lastCheck: string;
//   endDate: string;
// };

export type InviteToAccount = {
  newTenant: CreateAdminDataInput;
  existingTenant: CreateAdminDataInput;
};

export type ThirdPartyLambdaResponse = {
  isNew: boolean;
  isQuickSetupFinished: boolean;
  adminDataRecords: CreateAdminDataInput[];
  email: string;
  fullName: string;
  secret: string;
  isSumoAdmin: boolean;
  staffRecord: CreateStaffInput;
  lastSuperAdmin: boolean;
  inviteExpired: boolean;
};

export type ViewAsUserPayload = {
  tenantId: string;
  userId: string;
};

export type ResetViewAsUserPayload = {
  redirect?: boolean;
  removeLicenseType?: boolean;
};

export type ThirdPartySettings = {
  access_token: string,
  email: string,
  expiry_date: string,
  id_token: string,
  refresh_token: string,
  scope: string,
  token_type: string
}

export type CreateAdminDataType = Omit<CreateAdminDataInput, 'updatedAt'> & {
  updatedAt?: string;
};
