export const Account = Object.freeze({
  MY_ROLE_TITLE: `My Role`,
  ROLE_DESCRIPTION: `Your current role - `,
  TITLE: `Account Settings`,
});

export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `You may connect only 1 calendar`, // To increase this limit, contact sales to upgrade your license tier.
  DESCRIPTION: `Connect your calendar so SUMO knows when you're available and updates your calendar as appointments are booked.`,
  DISCONNECT_MESSAGE: `Are you sure you would like to disconnect your calendar?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Appointments will not be deleted or canceled.`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016, or 2019`,
  EXCHANGE_CALENDAR_TITLE: `Exchange Calendar`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com, or Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Office 365 Calendar`,
  SELECT_CALENDAR_TITLE: `Select Calendar`,
  TITLE: `Calendar Connections`,
  TOOLTIP: `Connect your Google or Microsoft calendar to SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Agree and Close`,
  DESCRIPTION: `When you visit a website, it has the ability to store or retrieve certain information on your browser. This information, often in the form of cookies, can be about your preferences, device, or other details. Its primary purpose is to ensure that the website functions as you would expect. While this information does not typically identify you directly, it can enhance your web browsing experience by personalizing content. We understand and value your right to privacy, which is why we provide you with the option to control and customize your cookie preferences. By clicking on the various category headings, you can learn more about each type of cookie and modify our default settings. Please note that blocking certain types of cookies may impact your overall experience on the site and limit the services we can offer you.`,
  DESCRIPTION_FIRST_PART: `When you visit a website, it has the ability to store or retrieve certain information on your browser. This information, often in the form of cookies, can be about your preferences, device, or other details. Its primary purpose is to ensure that the website functions as you would expect.`,
  DESCRIPTION_SECOND_PART: `While this information does not typically identify you directly, it can enhance your web browsing experience by personalizing content. We understand and value your right to privacy, which is why we provide you with the option to control and customize your cookie preferences.`,
  DESCRIPTION_THIRD_PART: `By clicking on the various category headings, you can learn more about each type of cookie and modify our default settings. Please note that blocking certain types of cookies may impact your overall experience on the site and limit the services we can offer you.`,
  FUNCTIONAL_NAME: `Functional Cookies`,
  MANAGE_TITLE: `Manage Consent Preferences`,
  NECESSARY_ONLY: `Strictly Necessary Only`,
  PERFORMANCE_NAME: `Performance Cookies`,
  STRICTLY_NAME: `Strictly Necessary Cookies`,
  TARGETING_NAME: `Targeting Cookies`,
  TITLE: `Cookie Settings`,
});

export const Login = Object.freeze({
  CONTACT_US: `Please contact support if you need assistance`,
  DESCRIPTION: `You login to SUMO1 with the following connected account`,
  GOOGLE_ACCOUNT: `Google Account`,
  GOOGLE_ACCOUNTS_SWITCH: `Switch Google Accounts`,
  GOOGLE_SWITCH: `Switch to Google Login`,
  GOOGLE_SWITCH_DESC: `Change from Microsoft authentication to Google`,
  MICROSOFT_ACCOUNT: `Microsoft Account`,
  MICROSOFT_ACCOUNTS_SWITCH: `Switch Microsoft Accounts`,
  MICROSOFT_SWITCH: `Switch to Microsoft Login`,
  MICROSOFT_SWITCH_DESC: `Change from Google authentication to Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Your SUMO1 account will lose access to data only available to you through your original provider. You may also need to re-grant access to integrations and calendars.`,
  SWITCH_DESCRIPTION_PART1: `Change from `,
  SWITCH_DESCRIPTION_PART2: ` authentication to `,
  TITLE: `Login`,
});

export const MyLink = Object.freeze({
  LINK: `Link Name`,
  LINK_HELPER_TEXT: `Minimum 3 symbols. Please use only a-z, 0-9 characters and -.&=_'-+,<>`,
  SUBTITLE: `Changing your SUMO1 URL will mean that all of your copied links will no longer work and will updated`,
  TITLE: `My Link`,
});

export const Phone = Object.freeze({
  LABEL: `Phone Number`,
  TITLE: `Phone Number(s)`,
});

export const Profile = Object.freeze({
  DATE_FORMAT: `Date Format`,
  DELETE_ACCOUNT: `Delete Account`,
  DELETE_ACCOUNT_DIALOG_TEXT: `Are you sure you want to delete your account? Your request will be sent to the admin.`,
  DELETE_ACCOUNT_REQUEST_TEXT: `Delete request has been sent to admin.`,
  DELETE_ACCOUNT_REQUEST_TITLE: `Sent`,
  LANGUAGE: `Language`,
  NAME: `Name`,
  WELCOME_MESSAGE: `Welcome Message`,
  PHONE_DETAILS_LABELS: `Phone Details`,
  PHONE_DETAILS_PLACEHOLDER: `Add additional info or instructions here.\r\nEx: I look forward to meeting. SMS Text me anytime.`,
  TIME_FORMAT: `Time Format`,
  TIME_ZONE: `Time Zone`,
  TITLE: `Profile`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: `New calendar request fail`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: `New calendar successfully connected!`,
  CONNECT_ZOOM_ERROR_TOAST: `Zoom connected request fail`,
  CONNECT_ZOOM_SUCCESS_TOAST: `Zoom connected successfully!`,
  GET_USER_SETTINGS_FAIL: `User data not found, please contact to support`,
  SAVE_USER_SETTINGS_ERROR_TOAST: `Settings save request fail`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST: `Your settings successfully saved!`,
  UPDATE_USER_LINK_ERROR_MESSAGE: `The link is already taken`,
  UPDATE_USER_LINK_SUCCESS_MESSAGE: `Your link successfully updated!`,
});
