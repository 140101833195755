import { FC, useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { OverridesInput, TimeFromToInput } from '../../../API';
import { userSettingsSelectors } from '../../../store/userSettings';
import {
  AVAILABILITY_OVERRIDE_MODAL_NAME,
  DEFAULT_AVAILABILITY_TIME,
  availabilityOverrideModalActions,
} from '../../../store/availability';
import {
  calcFromToTimeError,
  checkTimeOverlapping,
  formatDateUTC,
  formatDateHHMM,
  formatTimeHHMM,
  isValidDate,
} from '../../../services/DateService';
import { Modal } from '../../common';
import labels from './labels';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { TimeFormat } from '../../../store/userSettings/typings';
import i18n from '../../../i18n/i18n';

interface AvailabilityOverrideProps {
  overrides: Array<OverridesInput | null>;
  onChange: (overrides: Array<OverridesInput | null>) => void;
  readonly?: boolean;
}

export const AvailabilityOverride: FC<AvailabilityOverrideProps> = ({ overrides, onChange, readonly }) => {
  const dispatch = useDispatch();
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  // Modal state and handlers
  const [selectedDate, setSelectedDate] = useState<Date[] | null>(null);
  const [selectedTimes, setSelectedTimes] = useState<TimeFromToInput[]>([DEFAULT_AVAILABILITY_TIME]);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isTimeInvalid, setIsTimeInvalid] = useState(false);
  const [isTimeOverlapping, setIsTimeOverlapping] = useState(false);
  const [isDateOverlapping, setIsDateOverlapping] = useState(false);

  const availableSelectOptions = [
    {
      value: true,
      name: labels.availableText,
    },
    {
      value: false,
      name: labels.unavailableText,
    },
  ];

  const checkDateOverlapping = () => {
    let dateOverlapping = false;
    if (!selectedDate) return false;
    // convert selected date to appropriate string
    const from = selectedDate[0] instanceof Date ? convertDateToString(selectedDate[0]) : '';
    const to = selectedDate[1] instanceof Date ? convertDateToString(selectedDate[1]) : from;
    const days = {
      from: new Date(from).toISOString(),
      to: new Date(to).toISOString(),
    };

    for (let i = 0; overrides && i < overrides.length && !dateOverlapping; i++) {
      const anotherDays = overrides[i]?.days;
      if (!anotherDays?.from || !anotherDays?.to) {
        continue;
      }
      if (
        (days.from >= anotherDays.from && days.from <= anotherDays.to) ||
        (days.to >= anotherDays.from && days.to <= anotherDays.to) ||
        (days.from < anotherDays.from && days.to > anotherDays.to)
      ) {
        dateOverlapping = true;
        break;
      }
    }
    return dateOverlapping;
  };

  useEffect(() => {
    setIsTimeOverlapping(checkTimeOverlapping(selectedTimes));
    const fromToTimeInvalid = selectedTimes.some((selectedTime) =>
      calcFromToTimeError(selectedTime?.from, selectedTime?.to)
    );
    setIsTimeInvalid(fromToTimeInvalid);
  }, [selectedTimes]);

  useEffect(() => {
    setIsDateOverlapping(checkDateOverlapping());
  }, [selectedDate]);

  const convertDateToString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JS months start from 0
    const day = String(date.getDate()).padStart(2, '0');

    const dateValue = `${year}-${month}-${day}`;
    return dateValue;
  };

  const handleSave = () => {
    if (selectedDate) {
      const from = selectedDate[0] instanceof Date ? convertDateToString(selectedDate[0]) : '';
      // if there is no second SelectedDate, the day is selected, not the period
      const to = selectedDate[1] instanceof Date ? convertDateToString(selectedDate[1]) : from;
      if (isValidDate(from) && isValidDate(to)) {
        const days = {
          from: new Date(from).toISOString(),
          to: new Date(to).toISOString(),
        };

        // if unavailable set time from 00:00 to 00:00
        const time = isAvailable
          ? selectedTimes.map((time) => ({
              from: time.from ? time.from : null,
              to: time.to ? time.to : null,
            }))
          : [{ from: '00:00', to: '00:00' }];

        onChange([...overrides, { days, time, isBlock: !isAvailable }]);
      }

      setSelectedDate(null);
      setSelectedTimes([DEFAULT_AVAILABILITY_TIME]);
      setIsAvailable(true);
    }
    handleClose();
  };

  const handleRemove = (dates: TimeFromToInput) => {
    onChange(
      overrides.filter((override: OverridesInput | null) => {
        if (!override) return false;
        // Check if the date matches and remove the specific time entry
        return !(override.days?.from === dates.from && override.days?.to === dates.to);
      })
    );
  };

  const handleAddTime = (): void => {
    setSelectedTimes((prevDates: TimeFromToInput[]) => [...prevDates, DEFAULT_AVAILABILITY_TIME]);
  };

  const handleRemoveTime = (index: number): void => {
    setSelectedTimes((prevDates: TimeFromToInput[]) =>
      prevDates.filter((_: TimeFromToInput, i: number) => i !== index)
    );
  };

  const handleClickOpen = () => {
    dispatch(availabilityOverrideModalActions.openModal());
  };

  const handleClose = () => {
    dispatch(availabilityOverrideModalActions.closeModal());
    setSelectedDate(null);
    setSelectedTimes([DEFAULT_AVAILABILITY_TIME]);
  };

  return (
    <>
      <p className="text-lg">{labels.dateOverrideDescription}</p>
      <div className="flex justify-content-center align-content-center mt-2">
        <Button
          outlined
          icon="pi pi-plus"
          onClick={handleClickOpen}
          label={labels.dateOverrideButton}
          className="w-max"
          disabled={readonly}
        />
      </div>

      <div
        className="flex flex-column justify-content-between overflow-y-auto pr-3"
        style={{ maxHeight: '34rem' }}
      >
        {overrides &&
          overrides.map((override: OverridesInput | null, index: number) => {
            if (!override || !override.days) {
              return null;
            }
            return (
              <Fragment key={`${override.days.from}-${index}`}>
                <div className="border-top-1 border-gray-200 my-3 -mx-3" />
                <div className="grid align-items-center">
                  <div className="col-7">
                    {formatDateUTC(override.days.from, dateFormat) +
                      (override.days.to && override.days.to !== override.days.from
                        ? ' - ' + formatDateUTC(override.days.to, dateFormat)
                        : '')}
                  </div>
                  <div className="col-4 flex flex-column">
                    {/* if there is only one override record with equals time
                        then set Unavailable for the day */}
                    {override.time?.length === 1 &&
                    override.time[0] &&
                    override.time[0].from === override.time[0].to ? (
                      <div>{labels.unavailableText}</div>
                    ) : (
                      override.time?.map((time, ind) => (
                        <div key={`${time}-${ind}`}>
                          {time
                            ? `${formatTimeHHMM(time.from || '', timeFormat)} - ${formatTimeHHMM(
                                time.to || '',
                                timeFormat
                              )}`
                            : ''}
                        </div>
                      ))
                    )}
                  </div>

                  <Button
                    icon="pi pi-trash"
                    rounded
                    text
                    className="col text-color"
                    onClick={() => handleRemove(override?.days ? override?.days : { from: null, to: null })}
                    disabled={readonly}
                  />
                </div>
              </Fragment>
            );
          })}
        <div className="border-top-1 border-gray-200 mt-3 my-3 -mx-3" />
      </div>

      <Modal.Container name={AVAILABILITY_OVERRIDE_MODAL_NAME} dividers>
        <Modal.Header>
          <span className="font-semibold">{labels.dateOverrideModalPrompt}</span>
        </Modal.Header>
        <div className="flex flex-column align-items-center p-fluid gap-3">
          <Calendar
            id="date"
            value={selectedDate}
            onChange={(e) => {
              if (Array.isArray(e.value)) {
                setSelectedDate(e.value);
              }
            }}
            inline={true}
            locale={i18n.language}
            selectionMode="range"
            panelClassName="w-max p-0"
            minDate={new Date()}
          />
          <SelectButton
            value={isAvailable}
            onChange={(e) => setIsAvailable(e.value)}
            options={availableSelectOptions}
            optionValue="value"
            optionLabel="name"
            className="w-full"
            unselectable={false}
          />
          {isAvailable ? (
            <>
              <div className="text-lg align-self-start">{labels.dateOverrideModalHours}</div>

              {selectedTimes.map((selectedTime: TimeFromToInput, index) => (
                <div key={index} className="w-full">
                  <div className="flex align-items-center justify-content-between">
                    <div className="flex align-items-center">
                      <Calendar
                        id={`from-time-${index}`}
                        className="w-8rem"
                        inputStyle={{ textAlign: 'center' }}
                        dateFormat="HH:mm"
                        hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
                        value={new Date(`1970-01-01T${selectedTime?.from}:00`)}
                        timeOnly
                        onChange={(event) => {
                          setSelectedTimes((prevTimes) =>
                            prevTimes.map((time, i) =>
                              i === index && event.target.value instanceof Date
                                ? { ...time, from: formatDateHHMM(event.target.value, TimeFormat.military) }
                                : time
                            )
                          );
                        }}
                        baseZIndex={1300}
                      />
                      <span className="px-3">-</span>
                      <Calendar
                        id={`to-time-${index}`}
                        className="w-8rem"
                        inputStyle={{ textAlign: 'center' }}
                        dateFormat="HH:mm"
                        hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
                        value={new Date(`1970-01-01T${selectedTime?.to}:00`)}
                        timeOnly
                        onChange={(event) => {
                          setSelectedTimes((prevTimes) =>
                            prevTimes.map((time, i) =>
                              i === index && event.target.value instanceof Date
                                ? { ...time, to: formatDateHHMM(event.target.value, TimeFormat.military) }
                                : time
                            )
                          );
                        }}
                        baseZIndex={1300}
                      />
                      {selectedTimes.length > 1 && (
                        <Button
                          icon="pi pi-trash"
                          rounded
                          text
                          className="text-color ml-2"
                          onClick={() => handleRemoveTime(index)}
                        />
                      )}
                    </div>
                    {index === selectedTimes.length - 1 && (
                      <Button icon="pi pi-plus" rounded text className="text-color" onClick={handleAddTime} />
                    )}
                  </div>
                  {calcFromToTimeError(selectedTime?.from, selectedTime?.to) && (
                    <small id="from-to-time-help" className="p-error block -mb-1 mt-1">
                      {labels.timeInValidText}
                    </small>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className="align-self-start text-lg">{labels.dateOverrideModalUnavailable}</div>
          )}
          {isDateOverlapping && (
            <small id="date-overlapping-help" className="p-error block align-self-start ">
              {labels.dateOverlappingText}
            </small>
          )}
          {isTimeOverlapping && (
            <small id="time-overlapping-help" className="p-error block align-self-start ">
              {labels.timeOverlappingText}
            </small>
          )}
        </div>

        <Modal.Buttons>
          <div className="flex justify-content-end gap-4 line-height-4 -mb-2 mt-3">
            <Button rounded outlined onClick={handleClose} label={labels.cancel} className="w-8rem mr-0" />
            <Button
              rounded
              onClick={handleSave}
              label={labels.save}
              className="w-8rem mr-0"
              disabled={!selectedDate || isDateOverlapping || isTimeOverlapping || isTimeInvalid}
            />
          </div>
        </Modal.Buttons>
      </Modal.Container>
    </>
  );
};
