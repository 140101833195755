import dayjs from 'dayjs';
import { v4 as getId } from 'uuid';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { scheduledMeetingsActions } from '../../store/scheduledMeetings/actions';
import { scheduledMeetingsSelectors } from '../../store/scheduledMeetings/selectors';
import { Preloader, SectionTitle } from '../../components/common';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import labels from './labels';
import {
  CancelScheduledMeetingModal,
  EditMeetingNotesModal,
  ScheduledMeetingRescheduleModal,
  ScheduledDayMeetings,
  ScheduledFilters,
  ViewAttendeesModal,
  ScheduledMeetingNoShowModal,
} from '../../components/scheduledMeetings';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { bookingTemplatesActions } from '../../store/bookingTemplates';
import { SortMethods } from '../../store/scheduledMeetings/typings';
import { bookingPagesActions } from '../../store/bookingPages';
import { usersActions } from '../../store/users';
import { useUserNavigation } from '../../hooks/useUserNavigation';

dayjs.extend(utc);
dayjs.extend(timezone);

export const ScheduledMeetings = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const isFetching = useSelector(scheduledMeetingsSelectors.selectIsFetching);
  const eventDays = useSelector(scheduledMeetingsSelectors.selectEventsDays);

  useEffect(() => {
    if (!isInitialVisit) {
      dispatch(bookingPagesActions.getBookingPagesRequest());
      dispatch(bookingTemplatesActions.getBookingTemplatesRequest());
      dispatch(usersActions.getUsersRequest());
    }
    dispatch(scheduledMeetingsActions.setSortMethod(SortMethods.UPCOMING));
    dispatch(scheduledMeetingsActions.getScheduledMeetingsRequest());
  }, [isInitialVisit]);

  return (
    <div>
      {isFetching && <Preloader />}

      <div className="card mb-3 sumo-header-bg">
        <SectionTitle
          id="bookedMeetingsPageTitle"
          icon="pi-calendar"
          title={labels.title}
        />
      </div>

      <ScheduledFilters />

      {!eventDays.length ? (
        <div className="m-3">{labels.noScheduledMeetings}</div>
      ) : (
        <Accordion multiple>
          {eventDays.map((day: string) => {
            const key = getId(); // for unique ids, days not apiure 
            const date = dayjs(day).format('MMMM D, YYYY');
            const today = dayjs().format('MMMM D, YYYY');
            return (
              <AccordionTab
                key={key}
                header={
                  <>
                    <span>{date}</span>
                    <span className="uppercase font-bold">{date === today ? ` - ${labels.today}` : ''}</span>
                  </>
                }
              >
                <ScheduledDayMeetings day={day} />
              </AccordionTab>
            );
          })}
        </Accordion>
      )}

      <EditMeetingNotesModal />
      <ViewAttendeesModal />
      <CancelScheduledMeetingModal />
      <ScheduledMeetingRescheduleModal />
      <ScheduledMeetingNoShowModal />
    </div>
  );
};