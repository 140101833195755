import { useDispatch, useSelector } from 'react-redux';
import { authentificationActions, authentificationSelectors } from '../../../../store/authentification';
import { useContext } from 'react';
import { LayoutContext } from '../../../common/context/layoutcontext';
import { Tooltip } from 'primereact/tooltip';
import labels from './labels';

export const ViewAsUserWarningBlock = () => {
  const dispatch = useDispatch();
  const isViewAsUserMode = useSelector(authentificationSelectors.selectIsViewAsUser);
  const { layoutConfig } = useContext(LayoutContext);

  return (
    <>
      {isViewAsUserMode && layoutConfig.colorScheme !== 'dim' && (
        <>
          <Tooltip target=".view-as-user-icon" className="block md:hidden lg:block xl:hidden " />
          <div
            className="view-as-user-icon bg-pink-200 border-round mr-3 p-2 flex align-items-center"
            data-pr-tooltip={labels.viewingSumo1}
          >
            <span className="pi pi-exclamation-triangle text-xl lg:text-xl md:text-base xl:text-base " />
            <span className="ml-1 hidden lg:hidden md:block xl:block">{labels.viewingSumo1} </span>
            <span
              className="ml-1 font-semibold hover:underline cursor-pointer"
              onClick={() => {
                dispatch(authentificationActions.resetViewAsUserRequest({ redirect: true, removeLicenseType: true }));
              }}
            >
              {labels.backToOPSConsole}
            </span>
          </div>
        </>
      )}
    </>
  );
};
