import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverridesInput, WeeklyHoursInput } from '../../../API';

import {
  CHANGE_AVAILABILITY_NAME,
  LIMIT_30_ITEMS_ERROR_TOAST,
  changeAvailabilityNameModalActions,
  userAvailabilityActions,
  userAvailabilitySelectors,
} from '../../../store/availability';

import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { ConfirmationModal, Modal } from '../../common';
import { AvailabilityTable, AvailabilityOverride } from '../';
import labels from './labels';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { AppDispatch } from '../../../store/rootStore';
import { userSettingsSelectors } from '../../../store/userSettings';
import { notificationsActions } from '../../../store/notifications';
import { authentificationSelectors } from '../../../store/authentification';
import { useUserNavigation } from '../../../hooks/useUserNavigation';

export const AvailabilityRecord = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const availabilities = useSelector(userAvailabilitySelectors.selectUserAvailabilities);
  const activeBookingPages = useSelector(userAvailabilitySelectors.selectActiveBookingPages);
  const link = useSelector(userAvailabilitySelectors.selectUserAvailabilityLink);
  const name = useSelector(userAvailabilitySelectors.selectName);
  const isDefault = useSelector(userAvailabilitySelectors.selectIsDefault);
  const overrides = useSelector(userAvailabilitySelectors.selectSortedOverrides);
  const weeklyHours = useSelector(userAvailabilitySelectors.selectWeeklyHours);
  const cloneName = useSelector(userAvailabilitySelectors.selectCloneName);
  const isNameDuplicate = useSelector(userAvailabilitySelectors.selectIsNameDuplicate);
  const isNameValid = useSelector(userAvailabilitySelectors.selectIsNameValid);
  const isCloneNameDuplicate = useSelector(userAvailabilitySelectors.selectIsCloneNameDuplicate);
  const isCloneNameValid = useSelector(userAvailabilitySelectors.selectIsCloneNameValid);
  const isWeeklyHoursValid = useSelector(userAvailabilitySelectors.selectIsWeeklyHoursValid);
  const availabilitiesOptions = useSelector(userAvailabilitySelectors.selectUserAvailabilitiesOptions);
  const selectedAvailability = useSelector(userAvailabilitySelectors.selectUserAvailability);
  const selectedInitAvailability = useSelector(userAvailabilitySelectors.selectInitUserAvailability);

  const bookingPageIsFetching = useSelector(bookingPageSelectors.selectIsFetching);
  const bookingPages = useSelector(bookingPageSelectors.selectBookingPages);
  const bookingPageIds = useSelector(bookingPageSelectors.selectBookingPageIds);
  const oldestAvailability = useSelector(userAvailabilitySelectors.selectOldestAvailability(link || ''));

  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const timeZoneWithTime = useSelector(userSettingsSelectors.selectUserTimeZoneWithTime);
  const isCreateAvail = useSelector(userSettingsSelectors.selectAvailabilityCreate);
  const isEditAvail = useSelector(userSettingsSelectors.selectAvailabilityEdit);
  const isDeleteAvail = useSelector(userSettingsSelectors.selectAvailabilityDelete);

  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);

  const [isClone, setIsClone] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const menu = useRef<any>(null); // TODO: replace any

  useEffect(() => {
    if (isUserSettingsReceived && isLicenseActive) {
      dispatch(bookingPagesActions.getBookingPagesPageRequest(isInitialVisit));
    }
  }, [isUserSettingsReceived, isInitialVisit]);

  useEffect(() => {
    // if the system will found valid changes after 0.5sec it will update the availability
    const timer = setTimeout(() => {
      if (selectedAvailability !== selectedInitAvailability && isWeeklyHoursValid && isEditAvail) {
        dispatch(userAvailabilityActions.saveUserAvailabilityRequest());
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedAvailability, selectedInitAvailability, dispatch]);

  const handleEditName = () => {
    setIsClone(false);
    dispatch(changeAvailabilityNameModalActions.openModal());
  };

  const handleCloneAvailability = () => {
    if (availabilitiesOptions.length >= 30) {
      dispatch(notificationsActions.showToast(LIMIT_30_ITEMS_ERROR_TOAST));
    } else {
      setIsClone(true);
      dispatch(userAvailabilityActions.cloneAvailabilityThunk());
    }
  };

  const handleDeleteAvailability = () => {
    dispatch(userAvailabilityActions.deleteUserAvailabilityRequest(link));
    setIsConfirmModalOpen(false);
  };

  const handleBookingPageChange = (value: [string]) => {
    dispatch(userAvailabilityActions.updateUserAvailability({ activeBookingPages: value }));
  };

  const handleWeeklyHoursChange = (weeklyHours: Array<WeeklyHoursInput | null>) => {
    dispatch(userAvailabilityActions.updateUserAvailability({ weeklyHours }));
  };

  const handleOverridesChange = (overrides: Array<OverridesInput | null>) => {
    dispatch(userAvailabilityActions.updateUserAvailability({ overrides }));
  };

  const handleSaveAvailability = () => {
    dispatch(userAvailabilityActions.saveUserAvailabilityRequest());
  };

  const handleNameChange = (name: string) => {
    dispatch(
      isClone ? userAvailabilityActions.setCloneName(name) : userAvailabilityActions.updateUserAvailability({ name })
    );
  };

  const handleNameDialogCancel = () => {
    dispatch(changeAvailabilityNameModalActions.closeModal());
  };

  const handleNameSave = () => {
    isClone ? dispatch(userAvailabilityActions.cloneUserAvailabilityRequest()) : handleSaveAvailability();
    dispatch(changeAvailabilityNameModalActions.closeModal());
  };

  const handleSetAsDefault = () => {
    dispatch(userAvailabilityActions.setDefaultAvailabilityRequest(true));
  };

  const menuItems = [
    {
      label: labels.editName,
      icon: 'pi pi-pencil',
      command: handleEditName,
      visible: isEditAvail,
    },
    {
      label: labels.clone,
      icon: 'pi pi-clone',
      command: handleCloneAvailability,
      visible: isCreateAvail,
    },
    ...(!isDefault
      ? [
          {
            label: labels.setDefault,
            icon: 'pi pi-star',
            command: handleSetAsDefault,
            visible: isEditAvail,
          },
        ]
      : []),
    ...(availabilities.length > 1
      ? [
          {
            label: labels.delete,
            icon: 'pi pi-trash',
            command: () => setIsConfirmModalOpen(true),
            visible: isDeleteAvail,
          },
        ]
      : []),
  ];

  return (
    <div>
      <div className="p-d-flex p-flex-column">
        <div className="mb-4">
          <div className="flex align-items-center">
            <div className="font-bold text-xl">{name}</div>
            {(isEditAvail || isCreateAvail || (availabilities.length > 1 && isDeleteAvail)) && (
              <>
                <Button
                  className="ml-1"
                  icon="pi pi-cog"
                  rounded
                  text
                  severity="secondary"
                  onClick={(e) => menu.current?.toggle && menu.current.toggle(e)}
                />
                <Menu model={menuItems} popup ref={menu} />
              </>
            )}
          </div>
          {isDefault && <p>⭐ {labels.isDefault}</p>}
        </div>

        <div className="flex justify-content-between">
          <div className="align-items-top">
            <p className="font-semibold">{labels.bookingPages}</p>
            <MultiSelect
              value={activeBookingPages?.filter((id) => id && bookingPageIds.includes(id)) || []}
              options={bookingPages?.map((page) => ({
                label: page.what?.customName,
                value: page.id,
                displayId: page.displayId,
              }))}
              onChange={(e) => handleBookingPageChange(e.value)}
              placeholder={labels.activeOn}
              disabled={!isEditAvail}
              className="w-full md:w-20rem"
              itemTemplate={(option) => (
                <div className="w-16rem flex justify-content-between gap-1">
                  <div className="flex-1 overflow-hidden text-overflow-ellipsis">{option.label}</div>
                  <div className="bg-gray-200 px-2 border-round">{option.displayId}</div>
                </div>
              )}
            />
            {bookingPageIsFetching && <i className="pi pi-spin pi-spinner" style={{ marginLeft: '10px' }} />}
          </div>
          <div className="text-right">
            <p className="font-semibold">{labels.timeZone}</p>
            <div>{timeZoneWithTime}</div>
          </div>
        </div>

        <div className="grid align-items-top p-fluid mt-3">
          <div className="col-12 md:col-7 p-4">
            <p className="text-lg">{labels.setWeeklyHours}</p>
            <AvailabilityTable weeklyHours={weeklyHours} onChange={handleWeeklyHoursChange} readonly={!isEditAvail} />

            {/* <div className="p-d-flex p-jc-end" style={{ marginTop: '20px' }}>
              <Button
                label={labels.saveAvailability}
                className="p-button-primary"
                onClick={handleSaveAvailability}
                disabled={!isWeeklyHoursValid || !isEditAvail}
              />
            </div> */}
          </div>

          <div className="col-12 md:col-5 p-4">
            <AvailabilityOverride overrides={overrides} onChange={handleOverridesChange} readonly={!isEditAvail} />
          </div>
        </div>
      </div>

      <Modal.Container name={CHANGE_AVAILABILITY_NAME}>
        <Modal.Header>
          <div className="text-3xl">{isClone ? labels.cloneTitle : labels.dialogEditTitleName}</div>
        </Modal.Header>
        <div className="w-25rem pt-2 p-fluid">
          <h6>{labels.dialogTitle}</h6>
          <InputText
            type="text"
            value={isClone ? cloneName : name}
            onChange={(e) => {
              handleNameChange(e.target.value);
            }}
            placeholder={labels.dialogPlaceholder}
            className={`${(isClone ? !isCloneNameValid : !isNameValid) && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
          {(isClone ? isCloneNameDuplicate : isNameDuplicate) && (
            <div className="p-error block">{labels.duplicateNameMessage}</div>
          )}
        </div>
        <Modal.Buttons>
          <div className="flex gap-2 justify-content-end -mr-2">
            <Button onClick={handleNameDialogCancel} className="p-button-outlined">
              {labels.cancel}
            </Button>
            <Button
              onClick={handleNameSave}
              color="primary"
              autoFocus
              disabled={isClone ? !isCloneNameValid : !isNameValid}
            >
              {labels.save}
            </Button>
          </div>
        </Modal.Buttons>
      </Modal.Container>

      <ConfirmationModal
        visible={isConfirmModalOpen}
        title={labels.delete}
        description={`${labels.deleteDesc} "${name}"?`}
        additionalText={[isDefault ? `"${oldestAvailability?.availabilityData?.name}" ${labels.deleteMessage}` : '']}
        confirmButtonLabel={labels.yes}
        cancelButtonLabel={labels.no}
        onConfirm={handleDeleteAvailability}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};
