export const ConnectIntegrationPage = Object.freeze({
  BENEFITS_TITLE: `Beneficios`,
  DISCONNECT_DESCRIPTION: `¿Estás seguro de que quieres desconectar `,
  GOOGLE_CALENDAR_BENEFITS: `Reserve y reprograma automáticamente reuniones en tu Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Debes conceder acceso a tu Google Calendar.`,
  GOOGLE_MEET_BENEFITS: `Crea automáticamente reuniones de Google Meet en el momento de la programación. Comparte instantáneamente detalles únicos de la conferencia al confirmar.`,
  GOOGLE_MEET_REQUIREMENTS: `Según tu configuración, tu administrador de cuenta de Google Meet puede necesitar aprobar previamente SUMO1 en Google Marketplace.`,
  MICROSOFT_CALENDAR_BENEFITS: `Reserva y reprograma automáticamente reuniones en tu Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Debes conceder acceso a tu Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Crea automáticamente reuniones de Microsoft Teams en el momento de la programación. Comparte instantáneamente detalles únicos de la conferencia al confirmar.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Según tu configuración, tu administrador de cuenta de Microsoft Teams puede necesitar aprobar previamente SUMO1 en Microsoft Marketplace.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  ZOOM_BENEFITS: `Crea automáticamente reuniones de Zoom en el momento de la programación. Comparte instantáneamente detalles únicos de la conferencia al confirmar.`,
  ZOOM_REQUIREMENTS: `Según tu configuración, tu administrador de cuenta de Zoom puede necesitar aprobar previamente SUMO1 en Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Ahorra más tiempo automatizando videollamadas, calendarios y más.`,
  FILTER_ALL: `Todo`,
  FILTER_CALENDARS: `Calendarios`,
  FILTER_VIDEO_CONFERENCE: `Videollamada`,
  GOOGLE_CALENDAR_DESC: `Reserva y reprograma automáticamente reuniones en tu Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MEET_DESC: `Añade automáticamente detalles de Google Meet a tus reuniones.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_CALENDAR_DESC: `Reserva y reprograma automáticamente reuniones en tu Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Añade automáticamente detalles de Microsoft Teams a tus reuniones.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `No se encontraron elementos.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  TITLE: `Integración`,
  ZOOM_DESC: `Añade automáticamente detalles de Zoom a tus reuniones de SUMO.`,
  ZOOM_TITLE: `Zoom Meeting`,
});
