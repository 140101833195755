import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TimeFromToInput, WeekDay, WeeklyHoursInput } from '../../../API';
import { AppDispatch } from '../../../store/rootStore';
import { DEFAULT_AVAILABILITY_TIME, WEEKDAY_OPTIONS, userAvailabilitySelectors } from '../../../store/availability';
import { QuickSetupScreen } from '../';
import labels from './labels';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { calcFromToTimeError, formatDateHHMM } from '../../../services/DateService';
import { quickSetupActions, quickSetupNavigationActions, quickSetupSelectors } from '../../../store/quickSetup';
import { TimeFormat, UserSettingsKeys, userSettingsSelectors } from '../../../store/userSettings';
import { UserDataInputCreatedAt } from '../../../store/global/types';

export const SetupAvailabilityStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const defaultAvailability = useSelector(userAvailabilitySelectors.selectDefaultAvailability);
  const userAvailability = useSelector(quickSetupSelectors.selectUserAvailability);
  const userWeeklyHours = useSelector(quickSetupSelectors.selectUserAvailabilityWeeklyHours);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const isFirstAdmin = useSelector(userSettingsSelectors.selectIsFirstTenantUser);
  const isEditable = useSelector(userSettingsSelectors.selectAvailabilityEdit);

  const [time, setTime] = useState<TimeFromToInput>({ from: null, to: null });
  const [isTimeInvalid, setIsTimeInvalid] = useState(false);
  const [minimumDaysError, setMinimumDaysError] = useState(false);

  const [weeklyHours, setWeeklyHours] = useState<WeeklyHoursInput[]>(userWeeklyHours);
  const savedWeeklyHours = localStorage.getItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS);

  useEffect(() => {
    if ((!userAvailability || !userWeeklyHours) && defaultAvailability) {
      dispatch(quickSetupActions.setUserAvailability(defaultAvailability));
    }

    const weeklyHoursToSet: WeeklyHoursInput[] = savedWeeklyHours
      ? JSON.parse(savedWeeklyHours)
      : userWeeklyHours || [];
    setWeeklyHours(weeklyHoursToSet);

    const firstValidDay = weeklyHoursToSet.find((day: WeeklyHoursInput) => day?.time?.[0]?.from && day?.time?.[0]?.to);
    const timeToSet = determineTimeToSet(firstValidDay);

    setTime(timeToSet);
  }, [userWeeklyHours, defaultAvailability, savedWeeklyHours]);

  useEffect(() => {
    setIsTimeInvalid(calcFromToTimeError(time.from, time.to));
    setMinimumDaysError(weeklyHours.filter(day => day.time && day.time.length > 0).length < 1);
  }, [time, weeklyHours]);

  const determineTimeToSet = (firstValidDay: WeeklyHoursInput | undefined) => {
    return firstValidDay && firstValidDay.time && firstValidDay.time[0]
      ? { from: firstValidDay.time[0].from as string, to: firstValidDay.time[0].to as string }
      : DEFAULT_AVAILABILITY_TIME;
  };

  const handleTimeChange = (input: Partial<TimeFromToInput>) => {
    const newTime = { ...time, ...input };
    setTime(newTime);
    setWeeklyHours(
      weeklyHours.length
        ? weeklyHours.map((day) => (day?.time?.length ? { ...day, time: [newTime] } : day))
        : weeklyHours
    );
  };

  const handleDayChange = (selectedTypes: WeekDay[]) => {
    const updatedWeeklyHours = weeklyHours.map((day) => {
      if (day?.type && selectedTypes.includes(day.type)) {
        return { ...day, time: [time] };
      } else {
        return { ...day, time: [] };
      }
    });
    setWeeklyHours(updatedWeeklyHours);
  };

  const availabilitySave = () => {
    const availability = {
      ...userAvailability,
      availabilityData: { ...userAvailability?.availabilityData, weeklyHours },
    } as UserDataInputCreatedAt;
    localStorage.setItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS, JSON.stringify(weeklyHours));

    dispatch(quickSetupActions.setUserAvailability(availability));
  };

  const handleBack = () => {
    availabilitySave();
    dispatch(quickSetupNavigationActions.navigateBack());
  };

  const handleNext = () => {
    if (!minimumDaysError) {
      availabilitySave();
      dispatch(quickSetupNavigationActions.navigateNext());
    }
  };

  return (
    <QuickSetupScreen.Container title={labels.title}>
      <div className="flex flex-column align-items-center">
        <div className="text-xl mt-3">{isFirstAdmin ? labels.adminDescription : labels.userDescription}</div>
        <div className="flex flex-column align-items-center col-12 md:col-6">
          <Divider className="my-5" />
          <div className="flex flex-column w-fit">
            <div>{labels.setupHoursInputLabel}</div>
            <div>
              <Calendar
                inputStyle={{ textAlign: 'center' }}
                className="w-8rem"
                dateFormat="HH:mm"
                hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
                value={new Date(`1970-01-01T${time.from}`)}
                timeOnly
                onChange={(e) =>
                  e.target.value instanceof Date &&
                  handleTimeChange({ from: formatDateHHMM(e.target.value, TimeFormat.military) })
                }
                disabled={!isEditable}
              />
              <span className="text-xl px-2">-</span>
              <Calendar
                inputStyle={{ textAlign: 'center' }}
                className="w-8rem"
                dateFormat="HH:mm"
                hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
                value={new Date(`1970-01-01T${time.to}`)}
                timeOnly
                onChange={(e) =>
                  e.target.value instanceof Date &&
                  handleTimeChange({ to: formatDateHHMM(e.target.value, TimeFormat.military) })
                }
                disabled={!isEditable}
              />
            </div>
            {isTimeInvalid && (
              <div id="from-to-time-help" className="p-error block">
                {labels.timeInValidText}
              </div>
            )}
            <div className="mt-5">{labels.setupDaysInputLabel}</div>
            <SelectButton
              value={weeklyHours?.filter((el) => el?.time?.length).map((el) => el?.type)}
              onChange={(e) => handleDayChange(e.value)}
              options={WEEKDAY_OPTIONS}
              optionLabel="label"
              className="flex flex-wrap justify-content-center pb-2"
              multiple
              disabled={!isEditable}
            />
            {minimumDaysError && (
              <div className="p-error block mt-2">
                {labels.minimumDaysErrorText}
              </div>
            )}
          </div>
          <Divider className="my-5" />

          <div className="text-lg">{labels.message}</div>
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <div className="flex justify-content-between">
          <Button label={labels.back} className="w-8rem m-2" onClick={handleBack} outlined />
          <Button label={labels.next} className="w-8rem m-2" onClick={handleNext} disabled={isTimeInvalid || minimumDaysError} />
        </div>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
