import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { editTeamModalActions, editTeamModalSelectors, teamsActions, teamsSelectors } from '../../../store/teams';
import { ChangeEvent, useEffect, useState } from 'react';
import labels from './labels';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { AutoCompleteWithChips } from '../../common/autoCompleteWIthChips/AutoCompleteWithChips';
import { UserOption } from '../../../store/users/typings';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primereact/autocomplete';
import { usersSelectors } from '../../../store/users';
import { TeamMemberInput } from '../../../API';
import { WorkspaceOption, workspacesSelectors } from '../../../store/workspaces';
import { Tooltip } from 'primereact/tooltip';
import { userSettingsSelectors } from '../../../store/userSettings';

export const EditTeamModal = () => {
  const dispatch = useDispatch();
  const open = useSelector(editTeamModalSelectors.selectIsModalOpen);
  const selectedTeam = useSelector(teamsSelectors.selectSelectedTeam);
  const users = useSelector(usersSelectors.selectLoggedUsersInCurrentWorkspaceOptions);
  const allUsers = useSelector(usersSelectors.selectLoggedUserListOptions);
  const members = useSelector(teamsSelectors.selectMembers);
  const membersIds = useSelector(teamsSelectors.selectMembersIds);

  const workspaces = useSelector(workspacesSelectors.selectWorkspaceOptions);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const workspaceId = useSelector(teamsSelectors.selectWorkspaceId);
  const workspacesForRead = useSelector(teamsSelectors.selectWorkspacesForRead);
  const workspaceName = useSelector(teamsSelectors.selectWorkspaceName);

  const isExisting = useSelector(teamsSelectors.selectIsExistingTeam);
  const isReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyTeam);
  const sharedForRead = useSelector(teamsSelectors.selectSharedForReadTeam);

  const isNameValid = useSelector(teamsSelectors.selectIsNameValid);
  const isNameDuplicate = useSelector(teamsSelectors.selectIsNameDuplicate);
  const [localTeamName, setLocalTeamName] = useState(selectedTeam.name || '');
  const [filteredUsers, setFilteredUsers] = useState<UserOption[]>([]);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState<WorkspaceOption[]>([]);

  useEffect(() => {
    setLocalTeamName(selectedTeam.name || '');
  }, [selectedTeam]);

  const handleClose = () => {
    dispatch(editTeamModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(teamsActions.saveTeamRequest());
  };

  const handleChange = (name: string, value: boolean) => {
    dispatch(teamsActions.updateSelectedTeam({ [name]: value }));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalTeamName(e.target.value);
  };

  const handleOnBlurName = () => {
    dispatch(teamsActions.updateSelectedTeam({ name: localTeamName.trim() }));
  };

  const handleUserListSelect = (e: AutoCompleteSelectEvent) => {
    dispatch(teamsActions.updateSelectedTeam({ members: [...members, { userId: e.value.value }] }));
  };
  const removeUserListChange = (value: string) => {
    dispatch(teamsActions.updateSelectedTeam({ members: members.filter((member) => member?.userId !== value) }));
  };
  const filterUser = (e: AutoCompleteCompleteEvent) => {
    setFilteredUsers(
      users.filter((user) => {
        return (
          (!e.query.trim().length || user.label.toLowerCase().startsWith(e.query.toLowerCase())) &&
          !membersIds.includes(user.value)
        );
      })
    );
  };

  const handleWorkspaceListSelect = (e: AutoCompleteSelectEvent) => {
    dispatch(teamsActions.updateSelectedTeam({ workspacesForRead: [...workspacesForRead, e.value.id] }));
  };
  const removeWorkspaceListChange = (value: string) => {
    dispatch(
      teamsActions.updateSelectedTeam({
        workspacesForRead: workspacesForRead.filter((workspace) => workspace !== value),
      })
    );
  };
  const filterWorkspace = (e: AutoCompleteCompleteEvent) => {
    setFilteredWorkspaces(
      workspaces.filter((workspace) => {
        return (
          (!e.query.trim().length || workspace.name.toLowerCase().startsWith(e.query.toLowerCase())) &&
          !workspacesForRead.includes(workspace.id) &&
          workspace.id !== workspaceId &&
          (isSuperAdmin || userWorkspaces.includes(workspace.id))
        );
      })
    );
  };
  const handleWorkspaceRemovable = (value: { value: string }) => {
    return isSuperAdmin || userWorkspaces.includes(value.value);
  };

  const confirmFooter = () => {
    return (
      <div className="flex justify-content-end gap-3">
        <Button label={labels.cancel} outlined className="w-6rem" onClick={handleClose} />
        <Button
          label={labels.save}
          className="w-8rem"
          onClick={handleSave}
          disabled={!members.length || !isNameValid || isReadOnly || sharedForRead}
        />
      </div>
    );
  };

  return (
    <Dialog
      header={isExisting ? labels.editTeam : labels.createTeam}
      headerClassName="text-center text-900"
      footer={confirmFooter}
      visible={open}
      onHide={handleClose}
      className="w-11 md:w-9 lg:w-6"
      focusOnShow={false}
    >
      <div className="w-12 p-2 mb-3 border-1 border-300" style={{ background: '#e7f4fd' }}>
        <span className="pi pi-info-circle mt-1 mr-2" />
        <b>{labels.roundRobinDescPar1}</b>
        {` - ${labels.roundRobinDescPar2}`}
      </div>
      <div className="p-fluid grid p-2 ">
        <div className="flex flex-column col-12 md:col-8">
          <div className="flex align-items-center w-12rem mr-2 mb-2">
            <label htmlFor="name">{labels.teamName}</label>
            <Tooltip target=".name-info" className="max-w-30rem" />
            <i
              className="name-info pi pi-info-circle ml-1"
              data-pr-position="top"
              data-pr-my="center bottom-10"
              data-pr-tooltip={labels.teamNameTooltip}
            ></i>
          </div>
          <InputText
            id="name"
            name="name"
            value={localTeamName}
            onChange={handleNameChange}
            onBlur={handleOnBlurName}
            maxLength={MAX_LENGTH_NAME}
            placeholder={labels.teamNamePlaceholder}
            className={`${isNameValid ? '' : 'p-invalid'}`}
            disabled={isReadOnly || sharedForRead}
          />
          {isNameDuplicate && (
            <small id="email-help" className="p-error block">
              {labels.duplicateName}
            </small>
          )}
        </div>
        <div className="flex col-12 md:col-4 md:justify-content-end">
          <div className="flex flex-column w-max">
            <label>
              {labels.availableForUse}
              <Tooltip target=".availableForUse" className="max-w-30rem" />
              <i
                className="availableForUse pi pi-info-circle ml-2"
                data-pr-position="top"
                data-pr-tooltip={labels.availableForUseTooltip}
              ></i>
            </label>
            <div className="flex flex-column align-items-center  w-fit">
              <InputSwitch
                className="mt-2"
                checked={!!selectedTeam.active}
                name="active"
                onChange={(e) => handleChange(e.target.name, !!e.target.value)}
                disabled={isReadOnly || sharedForRead}
              />
              <div className="text-sm mt-1">{selectedTeam.active ? labels.on : labels.off}</div>
            </div>
          </div>
        </div>
        <div className="col-12 surface-50 text-sm p-2 my-2 uppercase">{labels.members}</div>
        <div className="col-12 py-2">
          <div className="pb-2">{labels.membersLabel}</div>
          <AutoCompleteWithChips<UserOption, TeamMemberInput>
            placeholder={labels.membersPlaceholder}
            field="label"
            onSelect={handleUserListSelect}
            suggestions={filteredUsers}
            completeMethod={filterUser}
            className={`w-12 md:w-8 ${!members.length ? 'p-invalid' : ''}`}
            valuesList={members}
            chipKey="userId"
            chipLabelFromList={(key) =>
              (sharedForRead ? allUsers : users).find((user) => user.value === key)?.label || ''
            }
            chipLabel="userId"
            chipOnRemove={removeUserListChange}
            disabled={isReadOnly || sharedForRead}
          />
        </div>
        <div className="col-12 surface-50 text-sm p-2 my-2 uppercase">{labels.workspaces}</div>
        <div className="col-12 py-2">
          <div className="pb-2">
            {`${labels.workspacesLabel}: `} <b>{workspaceName}</b>
            <Tooltip target=".defaultWorkspace" className="max-w-30rem" />
            <i
              className="defaultWorkspace pi pi-info-circle ml-2"
              data-pr-position="top"
              data-pr-tooltip={labels.defaultWorkspaceTooltip}
            ></i>
          </div>
          <AutoCompleteWithChips<WorkspaceOption, { value: string }>
            placeholder={labels.workspacesPlaceholder}
            field="name"
            onSelect={handleWorkspaceListSelect}
            suggestions={filteredWorkspaces}
            completeMethod={filterWorkspace}
            className="w-12 md:w-8"
            valuesList={workspacesForRead.map((workspace) => ({ value: workspace }))}
            chipKey="value"
            chipLabelFromList={(key) => workspaces.find((workspace) => workspace.id === key)?.name || ''}
            chipLabel="value"
            chipOnRemove={removeWorkspaceListChange}
            isRemovable={handleWorkspaceRemovable}
            disabled={workspaces.length < 2 || isReadOnly || sharedForRead}
          />
        </div>
      </div>
    </Dialog>
  );
};
