import { CreateTenantInput } from '../../API';
import { DEFAULT_LOOK_AND_FEEL } from '../bookingPages';
import { t } from 'i18next';
import { ToastNotificationOptions } from '../notifications';
import { LinkStatus } from '../userSettings';
import urlConstants from '../../shared/JSON/urlConstants.json';


export const DEFAULT_LINK_STATUS = LinkStatus.FREE;

export const SAVE_QUICK_SETUP_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  title: t('QuickSetupToastsNotifications:SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE'),
  message: t('QuickSetupToastsNotifications:SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC'),
});

export const SAVE_QUICK_SETUP_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('QuickSetupToastsNotifications:SAVE_QUICK_SETUP_ERROR_TOAST'),
});

export const DEFAULT_TENANT_INFO: Partial<CreateTenantInput> = {
  tenantStyle: {
    avatar: null,
    backgroundImage: null,
    lookAndFeel: DEFAULT_LOOK_AND_FEEL,
    opacity: 85,
    css: null,
    footer: `<a href="${urlConstants.NAME_URL_SITE}" target="_blank">Powered by SUMO</a>`,
  },
};
