import { useDispatch, useSelector } from 'react-redux';
import { PickList, PickListChangeEvent } from 'primereact/picklist';
import { IntegrationType } from '../../../API';
import {
  BookingTemplateWhoOption,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../store/bookingTemplates';
import { UserAdminDataFullRecord } from '../../../store/users/typings';
import { ReactComponent as GoogleMeetIcon } from '../../../assets/icons/google-meet.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/icons/zoom.svg';
import { ReactComponent as TeamsIcon } from '../../../assets/icons/teams.svg';
import labels from './labels';
import { Fragment } from 'react';
import { BookingPageWhoOption } from '../../../store/bookingPages';
import { userSettingsSelectors } from '../../../store/userSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

export const BookingTemplateWhoStep = () => {
  const dispatch = useDispatch();
  const teams = useSelector(bookingTemplatesSelectors.selectAssignedTeams);
  const availableTeams = useSelector(bookingTemplatesSelectors.selectAvailableTeams);
  const hosts = useSelector(bookingTemplatesSelectors.selectAssignedHosts);
  const availableHosts = useSelector(bookingTemplatesSelectors.selectAvailableHosts);
  // const rescheduleWith = useSelector(bookingTemplatesSelectors.selectRescheduleWith);
  const isWhoStepValid = useSelector(bookingTemplatesSelectors.selectIsWhoStepValid);
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);
  const isTeamsRead = useSelector(userSettingsSelectors.selectTeamsRead);

  const handleTeamsChange = (e: PickListChangeEvent) => {
    const teamIds = (e.target as BookingPageWhoOption[]).map((team) => team.value);
    dispatch(bookingTemplatesActions.updatePotentialTeams(teamIds.filter((id) => !!id) as string[]));
  };

  const handleHostsChange = (e: PickListChangeEvent) => {
    const hostIds = (e.target as UserAdminDataFullRecord[]).map((host) => host.userId);
    dispatch(bookingTemplatesActions.updatePotentialHosts(hostIds.filter((id) => !!id) as string[]));
  };

  // const handleRescheduleWithChange = (value: RescheduleWith) => {
  //   dispatch(bookingTemplatesActions.updateWhoStep({ hostRescheduleWith: value }));
  // };

  // const searchTeam = (e: AutoCompleteCompleteEvent) => {
  //   if (!e.query.trim().length) {
  //     setFilteredTeams(teamsList);
  //     return;
  //   }
  //   setTimeout(() => {
  //     setFilteredTeams(
  //       teamsList.filter((team) => {
  //         return team.name.toLowerCase().startsWith(e.query.toLowerCase());
  //       })
  //     );
  //   }, 250);
  // };

  const hostTemplate = (item: BookingTemplateWhoOption) => (
    <div key={item.value} className="flex justify-content-between">
      <div>{item.label}</div>
      <div className="flex align-items-center gap-3 pl-1">
        {item.phoneNumber && <i className="pi pi-phone text-xl" title={labels.phoneCall} />}
        {item.integrationTypes?.map((type) => {
          switch (type) {
            case IntegrationType.ZOOM:
              return <ZoomIcon key={type} title={labels.zoom} />;
            case IntegrationType.GOOGLE_MEET:
              return <GoogleMeetIcon key={type} title={labels.googleMeet} />;
            case IntegrationType.MICROSOFT_TEAMS:
              return <TeamsIcon key={type} title={labels.microsoftTeams} />;
            default:
              return <Fragment key="default"></Fragment>;
          }
        })}
      </div>
    </div>
  );

  return (
    <div>
      <div className="grid  p-fluid">
        <div className="col-12 mb-4">
          <div className="mb-2 text-lg font-bold">
            <span>
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              {labels.potentialHosts}
            </span>
          </div>
          <PickList
            pt={{
              moveAllToTargetButton: { root: { className: isReadOnly ? 'p-disabled' : '' } },
              moveAllToSourceButton: { root: { className: isReadOnly ? 'p-disabled' : '' } },
              moveToTargetButton: { root: { className: isReadOnly ? 'p-disabled' : '' } },
              moveToSourceButton: { root: { className: isReadOnly ? 'p-disabled' : '' } },
              header: { className: 'bg-transparent border-none font-normal p-1' },
            }}
            sourceStyle={{ height: '15rem', borderRadius: '4px', padding: 0 }}
            targetStyle={{ height: '15rem', borderRadius: '4px', padding: 0 }}
            showSourceControls={false}
            showTargetControls={false}
            source={availableHosts}
            target={hosts}
            onChange={handleHostsChange}
            itemTemplate={(item: UserAdminDataFullRecord) =>
              hostTemplate({
                value: item.email,
                label: item.fullName || item.email,
                phoneNumber: !!item.phoneNumber,
                integrationTypes: item.integrationTypes || [],
              })
            }
            className={(!isWhoStepValid && 'p-invalid') || ''}
            sourceHeader={labels.available}
            targetHeader={labels.selected}
          ></PickList>
        </div>
        {(isTeamsRead || !!teams.length) && (
          <div className="col-12 mb-4">
            <div className="mb-2 text-lg font-bold">
              <span>
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                {labels.teams}
              </span>
            </div>
            <PickList
              pt={{
                moveAllToTargetButton: { root: { className: isReadOnly || !isTeamsRead ? 'p-disabled' : '' } },
                moveAllToSourceButton: { root: { className: isReadOnly || !isTeamsRead ? 'p-disabled' : '' } },
                moveToTargetButton: { root: { className: isReadOnly || !isTeamsRead ? 'p-disabled' : '' } },
                moveToSourceButton: { root: { className: isReadOnly || !isTeamsRead ? 'p-disabled' : '' } },
                header: { className: 'bg-transparent border-none font-normal p-1' },
              }}
              sourceStyle={{ height: '15rem', borderRadius: '4px', padding: 0 }}
              targetStyle={{ height: '15rem', borderRadius: '4px', padding: 0 }}
              showSourceControls={false}
              showTargetControls={false}
              source={availableTeams}
              target={teams}
              onChange={handleTeamsChange}
              itemTemplate={hostTemplate}
              className={(!isWhoStepValid && 'p-invalid') || ''}
              sourceHeader={labels.available}
              targetHeader={labels.selected}
            ></PickList>
          </div>
        )}

        {/* <div className="col-12">
          <div className="mb-2 font-bold">
            <span>{labels.rescheduleWith}</span>
            <Tooltip target=".invite-others" className="max-w-30rem" />
            <i
              className="invite-others pi pi-info-circle ml-2"
              data-pr-position="top"
              data-pr-my="center bottom-10"
              data-pr-tooltip={labels.rescheduleWithTooltip}
            ></i>
          </div>
          <div className="field-radiobutton">
            <RadioButton
              inputId="assignedHost"
              value={RescheduleWith.ASSIGNED_HOST}
              checked={rescheduleWith === RescheduleWith.ASSIGNED_HOST}
              onChange={(e) => handleRescheduleWithChange(e.target.value as RescheduleWith)}
              disabled={isReadOnly}
            />
            <label htmlFor="assignedHost" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
              {labels.assignedHost}
            </label>
          </div>
          <div className="field-radiobutton">
            <RadioButton
              inputId="roundRobinToNewHost"
              value={RescheduleWith.ROUND_ROBIN_TO_NEW_HOST}
              checked={rescheduleWith === RescheduleWith.ROUND_ROBIN_TO_NEW_HOST}
              onChange={(e) => handleRescheduleWithChange(e.target.value as RescheduleWith)}
              disabled={isReadOnly}
            />
            <label htmlFor="roundRobinToNewHost" className="ml-0 py-1 px-2 cursor-pointer hover:text-primary">
              {labels.roundRobinToNewHost}
            </label>
          </div>
        </div> */}
      </div>
    </div>
  );
};
