import { call, put } from 'redux-saga/effects';
import { googleAuth, microsoftAuth } from './service';
import { MicrosoftCodeResponse, ThirdPartyLambdaResponse, ThirdPartyType } from './typings';
import { CodeResponse as GoogleCodeResponse } from '@react-oauth/google';
import { authentificationActions } from './actions';
import { THIRD_PARTY, INVITE_EXPIRED_ERROR_TOAST } from './constants';
import { CreateAdminDataInput } from '../../API';
import { UserSettingsKeys } from '../userSettings';
import { adminDataStatus } from '../../shared/backend/constants';
import { globalActions } from '../global';
import { notificationsActions } from '../notifications';

export function* getAuthResponse(
  type: ThirdPartyType,
  codeResponse: any,
  userTimeZone: string,
  switchAccount: boolean,
  updateIntegration: boolean,
  integrationType: string,
  inviteTenantId: string | null,
  login: boolean,
): Generator<any, ThirdPartyLambdaResponse, any> {
  if (type === ThirdPartyType.GOOGLE) {
    return yield call(
      googleAuth,
      codeResponse as GoogleCodeResponse,
      userTimeZone,
      switchAccount,
      !!updateIntegration,
      integrationType,
      inviteTenantId,
      login,
    );
  }
  if (type === ThirdPartyType.MICROSOFT) {
    return yield call(
      microsoftAuth,
      codeResponse as MicrosoftCodeResponse,
      userTimeZone,
      switchAccount,
      !!updateIntegration,
      integrationType,
      inviteTenantId,
      login,
    );
  }
  throw new Error(`Unsupported third party type: ${type}`);
}

export function* handleAuthResponse(
  authResponse: ThirdPartyLambdaResponse,
  type: ThirdPartyType,
  switchAccount: boolean,
  updateIntegration: boolean,
  inviteTenantId: string | null,
  login: boolean
) {
  const { adminDataRecords, staffRecord, lastSuperAdmin, isNew, inviteExpired } = authResponse;

  // if invite expired - can be true only for invite link
  if (!!inviteExpired) {
    // to stop isFetching
    yield put(authentificationActions.thirdPartyAuthSuccess());
    // show invite expired popup
    yield put<any>(notificationsActions.showToast(INVITE_EXPIRED_ERROR_TOAST));
  } else {
    // If there are no admin data records, throw an error
    if (!adminDataRecords) {
      throw new Error('User not found in the system');
    }

    // SUMO1 Admin
    if (staffRecord) {
      yield put(authentificationActions.setSUMO1AdminData(staffRecord));
      localStorage.setItem(UserSettingsKeys.SUMO1_STAFF_DATA, JSON.stringify(staffRecord));
    }
    // whether the user is the last Super Admin in the current tenant with other users
    yield put(authentificationActions.setIsLastSuperAdmin(!!lastSuperAdmin));

    // We update Third Party?
    if (!updateIntegration || switchAccount) {
      yield put(authentificationActions.setThirdParty(type));
      localStorage.setItem(THIRD_PARTY, type);
      // Handle the admin data records
      if (!switchAccount) {
        yield call(handleAdminDataRecords, adminDataRecords, inviteTenantId, login, isNew);
      }
    }
  }
}

export function* handleAdminDataRecords(
  adminDataRecords: CreateAdminDataInput[],
  inviteTenantId: string | null,
  login: boolean,
  isNew: boolean
) {
  if (adminDataRecords.length > 1) {
    // If the user has more than one admin data record, handle multiple admin data records
    yield call(handleMultipleAdminDataRecords, adminDataRecords, inviteTenantId, login, isNew);
    // If everything is successfull, dispatch the third party authentication success action
    // only for multiple invites (pop-up mode) to stop Preloader
    yield put(authentificationActions.thirdPartyAuthSuccess());
  } else {
    // If the user only has one admin data record, handle the single admin data record
    yield call(handleSingleAdminDataRecord, adminDataRecords[0]);
  }
}

export function* handleMultipleAdminDataRecords(
  adminDataRecords: CreateAdminDataInput[],
  inviteTenantId: string | null,
  login: boolean,
  isNew: boolean
) {
  if (login) {
    // If the user is logging in, find the active admin data record and handle it
    const activeAdminData = adminDataRecords.find((record) => record.status === adminDataStatus.active);
    if (activeAdminData) {
      // If an active admin data record is found, handle it
      yield call(handleSingleAdminDataRecord, activeAdminData);
    } else {
      // If no active admin data record is found, choose first adminData
      yield call(handleSingleAdminDataRecord, adminDataRecords[0]);
    }
    // If the user has an invite tenant ID
  } else if (inviteTenantId) {
    // if one of the admin data records matches the invite tenant ID, handle the invited tenant
    yield call(handleInvitedTenant, adminDataRecords, inviteTenantId);
  } else if (adminDataRecords.length === 2 && !isNew) {
    // If the user has exactly two admin data records, handle the two admin data records, but if user is new we'll not show Leave Account popup
    yield call(handleTwoAdminDataRecords, adminDataRecords);
  } else {
    yield put(authentificationActions.setAccounts(adminDataRecords));
  }
}

function* handleTwoAdminDataRecords(adminDataRecords: CreateAdminDataInput[]) {
  // find newTenant and existing based on the adminData status
  const newTenant = adminDataRecords.find(
    (record) => record.invitedByName && record.status === adminDataStatus.inviteSent
  );
  const existingTenant = adminDataRecords.find((record) => (record.status === adminDataStatus.active || record.status === adminDataStatus.inactive));
  // If both the new tenant and the existing tenant exist, set the invite to account
  if (!!newTenant && !!existingTenant) {
    yield put(authentificationActions.setInviteToAccount({ newTenant, existingTenant }));
  } else {
    // If either the new tenant or the existing tenant does not exist, throw an error
    throw new Error('handleInvitedByEmailTenant(): tenant by email not found or active tenant is not found');
  }
}

function* handleInvitedTenant(adminDataRecords: CreateAdminDataInput[], inviteTenantId: string) {
  // Find the new tenant and the existing tenant based on the invite tenant ID
  const newTenant = adminDataRecords.find((record) => record.tenantId === inviteTenantId);
  const existingTenant = adminDataRecords.find((record) => (record.status === adminDataStatus.active || record.status === adminDataStatus.inactive));
  // If both the new tenant and the existing tenant exist, set the invite to account
  if (!!newTenant && !!existingTenant) {
    if (newTenant.tenantId === existingTenant.tenantId) {
      // if new tenant and existing are the same
      yield call(handleSingleAdminDataRecord, existingTenant);
    } else {
      yield put(authentificationActions.setInviteToAccount({ newTenant, existingTenant }));
    }
  } else {
    // If either the new tenant or the existing tenant does not exist, throw an error
    throw new Error('handleInvitedTenant(): tenantId is wrong or active tenant is not found');
  }
}

export function* handleSingleAdminDataRecord(adminDataRecord: CreateAdminDataInput) {
  // Get the tenant ID from the admin data record
  const tenant = adminDataRecord.tenantId;
  // If the tenant ID exists, update the user data and check the license
  if (tenant) {
    // If everything is successful, dispatch the choose an account success action
    yield put(globalActions.getMainDataRequest());
    yield put(authentificationActions.chooseAnAccountSuccess());
  } else {
    // If the tenant ID does not exist, throw an error
    throw new Error('tenantId in chosen item in adminDataRecords was not found');
  }
}
