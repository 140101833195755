import { CSSProperties, memo } from 'react';
import { CodeResponse, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Button } from 'primereact/button';

interface IGoogleAuthButton {
  disabled?: boolean;
  label?: string;
  onSuccess: (codeResponse: CodeResponse) => void;
  sx?: CSSProperties;
  icon?: string;
  rounded?: boolean;
  className?: string;
  login?: boolean;
}

export const GoogleAuthButton = memo(
  ({ disabled, label, onSuccess, sx, icon, rounded, className, login }: IGoogleAuthButton) => (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <GoogleSignUpButtonInner
        disabled={disabled}
        label={label}
        onSuccess={onSuccess}
        sx={sx}
        icon={icon}
        rounded={rounded}
        className={className}
        login={login}
      />
    </GoogleOAuthProvider>
  )
);

const GoogleSignUpButtonInner = memo(
  ({ disabled, label, onSuccess, sx, icon, rounded, className, login }: IGoogleAuthButton) => {
    const googleLogin = useGoogleLogin({
      onSuccess: (response: any) => {
        onSuccess({
          code: response.code || response.access_token,
          ...response
        } as CodeResponse);
      },
      onError: (errorResponse: any) => console.error(errorResponse),
      flow: login ? 'implicit' : 'auth-code',
      scope: login ? '' : 'https://www.googleapis.com/auth/calendar.events',
      ux_mode: 'popup',
    } as any);

    return (
      <Button
        label={label}
        icon={icon}
        disabled={disabled}
        style={sx}
        onClick={googleLogin as any}
        rounded={rounded}
        className={className}
      />
    );
  }
);

GoogleAuthButton.displayName = 'GoogleAuthButton';
GoogleSignUpButtonInner.displayName = 'GoogleSignUpButtonInner';
