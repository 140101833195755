export const ConnectIntegrationPage = Object.freeze({
  BENEFITS_TITLE: `Avantages`,
  DISCONNECT_DESCRIPTION: `Êtes-vous sûr de vouloir vous déconnecter `,
  GOOGLE_CALENDAR_BENEFITS: `Réservation et reprogrammation automatiques des réunions dans votre Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Vous devez accorder l'accès à votre Google Calendar.`,
  GOOGLE_MEET_BENEFITS: `Création automatique de réunions Google Meet au moment de la programmation d'un événement. Partage instantané des détails de la conférence dès la confirmation.`,
  GOOGLE_MEET_REQUIREMENTS: `En fonction de votre configuration, votre administrateur du compte Google Meet peut être amené à pré-approuver SUMO1 dans le Google Marketplace.`,
  MICROSOFT_CALENDAR_BENEFITS: `Réservation et reprogrammation automatiques des réunions dans votre Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Vous devez accorder l'accès à votre Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Création automatique de réunions Microsoft Teams au moment de la programmation d'un événement. Partage instantané des détails de la conférence dès la confirmation.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `En fonction de votre configuration, votre administrateur du compte Microsoft Teams peut être amené à pré-approuver SUMO1 dans le Microsoft Marketplace.`,
  REQUIREMENTS_TITLE: `Exigences`,
  ZOOM_BENEFITS: `Création automatique de réunions Zoom au moment de la programmation d'un événement. Partage instantané des détails de la conférence dès la confirmation.`,
  ZOOM_REQUIREMENTS: `En fonction de votre configuration, votre administrateur du compte Zoom peut être amené à pré-approuver SUMO1 dans le Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Gagnez plus de temps en automatisant la vidéoconférence, la planification, et plus encore.`,
  FILTER_ALL: `Tout`,
  FILTER_CALENDARS: `Calendriers`,
  FILTER_VIDEO_CONFERENCE: `Vidéoconférence`,
  GOOGLE_CALENDAR_DESC: `Réservation et reprogrammation automatiques des réunions dans votre Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MEET_DESC: `Ajout automatique des détails Google Meet à vos réunions.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_CALENDAR_DESC: `Réservation et reprogrammation automatiques des réunions dans votre Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Ajout automatique des détails Microsoft Teams à vos réunions.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `Aucun élément trouvé.`,
  REQUIREMENTS_TITLE: `Exigences`,
  TITLE: `Intégration`,
  ZOOM_DESC: `Ajout automatique des détails Zoom à vos réunions SUMO.`,
  ZOOM_TITLE: `Réunion Zoom`,
});
