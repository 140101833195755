import { createSelector } from 'reselect';
import { State } from '../../rootStore';
import { UserStatus } from '../../users/typings';
import { convertUnixToFormattedDate, formatDateTimeThroughGap } from '../../../services/DateService';
import dayjs from 'dayjs';
import { DateFormat, TimeFormat, userSettingsSelectors } from '../../userSettings';
import { getRoleNameByRole } from './utils';
import { opsConsoleSelectors } from '../common/selectors';

import { OPSConsoleRole, orgsSelectors } from '../orgs';

const usersState = (state: State) => state.OPSConsoleUsers;

const selectIsFetching = createSelector(usersState, (state) => state.isFetching);
const selectError = createSelector(usersState, (state) => state.error);
const selectUsers = createSelector(usersState, (state) => state.users);
const selectUserDetails = createSelector(usersState, (state) => state.userDetails);

const selectUsersNumber = createSelector(selectUsers, (users) => users.length);

const selectSearchUsers = createSelector(selectUsers, opsConsoleSelectors.selectSearchString, (users, searchString) =>
  users
    .filter(
      (user) =>
        user?.userName?.toLowerCase().includes(searchString.trim().toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchString.trim().toLowerCase())
    )
    .sort((userA, userB) => (userB.lastActive > userA.lastActive ? 1 : -1))
);

const selectSuperAdminsEmails = createSelector(
  selectSearchUsers,
  orgsSelectors.selectRolesDetails as (state: State) => OPSConsoleRole[],
  (users, rolesDetails) =>
    users
      .filter(
        (user) => rolesDetails && rolesDetails.some((role) => role.id === user.role.id && role.isAccessToAllWorkspaces)
      )
      .map((user) => user.email)
);

const selectUserName = createSelector(selectUserDetails, (user) => user?.userName || '');
const selectUserEmail = createSelector(selectUserDetails, (user) => user?.email || '');
const selectUserId = createSelector(selectUserDetails, (user) => user?.userId || '');
const selectUserNameOrEmail = createSelector(selectUserName, selectUserEmail, (name, email) => name || email || '');
const selectAccountName = createSelector(selectUserDetails, (user) => user?.accountName || '');
const selectUserStatus = createSelector(selectUserDetails, (user) => user?.status || '');
const selectTenantId = createSelector(selectUserDetails, (user) => user?.tenantId || '');
const selectBookedMeetings = createSelector(selectUserDetails, (user) => user?.bookedMeetings || 0);
const selectFirstLogin = createSelector(
  selectUserDetails,
  (user) => (user?.firstLogin && formatDateTimeThroughGap(user.firstLogin)) || ''
);
const selectLastActive = createSelector(
  selectUserDetails,
  userSettingsSelectors.selectDateFormat,
  userSettingsSelectors.selectTimeFormat,
  (user, dateFormat, timeFormat) => {
    const finalDateFormat = dateFormat ?? DateFormat.default;
    const finalTimeFormat = timeFormat ?? TimeFormat.default;
    return user?.lastActive ? convertUnixToFormattedDate(Number(user.lastActive), finalDateFormat, finalTimeFormat) : '';
  }
);
const selectUserRole = createSelector(selectUserDetails, (user) => user?.role);

const selectRoleName = createSelector(selectUserRole, (role) => getRoleNameByRole(role));

const selectUserIsActive = createSelector(selectUserStatus, (status) => status === UserStatus.Active);

const selectUserLicense = createSelector(selectUserDetails, (user) => user?.license);
const selectTerm = createSelector(selectUserLicense, (license) => license?.type);
const selectUsersAssigned = createSelector(selectUserLicense, (license) => license?.assigned || 0);
const selectLicenseEndDate = createSelector(selectUserLicense, (license) =>
  license?.endDate ? dayjs(license?.endDate).format(DateFormat.DDMMYYYY) : ''
);

const selectIsPaginatorEnabled = createSelector(selectSearchUsers, (users) => users.length > 10);

export const OPSConsoleUsersSelectors = {
  selectIsFetching,
  selectError,
  selectUsers,
  selectUsersNumber,
  selectUserDetails,
  selectSearchUsers,
  selectSuperAdminsEmails,
  selectUserName,
  selectUserId,
  selectUserEmail,
  selectUserNameOrEmail,
  selectAccountName,
  selectUserStatus,
  selectTenantId,
  selectBookedMeetings,
  selectFirstLogin,
  selectLastActive,
  selectRoleName,
  selectUserIsActive,
  selectTerm,
  selectUsersAssigned,
  selectLicenseEndDate,
  selectIsPaginatorEnabled,
};
