import { CreateUserEventInput, LocationType } from '../../API';

export enum SortMethods {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  DATE_RANGE = 'DATE_RANGE',
}

export enum Statuses {
  ALL = 'ALL',
  BOOKED = 'BOOKED',
  CANCELED = 'CANCELED',
}

export type UpdateScheduledMeeting = CreateUserEventInput & { editMeetingNotes?: boolean };
