/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelUserDataPrimaryCompositeKeyConditionInput = {
  eq?: ModelUserDataPrimaryCompositeKeyInput | null,
  le?: ModelUserDataPrimaryCompositeKeyInput | null,
  lt?: ModelUserDataPrimaryCompositeKeyInput | null,
  ge?: ModelUserDataPrimaryCompositeKeyInput | null,
  gt?: ModelUserDataPrimaryCompositeKeyInput | null,
  between?: Array< ModelUserDataPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelUserDataPrimaryCompositeKeyInput | null,
};

export type ModelUserDataPrimaryCompositeKeyInput = {
  link?: string | null,
  tenant?: string | null,
};

export type ModelUserDataFilterInput = {
  userId?: ModelStringInput | null,
  tenant?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  link?: ModelStringInput | null,
  recordType?: ModelUserRecordTypeInput | null,
  lastActive?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserDataFilterInput | null > | null,
  or?: Array< ModelUserDataFilterInput | null > | null,
  not?: ModelUserDataFilterInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelUserRecordTypeInput = {
  eq?: UserRecordType | null,
  ne?: UserRecordType | null,
};

export enum UserRecordType {
  MAIN_LINK = "MAIN_LINK",
  PROFILE = "PROFILE",
  AVAILABILITY = "AVAILABILITY",
  BOOKING_PAGE = "BOOKING_PAGE",
}


export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserDataConnection = {
  __typename: "ModelUserDataConnection",
  items:  Array<UserData | null >,
  nextToken?: string | null,
};

export type UserData = {
  __typename: "UserData",
  userId: string,
  tenant: string,
  owner?: string | null,
  link: string,
  recordType: UserRecordType,
  userSettings?: UserSettings | null,
  availabilityData?: Availability | null,
  statistics?: Statistics | null,
  lastActive?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UserSettings = {
  __typename: "UserSettings",
  userDefaultAvailabilityId?: string | null,
  avatar?: string | null,
  fullName?: string | null,
  phoneDetails?: string | null,
  language?: string | null,
  dateFormat?: string | null,
  timeFormat?: string | null,
  country?: string | null,
  countryCode?: string | null,
  timeZone?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  integrations?:  Array<Integration | null > | null,
  isAdmin?: boolean | null,
  isQuickSetupFinished?: boolean | null,
  isInvitePeopleClosed?: boolean | null,
  defaultVideoIntegration?: VideoConferenceType | null,
  calendarWarningDate?: string | null,
};

export type Integration = {
  __typename: "Integration",
  type: IntegrationType,
  settings?: string | null,
};

export enum IntegrationType {
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  MICROSOFT_CALENDAR = "MICROSOFT_CALENDAR",
  ZOOM = "ZOOM",
  GOOGLE_MEET = "GOOGLE_MEET",
  MICROSOFT_TEAMS = "MICROSOFT_TEAMS",
}


export enum VideoConferenceType {
  ZOOM = "ZOOM",
  GOOGLE_MEET = "GOOGLE_MEET",
  MICROSOFT_TEAMS = "MICROSOFT_TEAMS",
}


export type Availability = {
  __typename: "Availability",
  id: string,
  name: string,
  activeBookingPages?: Array< string | null > | null,
  isDefault?: boolean | null,
  weeklyHours?:  Array<WeeklyHours | null > | null,
  overrides?:  Array<Overrides | null > | null,
};

export type WeeklyHours = {
  __typename: "WeeklyHours",
  type?: WeekDay | null,
  time?:  Array<TimeFromTo | null > | null,
};

export enum WeekDay {
  SUN = "SUN",
  MON = "MON",
  TUE = "TUE",
  WED = "WED",
  THU = "THU",
  FRI = "FRI",
  SAT = "SAT",
}


export type TimeFromTo = {
  __typename: "TimeFromTo",
  from?: string | null,
  to?: string | null,
};

export type Overrides = {
  __typename: "Overrides",
  days?: DateTimeFromTo | null,
  time?:  Array<TimeFromTo | null > | null,
  isBlock?: boolean | null,
};

export type DateTimeFromTo = {
  __typename: "DateTimeFromTo",
  from?: string | null,
  to?: string | null,
};

export type Statistics = {
  __typename: "Statistics",
  eventCreated?: number | null,
  emailsSent?: number | null,
  eventRescheduled?: number | null,
  eventCanceled?: number | null,
};

export type CreateTeamRoundRobinInput = {
  teamId: string,
  userId: string,
  lastCreatedEvent?: string | null,
};

export type ModelTeamRoundRobinConditionInput = {
  userId?: ModelStringInput | null,
  lastCreatedEvent?: ModelStringInput | null,
  and?: Array< ModelTeamRoundRobinConditionInput | null > | null,
  or?: Array< ModelTeamRoundRobinConditionInput | null > | null,
  not?: ModelTeamRoundRobinConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type TeamRoundRobin = {
  __typename: "TeamRoundRobin",
  teamId: string,
  userId: string,
  lastCreatedEvent?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTeamRoundRobinInput = {
  teamId: string,
  userId?: string | null,
  lastCreatedEvent?: string | null,
};

export type DeleteTeamRoundRobinInput = {
  teamId: string,
};

export type CreateTeamUserRoundRobinInput = {
  teamId: string,
  userId: string,
  lastCreatedEvent?: string | null,
};

export type ModelTeamUserRoundRobinConditionInput = {
  lastCreatedEvent?: ModelStringInput | null,
  and?: Array< ModelTeamUserRoundRobinConditionInput | null > | null,
  or?: Array< ModelTeamUserRoundRobinConditionInput | null > | null,
  not?: ModelTeamUserRoundRobinConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type TeamUserRoundRobin = {
  __typename: "TeamUserRoundRobin",
  teamId: string,
  userId: string,
  lastCreatedEvent?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTeamUserRoundRobinInput = {
  teamId: string,
  userId: string,
  lastCreatedEvent?: string | null,
};

export type DeleteTeamUserRoundRobinInput = {
  teamId: string,
  userId: string,
};

export type CreateUserDataInput = {
  userId: string,
  tenant: string,
  owner?: string | null,
  link: string,
  recordType: UserRecordType,
  userSettings?: UserSettingsInput | null,
  availabilityData?: AvailabilityInput | null,
  statistics?: StatisticsInput | null,
  lastActive?: string | null,
};

export type UserSettingsInput = {
  userDefaultAvailabilityId?: string | null,
  avatar?: string | null,
  fullName?: string | null,
  phoneDetails?: string | null,
  language?: string | null,
  dateFormat?: string | null,
  timeFormat?: string | null,
  country?: string | null,
  countryCode?: string | null,
  timeZone?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  integrations?: Array< IntegrationInput | null > | null,
  isAdmin?: boolean | null,
  isQuickSetupFinished?: boolean | null,
  isInvitePeopleClosed?: boolean | null,
  defaultVideoIntegration?: VideoConferenceType | null,
  calendarWarningDate?: string | null,
};

export type IntegrationInput = {
  type: IntegrationType,
  settings?: string | null,
};

export type AvailabilityInput = {
  id: string,
  name: string,
  activeBookingPages?: Array< string | null > | null,
  isDefault?: boolean | null,
  weeklyHours?: Array< WeeklyHoursInput | null > | null,
  overrides?: Array< OverridesInput | null > | null,
};

export type WeeklyHoursInput = {
  type?: WeekDay | null,
  time?: Array< TimeFromToInput | null > | null,
};

export type TimeFromToInput = {
  from?: string | null,
  to?: string | null,
};

export type OverridesInput = {
  days?: DateTimeFromToInput | null,
  time?: Array< TimeFromToInput | null > | null,
  isBlock?: boolean | null,
};

export type DateTimeFromToInput = {
  from?: string | null,
  to?: string | null,
};

export type StatisticsInput = {
  eventCreated?: number | null,
  emailsSent?: number | null,
  eventRescheduled?: number | null,
  eventCanceled?: number | null,
};

export type ModelUserDataConditionInput = {
  owner?: ModelStringInput | null,
  recordType?: ModelUserRecordTypeInput | null,
  lastActive?: ModelStringInput | null,
  and?: Array< ModelUserDataConditionInput | null > | null,
  or?: Array< ModelUserDataConditionInput | null > | null,
  not?: ModelUserDataConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserDataInput = {
  userId: string,
  tenant: string,
  owner?: string | null,
  link: string,
  recordType?: UserRecordType | null,
  userSettings?: UserSettingsInput | null,
  availabilityData?: AvailabilityInput | null,
  statistics?: StatisticsInput | null,
  lastActive?: string | null,
};

export type DeleteUserDataInput = {
  userId: string,
  link: string,
  tenant: string,
};

export type CreateUserEventInput = {
  userId: string,
  tenant: string,
  eventDate: string,
  eventId: string,
  editKey?: string | null,
  shortLink?: string | null,
  shortEditLink?: string | null,
  bookingPageId: string,
  bookingPageName: string,
  owner?: string | null,
  startTime: string,
  endTime: string,
  beforeStartTime?: number | null,
  afterEndTime?: number | null,
  location?: LocationInput | null,
  integrations?: Array< IntegrationInput | null > | null,
  timeZone?: string | null,
  note?: string | null,
  cohosts?: Array< string | null > | null,
  guestEmails?: Array< string | null > | null,
  inputFields?: Array< CustomFieldInput | null > | null,
  canceled?: CanceledEventInput | null,
  executionIds?: ExecutionIdsInput | null,
  isNoShow?: boolean | null,
  host?: string | null,
  internalNotes?: string | null,
  isSMSAllowed?: boolean | null,
  hostName?: string | null,
  cohostNames?: Array< string | null > | null,
};

export type LocationInput = {
  type: LocationType,
  settings?: string | null,
};

export enum LocationType {
  IN_PERSONAL = "IN_PERSONAL",
  PHONE_CALL = "PHONE_CALL",
  VIDEO_CONFERENCE = "VIDEO_CONFERENCE",
}


export type CustomFieldInput = {
  id?: string | null,
  answers?: Array< string | null > | null,
  enabled?: boolean | null,
  label?: string | null,
  required?: boolean | null,
  type?: CustomType | null,
  value?: string | null,
  fieldType?: CustomFieldType | null,
};

export enum CustomType {
  CHECKBOXES = "CHECKBOXES",
  RADIO_BUTTONS = "RADIO_BUTTONS",
  SELECT = "SELECT",
  PHONE = "PHONE",
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
}


export enum CustomFieldType {
  NAME = "NAME",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  CUSTOM = "CUSTOM",
  LOCATION = "LOCATION",
}


export type CanceledEventInput = {
  isCanceled: boolean,
  type?: CanceledType | null,
  note?: string | null,
};

export enum CanceledType {
  UNEXPECTED = "UNEXPECTED",
  INVITE_OTHERS = "INVITE_OTHERS",
  TIME = "TIME",
  OTHER = "OTHER",
}


export type ExecutionIdsInput = {
  emailExecutionIds?: Array< string | null > | null,
  smsExecutionIds?: Array< string | null > | null,
};

export type ModelUserEventConditionInput = {
  editKey?: ModelStringInput | null,
  shortLink?: ModelStringInput | null,
  shortEditLink?: ModelStringInput | null,
  bookingPageId?: ModelStringInput | null,
  bookingPageName?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  beforeStartTime?: ModelIntInput | null,
  afterEndTime?: ModelIntInput | null,
  timeZone?: ModelStringInput | null,
  note?: ModelStringInput | null,
  cohosts?: ModelStringInput | null,
  guestEmails?: ModelStringInput | null,
  isNoShow?: ModelBooleanInput | null,
  host?: ModelStringInput | null,
  internalNotes?: ModelStringInput | null,
  isSMSAllowed?: ModelBooleanInput | null,
  hostName?: ModelStringInput | null,
  cohostNames?: ModelStringInput | null,
  and?: Array< ModelUserEventConditionInput | null > | null,
  or?: Array< ModelUserEventConditionInput | null > | null,
  not?: ModelUserEventConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UserEvent = {
  __typename: "UserEvent",
  userId: string,
  tenant: string,
  eventDate: string,
  eventId: string,
  editKey?: string | null,
  shortLink?: string | null,
  shortEditLink?: string | null,
  bookingPageId: string,
  bookingPageName: string,
  owner?: string | null,
  startTime: string,
  endTime: string,
  beforeStartTime?: number | null,
  afterEndTime?: number | null,
  location?: Location | null,
  integrations?:  Array<Integration | null > | null,
  timeZone?: string | null,
  note?: string | null,
  cohosts?: Array< string | null > | null,
  guestEmails?: Array< string | null > | null,
  inputFields?:  Array<CustomField | null > | null,
  canceled?: CanceledEvent | null,
  executionIds?: ExecutionIds | null,
  isNoShow?: boolean | null,
  host?: string | null,
  internalNotes?: string | null,
  isSMSAllowed?: boolean | null,
  hostName?: string | null,
  cohostNames?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type Location = {
  __typename: "Location",
  type: LocationType,
  settings?: string | null,
};

export type CustomField = {
  __typename: "CustomField",
  id?: string | null,
  answers?: Array< string | null > | null,
  enabled?: boolean | null,
  label?: string | null,
  required?: boolean | null,
  type?: CustomType | null,
  value?: string | null,
  fieldType?: CustomFieldType | null,
};

export type CanceledEvent = {
  __typename: "CanceledEvent",
  isCanceled: boolean,
  type?: CanceledType | null,
  note?: string | null,
};

export type ExecutionIds = {
  __typename: "ExecutionIds",
  emailExecutionIds?: Array< string | null > | null,
  smsExecutionIds?: Array< string | null > | null,
};

export type UpdateUserEventInput = {
  userId: string,
  tenant: string,
  eventDate: string,
  eventId: string,
  editKey?: string | null,
  shortLink?: string | null,
  shortEditLink?: string | null,
  bookingPageId?: string | null,
  bookingPageName?: string | null,
  owner?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  beforeStartTime?: number | null,
  afterEndTime?: number | null,
  location?: LocationInput | null,
  integrations?: Array< IntegrationInput | null > | null,
  timeZone?: string | null,
  note?: string | null,
  cohosts?: Array< string | null > | null,
  guestEmails?: Array< string | null > | null,
  inputFields?: Array< CustomFieldInput | null > | null,
  canceled?: CanceledEventInput | null,
  executionIds?: ExecutionIdsInput | null,
  isNoShow?: boolean | null,
  host?: string | null,
  internalNotes?: string | null,
  isSMSAllowed?: boolean | null,
  hostName?: string | null,
  cohostNames?: Array< string | null > | null,
};

export type DeleteUserEventInput = {
  userId: string,
  eventDate: string,
  eventId: string,
  tenant: string,
};

export type CreateAdminDataInput = {
  email: string,
  userId?: string | null,
  roleId: string,
  status?: string | null,
  tenantId: string,
  workspaceIds?: Array< string | null > | null,
  invitedById?: string | null,
  invitedByName?: string | null,
  team?: Array< string | null > | null,
  bookingTemplateIds?: Array< string | null > | null,
  expirationDate?: string | null,
};

export type ModelAdminDataConditionInput = {
  userId?: ModelStringInput | null,
  roleId?: ModelStringInput | null,
  status?: ModelStringInput | null,
  workspaceIds?: ModelStringInput | null,
  invitedById?: ModelStringInput | null,
  invitedByName?: ModelStringInput | null,
  team?: ModelStringInput | null,
  bookingTemplateIds?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  and?: Array< ModelAdminDataConditionInput | null > | null,
  or?: Array< ModelAdminDataConditionInput | null > | null,
  not?: ModelAdminDataConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type AdminData = {
  __typename: "AdminData",
  email: string,
  userId?: string | null,
  roleId: string,
  status?: string | null,
  tenantId: string,
  workspaceIds?: Array< string | null > | null,
  invitedById?: string | null,
  invitedByName?: string | null,
  team?: Array< string | null > | null,
  bookingTemplateIds?: Array< string | null > | null,
  expirationDate?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateAdminDataInput = {
  email: string,
  userId?: string | null,
  roleId?: string | null,
  status?: string | null,
  tenantId: string,
  workspaceIds?: Array< string | null > | null,
  invitedById?: string | null,
  invitedByName?: string | null,
  team?: Array< string | null > | null,
  bookingTemplateIds?: Array< string | null > | null,
  expirationDate?: string | null,
};

export type DeleteAdminDataInput = {
  email: string,
  tenantId: string,
};

export type CreateTenantInput = {
  tenantId: string,
  name?: string | null,
  status?: TenantStatus | null,
  tenantStyle?: BookingPageStyleInput | null,
  workspace?: Array< WorkspaceInput | null > | null,
  type?: OrgType | null,
  note?: string | null,
  subscription?: string | null,
};

export enum TenantStatus {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
}


export type BookingPageStyleInput = {
  avatar?: string | null,
  backgroundImage?: string | null,
  opacity?: number | null,
  css?: string | null,
  footer?: string | null,
  lookAndFeel?: LookAndFeelInput | null,
  defaultWeeklyHours?: Array< WeeklyHoursInput | null > | null,
};

export type LookAndFeelInput = {
  siteColorPrimary?: string | null,
  siteColorButtons?: string | null,
  siteColorBackground?: string | null,
  fontColorHeader?: string | null,
  fontColorSubHeader?: string | null,
  fontColorMainText?: string | null,
  fontHeader?: string | null,
  fontSubheader?: string | null,
  fontMainText?: string | null,
  CSSStyled?: string | null,
  FooterHTML?: string | null,
};

export type WorkspaceInput = {
  id: string,
  name: string,
  isActive: boolean,
  isPhoneRequired: boolean,
  bookingPageIndex?: number | null,
  style?: BookingPageStyleInput | null,
  labels?: BookingPageLabelsInput | null,
};

export type BookingPageLabelsInput = {
  eventNameLabel?: string | null,
  bookingTemplateLabel?: string | null,
  detailsLabel?: string | null,
  dateLabel?: string | null,
  timeLabel?: string | null,
  informationLabel?: string | null,
  additionalLabel?: string | null,
  cancelLabel?: string | null,
  bookButtonLabel?: string | null,
  updateButtonLabel?: string | null,
  bookAnotherButtonLabel?: string | null,
  cancelButtonLabel?: string | null,
  bookTitle?: string | null,
  bookDescription?: string | null,
  rescheduleTitle?: string | null,
  rescheduleDescription?: string | null,
  cancelTitle?: string | null,
  cancelDescription?: string | null,
};

export enum OrgType {
  TRIAL = "TRIAL",
  TEST = "TEST",
  CUSTOMER = "CUSTOMER",
}


export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  status?: ModelTenantStatusInput | null,
  type?: ModelOrgTypeInput | null,
  note?: ModelStringInput | null,
  subscription?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTenantStatusInput = {
  eq?: TenantStatus | null,
  ne?: TenantStatus | null,
};

export type ModelOrgTypeInput = {
  eq?: OrgType | null,
  ne?: OrgType | null,
};

export type Tenant = {
  __typename: "Tenant",
  tenantId: string,
  name?: string | null,
  status?: TenantStatus | null,
  tenantStyle?: BookingPageStyle | null,
  workspace?:  Array<Workspace | null > | null,
  type?: OrgType | null,
  note?: string | null,
  subscription?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type BookingPageStyle = {
  __typename: "BookingPageStyle",
  avatar?: string | null,
  backgroundImage?: string | null,
  opacity?: number | null,
  css?: string | null,
  footer?: string | null,
  lookAndFeel?: LookAndFeel | null,
  defaultWeeklyHours?:  Array<WeeklyHours | null > | null,
};

export type LookAndFeel = {
  __typename: "LookAndFeel",
  siteColorPrimary?: string | null,
  siteColorButtons?: string | null,
  siteColorBackground?: string | null,
  fontColorHeader?: string | null,
  fontColorSubHeader?: string | null,
  fontColorMainText?: string | null,
  fontHeader?: string | null,
  fontSubheader?: string | null,
  fontMainText?: string | null,
  CSSStyled?: string | null,
  FooterHTML?: string | null,
};

export type Workspace = {
  __typename: "Workspace",
  id: string,
  name: string,
  isActive: boolean,
  isPhoneRequired: boolean,
  bookingPageIndex?: number | null,
  style?: BookingPageStyle | null,
  labels?: BookingPageLabels | null,
};

export type BookingPageLabels = {
  __typename: "BookingPageLabels",
  eventNameLabel?: string | null,
  bookingTemplateLabel?: string | null,
  detailsLabel?: string | null,
  dateLabel?: string | null,
  timeLabel?: string | null,
  informationLabel?: string | null,
  additionalLabel?: string | null,
  cancelLabel?: string | null,
  bookButtonLabel?: string | null,
  updateButtonLabel?: string | null,
  bookAnotherButtonLabel?: string | null,
  cancelButtonLabel?: string | null,
  bookTitle?: string | null,
  bookDescription?: string | null,
  rescheduleTitle?: string | null,
  rescheduleDescription?: string | null,
  cancelTitle?: string | null,
  cancelDescription?: string | null,
};

export type UpdateTenantInput = {
  tenantId: string,
  name?: string | null,
  status?: TenantStatus | null,
  tenantStyle?: BookingPageStyleInput | null,
  workspace?: Array< WorkspaceInput | null > | null,
  type?: OrgType | null,
  note?: string | null,
  subscription?: string | null,
};

export type DeleteTenantInput = {
  tenantId: string,
};

export type CreateBookingTemplateInput = {
  id?: string | null,
  workspaceId: string,
  enabled?: boolean | null,
  inviteOthers?: boolean | null,
  enterNote?: boolean | null,
  labels?: BookingPageLabelsInput | null,
  style?: BookingPageStyleInput | null,
  calendar?: BookingCalendarInput | null,
  potentialHosts?: Array< string | null > | null,
  potentialTeams?: Array< string | null > | null,
  what?: BookingTemplateWhatInput | null,
  where?: WhereInput | null,
  when?: WhenInput | null,
  inputFields?: Array< CustomFieldInput | null > | null,
  notifications?: NotificationsInput | null,
  confirmationsPage?: ConfirmationsPageInput | null,
  lastModify?: string | null,
};

export type BookingCalendarInput = {
  timeFormat?: TimeFormat | null,
  calendarFormat?: CalendarFormat | null,
  timeInterval?: number | null,
  topOfInterval?: boolean | null,
  timeZoneType?: TimeZoneType | null,
  selectedTimeZone?: Array< string | null > | null,
  defaultTimeZone?: string | null,
};

export enum TimeFormat {
  HOUR_12 = "HOUR_12",
  HOUR_24 = "HOUR_24",
}


export enum CalendarFormat {
  MONTH_VIEW = "MONTH_VIEW",
  WEEK_VIEW = "WEEK_VIEW",
}


export enum TimeZoneType {
  DETECT_INVITEE = "DETECT_INVITEE",
  DISPLAY_SELECTED = "DISPLAY_SELECTED",
}


export type BookingTemplateWhatInput = {
  customName?: string | null,
  color?: string | null,
};

export type WhereInput = {
  locationTypes?: Array< LocationType | null > | null,
  videoConferenceType?: VideoConferenceType | null,
  defaultLocationType?: LocationType | null,
  inPersonType?: InPersonType | null,
  customAddress?: string | null,
  locations?: Array< string | null > | null,
  phoneCallType?: PhoneCallType | null,
  customPhone?: string | null,
  customCountryCode?: string | null,
  skipTheWhereStep?: boolean | null,
};

export enum InPersonType {
  CUSTOM_ADDRESS = "CUSTOM_ADDRESS",
  LOCATION = "LOCATION",
  INVITEE_LOCATION = "INVITEE_LOCATION",
}


export enum PhoneCallType {
  HOST_PHONE_NUMBER = "HOST_PHONE_NUMBER",
  PROMPT_INVITE = "PROMPT_INVITE",
  CUSTOM_PHONE = "CUSTOM_PHONE",
}


export type WhenInput = {
  duration?: TimeInput | null,
  scheduleBuffer?: TimeInput | null,
  dateRange?: DateRangeInput | null,
  afterEndTime?: string | null,
  beforeStartTime?: string | null,
  eventsPerDay?: number | null,
};

export type TimeInput = {
  count?: number | null,
  timeUnit?: TimeUnit | null,
};

export enum TimeUnit {
  MINUTE = "MINUTE",
  HOUR = "HOUR",
  DAY = "DAY",
}


export type DateRangeInput = {
  type?: DateRangeType | null,
  count?: number | null,
  daysType?: DaysType | null,
  from?: string | null,
  to?: string | null,
};

export enum DateRangeType {
  DAYS_IN_FUTURE = "DAYS_IN_FUTURE",
  SPECIFIC_DATES = "SPECIFIC_DATES",
  UNLIMITED = "UNLIMITED",
}


export enum DaysType {
  CALENDAR_DAYS = "CALENDAR_DAYS",
  WEEKDAYS = "WEEKDAYS",
}


export type NotificationsInput = {
  confirmations?: MessageTemplateInput | null,
  reschedule?: MessageTemplateInput | null,
  reminders?: MessageTemplateInput | null,
  cancelations?: MessageTemplateInput | null,
  followUp?: MessageTemplateInput | null,
  smsReminder?: MessageTemplateInput | null,
};

export type MessageTemplateInput = {
  enabled?: boolean | null,
  subject?: string | null,
  body?: string | null,
  timing?: Array< number | null > | null,
};

export type ConfirmationsPageInput = {
  type?: ConfirmationsPageType | null,
  externalLink?: string | null,
  anotherEventEnabled?: boolean | null,
  rescheduleEnabled?: boolean | null,
  cancelEnabled?: boolean | null,
  customLinks?: Array< CustomLinkInput | null > | null,
  cancelationPolicy?: string | null,
};

export enum ConfirmationsPageType {
  OUR_PAGE = "OUR_PAGE",
  REDIRECT = "REDIRECT",
}


export type CustomLinkInput = {
  enabled?: boolean | null,
  name?: string | null,
  link?: string | null,
};

export type ModelBookingTemplateConditionInput = {
  enabled?: ModelBooleanInput | null,
  inviteOthers?: ModelBooleanInput | null,
  enterNote?: ModelBooleanInput | null,
  potentialHosts?: ModelStringInput | null,
  potentialTeams?: ModelStringInput | null,
  lastModify?: ModelStringInput | null,
  and?: Array< ModelBookingTemplateConditionInput | null > | null,
  or?: Array< ModelBookingTemplateConditionInput | null > | null,
  not?: ModelBookingTemplateConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type BookingTemplate = {
  __typename: "BookingTemplate",
  id: string,
  workspaceId: string,
  enabled?: boolean | null,
  inviteOthers?: boolean | null,
  enterNote?: boolean | null,
  labels?: BookingPageLabels | null,
  style?: BookingPageStyle | null,
  calendar?: BookingCalendar | null,
  potentialHosts?: Array< string | null > | null,
  potentialTeams?: Array< string | null > | null,
  what?: BookingTemplateWhat | null,
  where?: Where | null,
  when?: When | null,
  inputFields?:  Array<CustomField | null > | null,
  notifications?: Notifications | null,
  confirmationsPage?: ConfirmationsPage | null,
  lastModify?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type BookingCalendar = {
  __typename: "BookingCalendar",
  timeFormat?: TimeFormat | null,
  calendarFormat?: CalendarFormat | null,
  timeInterval?: number | null,
  topOfInterval?: boolean | null,
  timeZoneType?: TimeZoneType | null,
  selectedTimeZone?: Array< string | null > | null,
  defaultTimeZone?: string | null,
};

export type BookingTemplateWhat = {
  __typename: "BookingTemplateWhat",
  customName?: string | null,
  color?: string | null,
};

export type Where = {
  __typename: "Where",
  locationTypes?: Array< LocationType | null > | null,
  videoConferenceType?: VideoConferenceType | null,
  defaultLocationType?: LocationType | null,
  inPersonType?: InPersonType | null,
  customAddress?: string | null,
  locations?: Array< string | null > | null,
  phoneCallType?: PhoneCallType | null,
  customPhone?: string | null,
  customCountryCode?: string | null,
  skipTheWhereStep?: boolean | null,
};

export type When = {
  __typename: "When",
  duration?: Time | null,
  scheduleBuffer?: Time | null,
  dateRange?: DateRange | null,
  afterEndTime?: string | null,
  beforeStartTime?: string | null,
  eventsPerDay?: number | null,
};

export type Time = {
  __typename: "Time",
  count?: number | null,
  timeUnit?: TimeUnit | null,
};

export type DateRange = {
  __typename: "DateRange",
  type?: DateRangeType | null,
  count?: number | null,
  daysType?: DaysType | null,
  from?: string | null,
  to?: string | null,
};

export type Notifications = {
  __typename: "Notifications",
  confirmations?: MessageTemplate | null,
  reschedule?: MessageTemplate | null,
  reminders?: MessageTemplate | null,
  cancelations?: MessageTemplate | null,
  followUp?: MessageTemplate | null,
  smsReminder?: MessageTemplate | null,
};

export type MessageTemplate = {
  __typename: "MessageTemplate",
  enabled?: boolean | null,
  subject?: string | null,
  body?: string | null,
  timing?: Array< number | null > | null,
};

export type ConfirmationsPage = {
  __typename: "ConfirmationsPage",
  type?: ConfirmationsPageType | null,
  externalLink?: string | null,
  anotherEventEnabled?: boolean | null,
  rescheduleEnabled?: boolean | null,
  cancelEnabled?: boolean | null,
  customLinks?:  Array<CustomLink | null > | null,
  cancelationPolicy?: string | null,
};

export type CustomLink = {
  __typename: "CustomLink",
  enabled?: boolean | null,
  name?: string | null,
  link?: string | null,
};

export type UpdateBookingTemplateInput = {
  id: string,
  workspaceId: string,
  enabled?: boolean | null,
  inviteOthers?: boolean | null,
  enterNote?: boolean | null,
  labels?: BookingPageLabelsInput | null,
  style?: BookingPageStyleInput | null,
  calendar?: BookingCalendarInput | null,
  potentialHosts?: Array< string | null > | null,
  potentialTeams?: Array< string | null > | null,
  what?: BookingTemplateWhatInput | null,
  where?: WhereInput | null,
  when?: WhenInput | null,
  inputFields?: Array< CustomFieldInput | null > | null,
  notifications?: NotificationsInput | null,
  confirmationsPage?: ConfirmationsPageInput | null,
  lastModify?: string | null,
};

export type DeleteBookingTemplateInput = {
  id: string,
  workspaceId: string,
};

export type CreateBookingPageInput = {
  id?: string | null,
  workspaceId: string,
  inviteOthers?: boolean | null,
  enterNote?: boolean | null,
  shortLink?: string | null,
  adminOnly?: boolean | null,
  labels?: BookingPageLabelsInput | null,
  style?: BookingPageStyleInput | null,
  calendar?: BookingCalendarInput | null,
  what?: BookingPageWhatInput | null,
  who?: WhoInput | null,
  where?: WhereInput | null,
  when?: WhenInput | null,
  inputFields?: Array< CustomFieldInput | null > | null,
  notifications?: NotificationsInput | null,
  confirmationsPage?: ConfirmationsPageInput | null,
  lastModify?: string | null,
  displayId?: string | null,
};

export type BookingPageWhatInput = {
  customName?: string | null,
  color?: string | null,
  bookingTemplateId?: string | null,
  isActive?: boolean | null,
};

export type WhoInput = {
  cohosts?: Array< TeamOrUserInput | null > | null,
  host?: Array< TeamOrUserInput | null > | null,
  hostAssignmentMode?: AssignmentMode | null,
  hostRescheduleWith?: RescheduleWith | null,
};

export type TeamOrUserInput = {
  teamId?: string | null,
  userId?: string | null,
  priority?: number | null,
};

export enum AssignmentMode {
  ROUND_ROBIN = "ROUND_ROBIN",
  RANDOM = "RANDOM",
}


export enum RescheduleWith {
  ASSIGNED_HOST = "ASSIGNED_HOST",
  ROUND_ROBIN_TO_NEW_HOST = "ROUND_ROBIN_TO_NEW_HOST",
}


export type ModelBookingPageConditionInput = {
  inviteOthers?: ModelBooleanInput | null,
  enterNote?: ModelBooleanInput | null,
  shortLink?: ModelStringInput | null,
  adminOnly?: ModelBooleanInput | null,
  lastModify?: ModelStringInput | null,
  displayId?: ModelStringInput | null,
  and?: Array< ModelBookingPageConditionInput | null > | null,
  or?: Array< ModelBookingPageConditionInput | null > | null,
  not?: ModelBookingPageConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type BookingPage = {
  __typename: "BookingPage",
  id: string,
  workspaceId: string,
  inviteOthers?: boolean | null,
  enterNote?: boolean | null,
  shortLink?: string | null,
  adminOnly?: boolean | null,
  labels?: BookingPageLabels | null,
  style?: BookingPageStyle | null,
  calendar?: BookingCalendar | null,
  what?: BookingPageWhat | null,
  who?: Who | null,
  where?: Where | null,
  when?: When | null,
  inputFields?:  Array<CustomField | null > | null,
  notifications?: Notifications | null,
  confirmationsPage?: ConfirmationsPage | null,
  lastModify?: string | null,
  displayId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type BookingPageWhat = {
  __typename: "BookingPageWhat",
  customName?: string | null,
  color?: string | null,
  bookingTemplateId?: string | null,
  isActive?: boolean | null,
};

export type Who = {
  __typename: "Who",
  cohosts?:  Array<TeamOrUser | null > | null,
  host?:  Array<TeamOrUser | null > | null,
  hostAssignmentMode?: AssignmentMode | null,
  hostRescheduleWith?: RescheduleWith | null,
};

export type TeamOrUser = {
  __typename: "TeamOrUser",
  teamId?: string | null,
  userId?: string | null,
  priority?: number | null,
};

export type UpdateBookingPageInput = {
  id: string,
  workspaceId: string,
  inviteOthers?: boolean | null,
  enterNote?: boolean | null,
  shortLink?: string | null,
  adminOnly?: boolean | null,
  labels?: BookingPageLabelsInput | null,
  style?: BookingPageStyleInput | null,
  calendar?: BookingCalendarInput | null,
  what?: BookingPageWhatInput | null,
  who?: WhoInput | null,
  where?: WhereInput | null,
  when?: WhenInput | null,
  inputFields?: Array< CustomFieldInput | null > | null,
  notifications?: NotificationsInput | null,
  confirmationsPage?: ConfirmationsPageInput | null,
  lastModify?: string | null,
  displayId?: string | null,
};

export type DeleteBookingPageInput = {
  id: string,
  workspaceId: string,
};

export type CreateRoleInput = {
  id?: string | null,
  tenantId: string,
  name: string,
  isActive: boolean,
  isCustom: boolean,
  hasChromeAccess: boolean,
  users?: Array< PermissionAction | null > | null,
  availability?: Array< PermissionAction | null > | null,
  myBookingPages?: Array< PermissionAction | null > | null,
  allBookingPages?: Array< PermissionAction | null > | null,
  bookingTemplates?: Array< PermissionAction | null > | null,
  resources?: Array< PermissionAction | null > | null,
  teams?: Array< PermissionAction | null > | null,
  isAccessToAllWorkspaces?: boolean | null,
  workspaceAdminActions?: Array< PermissionAction | null > | null,
  updatedBy?: string | null,
};

export enum PermissionAction {
  READ = "READ",
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE",
}


export type ModelRoleConditionInput = {
  name?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  isCustom?: ModelBooleanInput | null,
  hasChromeAccess?: ModelBooleanInput | null,
  users?: ModelPermissionActionListInput | null,
  availability?: ModelPermissionActionListInput | null,
  myBookingPages?: ModelPermissionActionListInput | null,
  allBookingPages?: ModelPermissionActionListInput | null,
  bookingTemplates?: ModelPermissionActionListInput | null,
  resources?: ModelPermissionActionListInput | null,
  teams?: ModelPermissionActionListInput | null,
  isAccessToAllWorkspaces?: ModelBooleanInput | null,
  workspaceAdminActions?: ModelPermissionActionListInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRoleConditionInput | null > | null,
  or?: Array< ModelRoleConditionInput | null > | null,
  not?: ModelRoleConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPermissionActionListInput = {
  eq?: Array< PermissionAction | null > | null,
  ne?: Array< PermissionAction | null > | null,
  contains?: PermissionAction | null,
  notContains?: PermissionAction | null,
};

export type Role = {
  __typename: "Role",
  id: string,
  tenantId: string,
  name: string,
  isActive: boolean,
  isCustom: boolean,
  hasChromeAccess: boolean,
  users?: Array< PermissionAction | null > | null,
  availability?: Array< PermissionAction | null > | null,
  myBookingPages?: Array< PermissionAction | null > | null,
  allBookingPages?: Array< PermissionAction | null > | null,
  bookingTemplates?: Array< PermissionAction | null > | null,
  resources?: Array< PermissionAction | null > | null,
  teams?: Array< PermissionAction | null > | null,
  isAccessToAllWorkspaces?: boolean | null,
  workspaceAdminActions?: Array< PermissionAction | null > | null,
  updatedBy?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateRoleInput = {
  id: string,
  tenantId: string,
  name?: string | null,
  isActive?: boolean | null,
  isCustom?: boolean | null,
  hasChromeAccess?: boolean | null,
  users?: Array< PermissionAction | null > | null,
  availability?: Array< PermissionAction | null > | null,
  myBookingPages?: Array< PermissionAction | null > | null,
  allBookingPages?: Array< PermissionAction | null > | null,
  bookingTemplates?: Array< PermissionAction | null > | null,
  resources?: Array< PermissionAction | null > | null,
  teams?: Array< PermissionAction | null > | null,
  isAccessToAllWorkspaces?: boolean | null,
  workspaceAdminActions?: Array< PermissionAction | null > | null,
  updatedBy?: string | null,
};

export type DeleteRoleInput = {
  id: string,
  tenantId: string,
};

export type CreateLicenseInput = {
  id?: string | null,
  tenantId: string,
  type?: LicenseType | null,
  startDate?: string | null,
  endDate?: string | null,
  owned?: number | null,
  assigned?: number | null,
  hasScheduledChanges?: boolean | null,
  scheduledChanges?: Array< ScheduledChangesInput | null > | null,
};

export enum LicenseType {
  TRIAL = "TRIAL",
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
}


export type ScheduledChangesInput = {
  id?: string | null,
  seats?: number | null,
  plan?: string | null,
  changeBy?: string | null,
  requestDate?: string | null,
};

export type ModelLicenseConditionInput = {
  type?: ModelLicenseTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  owned?: ModelIntInput | null,
  assigned?: ModelIntInput | null,
  hasScheduledChanges?: ModelBooleanInput | null,
  and?: Array< ModelLicenseConditionInput | null > | null,
  or?: Array< ModelLicenseConditionInput | null > | null,
  not?: ModelLicenseConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelLicenseTypeInput = {
  eq?: LicenseType | null,
  ne?: LicenseType | null,
};

export type License = {
  __typename: "License",
  id: string,
  tenantId: string,
  type?: LicenseType | null,
  startDate?: string | null,
  endDate?: string | null,
  owned?: number | null,
  assigned?: number | null,
  hasScheduledChanges?: boolean | null,
  scheduledChanges?:  Array<ScheduledChanges | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ScheduledChanges = {
  __typename: "ScheduledChanges",
  id?: string | null,
  seats?: number | null,
  plan?: string | null,
  changeBy?: string | null,
  requestDate?: string | null,
};

export type UpdateLicenseInput = {
  id: string,
  tenantId: string,
  type?: LicenseType | null,
  startDate?: string | null,
  endDate?: string | null,
  owned?: number | null,
  assigned?: number | null,
  hasScheduledChanges?: boolean | null,
  scheduledChanges?: Array< ScheduledChangesInput | null > | null,
};

export type DeleteLicenseInput = {
  id: string,
  tenantId: string,
};

export type CreateAccountInput = {
  id?: string | null,
  tenantIds?: Array< string | null > | null,
  name?: string | null,
  createdBy?: string | null,
  note?: string | null,
};

export type ModelAccountConditionInput = {
  tenantIds?: ModelStringInput | null,
  name?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  note?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  tenantIds?: Array< string | null > | null,
  name?: string | null,
  createdBy?: string | null,
  note?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateAccountInput = {
  id: string,
  tenantIds?: Array< string | null > | null,
  name?: string | null,
  createdBy?: string | null,
  note?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateStaffInput = {
  email: string,
  userId?: string | null,
  userName?: string | null,
  roleName?: StaffRoles | null,
  status?: StaffStatus | null,
};

export enum StaffRoles {
  MAIN_ADMIN = "MAIN_ADMIN",
  OPERATIONS = "OPERATIONS",
  SUPPORT = "SUPPORT",
}


export enum StaffStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelStaffConditionInput = {
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  roleName?: ModelStaffRolesInput | null,
  status?: ModelStaffStatusInput | null,
  and?: Array< ModelStaffConditionInput | null > | null,
  or?: Array< ModelStaffConditionInput | null > | null,
  not?: ModelStaffConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelStaffRolesInput = {
  eq?: StaffRoles | null,
  ne?: StaffRoles | null,
};

export type ModelStaffStatusInput = {
  eq?: StaffStatus | null,
  ne?: StaffStatus | null,
};

export type Staff = {
  __typename: "Staff",
  email: string,
  userId?: string | null,
  userName?: string | null,
  roleName?: StaffRoles | null,
  status?: StaffStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateStaffInput = {
  email: string,
  userId?: string | null,
  userName?: string | null,
  roleName?: StaffRoles | null,
  status?: StaffStatus | null,
};

export type DeleteStaffInput = {
  email: string,
};

export type CreateTeamInput = {
  id?: string | null,
  workspaceId?: string | null,
  name?: string | null,
  active?: boolean | null,
  workspacesForRead?: Array< string | null > | null,
  members?: Array< TeamMemberInput | null > | null,
  assignmentMode?: AssignmentMode | null,
  rescheduleWith?: RescheduleWith | null,
  createdBy?: string | null,
};

export type TeamMemberInput = {
  userId?: string | null,
  priority?: number | null,
};

export type ModelTeamConditionInput = {
  workspaceId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  workspacesForRead?: ModelStringInput | null,
  assignmentMode?: ModelAssignmentModeInput | null,
  rescheduleWith?: ModelRescheduleWithInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelTeamConditionInput | null > | null,
  or?: Array< ModelTeamConditionInput | null > | null,
  not?: ModelTeamConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelAssignmentModeInput = {
  eq?: AssignmentMode | null,
  ne?: AssignmentMode | null,
};

export type ModelRescheduleWithInput = {
  eq?: RescheduleWith | null,
  ne?: RescheduleWith | null,
};

export type Team = {
  __typename: "Team",
  id: string,
  workspaceId?: string | null,
  name?: string | null,
  active?: boolean | null,
  workspacesForRead?: Array< string | null > | null,
  members?:  Array<TeamMember | null > | null,
  assignmentMode?: AssignmentMode | null,
  rescheduleWith?: RescheduleWith | null,
  createdBy?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type TeamMember = {
  __typename: "TeamMember",
  userId?: string | null,
  priority?: number | null,
};

export type UpdateTeamInput = {
  id: string,
  workspaceId?: string | null,
  name?: string | null,
  active?: boolean | null,
  workspacesForRead?: Array< string | null > | null,
  members?: Array< TeamMemberInput | null > | null,
  assignmentMode?: AssignmentMode | null,
  rescheduleWith?: RescheduleWith | null,
  createdBy?: string | null,
};

export type DeleteTeamInput = {
  id: string,
};

export type ModelTeamRoundRobinFilterInput = {
  teamId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  lastCreatedEvent?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamRoundRobinFilterInput | null > | null,
  or?: Array< ModelTeamRoundRobinFilterInput | null > | null,
  not?: ModelTeamRoundRobinFilterInput | null,
};

export type ModelTeamRoundRobinConnection = {
  __typename: "ModelTeamRoundRobinConnection",
  items:  Array<TeamRoundRobin | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTeamUserRoundRobinFilterInput = {
  teamId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  lastCreatedEvent?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamUserRoundRobinFilterInput | null > | null,
  or?: Array< ModelTeamUserRoundRobinFilterInput | null > | null,
  not?: ModelTeamUserRoundRobinFilterInput | null,
};

export type ModelTeamUserRoundRobinConnection = {
  __typename: "ModelTeamUserRoundRobinConnection",
  items:  Array<TeamUserRoundRobin | null >,
  nextToken?: string | null,
};

export type ModelUserEventPrimaryCompositeKeyConditionInput = {
  eq?: ModelUserEventPrimaryCompositeKeyInput | null,
  le?: ModelUserEventPrimaryCompositeKeyInput | null,
  lt?: ModelUserEventPrimaryCompositeKeyInput | null,
  ge?: ModelUserEventPrimaryCompositeKeyInput | null,
  gt?: ModelUserEventPrimaryCompositeKeyInput | null,
  between?: Array< ModelUserEventPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelUserEventPrimaryCompositeKeyInput | null,
};

export type ModelUserEventPrimaryCompositeKeyInput = {
  eventDate?: string | null,
  eventId?: string | null,
  tenant?: string | null,
};

export type ModelUserEventFilterInput = {
  userId?: ModelStringInput | null,
  tenant?: ModelStringInput | null,
  eventDate?: ModelStringInput | null,
  eventId?: ModelStringInput | null,
  editKey?: ModelStringInput | null,
  shortLink?: ModelStringInput | null,
  shortEditLink?: ModelStringInput | null,
  bookingPageId?: ModelStringInput | null,
  bookingPageName?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  beforeStartTime?: ModelIntInput | null,
  afterEndTime?: ModelIntInput | null,
  timeZone?: ModelStringInput | null,
  note?: ModelStringInput | null,
  cohosts?: ModelStringInput | null,
  guestEmails?: ModelStringInput | null,
  isNoShow?: ModelBooleanInput | null,
  host?: ModelStringInput | null,
  internalNotes?: ModelStringInput | null,
  isSMSAllowed?: ModelBooleanInput | null,
  hostName?: ModelStringInput | null,
  cohostNames?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserEventFilterInput | null > | null,
  or?: Array< ModelUserEventFilterInput | null > | null,
  not?: ModelUserEventFilterInput | null,
};

export type ModelUserEventConnection = {
  __typename: "ModelUserEventConnection",
  items:  Array<UserEvent | null >,
  nextToken?: string | null,
};

export type ModelAdminDataFilterInput = {
  email?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  roleId?: ModelStringInput | null,
  status?: ModelStringInput | null,
  tenantId?: ModelStringInput | null,
  workspaceIds?: ModelStringInput | null,
  invitedById?: ModelStringInput | null,
  invitedByName?: ModelStringInput | null,
  team?: ModelStringInput | null,
  bookingTemplateIds?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminDataFilterInput | null > | null,
  or?: Array< ModelAdminDataFilterInput | null > | null,
  not?: ModelAdminDataFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelAdminDataConnection = {
  __typename: "ModelAdminDataConnection",
  items:  Array<AdminData | null >,
  nextToken?: string | null,
};

export type ModelTenantFilterInput = {
  tenantId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelTenantStatusInput | null,
  type?: ModelOrgTypeInput | null,
  note?: ModelStringInput | null,
  subscription?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items:  Array<Tenant | null >,
  nextToken?: string | null,
};

export type ModelBookingTemplateFilterInput = {
  id?: ModelStringInput | null,
  workspaceId?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  inviteOthers?: ModelBooleanInput | null,
  enterNote?: ModelBooleanInput | null,
  potentialHosts?: ModelStringInput | null,
  potentialTeams?: ModelStringInput | null,
  lastModify?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookingTemplateFilterInput | null > | null,
  or?: Array< ModelBookingTemplateFilterInput | null > | null,
  not?: ModelBookingTemplateFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBookingTemplateConnection = {
  __typename: "ModelBookingTemplateConnection",
  items:  Array<BookingTemplate | null >,
  nextToken?: string | null,
};

export type ModelBookingPageFilterInput = {
  id?: ModelStringInput | null,
  workspaceId?: ModelStringInput | null,
  inviteOthers?: ModelBooleanInput | null,
  enterNote?: ModelBooleanInput | null,
  shortLink?: ModelStringInput | null,
  adminOnly?: ModelBooleanInput | null,
  lastModify?: ModelStringInput | null,
  displayId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookingPageFilterInput | null > | null,
  or?: Array< ModelBookingPageFilterInput | null > | null,
  not?: ModelBookingPageFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBookingPageConnection = {
  __typename: "ModelBookingPageConnection",
  items:  Array<BookingPage | null >,
  nextToken?: string | null,
};

export type ModelRoleFilterInput = {
  id?: ModelStringInput | null,
  tenantId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  isCustom?: ModelBooleanInput | null,
  hasChromeAccess?: ModelBooleanInput | null,
  users?: ModelPermissionActionListInput | null,
  availability?: ModelPermissionActionListInput | null,
  myBookingPages?: ModelPermissionActionListInput | null,
  allBookingPages?: ModelPermissionActionListInput | null,
  bookingTemplates?: ModelPermissionActionListInput | null,
  resources?: ModelPermissionActionListInput | null,
  teams?: ModelPermissionActionListInput | null,
  isAccessToAllWorkspaces?: ModelBooleanInput | null,
  workspaceAdminActions?: ModelPermissionActionListInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRoleFilterInput | null > | null,
  or?: Array< ModelRoleFilterInput | null > | null,
  not?: ModelRoleFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelRoleConnection = {
  __typename: "ModelRoleConnection",
  items:  Array<Role | null >,
  nextToken?: string | null,
};

export type ModelLicenseFilterInput = {
  id?: ModelStringInput | null,
  tenantId?: ModelStringInput | null,
  type?: ModelLicenseTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  owned?: ModelIntInput | null,
  assigned?: ModelIntInput | null,
  hasScheduledChanges?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLicenseFilterInput | null > | null,
  or?: Array< ModelLicenseFilterInput | null > | null,
  not?: ModelLicenseFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelLicenseConnection = {
  __typename: "ModelLicenseConnection",
  items:  Array<License | null >,
  nextToken?: string | null,
};

export type ModelAccountFilterInput = {
  id?: ModelStringInput | null,
  tenantIds?: ModelStringInput | null,
  name?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelStaffFilterInput = {
  email?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  roleName?: ModelStaffRolesInput | null,
  status?: ModelStaffStatusInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStaffFilterInput | null > | null,
  or?: Array< ModelStaffFilterInput | null > | null,
  not?: ModelStaffFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelStaffConnection = {
  __typename: "ModelStaffConnection",
  items:  Array<Staff | null >,
  nextToken?: string | null,
};

export type ModelTeamFilterInput = {
  id?: ModelStringInput | null,
  workspaceId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  workspacesForRead?: ModelStringInput | null,
  assignmentMode?: ModelAssignmentModeInput | null,
  rescheduleWith?: ModelRescheduleWithInput | null,
  createdBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamFilterInput | null > | null,
  or?: Array< ModelTeamFilterInput | null > | null,
  not?: ModelTeamFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items:  Array<Team | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionTeamRoundRobinFilterInput = {
  teamId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  lastCreatedEvent?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamRoundRobinFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamRoundRobinFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionTeamUserRoundRobinFilterInput = {
  teamId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  lastCreatedEvent?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamUserRoundRobinFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamUserRoundRobinFilterInput | null > | null,
};

export type ModelSubscriptionUserDataFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  recordType?: ModelSubscriptionStringInput | null,
  lastActive?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserDataFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserDataFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUserEventFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  eventDate?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionStringInput | null,
  editKey?: ModelSubscriptionStringInput | null,
  shortLink?: ModelSubscriptionStringInput | null,
  shortEditLink?: ModelSubscriptionStringInput | null,
  bookingPageId?: ModelSubscriptionStringInput | null,
  bookingPageName?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  beforeStartTime?: ModelSubscriptionIntInput | null,
  afterEndTime?: ModelSubscriptionIntInput | null,
  timeZone?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  cohosts?: ModelSubscriptionStringInput | null,
  guestEmails?: ModelSubscriptionStringInput | null,
  isNoShow?: ModelSubscriptionBooleanInput | null,
  host?: ModelSubscriptionStringInput | null,
  internalNotes?: ModelSubscriptionStringInput | null,
  isSMSAllowed?: ModelSubscriptionBooleanInput | null,
  hostName?: ModelSubscriptionStringInput | null,
  cohostNames?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserEventFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionAdminDataFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  roleId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  tenantId?: ModelSubscriptionStringInput | null,
  workspaceIds?: ModelSubscriptionStringInput | null,
  invitedById?: ModelSubscriptionStringInput | null,
  invitedByName?: ModelSubscriptionStringInput | null,
  team?: ModelSubscriptionStringInput | null,
  bookingTemplateIds?: ModelSubscriptionStringInput | null,
  expirationDate?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminDataFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminDataFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionTenantFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  subscription?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTenantFilterInput | null > | null,
  or?: Array< ModelSubscriptionTenantFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionBookingTemplateFilterInput = {
  workspaceId?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  inviteOthers?: ModelSubscriptionBooleanInput | null,
  enterNote?: ModelSubscriptionBooleanInput | null,
  potentialHosts?: ModelSubscriptionStringInput | null,
  potentialTeams?: ModelSubscriptionStringInput | null,
  lastModify?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingTemplateFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionBookingPageFilterInput = {
  workspaceId?: ModelSubscriptionStringInput | null,
  inviteOthers?: ModelSubscriptionBooleanInput | null,
  enterNote?: ModelSubscriptionBooleanInput | null,
  shortLink?: ModelSubscriptionStringInput | null,
  adminOnly?: ModelSubscriptionBooleanInput | null,
  lastModify?: ModelSubscriptionStringInput | null,
  displayId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingPageFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingPageFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionRoleFilterInput = {
  tenantId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  isCustom?: ModelSubscriptionBooleanInput | null,
  hasChromeAccess?: ModelSubscriptionBooleanInput | null,
  users?: ModelSubscriptionStringInput | null,
  availability?: ModelSubscriptionStringInput | null,
  myBookingPages?: ModelSubscriptionStringInput | null,
  allBookingPages?: ModelSubscriptionStringInput | null,
  bookingTemplates?: ModelSubscriptionStringInput | null,
  resources?: ModelSubscriptionStringInput | null,
  teams?: ModelSubscriptionStringInput | null,
  isAccessToAllWorkspaces?: ModelSubscriptionBooleanInput | null,
  workspaceAdminActions?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionRoleFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionLicenseFilterInput = {
  tenantId?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  owned?: ModelSubscriptionIntInput | null,
  assigned?: ModelSubscriptionIntInput | null,
  hasScheduledChanges?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLicenseFilterInput | null > | null,
  or?: Array< ModelSubscriptionLicenseFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionAccountFilterInput = {
  tenantIds?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionStaffFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  roleName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStaffFilterInput | null > | null,
  or?: Array< ModelSubscriptionStaffFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionTeamFilterInput = {
  workspaceId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  workspacesForRead?: ModelSubscriptionStringInput | null,
  assignmentMode?: ModelSubscriptionStringInput | null,
  rescheduleWith?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type listEventTypesQueryVariables = {
  userId?: string | null,
  linkTenant?: ModelUserDataPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type listEventTypesQuery = {
  listUserData?:  {
    __typename: "ModelUserDataConnection",
    items:  Array< {
      __typename: "UserData",
      userId: string,
      tenant: string,
      link: string,
      recordType: UserRecordType,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getUserQueryVariables = {
  userId?: string | null,
  linkTenant?: ModelUserDataPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type getUserQuery = {
  listUserData?:  {
    __typename: "ModelUserDataConnection",
    items:  Array< {
      __typename: "UserData",
      userId: string,
      tenant: string,
      link: string,
      recordType: UserRecordType,
      userSettings?:  {
        __typename: "UserSettings",
        userDefaultAvailabilityId?: string | null,
        avatar?: string | null,
        fullName?: string | null,
        phoneDetails?: string | null,
        language?: string | null,
        dateFormat?: string | null,
        timeFormat?: string | null,
        country?: string | null,
        countryCode?: string | null,
        timeZone?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listAvailabilityQueryVariables = {
  userId?: string | null,
  linkTenant?: ModelUserDataPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type listAvailabilityQuery = {
  listUserData?:  {
    __typename: "ModelUserDataConnection",
    items:  Array< {
      __typename: "UserData",
      userId: string,
      tenant: string,
      link: string,
      recordType: UserRecordType,
      availabilityData?:  {
        __typename: "Availability",
        id: string,
        name: string,
        activeBookingPages?: Array< string | null > | null,
        isDefault?: boolean | null,
        weeklyHours?:  Array< {
          __typename: "WeeklyHours",
          type?: WeekDay | null,
          time?:  Array< {
            __typename: "TimeFromTo",
            from?: string | null,
            to?: string | null,
          } | null > | null,
        } | null > | null,
        overrides?:  Array< {
          __typename: "Overrides",
          days?:  {
            __typename: "DateTimeFromTo",
            from?: string | null,
            to?: string | null,
          } | null,
          time?:  Array< {
            __typename: "TimeFromTo",
            from?: string | null,
            to?: string | null,
          } | null > | null,
        } | null > | null,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CreateTeamRoundRobinMutationVariables = {
  input: CreateTeamRoundRobinInput,
  condition?: ModelTeamRoundRobinConditionInput | null,
};

export type CreateTeamRoundRobinMutation = {
  createTeamRoundRobin?:  {
    __typename: "TeamRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTeamRoundRobinMutationVariables = {
  input: UpdateTeamRoundRobinInput,
  condition?: ModelTeamRoundRobinConditionInput | null,
};

export type UpdateTeamRoundRobinMutation = {
  updateTeamRoundRobin?:  {
    __typename: "TeamRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTeamRoundRobinMutationVariables = {
  input: DeleteTeamRoundRobinInput,
  condition?: ModelTeamRoundRobinConditionInput | null,
};

export type DeleteTeamRoundRobinMutation = {
  deleteTeamRoundRobin?:  {
    __typename: "TeamRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTeamUserRoundRobinMutationVariables = {
  input: CreateTeamUserRoundRobinInput,
  condition?: ModelTeamUserRoundRobinConditionInput | null,
};

export type CreateTeamUserRoundRobinMutation = {
  createTeamUserRoundRobin?:  {
    __typename: "TeamUserRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTeamUserRoundRobinMutationVariables = {
  input: UpdateTeamUserRoundRobinInput,
  condition?: ModelTeamUserRoundRobinConditionInput | null,
};

export type UpdateTeamUserRoundRobinMutation = {
  updateTeamUserRoundRobin?:  {
    __typename: "TeamUserRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTeamUserRoundRobinMutationVariables = {
  input: DeleteTeamUserRoundRobinInput,
  condition?: ModelTeamUserRoundRobinConditionInput | null,
};

export type DeleteTeamUserRoundRobinMutation = {
  deleteTeamUserRoundRobin?:  {
    __typename: "TeamUserRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserDataMutationVariables = {
  input: CreateUserDataInput,
  condition?: ModelUserDataConditionInput | null,
};

export type CreateUserDataMutation = {
  createUserData?:  {
    __typename: "UserData",
    userId: string,
    tenant: string,
    owner?: string | null,
    link: string,
    recordType: UserRecordType,
    userSettings?:  {
      __typename: "UserSettings",
      userDefaultAvailabilityId?: string | null,
      avatar?: string | null,
      fullName?: string | null,
      phoneDetails?: string | null,
      language?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      country?: string | null,
      countryCode?: string | null,
      timeZone?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      isQuickSetupFinished?: boolean | null,
      isInvitePeopleClosed?: boolean | null,
      defaultVideoIntegration?: VideoConferenceType | null,
      calendarWarningDate?: string | null,
    } | null,
    availabilityData?:  {
      __typename: "Availability",
      id: string,
      name: string,
      activeBookingPages?: Array< string | null > | null,
      isDefault?: boolean | null,
    } | null,
    statistics?:  {
      __typename: "Statistics",
      eventCreated?: number | null,
      emailsSent?: number | null,
      eventRescheduled?: number | null,
      eventCanceled?: number | null,
    } | null,
    lastActive?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserDataMutationVariables = {
  input: UpdateUserDataInput,
  condition?: ModelUserDataConditionInput | null,
};

export type UpdateUserDataMutation = {
  updateUserData?:  {
    __typename: "UserData",
    userId: string,
    tenant: string,
    owner?: string | null,
    link: string,
    recordType: UserRecordType,
    userSettings?:  {
      __typename: "UserSettings",
      userDefaultAvailabilityId?: string | null,
      avatar?: string | null,
      fullName?: string | null,
      phoneDetails?: string | null,
      language?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      country?: string | null,
      countryCode?: string | null,
      timeZone?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      isQuickSetupFinished?: boolean | null,
      isInvitePeopleClosed?: boolean | null,
      defaultVideoIntegration?: VideoConferenceType | null,
      calendarWarningDate?: string | null,
    } | null,
    availabilityData?:  {
      __typename: "Availability",
      id: string,
      name: string,
      activeBookingPages?: Array< string | null > | null,
      isDefault?: boolean | null,
    } | null,
    statistics?:  {
      __typename: "Statistics",
      eventCreated?: number | null,
      emailsSent?: number | null,
      eventRescheduled?: number | null,
      eventCanceled?: number | null,
    } | null,
    lastActive?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserDataMutationVariables = {
  input: DeleteUserDataInput,
  condition?: ModelUserDataConditionInput | null,
};

export type DeleteUserDataMutation = {
  deleteUserData?:  {
    __typename: "UserData",
    userId: string,
    tenant: string,
    owner?: string | null,
    link: string,
    recordType: UserRecordType,
    userSettings?:  {
      __typename: "UserSettings",
      userDefaultAvailabilityId?: string | null,
      avatar?: string | null,
      fullName?: string | null,
      phoneDetails?: string | null,
      language?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      country?: string | null,
      countryCode?: string | null,
      timeZone?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      isQuickSetupFinished?: boolean | null,
      isInvitePeopleClosed?: boolean | null,
      defaultVideoIntegration?: VideoConferenceType | null,
      calendarWarningDate?: string | null,
    } | null,
    availabilityData?:  {
      __typename: "Availability",
      id: string,
      name: string,
      activeBookingPages?: Array< string | null > | null,
      isDefault?: boolean | null,
    } | null,
    statistics?:  {
      __typename: "Statistics",
      eventCreated?: number | null,
      emailsSent?: number | null,
      eventRescheduled?: number | null,
      eventCanceled?: number | null,
    } | null,
    lastActive?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserEventMutationVariables = {
  input: CreateUserEventInput,
  condition?: ModelUserEventConditionInput | null,
};

export type CreateUserEventMutation = {
  createUserEvent?:  {
    __typename: "UserEvent",
    userId: string,
    tenant: string,
    eventDate: string,
    eventId: string,
    editKey?: string | null,
    shortLink?: string | null,
    shortEditLink?: string | null,
    bookingPageId: string,
    bookingPageName: string,
    owner?: string | null,
    startTime: string,
    endTime: string,
    beforeStartTime?: number | null,
    afterEndTime?: number | null,
    location?:  {
      __typename: "Location",
      type: LocationType,
      settings?: string | null,
    } | null,
    integrations?:  Array< {
      __typename: "Integration",
      type: IntegrationType,
      settings?: string | null,
    } | null > | null,
    timeZone?: string | null,
    note?: string | null,
    cohosts?: Array< string | null > | null,
    guestEmails?: Array< string | null > | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    canceled?:  {
      __typename: "CanceledEvent",
      isCanceled: boolean,
      type?: CanceledType | null,
      note?: string | null,
    } | null,
    executionIds?:  {
      __typename: "ExecutionIds",
      emailExecutionIds?: Array< string | null > | null,
      smsExecutionIds?: Array< string | null > | null,
    } | null,
    isNoShow?: boolean | null,
    host?: string | null,
    internalNotes?: string | null,
    isSMSAllowed?: boolean | null,
    hostName?: string | null,
    cohostNames?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserEventMutationVariables = {
  input: UpdateUserEventInput,
  condition?: ModelUserEventConditionInput | null,
};

export type UpdateUserEventMutation = {
  updateUserEvent?:  {
    __typename: "UserEvent",
    userId: string,
    tenant: string,
    eventDate: string,
    eventId: string,
    editKey?: string | null,
    shortLink?: string | null,
    shortEditLink?: string | null,
    bookingPageId: string,
    bookingPageName: string,
    owner?: string | null,
    startTime: string,
    endTime: string,
    beforeStartTime?: number | null,
    afterEndTime?: number | null,
    location?:  {
      __typename: "Location",
      type: LocationType,
      settings?: string | null,
    } | null,
    integrations?:  Array< {
      __typename: "Integration",
      type: IntegrationType,
      settings?: string | null,
    } | null > | null,
    timeZone?: string | null,
    note?: string | null,
    cohosts?: Array< string | null > | null,
    guestEmails?: Array< string | null > | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    canceled?:  {
      __typename: "CanceledEvent",
      isCanceled: boolean,
      type?: CanceledType | null,
      note?: string | null,
    } | null,
    executionIds?:  {
      __typename: "ExecutionIds",
      emailExecutionIds?: Array< string | null > | null,
      smsExecutionIds?: Array< string | null > | null,
    } | null,
    isNoShow?: boolean | null,
    host?: string | null,
    internalNotes?: string | null,
    isSMSAllowed?: boolean | null,
    hostName?: string | null,
    cohostNames?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserEventMutationVariables = {
  input: DeleteUserEventInput,
  condition?: ModelUserEventConditionInput | null,
};

export type DeleteUserEventMutation = {
  deleteUserEvent?:  {
    __typename: "UserEvent",
    userId: string,
    tenant: string,
    eventDate: string,
    eventId: string,
    editKey?: string | null,
    shortLink?: string | null,
    shortEditLink?: string | null,
    bookingPageId: string,
    bookingPageName: string,
    owner?: string | null,
    startTime: string,
    endTime: string,
    beforeStartTime?: number | null,
    afterEndTime?: number | null,
    location?:  {
      __typename: "Location",
      type: LocationType,
      settings?: string | null,
    } | null,
    integrations?:  Array< {
      __typename: "Integration",
      type: IntegrationType,
      settings?: string | null,
    } | null > | null,
    timeZone?: string | null,
    note?: string | null,
    cohosts?: Array< string | null > | null,
    guestEmails?: Array< string | null > | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    canceled?:  {
      __typename: "CanceledEvent",
      isCanceled: boolean,
      type?: CanceledType | null,
      note?: string | null,
    } | null,
    executionIds?:  {
      __typename: "ExecutionIds",
      emailExecutionIds?: Array< string | null > | null,
      smsExecutionIds?: Array< string | null > | null,
    } | null,
    isNoShow?: boolean | null,
    host?: string | null,
    internalNotes?: string | null,
    isSMSAllowed?: boolean | null,
    hostName?: string | null,
    cohostNames?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAdminDataMutationVariables = {
  input: CreateAdminDataInput,
  condition?: ModelAdminDataConditionInput | null,
};

export type CreateAdminDataMutation = {
  createAdminData?:  {
    __typename: "AdminData",
    email: string,
    userId?: string | null,
    roleId: string,
    status?: string | null,
    tenantId: string,
    workspaceIds?: Array< string | null > | null,
    invitedById?: string | null,
    invitedByName?: string | null,
    team?: Array< string | null > | null,
    bookingTemplateIds?: Array< string | null > | null,
    expirationDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAdminDataMutationVariables = {
  input: UpdateAdminDataInput,
  condition?: ModelAdminDataConditionInput | null,
};

export type UpdateAdminDataMutation = {
  updateAdminData?:  {
    __typename: "AdminData",
    email: string,
    userId?: string | null,
    roleId: string,
    status?: string | null,
    tenantId: string,
    workspaceIds?: Array< string | null > | null,
    invitedById?: string | null,
    invitedByName?: string | null,
    team?: Array< string | null > | null,
    bookingTemplateIds?: Array< string | null > | null,
    expirationDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAdminDataMutationVariables = {
  input: DeleteAdminDataInput,
  condition?: ModelAdminDataConditionInput | null,
};

export type DeleteAdminDataMutation = {
  deleteAdminData?:  {
    __typename: "AdminData",
    email: string,
    userId?: string | null,
    roleId: string,
    status?: string | null,
    tenantId: string,
    workspaceIds?: Array< string | null > | null,
    invitedById?: string | null,
    invitedByName?: string | null,
    team?: Array< string | null > | null,
    bookingTemplateIds?: Array< string | null > | null,
    expirationDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    tenantId: string,
    name?: string | null,
    status?: TenantStatus | null,
    tenantStyle?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    workspace?:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      isActive: boolean,
      isPhoneRequired: boolean,
      bookingPageIndex?: number | null,
    } | null > | null,
    type?: OrgType | null,
    note?: string | null,
    subscription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    tenantId: string,
    name?: string | null,
    status?: TenantStatus | null,
    tenantStyle?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    workspace?:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      isActive: boolean,
      isPhoneRequired: boolean,
      bookingPageIndex?: number | null,
    } | null > | null,
    type?: OrgType | null,
    note?: string | null,
    subscription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    tenantId: string,
    name?: string | null,
    status?: TenantStatus | null,
    tenantStyle?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    workspace?:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      isActive: boolean,
      isPhoneRequired: boolean,
      bookingPageIndex?: number | null,
    } | null > | null,
    type?: OrgType | null,
    note?: string | null,
    subscription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBookingTemplateMutationVariables = {
  input: CreateBookingTemplateInput,
  condition?: ModelBookingTemplateConditionInput | null,
};

export type CreateBookingTemplateMutation = {
  createBookingTemplate?:  {
    __typename: "BookingTemplate",
    id: string,
    workspaceId: string,
    enabled?: boolean | null,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    potentialHosts?: Array< string | null > | null,
    potentialTeams?: Array< string | null > | null,
    what?:  {
      __typename: "BookingTemplateWhat",
      customName?: string | null,
      color?: string | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBookingTemplateMutationVariables = {
  input: UpdateBookingTemplateInput,
  condition?: ModelBookingTemplateConditionInput | null,
};

export type UpdateBookingTemplateMutation = {
  updateBookingTemplate?:  {
    __typename: "BookingTemplate",
    id: string,
    workspaceId: string,
    enabled?: boolean | null,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    potentialHosts?: Array< string | null > | null,
    potentialTeams?: Array< string | null > | null,
    what?:  {
      __typename: "BookingTemplateWhat",
      customName?: string | null,
      color?: string | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBookingTemplateMutationVariables = {
  input: DeleteBookingTemplateInput,
  condition?: ModelBookingTemplateConditionInput | null,
};

export type DeleteBookingTemplateMutation = {
  deleteBookingTemplate?:  {
    __typename: "BookingTemplate",
    id: string,
    workspaceId: string,
    enabled?: boolean | null,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    potentialHosts?: Array< string | null > | null,
    potentialTeams?: Array< string | null > | null,
    what?:  {
      __typename: "BookingTemplateWhat",
      customName?: string | null,
      color?: string | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBookingPageMutationVariables = {
  input: CreateBookingPageInput,
  condition?: ModelBookingPageConditionInput | null,
};

export type CreateBookingPageMutation = {
  createBookingPage?:  {
    __typename: "BookingPage",
    id: string,
    workspaceId: string,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    shortLink?: string | null,
    adminOnly?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    what?:  {
      __typename: "BookingPageWhat",
      customName?: string | null,
      color?: string | null,
      bookingTemplateId?: string | null,
      isActive?: boolean | null,
    } | null,
    who?:  {
      __typename: "Who",
      hostAssignmentMode?: AssignmentMode | null,
      hostRescheduleWith?: RescheduleWith | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    displayId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBookingPageMutationVariables = {
  input: UpdateBookingPageInput,
  condition?: ModelBookingPageConditionInput | null,
};

export type UpdateBookingPageMutation = {
  updateBookingPage?:  {
    __typename: "BookingPage",
    id: string,
    workspaceId: string,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    shortLink?: string | null,
    adminOnly?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    what?:  {
      __typename: "BookingPageWhat",
      customName?: string | null,
      color?: string | null,
      bookingTemplateId?: string | null,
      isActive?: boolean | null,
    } | null,
    who?:  {
      __typename: "Who",
      hostAssignmentMode?: AssignmentMode | null,
      hostRescheduleWith?: RescheduleWith | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    displayId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBookingPageMutationVariables = {
  input: DeleteBookingPageInput,
  condition?: ModelBookingPageConditionInput | null,
};

export type DeleteBookingPageMutation = {
  deleteBookingPage?:  {
    __typename: "BookingPage",
    id: string,
    workspaceId: string,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    shortLink?: string | null,
    adminOnly?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    what?:  {
      __typename: "BookingPageWhat",
      customName?: string | null,
      color?: string | null,
      bookingTemplateId?: string | null,
      isActive?: boolean | null,
    } | null,
    who?:  {
      __typename: "Who",
      hostAssignmentMode?: AssignmentMode | null,
      hostRescheduleWith?: RescheduleWith | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    displayId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateRoleMutationVariables = {
  input: CreateRoleInput,
  condition?: ModelRoleConditionInput | null,
};

export type CreateRoleMutation = {
  createRole?:  {
    __typename: "Role",
    id: string,
    tenantId: string,
    name: string,
    isActive: boolean,
    isCustom: boolean,
    hasChromeAccess: boolean,
    users?: Array< PermissionAction | null > | null,
    availability?: Array< PermissionAction | null > | null,
    myBookingPages?: Array< PermissionAction | null > | null,
    allBookingPages?: Array< PermissionAction | null > | null,
    bookingTemplates?: Array< PermissionAction | null > | null,
    resources?: Array< PermissionAction | null > | null,
    teams?: Array< PermissionAction | null > | null,
    isAccessToAllWorkspaces?: boolean | null,
    workspaceAdminActions?: Array< PermissionAction | null > | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateRoleMutationVariables = {
  input: UpdateRoleInput,
  condition?: ModelRoleConditionInput | null,
};

export type UpdateRoleMutation = {
  updateRole?:  {
    __typename: "Role",
    id: string,
    tenantId: string,
    name: string,
    isActive: boolean,
    isCustom: boolean,
    hasChromeAccess: boolean,
    users?: Array< PermissionAction | null > | null,
    availability?: Array< PermissionAction | null > | null,
    myBookingPages?: Array< PermissionAction | null > | null,
    allBookingPages?: Array< PermissionAction | null > | null,
    bookingTemplates?: Array< PermissionAction | null > | null,
    resources?: Array< PermissionAction | null > | null,
    teams?: Array< PermissionAction | null > | null,
    isAccessToAllWorkspaces?: boolean | null,
    workspaceAdminActions?: Array< PermissionAction | null > | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteRoleMutationVariables = {
  input: DeleteRoleInput,
  condition?: ModelRoleConditionInput | null,
};

export type DeleteRoleMutation = {
  deleteRole?:  {
    __typename: "Role",
    id: string,
    tenantId: string,
    name: string,
    isActive: boolean,
    isCustom: boolean,
    hasChromeAccess: boolean,
    users?: Array< PermissionAction | null > | null,
    availability?: Array< PermissionAction | null > | null,
    myBookingPages?: Array< PermissionAction | null > | null,
    allBookingPages?: Array< PermissionAction | null > | null,
    bookingTemplates?: Array< PermissionAction | null > | null,
    resources?: Array< PermissionAction | null > | null,
    teams?: Array< PermissionAction | null > | null,
    isAccessToAllWorkspaces?: boolean | null,
    workspaceAdminActions?: Array< PermissionAction | null > | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateLicenseMutationVariables = {
  input: CreateLicenseInput,
  condition?: ModelLicenseConditionInput | null,
};

export type CreateLicenseMutation = {
  createLicense?:  {
    __typename: "License",
    id: string,
    tenantId: string,
    type?: LicenseType | null,
    startDate?: string | null,
    endDate?: string | null,
    owned?: number | null,
    assigned?: number | null,
    hasScheduledChanges?: boolean | null,
    scheduledChanges?:  Array< {
      __typename: "ScheduledChanges",
      id?: string | null,
      seats?: number | null,
      plan?: string | null,
      changeBy?: string | null,
      requestDate?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateLicenseMutationVariables = {
  input: UpdateLicenseInput,
  condition?: ModelLicenseConditionInput | null,
};

export type UpdateLicenseMutation = {
  updateLicense?:  {
    __typename: "License",
    id: string,
    tenantId: string,
    type?: LicenseType | null,
    startDate?: string | null,
    endDate?: string | null,
    owned?: number | null,
    assigned?: number | null,
    hasScheduledChanges?: boolean | null,
    scheduledChanges?:  Array< {
      __typename: "ScheduledChanges",
      id?: string | null,
      seats?: number | null,
      plan?: string | null,
      changeBy?: string | null,
      requestDate?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteLicenseMutationVariables = {
  input: DeleteLicenseInput,
  condition?: ModelLicenseConditionInput | null,
};

export type DeleteLicenseMutation = {
  deleteLicense?:  {
    __typename: "License",
    id: string,
    tenantId: string,
    type?: LicenseType | null,
    startDate?: string | null,
    endDate?: string | null,
    owned?: number | null,
    assigned?: number | null,
    hasScheduledChanges?: boolean | null,
    scheduledChanges?:  Array< {
      __typename: "ScheduledChanges",
      id?: string | null,
      seats?: number | null,
      plan?: string | null,
      changeBy?: string | null,
      requestDate?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    tenantIds?: Array< string | null > | null,
    name?: string | null,
    createdBy?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    tenantIds?: Array< string | null > | null,
    name?: string | null,
    createdBy?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    tenantIds?: Array< string | null > | null,
    name?: string | null,
    createdBy?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateStaffMutationVariables = {
  input: CreateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type CreateStaffMutation = {
  createStaff?:  {
    __typename: "Staff",
    email: string,
    userId?: string | null,
    userName?: string | null,
    roleName?: StaffRoles | null,
    status?: StaffStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateStaffMutationVariables = {
  input: UpdateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type UpdateStaffMutation = {
  updateStaff?:  {
    __typename: "Staff",
    email: string,
    userId?: string | null,
    userName?: string | null,
    roleName?: StaffRoles | null,
    status?: StaffStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteStaffMutationVariables = {
  input: DeleteStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type DeleteStaffMutation = {
  deleteStaff?:  {
    __typename: "Staff",
    email: string,
    userId?: string | null,
    userName?: string | null,
    roleName?: StaffRoles | null,
    status?: StaffStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?:  {
    __typename: "Team",
    id: string,
    workspaceId?: string | null,
    name?: string | null,
    active?: boolean | null,
    workspacesForRead?: Array< string | null > | null,
    members?:  Array< {
      __typename: "TeamMember",
      userId?: string | null,
      priority?: number | null,
    } | null > | null,
    assignmentMode?: AssignmentMode | null,
    rescheduleWith?: RescheduleWith | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    workspaceId?: string | null,
    name?: string | null,
    active?: boolean | null,
    workspacesForRead?: Array< string | null > | null,
    members?:  Array< {
      __typename: "TeamMember",
      userId?: string | null,
      priority?: number | null,
    } | null > | null,
    assignmentMode?: AssignmentMode | null,
    rescheduleWith?: RescheduleWith | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?:  {
    __typename: "Team",
    id: string,
    workspaceId?: string | null,
    name?: string | null,
    active?: boolean | null,
    workspacesForRead?: Array< string | null > | null,
    members?:  Array< {
      __typename: "TeamMember",
      userId?: string | null,
      priority?: number | null,
    } | null > | null,
    assignmentMode?: AssignmentMode | null,
    rescheduleWith?: RescheduleWith | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type GetTeamRoundRobinQueryVariables = {
  teamId: string,
};

export type GetTeamRoundRobinQuery = {
  getTeamRoundRobin?:  {
    __typename: "TeamRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTeamRoundRobinsQueryVariables = {
  teamId?: string | null,
  filter?: ModelTeamRoundRobinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTeamRoundRobinsQuery = {
  listTeamRoundRobins?:  {
    __typename: "ModelTeamRoundRobinConnection",
    items:  Array< {
      __typename: "TeamRoundRobin",
      teamId: string,
      userId: string,
      lastCreatedEvent?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamUserRoundRobinQueryVariables = {
  teamId: string,
  userId: string,
};

export type GetTeamUserRoundRobinQuery = {
  getTeamUserRoundRobin?:  {
    __typename: "TeamUserRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTeamUserRoundRobinsQueryVariables = {
  teamId?: string | null,
  userId?: ModelStringKeyConditionInput | null,
  filter?: ModelTeamUserRoundRobinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTeamUserRoundRobinsQuery = {
  listTeamUserRoundRobins?:  {
    __typename: "ModelTeamUserRoundRobinConnection",
    items:  Array< {
      __typename: "TeamUserRoundRobin",
      teamId: string,
      userId: string,
      lastCreatedEvent?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TeamRoundRobinsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamRoundRobinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TeamRoundRobinsByUserIdQuery = {
  teamRoundRobinsByUserId?:  {
    __typename: "ModelTeamRoundRobinConnection",
    items:  Array< {
      __typename: "TeamRoundRobin",
      teamId: string,
      userId: string,
      lastCreatedEvent?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TeamUserRoundRobinsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamUserRoundRobinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TeamUserRoundRobinsByUserIdQuery = {
  teamUserRoundRobinsByUserId?:  {
    __typename: "ModelTeamUserRoundRobinConnection",
    items:  Array< {
      __typename: "TeamUserRoundRobin",
      teamId: string,
      userId: string,
      lastCreatedEvent?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserDataQueryVariables = {
  userId: string,
  link: string,
  tenant: string,
};

export type GetUserDataQuery = {
  getUserData?:  {
    __typename: "UserData",
    userId: string,
    tenant: string,
    owner?: string | null,
    link: string,
    recordType: UserRecordType,
    userSettings?:  {
      __typename: "UserSettings",
      userDefaultAvailabilityId?: string | null,
      avatar?: string | null,
      fullName?: string | null,
      phoneDetails?: string | null,
      language?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      country?: string | null,
      countryCode?: string | null,
      timeZone?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      isQuickSetupFinished?: boolean | null,
      isInvitePeopleClosed?: boolean | null,
      defaultVideoIntegration?: VideoConferenceType | null,
      calendarWarningDate?: string | null,
    } | null,
    availabilityData?:  {
      __typename: "Availability",
      id: string,
      name: string,
      activeBookingPages?: Array< string | null > | null,
      isDefault?: boolean | null,
    } | null,
    statistics?:  {
      __typename: "Statistics",
      eventCreated?: number | null,
      emailsSent?: number | null,
      eventRescheduled?: number | null,
      eventCanceled?: number | null,
    } | null,
    lastActive?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserDataQueryVariables = {
  userId?: string | null,
  linkTenant?: ModelUserDataPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserDataQuery = {
  listUserData?:  {
    __typename: "ModelUserDataConnection",
    items:  Array< {
      __typename: "UserData",
      userId: string,
      tenant: string,
      owner?: string | null,
      link: string,
      recordType: UserRecordType,
      lastActive?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserDataByTenantQueryVariables = {
  tenant: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserDataByTenantQuery = {
  userDataByTenant?:  {
    __typename: "ModelUserDataConnection",
    items:  Array< {
      __typename: "UserData",
      userId: string,
      tenant: string,
      owner?: string | null,
      link: string,
      recordType: UserRecordType,
      lastActive?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserDataByLinkQueryVariables = {
  link: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserDataByLinkQuery = {
  userDataByLink?:  {
    __typename: "ModelUserDataConnection",
    items:  Array< {
      __typename: "UserData",
      userId: string,
      tenant: string,
      owner?: string | null,
      link: string,
      recordType: UserRecordType,
      lastActive?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserEventQueryVariables = {
  userId: string,
  eventDate: string,
  eventId: string,
  tenant: string,
};

export type GetUserEventQuery = {
  getUserEvent?:  {
    __typename: "UserEvent",
    userId: string,
    tenant: string,
    eventDate: string,
    eventId: string,
    editKey?: string | null,
    shortLink?: string | null,
    shortEditLink?: string | null,
    bookingPageId: string,
    bookingPageName: string,
    owner?: string | null,
    startTime: string,
    endTime: string,
    beforeStartTime?: number | null,
    afterEndTime?: number | null,
    location?:  {
      __typename: "Location",
      type: LocationType,
      settings?: string | null,
    } | null,
    integrations?:  Array< {
      __typename: "Integration",
      type: IntegrationType,
      settings?: string | null,
    } | null > | null,
    timeZone?: string | null,
    note?: string | null,
    cohosts?: Array< string | null > | null,
    guestEmails?: Array< string | null > | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    canceled?:  {
      __typename: "CanceledEvent",
      isCanceled: boolean,
      type?: CanceledType | null,
      note?: string | null,
    } | null,
    executionIds?:  {
      __typename: "ExecutionIds",
      emailExecutionIds?: Array< string | null > | null,
      smsExecutionIds?: Array< string | null > | null,
    } | null,
    isNoShow?: boolean | null,
    host?: string | null,
    internalNotes?: string | null,
    isSMSAllowed?: boolean | null,
    hostName?: string | null,
    cohostNames?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserEventsQueryVariables = {
  userId?: string | null,
  eventDateEventIdTenant?: ModelUserEventPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserEventsQuery = {
  listUserEvents?:  {
    __typename: "ModelUserEventConnection",
    items:  Array< {
      __typename: "UserEvent",
      userId: string,
      tenant: string,
      eventDate: string,
      eventId: string,
      editKey?: string | null,
      shortLink?: string | null,
      shortEditLink?: string | null,
      bookingPageId: string,
      bookingPageName: string,
      owner?: string | null,
      startTime: string,
      endTime: string,
      beforeStartTime?: number | null,
      afterEndTime?: number | null,
      timeZone?: string | null,
      note?: string | null,
      cohosts?: Array< string | null > | null,
      guestEmails?: Array< string | null > | null,
      isNoShow?: boolean | null,
      host?: string | null,
      internalNotes?: string | null,
      isSMSAllowed?: boolean | null,
      hostName?: string | null,
      cohostNames?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserEventsByTenantQueryVariables = {
  tenant: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserEventsByTenantQuery = {
  userEventsByTenant?:  {
    __typename: "ModelUserEventConnection",
    items:  Array< {
      __typename: "UserEvent",
      userId: string,
      tenant: string,
      eventDate: string,
      eventId: string,
      editKey?: string | null,
      shortLink?: string | null,
      shortEditLink?: string | null,
      bookingPageId: string,
      bookingPageName: string,
      owner?: string | null,
      startTime: string,
      endTime: string,
      beforeStartTime?: number | null,
      afterEndTime?: number | null,
      timeZone?: string | null,
      note?: string | null,
      cohosts?: Array< string | null > | null,
      guestEmails?: Array< string | null > | null,
      isNoShow?: boolean | null,
      host?: string | null,
      internalNotes?: string | null,
      isSMSAllowed?: boolean | null,
      hostName?: string | null,
      cohostNames?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserEventsByEventDateQueryVariables = {
  eventDate: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserEventsByEventDateQuery = {
  userEventsByEventDate?:  {
    __typename: "ModelUserEventConnection",
    items:  Array< {
      __typename: "UserEvent",
      userId: string,
      tenant: string,
      eventDate: string,
      eventId: string,
      editKey?: string | null,
      shortLink?: string | null,
      shortEditLink?: string | null,
      bookingPageId: string,
      bookingPageName: string,
      owner?: string | null,
      startTime: string,
      endTime: string,
      beforeStartTime?: number | null,
      afterEndTime?: number | null,
      timeZone?: string | null,
      note?: string | null,
      cohosts?: Array< string | null > | null,
      guestEmails?: Array< string | null > | null,
      isNoShow?: boolean | null,
      host?: string | null,
      internalNotes?: string | null,
      isSMSAllowed?: boolean | null,
      hostName?: string | null,
      cohostNames?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserEventsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserEventsByEventIdQuery = {
  userEventsByEventId?:  {
    __typename: "ModelUserEventConnection",
    items:  Array< {
      __typename: "UserEvent",
      userId: string,
      tenant: string,
      eventDate: string,
      eventId: string,
      editKey?: string | null,
      shortLink?: string | null,
      shortEditLink?: string | null,
      bookingPageId: string,
      bookingPageName: string,
      owner?: string | null,
      startTime: string,
      endTime: string,
      beforeStartTime?: number | null,
      afterEndTime?: number | null,
      timeZone?: string | null,
      note?: string | null,
      cohosts?: Array< string | null > | null,
      guestEmails?: Array< string | null > | null,
      isNoShow?: boolean | null,
      host?: string | null,
      internalNotes?: string | null,
      isSMSAllowed?: boolean | null,
      hostName?: string | null,
      cohostNames?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdminDataQueryVariables = {
  email: string,
  tenantId: string,
};

export type GetAdminDataQuery = {
  getAdminData?:  {
    __typename: "AdminData",
    email: string,
    userId?: string | null,
    roleId: string,
    status?: string | null,
    tenantId: string,
    workspaceIds?: Array< string | null > | null,
    invitedById?: string | null,
    invitedByName?: string | null,
    team?: Array< string | null > | null,
    bookingTemplateIds?: Array< string | null > | null,
    expirationDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAdminDataQueryVariables = {
  email?: string | null,
  tenantId?: ModelStringKeyConditionInput | null,
  filter?: ModelAdminDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAdminDataQuery = {
  listAdminData?:  {
    __typename: "ModelAdminDataConnection",
    items:  Array< {
      __typename: "AdminData",
      email: string,
      userId?: string | null,
      roleId: string,
      status?: string | null,
      tenantId: string,
      workspaceIds?: Array< string | null > | null,
      invitedById?: string | null,
      invitedByName?: string | null,
      team?: Array< string | null > | null,
      bookingTemplateIds?: Array< string | null > | null,
      expirationDate?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AdminDataByTenantIdQueryVariables = {
  tenantId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdminDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminDataByTenantIdQuery = {
  adminDataByTenantId?:  {
    __typename: "ModelAdminDataConnection",
    items:  Array< {
      __typename: "AdminData",
      email: string,
      userId?: string | null,
      roleId: string,
      status?: string | null,
      tenantId: string,
      workspaceIds?: Array< string | null > | null,
      invitedById?: string | null,
      invitedByName?: string | null,
      team?: Array< string | null > | null,
      bookingTemplateIds?: Array< string | null > | null,
      expirationDate?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantQueryVariables = {
  tenantId: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    tenantId: string,
    name?: string | null,
    status?: TenantStatus | null,
    tenantStyle?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    workspace?:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      isActive: boolean,
      isPhoneRequired: boolean,
      bookingPageIndex?: number | null,
    } | null > | null,
    type?: OrgType | null,
    note?: string | null,
    subscription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTenantsQueryVariables = {
  tenantId?: string | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      tenantId: string,
      name?: string | null,
      status?: TenantStatus | null,
      type?: OrgType | null,
      note?: string | null,
      subscription?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookingTemplateQueryVariables = {
  id: string,
  workspaceId: string,
};

export type GetBookingTemplateQuery = {
  getBookingTemplate?:  {
    __typename: "BookingTemplate",
    id: string,
    workspaceId: string,
    enabled?: boolean | null,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    potentialHosts?: Array< string | null > | null,
    potentialTeams?: Array< string | null > | null,
    what?:  {
      __typename: "BookingTemplateWhat",
      customName?: string | null,
      color?: string | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBookingTemplatesQueryVariables = {
  id?: string | null,
  workspaceId?: ModelStringKeyConditionInput | null,
  filter?: ModelBookingTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBookingTemplatesQuery = {
  listBookingTemplates?:  {
    __typename: "ModelBookingTemplateConnection",
    items:  Array< {
      __typename: "BookingTemplate",
      id: string,
      workspaceId: string,
      enabled?: boolean | null,
      inviteOthers?: boolean | null,
      enterNote?: boolean | null,
      potentialHosts?: Array< string | null > | null,
      potentialTeams?: Array< string | null > | null,
      lastModify?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingTemplatesByWorkspaceIdQueryVariables = {
  workspaceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingTemplatesByWorkspaceIdQuery = {
  bookingTemplatesByWorkspaceId?:  {
    __typename: "ModelBookingTemplateConnection",
    items:  Array< {
      __typename: "BookingTemplate",
      id: string,
      workspaceId: string,
      enabled?: boolean | null,
      inviteOthers?: boolean | null,
      enterNote?: boolean | null,
      potentialHosts?: Array< string | null > | null,
      potentialTeams?: Array< string | null > | null,
      lastModify?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookingPageQueryVariables = {
  id: string,
  workspaceId: string,
};

export type GetBookingPageQuery = {
  getBookingPage?:  {
    __typename: "BookingPage",
    id: string,
    workspaceId: string,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    shortLink?: string | null,
    adminOnly?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    what?:  {
      __typename: "BookingPageWhat",
      customName?: string | null,
      color?: string | null,
      bookingTemplateId?: string | null,
      isActive?: boolean | null,
    } | null,
    who?:  {
      __typename: "Who",
      hostAssignmentMode?: AssignmentMode | null,
      hostRescheduleWith?: RescheduleWith | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    displayId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBookingPagesQueryVariables = {
  id?: string | null,
  workspaceId?: ModelStringKeyConditionInput | null,
  filter?: ModelBookingPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBookingPagesQuery = {
  listBookingPages?:  {
    __typename: "ModelBookingPageConnection",
    items:  Array< {
      __typename: "BookingPage",
      id: string,
      workspaceId: string,
      inviteOthers?: boolean | null,
      enterNote?: boolean | null,
      shortLink?: string | null,
      adminOnly?: boolean | null,
      lastModify?: string | null,
      displayId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingPagesByWorkspaceIdQueryVariables = {
  workspaceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingPagesByWorkspaceIdQuery = {
  bookingPagesByWorkspaceId?:  {
    __typename: "ModelBookingPageConnection",
    items:  Array< {
      __typename: "BookingPage",
      id: string,
      workspaceId: string,
      inviteOthers?: boolean | null,
      enterNote?: boolean | null,
      shortLink?: string | null,
      adminOnly?: boolean | null,
      lastModify?: string | null,
      displayId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRoleQueryVariables = {
  id: string,
  tenantId: string,
};

export type GetRoleQuery = {
  getRole?:  {
    __typename: "Role",
    id: string,
    tenantId: string,
    name: string,
    isActive: boolean,
    isCustom: boolean,
    hasChromeAccess: boolean,
    users?: Array< PermissionAction | null > | null,
    availability?: Array< PermissionAction | null > | null,
    myBookingPages?: Array< PermissionAction | null > | null,
    allBookingPages?: Array< PermissionAction | null > | null,
    bookingTemplates?: Array< PermissionAction | null > | null,
    resources?: Array< PermissionAction | null > | null,
    teams?: Array< PermissionAction | null > | null,
    isAccessToAllWorkspaces?: boolean | null,
    workspaceAdminActions?: Array< PermissionAction | null > | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListRolesQueryVariables = {
  id?: string | null,
  tenantId?: ModelStringKeyConditionInput | null,
  filter?: ModelRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRolesQuery = {
  listRoles?:  {
    __typename: "ModelRoleConnection",
    items:  Array< {
      __typename: "Role",
      id: string,
      tenantId: string,
      name: string,
      isActive: boolean,
      isCustom: boolean,
      hasChromeAccess: boolean,
      users?: Array< PermissionAction | null > | null,
      availability?: Array< PermissionAction | null > | null,
      myBookingPages?: Array< PermissionAction | null > | null,
      allBookingPages?: Array< PermissionAction | null > | null,
      bookingTemplates?: Array< PermissionAction | null > | null,
      resources?: Array< PermissionAction | null > | null,
      teams?: Array< PermissionAction | null > | null,
      isAccessToAllWorkspaces?: boolean | null,
      workspaceAdminActions?: Array< PermissionAction | null > | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RolesByTenantIdQueryVariables = {
  tenantId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RolesByTenantIdQuery = {
  rolesByTenantId?:  {
    __typename: "ModelRoleConnection",
    items:  Array< {
      __typename: "Role",
      id: string,
      tenantId: string,
      name: string,
      isActive: boolean,
      isCustom: boolean,
      hasChromeAccess: boolean,
      users?: Array< PermissionAction | null > | null,
      availability?: Array< PermissionAction | null > | null,
      myBookingPages?: Array< PermissionAction | null > | null,
      allBookingPages?: Array< PermissionAction | null > | null,
      bookingTemplates?: Array< PermissionAction | null > | null,
      resources?: Array< PermissionAction | null > | null,
      teams?: Array< PermissionAction | null > | null,
      isAccessToAllWorkspaces?: boolean | null,
      workspaceAdminActions?: Array< PermissionAction | null > | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLicenseQueryVariables = {
  id: string,
  tenantId: string,
};

export type GetLicenseQuery = {
  getLicense?:  {
    __typename: "License",
    id: string,
    tenantId: string,
    type?: LicenseType | null,
    startDate?: string | null,
    endDate?: string | null,
    owned?: number | null,
    assigned?: number | null,
    hasScheduledChanges?: boolean | null,
    scheduledChanges?:  Array< {
      __typename: "ScheduledChanges",
      id?: string | null,
      seats?: number | null,
      plan?: string | null,
      changeBy?: string | null,
      requestDate?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListLicensesQueryVariables = {
  id?: string | null,
  tenantId?: ModelStringKeyConditionInput | null,
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLicensesQuery = {
  listLicenses?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      tenantId: string,
      type?: LicenseType | null,
      startDate?: string | null,
      endDate?: string | null,
      owned?: number | null,
      assigned?: number | null,
      hasScheduledChanges?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LicensesByTenantIdQueryVariables = {
  tenantId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LicensesByTenantIdQuery = {
  licensesByTenantId?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      tenantId: string,
      type?: LicenseType | null,
      startDate?: string | null,
      endDate?: string | null,
      owned?: number | null,
      assigned?: number | null,
      hasScheduledChanges?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    tenantIds?: Array< string | null > | null,
    name?: string | null,
    createdBy?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  id?: string | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      tenantIds?: Array< string | null > | null,
      name?: string | null,
      createdBy?: string | null,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStaffQueryVariables = {
  email: string,
};

export type GetStaffQuery = {
  getStaff?:  {
    __typename: "Staff",
    email: string,
    userId?: string | null,
    userName?: string | null,
    roleName?: StaffRoles | null,
    status?: StaffStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListStaffQueryVariables = {
  email?: string | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStaffQuery = {
  listStaff?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      email: string,
      userId?: string | null,
      userName?: string | null,
      roleName?: StaffRoles | null,
      status?: StaffStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?:  {
    __typename: "Team",
    id: string,
    workspaceId?: string | null,
    name?: string | null,
    active?: boolean | null,
    workspacesForRead?: Array< string | null > | null,
    members?:  Array< {
      __typename: "TeamMember",
      userId?: string | null,
      priority?: number | null,
    } | null > | null,
    assignmentMode?: AssignmentMode | null,
    rescheduleWith?: RescheduleWith | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTeamsQueryVariables = {
  id?: string | null,
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTeamsQuery = {
  listTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      workspaceId?: string | null,
      name?: string | null,
      active?: boolean | null,
      workspacesForRead?: Array< string | null > | null,
      assignmentMode?: AssignmentMode | null,
      rescheduleWith?: RescheduleWith | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TeamsByWorkspaceIdQueryVariables = {
  workspaceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TeamsByWorkspaceIdQuery = {
  teamsByWorkspaceId?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      workspaceId?: string | null,
      name?: string | null,
      active?: boolean | null,
      workspacesForRead?: Array< string | null > | null,
      assignmentMode?: AssignmentMode | null,
      rescheduleWith?: RescheduleWith | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateTeamRoundRobinSubscriptionVariables = {
  filter?: ModelSubscriptionTeamRoundRobinFilterInput | null,
};

export type OnCreateTeamRoundRobinSubscription = {
  onCreateTeamRoundRobin?:  {
    __typename: "TeamRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTeamRoundRobinSubscriptionVariables = {
  filter?: ModelSubscriptionTeamRoundRobinFilterInput | null,
};

export type OnUpdateTeamRoundRobinSubscription = {
  onUpdateTeamRoundRobin?:  {
    __typename: "TeamRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTeamRoundRobinSubscriptionVariables = {
  filter?: ModelSubscriptionTeamRoundRobinFilterInput | null,
};

export type OnDeleteTeamRoundRobinSubscription = {
  onDeleteTeamRoundRobin?:  {
    __typename: "TeamRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTeamUserRoundRobinSubscriptionVariables = {
  filter?: ModelSubscriptionTeamUserRoundRobinFilterInput | null,
};

export type OnCreateTeamUserRoundRobinSubscription = {
  onCreateTeamUserRoundRobin?:  {
    __typename: "TeamUserRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTeamUserRoundRobinSubscriptionVariables = {
  filter?: ModelSubscriptionTeamUserRoundRobinFilterInput | null,
};

export type OnUpdateTeamUserRoundRobinSubscription = {
  onUpdateTeamUserRoundRobin?:  {
    __typename: "TeamUserRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTeamUserRoundRobinSubscriptionVariables = {
  filter?: ModelSubscriptionTeamUserRoundRobinFilterInput | null,
};

export type OnDeleteTeamUserRoundRobinSubscription = {
  onDeleteTeamUserRoundRobin?:  {
    __typename: "TeamUserRoundRobin",
    teamId: string,
    userId: string,
    lastCreatedEvent?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserDataSubscriptionVariables = {
  filter?: ModelSubscriptionUserDataFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserDataSubscription = {
  onCreateUserData?:  {
    __typename: "UserData",
    userId: string,
    tenant: string,
    owner?: string | null,
    link: string,
    recordType: UserRecordType,
    userSettings?:  {
      __typename: "UserSettings",
      userDefaultAvailabilityId?: string | null,
      avatar?: string | null,
      fullName?: string | null,
      phoneDetails?: string | null,
      language?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      country?: string | null,
      countryCode?: string | null,
      timeZone?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      isQuickSetupFinished?: boolean | null,
      isInvitePeopleClosed?: boolean | null,
      defaultVideoIntegration?: VideoConferenceType | null,
      calendarWarningDate?: string | null,
    } | null,
    availabilityData?:  {
      __typename: "Availability",
      id: string,
      name: string,
      activeBookingPages?: Array< string | null > | null,
      isDefault?: boolean | null,
    } | null,
    statistics?:  {
      __typename: "Statistics",
      eventCreated?: number | null,
      emailsSent?: number | null,
      eventRescheduled?: number | null,
      eventCanceled?: number | null,
    } | null,
    lastActive?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserDataSubscriptionVariables = {
  filter?: ModelSubscriptionUserDataFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserDataSubscription = {
  onUpdateUserData?:  {
    __typename: "UserData",
    userId: string,
    tenant: string,
    owner?: string | null,
    link: string,
    recordType: UserRecordType,
    userSettings?:  {
      __typename: "UserSettings",
      userDefaultAvailabilityId?: string | null,
      avatar?: string | null,
      fullName?: string | null,
      phoneDetails?: string | null,
      language?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      country?: string | null,
      countryCode?: string | null,
      timeZone?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      isQuickSetupFinished?: boolean | null,
      isInvitePeopleClosed?: boolean | null,
      defaultVideoIntegration?: VideoConferenceType | null,
      calendarWarningDate?: string | null,
    } | null,
    availabilityData?:  {
      __typename: "Availability",
      id: string,
      name: string,
      activeBookingPages?: Array< string | null > | null,
      isDefault?: boolean | null,
    } | null,
    statistics?:  {
      __typename: "Statistics",
      eventCreated?: number | null,
      emailsSent?: number | null,
      eventRescheduled?: number | null,
      eventCanceled?: number | null,
    } | null,
    lastActive?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserDataSubscriptionVariables = {
  filter?: ModelSubscriptionUserDataFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserDataSubscription = {
  onDeleteUserData?:  {
    __typename: "UserData",
    userId: string,
    tenant: string,
    owner?: string | null,
    link: string,
    recordType: UserRecordType,
    userSettings?:  {
      __typename: "UserSettings",
      userDefaultAvailabilityId?: string | null,
      avatar?: string | null,
      fullName?: string | null,
      phoneDetails?: string | null,
      language?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      country?: string | null,
      countryCode?: string | null,
      timeZone?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      isQuickSetupFinished?: boolean | null,
      isInvitePeopleClosed?: boolean | null,
      defaultVideoIntegration?: VideoConferenceType | null,
      calendarWarningDate?: string | null,
    } | null,
    availabilityData?:  {
      __typename: "Availability",
      id: string,
      name: string,
      activeBookingPages?: Array< string | null > | null,
      isDefault?: boolean | null,
    } | null,
    statistics?:  {
      __typename: "Statistics",
      eventCreated?: number | null,
      emailsSent?: number | null,
      eventRescheduled?: number | null,
      eventCanceled?: number | null,
    } | null,
    lastActive?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserEventSubscriptionVariables = {
  filter?: ModelSubscriptionUserEventFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserEventSubscription = {
  onCreateUserEvent?:  {
    __typename: "UserEvent",
    userId: string,
    tenant: string,
    eventDate: string,
    eventId: string,
    editKey?: string | null,
    shortLink?: string | null,
    shortEditLink?: string | null,
    bookingPageId: string,
    bookingPageName: string,
    owner?: string | null,
    startTime: string,
    endTime: string,
    beforeStartTime?: number | null,
    afterEndTime?: number | null,
    location?:  {
      __typename: "Location",
      type: LocationType,
      settings?: string | null,
    } | null,
    integrations?:  Array< {
      __typename: "Integration",
      type: IntegrationType,
      settings?: string | null,
    } | null > | null,
    timeZone?: string | null,
    note?: string | null,
    cohosts?: Array< string | null > | null,
    guestEmails?: Array< string | null > | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    canceled?:  {
      __typename: "CanceledEvent",
      isCanceled: boolean,
      type?: CanceledType | null,
      note?: string | null,
    } | null,
    executionIds?:  {
      __typename: "ExecutionIds",
      emailExecutionIds?: Array< string | null > | null,
      smsExecutionIds?: Array< string | null > | null,
    } | null,
    isNoShow?: boolean | null,
    host?: string | null,
    internalNotes?: string | null,
    isSMSAllowed?: boolean | null,
    hostName?: string | null,
    cohostNames?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserEventSubscriptionVariables = {
  filter?: ModelSubscriptionUserEventFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserEventSubscription = {
  onUpdateUserEvent?:  {
    __typename: "UserEvent",
    userId: string,
    tenant: string,
    eventDate: string,
    eventId: string,
    editKey?: string | null,
    shortLink?: string | null,
    shortEditLink?: string | null,
    bookingPageId: string,
    bookingPageName: string,
    owner?: string | null,
    startTime: string,
    endTime: string,
    beforeStartTime?: number | null,
    afterEndTime?: number | null,
    location?:  {
      __typename: "Location",
      type: LocationType,
      settings?: string | null,
    } | null,
    integrations?:  Array< {
      __typename: "Integration",
      type: IntegrationType,
      settings?: string | null,
    } | null > | null,
    timeZone?: string | null,
    note?: string | null,
    cohosts?: Array< string | null > | null,
    guestEmails?: Array< string | null > | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    canceled?:  {
      __typename: "CanceledEvent",
      isCanceled: boolean,
      type?: CanceledType | null,
      note?: string | null,
    } | null,
    executionIds?:  {
      __typename: "ExecutionIds",
      emailExecutionIds?: Array< string | null > | null,
      smsExecutionIds?: Array< string | null > | null,
    } | null,
    isNoShow?: boolean | null,
    host?: string | null,
    internalNotes?: string | null,
    isSMSAllowed?: boolean | null,
    hostName?: string | null,
    cohostNames?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserEventSubscriptionVariables = {
  filter?: ModelSubscriptionUserEventFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserEventSubscription = {
  onDeleteUserEvent?:  {
    __typename: "UserEvent",
    userId: string,
    tenant: string,
    eventDate: string,
    eventId: string,
    editKey?: string | null,
    shortLink?: string | null,
    shortEditLink?: string | null,
    bookingPageId: string,
    bookingPageName: string,
    owner?: string | null,
    startTime: string,
    endTime: string,
    beforeStartTime?: number | null,
    afterEndTime?: number | null,
    location?:  {
      __typename: "Location",
      type: LocationType,
      settings?: string | null,
    } | null,
    integrations?:  Array< {
      __typename: "Integration",
      type: IntegrationType,
      settings?: string | null,
    } | null > | null,
    timeZone?: string | null,
    note?: string | null,
    cohosts?: Array< string | null > | null,
    guestEmails?: Array< string | null > | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    canceled?:  {
      __typename: "CanceledEvent",
      isCanceled: boolean,
      type?: CanceledType | null,
      note?: string | null,
    } | null,
    executionIds?:  {
      __typename: "ExecutionIds",
      emailExecutionIds?: Array< string | null > | null,
      smsExecutionIds?: Array< string | null > | null,
    } | null,
    isNoShow?: boolean | null,
    host?: string | null,
    internalNotes?: string | null,
    isSMSAllowed?: boolean | null,
    hostName?: string | null,
    cohostNames?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAdminDataSubscriptionVariables = {
  filter?: ModelSubscriptionAdminDataFilterInput | null,
  owner?: string | null,
};

export type OnCreateAdminDataSubscription = {
  onCreateAdminData?:  {
    __typename: "AdminData",
    email: string,
    userId?: string | null,
    roleId: string,
    status?: string | null,
    tenantId: string,
    workspaceIds?: Array< string | null > | null,
    invitedById?: string | null,
    invitedByName?: string | null,
    team?: Array< string | null > | null,
    bookingTemplateIds?: Array< string | null > | null,
    expirationDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAdminDataSubscriptionVariables = {
  filter?: ModelSubscriptionAdminDataFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAdminDataSubscription = {
  onUpdateAdminData?:  {
    __typename: "AdminData",
    email: string,
    userId?: string | null,
    roleId: string,
    status?: string | null,
    tenantId: string,
    workspaceIds?: Array< string | null > | null,
    invitedById?: string | null,
    invitedByName?: string | null,
    team?: Array< string | null > | null,
    bookingTemplateIds?: Array< string | null > | null,
    expirationDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAdminDataSubscriptionVariables = {
  filter?: ModelSubscriptionAdminDataFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAdminDataSubscription = {
  onDeleteAdminData?:  {
    __typename: "AdminData",
    email: string,
    userId?: string | null,
    roleId: string,
    status?: string | null,
    tenantId: string,
    workspaceIds?: Array< string | null > | null,
    invitedById?: string | null,
    invitedByName?: string | null,
    team?: Array< string | null > | null,
    bookingTemplateIds?: Array< string | null > | null,
    expirationDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
  owner?: string | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    tenantId: string,
    name?: string | null,
    status?: TenantStatus | null,
    tenantStyle?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    workspace?:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      isActive: boolean,
      isPhoneRequired: boolean,
      bookingPageIndex?: number | null,
    } | null > | null,
    type?: OrgType | null,
    note?: string | null,
    subscription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    tenantId: string,
    name?: string | null,
    status?: TenantStatus | null,
    tenantStyle?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    workspace?:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      isActive: boolean,
      isPhoneRequired: boolean,
      bookingPageIndex?: number | null,
    } | null > | null,
    type?: OrgType | null,
    note?: string | null,
    subscription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    tenantId: string,
    name?: string | null,
    status?: TenantStatus | null,
    tenantStyle?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    workspace?:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      isActive: boolean,
      isPhoneRequired: boolean,
      bookingPageIndex?: number | null,
    } | null > | null,
    type?: OrgType | null,
    note?: string | null,
    subscription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBookingTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionBookingTemplateFilterInput | null,
  owner?: string | null,
};

export type OnCreateBookingTemplateSubscription = {
  onCreateBookingTemplate?:  {
    __typename: "BookingTemplate",
    id: string,
    workspaceId: string,
    enabled?: boolean | null,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    potentialHosts?: Array< string | null > | null,
    potentialTeams?: Array< string | null > | null,
    what?:  {
      __typename: "BookingTemplateWhat",
      customName?: string | null,
      color?: string | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBookingTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionBookingTemplateFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBookingTemplateSubscription = {
  onUpdateBookingTemplate?:  {
    __typename: "BookingTemplate",
    id: string,
    workspaceId: string,
    enabled?: boolean | null,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    potentialHosts?: Array< string | null > | null,
    potentialTeams?: Array< string | null > | null,
    what?:  {
      __typename: "BookingTemplateWhat",
      customName?: string | null,
      color?: string | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBookingTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionBookingTemplateFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBookingTemplateSubscription = {
  onDeleteBookingTemplate?:  {
    __typename: "BookingTemplate",
    id: string,
    workspaceId: string,
    enabled?: boolean | null,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    potentialHosts?: Array< string | null > | null,
    potentialTeams?: Array< string | null > | null,
    what?:  {
      __typename: "BookingTemplateWhat",
      customName?: string | null,
      color?: string | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBookingPageSubscriptionVariables = {
  filter?: ModelSubscriptionBookingPageFilterInput | null,
  owner?: string | null,
};

export type OnCreateBookingPageSubscription = {
  onCreateBookingPage?:  {
    __typename: "BookingPage",
    id: string,
    workspaceId: string,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    shortLink?: string | null,
    adminOnly?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    what?:  {
      __typename: "BookingPageWhat",
      customName?: string | null,
      color?: string | null,
      bookingTemplateId?: string | null,
      isActive?: boolean | null,
    } | null,
    who?:  {
      __typename: "Who",
      hostAssignmentMode?: AssignmentMode | null,
      hostRescheduleWith?: RescheduleWith | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    displayId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBookingPageSubscriptionVariables = {
  filter?: ModelSubscriptionBookingPageFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBookingPageSubscription = {
  onUpdateBookingPage?:  {
    __typename: "BookingPage",
    id: string,
    workspaceId: string,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    shortLink?: string | null,
    adminOnly?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    what?:  {
      __typename: "BookingPageWhat",
      customName?: string | null,
      color?: string | null,
      bookingTemplateId?: string | null,
      isActive?: boolean | null,
    } | null,
    who?:  {
      __typename: "Who",
      hostAssignmentMode?: AssignmentMode | null,
      hostRescheduleWith?: RescheduleWith | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    displayId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBookingPageSubscriptionVariables = {
  filter?: ModelSubscriptionBookingPageFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBookingPageSubscription = {
  onDeleteBookingPage?:  {
    __typename: "BookingPage",
    id: string,
    workspaceId: string,
    inviteOthers?: boolean | null,
    enterNote?: boolean | null,
    shortLink?: string | null,
    adminOnly?: boolean | null,
    labels?:  {
      __typename: "BookingPageLabels",
      eventNameLabel?: string | null,
      bookingTemplateLabel?: string | null,
      detailsLabel?: string | null,
      dateLabel?: string | null,
      timeLabel?: string | null,
      informationLabel?: string | null,
      additionalLabel?: string | null,
      cancelLabel?: string | null,
      bookButtonLabel?: string | null,
      updateButtonLabel?: string | null,
      bookAnotherButtonLabel?: string | null,
      cancelButtonLabel?: string | null,
      bookTitle?: string | null,
      bookDescription?: string | null,
      rescheduleTitle?: string | null,
      rescheduleDescription?: string | null,
      cancelTitle?: string | null,
      cancelDescription?: string | null,
    } | null,
    style?:  {
      __typename: "BookingPageStyle",
      avatar?: string | null,
      backgroundImage?: string | null,
      opacity?: number | null,
      css?: string | null,
      footer?: string | null,
    } | null,
    calendar?:  {
      __typename: "BookingCalendar",
      timeFormat?: TimeFormat | null,
      calendarFormat?: CalendarFormat | null,
      timeInterval?: number | null,
      topOfInterval?: boolean | null,
      timeZoneType?: TimeZoneType | null,
      selectedTimeZone?: Array< string | null > | null,
      defaultTimeZone?: string | null,
    } | null,
    what?:  {
      __typename: "BookingPageWhat",
      customName?: string | null,
      color?: string | null,
      bookingTemplateId?: string | null,
      isActive?: boolean | null,
    } | null,
    who?:  {
      __typename: "Who",
      hostAssignmentMode?: AssignmentMode | null,
      hostRescheduleWith?: RescheduleWith | null,
    } | null,
    where?:  {
      __typename: "Where",
      locationTypes?: Array< LocationType | null > | null,
      videoConferenceType?: VideoConferenceType | null,
      defaultLocationType?: LocationType | null,
      inPersonType?: InPersonType | null,
      customAddress?: string | null,
      locations?: Array< string | null > | null,
      phoneCallType?: PhoneCallType | null,
      customPhone?: string | null,
      customCountryCode?: string | null,
      skipTheWhereStep?: boolean | null,
    } | null,
    when?:  {
      __typename: "When",
      afterEndTime?: string | null,
      beforeStartTime?: string | null,
      eventsPerDay?: number | null,
    } | null,
    inputFields?:  Array< {
      __typename: "CustomField",
      id?: string | null,
      answers?: Array< string | null > | null,
      enabled?: boolean | null,
      label?: string | null,
      required?: boolean | null,
      type?: CustomType | null,
      value?: string | null,
      fieldType?: CustomFieldType | null,
    } | null > | null,
    notifications?:  {
      __typename: "Notifications",
    } | null,
    confirmationsPage?:  {
      __typename: "ConfirmationsPage",
      type?: ConfirmationsPageType | null,
      externalLink?: string | null,
      anotherEventEnabled?: boolean | null,
      rescheduleEnabled?: boolean | null,
      cancelEnabled?: boolean | null,
      cancelationPolicy?: string | null,
    } | null,
    lastModify?: string | null,
    displayId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateRoleSubscriptionVariables = {
  filter?: ModelSubscriptionRoleFilterInput | null,
  owner?: string | null,
};

export type OnCreateRoleSubscription = {
  onCreateRole?:  {
    __typename: "Role",
    id: string,
    tenantId: string,
    name: string,
    isActive: boolean,
    isCustom: boolean,
    hasChromeAccess: boolean,
    users?: Array< PermissionAction | null > | null,
    availability?: Array< PermissionAction | null > | null,
    myBookingPages?: Array< PermissionAction | null > | null,
    allBookingPages?: Array< PermissionAction | null > | null,
    bookingTemplates?: Array< PermissionAction | null > | null,
    resources?: Array< PermissionAction | null > | null,
    teams?: Array< PermissionAction | null > | null,
    isAccessToAllWorkspaces?: boolean | null,
    workspaceAdminActions?: Array< PermissionAction | null > | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateRoleSubscriptionVariables = {
  filter?: ModelSubscriptionRoleFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRoleSubscription = {
  onUpdateRole?:  {
    __typename: "Role",
    id: string,
    tenantId: string,
    name: string,
    isActive: boolean,
    isCustom: boolean,
    hasChromeAccess: boolean,
    users?: Array< PermissionAction | null > | null,
    availability?: Array< PermissionAction | null > | null,
    myBookingPages?: Array< PermissionAction | null > | null,
    allBookingPages?: Array< PermissionAction | null > | null,
    bookingTemplates?: Array< PermissionAction | null > | null,
    resources?: Array< PermissionAction | null > | null,
    teams?: Array< PermissionAction | null > | null,
    isAccessToAllWorkspaces?: boolean | null,
    workspaceAdminActions?: Array< PermissionAction | null > | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteRoleSubscriptionVariables = {
  filter?: ModelSubscriptionRoleFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRoleSubscription = {
  onDeleteRole?:  {
    __typename: "Role",
    id: string,
    tenantId: string,
    name: string,
    isActive: boolean,
    isCustom: boolean,
    hasChromeAccess: boolean,
    users?: Array< PermissionAction | null > | null,
    availability?: Array< PermissionAction | null > | null,
    myBookingPages?: Array< PermissionAction | null > | null,
    allBookingPages?: Array< PermissionAction | null > | null,
    bookingTemplates?: Array< PermissionAction | null > | null,
    resources?: Array< PermissionAction | null > | null,
    teams?: Array< PermissionAction | null > | null,
    isAccessToAllWorkspaces?: boolean | null,
    workspaceAdminActions?: Array< PermissionAction | null > | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateLicenseSubscriptionVariables = {
  filter?: ModelSubscriptionLicenseFilterInput | null,
  owner?: string | null,
};

export type OnCreateLicenseSubscription = {
  onCreateLicense?:  {
    __typename: "License",
    id: string,
    tenantId: string,
    type?: LicenseType | null,
    startDate?: string | null,
    endDate?: string | null,
    owned?: number | null,
    assigned?: number | null,
    hasScheduledChanges?: boolean | null,
    scheduledChanges?:  Array< {
      __typename: "ScheduledChanges",
      id?: string | null,
      seats?: number | null,
      plan?: string | null,
      changeBy?: string | null,
      requestDate?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateLicenseSubscriptionVariables = {
  filter?: ModelSubscriptionLicenseFilterInput | null,
  owner?: string | null,
};

export type OnUpdateLicenseSubscription = {
  onUpdateLicense?:  {
    __typename: "License",
    id: string,
    tenantId: string,
    type?: LicenseType | null,
    startDate?: string | null,
    endDate?: string | null,
    owned?: number | null,
    assigned?: number | null,
    hasScheduledChanges?: boolean | null,
    scheduledChanges?:  Array< {
      __typename: "ScheduledChanges",
      id?: string | null,
      seats?: number | null,
      plan?: string | null,
      changeBy?: string | null,
      requestDate?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteLicenseSubscriptionVariables = {
  filter?: ModelSubscriptionLicenseFilterInput | null,
  owner?: string | null,
};

export type OnDeleteLicenseSubscription = {
  onDeleteLicense?:  {
    __typename: "License",
    id: string,
    tenantId: string,
    type?: LicenseType | null,
    startDate?: string | null,
    endDate?: string | null,
    owned?: number | null,
    assigned?: number | null,
    hasScheduledChanges?: boolean | null,
    scheduledChanges?:  Array< {
      __typename: "ScheduledChanges",
      id?: string | null,
      seats?: number | null,
      plan?: string | null,
      changeBy?: string | null,
      requestDate?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  owner?: string | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    tenantIds?: Array< string | null > | null,
    name?: string | null,
    createdBy?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    tenantIds?: Array< string | null > | null,
    name?: string | null,
    createdBy?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    tenantIds?: Array< string | null > | null,
    name?: string | null,
    createdBy?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateStaffSubscriptionVariables = {
  filter?: ModelSubscriptionStaffFilterInput | null,
  owner?: string | null,
};

export type OnCreateStaffSubscription = {
  onCreateStaff?:  {
    __typename: "Staff",
    email: string,
    userId?: string | null,
    userName?: string | null,
    roleName?: StaffRoles | null,
    status?: StaffStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateStaffSubscriptionVariables = {
  filter?: ModelSubscriptionStaffFilterInput | null,
  owner?: string | null,
};

export type OnUpdateStaffSubscription = {
  onUpdateStaff?:  {
    __typename: "Staff",
    email: string,
    userId?: string | null,
    userName?: string | null,
    roleName?: StaffRoles | null,
    status?: StaffStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteStaffSubscriptionVariables = {
  filter?: ModelSubscriptionStaffFilterInput | null,
  owner?: string | null,
};

export type OnDeleteStaffSubscription = {
  onDeleteStaff?:  {
    __typename: "Staff",
    email: string,
    userId?: string | null,
    userName?: string | null,
    roleName?: StaffRoles | null,
    status?: StaffStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
  owner?: string | null,
};

export type OnCreateTeamSubscription = {
  onCreateTeam?:  {
    __typename: "Team",
    id: string,
    workspaceId?: string | null,
    name?: string | null,
    active?: boolean | null,
    workspacesForRead?: Array< string | null > | null,
    members?:  Array< {
      __typename: "TeamMember",
      userId?: string | null,
      priority?: number | null,
    } | null > | null,
    assignmentMode?: AssignmentMode | null,
    rescheduleWith?: RescheduleWith | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTeamSubscription = {
  onUpdateTeam?:  {
    __typename: "Team",
    id: string,
    workspaceId?: string | null,
    name?: string | null,
    active?: boolean | null,
    workspacesForRead?: Array< string | null > | null,
    members?:  Array< {
      __typename: "TeamMember",
      userId?: string | null,
      priority?: number | null,
    } | null > | null,
    assignmentMode?: AssignmentMode | null,
    rescheduleWith?: RescheduleWith | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTeamSubscription = {
  onDeleteTeam?:  {
    __typename: "Team",
    id: string,
    workspaceId?: string | null,
    name?: string | null,
    active?: boolean | null,
    workspacesForRead?: Array< string | null > | null,
    members?:  Array< {
      __typename: "TeamMember",
      userId?: string | null,
      priority?: number | null,
    } | null > | null,
    assignmentMode?: AssignmentMode | null,
    rescheduleWith?: RescheduleWith | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
