import { Button } from 'primereact/button';
import { QuickSetupScreen } from '..';
import labels from './labels';
import { quickSetupActions, quickSetupNavigationActions, quickSetupSelectors } from '../../../store/quickSetup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import chromeExtensionPhoto from '../../../assets/images/chromeExtension.png';
import { Link } from 'react-router-dom';
import { authentificationSelectors } from '../../../store/authentification';
import { Checkbox } from 'primereact/checkbox';
import { userSettingsSelectors } from '../../../store/userSettings';
import urlConstants from '../../../shared/JSON/urlConstants.json';

export const SetupChromeExtensionStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isThirdPartyGoogle = useSelector(authentificationSelectors.selectIsThirdPartyGoogle);
  const isFirstAdmin = useSelector(userSettingsSelectors.selectIsFirstTenantUser);
  const isAcceptedTerms = useSelector(quickSetupSelectors.selectIsAcceptTerms);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);

  const handleSave = () => {
    dispatch(quickSetupActions.saveQuickSetupRequest());
  };

  const handleBack = () => {
    dispatch(quickSetupNavigationActions.navigateBack());
  };

  const handleAcceptTerms = (value: boolean) => {
    dispatch(quickSetupActions.setIsAcceptTerms(value));
  };

  return (
    <QuickSetupScreen.Container title={labels.title}>
      <div className="flex flex-column p-3">
        <div className="flex flex-row flex-wrap">
          <div className="col-12 md:col-6 md:pr-3">
            <div className="text-xl font-bold">
              {isThirdPartyGoogle ? labels.sumo1ForGoogle : labels.sumo1ForMicrosoft}
            </div>
            <div className="text-color-secondary">{labels.chromeExtension}</div>
          </div>
          <div className="col-12 md:col-6 flex flex-column">
            <Button label={labels.title} className="w-15rem md:align-self-end" outlined />
          </div>
        </div>
        <div className="flex flex-row flex-wrap mt-3">
          <div className="col-12 md:col-6 md:pr-3">
            <img src={chromeExtensionPhoto} className="w-full" />
          </div>
          <div className="col-12 md:col-6 md:px-3 flex flex-column">
            <div className="text-xl mb-1">{labels.benefitsTitle}</div>
            <div>{labels.benefitsDesc}</div>
            {labels.benefitsList.map((item, index) => (
              <div key={index}>- {item}</div>
            ))}
            <div className="text-xl mb-1 mt-3">{labels.requirementsTitle}</div>
            <div>{labels.requirementsDesc}</div>
          </div>
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <div className="flex justify-content-between">
          <Button label={labels.back} className="w-8rem m-2 align-self-end" onClick={handleBack} outlined />
          <div className="flex flex-column md:flex-row">
            {isFirstAdmin && (
              <div className="align-self-center">
                <Checkbox
                  checked={isAcceptedTerms || !!isQuickSetupFinished}
                  onChange={(e) => handleAcceptTerms(!!e.target.checked)}
                  disabled={!!isQuickSetupFinished}
                />
                <Link
                  to={urlConstants.TERMS_OF_SERVICE_URL}
                  target="_blank"
                  className="ml-2 mr-3 underline text-primary hover:text-primary-700"
                >
                  {labels.acceptTerms_step1}
                </Link>
                {labels.acceptTerms_step2}
                <Link
                  to={urlConstants.PRIVACY_POLICY_URL}
                  target="_blank"
                  className="ml-2 mr-3 underline text-primary hover:text-primary-700"
                >
                  {labels.acceptTerms_step3}
                </Link>
              </div>
            )}
            <Button
              label={labels.save}
              className="w-13rem m-2"
              onClick={handleSave}
              disabled={isFirstAdmin && !isAcceptedTerms && !isQuickSetupFinished}
            />
          </div>
        </div>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
