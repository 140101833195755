import { AppThunk } from '../rootStore';
import {
  BookingPageLabelsInput,
  CanceledEventInput,
  CreateUserEventInput,
  CustomFieldInput,
  IntegrationInput,
  LocationInput,
  UpdateUserEventInput,
} from '../../API';
import { AgendaType, CreateUpdateEventResponse, EventSteps, PreviewData, RescheduleData, ViewSettings } from './types';
import { eventSelectors } from './selectors';
import { calculateEventLocationSettings, calculateUpdatedEvent } from './utils';

export enum EventActionTypes {
  CREATE_EVENT_REQUEST = 'event/CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS = 'event/CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAIL = 'event/CREATE_EVENT_FAIL',
  UPDATE_EVENT_REQUEST = 'event/UPDATE_EVENT_REQUEST',
  UPDATE_EVENT_SUCCESS = 'event/UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAIL = 'event/UPDATE_EVENT_FAIL',
  GET_AGENDA_REQUEST = 'event/GET_AGENDA_REQUEST',
  GET_AGENDA_SUCCESS = 'event/GET_AGENDA_SUCCESS',
  GET_AGENDA_FAIL = 'event/GET_AGENDA_FAIL',
  CANCEL_EVENT_REQUEST = 'event/CANCEL_EVENT_REQUEST',
  CANCEL_EVENT_SUCCESS = 'event/CANCEL_EVENT_SUCCESS',
  CANCEL_EVENT_FAIL = 'event/CANCEL_EVENT_FAIL',
  UPDATE_EVENT = 'event/UPDATE_EVENT',
  UPDATE_LOCATION = 'event/UPDATE_LOCATION',
  UPDATE_INTEGRATION = 'event/UPDATE_INTEGRATION',
  // UPDATE_INPUT_FIELDS = 'event/UPDATE_INPUT_FIELDS',
  UPDATE_CUSTOM_FIELD = 'event/UPDATE_CUSTOM_FIELD',
  UPDATE_CANCELED = 'event/UPDATE_CANCELED',
  SET_EVENT_STEP = 'event/SET_EVENT_STEP',
  SET_PREVIOUS_STEP = 'event/SET_PREVIOUS_STEP',
  SET_NEXT_STEP = 'event/SET_NEXT_STEP',
  SET_IS_MOBILE = 'event/SET_IS_MOBILE',
  SET_RESCHEDULE_DATA = 'event/SET_RESCHEDULE_DATA',
  SET_PREVIEW_DATA = 'event/SET_PREVIEW_DATA',
  SET_PREVIEW_BOOKING_PAGE_HOW = 'event/SET_PREVIEW_BOOKING_PAGE_HOW',
  SET_BOOKING_PAGE_ID = 'event/SET_BOOKING_PAGE_ID',
  SET_VIEW_DATE = 'event/SET_VIEW_DATE',
  SET_VIEW_SETTINGS = 'event/SET_VIEW_SETTINGS',
  SET_SCHEDULED_MEETING = 'event/SET_SCHEDULED_MEETING',
  SET_EVENT_ID = 'event/SET_EVENT_ID',
  SET_READ_ONLY = 'event/SET_READ_ONLY',
}

export type EventAction =
  | { type: EventActionTypes.CREATE_EVENT_REQUEST }
  | { type: EventActionTypes.CREATE_EVENT_SUCCESS; payload: CreateUpdateEventResponse }
  | { type: EventActionTypes.CREATE_EVENT_FAIL; error: string }
  | { type: EventActionTypes.UPDATE_EVENT_REQUEST }
  | { type: EventActionTypes.UPDATE_EVENT_SUCCESS; payload: CreateUpdateEventResponse }
  | { type: EventActionTypes.UPDATE_EVENT_FAIL; error: string }
  | { type: EventActionTypes.GET_AGENDA_REQUEST }
  | { type: EventActionTypes.GET_AGENDA_SUCCESS; payload: AgendaType }
  | { type: EventActionTypes.GET_AGENDA_FAIL; error: string }
  | { type: EventActionTypes.CANCEL_EVENT_REQUEST }
  | { type: EventActionTypes.CANCEL_EVENT_SUCCESS }
  | { type: EventActionTypes.CANCEL_EVENT_FAIL; error: string }
  | { type: EventActionTypes.UPDATE_EVENT; payload: Partial<CreateUserEventInput> }
  | { type: EventActionTypes.UPDATE_LOCATION; payload: Partial<LocationInput> }
  | { type: EventActionTypes.UPDATE_INTEGRATION; payload: Partial<IntegrationInput> }
  // | { type: EventActionTypes.UPDATE_INPUT_FIELDS; payload: Partial<CustomFieldInput> }
  | { type: EventActionTypes.UPDATE_CUSTOM_FIELD; payload: CustomFieldInput }
  | { type: EventActionTypes.UPDATE_CANCELED; payload: Partial<CanceledEventInput> }
  | { type: EventActionTypes.SET_EVENT_STEP; payload: EventSteps }
  | { type: EventActionTypes.SET_PREVIOUS_STEP }
  | { type: EventActionTypes.SET_NEXT_STEP }
  | { type: EventActionTypes.SET_IS_MOBILE }
  | { type: EventActionTypes.SET_RESCHEDULE_DATA; payload: RescheduleData }
  | { type: EventActionTypes.SET_PREVIEW_DATA; payload: PreviewData }
  | { type: EventActionTypes.SET_PREVIEW_BOOKING_PAGE_HOW; payload: Partial<BookingPageLabelsInput> }
  | { type: EventActionTypes.SET_BOOKING_PAGE_ID; payload: string }
  | { type: EventActionTypes.SET_VIEW_DATE; payload: number }
  | { type: EventActionTypes.SET_VIEW_SETTINGS; payload: Partial<ViewSettings> }
  | { type: EventActionTypes.SET_SCHEDULED_MEETING; payload: UpdateUserEventInput }
  | { type: EventActionTypes.SET_EVENT_ID; payload: string }
  | { type: EventActionTypes.SET_READ_ONLY; payload: boolean };

const createEventRequest = (): EventAction => ({ type: EventActionTypes.CREATE_EVENT_REQUEST });
const createEventSuccess = (payload: CreateUpdateEventResponse): EventAction => ({
  type: EventActionTypes.CREATE_EVENT_SUCCESS,
  payload,
});
const createEventFail = (error: string): EventAction => ({ type: EventActionTypes.CREATE_EVENT_FAIL, error });
const updateEventRequest = (): EventAction => ({ type: EventActionTypes.UPDATE_EVENT_REQUEST });
const updateEventSuccess = (payload: CreateUpdateEventResponse): EventAction => ({
  type: EventActionTypes.UPDATE_EVENT_SUCCESS,
  payload,
});
const updateEventFail = (error: string): EventAction => ({ type: EventActionTypes.UPDATE_EVENT_FAIL, error });
const getAgendaRequest = (): EventAction => ({ type: EventActionTypes.GET_AGENDA_REQUEST });
const getAgendaSuccess = (payload: AgendaType): EventAction => ({
  type: EventActionTypes.GET_AGENDA_SUCCESS,
  payload,
});
const getAgendaFail = (error: string): EventAction => ({
  type: EventActionTypes.GET_AGENDA_FAIL,
  error,
});
const cancelEventRequest = (): EventAction => ({
  type: EventActionTypes.CANCEL_EVENT_REQUEST,
});
const cancelEventSuccess = (): EventAction => ({ type: EventActionTypes.CANCEL_EVENT_SUCCESS });
const cancelEventFail = (error: string): EventAction => ({
  type: EventActionTypes.CANCEL_EVENT_FAIL,
  error,
});
const updateEvent = (payload: Partial<CreateUserEventInput>): EventAction => ({
  type: EventActionTypes.UPDATE_EVENT,
  payload,
});
const updateLocation = (payload: Partial<LocationInput>): EventAction => ({
  type: EventActionTypes.UPDATE_LOCATION,
  payload,
});
const updateIntegration = (payload: Partial<IntegrationInput>): EventAction => ({
  type: EventActionTypes.UPDATE_INTEGRATION,
  payload,
});
/* const updateInputFields = (payload: Partial<CustomFieldInput>): EventAction => ({
  type: EventActionTypes.UPDATE_INPUT_FIELDS,
  payload,
}); */
const updateCustomField = (payload: CustomFieldInput): EventAction => ({
  type: EventActionTypes.UPDATE_CUSTOM_FIELD,
  payload,
});
const updateCanceled = (payload: Partial<CanceledEventInput>): EventAction => ({
  type: EventActionTypes.UPDATE_CANCELED,
  payload,
});
const setEventStep = (payload: EventSteps): EventAction => ({
  type: EventActionTypes.SET_EVENT_STEP,
  payload,
});
const setRescheduleData = (payload: RescheduleData): EventAction => ({
  type: EventActionTypes.SET_RESCHEDULE_DATA,
  payload,
});
const setPreviewData = (payload: PreviewData): EventAction => ({
  type: EventActionTypes.SET_PREVIEW_DATA,
  payload,
});
const setPreviewBookingPageHow = (payload: Partial<BookingPageLabelsInput>): EventAction => ({
  type: EventActionTypes.SET_PREVIEW_BOOKING_PAGE_HOW,
  payload,
});
const setBookingPageId = (payload: string): EventAction => ({
  type: EventActionTypes.SET_BOOKING_PAGE_ID,
  payload,
});
const setViewDate = (payload: number): EventAction => ({
  type: EventActionTypes.SET_VIEW_DATE,
  payload,
});
const setPreviousStep = (): EventAction => ({
  type: EventActionTypes.SET_PREVIOUS_STEP,
});
const setNextStep = (): EventAction => ({
  type: EventActionTypes.SET_NEXT_STEP,
});
const setIsMobile = (): EventAction => ({
  type: EventActionTypes.SET_IS_MOBILE,
});

const setViewSettings = (payload: Partial<ViewSettings>): EventAction => ({
  type: EventActionTypes.SET_VIEW_SETTINGS,
  payload,
});

const setEventId = (payload: string): EventAction => ({
  type: EventActionTypes.SET_EVENT_ID,
  payload,
});

const setScheduledMeeting = (payload: UpdateUserEventInput): EventAction => ({
  type: EventActionTypes.SET_SCHEDULED_MEETING,
  payload,
});

const setReadOnly = (payload: boolean): EventAction => ({
  type: EventActionTypes.SET_READ_ONLY,
  payload,
});

const setEventDateThunk =
  (payload: string): AppThunk =>
  (dispatch, getState) => {
    const currentDate = eventSelectors.selectEventDateString(getState());
    const emptyTime = payload !== currentDate;

    dispatch(updateEvent({ eventDate: payload, ...(emptyTime ? { startTime: '' } : {}) }));
  };

const setEventStartTimeThunk =
  (payload: Date): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const agenda = eventSelectors.selectAgenda(state);
    const event = eventSelectors.selectEvent(state);
    const isRescheduleMode = eventSelectors.selectIsRescheduleMode(state);

    dispatch(updateEvent(calculateUpdatedEvent(agenda, event, payload)));
    if (!isRescheduleMode) {
      dispatch(setEventStep(EventSteps.INFO));
    }
  };

const saveEventThunk = (): AppThunk => (dispatch, getState) => {
  const isExisting = eventSelectors.selectIsExisting(getState());
  const bookingPage = eventSelectors.selectBookingPage(getState());
  const event = eventSelectors.selectEvent(getState());
  const hostPhone = eventSelectors.selectHostFullPhone(getState());
  const customPhone = eventSelectors.selectCustomPhone(getState());
  const attendeePhone = eventSelectors.selectAttendeePhoneFormatted(getState());
  const attendeeLocation = eventSelectors.selectAttendeeLocation(getState());

  const settings = calculateEventLocationSettings(
    event.location,
    bookingPage.where,
    hostPhone,
    customPhone,
    attendeePhone,
    attendeeLocation
  );
  dispatch(updateLocation({ settings }));
  dispatch(isExisting ? updateEventRequest() : createEventRequest());
};

export const eventActions = {
  createEventRequest,
  createEventSuccess,
  createEventFail,
  updateEventRequest,
  updateEventSuccess,
  updateEventFail,
  getAgendaRequest,
  getAgendaSuccess,
  getAgendaFail,
  cancelEventRequest,
  cancelEventSuccess,
  cancelEventFail,
  updateEvent,
  updateLocation,
  updateIntegration,
  //updateInputFields,
  updateCustomField,
  updateCanceled,
  setEventStep,
  setPreviousStep,
  setNextStep,
  setIsMobile,
  setRescheduleData,
  setPreviewData,
  setPreviewBookingPageHow,
  setBookingPageId,
  setViewDate,
  setViewSettings,
  setEventId,
  setScheduledMeeting,
  setReadOnly,
  setEventDateThunk,
  setEventStartTimeThunk,
  saveEventThunk,
};
