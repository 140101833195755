import { t } from '../../../i18n/i18n';

export default {
  activeOn: t('Availability:ACTIVE_ON'),
  bookingPages: t('Availability:BOOKING_PAGES'),
  clone: t('Common:CLONE'),
  cloneTitle: t('Availability:CLONE_SCHEDULE_TITLE'),
  cancel: t('Common:CANCEL'),
  delete: t('Common:DELETE'),
  deleteDesc: t('Availability:DELETE_DESCRIPTION'),
  deleteMessage: t('Availability:DELETE_MESSAGE'),
  dialogTitle: t('Availability:DIALOG_TITLE'),
  dialogPlaceholder: t('Availability:DIALOG_PLACEHOLDER'),
  dialogEditTitleName: t('Availability:DIALOG_EDIT_NAME_TITLE'),
  duplicateNameMessage: t('Availability:DUPLICATE_NAME_MESSAGE'),
  editName: t('Availability:EDIT_NAME'),
  bookingPage: t('Availability:BOOKING_PAGE'),
  isDefault: t('Availability:IS_DEFAULT'),
  no: t('Common:NO'),
  timeZone: t('Availability:TIME_ZONE'),
  setDefault: t('Availability:SET_AS_DEFAULT'),
  setWeeklyHours: t('Availability:SET_WEEKLY_HOURS'),
  saveAvailability: t('Availability:SAVE_AVAILABILITY'),
  save: t('Common:SAVE'),
  yes: t('Common:YES'),
};
