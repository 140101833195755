import { API } from 'aws-amplify';
import { APIPostRequest } from '../utils/reduxUtils';
import { ChargeeBeeResponse, Subscription, updateSubscriptionRequest } from './types';

export async function getSubscriptionDetails() {
  try {
    return (await APIPostRequest('/api/public/billing/getSubscriptionDetails', {})) as Subscription;
  } catch (error: unknown) {
    console.error('Error getSubscriptionDetails:', error);
    throw error;
  }
}

export async function changeSeatsOrTerm(body: updateSubscriptionRequest) {
  try {
    return (await APIPostRequest('/api/js/billing/changeSeatsOrTerm', body )) as ChargeeBeeResponse;
  } catch (error: unknown) {
    console.error('Error changeSeatsOrTerm:', error);
    throw error;
  }
}

export async function deleteScheduledChange(scheduledChangeId: string) {
  try {
    await APIPostRequest('/api/js/deleteScheduledChange', { scheduledChangeId });
  } catch (error: unknown) {
    console.error('Error deleteScheduledChange:', error);
    throw error;
  }
}
