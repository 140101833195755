import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CodeResponse } from '@react-oauth/google';
import { IntegrationType } from '../../API';
import { MicrosoftCodeResponse, ThirdPartyType } from '../../store/authentification';
import { userSettingsActions, userSettingsSelectors } from '../../store/userSettings';
import { ConfirmationModal, Preloader, SectionTitle } from '../../components/common';
import { GoogleAuthButton, MicrosoftAuthButton } from '../../components/public';
import labels from './labels';
import { CalendarGroup } from '../../components/calendarConnections';
import urlConstants from '../../shared/JSON/urlConstants.json';

export const CalendarConnections = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const isGoogleCalendarConnected = useSelector(userSettingsSelectors.selectIsGoogleCalendarConnected);
  const isMicrosoftCalendarConnected = useSelector(userSettingsSelectors.selectIsMicrosoftCalendarConnected);
  const email = useSelector(userSettingsSelectors.selectConnectedEmail);

  const [currentCalendarType, setCurrentCalendarType] = useState<IntegrationType | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleGoogleAuthSuccess = (codeResponse: CodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        codeResponse,
        type: ThirdPartyType.GOOGLE,
        integrationType: IntegrationType.GOOGLE_CALENDAR,
        switchAccount: false,
        login: false,
      })
    );
  };

  const handleMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        codeResponse,
        type: ThirdPartyType.MICROSOFT,
        integrationType: IntegrationType.MICROSOFT_CALENDAR,
        switchAccount: false,
        login: false,
      })
    );
  };

  const disconnectCalendar = (calendarType: IntegrationType) => {
    setCurrentCalendarType(calendarType);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (currentCalendarType) {
      dispatch(userSettingsActions.removeIntegration(currentCalendarType));
      dispatch(userSettingsActions.saveUserSettingsRequest());
    }
    setIsConfirmModalOpen(false);
  };

  const calendarGroups: Array<{
    title: string;
    items: Array<{
      calendarTitle: string;
      calendarDescription: string;
      isCalendarConnected: boolean;
      disconnectCalendar: () => void;
      AuthButton: React.ElementType;
      handleAuthSuccess?: (codeResponse: CodeResponse) => void;
      handleAuthRedirect?: (codeResponse: MicrosoftCodeResponse) => void;
    }>;
  }> = [
    {
      title: labels.google,
      items: [
        {
          calendarTitle: labels.googleCalendarTitle,
          calendarDescription: labels.googleCalendarDescription,
          isCalendarConnected: isGoogleCalendarConnected,
          disconnectCalendar: () => disconnectCalendar(IntegrationType.GOOGLE_CALENDAR),
          AuthButton: GoogleAuthButton,
          handleAuthSuccess: handleGoogleAuthSuccess,
        },
      ],
    },
    {
      title: labels.microsoft,
      items: [
        {
          calendarTitle: labels.microsoftCalendarTitle,
          calendarDescription: labels.microsoftCalendarDescription,
          isCalendarConnected: isMicrosoftCalendarConnected,
          disconnectCalendar: () => disconnectCalendar(IntegrationType.MICROSOFT_CALENDAR),
          AuthButton: MicrosoftAuthButton,
          handleAuthRedirect: handleMicrosoftAuthRedirect,
        },
        // {
        //   calendarTitle: labels.exchangeCalendarTitle,
        //   calendarDescription: labels.exchangeCalendarDescription,
        //   isCalendarConnected: false, // Exchange calendar connection status
        //   disconnectCalendar: () => { console.log('Exchange calendar disconnected.'); }, // console.log for typescript until this calendar is not implement
        //   AuthButton: Button, // Auth button for Exchange calendar
        // },
      ],
    },
  ];

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between sumo-header-bg">
        <SectionTitle
          id="calendarConnectorsTitle"
          icon="pi-calendar"
          title={labels.title}
          tooltip={labels.tooltip}
          learnMoreURL={urlConstants.KB_CALENDAR_CONNECTIONS_URL}
        />
      </div>

      <div className="card">
        <span className="block text-xl mb-2">{labels.selectCalendar}</span>
        <p className="text-sm">{labels.description}</p>
        <div className="flex p-2 border-1 border-300" style={{ background: '#e7f4fd' }}>
          <i className="pi pi-info-circle text-2xl pr-2" />
          {labels.calendarInfoMessage}
        </div>

        {calendarGroups.map((group, index) => (
          <CalendarGroup key={index} title={group.title} items={group.items} email={email} />
        ))}
      </div>

      <ConfirmationModal
        visible={isConfirmModalOpen}
        title={labels.disconnect}
        description={labels.disconnectMessage}
        additionalText={[labels.disconnectMessageDescription]}
        confirmButtonLabel={labels.yes}
        cancelButtonLabel={labels.no}
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};
