export const DefaultBookingPage = Object.freeze({
  NAME: `Réunion de 30 minutes`,
  BOOK_A_MEETING: `Réserver une Réunion`,
  MEETING_DETAILS: `Détails de la Réunion`,
  SELECT_DATE: `Sélectionner une Date`,
  SELECT_TIME: `Sélectionner une Heure`,
  YOUR_INFORMATION: `Vos Informations`,
  MORE_OPTIONS: `Plus d'Options`,
  CANCEL: `Annuler`,
  BOOK_MEETING_BUTTON: `Réserver la Réunion`,
  BOOK_ANOTHER_MEETING_BUTTON: `Réserver une Autre Réunion`,
  CANCEL_MEETING_BUTTON: `Annuler la Réunion`,
  SAVE_MEETING_BUTTON: `Reprogrammer`,
  MEETING_BOOKED_TITLE: `Réunion Réservée!`,
  MEETING_BOOKED_DESCRIPTION: `Une confirmation par e-mail a été envoyée à votre boîte de réception.`,
  MEETING_RESCHEDULED_TITLE: `Réunion Reprogrammée!`,
  MEETING_RESCHEDULED_DESCRIPTION: `Une confirmation par e-mail a été envoyée à votre boîte de réception.`,
  MEETING_CANCELED_TITLE: `Réunion Annulée!`,
  MEETING_CANCELED_DESCRIPTION: `Une confirmation par e-mail a été envoyée à votre boîte de réception.`,
});

export const BookingPagesEditBookingPageBufferTimeSettings = Object.freeze({
  AFTER_CHECKBOX_LABEL: `Après l'événement`,
  BEFORE_CHECKBOX_LABEL: `Avant l'événement`,
  DESCRIPTION: `Définir les temps de tampon avant et après`,
  EVENT_TIME: `Heure de l'événement`,
  TITLE: `Bloquer automatiquement l'heure du calendrier avant ou après ce modèle de réservation ?`,
});

export const BookingPagesEditBookingPageDateRangeSettings = Object.freeze({
  INVITEES_CAN_SCHEDULE: `Les invités peuvent planifier ...`,
  INVITEES_CAN_NOT_SCHEDULE: `Les invités ne peuvent pas planifier moins de...`,
  RADIO_GROUP_DAYS_IN_FUTURE_OPTION: `Jours dans le futur`,
  RADIO_GROUP_SPECIFIC_DATES_OPTION: `Dans une plage de dates`,
  RADIO_GROUP_UNLIMITED_OPTION: `Indéfiniment dans le futur`,
});

export const BookingPagesEditBookingPageDurationSettings = Object.freeze({
  DURATION: `Durée`,
  DURATION_TOOLTIP: `Définissez la durée de votre réunion. Ne doit pas dépasser 12 heures.`,
});

export const BookingPagesEditBookingPageWhatStep = Object.freeze({
  EVENT_COLOR_INPUT_LABEL: `Couleur`,
  EVENT_DESCRIPTION_INPUT_LABEL: `Description/Instructions`,
  EVENT_DESCRIPTION_INPUT_PLACEHOLDER: `Rédigez un résumé et tous les détails que votre invité doit connaître sur l'événement.`,
  EVENT_NAME_INPUT_LABEL: `Nom`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Échec de la demande de clonage de la page de réservation`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Demande de clonage de la page de réservation réussie`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Échec de la demande de suppression des pages de réservation`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Demande de suppression des pages de réservation réussie`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Impossible de récupérer la page de réservation, elle pourrait avoir été supprimée`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Échec de la demande d'obtention des pages de réservation`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Échec de la demande d'enregistrement de la page de réservation`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Demande d'enregistrement de la page de réservation réussie`,
});

export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Nouvelle page de réservation`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Êtes-vous sûr de vouloir supprimer cette page de réservation ?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Êtes-vous sûr de vouloir supprimer ces pages de réservation ?`,
  TITLE: `Pages de réservation`,
  ADD_TO_SITE: `Ajouter au site Web`,
  CLONE_MODAL_TITLE: `Cloner la page de réservation`,
  DEFAULT_FILTER: `Mes pages de réservation`,
  SHARED_LABEL: `Partagé`,
  CLONE_NOTIFICATION: `Veuillez noter que la propriété "Réservé aux administrateurs" est désactivée par défaut pour un enregistrement cloné.`,
  TOOLTIP: `Créer des liens de réservation pour vous rencontrer et/ou rencontrer d'autres utilisateurs`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE_PART_1: `Quel`,
  WHAT_TITLE_PART_2: `est l'objet de cette page de réservation ?`,
  WHO_TITLE_PART_1: `Qui`,
  WHO_TITLE_PART_2: `peut héberger ?`,
  HOW_TITLE_PART_1: `Comment`,
  HOW_TITLE_PART_2: `devrait-elle paraître ?`,
  HOW_DESCRIPTION: `Marque, étiquettes`,
  ADDITIONAL_OPTIONS: `Options supplémentaires`,
  CALENDAR_TITLE_PART_1: `Calendrier`,
  CALENDAR_TITLE_PART_2: `Paramètres`,
  PAGE_TITLE_PART_1: `Page`,
  PAGE_TITLE_PART_2: `Paramètres`,
  BOOKING_TEMPLATE_TITLE: `Plus de paramètres de la page de réservation ?`,
  BOOKING_TEMPLATE_PART_1: `Chaque `,
  BOOKING_TEMPLATE_PART_2: `"Modèle de réservation"`,
  BOOKING_TEMPLATE_PART_3: ` contient les paramètres qui ont un impact sur les pages de réservation associées. Par exemple, chaque "Page de confirmation" peut être unique au modèle de réservation. Par conséquent, ces paramètres se trouvent sur le "Modèle de réservation".`,
  VIEW_BOOKING_PAGE: 'Voir la page de réservation',
  COPY_LINK: 'Copier le lien',
  ADD_TO_WEBSITE: 'Ajouter au site web',
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Enregistrement verrouillé. Ces paramètres proviennent du modèle de réservation intitulé : `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Cliquez ici pour remplacer ces paramètres`,
  MAIN_HOST: `Hôte principal`,
  CO_HOST: `Co-hôte`,
  HOST_NOTIFICATION: `Le principal hôte n'a pas encore de options de localisation connectées. Cette page de réservation ne fonctionnera pas tant qu'il ne le fera pas.`,
  LOCKED_ADMIN_ONLY: `Seul un administrateur peut modifier ces paramètres. Dernière modification par:`,
  LOCKED_NO_PERMISSIONS: `Vous n'êtes pas autorisé à modifier cette page de réservation. Dernière modification par:`,
  BACK_TO_LIST: 'Retour à la liste',
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Nom de la page de réservation`,
  NAME_PLACEHOLDER: `Nom interne pour cette page de réservation`,
  NAME_TOOLTIP: `Ce texte sera affiché aux participants de la réunion sur leur calendrier et dans les e-mails. Par exemple "Réunion de 30min" ou "Examen Dentaire".`,
  ACTIVE: `Active`,
  INACTIVE: `Inactive`,
  BOOKING_TEMPLATE: `Modèle de réservation`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Rechercher et ajouter un modèle de réservation`,
  MEETING_NAME_LABEL: `Nom de la réunion`,
  CUSTOM_NAME_LABEL: `Nom d'affichage`,
  CUSTOM_NAME_PLACEHOLDER: `Nom personnalisé pour cette page de réservation`,
  BOOKING_PAGE: `Page de réservation`,
  ADMIN_ONLY: `Admin uniquement`,
  ADMIN_ONLY_PLACEHOLDER: `Activer pour que seul un administrateur puisse modifier ces paramètres.`,
});

export const BookingPageHowStep = Object.freeze({
  PREVIEW_DESCRIPTION: `Vous pouvez voir l'apparence actuelle de cette page de réservation et remplacer les étiquettes ici.`,
  PREVIEW_BUTTON: `Voir et remplacer les étiquettes`,
  PREVIEW_TITLE: `Remplacer les étiquettes`,
  BRANDING_DESCRIPTION: `Vous pouvez remplacer les paramètres de branding ici.`,
  BRANDING_BUTTON: `Remplacer les paramètres de branding`,
  BRANDING_TITLE: `Remplacer les paramètres de branding`,
});

export const BookingPageBranding = Object.freeze({
  DESCRIPTION: `Par défaut, cette page de réservation utilisera les paramètres de branding définis pour cet espace de travail. Cependant, vous pouvez remplacer les paramètres spécifiques de cette page de réservation ci-dessous.`,
  UPLOAD_LOGO: `Télécharger votre logo`,
  UPLOAD_LOGO_BUTTON: `Télécharger le logo`,
  UPLOAD_BACKGROUND: `Télécharger votre arrière-plan`,
  UPLOAD_BACKGROUND_BUTTON: `Télécharger l'arrière-plan`,
  OPACITY: `Opacité`,
  CSS: `CSS`,
  CSS_PLACEHOLDER: `Vous pouvez ajouter du CSS personnalisé ici pour les remplacements`,
  FOOTER_TEXT: `Texte de pied de page`,
  SAVE: `Enregistrer`,
});

export const BookingPageCalendarStep = Object.freeze({
  TIME_FORMAT: `Format de l'heure`,
  HOUR_12: `12 heures`,
  HOUR_24: `24 heures`,
  CALENDAR_FORMAT: `Format du calendrier`,
  MONTH_VIEW: `Vue mensuelle`,
  WEEK_VIEW: `Vue hebdomadaire`,
  START_TIME_INTERVALS: `Intervalles de début`,
  START_TIME_INTERVALS_DESCRIPTION: `Afficher les créneaux horaires disponibles par incréments de...`,
  START_TIME_INTERVALS_TOOLTIP: `Choisissez la durée des intervalles que vous souhaitez afficher lors de la planification d'un rendez-vous. Par défaut, nous avons sélectionné 15 minutes pour maximiser la disponibilité des créneaux. Cette option affichera les créneaux disponibles par cet intervalle.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Haut de l'intervalle`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Activez «Haut de l'intervalle» si vous exigez que tous les rendez-vous commencent toujours en haut de l'intervalle, quelle que soit la durée du modèle de réservation. Par exemple, les durées de créneau seraient disponibles à 8h00, 9h00, 10h00, etc. REMARQUE: L'activation de cette fonctionnalité ne permettra pas à l'utilisateur de maximiser la disponibilité.`,
  TIME_ZONES: `Fuseaux horaires`,
  TIME_ZONES_TOOLTIP: `Les fuseaux horaires suivants seront disponibles sur la page de réservation accessible au client. Si vous ne choisissez qu'un fuseau horaire, il sera fixe, de sorte que le champ du fuseau horaire sera grisé et l'utilisateur ne pourra pas le changer.`,
  TIME_ZONE_DETECT: `Détecter automatiquement et afficher les créneaux horaires dans le fuseau horaire de l'invité.`,
  TIME_ZONE_DISPLAY: `Afficher uniquement les fuseaux horaires suivants (idéal pour les événements en personne)`,
  DEFAULT_TIME_ZONE: `Fuseau horaire par défaut`,
});

export const BookingPageSettingsStep = Object.freeze({
  INVITE_OTHERS_LABEL: `Autoriser les invités à inviter d'autres personnes`,
  INVITE_OTHERS_TOOLTIP: `En activant cette fonctionnalité, un champ "Inviter d'autres personnes" s'affichera sur la page de réservation, permettant à l'invité d'ajouter d'autres personnes à la liste des participants en saisissant des adresses e-mail séparées par des virgules.`,
  INVITE_OTHERS_SHORT: `Inviter d'autres personnes`,
  ENTER_NOTE_LABEL: `Autoriser les invités à saisir une note pour tous les invités`,
  ENTER_NOTE_TOOLTIP: `En activant cette fonctionnalité, un champ "Note pour les participants" s'affichera, permettant à l'invité de saisir une note à afficher sur le rendez-vous réservé au calendrier pour que tous les participants puissent la voir.`,
  ENTER_NOTE_SHORT: `Saisir une note`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `En bas à gauche`,
  BOTTOM_RIGHT: `En bas à droite`,
  BUTTON_POPUP_TITLE: `Bouton vers Popup`,
  COPY_CODE_EMBED_DESCRIPTION: `Intégrez votre widget SUMO sur votre site Web en collant le code HTML à l'emplacement souhaité.`,
  COPY_CODE_LINK_DESCRIPTION: `Affichez un bouton de réservation flottant sur les pages de votre choix en plaçant ce code dans votre HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Affichez votre lien de réservation n'importe où en plaçant ce code HTML dans vos pages, signatures d'e-mails, et plus encore.`,
  COPY_CODE_TITLE: `Copier le code`,
  CUSTOMIZE_DESCRIPTION: `Personnalisez l'apparence pour s'intégrer parfaitement dans votre site Web.`,
  CUSTOMIZE_TITLE: `Personnaliser`,
  EMBED_DESCRIPTION: `Affichez votre page de réservation SUMO1 dans un iFrame sur votre site Web.`,
  EMBED_TITLE: `Intégrer`,
  FLOATING_BUTTON_DESCRIPTION: `Faire flotter le bouton sur la page`,
  HIDE_PAGE_DETAILS: `Masquer les détails de la page`,
  HIDE_COOKIE_BANNER: `Masquer la bannière des cookies`,
  LABEL: `Étiquette`,
  LINK_DESCRIPTION: `Ajoutez un lien texte sur votre site qui lancera votre page de réservation SUMO1 dans une fenêtre contextuelle.`,
  LINK_TITLE: `Lien texte`,
  LOCATION: `Emplacement`,
  POPUP_DESCRIPTION: `Affichez votre page de réservation SUMO1 dans une fenêtre contextuelle lorsqu'un bouton est cliqué.`,
  POPUP_TITLE: `Fenêtre contextuelle`,
  TITLE: `Comment allez-vous ajouter SUMO à votre site Web?`,
  TOP_LEFT: `En haut à gauche`,
  TOP_RIGHT: `En haut à droite`,
});

export const EditBookingPageWhereStep = Object.freeze({
  HOSTS_PHONE_NOTIFICATION: `L'hôte sélectionné n'a pas de numéro de téléphone`,
  VIDEO_CONFERENCE_NOTIFICATION: `L'hôte sélectionné n'a aucune conférence vidéo`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirmer le remplacement`,
  DESCRIPTION: `Lorsque vous remplacez les paramètres d'un modèle de réservation, vous n'utilisez techniquement plus ce modèle de réservation,
  mais créez plutôt une page de réservation personnalisée, qui nécessite un nom.`,
});
