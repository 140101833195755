import { t } from '../../../i18n/i18n';

export default {
  availableForUse: t('EditTeam:AVAILABLE_FOR_USE'),
  availableForUseTooltip: t('EditTeam:AVAILABLE_FOR_USE_TOOLTIP'),
  cancel: t('Common:CANCEL'),
  createTeam: t('Teams:CREATE_TEAM'),
  defaultWorkspaceTooltip: t('EditTeam:DEFAULT_WORKSPACE_TOOLTIP'),
  editTeam: t('EditTeam:EDIT_TEAM'),
  members: t('Teams:MEMBERS'),
  membersLabel: t('EditTeam:MEMBERS_LABEL'),
  membersPlaceholder: t('EditTeam:MEMBERS_PLACEHOLDER'),
  off: t('Common:OFF'),
  on: t('Common:ON'),
  roundRobinDescPar1: t('EditTeam:ROUND_ROBIN_DESC_PART1'),
  roundRobinDescPar2: t('EditTeam:ROUND_ROBIN_DESC_PART2'),
  save: t('Common:SAVE'),
  teamName: t('EditTeam:TEAM_NAME'),
  teamNameTooltip: t('EditTeam:TEAM_NAME_TOOLTIP'),
  teamNamePlaceholder: t('EditTeam:TEAM_NAME_PLACEHOLDER'),
  workspaces: t('EditTeam:WORKSPACES'),
  workspacesLabel: t('EditTeam:WORKSPACES_LABEL'),
  workspacesPlaceholder: t('EditTeam:WORKSPACES_PLACEHOLDER'),
  duplicateName: t('Common:NAME_IS_DUPLICATE'),
};
