import { t } from '../../../i18n/i18n';

export default {
  activate: t('Common:ACTIVATE'),
  deactivate: t('Common:DEACTIVATE'),
  clone: t('Common:CLONE'),
  copyLink: t('Common:COPY_LINK'),
  copiedLink: t('Common:COPIED_LINK'),
  delete: t('Common:DELETE'),
  edit: t('Common:EDIT'),
  share: t('Common:SHARE'),
  addToSite: t('BookingPages:ADD_TO_SITE'),
  locked: t('Common:LOCKED'),
};
