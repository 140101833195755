import { CodeResponse } from "@react-oauth/google";
import { MicrosoftCodeResponse } from "../../../store/authentification";
import { Button } from "primereact/button";
import labels from "./labels";
import { useSelector } from "react-redux";
import { userSettingsSelectors } from "../../../store/userSettings";

interface ICalendarGroup {
  title: string;
  items: Array<{
    calendarTitle: string;
    calendarDescription: string;
    isCalendarConnected: boolean;
    disconnectCalendar: () => void;
    AuthButton: React.ElementType;
    handleAuthSuccess?: (codeResponse: CodeResponse) => void;
    handleAuthRedirect?: (codeResponse: MicrosoftCodeResponse) => void;
  }>;
  email: string;
}
export const CalendarGroup = ({ title, items, email }: ICalendarGroup) => {
const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
const isMicrosoftCalendarConnected = useSelector(userSettingsSelectors.selectIsMicrosoftCalendarConnected);
const isGoogleCalendarConnected = useSelector(userSettingsSelectors.selectIsGoogleCalendarConnected);

  return (
    <div className="pt-4">
      <div className="surface-100 p-1 pl-2">
        <span className="uppercase text-color-secondary text-sm font-medium">{title}</span>
      </div>
      {items.map((item, index) => (
        <div key={index}>
          <div className="flex flex-column md:flex-row align-items-start md:align-items-center ml-2 mt-3">
            <span className="text-xl md:w-4">{item.calendarTitle}</span>
            <span className="text-sm md:text-lg text-color-secondary flex-grow-1">
              {item.calendarDescription}
            </span>
            {item.isCalendarConnected ? (
              <Button
                className="w-8rem lg:w-11rem font-semibold"
                rounded
                severity="danger"
                label={labels.disconnect}
                disabled={isFetching}
                onClick={item.disconnectCalendar}
              />
            ) : (
              <item.AuthButton
                className={`w-8rem lg:w-11rem font-semibold ${
                  isMicrosoftCalendarConnected || isGoogleCalendarConnected ? 'p-button-outlined p-button-secondary surface-100' : ''
                }`}
                label={labels.connect}
                rounded={true}
                disabled={isFetching || isMicrosoftCalendarConnected || isGoogleCalendarConnected}
                onSuccess={item.handleAuthSuccess}
                onRedirect={item.handleAuthRedirect}
              />
            )}
          </div>
          {item.isCalendarConnected ? (
            <div className="ml-2">
              <div className="hidden md:block font-semibold text-0 w-max py-1 px-4 border-round-3xl bg-green-500">
                {labels.connected}
              </div>
              <p>{email}</p>
            </div>
          ) : (
            <div className="pt-4"></div>
          )}
        </div>
      ))}
    </div>
  );
};
