import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const CLONE_MODAL: ModalName = 'BookingTemplateCloneModal';
export const cloneModalActions = modalsActionsFactory(CLONE_MODAL);
export const cloneModalSelectors = modalsSelectorsFactory(CLONE_MODAL);

export const EDIT_CUSTOM_FIELD_MODAL_NAME: ModalName = 'BookingTemplateEditCustomFieldModal';
export const editCustomFieldModalActions = modalsActionsFactory(EDIT_CUSTOM_FIELD_MODAL_NAME);
export const editCustomFieldModalSelectors = modalsSelectorsFactory(EDIT_CUSTOM_FIELD_MODAL_NAME);

export const DELETE_BOOKING_TEMPLATES_MODAL_NAME: ModalName = 'deleteBookingTemplatesModal';
export const deleteBookingTemplatesModalActions = modalsActionsFactory(DELETE_BOOKING_TEMPLATES_MODAL_NAME);
export const deleteBookingTemplatesModalSelectors = modalsSelectorsFactory(DELETE_BOOKING_TEMPLATES_MODAL_NAME);
