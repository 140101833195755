import { useSelector } from 'react-redux';
import { GlobalError, Preloader } from '../components/common';
import { authentificationActions, authentificationSelectors } from '../store/authentification';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../store/error/actions';
import { ErrorCodes } from '../store/error/types';
import Layout from '../components/private/auth/layout';
import { Outlet, useLocation, useOutlet } from 'react-router-dom';
import { opsConsoleSelectors } from '../store/opsConsole/common/selectors';
import { opsConsoleActions } from '../store/opsConsole/common/actions';
import { UserSettingsKeys } from '../store/userSettings';
import { ViewAsUserInfoModal } from '../components/opsConsole';
import { globalActions, globalSelectors } from '../store/global';
import { errorSelectors } from '../store/error/selectors';
import { useUserNavigation } from '../hooks/useUserNavigation';
import { Path } from './pathes';

export const OPSConsoleRoute = () => {
  const dispatch = useDispatch();
  const outlet = useOutlet();
  const location = useLocation();
  const { isAuthenticated } = useUserNavigation();
  const isFetching = useSelector(authentificationSelectors.selectIsFetching);
  const isSumo1Admin = useSelector(authentificationSelectors.selectIsSumo1Admin);
  const staffUserId = useSelector(authentificationSelectors.selectSumo1AdminUserId);
  const paths = useSelector(opsConsoleSelectors.selectPaths);
  const isUserAuthenticated = useSelector(authentificationSelectors.selectIsUserAuthenticated);
  const isMainDataError = useSelector(globalSelectors.selectIsError); // TODO: reneame or change the logic
  const hasError = useSelector(errorSelectors.selectHasError);
  const searchString = useSelector(opsConsoleSelectors.selectSearchString);
  const startOnes = useRef(false);

  const [isViewAsUserMode, setIsViewAsUserMode] = useState(false);

  useEffect(() => {
    if (searchString) {
      dispatch(opsConsoleActions.setSearchString('')) // set the search of OPS Console as empty if location was changed
    }
  }, [location])

  useEffect(() => {
    const handler = setInterval(() => {
      const userId = localStorage.getItem(UserSettingsKeys.USER_ID);
      if (userId && staffUserId) setIsViewAsUserMode(userId !== staffUserId);
    }, 2000);
    return () => {
      clearInterval(handler);
    };
  }, []);

  useEffect(() => {
    if (paths && !paths.some((name) => location.pathname.startsWith(name))) {
      dispatch(opsConsoleActions.resetFiltering());
    }
  }, [location]);

  useEffect(() => {
    if (!isFetching && !isSumo1Admin) {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, [isFetching]);

  useEffect(() => {
    if (isAuthenticated && !isUserAuthenticated && !isMainDataError && !startOnes.current) {
      startOnes.current = true;
      dispatch(globalActions.getMainDataRequest());
    }
  }, [isAuthenticated, isUserAuthenticated, isMainDataError, startOnes]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authentificationActions.logoutUserRequest(Path.Landing));
    }
  }, [isAuthenticated]);

  if (hasError) return <GlobalError />;

  return isFetching ? (
    <Preloader />
  ) : (
    <Layout>
      <ViewAsUserInfoModal visible={isViewAsUserMode} />
      <Outlet />
      {!outlet && <div> TODO Dashboard</div>}
    </Layout>
  );
};
