import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { ThirdPartyType, UserDataCoreType } from './typings';
import dayjs from 'dayjs';
import { t } from '../../i18n/i18n';
import { LicenseType, StaffRoles, TenantStatus } from '../../API';
import { EXPIRATION_PERIOD_DAYS, USER_WARNING_DAYS } from './constants';

const authentificationState = (state: State) => state.authentification;

const selectIsFetching = createSelector(authentificationState, (authentification) => authentification.isFetching);

const selectLicensesIsFetching = createSelector(authentificationState, (authentification) => authentification.licensesIsFetching);

const selectUserDataCore = createSelector(authentificationState, (authentification) => authentification.userDataCore);

const selectUserId = createSelector(selectUserDataCore, (userDataCore: UserDataCoreType) => userDataCore.userId);

const selectTenantId = createSelector(selectUserDataCore, (userDataCore: UserDataCoreType) => userDataCore.tenantId);

const selectLink = createSelector(selectUserDataCore, (userDataCore: UserDataCoreType) => userDataCore.link);

const selectWorkspace = createSelector(selectUserDataCore, (userDataCore: UserDataCoreType) => userDataCore.workspaceId);


const selectIsUserAuthenticated = createSelector(
  authentificationState,
  (authentification) => authentification.isAuthenticated
);
const selectIsUserRegistered = createSelector(
  authentificationState,
  (authentification) => authentification.userRegistered
);
const selectUserWasDeleted = createSelector(authentificationState, (authentification) => authentification.userDeleted);
const selectError = createSelector(authentificationState, (authentification) => authentification.error);
const selectThirdParty = createSelector(authentificationState, (authentification) => authentification.thirdParty);
const selectIsThirdPartyGoogle = createSelector(selectThirdParty, (thirdParty) => thirdParty === ThirdPartyType.GOOGLE);
const selectIsThirdPartyMicrosoft = createSelector(
  selectThirdParty,
  (thirdParty) => thirdParty === ThirdPartyType.MICROSOFT
);

const selectUserAccounts = createSelector(authentificationState, (authentification) => authentification.userAccounts);

const selectInviteToAccount = createSelector(
  authentificationState,
  (authentification) => authentification.inviteToAccount
);

const selectIsLastSuperAdmin = createSelector(
  authentificationState,
  (authentification) => authentification.isLastSuperAdmin
);

// TODO support multiple licenses if needed

const selectLicenses = createSelector(authentificationState, (authentification) => authentification.licenses);
const selectCurrentLicense = createSelector(selectLicenses, (licenses) => licenses[0]);
const selectAssigned = createSelector(selectCurrentLicense, (license) => license?.assigned || 1);
const selectScheduledChanges = createSelector(selectCurrentLicense, (license) => license?.scheduledChanges);
const selectScheduledRemoveSeats = createSelector(
  selectScheduledChanges,
  (changes) => changes?.reduce((result, change) => result + (change?.seats || 0), 0) || 0
);
const selectRemainingLicenses = createSelector(
  selectCurrentLicense,
  selectScheduledRemoveSeats,
  (license, removeSeats) => (license ? (license.owned || 0) - (license.assigned || 0) - removeSeats : 0)
);

const selectLicenseType = createSelector(selectCurrentLicense, (licenseData) => licenseData?.type);
const selectLicenseEndDate = createSelector(selectCurrentLicense, (licenseData) => licenseData?.endDate);
const selectIsTrialLicense = createSelector(selectLicenseType, (type) => type === LicenseType.TRIAL);
const selectIsPaidLicense = createSelector(selectLicenseType, (type) => type !== LicenseType.TRIAL);

const selectTrialExpirationMessage = createSelector(selectIsTrialLicense, selectLicenseEndDate, (isTrial, endDate) =>
  isTrial && endDate
    ? `${t('License:TRIAL_EXPIRES_MESSAGE')} ${dayjs(endDate).diff(dayjs(), 'day')} ${t('Common:DAYS')}`
    : ''
);
const selectIsPaymentLate = createSelector(selectLicenseEndDate, (endDate) =>
  endDate ? dayjs().isAfter(dayjs(endDate)) : false
);
const selectPaidExpirationMessage = createSelector(
  selectIsPaidLicense,
  selectIsPaymentLate,
  selectLicenseEndDate,
  (isPaid, isPaymentLate, endDate) => {
    if (!isPaid || !isPaymentLate || !endDate) {
      return '';
    }
    const adjustedEndDate = dayjs(endDate).add(EXPIRATION_PERIOD_DAYS, 'day');
    const daysDifference = adjustedEndDate.diff(dayjs(), 'day');
    
    if (daysDifference === 0) {
      return `${t('License:PAID_EXPIRES_MESSAGE_PART_1')} ${t('License:PAID_EXPIRES_MESSAGE_PART_2_TODAY')}`;
    }

    const messagePart2Key = daysDifference > 0
      ? 'License:PAID_EXPIRES_MESSAGE_PART_2_LEFT'
      : 'License:PAID_EXPIRES_MESSAGE_PART_2_AGO';

    return `${t('License:PAID_EXPIRES_MESSAGE_PART_1')} ${Math.abs(daysDifference)} ${t(messagePart2Key)}`;
  }
);
const selectShowExpirationUserMessage = createSelector(
  selectIsPaidLicense,
  selectIsPaymentLate,
  selectLicenseEndDate,
  (isPaid, isPaymentLate, endDate) =>
    isPaid &&
    isPaymentLate &&
    endDate &&
    dayjs().isAfter(dayjs(endDate).add(EXPIRATION_PERIOD_DAYS - USER_WARNING_DAYS, 'day'), 'day')
);
const selectSumo1AdminData = createSelector(authentificationState, (authentification) => authentification.Sumo1Admin);
const selectIsSumo1Admin = createSelector(selectSumo1AdminData, (data) => !!data.email);
const selectSumo1AdminUserId = createSelector(selectSumo1AdminData, (data) => data.userId);
const selectSumo1AdminRole = createSelector(selectSumo1AdminData, (data) => data.roleName);
const selectIsMainAdmin = createSelector(selectSumo1AdminRole, (role) => role === StaffRoles.MAIN_ADMIN);
const selectIsOperations = createSelector(selectSumo1AdminRole, (role) => role === StaffRoles.OPERATIONS);
const selectIsMainAdminOrOperations = createSelector(
  selectIsMainAdmin,
  selectIsOperations,
  (isMainAdmin, isOperations) => isMainAdmin || isOperations
);
const selectIsSupport = createSelector(selectSumo1AdminRole, (role) => role === StaffRoles.SUPPORT);
const selectIsMainAdminOrSupport = createSelector(selectIsMainAdmin, selectIsSupport, (isMainAdmin, isSupport) => isMainAdmin || isSupport);
const selectStaffEmail = createSelector(selectSumo1AdminData, (staff) => staff.email);
const selectStaffName = createSelector(selectSumo1AdminData, (staff) => staff.userName);
const selectStaffNameOrEmail = createSelector(selectStaffEmail, selectStaffName, (email, name) => name || email);

const selectIsViewAsUser = createSelector(
  selectSumo1AdminUserId,
  selectUserId,
  (sumo1UserId, userId) => sumo1UserId && userId && sumo1UserId !== userId
);
const selectIsOPSConsole = createSelector(
  () =>
    window.location.pathname.startsWith('/opsconsole') &&
    !window.location.pathname.startsWith('/opsconsole/view-as-user')
);

const selectTenant = createSelector(authentificationState, (authentification) => authentification.tenant);
const selectTenantName = createSelector(selectTenant, (tenant) => tenant.name);
const selectTenantStatus = createSelector(selectTenant, (tenant) => tenant.status);
const selectIsTenantExpired = createSelector(selectTenantStatus, (status) => status === TenantStatus.EXPIRED);
const selectIsLicenseActive = createSelector(
  selectIsTrialLicense,
  selectLicenseEndDate,
  selectIsTenantExpired,
  (isTrial, endDate, isTenantExpired) => isTrial ? new Date() < new Date(endDate || 0) : !isTenantExpired
);

const selectTenantFileFolderPath = createSelector(selectTenantId, (tenantId) => tenantId ? tenantId + '/' : '');

const selectIsCookieOpen = createSelector(authentificationState, (authentification) => authentification.isCookieOpen);

export const authentificationSelectors = {
  selectIsFetching,
  selectLicensesIsFetching,
  selectError,
  selectUserId,
  selectTenantId,
  selectTenantName,
  selectLink,
  selectWorkspace,
  selectIsUserAuthenticated,
  selectIsUserRegistered,
  selectUserWasDeleted,
  selectThirdParty,
  selectIsThirdPartyGoogle,
  selectIsThirdPartyMicrosoft,
  selectLicenseData: selectCurrentLicense,
  selectUserAccounts,
  selectInviteToAccount,
  selectIsLastSuperAdmin,
  selectIsLicenseActive,
  selectLicenseEndDate,
  selectIsTrialLicense,
  selectTrialExpirationMessage,
  selectPaidExpirationMessage,
  selectShowExpirationUserMessage,
  selectIsPaidLicense,
  selectIsSumo1Admin,
  selectSumo1AdminRole,
  selectIsMainAdmin,
  selectIsOperations,
  selectIsMainAdminOrOperations,
  selectIsSupport,
  selectIsMainAdminOrSupport,
  selectStaffNameOrEmail,
  selectIsTenantExpired,
  selectSumo1AdminUserId,
  selectIsOPSConsole,
  selectIsViewAsUser,
  selectLicenses,
  selectCurrentLicense,
  selectAssigned,
  selectRemainingLicenses,
  selectLicenseType,
  selectTenantFileFolderPath,
  selectIsCookieOpen,
};
