import { useDispatch, useSelector } from 'react-redux';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { Label } from '../../common';

export const PublicBookingPageBookedMessage = () => {
  const dispatch = useDispatch();
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const mainTextStyle = useSelector(eventSelectors.selectMainTextStyle);
  const bookTitle = useSelector(eventSelectors.selectBookTitle);
  const bookDescription = useSelector(eventSelectors.selectBookDescription);
  const rescheduleTitle = useSelector(eventSelectors.selectRescheduleTitle);
  const rescheduleDescription = useSelector(eventSelectors.selectRescheduleDescription);
  const cancelTitle = useSelector(eventSelectors.selectCancelTitle);
  const cancelDescription = useSelector(eventSelectors.selectCancelDescription);
  const showBooked = useSelector(eventSelectors.selectShowBookedMessage);
  const showRescheduled = useSelector(eventSelectors.selectShowRescheduledMessage);
  const showCanceled = useSelector(eventSelectors.selectShowCanceledMessage);

  const handleBookTitleChange = (bookTitle: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ bookTitle }));
  };

  const handleBookDescriptionChange = (bookDescription: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ bookDescription }));
  };

  const handleRescheduleTitleChange = (rescheduleTitle: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ rescheduleTitle }));
  };

  const handleRescheduleDescriptionChange = (rescheduleDescription: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ rescheduleDescription }));
  };

  const handleCancelTitleChange = (cancelTitle: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ cancelTitle }));
  };

  const handleCancelDescriptionChange = (cancelDescription: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ cancelDescription }));
  };

  const renderMainTextLabel = (value: string, onChange: (value: string) => void, textClassName?: string) => (
    <Label
      editClassName="hover:text-primary"
      textClassName={textClassName}
      textStyle={mainTextStyle}
      editable={isEditable}
      value={value}
      onBlurUpdate={onChange}
    />
  );

  return <>
    {showBooked && (
      <div className="card mt-2 mb-3 flex align-items-center bg-green-100 border-1 surface-border shadow-none">
        <i className="pi pi-check-circle text-green-500 mr-4 text-5xl"></i>
        <div className="flex-1">
          <div>{renderMainTextLabel(bookTitle, handleBookTitleChange, 'text-xl font-semibold')}</div>
          <div>{renderMainTextLabel(bookDescription, handleBookDescriptionChange)}</div>
        </div>
      </div>
    )}
    {showRescheduled && (
      <div className="card mt-2 mb-3 flex align-items-center bg-green-100 border-1 surface-border shadow-none">
        <i className="pi pi-check-circle text-green-500 mr-4 text-5xl"></i>
        <div className="flex-1">
          <div>{renderMainTextLabel(rescheduleTitle, handleRescheduleTitleChange, 'text-xl font-semibold')}</div>
          <div>{renderMainTextLabel(rescheduleDescription, handleRescheduleDescriptionChange)}</div>
        </div>
      </div>
    )}
    {showCanceled && (
    <div className="card mt-2 mb-3 flex align-items-center bg-red-100 border-1 surface-border shadow-none">
      <i className="pi pi-ban text-red-500 mr-4 text-5xl"></i>
      <div className="flex-1">
        <div>{renderMainTextLabel(cancelTitle, handleCancelTitleChange, 'text-xl font-semibold')}</div>
        <div>{renderMainTextLabel(cancelDescription, handleCancelDescriptionChange)}</div>
      </div>
    </div>
    )}
  </>
};
