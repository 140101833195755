import { createSelector } from 'reselect';
import { checkTimeWrongOrder, checkTimeOverlapping } from '../../services/DateService';
import { State } from '../rootStore';
import { AvailabilityOptions } from './types';
import { DEFAULT_AVAILABILITY } from './constants';

const userAvailabilityState = (state: State) => state.userAvailability;

// TODO: start hadling availability.error
const selectIsFetching = createSelector(userAvailabilityState, (availability) => availability.isFetching);

const selectUserAvailabilities = createSelector(
  userAvailabilityState,
  (availability) => availability.userAvailabilities
);
const selectUserAvailabilitiesData = createSelector(selectUserAvailabilities, (availabilities) =>
  availabilities.map((availability) => availability?.availabilityData)
);
const selectUserAvailabilitiesNames = createSelector(selectUserAvailabilities, (availabilities) =>
  availabilities.map(({ availabilityData }) => availabilityData?.name)
);
const selectUserAvailabilitiesOptions = createSelector(
  selectUserAvailabilities,
  (availabilities) =>
    availabilities.length ? [...availabilities] // Create a shallow copy of the array
      .sort((a, b) => {
        // Sorting that isDefault item should be the first
        if (a.availabilityData?.isDefault) {
          return -1;
        }
        if (b.availabilityData?.isDefault) {
          return 1;
        }
        const aCreatedAt = a.createdAt ? a.createdAt : '';
        const bCreatedAt = b.createdAt ? b.createdAt : '';
        return aCreatedAt <= bCreatedAt ? -1 : 1;
      })
      .map(({ availabilityData, link }) => ({
        label: availabilityData?.name,
        value: link,
        isDefault: availabilityData?.isDefault,
      })) : [] as AvailabilityOptions
);

const selectUserAvailability = createSelector(userAvailabilityState, (availability) => availability.userAvailability);

// selectedAvailability before changes
const selectInitUserAvailability = createSelector(selectUserAvailability, selectUserAvailabilities, 
  (selectedAvailability, availabilities) => {
    if (selectedAvailability !== DEFAULT_AVAILABILITY) {
      return availabilities.find((availability) => availability?.availabilityData?.name === selectedAvailability.availabilityData?.name) || selectedAvailability;
    } else {
      return DEFAULT_AVAILABILITY
    }    
  }
);

const selectUserAvailabilityLink = createSelector(selectUserAvailability, (availability) => availability.link);
const selectUserAvailabilityData = createSelector(
  selectUserAvailability,
  (availability) => availability.availabilityData
);
const selectActiveBookingPages = createSelector(
  selectUserAvailabilityData,
  (availabilityData) => availabilityData?.activeBookingPages
);
const selectIsDefault = createSelector(selectUserAvailabilityData, (availabilityData) => availabilityData?.isDefault);
const selectName = createSelector(selectUserAvailabilityData, (availabilityData) => availabilityData?.name);
const selectWeeklyHours = createSelector(
  selectUserAvailabilityData,
  (availabilityData) => availabilityData?.weeklyHours || []
);
const selectOverrides = createSelector(
  selectUserAvailabilityData,
  (availabilityData) => availabilityData?.overrides || []
);
const selectSortedOverrides = createSelector(selectOverrides, (overrides) =>
  overrides
    .filter((date) => date?.days?.to && date?.days?.to >= new Date().toISOString())
    .sort((a, b) => {
      if (!a?.days?.from || !b?.days?.from) {
        return 0;
      }
      return a.days.from > b.days.from ? 1 : -1;
    })
);

const selectCloneName = createSelector(userAvailabilityState, (availability) => availability.cloneName);

const selectIsNameDuplicate = createSelector(
  selectUserAvailabilitiesData,
  selectUserAvailabilityData,
  (availabilities, availability) =>
    Boolean(availabilities?.find((item) => item?.id !== availability?.id && item?.name === availability?.name))
);
const selectIsNameValid = createSelector(
  selectName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectIsCloneNameDuplicate = createSelector(selectUserAvailabilitiesNames, selectCloneName, (names, cloneName) =>
  Boolean(names?.find((name) => name === cloneName))
);
const selectIsCloneNameValid = createSelector(
  selectCloneName,
  selectIsCloneNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectIsWeeklyHoursValid = createSelector(selectWeeklyHours, (weeklyHours) =>
  weeklyHours.every((day) => !day?.time || (!checkTimeOverlapping(day?.time) && !checkTimeWrongOrder(day?.time)))
);

const selectDefaultAvailability = createSelector(selectUserAvailabilities, (availabilities) =>
  availabilities.find(({ availabilityData }) => availabilityData?.isDefault)
);
const selectDefaultAvailabilityWeeklyHours = createSelector(
  selectDefaultAvailability,
  (availability) => availability?.availabilityData?.weeklyHours
);

const selectAvailabilityByLink = (link?: string) =>
  createSelector(selectUserAvailabilities, (availabilities) =>
    availabilities.find((availability) => availability.link === link)
  );

const selectOldestAvailability = (exceptLink?: string) =>
  createSelector(selectUserAvailabilities, (availabilities) => {
    const sortedAvailabilities = (
      exceptLink ? [...availabilities].filter((availability) => availability.link !== exceptLink) : [...availabilities]
    ).sort((a, b) => {
      const aCreatedAt = a.createdAt ? a.createdAt : '';
      const bCreatedAt = b.createdAt ? b.createdAt : '';
      return aCreatedAt <= bCreatedAt ? -1 : 1;
    });
    return sortedAvailabilities[0];
  });

export const userAvailabilitySelectors = {
  selectIsFetching,
  selectUserAvailabilities,
  selectUserAvailabilitiesNames,
  selectUserAvailabilitiesOptions,
  selectDefaultAvailability,
  selectUserAvailability,
  selectUserAvailabilityLink,
  selectInitUserAvailability,
  selectActiveBookingPages,
  selectIsDefault,
  selectName,
  selectSortedOverrides,
  selectWeeklyHours,
  selectCloneName,
  selectIsNameDuplicate,
  selectIsNameValid,
  selectIsCloneNameDuplicate,
  selectIsCloneNameValid,
  selectIsWeeklyHoursValid,
  selectDefaultAvailabilityWeeklyHours,
  selectAvailabilityByLink,
  selectOldestAvailability,
};
