import { Path } from '../../../routing';
import AppSubMenu from './AppSubMenu';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { t } from '../../../i18n/i18n';
import { authentificationSelectors } from '../../../store/authentification';
import { LayoutContext } from '../context/layoutcontext';
import { useContext } from 'react';

const AppMenu = () => {
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isUsersRead = useSelector(userSettingsSelectors.selectUsersRead);
  const isBookingTemplatesRead = useSelector(userSettingsSelectors.selectBookingTemplatesRead);
  const isWorkspacesRead = useSelector(userSettingsSelectors.selectWorkspacesRead);
  const isTeamsRead = useSelector(userSettingsSelectors.selectTeamsRead);
  const isRoleLoaded = useSelector(userSettingsSelectors.selectIsRoleLoaded);
  const isPaidLicense = useSelector(authentificationSelectors.selectIsPaidLicense);
  const isViewAsUserMode = useSelector(authentificationSelectors.selectIsViewAsUser);
  const isSumoONEAdmin = useSelector(authentificationSelectors.selectIsSumo1Admin);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);
  const { layoutConfig } = useContext(LayoutContext);

  const createModal = () => {
    if (!isLicenseActive) {
      return licenseExpiredModel;
    } else if (layoutConfig.colorScheme === 'dim') {
      return OPSConsoleModel;
    } else {
      return model;
    }
  };

  const licenseExpiredModel = [
    ...!isLicenseActive ? [
      {
        label: t('AppMenu:ADMIN_TOOLS_TITLE'),
        items: [
          {
            label: t('Common:USERS'),
            icon: 'pi pi-fw pi-user',
            to: Path.Users,
            visible: isUsersRead,
          },
        ],
      },
    ]: [],
    ...( isSuperAdmin && !isLicenseActive
      ? [
          { separator: true },
          {
            label: t('AppMenu:ADMIN_SETTINGS_TITLE'),
            items: [
              {
                label: t('Billing:TITLE'),
                icon: 'pi pi-fw pi-dollar',
                to: Path.Billing,
                // visible: isPaidLicense && isSuperAdmin,
              },
            ],
          },
        ]
      : []),
  ]

  const model = [
    ...(isSumoONEAdmin && !isViewAsUserMode
      ? [
          {
            label: t('Common:INTERNAL'),
            items: [
              {
                label: t('AppMenu:OPEN_OPS_CONSOLE'),
                icon: 'pi pi-fw pi-wrench',
                to: Path.OPSConsoleOrgs,
              },
            ],
          },
          { separator: true },
        ]
      : []),
    {
      label: t('AppMenu:BOOKING_TOOLS_TITLE'),
      // icon: 'pi pi-home',
      items: [
        // {
        //   label: t('AppMenu:SUMO1_FOR_GMAIL'),
        //   icon: 'pi pi-fw pi-google',
        //   to: '',
        // },
        {
          label: t('BookingPages:TITLE'),
          icon: 'pi pi-fw pi-desktop',
          to: Path.BookingPages,
        },
        {
          label: t('BookingTemplates:TITLE'),
          icon: 'pi pi-fw pi-th-large',
          to: Path.BookingTemplates,
          visible: isBookingTemplatesRead,
        },
        {
          label: t('ScheduledMeetings:TITLE'),
          icon: 'pi pi-fw pi-calendar',
          to: Path.BookedMeetings,
        },
      ],
    },
    { separator: true },
    {
      label: t('AppMenu:MORE_TOOLS_TITLE'),
      items: [
        {
          label: t('IntegrationPage:TITLE'),
          icon: 'pi pi-fw pi-link',
          to: Path.Integration,
        },
      ],
    },
    ...(isRoleLoaded
      ? [
          ...(isUsersRead || isTeamsRead
            ? [
                { separator: true },
                {
                  label: t('AppMenu:ADMIN_TOOLS_TITLE'),
                  items: [
                    {
                      label: t('Common:USERS'),
                      icon: 'pi pi-fw pi-user',
                      to: Path.Users,
                      visible: isUsersRead,
                    },
                    {
                      label: t('Common:ROLES'),
                      icon: 'pi pi-fw pi-lock',
                      to: Path.Roles,
                      visible: isSuperAdmin,
                    },
                    {
                      label: t('Teams:TITLE'),
                      icon: 'pi pi-fw pi-users',
                      to: Path.Teams,
                      visible: isTeamsRead,
                    },
                    {
                      label: t('Workspaces:TITLE'),
                      icon: 'pi pi-fw pi-globe',
                      to: Path.Workspaces,
                      visible: isWorkspacesRead,
                    },
                  ],
                },
              ]
            : []),

          ...(isPaidLicense && isSuperAdmin
            ? [
                { separator: true },
                {
                  label: t('AppMenu:ADMIN_SETTINGS_TITLE'),
                  items: [
                    {
                      label: t('Billing:TITLE'),
                      icon: 'pi pi-fw pi-dollar',
                      to: Path.Billing,
                      // visible: isPaidLicense && isSuperAdmin,
                    },
                  ],
                },
              ]
            : []),
        ]
      : []),
  ];

  const OPSConsoleModel = [
    {
      label: t('Common:INTERNAL'),
      items: [
        {
          label: t('AppMenu:OPEN_MY_SUMO1'),
          icon: 'pi pi-fw pi-calendar-plus',
          to: isQuickSetupFinished ? Path.BookingPages : Path.QuickSetup,
        },
      ],
    },
    { separator: true },
    {
      label: t('AppMenu:SUPPORT_TITLE'),
      items: [
        // {
        //   label: t('AppMenu:DASHBOARD_TITLE'),
        //   icon: 'pi pi-fw pi-desktop',
        //   to: Path.OPSConsole,
        // },
        {
          label: t('AppMenu:ACCOUNTS_TITLE'),
          icon: 'pi pi-fw pi-building',
          to: Path.OPSConsoleAccounts,
        },
        {
          label: t('AppMenu:ORGS_TITLE'),
          icon: 'pi pi-fw pi-cloud',
          to: Path.OPSConsoleOrgs,
        },
        {
          label: t('Common:USERS'),
          icon: 'pi pi-fw pi-user',
          to: Path.OPSConsoleUsers,
        },
      ],
    },
    { separator: true },
    {
      label: t('AppMenu:OPERATIONS_TITLE'),
      items: [
        // {
        //   label: t('AppMenu:BILLING_TITLE'),
        //   icon: 'pi pi-fw pi-dollar',
        //   to: Path.OPSConsoleBilling,
        // },
        {
          label: t('AppMenu:STAFF_TITLE'),
          icon: 'pi pi-fw pi-users',
          to: Path.OPSConsoleStaff,
        },
      ],
    },
  ];

  return <AppSubMenu model={createModal()} />;
};

export default AppMenu;
