import { ConfirmationModal, Preloader, Search, SectionTitle } from '../../components/common';
import labels from './labels';
import { Button } from 'primereact/button';
import { MAX_LENGTH_NAME } from '../../types/constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { rolesActions, rolesSelectors } from '../../store/roles';
import { userSettingsSelectors } from '../../store/userSettings';
import { formatDateUTC } from '../../services/DateService';
import { EditRoleModal } from '../../components/roles';
import { editRoleModalActions } from '../../store/roles/modal';
import { DEFAULT_ROLE_DATA } from '../../store/roles/constants';
import { Role } from '../../API';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';

export const Roles = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');
  const isFetching = useSelector(rolesSelectors.selectIsFetching);
  const roles = useSelector(rolesSelectors.selectSearchedRoles(searchString));
  const selectedRole = useSelector(rolesSelectors.selectSelectedRole);
  const superAdminId = useSelector(rolesSelectors.selectSuperAdminId);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const currentUserRoleId = useSelector(userSettingsSelectors.selectUserRoleId);
  const menu = useRef<Menu | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(rolesActions.getRolesRequest());
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  const menuItems = [
    {
      label: labels.edit,
      icon: 'pi pi-pencil',
      command: () => {
        dispatch(editRoleModalActions.openModal());
      },
      visible: selectedRole.isCustom,
    },
    {
      label: selectedRole.isActive ? labels.deactivate : labels.activate,
      icon: selectedRole.isActive ? 'pi pi-ban' : 'pi pi-check-circle',
      command: () => {
        if (selectedRole.isActive) {
          setIsDelete(false);
          setIsModalOpen(true);
        } else {
          dispatch(rolesActions.deactivateRoleRequest());
        }
      },
      visible: selectedRole.id !== superAdminId && selectedRole.id !== currentUserRoleId,
    },
    {
      label: labels.clone,
      icon: 'pi pi-clone',
      command: () => {
        dispatch(rolesActions.cloneSelectedRole());
        dispatch(editRoleModalActions.openModal());
      },
    },
    {
      label: labels.delete,
      icon: 'pi pi-trash',
      command: () => {
        setIsDelete(true);
        setIsModalOpen(true);
      },
      visible: selectedRole.isCustom && selectedRole.id !== currentUserRoleId,
    },
  ];

  const handleDelete = () => {
    dispatch(rolesActions.deleteRoleRequest());
    setIsModalOpen(false);
  };

  const handleInactivate = () => {
    dispatch(rolesActions.deactivateRoleRequest());
    setIsModalOpen(false);
  };

  const addRole = () => {
    dispatch(rolesActions.setSelectedRole(DEFAULT_ROLE_DATA));
    dispatch(editRoleModalActions.openModal());
  };

  const renderActions = (data: Role) => {
    return (
      <>
        <Button
          icon="pi pi-ellipsis-h text-color"
          text
          onClick={(e) => {
            dispatch(rolesActions.setSelectedRole(data));
            return menu.current?.toggle && menu.current.toggle(e);
          }}
        />
        <Menu model={menuItems} popup ref={menu} />
      </>
    );
  };

  const handleSearchChange = (value: string) => {
    setSearchString(value.toLowerCase());
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between sumo-header-bg">
        <SectionTitle id="rolesTitle" icon="pi-lock" title={labels.title} />
      </div>

      <div className="card">
        <div className="grid mb-4">
          <div className="p-inputgroup col-12 md:col-6">
            <Search
              placeholder={labels.search}
              value={searchString}
              onChange={handleSearchChange}
              maxTextLength={MAX_LENGTH_NAME}
            />
          </div>
          <div className="col-12 md:col-6 flex align-items-start md:justify-content-end">
            <Button label={labels.newRole} onClick={addRole} />
          </div>
        </div>

        <DataTable
          value={roles.sort((roleA, roleB) => (roleA.createdAt > roleB.createdAt ? 1 : -1))}
          paginator={roles.length > 10}
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          size="small"
          showGridlines
          removableSort
          resizableColumns
          emptyMessage={labels.noResults}
        >
          <Column field="name" header={labels.role} sortable bodyClassName="text-overflow-ellipsis" />
          <Column
            field="isCustom"
            header={labels.custom}
            sortable
            bodyClassName="text-overflow-ellipsis"
            body={(data: Role) => (data.isCustom ? labels.yes : labels.no)}
          />
          <Column
            field="isActive"
            header={labels.active}
            sortable
            bodyClassName="text-overflow-ellipsis"
            body={(data: Role) => (data.isActive ? labels.yes : labels.no)}
          />
          <Column
            field="updatedAt"
            header={labels.lastModified}
            sortable
            bodyClassName="text-overflow-ellipsis"
            body={(data: Role) => formatDateUTC(data.updatedAt, dateFormat)}
          />
          <Column
            field="updatedBy"
            header={labels.lastModifiedBy}
            sortable
            bodyClassName="text-overflow-ellipsis"
            frozen
          />
          <Column body={(data) => renderActions(data)} className="w-4rem" align="center" frozen />
        </DataTable>
      </div>
      <EditRoleModal />
      <ConfirmationModal
        visible={isModalOpen}
        title={isDelete ? labels.delete : labels.deactivate}
        description={`${isDelete ? labels.deleteDesc : labels.deactivateDesc} "${
          selectedRole.name
        }" ${labels.role.toLowerCase()}?`}
        additionalText={isDelete ? [labels.deleteMessage] : undefined}
        confirmButtonLabel={labels.yes}
        cancelButtonLabel={labels.no}
        onConfirm={isDelete ? handleDelete : handleInactivate}
        onCancel={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
