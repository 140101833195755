import { memo, useEffect, useState } from 'react';
import { CanceledType, CreateUserEventInput, CustomFieldInput, CustomFieldType, LocationType } from '../../../API';
import { useDispatch } from 'react-redux';
import { formatDateHHMM } from '../../../services/DateService';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import {
  cancelReasons,
  scheduledMeetingsActions,
  locationIcon,
  locationLabels,
  cancelEventModalActions,
  editMeetingNotesModalActions,
  rescheduleEventModalActions,
  viewAttendeesModalActions,
  noShowEventModalActions,
} from '../../../store/scheduledMeetings';
import labels from './labels';
import { TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { authentificationSelectors } from '../../../store/authentification';
import { bookingPageSelectors } from '../../../store/bookingPages';

interface IScheduledMeeting {
  event: CreateUserEventInput;
}

export const ScheduledMeeting = memo(({ event }: IScheduledMeeting) => {
  const { canceled } = event;
  const dispatch = useDispatch();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const bookingPageData = useSelector(bookingPageSelectors.selectBookingPageById(event.bookingPageId));
  const currentUserId = useSelector(authentificationSelectors.selectUserId);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const canceledReason = (cancelReasons as { [key: string]: string })[canceled?.type || CanceledType.OTHER];

  const [isPastEvent, setIsPastEvent] = useState(new Date(event.startTime) <= new Date());
  const [isAnswersOpen, setIsAnswersOpen] = useState(false);

  const generateNamesString = (names: (string | null)[] | null | undefined) =>
    names?.length
      ? names.length > 2
        ? ', ' + names[0] + ', ' + names[1] + ' ... (+' + (names.length - 2) + ')'
        : ', ' + names.join(', ')
      : '';

  useEffect(() => {
    setIsPastEvent(new Date(event.startTime) <= new Date());
  }, [event.startTime]);

  const getLocationInfo = () => {
    if (event.location) {
      const className = (locationIcon as { [key: string]: string })[event.location?.type];
      const label = (locationLabels as { [key: string]: string })[event.location?.type];
      return (
        <>
          <span className={`mr-2 text-lg ${className}`} />
          {label}
        </>
      );
    }
    return <>{labels.noLocationGiven}</>;
  };

  const openRescheduleEventModal = () => {
    dispatch(scheduledMeetingsActions.selectScheduledMeeting(event));
    dispatch(rescheduleEventModalActions.openModal());
  };

  const openViewAttendeesModal = () => {
    dispatch(scheduledMeetingsActions.selectScheduledMeeting(event));
    dispatch(viewAttendeesModalActions.openModal());
  };

  const openEditMeetingNotesModal = () => {
    dispatch(scheduledMeetingsActions.selectScheduledMeeting(event));
    dispatch(editMeetingNotesModalActions.openModal());
  };

  const openCancelEventModal = () => {
    dispatch(scheduledMeetingsActions.selectScheduledMeeting(event));
    dispatch(scheduledMeetingsActions.updateCancelReason(CanceledType.UNEXPECTED));
    dispatch(cancelEventModalActions.openModal());
  };

  const openNoShowEventModal = () => {
    dispatch(scheduledMeetingsActions.selectScheduledMeeting(event));
    dispatch(noShowEventModalActions.openModal());
  };

  const undoNoShow = () => {
    dispatch(
      scheduledMeetingsActions.updateScheduledMeetingRequest({
        ...event,
        isNoShow: false,
      })
    );
  };

  const isCustomFieldVisible = (field: CustomFieldInput | null): boolean => {
    if (!field?.enabled) {
      return false;
    }
    switch (field.fieldType) {
      case CustomFieldType.NAME:
      case CustomFieldType.EMAIL:
      case CustomFieldType.CUSTOM:
        return true;
      case CustomFieldType.PHONE:
        return event.location?.type === LocationType.PHONE_CALL || Boolean(field.value?.split('-')[1]);
      case CustomFieldType.LOCATION:
        return event.location?.type === LocationType.IN_PERSONAL;
      default:
        return false;
    }
  };

  return (
    <div
      className={`mb-3 border-1 border-round surface-border cursor-pointer ${
        canceled?.isCanceled ? 'bg-gray-200' : 'hover:bg-gray-50'
      }`}
      onClick={() => setIsDetailsOpen((prev) => !prev)}
    >
      <div
        className="p-3 border-round"
        style={{
          borderLeft: `6px solid ${bookingPageData?.what?.color || 'var(--primary-color)'}`,
          margin: '-1px',
        }}
      >
        <div className="grid align-items-start -mb-2">
          <div className="col-12 md:col-4">
            <div>{formatDateHHMM(event.startTime, timeFormat) + ' - ' + formatDateHHMM(event.endTime, timeFormat)}</div>
            <div className="flex align-items-center mt-2">{getLocationInfo()}</div>
          </div>
          {canceled?.isCanceled && (
            <div className="relative">
              <div className="absolute" style={{ top: '12px', right: '-8px' }}>
                <i className="pi pi-ban text-red-400 mr-4 text-5xl"></i>
              </div>
            </div>
          )}
          <div className="col-12 md:col">
            <div>
              <span>{labels.bookingPage}: </span>
              <Link
                className="text-color hover:underline hover:text-primary font-bold"
                target="_blank"
                to={Path.EditBookingPage.replace(':bookingPageId', event.bookingPageId || '')}
              >
                {event.bookingPageName}
              </Link>
              {bookingPageData?.displayId && (
                <span className="bg-gray-200 px-2 border-round ml-2">{bookingPageData.displayId}</span>
              )}
            </div>
            <div className="mt-2">
              {labels.hosts + ': '}
              <b>{event.hostName}</b>
              {generateNamesString(event.cohostNames)}
            </div>
            <div className="mt-2">
              {labels.attendees + ': '}
              <b>{event.inputFields?.find((field) => field?.fieldType === CustomFieldType.NAME)?.value || ''}</b>
              {generateNamesString(event.guestEmails)}
            </div>
            {event.isNoShow && (
              <div className="flex align-items-center mt-2 px-2 py-1 text-white w-fit uppercase bg-red-600">
                {labels.noShowLabel}
              </div>
            )}
          </div>
          <div className="md:col-fixed flex align-items-center">
            <i className={`pi pi-angle-${isDetailsOpen ? 'down' : 'right'}`}></i>
            <span className="hover:text-primary-600">{labels.details}</span>
          </div>
        </div>

        {isDetailsOpen && (
          <>
            <div className="border-top-1 surface-border my-3 -mx-3 -mt-1"></div>
            <div className="grid -mb-2">
              <div className="col-12 md:col-4 flex flex-column gap-3">
                {/* {event.location?.type === LocationType.IN_PERSONAL && <div>{event.location.settings}</div>} */}
                {!canceled?.isCanceled && !isPastEvent && (
                  <Button
                    label={labels.reschedule}
                    icon="pi pi-calendar"
                    outlined
                    className="md:w-13rem"
                    onClick={(e) => {
                      e.stopPropagation();
                      openRescheduleEventModal();
                    }}
                  />
                )}
                {/* <Button label={labels.editAttendeeLimit} icon="pi pi-sort" outlined className="w-13rem mt-3" /> */}
                {!canceled?.isCanceled && event.userId === currentUserId && (
                  <Button
                    label={event.isNoShow ? labels.undoNoShow : labels.markAsNoShow}
                    icon="pi pi-user-minus"
                    outlined
                    className="md:w-13rem"
                    onClick={(e) => {
                      e.stopPropagation();
                      event.isNoShow ? undoNoShow() : openNoShowEventModal();
                    }}
                    disabled={!isPastEvent}
                    tooltip={!isPastEvent ? labels.markAsNoShowTooltip : ''}
                    tooltipOptions={{ showOnDisabled: true }}
                  />
                )}
                {!canceled?.isCanceled && !isPastEvent && (
                  <Button
                    label={labels.cancel}
                    icon="pi pi-trash"
                    severity="danger"
                    outlined
                    className="md:w-13rem"
                    onClick={(e) => {
                      e.stopPropagation();
                      openCancelEventModal();
                    }}
                  />
                )}
              </div>
              <div className="col-12 md:col-8">
                <div
                  className="cursor-pointer flex align-items-center w-fit"
                  onClick={(e) => {
                    e.stopPropagation();
                    openViewAttendeesModal();
                  }}
                >
                  <span className="mr-2 text-lg pi pi-user" />
                  <span className="underline text-primary hover:text-primary-700">{labels.viewAttendees}</span>
                </div>
                <div
                  className="cursor-pointer flex align-items-center mt-3 w-fit"
                  onClick={(e) => {
                    e.stopPropagation();
                    openEditMeetingNotesModal();
                  }}
                >
                  <span className={`mr-2 text-lg pi ${event.internalNotes ? 'pi-file-edit' : 'pi-plus'}`} />
                  <span className="underline text-primary hover:text-primary-700">{labels.meetingNotes}</span>
                </div>
                {event.internalNotes && (
                  <div
                    className="border-1 surface-border border-round py-2 px-4 mt-3 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      openEditMeetingNotesModal();
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: event.internalNotes || '' }} />
                  </div>
                )}

                <div
                  className="cursor-pointer flex align-items-center mt-3 w-fit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsAnswersOpen((prev) => !prev);
                  }}
                >
                  <span className={`mr-2 text-lg pi ${isAnswersOpen ? 'pi-angle-up' : 'pi-angle-down'}`} />
                  <span className="underline text-primary hover:text-primary-700">{labels.inviteeAnswers}</span>
                </div>
                {isAnswersOpen && (
                  <div className="grid mt-3">
                    {event.inputFields?.map(
                      (customField) =>
                        isCustomFieldVisible(customField) &&
                        customField && (
                          <div key={customField.id} className="col-12 md:col-6">
                            <div className="font-bold">{customField.label}</div>
                            <div>{customField.value}</div>
                          </div>
                        )
                    )}

                    {event.note && (
                      <div className="col-12 md:col-6">
                        <div className="font-bold">{labels.noteForAttendees}</div>
                        <div>{event.note}</div>
                      </div>
                    )}
                  </div>
                )}

                {canceled?.isCanceled && (
                  <div className="py-2 mt-3">
                    <div>
                      <span className="font-semibold">{labels.cancelReason}</span> {canceledReason}
                    </div>
                    {canceled?.note}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

ScheduledMeeting.displayName = 'ScheduledMeeting';
