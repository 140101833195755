import { t } from '../../../i18n/i18n';

export default {
  activate: t('Common:ACTIVATE'),
  admin: t('Workspaces:ADMIN_TITLE'),
  clone: t('Common:CLONE'),
  deactivate: t('Common:DEACTIVATE'),
  delete: t('Common:DELETE'),
  edit: t('Common:EDIT'),
  none: t('Common:NONE'),
  view: t('Common:VIEW'),
};
