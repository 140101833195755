import { combineReducers } from 'redux';
import { workspacesActionTypes, WorkspacesAction } from './actions';
import { WorkspaceInput } from '../../API';
import { DEFAULT_WORKSPACE } from './constants';
import { WorkspaceData } from './types';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { UploadedImage } from '../../types/types';

const workspace = (state: WorkspaceData = {} as WorkspaceData, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.SET_WORKSPACE:
      return action.payload;
    case workspacesActionTypes.UPDATE_WORKSPACE:
      return { ...state, ...action.payload };
    case workspacesActionTypes.CREATE_WORKSPACE_SUCCESS:
      return DEFAULT_WORKSPACE;
    case workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.DELETE_WORKSPACES_SUCCESS:
      return {} as WorkspaceData;
    // case workspacesActionTypes.UPLOAD_IMAGE_FILE_SUCCESS:
    //   return {
    //     ...state,
    //     style: { ...state.style, [action.payload.imageType]: action.payload.imageLink },
    //   };
    case workspacesActionTypes.UPDATE_WORKSPACE_STYLE:
      return {
        ...state,
        style: { ...state.style, ...action.payload },
      };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as WorkspaceData;
    default:
      return state;
  }
};

const workspaces = (state: WorkspaceInput[] = [], action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.GET_WORKSPACES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const isFetchingAvatar = (state: boolean = false, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.UPLOAD_AVATAR_FILE_REQUEST:
      return true;
    case workspacesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS:
    case workspacesActionTypes.UPLOAD_AVATAR_FILE_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isFetchingBackground = (state: boolean = false, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST:
      return true;
    case workspacesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS:
    case workspacesActionTypes.UPLOAD_BACKGROUND_FILE_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const images = (state: UploadedImage[] = [], action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS:
    case workspacesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS:
      return [
        ...state,
        action.payload,
       ] as UploadedImage[];
    case workspacesActionTypes.RESET_IMAGE:
      return [
        ...state,
        { imageType: action.payload, imageLink: null },
       ] as UploadedImage[];
    case workspacesActionTypes.CLEAR_IMAGE:
      return [
        ...state,
        { imageType: action.payload, imageLink: '' },
       ] as UploadedImage[];
    case GlobalActionTypes.RESET_WHOLE_STORE:
    case workspacesActionTypes.CREATE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.SET_WORKSPACE:
      return [];
    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.GET_WORKSPACES_REQUEST:
    case workspacesActionTypes.SAVE_WORKSPACE_REQUEST:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST:
    case workspacesActionTypes.DELETE_WORKSPACES_REQUEST:
      return true;
    case workspacesActionTypes.GET_WORKSPACES_SUCCESS:
    case workspacesActionTypes.GET_WORKSPACES_FAIL:
    case workspacesActionTypes.SAVE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.SAVE_WORKSPACE_FAIL:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_FAIL:
    case workspacesActionTypes.DELETE_WORKSPACES_SUCCESS:
    case workspacesActionTypes.DELETE_WORKSPACES_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.GET_WORKSPACES_REQUEST:
      return null;
    case workspacesActionTypes.GET_WORKSPACES_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  workspace,
  workspaces,
  isFetchingAvatar,
  isFetching,
  isFetchingBackground,
  images,
  error,
});
