import { DEFAULT_MODAL_OPTIONS } from './constants';
import { ModalsRecord, InitializeModalSettings } from './typings';

export const checkModal = (state: ModalsRecord, payload: InitializeModalSettings) => {
  if (state.hasOwnProperty(payload.name)) {
    console.error('Modal.Container must have uniq "name" prop.');
    return state;
  }

  return {
    ...state,
    [payload.name]: { ...DEFAULT_MODAL_OPTIONS, ...payload.options },
  };
};
