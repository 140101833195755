import { combineReducers } from 'redux';
import { ErrorAction, ErrorActionTypes } from './actions';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { ErrorCodes } from './types';

const code = (state: ErrorCodes | null = null, action: ErrorAction | GlobalAction) => {
  switch (action.type) {
    case ErrorActionTypes.SET_THE_ERROR:
      return action.code;
    case ErrorActionTypes.RESET_THE_ERROR:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const location = (state: string = "", action: ErrorAction | GlobalAction) => {
  switch (action.type) {
    case ErrorActionTypes.SET_THE_ERROR:
      return window.location.pathname;
    case ErrorActionTypes.RESET_THE_ERROR:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return "";
    default:
      return state;
  }
};

export default combineReducers({ code, location });
