import { LicenseType, OrgType } from '../../../API';
import { t } from '../../../i18n/i18n';
import { CHARGEBEE_MONTHLY_TERM } from '../../billing';
import { ToastNotificationOptions } from '../../notifications';
import { OrgFilters, PayLaterDetails } from './types';

export enum ORG_STATUS_VALUES {
  'Active' = 'Active',
  'Expired' = 'Expired',
  'PaymentLate' = 'PaymentLate',
  'All' = 'All',
}
export const ORG_STATUS_OPTIONS = [
  { value: ORG_STATUS_VALUES.Active, label: t('OPSConsoleOrgs:ACTIVE') },
  { value: ORG_STATUS_VALUES.Expired, label: t('OPSConsoleOrgs:EXPIRED') },
  { value: ORG_STATUS_VALUES.PaymentLate, label: t('OPSConsoleOrgs:PAYMENT_LATE') },
  { value: ORG_STATUS_VALUES.All, label: t('Common:ALL') },
];

export enum TERM_FILTER_VALUES {
  'All' = 'All',
  'TRIAL' = 'TRIAL',
  'MONTHLY' = 'MONTHLY',
  'ANNUAL' = 'ANNUAL',
}

export enum CHARGEBEE_TERM_VALUES {
  'SILVER_MONTHLY' = 'SUMO1-Silver-USD-Monthly',
  'SILVER_ANNUL' = 'SUMO1-Silver-USD-Yearly',
}

export const TERM_FILTER_OPTIONS = [
  {
    value: TERM_FILTER_VALUES.All,
    label: t('OPSConsoleOrgs:TERM_ALL'),
  },
  {
    value: TERM_FILTER_VALUES.TRIAL,
    label: t('OPSConsoleOrgs:TERM_TRIAL'),
  },
  {
    value: TERM_FILTER_VALUES.MONTHLY,
    label: t('OPSConsoleOrgs:TERM_MONTHLY'),
  },
  {
    value: TERM_FILTER_VALUES.ANNUAL,
    label: t('OPSConsoleOrgs:TERM_ANNUAL'),
  },
];

export const DEFAULT_FILTER: OrgFilters = {
  status: ORG_STATUS_VALUES.Active,
  term: TERM_FILTER_VALUES.All,
};

export const DATA_TERM_OPTIONS = [
  {
    value: TERM_FILTER_VALUES.TRIAL,
    label: t('OPSConsoleOrgs:TRIAL'),
  },
  {
    value: TERM_FILTER_VALUES.MONTHLY,
    label: t('OPSConsoleOrgs:TERM_MONTHLY'),
  },
  {
    value: TERM_FILTER_VALUES.ANNUAL,
    label: t('OPSConsoleOrgs:TERM_ANNUAL'),
  },
];

export const PRICING_TIER_OPTIONS = ['Team Edition'];
export const DEFAULT_PRICING_TIER = 'Team Edition';

export const ORG_TYPE_OPTIONS = [
  { value: OrgType.TRIAL, label: t('OPSConsoleOrgs:TRIAL') },
  { value: OrgType.TEST, label: t('OPSConsoleOrgs:TEST') },
  { value: OrgType.CUSTOMER, label: t('OPSConsoleOrgs:CUSTOMER') },
];

export const DEFAULT_PAY_LATER_DETAILS: PayLaterDetails = {
  email: '',
  term: CHARGEBEE_MONTHLY_TERM,
  quantity: 1,
};

export const EXTEND_DAYS_OPTIONS = [15];
export const DEFAULT_EXTEND_DAYS = 15;
export const MAX_TRIAL_DURATION = 30;

export const ORGS_CSV_HEADERS = [
  t('OPSConsoleOrgDetails:ACCOUNT_NAME'),
  t('OPSConsoleOrgs:ORG_ID_TITLE'),
  t('Users:STATUS'),
  t('OPSConsoleOrgs:TYPE_TITLE'),
  t('OPSConsoleOrgs:TERM_TITLE'),
  t('OPSConsoleOrgs:AMOUNT_TITLE'),
  t('OPSConsoleOrgDetails:AMOUNT_PER_USER'),
  t('OPSConsoleOrgs:LICENSES_NUMBER'),
  t('OPSConsoleOrgDetails:ACTIVE_USERS_NUMBER'),
  t('OPSConsoleOrgDetails:ADOPTION_PERCENT_WEEKLY'),
  t('OPSConsoleOrgDetails:ADOPTION_PERCENT_MONTHLY'),
  t('OPSConsoleOrgDetails:START_DATE'),
  t('OPSConsoleOrgDetails:EXPIRATION_DATE'),
  t('OPSConsoleOrgs:DAYS_LEFT_TITLE'),
  t('OPSConsoleOrgs:BOOKED_NUMBER'),
  t('OPSConsoleOrgDetails:ORG_NOTE'),
  t('OPSConsoleOrgs:LAST_ACTIVE_TITLE'),
];

export const GET_ORGS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:GET_ORGS_FAIL_MESSAGE'),
});

export const GET_ORG_DETAILS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:GET_ORG_DETAILS_FAIL_MESSAGE'),
});

export const SAVE_ORG_DETAILS_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OrgsToastsNotifications:SAVE_ORG_DETAILS_SUCCESS_MESSAGE'),
});

export const SAVE_ORG_DETAILS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:SAVE_ORG_DETAILS_FAIL_MESSAGE'),
});

export const DELETE_ORG_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OrgsToastsNotifications:DELETE_ORG_SUCCESS_MESSAGE'),
});

export const DELETE_ORG_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:DELETE_ORG_FAIL_MESSAGE'),
});

export const EXTEND_LICENSE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OrgsToastsNotifications:EXTEND_LICENSE_SUCCESS_MESSAGE'),
});

export const EXTEND_LICENSE_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:EXTEND_LICENSE_FAIL_MESSAGE'),
});

export const EXPIRE_TRIAL_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OrgsToastsNotifications:EXPIRE_TRIAL_SUCCESS_MESSAGE'),
});

export const EXPIRE_TRIAL_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:EXPIRE_TRIAL_FAIL_MESSAGE'),
});

export const CONVERT_TO_TEST_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OrgsToastsNotifications:CONVERT_TO_TEST_SUCCESS_MESSAGE'),
});

export const CONVERT_TO_TEST_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:CONVERT_TO_TEST_FAIL_MESSAGE'),
});

export const CONVERT_TO_PAY_LATER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('OrgsToastsNotifications:CONVERT_TO_PAY_LATER_SUCCESS_MESSAGE'),
});

export const CONVERT_TO_PAY_LATER_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('OrgsToastsNotifications:CONVERT_TO_PAY_LATER_FAIL_MESSAGE'),
});

export const BUY_LICENSE_PAYLATER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 6000,
  title: t('OrgsToastsNotifications:CREATE_PAY_LATER_LICENSE_SUCCESS_TOAST'),
  message: t('OrgsToastsNotifications:CREATE_PAY_LATER_LICENSE_SUCCESS_MESSAGE'),
});