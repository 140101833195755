import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { ColorsAndFonts, QuickSetupScreen } from '..';
import labels from './labels';
import { Button } from 'primereact/button';
import { quickSetupActions, quickSetupNavigationActions, quickSetupSelectors } from '../../../store/quickSetup';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BookingPageLabelsInput } from '../../../API';
import { Link } from 'react-router-dom';
import EmptyImage from '../../../assets/images/emptyImage.svg';
import { BookingPagePreviewModal } from '../../bookingPages';
import { DEFAULT_BOOKING_PAGE_DATA, bookingPageSelectors, previewModalActions } from '../../../store/bookingPages';
import { Checkbox } from 'primereact/checkbox';
import { userSettingsSelectors } from '../../../store/userSettings';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { FILE_IMAGE_TYPES, MAX_FILE_SIZE_BYTES } from '../../../types/constants';
import { ImageType } from '../../../types/types';

export const SetupYourBrandStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const lookAndFeel = useSelector(quickSetupSelectors.selectLookAndFeel);
  const logo = useSelector(quickSetupSelectors.selectLogo);
  const background = useSelector(quickSetupSelectors.selectBackground);
  const styleForPreview = useSelector(quickSetupSelectors.selectStyleForPreview);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isAcceptedTerms = useSelector(quickSetupSelectors.selectIsAcceptTerms);
  const isShortLinkFetching = useSelector(bookingPageSelectors.selectIsShortLinkFetching);

  const [localLogo, setLocalLogo] = useState(logo);
  const [localBackground, setLocalBackground] = useState(background);
  const logoRef = useRef<FileUpload>(null);
  const backgroundRef = useRef<FileUpload>(null);

  const buttonOptions = () => ({
    label: labels.upload,
    icon: 'hidden',
    className: 'p-button-outlined p-button-plain',
  });

  useEffect(() => {
    setLocalBackground(background);
  }, [background]);

  useEffect(() => {
    setLocalLogo(logo);
  }, [logo]);

  const handleNext = () => {
    dispatch(quickSetupActions.saveQuickSetupRequest());
    // dispatch(quickSetupNavigationActions.navigateNext());
  };

  const handleBack = () => {
    dispatch(quickSetupNavigationActions.navigateBack());
  };

  const handleChange = (name: string, value: string) => {
    dispatch(quickSetupActions.updateWorkspaceLookAndFeel({ [name]: value }));
  };

  const uploadImage = (imageType: ImageType, file: File) => {
    dispatch(
      quickSetupActions.uploadImageFile({
        imageType: imageType,
        fileName: file.name,
        url: URL.createObjectURL(file),
      })
    );
    if (imageType === ImageType.avatar) {
      logoRef.current && logoRef.current.clear();
    } else {
      backgroundRef.current && backgroundRef.current.clear();
    }
  };

  const handleFileRemove = (imageType: ImageType) => {
    dispatch(quickSetupActions.clearImageFile(imageType));
  };

  const handlePreviewOpen = () => {
    dispatch(previewModalActions.openModal());
  };

  const handleSaveLabels = (labels: BookingPageLabelsInput) => {
    dispatch(quickSetupActions.updateWorkspaceLabels(labels));
  };

  const handleAcceptTerms = (value: boolean) => {
    dispatch(quickSetupActions.setIsAcceptTerms(value));
  };

  return (
    <QuickSetupScreen.Container title={labels.title}>
      <div className="flex flex-column align-items-center">
        <div className="text-xl mt-3">{labels.description}</div>
        <div className="w-full flex flex-column md:flex-row mt-4 px-2">
          <div className="flex flex-column align-items-center border-1 border-200 shadow-1 mb-5 md:mb-0 mr-0 md:mr-5  p-3">
            <div className="flex align-items-center text-xl align-self-start mb-3">
              <i className="pi pi-flag-fill text-2xl mr-2" />
              {labels.logoTitle}
            </div>
            <div className="mb-2 px-2">{labels.logoDesc}</div>
            <div className="w-full flex flex-row">
              <div className="col-6 flex flex-column align-items-center">
                <div className="-mt-1 font-semibold mb-2 text-lg">{labels.logo}</div>
                <Image
                  src={localLogo || EmptyImage}
                  alt={labels.yourLogo}
                  preview={Boolean(localLogo)}
                  imageClassName="max-h-8rem w-full h-full"
                  className="h-8rem w-full"
                  imageStyle={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
                <div className="flex flex-row align-items-center mt-2">
                  <FileUpload
                    ref={logoRef}
                    mode="basic"
                    auto
                    accept={FILE_IMAGE_TYPES}
                    maxFileSize={MAX_FILE_SIZE_BYTES}
                    chooseOptions={buttonOptions()}
                    customUpload
                    uploadHandler={(e) => uploadImage(ImageType.avatar, e.files[0])}
                  />
                  <Button
                    icon="pi pi-trash"
                    outlined
                    className="ml-2"
                    onClick={() => handleFileRemove(ImageType.avatar)}
                    disabled={!localLogo}
                  />
                </div>
              </div>
              <div className="col-6 flex flex-column align-items-center">
                <div className="-mt-1 font-semibold mb-2 text-lg">{labels.background}</div>
                <Image
                  src={localBackground || EmptyImage}
                  alt={labels.yourBackground}
                  preview={Boolean(localBackground)}
                  imageClassName="max-h-8rem w-full h-full"
                  className="h-8rem w-full"
                  imageStyle={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
                <div className="flex flex-row align-items-center mt-2">
                  <FileUpload
                    ref={backgroundRef}
                    mode="basic"
                    auto
                    accept={FILE_IMAGE_TYPES}
                    maxFileSize={MAX_FILE_SIZE_BYTES}
                    chooseOptions={buttonOptions()}
                    customUpload
                    uploadHandler={(e) => uploadImage(ImageType.backgroundImage, e.files[0])}
                  />
                  <Button
                    icon="pi pi-trash"
                    outlined
                    className="ml-2"
                    onClick={() => handleFileRemove(ImageType.backgroundImage)}
                    disabled={!localBackground}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">{labels.pictureFormats}</div>
          </div>
          <div className="col-12 md:col-7 flex flex-column align-items-center border-1 border-200 shadow-1 p-3">
            <div className="w-full flex justify-content-between align-items-baseline mb-3">
              <div className="flex align-items-center text-xl">
                <i className="pi pi-cog text-2xl mr-2" />
                {labels.colorsTitle}
              </div>
              <Button
                label={labels.preview}
                icon="pi pi-eye"
                text
                className="p-0 text-lg"
                onClick={handlePreviewOpen}
                tooltip={labels.previewTooltip}
                tooltipOptions={{ position: 'top' }}
              />
            </div>
            <ColorsAndFonts lookAndFeel={lookAndFeel || {}} handleChange={handleChange} />
            <div className="font-italic mt-2">{labels.colorsNotes}</div>
          </div>
        </div>
      </div>
      <BookingPagePreviewModal
        bookingPage={{ ...DEFAULT_BOOKING_PAGE_DATA, style: styleForPreview }}
        onSave={handleSaveLabels}
        isReadOnly={true}
      />
      <QuickSetupScreen.Buttons>
        <div className="flex justify-content-between">
          <Button label={labels.back} className="w-8rem m-2" onClick={handleBack} outlined />
          <div className="flex flex-column md:flex-row">
            <div className="align-self-center">
              <Checkbox
                checked={isAcceptedTerms || !!isQuickSetupFinished}
                onChange={(e) => handleAcceptTerms(!!e.target.checked)}
                disabled={!!isQuickSetupFinished}
              />
              <Link
                to={urlConstants.TERMS_OF_SERVICE_URL}
                target="_blank"
                className="ml-2 mr-1 underline text-primary hover:text-primary-700"
              >
                {labels.acceptTerms_step1}
              </Link>
              {labels.acceptTerms_step2}
              <Link
                to={urlConstants.PRIVACY_POLICY_URL}
                target="_blank"
                className="ml-1 mr-3 underline text-primary hover:text-primary-700"
              >
                {labels.acceptTerms_step3}
              </Link>
            </div>
            <Button
              label={labels.save}
              className="w-13rem m-2"
              onClick={handleNext}
              disabled={(!isAcceptedTerms && !isQuickSetupFinished) || isShortLinkFetching}
            />
          </div>
        </div>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};