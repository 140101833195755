import { CreateTeamInput } from '../../API';
import { APIPostRequest } from '../utils/reduxUtils';
import { GetTeamsResponse } from './types';

export const getTeams = async (workspaceId: string) => {
  try {
    const response = (await APIPostRequest('/api/public/getTeamsByWorkspaceId', {
      workspaceId,
    })) as GetTeamsResponse;
    if (response) {
      return response;
    } else {
      throw new Error('Teams not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching teams:', error);
    throw error;
  }
};

export const saveTeam = async (team: CreateTeamInput) => {
  try {
    await APIPostRequest('/api/public/upsertTeam', { team });
  } catch (error: unknown) {
    console.error('Error save team:', error);
    throw error;
  }
};

export const deleteTeam = async (id: string) => {
  try {
    await APIPostRequest('/api/public/deleteTeam', {
      id,
    });
  } catch (error: unknown) {
    console.error('Error delete team:', error);
    throw error;
  }
};
