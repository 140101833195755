export const PublicBookingPage = Object.freeze({
  HOST: `Host:`,
  HOST_PHONE: `Host-Telefon`,
  EDIT_BUTTON: `Bearbeiten`,
  MORE: `Mehr`,
  TIME_ZONE_LABEL: `Zeitzone`,
  NAME_FIELD_LABEL: `Name`,
  EMAIL_FIELD_LABEL: `E-Mail`,
  INVITE_OTHERS_FIELD_LABEL: `Weitere Teilnehmer einladen`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Lade andere ein, indem du ihre E-Mail-Adressen durch Kommas getrennt eingibst`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Hinweis für Teilnehmer`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Geben Sie einen Hinweis für alle Teilnehmer ein`,
  NO_USERS_AVAILABLE_LABEL: `Es sind keine Benutzer zur ausgewählten Zeit verfügbar. Bitte wählen Sie eine andere Zeit.`,
  CANCEL_TYPE_LABEL: `Warum absagen?`,
  CANCEL_REASON_LABEL: `Grund der Absage:`,
  IN_PERSON_LABEL: `Persönlich`,
  PHONE_LABEL: `Telefon`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Fehler`,
  VIDEO_CONFERENCE_LABEL: `Videokonferenz`,
  SLOTS_NO_FOUND: `Steckplätze nicht gefunden`,
  VIEW_NEXT_MONTH: `Nächsten Monat anzeigen`,
  ALLOW_SMS: `Terminerinnerungen per SMS erhalten?`,
});

export const ScheduledMeetings = Object.freeze({
  ATTENDEES: `Gäste`,
  CANCELED: 'Abgesagt',
  CANCEL_MEETING_TITLE: `Meeting abbrechen`,
  CANCEL_MEETING_DESCRIPTION: `Warum absagen?`,
  CANCEL_REASON: `Grund der Absage:`,
  DETAILS: `Details`,
  BOOKED: `Gebucht`,
  BOOKING_PAGE: `Buchungsseite`,
  BOOKING_PAGE_PLACEHOLDER: `Seite auswählen`,
  EDIT_ATTENDEE_LIMIT: `Teilnehmerbegrenzung bearbeiten`,
  GOOGLE_MEET: `Google Meet`,
  HOSTS: `Gastgeber`,
  IN_PERSON: `Persönlich`,
  INVITEE_ANSWERS: `Antworten der Eingeladenen`,
  LOCATION_TITLE: `Ort`,
  NO_SCHEDULED_MEETINGS: `Sie haben keine gebuchten Besprechungen`,
  NO_LOCATION_GIVEN: `Kein Ort angegeben`,
  NO_SHOW_LABEL: `Nicht erschienen`,
  MARK_AS_NO_SHOW: `Als Nicht erschienen markieren`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Sind Sie sicher, dass Sie`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `als Nicht erschienen markieren möchten?`,
  MARK_AS_NO_SHOW_TOOLTIP: `Die Veranstaltung hat noch nicht begonnen.`,
  MEETING_NOTES: `Interne Besprechungsnotizen`,
  MEETING_NOTES_DESC: `Nur der Gastgeber wird diese Notizen sehen.`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FOR_ATTENDEES: `Hinweis für Teilnehmer`,
  PAST: `Vergangenheit`,
  PHONE_CALL: `Telefonanruf`,
  RESCHEDULE: `Neu planen`,
  REASON_UNEXPECTED: `Unerwartetes Problem`,
  REASON_INVITE: `Andere einladen müssen`,
  REASON_TIME: `Zeit zur Vorbereitung benötigt`,
  REASON_OTHER: `Anderer Grund`,
  STATUS: `Status`,
  STATUS_PLACEHOLDER: `Status auswählen`,
  TEAM: `Team`,
  TITLE: `Gebuchte Besprechungen`,
  VIEW_ATTENDEES: `Teilnehmer anzeigen`,
  VIEW_ATTENDEES_DESC: `Dies sind die aktuellen Teilnehmer für dieses Meeting`,
  VIEW_ATTENDEES_NOTE: `Der Gastgeber kann Teilnehmer zu seinem verbundenen Kalender hinzufügen oder entfernen.`,
  UNDO_NO_SHOW: `Nicht erschienen rückgängig machen`,
  UPCOMING: `Bevorstehend`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Videokonferenz`,
});

export const ScheduledMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Fehler beim Abrufen geplanter Veranstaltungen`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Fehler beim Absagen der Veranstaltung`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Erfolgreich abgesagte Veranstaltung`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Fehler beim Aktualisieren der Veranstaltung`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Erfolgreich gelöschte Veranstaltung`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Fehler beim Löschen der Veranstaltung`,
});

export const HostNotificationTemplates = Object.freeze({
  HOST_CONFIRMATION_TEMPLATE_SUBJECT: `Neues Meeting: <Meeting Name> mit <Invitee Name> um <Meeting Time>, <Meeting Date>`,
  HOST_RESCHEDULE_SUBJECT: `Verschoben: <Meeting Name> mit <Invitee Name>`,
  HOST_CANCELATION_SUBJECT: `Abgesagt: <Meeting Name> mit <Invitee Name> um <Meeting Time>, <Meeting Date>`,
  HOST_CONFIRMATION_TEMPLATE_BODY: `Hallo <Host Name>,

Ihr neues Meeting <Meeting Name> ist geplant.

<b>Eingeladener</b>
<Invitee Name> ( <Invitee Email> )

<b>Zeit und Datum</b>
<Meeting Time>, <Meeting Date>

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_RESCHEDULE_BODY: `Hallo <Host Name>,

Ihr Meeting <Meeting Name> wurde verschoben.

<b>Eingeladener</b>
<Invitee Name> ( <Invitee Email> )

<b>Aktualisierte Zeit und Datum</b>
<Meeting Time>, <Meeting Date>

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  HOST_CANCELATION_BODY: `Hallo <Host Name>,

Ihr Meeting <Meeting Name> wurde abgesagt.

<b>Eingeladener</b>
<Invitee Name> ( <Invitee Email> )

<b>Zeit und Datum</b>
<Meeting Time>, <Meeting Date>

<b>Ort</b>
<Meeting Location>

<b>Absagegrund</b>
<Cancel Reason>`,
});
