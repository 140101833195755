import { useSelector } from 'react-redux';
import { bookingPagesActions, bookingPageSelectors } from '../../../store/bookingPages';
import { SettingsStep } from '../../common/meeting/settingsStep/SettingsStep';

export const BookingPageSettingsStep = () => {
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);

  return (
    <SettingsStep
      selectors={bookingPageSelectors}
      actions={bookingPagesActions}
      isReadOnly={isBookingPageLocked || hasTemplate}
    />
  );
};
