import { t } from '../../../i18n/i18n';

export default {
  addToTeam: t('Users:ADD_TO_TEAM'),
  addToTeamPlaceholder: t('Users:ADD_TO_TEAM_PLACEHOLDER'),
  alreadyInvitedCount: t('Users:ALREADY_INVITED_COUNT'),
  assignBookingTemplates: t('Users:ASSIGN_BOOKING_TEMPLATES'),
  assignBookingTemplatesPlaceholder: t('Users:ASSIGN_BOOKING_TEMPLATES_PLACEHOLDER'),
  bookingTemplatesTooltip: t('EditUser:BOOKING_TEMPLATES_TOOLTIP'),
  assignRole: t('Users:ASSIGN_ROLE'),
  assignWorkspace: t('Users:ASSIGN_WORKSPACE'),
  back: t('Common:BACK'),
  confirmDialogDesc: t('Users:CONFIRM_DIALOG_DESC'),
  close: t('Users:CLOSE'),
  CSVInviteMessage: t('Users:CSV_INVITE_MESSAGE_TITLE'),
  CSVTemplateDesc: t('Users:CSV_TEMPLATE_DESC'),
  CSVTemplateTitle: t('Users:CSV_TEMPLATE_TITLE'),
  CSVUploadDesc: t('Users:CSV_UPLOAD_DESC'),
  CSVUploadTitle: t('Users:CSV_UPLOAD_TITLE'),
  download: t('Common:DOWNLOAD'),
  days_10: t('EditUser:DAYS_10'),
  days_15: t('EditUser:DAYS_15'),
  days_20: t('EditUser:DAYS_20'),
  days_30: t('EditUser:DAYS_30'),
  expirationDate: t('EditUser:EXPIRATION_DATE'),
  invite: t('Users:INVITE'),
  inviteMessageDesc: t('Users:INVITE_MESSAGE_DESC'),
  inviteMessageTitle: t('Users:INVITE_MESSAGE_TITLE'),
  inviteTitle: t('Users:INVITE_TITLE'),
  bookingTemplate: t('Users:BOOKING_TEMPLATE'),
  noEmail: t('Users:NO_EMAIL_ADDRESSES'),
  none: t('Common:NONE'),
  next: t('Common:NEXT'),
  optional: t('Users:OPTIONAL'),
  remainingMessage: t('License:REMAINING_MESSAGE'),
  role: t('Users:ROLE'),
  superAdminAccessDescription: t('Users:SUPER_ADMIN_ACCESS_DESCRIPTION'),
  team: t('Users:TEAM'),
  upload: t('Common:UPLOAD'),
  user: t('Common:USER'),
  users: t('Common:USERS'),
  userCount: t('Users:USER_COUNT'),
  workspace: t('Users:WORKSPACE'),
};
