import { useSelector } from 'react-redux';
import {
  DEFAULT_PRICING_TIER,
  ORG_STATUS_OPTIONS,
  ORG_TYPE_OPTIONS,
  PRICING_TIER_OPTIONS,
  orgsActions,
  orgsSelectors,
} from '../../../../store/opsConsole/orgs';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import labels from './labels';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { OrgType, TenantStatus } from '../../../../API';
import { useDispatch } from 'react-redux';
import { accountsSelectors } from '../../../../store/opsConsole/accounts';
import { authentificationSelectors } from '../../../../store/authentification';
import { TERM_OPTIONS } from '../../../../store/opsConsole/users';
import { useEffect } from 'react';

export const OrgDetailsBasicInfo = () => {
  const dispatch = useDispatch();
  const tenantDetails = useSelector(orgsSelectors.selectTenantDetails);
  const license = useSelector(orgsSelectors.selectLicense);
  const startDate = useSelector(orgsSelectors.selectStartDate);
  const endDate = useSelector(orgsSelectors.selectEndDate);
  const orgType = useSelector(orgsSelectors.selectTenantType);
  const tenantNote = useSelector(orgsSelectors.selectTenantNote);
  const amountPerUser = useSelector(orgsSelectors.selectAmountPerUser);
  const orgStatus = useSelector(orgsSelectors.selectOrgStatus);
  const daysRemaining = useSelector(orgsSelectors.selectDaysRemaining);
  const weeklyAdoptionPercent = useSelector(orgsSelectors.selectWeeklyAdoptionPercent);
  const monthlyAdoptionPercent = useSelector(orgsSelectors.selectMonthlyAdoptionPercent);
  const licenseTerm = useSelector(orgsSelectors.selectLicenseTerm);
  const accountOptions = useSelector(accountsSelectors.selectAccountOptions);
  const accountId = useSelector(orgsSelectors.selectAccountId);
  const bookedMeetings = useSelector(orgsSelectors.selectBookedMeetings);
  const isSupport = useSelector(authentificationSelectors.selectIsSupport);
  const orgs = useSelector(orgsSelectors.selectOrgs);
  const orgDetails = useSelector(orgsSelectors.selectOrgDetails);
  const orgsIsFetching = useSelector(orgsSelectors.selectIsFetching);

  useEffect(() => {
    dispatch(orgsActions.getOrgsRequest());
  }, []);

  useEffect(() => {
    if (orgs.length && weeklyAdoptionPercent === null) {
      const currentOrg = orgs.find(org => org?.tenantId === orgDetails.tenantRecord?.tenantId);
      if (currentOrg) {
        dispatch(orgsActions.getOrgDetailsSuccess({
          ...orgDetails,
          percentOfWeekAdoption: currentOrg.percentOfWeekAdoption || '0',
          percentOfMonthAdoption: currentOrg.percentOfMonthAdoption || '0',
        }));
      }
    }
  }, [orgs, weeklyAdoptionPercent])


  const handleEndDateChange = (endDate: Date) => {
    const isEndDayInFuture = dayjs(endDate).isAfter(dayjs().format('YYYY-MM-DD'), 'day');
    dispatch(orgsActions.updateTenant({ status: isEndDayInFuture ? TenantStatus.ACTIVE : TenantStatus.EXPIRED }));
    dispatch(orgsActions.updateLicense({ endDate: dayjs(endDate).format('YYYY-MM-DD') }));
  };

  const handleAccountChange = (accountId: string) => {
    dispatch(orgsActions.changeAccount(accountId));
  };

  const handleNoteChange = (note: string) => {
    dispatch(orgsActions.updateTenant({ note }));
  };

  return (
    <div className="border-1 border-round p-4">
      <div className="text-lg font-bold mb-2">{labels.orgStatus}</div>
      <div className="grid justify-content-between">
        <div className="col-12 md:col-6 grid">
          <div className="flex flex-column col-12 lg:col-8">
            <label htmlFor="orgId">{labels.orgId}</label>
            <InputText value={tenantDetails?.tenantId || ''} id="orgId" disabled />
          </div>
          <div className="flex flex-column col-12 lg:col-4">
            <label htmlFor="orgType">{labels.orgType}</label>
            <Dropdown value={orgType} options={ORG_TYPE_OPTIONS} inputId="orgType" disabled />
          </div>
        </div>
        <div className="col-12 md:col-6 grid">
          <div className="flex flex-column col-12 lg:col-8">
            <label htmlFor="accountName">{labels.accountName}</label>
            <Dropdown
              value={accountId}
              options={accountOptions}
              id="accountName"
              onChange={(e) => handleAccountChange(e.target.value)}
            />
          </div>
          <div className="flex flex-column col-12 lg:col-4">
            <label htmlFor="orgStatus">{labels.orgStatus}</label>
            <Dropdown value={orgStatus} inputId="orgStatus" options={ORG_STATUS_OPTIONS} disabled />
          </div>
        </div>
      </div>
      <div className="flex flex-column my-3">
        <label htmlFor="orgNote">{labels.orgNote}</label>
        <InputTextarea
          rows={5}
          autoResize
          id="orgNote"
          value={tenantNote}
          onChange={(e) => handleNoteChange(e.target.value)}
        />
      </div>
      <div className="grid justify-content-between">
        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="startDate">{labels.startDate}</label>
          <Calendar value={startDate} dateFormat="mm/dd/yy" id="startDate" disabled />
        </div>
        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="expirationDate">{labels.expirationDate}</label>
          <Calendar
            value={endDate}
            dateFormat="mm/dd/yy"
            id="expirationDate"
            disabled={isSupport || orgType !== OrgType.TEST}
            onChange={(e) => e.target.value instanceof Date && handleEndDateChange(e.target.value)}
            minDate={startDate}
          />
        </div>

        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="pricingTier">{labels.featurePricingTier}</label>
          <Dropdown value={DEFAULT_PRICING_TIER} options={PRICING_TIER_OPTIONS} id="pricingTier" />
        </div>

        <div className="flex flex-column col-12 md:col-5 ">
          <label htmlFor="daysRemaining">{labels.daysRemaining}</label>
          <InputText value={daysRemaining} id="daysRemaining" disabled />
        </div>
      </div>
      {orgType === OrgType.CUSTOMER && (
        <>
          <div className="text-lg font-bold mt-3 mb-2">{labels.terms}</div>
          <div className="grid justify-content-between">
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="term">{labels.term}</label>
              <Dropdown value={licenseTerm} id="term" options={TERM_OPTIONS} disabled />
            </div>
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="amountPerUser">{labels.amountPerUser}</label>
              <InputText value={amountPerUser} id="amountPerUser" disabled />
            </div>
          </div>
          {/*  
            TODO remove if unneeded Payment Section
          <div className="text-lg font-bold mt-3 mb-2">{labels.payment}</div>
          <div className="grid justify-content-between">
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="paymentType">{labels.paymentType}</label>
              <Dropdown value={''} id="paymentType" />
            </div>
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="paymentTerm">{labels.paymentTerm}</label>
              <InputText value={''} id="paymentTerm" />
            </div>
          </div> */}
        </>
      )}
      <div className="text-lg font-bold mt-3 mb-2">
          {labels.userAdoption}
          {orgsIsFetching && <i className="pi pi-spin pi-spinner" style={{ marginLeft: '10px' }} />}
      </div>
      <div className="grid justify-content-between">
        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="adoptionWeekly">{labels.weeklyAdoptionPercent}</label>
          <InputText value={weeklyAdoptionPercent || '0'} id="adoptionWeekly" disabled />
        </div>
        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="adoptionMonthly">{labels.monthlyAdoptionPercent}</label>
          <InputText value={monthlyAdoptionPercent || '0'} id="adoptionMonthly" disabled />
        </div>
        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="licenses">{labels.licensesNumber}</label>
          <InputText value={license?.owned?.toString() || ''} id="licenses" disabled />
        </div>
        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="activeUsers">{labels.activeUsersNumber}</label>
          <InputText value={license?.assigned?.toString() || ''} id="activeUsers" disabled />
        </div>
        <div className="flex flex-column col-12 md:col-5">
          <label htmlFor="bookedMeeting">{labels.bookedNumber}</label>
          <InputText value={bookedMeetings} id="bookedMeeting" disabled />
        </div>
      </div>
    </div>
  );
};
