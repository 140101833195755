import { AppThunk } from '../rootStore';
import { TOASTS_QUEUE_MAX_LENGTH } from './constants';
import { ToastNotification, ToastNotificationOptions } from './typings';
import { notificationsSelectors } from './selectors';
import { v4 as getId } from 'uuid';

export enum NotificationsActionTypes {
  ADD_TOAST_TO_QUEUE = 'notifications/ADD_TOAST_TO_QUEUE',
  REMOVE_TOAST_FROM_QUEUE = 'notifications/REMOVE_TOAST_FROM_QUEUE',
}

export type NotificationsAction =
  | { type: NotificationsActionTypes.ADD_TOAST_TO_QUEUE; payload: ToastNotification }
  | { type: NotificationsActionTypes.REMOVE_TOAST_FROM_QUEUE; payload?: string };

const addToastToQueue = (payload: ToastNotification): NotificationsAction => ({
  type: NotificationsActionTypes.ADD_TOAST_TO_QUEUE,
  payload,
});
const removeToastFromQueue = (payload: string): NotificationsAction => ({
  type: NotificationsActionTypes.REMOVE_TOAST_FROM_QUEUE,
  payload,
});

const showToastThunk =
  (payload: ToastNotificationOptions): AppThunk =>
  (dispatch, getState) => {
    const toastNotificationsQueue = notificationsSelectors.selectToastNotificationsQueue(getState());

    toastNotificationsQueue.length >= TOASTS_QUEUE_MAX_LENGTH &&
      dispatch(hideToastThunk(toastNotificationsQueue[0].id));

    const id = getId();

    if (payload.autoHideDuration) {
      const timerId = setTimeout(() => {
        dispatch(hideToastThunk(id));
      }, payload.autoHideDuration);
      payload = { ...payload, timerId };
    }

    dispatch(addToastToQueue({ id, ...payload }));
  };

const hideToastThunk =
  (payload: string): AppThunk =>
  (dispatch, getState) => {
    const toastNotificationsQueue = notificationsSelectors.selectToastNotificationsQueue(getState());

    const currentToast = toastNotificationsQueue.find((toast) => toast.id === payload);

    currentToast?.timerId && clearTimeout(currentToast.timerId);

    dispatch(removeToastFromQueue(payload));
  };

export const notificationsActions = {
  showToast: showToastThunk,
  hideToast: hideToastThunk,
};
