import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader, SectionTitle } from '../../components/common';
import { usersActions, usersSelectors } from '../../store/users';
import { UsersList } from '../../components/users';
import { LicenseList } from '../../components/users/licenseList/LicenseList';
import { userSettingsSelectors } from '../../store/userSettings';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { BuyLicenseButton } from '../../components/billing';
import { authentificationActions, authentificationSelectors } from '../../store/authentification';
import { Button } from 'primereact/button';
import labels from './labels';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { useUserNavigation } from '../../hooks/useUserNavigation';
import { workspacesActions } from '../../store/workspaces/actions';
import { rolesActions } from '../../store/roles';

export const Users = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const isFetching = useSelector(usersSelectors.selectIsFetching);
  const isReadUsers = useSelector(userSettingsSelectors.selectUsersRead);
  const isPaidLicense = useSelector(authentificationSelectors.selectIsPaidLicense);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const licenseFetching = useSelector(authentificationSelectors.selectLicensesIsFetching);

  useEffect(() => {
    if (isReadUsers) {
      dispatch(usersActions.getUsersPageDataRequest());
      dispatch(rolesActions.getRolesRequest());
      if (!isInitialVisit) {
        dispatch(workspacesActions.getWorkspacesRequest());
      }
      if (isSuperAdmin) {
        dispatch(authentificationActions.getLicenseRequest());
      }
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, [isInitialVisit, isReadUsers]);

  return (
    <div>
      {isFetching && <Preloader />}

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle
          id="usersTitle"
          icon="pi-user"
          title={labels.userTitle}
        />
        <div className="-my-2 p-accordion-header-text">
          {isSuperAdmin &&
            (isPaidLicense ? (
              <Button label={labels.myBillings} onClick={() => navigationService.navigateTo(Path.Billing)} />
            ) : (
              <BuyLicenseButton />
            ))}
        </div>
      </div>

      <div className="card">
        {isSuperAdmin && (
          <>
            <div className="mb-3 text-lg font-semibold">
              {labels.licenseTitle}
              {licenseFetching && <i className="pi pi-spin pi-spinner" style={{ marginLeft: '10px' }} />}
            </div>
            <LicenseList />
            <div className="mb-3 mt-6 text-lg font-semibold">{labels.userTitle}</div>
          </>
        )}

        <UsersList />
      </div>
    </div>
  );
};
