import { t } from '../../../../i18n/i18n';

export default {
  typeLabel: t('EditBookingTemplateConfirmationStep:TYPE_LABEL'),
  externalLinkLabel: t('EditBookingTemplateConfirmationStep:EXTERNAL_LINK_LABEL'),
  externalLinkPlaceholder: t('EditBookingTemplateConfirmationStep:EXTERNAL_LINK_PLACEHOLDER'),
  displayBookAnotherButtonLabel: t('EditBookingTemplateConfirmationStep:DISPLAY_BOOK_ANOTHER_BUTTON_LABEL'),
  allowRescheduleLabel: t('EditBookingTemplateConfirmationStep:ALLOW_RESCHEDULE_LABEL'),
  allowCancelLabel: t('EditBookingTemplateConfirmationStep:ALLOW_CANCEL_LABEL'),
  cancelationPolicy: t('EditBookingTemplateNotificationsStep:CANCELATION_POLICY'),
  cancelationPolicyTooltip: t('EditBookingTemplateNotificationsStep:CANCELATION_POLICY_TOOLTIP'),
  addCustomLinkLabel: t('EditBookingTemplateConfirmationStep:ADD_CUSTOM_LINK_LABEL'),
  addCustomLinkDescription: t('EditBookingTemplateConfirmationStep:ADD_CUSTOM_LINK_DESCRIPTION'),
  addCustomLinkTitle: t('EditBookingTemplateConfirmationStep:ADD_CUSTOM_LINK_TITLE'),
  editCustomLinkTitle: t('EditBookingTemplateConfirmationStep:EDIT_CUSTOM_LINK_TITLE'),
  customLinkLabel: t('EditBookingTemplateConfirmationStep:CUSTOM_LINK_LABEL'),
  customLinkPlaceholder: t('EditBookingTemplateConfirmationStep:CUSTOM_LINK_PLACEHOLDER'),
  linkUrlLabel: t('EditBookingTemplateConfirmationStep:LINK_URL_LABEL'),
  linkUrlPlaceholder: t('EditBookingTemplateConfirmationStep:LINK_URL_PLACEHOLDER'),
  cancel: t('Common:CANCEL'),
  save: t('Common:SAVE'),
};
