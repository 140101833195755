import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primereact/autocomplete';
import { useSelector } from 'react-redux';
import { usersSelectors } from '../../../store/users';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { UserAdminDataFullRecord } from '../../../store/users/typings';
import { workspacesSelectors } from '../../../store/workspaces';
import { workspacesActions } from '../../../store/workspaces/actions';
import { AutoCompleteWithChips } from '../../common/autoCompleteWIthChips/AutoCompleteWithChips';
import labels from './labels';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { userSettingsSelectors } from '../../../store/userSettings';

export const WorkspaceWhoStep = () => {
  const dispatch = useDispatch();
  const adminUsers = useSelector(usersSelectors.selectAdminUsers);
  const standardUsers = useSelector(usersSelectors.selectStandardUsers);
  const adminList = useSelector(workspacesSelectors.selectAdminList);
  const userList = useSelector(workspacesSelectors.selectUserList);
  const isPhoneRequired = useSelector(workspacesSelectors.selectIsPhoneRequired);
  const adminEmailsList = useSelector(workspacesSelectors.selectAdminEmailsList);
  const userEmailsList = useSelector(workspacesSelectors.selectUserEmailsList);
  const isExistingWorkspace = useSelector(workspacesSelectors.selectIsExistingWorkspace);
  const id = useSelector(workspacesSelectors.selectId);
  const superAdminUsers = useSelector(usersSelectors.selectActiveSuperAdmins);
  const currentUser = useSelector(usersSelectors.selectCurrentUser);
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);

  const [filteredAdmins, setFilteredAdmins] = useState<UserAdminDataFullRecord[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserAdminDataFullRecord[]>([]);

  const handleAdminListSelect = (e: AutoCompleteSelectEvent) => {
    dispatch(workspacesActions.updateWorkspace({ adminList: [...adminList, e.value] }));
  };
  const removeAdminListChange = (value: string) => {
    dispatch(workspacesActions.updateWorkspace({ adminList: adminList.filter((record) => record.email !== value) }));
  };

  const handleUserListSelect = (e: AutoCompleteSelectEvent) => {
    dispatch(workspacesActions.updateWorkspace({ userList: [...userList, e.value] }));
  };
  const removeUserListChange = (value: string) => {
    dispatch(workspacesActions.updateWorkspace({ userList: userList.filter((record) => record.email !== value) }));
  };

  const filterAdmin = (e: AutoCompleteCompleteEvent) => {
    setFilteredAdmins(
      adminUsers.filter((admin) => {
        return (
          //admin.fullName?.toLowerCase().startsWith(e.query.toLowerCase()) ||
          (!e.query.trim().length || admin.email.toLowerCase().match(e.query.toLowerCase())) &&
          !adminEmailsList.includes(admin.email)
        );
      })
    );
  };

  const filterUser = (e: AutoCompleteCompleteEvent) => {
    setFilteredUsers(
      standardUsers.filter((user) => {
        return (
          //user.fullName?.toLowerCase().startsWith(e.query.toLowerCase()) ||
          (!e.query.trim().length || user.email.toLowerCase().startsWith(e.query.toLowerCase())) &&
          !userEmailsList.includes(user.email)
        );
      })
    );
  };

  const isRemovable = (value: UserAdminDataFullRecord) => {
    const hasMultipleWorkspaces = value.workspaceIds && value.workspaceIds?.length > 1;
    const isAssignedToCurrentWorkspace = value.workspaceIds?.includes(id);
    const isSuperAdmin = superAdminUsers.some((superAdmin) => superAdmin.roleId === value.roleId);
    const isCurrentUser = value.userId === currentUser.userId;

    return (
      !isSuperAdmin &&
      !isCurrentUser &&
      (!isExistingWorkspace || hasMultipleWorkspaces || !isAssignedToCurrentWorkspace)
    );
  };

  return (
    <div className="grid p-fluid py-2">
      <div className="surface-100 p-1 pl-2 col-12">
        <span className="uppercase text-color-secondary text-sm font-medium">{labels.adminTitle}</span>
      </div>
      <div className="col-12 py-4">
        <div className="pb-2">{labels.adminDesc}</div>
        <AutoCompleteWithChips<UserAdminDataFullRecord, UserAdminDataFullRecord>
          placeholder={labels.searchAddAdmins}
          field="email"
          onSelect={handleAdminListSelect}
          suggestions={filteredAdmins}
          completeMethod={filterAdmin}
          className="w-12 md:w-6"
          valuesList={adminList}
          chipKey="email"
          chipLabel="email"
          chipOnRemove={removeAdminListChange}
          isRemovable={isRemovable}
          disabled={isWorkspacesReadOnly}
        />
        {!isExistingWorkspace && <div>{labels.superAdminsDescription}</div>}
      </div>
      <div className="surface-100 p-1 pl-2 col-12">
        <span className="uppercase text-color-secondary text-sm font-medium">{labels.usersTitle}</span>
      </div>
      <div className="col-12 py-4">
        <div className="pb-2">{labels.usersDesc}</div>
        <AutoCompleteWithChips<UserAdminDataFullRecord, UserAdminDataFullRecord>
          placeholder={labels.searchAddUsers}
          field="email"
          onSelect={handleUserListSelect}
          suggestions={filteredUsers}
          completeMethod={filterUser}
          className="w-12 md:w-6"
          valuesList={userList}
          chipKey="email"
          chipLabel="email"
          chipOnRemove={removeUserListChange}
          isRemovable={isRemovable}
          disabled={isWorkspacesReadOnly}
        />
      </div>
      <div className="col-fixed flex p-2 border-round align-items-center" style={{ background: '#e7f4fd' }}>
        <i className="pi pi-info-circle text-xl pr-2" />
        <div>
          {labels.notesPart1}
          <Link to={Path.Users} className="text-color font-bold">
            {labels.notesPart2}
          </Link>
          {labels.notesPart3}
        </div>
      </div>
      <div className="surface-100 p-1 pl-2 col-12 mt-4 mb-2">
        <span className="uppercase text-color-secondary text-sm font-medium">{labels.inviteSettingsTitle}</span>
      </div>
      <div className="col-12 md:col-6 flex mb-0 align-items-center">
        <InputSwitch
          checked={isPhoneRequired}
          onChange={(e) => dispatch(workspacesActions.updateWorkspace({ isPhoneRequired: !!e.value }))}
          className="mr-3"
          disabled={isWorkspacesReadOnly}
        />

        <label>{labels.phoneNumberTitle}</label>
      </div>
      <div className="col-12 md:col-6 text-gray-500">{labels.phoneNumberDesc}</div>
    </div>
  );
};
