export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Los siguientes usuarios están asignados para usar solo este espacio de trabajo`,
  NOTES: `Debe asignar primero estos usuarios a otro espacio de trabajo antes de eliminar este.`,
  TITLE: `Reasignar Usuarios`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Error al activar/desactivar el espacio de trabajo`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espacio de trabajo activado`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Error al eliminar el espacio de trabajo`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Solicitud de eliminación del espacio de trabajo exitosa`,
  GET_WORKSPACES_ERROR_MESSAGE: `Error al obtener la solicitud de espacios de trabajo`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espacio de trabajo desactivado`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Error al guardar la solicitud del espacio de trabajo`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Espacio de trabajo guardado exitosamente`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administradores`,
  ADMIN_DESCRIPTION: `Las siguientes personas pueden administrar este espacio de trabajo.`,
  INVITE_SETTINGS_TITLE: `Configuración de Invitación`,
  NOTES_PART_1: `Alternativamente, puede usar la sección de `,
  NOTES_PART_2: `Gestión de Usuarios`,
  NOTES_PART_3: ` para encontrar un usuario y agregarlo a un espacio de trabajo.`,
  PHONE_NUMBER_DESCRIPTION: `Si planea utilizar SUMO para reservar llamadas telefónicas, se recomienda que lo mantenga habilitado.`,
  PHONE_NUMBER_TITLE: `Hacer obligatorio el campo "Número de teléfono" para los usuarios.`,
  SEARCH_ADD_ADMINS: `Buscar y agregar administrador`,
  SEARCH_ADD_USERS: `Buscar y agregar usuarios`,
  SUPER_ADMINS_DESCRIPTION: `Los superadministradores siempre estarán precargados`,
  USERS_DESCRIPTION: `Las siguientes personas pueden usar este espacio de trabajo.`,
  USERS_TITLE: `Usuarios`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Activo`,
  ADDITIONAL_OPTIONS: `Opciones Adicionales`,
  ADMIN_TITLE: `Admin`,
  BRANDING_DESCRIPTION: `Por defecto, todas las páginas de reserva de este espacio de trabajo utilizarán esta configuración de marca. Sin embargo, puede anular las configuraciones para esta página de reserva específica si es necesario.`,
  BRANDING_TITLE_PART_1: `¿Cuáles serán las`,
  BRANDING_TITLE_PART_2: `configuraciones de marca`,
  BRANDING_TITLE_PART_3: `para este espacio de trabajo?`,
  DEFAULT_WORKSPACE: `Espacio de Trabajo Predeterminado`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar`,
  EDIT_LABELS_DESCRIPTION: `Editar las etiquetas para todas las páginas de reserva en este espacio de trabajo. Puede anular estas configuraciones en páginas de reserva específicas según sea necesario.`,
  INACTIVE: `Inactivo`,
  NAME_TITLE: `Nombre del Espacio de Trabajo`,
  NEW_WORKSPACE: `Nuevo Espacio de Trabajo`,
  PREVIEW_BUTTON: `Ver Página de Reserva y Editar Etiquetas`,
  SELECTED_WORKSPACES: `espacios de trabajo seleccionados`,
  TITLE: `Espacios de Trabajo`,
  TOOLTIP: `Administre espacios de trabajo que brinden a los departamentos o equipos configuraciones globales únicas.`,
  WHAT_TITLE_PART_1: `¿Cuál es la`,
  WHAT_TITLE_PART_2: `configuración de este espacio de trabajo?`,
  WHO_TITLE_PART_1: `¿Quién`,
  WHO_TITLE_PART_2: `puede usar este espacio de trabajo?`,
});
