import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
  extendTrialModalActions,
  orgsActions,
  orgsSelectors,
  expireTrialModalActions,
  deleteOrgModalActions,
  payLaterModalActions,
} from '../../../store/opsConsole/orgs';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Path } from '../../../routing';
import { ConfirmationModal, Preloader, SectionTitle } from '../../../components/common';
import { Menu } from 'primereact/menu';
import {
  OrgConvertTrialModal,
  OrgDeleteModal,
  OrgDetailsBasicInfo,
  OrgDetailsLicenses,
  OrgDetailsUsers,
  OrgExpireTrialModal,
  OrgExtendTrialModal,
  OrgLicenseModal,
} from '../../../components/opsConsole';
import labels from './labels';
import { OrgType } from '../../../API';
import { authentificationSelectors } from '../../../store/authentification';
import { navigationService } from '../../../services/NavigationService';

export const OrgDetails = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const isFetching = useSelector(orgsSelectors.selectIsFetching);
  const orgType = useSelector(orgsSelectors.selectTenantType);
  const tenantId = useSelector(orgsSelectors.selectTenantId);
  const tenantName = useSelector(orgsSelectors.selectTenantName);
  const tenantIsActive = useSelector(orgsSelectors.selectTenantIsActive);
  const isEdited = useSelector(orgsSelectors.selectIsEdited);
  const isMainAdmin = useSelector(authentificationSelectors.selectIsMainAdmin);
  const isMainAdminOrOperations = useSelector(authentificationSelectors.selectIsMainAdminOrOperations);

  const menu = useRef<Menu>(null);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  useEffect(() => {
    if (orgId) {
      dispatch(orgsActions.getOrgDetailsRequest(orgId));
    }
  }, []);

  const navigateBack = () => {
    navigationService.navigateTo(Path.OPSConsoleOrgs);
  };

  const handleBack = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      navigateBack();
    }
  };

  const handleSave = () => {
    dispatch(orgsActions.saveOrgDetailsRequest());
  };

  const menuItems = [
    {
      label: labels.extendTrial,
      command: () => {
        dispatch(extendTrialModalActions.openModal());
      },
      visible: orgType === OrgType.TRIAL,
    },
    {
      label: labels.expireTrial,
      command: () => {
        dispatch(expireTrialModalActions.openModal());
      },
      visible: orgType === OrgType.TRIAL && tenantIsActive,
    },
    {
      label: labels.convertToTest,
      command: () => {
        dispatch(orgsActions.convertToTestRequest());
      },
      visible: orgType === OrgType.TRIAL,
    },
    {
      label: labels.convertToCustomer,
      command: () => {
        dispatch(payLaterModalActions.openModal());
      },
      visible: orgType === OrgType.TRIAL,
    },
    {
      label: labels.delete,
      command: () => {
        dispatch(deleteOrgModalActions.openModal());
      },
      visible: isMainAdmin,
    },
  ];

  return (
    <div>
      {isFetching && <Preloader />}
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        cancelButtonProps={{ className: 'w-auto mr-0' }}
        confirmButtonProps={{ className: 'w-auto ml-4 mr-0' }}
        onConfirm={navigateBack}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle
          id="orgDetailsTitle"
          icon="pi-cloud"
          title={labels.title}
        />
      </div>
      <div className="card">
        <div className="flex justify-content-between align-items-center mb-3">
          <div className="flex align-items-center">
            <Button label={labels.back} icon="pi pi-arrow-left" onClick={handleBack} outlined />
            <div className="ml-3 text-xl">
              {tenantName} &gt; {labels.orgTitle}: {tenantId}
            </div>
          </div>
          <div className="flex align-items-center">
            <Button label={labels.save} onClick={handleSave} className="w-6rem" disabled={!isEdited} />
            {((orgType === OrgType.TRIAL && isMainAdminOrOperations) || isMainAdmin) && (
              <>
                <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
                <Button
                  text
                  icon="pi pi-cog text-xl"
                  rounded
                  onClick={(event) => menu.current?.toggle(event)}
                  className="ml-3"
                />
              </>
            )}
          </div>
        </div>

        <OrgDetailsBasicInfo />
        <OrgDetailsLicenses />
        <OrgDetailsUsers />
      </div>

      <OrgExtendTrialModal isFromDetails={true} />
      <OrgExpireTrialModal isFromDetails={true} />
      <OrgConvertTrialModal />
      <OrgDeleteModal />
      <OrgLicenseModal />
    </div>
  );
};
