import { AssignmentMode } from '../../API';
import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import { TeamRecord } from './types';

export const GET_TEAMS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 3000,
  message: t('TeamsToastsNotifications:GET_TEAMS_FAIL_MESSAGE'),
});

export const SAVE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('TeamsToastsNotifications:SAVE_TEAM_SUCCESS_MESSAGE'),
});
export const SAVE_TEAM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('TeamsToastsNotifications:SAVE_TEAM_ERROR_MESSAGE'),
});

export const DELETE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('TeamsToastsNotifications:DELETE_TEAM_SUCCESS_MESSAGE'),
});
export const DELETE_TEAM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('TeamsToastsNotifications:DELETE_TEAM_ERROR_MESSAGE'),
});

export const ACTIVATE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('TeamsToastsNotifications:ACTIVATE_TEAM_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('TeamsToastsNotifications:DEACTIVATE_TEAM_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_ACTIVATE_TEAM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('TeamsToastsNotifications:DEACTIVATE_ACTIVATE_TEAM_ERROR_MESSAGE'),
});

export const DEFAULT_TEAM: TeamRecord = {
  active: true,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  assignmentMode: AssignmentMode.ROUND_ROBIN,
};
