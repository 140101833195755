import { t } from '../../../i18n/i18n';

export default {
  attendees: t('ScheduledMeetings:ATTENDEES'),
  bookingPage: t('ScheduledMeetings:BOOKING_PAGE'),
  cancel: t('Common:CANCEL'),
  cancelReason: t('ScheduledMeetings:CANCEL_REASON'),
  description: t('Common:DESCRIPTION'),
  details: t('ScheduledMeetings:DETAILS'),
  editAttendeeLimit: t('ScheduledMeetings:EDIT_ATTENDEE_LIMIT'),
  email: t('Common:EMAIL'),
  hosts: t('ScheduledMeetings:HOSTS'),
  inviteeAnswers: t('ScheduledMeetings:INVITEE_ANSWERS'),
  locationTitle: t('ScheduledMeetings:LOCATION_TITLE'),
  markAsNoShow: t('ScheduledMeetings:MARK_AS_NO_SHOW'),
  markAsNoShowTooltip: t('ScheduledMeetings:MARK_AS_NO_SHOW_TOOLTIP'),
  meetingNotes: t('ScheduledMeetings:MEETING_NOTES'),
  no: t('Common:NO'),
  noLocationGiven: t('ScheduledMeetings:NO_LOCATION_GIVEN'),
  noShowLabel: t('ScheduledMeetings:NO_SHOW_LABEL'),
  noteForAttendees: t('ScheduledMeetings:NOTE_FOR_ATTENDEES'),
  reschedule: t('ScheduledMeetings:RESCHEDULE'),
  timeZone: t('Common:TIMEZONE'),
  viewAttendees: t('ScheduledMeetings:VIEW_ATTENDEES'),
  undoNoShow: t('ScheduledMeetings:UNDO_NO_SHOW'),
  yes: t('Common:YES'),
};
