export const OPSConsoleOrgs = Object.freeze({
  ACCOUNT_TITLE: `Cuenta`,
  ACTIVE: `Activo`,
  ADOPTION_PERCENT: `% Adoptado`,
  AMOUNT_TITLE: `ARR`,
  BILLING_EMAIL: `Correo electrónico de facturación`,
  BOOKED_NUMBER: `# Reservadas`,
  CUSTOMER: `Cliente`,
  DAYS_LEFT_TITLE: `Días restantes`,
  EXPIRED: `Expirado`,
  EXPIRE_TRIAL: `Expirar Prueba`,
  EXPORT: `Exportar`,
  EXTEND_TRIAL: `Extender Prueba`,
  FILTER_ALL_ORGS: `Todas las Organizaciones`,
  FILTER_EXPIRED_SOON: `Expira Pronto`,
  FILTER_LOW_ADOPT: `Baja Adopción`,
  LICENSES_NUMBER: `# de Licencias`,
  LAST_ACTIVE_TITLE: `Última Actividad`,
  ORG_ID_TITLE: `ID de la Organización`,
  PAYMENT_LATE: `Pago Atrasado`,
  SEARCH_PLACEHOLDER: `Buscar por nombre de cuenta, correo electrónico del usuario o ID de organización`,
  STATUS_TITLE: `Estado`,
  SUSPENDED: `Suspendido`,
  TERM_TITLE: `Plazo`,
  TERM_ALL: `Todos`,
  TERM_ANNUAL: `Anual`,
  TERM_TRIAL: `Pruebas`,
  TERM_MONTHLY: `Mensual`,
  TEST: `Prueba`,
  TITLE: `Organizaciones`,
  TRIAL: `Prueba`,
  TYPE_TITLE: `Tipo`,
  VIEW_ORG: `Ver Organización`,
});

export const OrgsToastsNotifications = Object.freeze({
  CREATE_PAY_LATER_LICENSE_SUCCESS_TOAST: `Suscripción creada correctamente`,
  CREATE_PAY_LATER_LICENSE_SUCCESS_MESSAGE: `Espere hasta 1 minuto`,
  GET_ORGS_FAIL_MESSAGE: `Error al obtener las organizaciones`,
  GET_ORG_DETAILS_FAIL_MESSAGE: `Error al obtener detalles de la organización`,
  SAVE_ORG_DETAILS_SUCCESS_MESSAGE: `Éxito al guardar detalles de la organización`,
  SAVE_ORG_DETAILS_FAIL_MESSAGE: `Error al guardar detalles de la organización`,
  DELETE_ORG_SUCCESS_MESSAGE: `Organización eliminada exitosamente`,
  DELETE_ORG_FAIL_MESSAGE: `Error al eliminar la organización`,
  EXTEND_LICENSE_SUCCESS_MESSAGE: `Éxito al extender la licencia`,
  EXTEND_LICENSE_FAIL_MESSAGE: `Error al extender la licencia`,
  EXPIRE_TRIAL_SUCCESS_MESSAGE: `Éxito al expirar la prueba`,
  EXPIRE_TRIAL_FAIL_MESSAGE: `Error al expirar la prueba`,
  CONVERT_TO_TEST_SUCCESS_MESSAGE: `Éxito al convertir a prueba`,
  CONVERT_TO_TEST_FAIL_MESSAGE: `Error al convertir a prueba`,
  CONVERT_TO_PAY_LATER_SUCCESS_MESSAGE: `La suscripción de pago posterior del cliente se creó con éxito`,
  CONVERT_TO_PAY_LATER_FAIL_MESSAGE: `Error en la solicitud de suscripción de pago posterior del cliente`,
});

export const OPSConsoleOrgDetails = Object.freeze({
  ACCOUNT_NAME: `Nombre de la Cuenta`,
  ADOPTION_PERCENT_WEEKLY: `% Adopción semanal`,
  ADOPTION_PERCENT_MONTHLY: `% Adopción mensual`,
  ACTIVE_USERS_NUMBER: `# de Usuarios Asignados`,
  AMOUNT_PER_USER: `Por Usuario / Por Mes`,
  BOOKED_NUMBER: `# de Reuniones Reservadas`,
  CONVERT_TO_CUSTOMER: `Convertir a Cliente`,
  CONVERT_TO_TEST: `Convertir a Prueba`,
  DAYS_REMAINING: `Días Restantes`,
  EDIT_ORG: `Editar Organización`,
  EXPIRATION_DATE: `Fecha de Expiración`,
  FEATURE_PRICING_TIER: `Característica/Nivel de Precios`,
  FULL_ACCESS: `Acceso Completo`,
  LICENSES_NUMBER: `# de Licencias`,
  ORG_ID: `ID de la Organización`,
  ORG_NOTE: `Nota de la Organización`,
  ORG_STATUS: `Estado de la Organización`,
  ORG_TITLE: `Organización`,
  ORG_TYPE: `Tipo de Organización`,
  PARTIAL_ACCESS: `Acceso Parcial`,
  PAYMENT_TERM: `Término de Pago`,
  PAYMENT_TITLE: `Pago`,
  PAYMENT_TYPE: `Tipo de Pago`,
  START_DATE: `Fecha de Inicio`,
  TERM: `Plazo`,
  TERMS_TITLE: `Términos`,
  TOTAL_AMOUNT: `ARR`,
  USER_ADOPTION: `Adopción de Usuario`,
});

export const OrgDetailsLicenses = Object.freeze({
  CREATE_LICENSE: `Crear Licencia`,
  LICENSES_NUMBER: `# de Licencias`,
  PURCHASED_DATE: `Fecha de Compra`,
  PURCHASED_NAME: `Nombre del Comprador`,
  TITLE: `Licencias`,
});

export const OrgDetailsUsers = Object.freeze({
  TERM_TRIAL: `Prueba`,
  VIEW: `Ver`,
  VIEW_ALL_PART1: `Ver Todos`,
  VIEW_ALL_PART2: `Registros`,
  VIEW_LESS: `Ver Menos`,
});

export const OrgExtendTrialModal = Object.freeze({
  EXTEND_DESCRIPTION: `Extender # de Días`,
  EXTEND_MESSAGE: `No se puede extender más. La prueba ya se ha extendido a 30 días.`,
  EXTEND_TITLE: `Extender`,
  SAVE_AND_EXTEND: `Guardar y Extender`,
  RULES_DESCRIPTION_PART1: `Las pruebas gratuitas son por 15 días.`,
  RULES_DESCRIPTION_PART2: `El personal de soporte puede extender por 15 días adicionales.`,
  RULES_DESCRIPTION_PART3: `Una vez vencido, el cliente aún puede iniciar sesión, pero solo verá la Página de Facturación.`,
  RULES_DESCRIPTION_PART4: `Anímelos a comprar solo 1 licencia mensualmente.`,
  RULES_TITLE: `Reglas`,
  TITLE: `Extender Prueba`,
});

export const OrgExpireTrialModal = Object.freeze({
  EXPIRE_DESCRIPTION: `Sí, deseo forzar la expiración de esta prueba.`,
  EXPIRE_NOW: `Expirar Ahora`,
  EXPIRE_TITLE: `Expirar`,
  RULES_DESCRIPTION_PART1: `Si nota que un competidor o alguien está utilizando una prueba que no debería, puede forzar la expiración de la prueba.`,
  RULES_DESCRIPTION_PART2: `Recibirán un error al iniciar sesión, indicándoles que se comuniquen con ventas o soporte.`,
  RULES_TITLE: `Reglas`,
  TITLE: `Expirar Prueba`,
});

export const OrgConvertTrialModal = Object.freeze({
  AMOUNT_PER_USER: `Por Usuario / Por Mes`,
  ANNUAL_CONTRACT: `Contrato Anual`,
  BILLING_TITLE: `Facturación - Factura del Año 1`,
  CONTRACT_END_DATE: `Fecha de Finalización del Contrato`,
  CONTRACT_START_DATE: `Fecha de Inicio del Contrato`,
  CONVERT_TO_CUSTOMER: `Convertir a Cliente`,
  IMMEDIATE_PROCESS_DESCRIPTION: `Anime a los clientes a pagar con tarjeta de crédito en la aplicación haciendo clic en "Comprar Licencias" en la lista de usuarios, lo que los convertirá automáticamente.`,
  IMMEDIATE_PROCESS_TITLE: `Proceso Inmediato`,
  INVOICE_DUE_DATE: `Fecha de Vencimiento de la Factura`,
  MANUAL_PROCESS_DESCRIPTION_PART1: `Solo las operaciones pueden realizar esta función.`,
  MANUAL_PROCESS_DESCRIPTION_PART2: `Para >30 licencias, plazo anual, los clientes pueden pagar mediante ACH, transferencia bancaria o cheque.`,
  MANUAL_PROCESS_DESCRIPTION_PART3: `Los clientes deben haber firmado el contrato (y el PO es opcional).`,
  MANUAL_PROCESS_TITLE: `Proceso Manual`,
  NET_TERMS: `Términos NET`,
  RULES_TITLE: `Reglas`,
  SALESFORCE_OPPORTUNITY: `Oportunidad Salesforce`,
  SALESFORCE_OPPORTUNITY_PLACEHOLDER: `Ingrese el hipervínculo aquí`,
  TITLE: `Convertir Prueba a Cliente Pagador`,
  TOTAL_AMOUNT: `Total Anual`,
  USER_LICENSES_NUMBER: `# de Licencias de Usuario`,
  WARNING_MESSAGE: `Esta acción no se puede deshacer.`,
});

export const OrgDeleteModal = Object.freeze({
  TITLE: `Eliminar organización`,
  DESCRIPTION: `Esta organización será eliminada con todos los datos`,
});
