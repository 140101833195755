import { License, OrgType, Staff, StaffRoles, StaffStatus, Tenant } from '../../../API';
import { t } from '../../../i18n/i18n';
import { ToastNotificationOptions } from '../../notifications';
import { UserStatus } from '../../users/typings';

export const STAFF_ROLE_OPTIONS = [
  { value: StaffRoles.MAIN_ADMIN, label: t('OPSConsoleStaff:ADMIN_TITLE') },
  { value: StaffRoles.SUPPORT, label: t('OPSConsoleStaff:SUPPORT_TITLE') },
  { value: StaffRoles.OPERATIONS, label: t('OPSConsoleStaff:OPERATIONS_TITLE') },
];
export const DEFAULT_STAFF_ROLE = StaffRoles.OPERATIONS;

export const STAFF_STATUS_OPTIONS = [
  { value: StaffStatus.ACTIVE, label: t('OPSConsoleStaff:ACTIVE') },
  { value: StaffStatus.INACTIVE, label: t('OPSConsoleStaff:INACTIVE') },
];

export const NEW_DEFAULT_STAFF_RECORD: Staff = {
  __typename: 'Staff',
  userName: '',
  email: '',
  roleName: DEFAULT_STAFF_ROLE,
  status: StaffStatus.ACTIVE,
  createdAt: '',
  updatedAt: '',
};

export const GET_STAFF_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('StaffToastsNotifications:GET_STAFF_FAIL_MESSAGE'),
});

export const SAVE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('StaffToastsNotifications:SAVE_STAFF_SUCCESS_MESSAGE'),
});

export const SAVE_STAFF_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('StaffToastsNotifications:SAVE_STAFF_FAIL_MESSAGE'),
});

export const CHANGE_STAFF_STATUS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('StaffToastsNotifications:CHANGE_STAFF_STATUS_FAIL_MESSAGE'),
});

export const ACTIVATE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('StaffToastsNotifications:ACTIVATE_STAFF_SUCCESS_MESSAGE'),
});

export const DEACTIVATE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('StaffToastsNotifications:DEACTIVATE_STAFF_SUCCESS_MESSAGE'),
});

export const DELETE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('StaffToastsNotifications:DELETE_STAFF_SUCCESS_MESSAGE'),
});

export const DELETE_STAFF_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('StaffToastsNotifications:DELETE_STAFF_FAIL_MESSAGE'),
});

export const VIEW_AS_USER_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 4000,
  message: t('StaffToastsNotifications:VIEW_AS_USER_FAIL_MESSAGE'),
});

