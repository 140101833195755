import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { adminRoles } from '../../shared/backend/constants';

const rolesState = (state: State) => state.roles;

const selectIsFetching = createSelector(rolesState, (admin) => admin.isFetching);
const selectError = createSelector(rolesState, (admin) => admin.error);
const selectRoles = createSelector(rolesState, (admin) => admin.roles);
const selectSelectedRole = createSelector(rolesState, (admin) => admin.role);
const selectSearchedRoles = (searchString: string) =>
  createSelector(selectRoles, (roles) => 
    Array.isArray(roles) ? roles
      .filter((role) => role.name.toLowerCase().includes(searchString.toLowerCase()))
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt)) : []
  );

const selectStandardRoles = createSelector(selectRoles, (roles) => roles.filter((role) => !role.isCustom));

const selectStandardUser = createSelector(selectStandardRoles, (roles) => roles.find((role) => role.name === adminRoles.standardUser));
const selectStandardUserId = createSelector(selectStandardUser, (role) => role?.id || '');

const selectWorkspaceAdmin = createSelector(selectRoles, (roles) => roles.find((role) => role.name === adminRoles.workspaceAdmin));
const selectWorkspaceId = createSelector(selectWorkspaceAdmin, (role) => role?.id || '');

const selectSuperAdmin = createSelector(selectRoles, (roles) => roles.find((role) => role.name === adminRoles.superAdmin));
const selectSuperAdminId = createSelector(selectSuperAdmin, (role) => role?.id || '');

const selectAdminsRoles = createSelector(selectStandardRoles, (roles) => roles.filter((role) => role.name !== adminRoles.standardUser));
const selectAdminsRolesId = createSelector(selectAdminsRoles, (roles) => roles.map(role => role.id));

export const rolesSelectors = {
  selectIsFetching,
  selectError,
  selectRoles,
  selectSelectedRole,
  selectSearchedRoles,
  selectStandardUser,
  selectStandardUserId,
  selectAdminsRolesId,
  selectSuperAdmin,
  selectSuperAdminId,
  selectWorkspaceId
};
