import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { t } from '../../i18n/i18n';
import { DEFAULT_BOOKING_PAGE_HOW } from './constants';
import { authentificationSelectors } from '../authentification';
import { ImageType } from '../../types/types';
import { globalSelectors } from '../global';

const workspacesState = (state: State) => state.workspaces;

const selectIsFetching = createSelector(workspacesState, (workspaces) => workspaces.isFetching);
const selectIsFetchingAvatar = createSelector(workspacesState, (bookingPages) => bookingPages.isFetchingAvatar);
const selectIsFetchingBackground = createSelector(workspacesState, (bookingPages) => bookingPages.isFetchingBackground);

const selectWorkspaces = createSelector(workspacesState, (workspaces) => workspaces.workspaces);
//const selectTenantStyle = createSelector(workspacesState, (workspaces) => workspaces.tenantStyle);
const selectWorkspace = createSelector(workspacesState, (workspaces) => workspaces.workspace);
//const selectSelectedWorkspaces = createSelector(workspacesState, (workspaces) => workspaces.selectedWorkspaces);

const selectWorkspaceById = (id: string) =>
  createSelector(selectWorkspaces, (workspaces) => workspaces.find((workspace) => workspace.id === id));
const selectWorkspacesByIds = (id: string[]) =>
  createSelector(selectWorkspaces, (workspaces) => workspaces.filter((workspace) => id && id.includes(workspace.id)));
const selectFilteredWorkspacesByIds = (id: string[]) =>
  createSelector(selectWorkspacesByIds(id), globalSelectors.selectSearchString, (workspaces, searchString) =>
    workspaces
      .filter((workspace) => workspace.name?.toLowerCase().includes(searchString.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))
  );
const selectWorkspacesNamesById = createSelector(selectWorkspaces, (workspaces) =>
  workspaces.reduce((namesById, workspace) => {
    if (workspace.id) namesById[workspace.id] = workspace.name;
    return namesById;
  }, {} as Record<string, string>)
);

const selectName = createSelector(selectWorkspace, (workspace) => workspace.name);
const selectId = createSelector(selectWorkspace, (workspace) => workspace.id);
const selectIsActive = createSelector(selectWorkspace, (workspace) => workspace.isActive);
const selectWorkspaceStyle = createSelector(selectWorkspace, (workspace) => workspace.style || {});
const selectLookAndFeel = createSelector(selectWorkspaceStyle, (style) => style?.lookAndFeel);
const selectLogo = createSelector(selectWorkspaceStyle, (style) => style?.avatar);
const selectBackground = createSelector(selectWorkspaceStyle, (style) => style?.backgroundImage);
const selectOpacity = createSelector(selectWorkspaceStyle, (style) => style?.opacity);
const selectCss = createSelector(selectWorkspaceStyle, (style) => style?.css);
const selectFooter = createSelector(selectWorkspaceStyle, (style) => style?.footer);

const selectAdminList = createSelector(selectWorkspace, (workspace) => workspace.adminList || []);
const selectUserList = createSelector(selectWorkspace, (workspace) => workspace.userList || []);
const selectIsPhoneRequired = createSelector(selectWorkspace, (workspace) => workspace.isPhoneRequired);
const selectLabels = createSelector(selectWorkspace, (workspace) => workspace.labels || DEFAULT_BOOKING_PAGE_HOW);

const selectAdminEmailsList = createSelector(selectAdminList, (adminList) => adminList?.map((record) => record.email));
const selectUserEmailsList = createSelector(selectUserList, (userList) => userList?.map((record) => record.email));
const selectAdminCount = createSelector(selectAdminList, (adminList) => adminList?.length || 0);
const selectUserCount = createSelector(selectUserList, (userList) => userList?.length || 0);

const selectWhatStepInfo = createSelector(
  selectName,
  selectIsActive,
  (name, isActive) => `${name}, ${isActive ? t('Workspaces:ACTIVE') : t('Workspaces:INACTIVE')}`
);

const selectWhoStepInfo = createSelector(
  selectAdminCount,
  selectUserCount,
  (adminCount, userCount) =>
    `${adminCount} ${t('WorkspaceWhoStep:ADMINS_TITLE')}, ${userCount} ${t('WorkspaceWhoStep:USERS_TITLE')}`
);

const selectIsExistingWorkspace = createSelector(selectWorkspace, (workspace) => workspace.id !== '');
const selectIsSelectedWorkspaceEmpty = createSelector(selectWorkspace, (workspace) => !Object.keys(workspace).length);

const selectWorkspaceOptions = createSelector(selectWorkspaces, (workspaces) =>
  workspaces.map((workspace) => ({ id: workspace.id, name: workspace.name }))
);
const selectWorkspaceOptionsByIds = (id: string[]) =>
  createSelector(selectWorkspacesByIds(id), (workspaces) => {
    return workspaces.map((workspace) => ({ id: workspace.id, name: workspace.name }));
  });

const selectWorkspaceUserRecords = createSelector(selectAdminList, selectUserList, (admins, users) => [
  ...admins,
  ...users,
]);
const selectUsersWithoutOtherWorkspace = createSelector(selectWorkspaceUserRecords, (users) =>
  users.filter((user) => user.workspaceIds && user.workspaceIds?.length <= 1)
);

const selectCurrentWorkspaceData = createSelector(
  authentificationSelectors.selectWorkspace,
  selectWorkspaces,
  (workspaceId, workspaces) => workspaces.find((workspace) => workspace.id === workspaceId)
);
const selectIsWorkspacePhoneRequired = createSelector(
  selectCurrentWorkspaceData,
  (workspace) => workspace?.isPhoneRequired || false
);
const selectCurrentWorkspaceName= createSelector(
  selectCurrentWorkspaceData,
  (workspace) => workspace?.name || ''
);

const selectIsNameDuplicate = createSelector(selectWorkspaces, selectId, selectName, (workspaces, id, name) =>
  Boolean(workspaces.find((workspace) => workspace.id !== id && workspace.name === name))
);

const selectIsNameValid = createSelector(
  selectName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectIsWhatStepValid = createSelector(selectIsNameValid, (isNameValid) => isNameValid);

const selectImages = createSelector(workspacesState, (bookingPages) => bookingPages.images);

const selectFinalLogo = createSelector(selectImages, selectLogo, (images, bookingPageLogo) => {
  const logos = images.filter((image) => image.imageType === ImageType.avatar);
  return logos.length ? logos[logos.length - 1].imageLink : bookingPageLogo;
});
const selectFinalBackground = createSelector(selectImages, selectBackground, (images, bookingPageBackground) => {
  const logos = images.filter((image) => image.imageType === ImageType.backgroundImage);
  return logos.length ? logos[logos.length - 1].imageLink : bookingPageBackground;
});

const selectFinalLogoImage = createSelector(selectFinalLogo, (logo) => logo);
const selectFinalBackgroundImage = createSelector(selectFinalBackground, (background) => background);
const selectStyleForPreview = createSelector(
  selectWorkspaceStyle,
  selectFinalLogoImage,
  selectFinalBackground,
  (workspaceStyle, avatar, backgroundImage) => ({ ...workspaceStyle, avatar, backgroundImage })
);

export const workspacesSelectors = {
  selectIsFetching,
  selectIsFetchingAvatar,
  selectIsFetchingBackground,

  selectWorkspaces,
  selectWorkspace,

  selectWorkspaceById,
  selectWorkspacesNamesById,

  selectName,
  selectId,
  selectIsActive,
  selectWorkspaceStyle,
  selectLookAndFeel,
  selectLogo,
  selectBackground,
  selectOpacity,
  selectCss,
  selectFooter,
  selectFinalLogoImage,
  selectFinalBackgroundImage,
  selectStyleForPreview,

  selectAdminList,
  selectUserList,
  selectIsPhoneRequired,
  selectAdminEmailsList,
  selectUserEmailsList,
  selectLabels,

  selectWhatStepInfo,
  selectWhoStepInfo,
  selectIsExistingWorkspace,
  selectIsSelectedWorkspaceEmpty,
  selectIsNameDuplicate,
  selectWorkspaceOptions,
  selectWorkspacesByIds,
  selectFilteredWorkspacesByIds,
  selectWorkspaceOptionsByIds,

  selectUsersWithoutOtherWorkspace,
  selectIsWorkspacePhoneRequired,
  selectCurrentWorkspaceData,
  selectCurrentWorkspaceName,

  selectIsWhatStepValid,
};
