import { IntegrationType } from '../../API';
import zoomLogo from '../../assets/images/ZoomLogo.svg';
import googleMeetLogo from '../../assets/images/GoogleMeetLogo.svg';
import microsoftTeamsLogo from '../../assets/images/MicrosoftTeamsLogo.svg';
import googleCalendarLogo from '../../assets/images/GoogleCalendarLogo.svg';
import microsoft365Logo from '../../assets/images/Microsoft365Logo.svg';
import { IntegrationFilter } from './types';
import { t } from '../../i18n/i18n';

export const integrationLabels = {
  [IntegrationType.GOOGLE_CALENDAR]: t('IntegrationPage:GOOGLE_CALENDAR_TITLE'),
  [IntegrationType.GOOGLE_MEET]: t('IntegrationPage:GOOGLE_MEET_TITLE'),
  [IntegrationType.MICROSOFT_CALENDAR]: t('IntegrationPage:MICROSOFT_CALENDAR_TITLE'),
  [IntegrationType.MICROSOFT_TEAMS]: t('IntegrationPage:MICROSOFT_TEAMS_TITLE'),
  [IntegrationType.ZOOM]: t('IntegrationPage:ZOOM_TITLE'),
};

const integrationDescription = {
  [IntegrationType.GOOGLE_CALENDAR]: t('IntegrationPage:GOOGLE_CALENDAR_DESC'),
  [IntegrationType.GOOGLE_MEET]: t('IntegrationPage:GOOGLE_MEET_DESC'),
  [IntegrationType.MICROSOFT_CALENDAR]: t('IntegrationPage:MICROSOFT_CALENDAR_DESC'),
  [IntegrationType.MICROSOFT_TEAMS]: t('IntegrationPage:MICROSOFT_TEAMS_DESC'),
  [IntegrationType.ZOOM]: t('IntegrationPage:ZOOM_DESC'),
};

export const integrationIcon = {
  [IntegrationType.GOOGLE_CALENDAR]: googleCalendarLogo,
  [IntegrationType.GOOGLE_MEET]: googleMeetLogo,
  [IntegrationType.MICROSOFT_CALENDAR]: microsoft365Logo,
  [IntegrationType.MICROSOFT_TEAMS]: microsoftTeamsLogo,
  [IntegrationType.ZOOM]: zoomLogo,
};

export const DEFAULT_INTEGRATION_STATE = Object.values(IntegrationType).map((integration) => ({
  type: integration,
  label: integrationLabels[integration],
  icon: integrationIcon[integration],
  description: integrationDescription[integration],
}));

export const VIDEO_CONFERENCES = [
  IntegrationType.GOOGLE_MEET,
  IntegrationType.MICROSOFT_TEAMS,
  IntegrationType.ZOOM,
];

export const INTEGRATION_CATEGORIES = {
  [IntegrationFilter.ALL]: '*',
  [IntegrationFilter.CALENDARS]: [IntegrationType.GOOGLE_CALENDAR, IntegrationType.MICROSOFT_CALENDAR],
  [IntegrationFilter.VIDEO_CONFERENCE]: VIDEO_CONFERENCES,
};

export const INTEGRATION_FILTER_LABEL = {
  [IntegrationFilter.ALL]: t('IntegrationPage:FILTER_ALL'),
  [IntegrationFilter.CALENDARS]: t('IntegrationPage:FILTER_CALENDARS'),
  [IntegrationFilter.VIDEO_CONFERENCE]: t('IntegrationPage:FILTER_VIDEO_CONFERENCE'),
};
