import { GetUserDetailsPayload, UserDetails } from './types';
import { APIPostRequest } from "../../utils/reduxUtils";

export const getUsers = async () => {
  try {
    const response = (await APIPostRequest('/api/js/opsconsole/getUserDetailsList', {
    })) as UserDetails[];

    if (response) {
      return response;
    } else {
      throw new Error('Users not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getUserDetails = async (payload: GetUserDetailsPayload) => {
  try {
    const response = (await APIPostRequest('/api/public/opsconsole/getUserDetails', {
      ...payload,
    })) as UserDetails;

    if (response) {
      return response;
    } else {
      throw new Error('User Details not found');
    }
  } catch (error: unknown) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};
