import { t } from '../../../i18n/i18n';

export default {
  title: t('Register:TITLE'),
  subtitle: t('Register:SUBTITLE'),
  comment: t('Register:COMMENT'),
  googleSignUp: t('Authorise:GOOGLE_SING_UP'),
  microsoftSignUp: t('Authorise:MICROSOFT_SING_UP'),
  haveAnAccount: t('Authorise:HAVE_AN_ACCOUNT'),
  login: t('Common:LOGIN'),
  register: t('Common:REGISTER'),
};
