import { BookingPageLabelsInput, BookingPageStyleInput, LookAndFeelInput } from '../../API';
import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import { WorkspaceData } from './types';
import urlConstants from '../../shared/JSON/urlConstants.json';

export const DEFAULT_WORKSPACE_NAME = t('Workspaces:DEFAULT_WORKSPACE');
export const NEW_WORKSPACE_NAME = t('Workspaces:NEW_WORKSPACE');

export const DEFAULT_OPACITY = 60;

export const DEFAULT_BOOKING_PAGE_HOW: BookingPageLabelsInput = {
  eventNameLabel: t('DefaultBookingPage:BOOK_A_MEETING'),
  detailsLabel: t('DefaultBookingPage:MEETING_DETAILS'),
  dateLabel: t('DefaultBookingPage:SELECT_DATE'),
  timeLabel: t('DefaultBookingPage:SELECT_TIME'),
  informationLabel: t('DefaultBookingPage:YOUR_INFORMATION'),
  additionalLabel: t('DefaultBookingPage:MORE_OPTIONS'),
  cancelLabel: t('DefaultBookingPage:CANCEL'),
  bookButtonLabel: t('DefaultBookingPage:BOOK_MEETING_BUTTON'),
  updateButtonLabel: t('DefaultBookingPage:SAVE_MEETING_BUTTON'),
  bookAnotherButtonLabel: t('DefaultBookingPage:BOOK_ANOTHER_MEETING_BUTTON'),
  cancelButtonLabel: t('DefaultBookingPage:CANCEL_MEETING_BUTTON'),
  bookTitle: t('DefaultBookingPage:MEETING_BOOKED_TITLE'),
  bookDescription: t('DefaultBookingPage:MEETING_BOOKED_DESCRIPTION'),
  rescheduleTitle: t('DefaultBookingPage:MEETING_RESCHEDULED_TITLE'),
  rescheduleDescription: t('DefaultBookingPage:MEETING_RESCHEDULED_DESCRIPTION'),
  cancelTitle: t('DefaultBookingPage:MEETING_CANCELED_TITLE'),
  cancelDescription: t('DefaultBookingPage:MEETING_CANCELED_DESCRIPTION'),
};

export const DEFAULT_LOOK_AND_FEEL: LookAndFeelInput = {
  siteColorPrimary: '#FFFFFF',
  siteColorBackground: '#F5F5F5',
  siteColorButtons: '#2196F3',
  fontColorHeader: '#495057',
  fontColorSubHeader: '#495057',
  fontColorMainText: '#495057',
  fontHeader: 'Nunito',
  fontSubheader: 'Nunito',
  fontMainText: 'Nunito',
};

export const DEFAULT_STYLE: BookingPageStyleInput = {
  avatar: null,
  backgroundImage: null,
  lookAndFeel: DEFAULT_LOOK_AND_FEEL,
  opacity: DEFAULT_OPACITY,
  css: null,
  footer: `<a href="${urlConstants.NAME_URL_SITE}" target="_blank">Powered by SUMO</a>`,
};

export const DEFAULT_WORKSPACE: WorkspaceData = {
  id: '',
  name: NEW_WORKSPACE_NAME,
  isActive: true,
  adminList: [],
  userList: [],
  isPhoneRequired: true,
  labels: DEFAULT_BOOKING_PAGE_HOW,
  style: DEFAULT_STYLE,
};

export const GET_WORKSPACES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('WorkspaceToastsNotifications:GET_WORKSPACES_ERROR_MESSAGE'),
});

export const ACTIVATE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('WorkspaceToastsNotifications:ACTIVATE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const INACTIVATE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('WorkspaceToastsNotifications:INACTIVATE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const ACTIVATE_INACTIVATE_WORKSPACE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('WorkspaceToastsNotifications:ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE'),
});

export const DELETE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('WorkspaceToastsNotifications:DELETE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const DELETE_WORKSPACE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('WorkspaceToastsNotifications:DELETE_WORKSPACE_ERROR_MESSAGE'),
});

export const SAVE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('WorkspaceToastsNotifications:SAVE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const SAVE_WORKSPACE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('WorkspaceToastsNotifications:SAVE_WORKSPACE_ERROR_MESSAGE'),
});
