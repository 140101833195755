import { t } from '../../../i18n/i18n';

export default {
  title: t('Login:TITLE'),
  description: t('Login:DESCRIPTION'),
  google: t('Common:GOOGLE'),
  googleAccount: t('Login:GOOGLE_ACCOUNT'),
  googleSwitch: t('Login:GOOGLE_SWITCH'),
  googleSwitchDesc: t('Login:GOOGLE_SWITCH_DESC'),
  googleAccountSwitch: t('Login:GOOGLE_ACCOUNTS_SWITCH'),
  microsoft: t('Common:MICROSOFT'),
  microsoftAccount: t('Login:MICROSOFT_ACCOUNT'),
  microsoftSwitch: t('Login:MICROSOFT_SWITCH'),
  microsoftSwitchDesc: t('Login:MICROSOFT_SWITCH_DESC'),
  microsoftAccountSwitch: t('Login:MICROSOFT_ACCOUNTS_SWITCH'),
  contactUs: t('Login:CONTACT_US'),
  unlink: t('Common:UNLINK'),
  saveChanges: t('Common:SAVE_CHANGES'),
  switchAccountMessage: t('Login:SWITCH_ACCOUNT_MESSAGE'),
  switchDescriptionPart1: t('Login:SWITCH_DESCRIPTION_PART1'),
  switchDescriptionPart2: t('Login:SWITCH_DESCRIPTION_PART2'),
  connected: t('Common:CONNECTED'),
  yes: t('Common:YES'),
  no: t('Common:NO'),
};
