export const AuthentificationToastsNotifications = Object.freeze({
  CHOOSE_AN_ACCOUNT_ERROR_TOAST: `Erro na solicitação de escolha de conta`,
  DELETE_USER_ERROR_MESSAGE: `Erro na solicitação de exclusão de usuário`,
  DELETE_USER_SUCCESS_MESSAGE: `Solicitação de exclusão de usuário bem-sucedida`,
  GET_USER_AUTH_ERROR_MESSAGE: `Erro na solicitação de login do usuário`,
  GET_USER_LOGIN_ERROR_MESSAGE: `Não foi encontrado nenhum utilizador com esse e-mail`,
  GET_USER_LOGOUT_ERROR_MESSAGE: `Erro na solicitação de logout do usuário`,
  GET_MAIN_DATA_ERROR_MESSAGE: `Falha na solicitação de autenticação`,
  ROLE_DEACTIVATED_ERROR_MESSAGE: `O papel foi desativado. Por favor, entre em contato com seu administrador SUMO1.`,
  USER_DEACTIVATED_ERROR_MESSAGE: `O usuário foi desativado. Por favor, entre em contato com seu administrador SUMO1.`,
  TRIAL_LIMIT_ERROR_MESSAGE: `Você excedeu o limite de 30 itens para a versão de teste`,
  INVITE_EXPIRED_ERROR_TITLE: `Convite Expirado`,
  INVITE_EXPIRED_ERROR_MESSAGE_PART1: `Seu convite expirou. Por favor, entre em contato com seu administrador para obter um novo link ou tente `,
  INVITE_EXPIRED_ERROR_MESSAGE_PART2: `se inscrever sem um convite`,
  SWITCH_ACCOUNT_ERROR_MESSAGE: `Outra conta já está associada a este e-mail`,
});

export const Authorise = Object.freeze({
  DOESNT_HAVE_AN_ACCOUNT: `Não tem uma conta?`,
  GOOGLE_SING_IN: `Entrar com o Google`,
  GOOGLE_SING_UP: `Registrar com o Google`,
  HAVE_AN_ACCOUNT: `Já tem uma conta?`,
  MICROSOFT_SING_IN: `Entrar com a Microsoft`,
  MICROSOFT_SING_UP: `Registrar com a Microsoft`,
  SUBTITLE: `Comece a usar o seu link pessoal gratuito para agendamentos`,
  SUBTITLE_SING_IN: `Faça login no seu hub de agendamentos.`,
  TITLE_SING_IN: `Bem-vindo de volta ao SUMO`,
  CONNECTING_TO_GOOGLE: `Conectando ao Google...`,
  CONNECTING_TO_MICROSOFT: `Conectando ao Microsoft...`,
  SYNCING_CALENDAR: `Sincronizando o calendário...`,
});

export const DialogLeaveAccount = Object.freeze({
  ACCEPT_TEXT: 'Gostaria de aceitar este convite e sair da outra conta do SUMO1?',
  INVITE_TEXT_PART_1: `Você foi convidado por`,
  INVITE_TEXT_PART_2: `para se juntar ao SUMO1, mas seu e-mail já está associado a outra conta do SUMO1.`,
  LAST_SUPER_ADMIN_NOTE: `Você é o único Super Administrador na organização. Você deve primeiro fazer login na sua organização existente e transferir o cargo de "Super Administrador" para outro usuário antes de aceitar este convite. Entre em contato com o suporte da SUMO para obter assistência.`,
  TITLE: `Sair da conta`,
});

export const DialogUserAccounts = Object.freeze({
  ACCEPT_TEXT: `Gostaria de aceitar este convite e sair da outra conta do SUMO1?`,
  INVITE_FROM: `Convite de:`,
  INVITE_TEXT: `Você foi convidado a se juntar ao SUMO1, mas está tentando se cadastrar para uma nova conta do SUMO1.`,
  SELECT_TEXT: `Selecione abaixo se deseja aceitar este convite em vez de criar uma nova conta corporativa para o SUMO1.`,
  STAY_IN_MY_ACCOUNT: `Ficar na minha conta`,
  TITLE: `Aceitar convite`,
});

export const Register = Object.freeze({
  COMMENT: `*O SUMO1 não requer uma licença do Salesforce.`,
  SUBTITLE: `Agendamento online e muito mais`,
  TITLE: `Teste gratuito de 15 dias`,
});
