import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  connect: t('Common:CONNECT'),
  complete: t('Common:COMPLETE'),
  completedButtonLabel: t('QuickSetupVideoConferenceStep:COMPLETED_BUTTON_LABEL'),
  description: t('QuickSetupVideoConferenceStep:DESCRIPTION'),
  googleMeetTitle: t('QuickSetupVideoConferenceStep:GOOGLE_MEET_TITLE'),
  message: t('QuickSetupVideoConferenceStep:MESSAGE'),
  microsoftTeamsTitle: t('QuickSetupVideoConferenceStep:MICROSOFT_TEAMS_TITLE'),
  next: t('Common:NEXT'),
  title: t('QuickSetupVideoConferenceStep:TITLE'),
  zoomTitle: t('QuickSetupVideoConferenceStep:ZOOM_TITLE'),
  zoomStep: [
    t('QuickSetupVideoConferenceStep:ZOOM_STEP_1'),
    t('QuickSetupVideoConferenceStep:ZOOM_STEP_2'),
    t('QuickSetupVideoConferenceStep:ZOOM_STEP_3'),
    t('QuickSetupVideoConferenceStep:ZOOM_STEP_4'),
    t('QuickSetupVideoConferenceStep:ZOOM_STEP_5'),
  ],
};
