import { Auth } from 'aws-amplify';
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

interface UserNavigationContextType {
  isInitialVisit: boolean;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

const UserNavigationContext = createContext<UserNavigationContextType | undefined>(undefined);

export const UserNavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isInitialVisit, setIsInitialVisit] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const location = useLocation();
  const initialPath = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== initialPath.current) {
      setIsInitialVisit(false);
    }
  }, [location]);

  useEffect(() => {
    // listen for storage changes
    const handleStorageChange = () => {
      Auth.currentAuthenticatedUser()
        .then(() => setIsAuthenticated(true))
        .catch(() => setIsAuthenticated(false));
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <UserNavigationContext.Provider value={{ isInitialVisit, isAuthenticated, setIsAuthenticated }}>
      {children}
    </UserNavigationContext.Provider>
  );
};

export const useUserNavigation = () => {
  const context = useContext(UserNavigationContext);
  if (!context) {
    throw new Error('useUserNavigation must be used within a UserNavigationProvider');
  }
  return context;
};
