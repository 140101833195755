import { call, put, select, takeLatest } from 'redux-saga/effects';
import { CreateUserDataInput, IntegrationInput, IntegrationType, UserRecordType, UserSettingsInput } from '../../API';
import { LinkStatus } from './typings';
import {
  saveUserSettings,
  checkUserLink,
  uploadProtectedFile,
  updateUserLink,
  connectToZoom,
  getUserProfile,
} from './service';
import { UserSettingsActionTypes, userSettingsActions } from './actions';
import { userSettingsSelectors } from './selectors';
import { authenticationSagas, authentificationActions, authentificationSelectors } from '../authentification';
import { notificationsActions } from '../notifications';
import {
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST,
  CONNECT_ZOOM_ERROR_TOAST,
  CONNECT_ZOOM_SUCCESS_TOAST,
  SAVE_USER_SETTINGS_ERROR_TOAST,
  SAVE_USER_SETTINGS_SUCCESS_TOAST,
  UPDATE_USER_LINK_ERROR_TOAST,
  UPDATE_USER_LINK_SUCCESS_TOAST,
  UserSettingsKeys,
} from './constants';
import { createSelector } from 'reselect';
import { handleServiceError } from '../utils/reduxUtils';
import { Path } from '../../routing';

const selectSaveUserDataInputRequest = createSelector(
  authentificationSelectors.selectTenantId,
  authentificationSelectors.selectUserId,
  authentificationSelectors.selectLink,
  userSettingsSelectors.selectUserSettings,
  (tenantId, userId, link, userSettings) => ({
    userId,
    tenant: tenantId,
    link,
    recordType: UserRecordType.PROFILE,
    userSettings: userSettings
  })
);

function* getUserSettingsSaga() {
  try {
    const userId: string = yield select(authentificationSelectors.selectUserId);

    const response: CreateUserDataInput = yield call(getUserProfile, userId);
    if (response?.statistics) {
      yield put(userSettingsActions.setStatistics(response.statistics));
    }
    if (response?.userSettings) {
      const userSettingsWithParsedAndFilteredIntegrations = {
        ...response.userSettings,
        integrations: response.userSettings.integrations
          ?.filter(integration => integration?.type !== undefined) // Explicitly filter out undefined types
          .map(integration => ({
            type: integration?.type as IntegrationType, // Cast type to IntegrationType, as it's now guaranteed to be defined
            settings: integration?.settings && JSON.parse(integration.settings), // until is not done SO-1437 - Transfer Tenant/AdminData/UserData to Java
          })) || [],
      };

      yield put(userSettingsActions.getUserSettingsSuccess(userSettingsWithParsedAndFilteredIntegrations));
    } else {
      throw new Error('UserSettings not found');
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.getUserSettingsFail(error?.toString()));
    yield put(authentificationActions.logoutUserRequest(Path.Landing));
  }
}

function* saveUserSettingsSaga() {
  try {
    const input: CreateUserDataInput = yield select(selectSaveUserDataInputRequest);

    yield call(saveUserSettings, input);

    yield put(userSettingsActions.saveUserSettingsSuccess());
    yield put<any>(notificationsActions.showToast(SAVE_USER_SETTINGS_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(userSettingsActions.saveUserSettingsFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_USER_SETTINGS_ERROR_TOAST);
  }
}

function* checkUserLinkSaga({ link }: any) {
  try {
    const linkStatus: LinkStatus = yield call(checkUserLink, link);
    yield put(userSettingsActions.checkUserLinkSuccess(linkStatus));

    if (linkStatus === LinkStatus.FREE) {
      yield put(authentificationActions.updateUserDataCore({ link }));
    }
    return linkStatus;
  } catch (error: unknown) {
    yield put(userSettingsActions.checkUserLinkFail(error?.toString()));
  }
}

function* connectExternalCalendarSaga({ payload }: any) {
  try {
    yield call(authenticationSagas.thirdPartyAuth, { payload: { ...payload, updateIntegration: true } });
    yield call(getUserSettingsSaga);
    yield put(userSettingsActions.connectExternalCalendarSuccess());
    yield put<any>(notificationsActions.showToast(CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(userSettingsActions.connectExternalCalendarFail(error?.toString()));
    yield call(handleServiceError, error, CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST);
  }
}

function* uploadAvatarFileSaga({ payload }: any) {
  try {
    const avatarLink: string = yield call(uploadProtectedFile, payload);
    yield put(userSettingsActions.uploadAvatarFileSuccess(avatarLink));
    yield put(userSettingsActions.saveUserSettingsRequest());
  } catch (error: unknown) {
    yield put(userSettingsActions.uploadAvatarFileFail(error?.toString()));
  }
}

function* clearAvatarFileSaga() {
  try {
    const avatarLink: string | null | undefined = yield select(userSettingsSelectors.selectAvatar);
    if (avatarLink) {
      // yield call(removeFile, avatarLink);
      yield put(userSettingsActions.clearAvatarFileSuccess());
      yield put(userSettingsActions.saveUserSettingsRequest());
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.clearAvatarFileFail(error?.toString()));
  }
}

function* updateUserLinkSaga(action: ReturnType<typeof userSettingsActions.updateUserLinkRequest>) {
  try {
    if (action.type === UserSettingsActionTypes.UPDATE_USER_LINK_REQUEST) {
      const oldLink: string = yield select(authentificationSelectors.selectLink);
      const input: { oldLink: string; newLink: string } = {
        oldLink,
        newLink: action.link,
      };

      yield call(updateUserLink, input);
      yield put(userSettingsActions.updateUserLinkSuccess());

      localStorage.setItem(UserSettingsKeys.LINK, action.link);
      yield put(authentificationActions.updateUserDataCore({ link: action.link }));
      yield put<any>(notificationsActions.showToast(UPDATE_USER_LINK_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.updateUserLinkFail(error?.toString()));
    yield call(handleServiceError, error, UPDATE_USER_LINK_ERROR_TOAST);
  }
}

function* connectZoomSaga(action: ReturnType<typeof userSettingsActions.connectZoomRequest>) {
  try {
    if (action.type === UserSettingsActionTypes.CONNECT_ZOOM_REQUEST) {
      const code = action.payload.code;
      const redirectURI = action.payload.redirectURI;

      const zoomIntegration: IntegrationInput = yield call(connectToZoom, code, redirectURI);

      const parseZoomIntegration: IntegrationInput = { // parse until is not done SO-1433 - Transfer Billing/Zoom/Other to Java to Java
        ...zoomIntegration,
        settings: zoomIntegration.settings && JSON.parse(zoomIntegration.settings)
      }

      yield put(userSettingsActions.connectZoomSuccess(parseZoomIntegration));
      yield put<any>(notificationsActions.showToast(CONNECT_ZOOM_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.connectZoomFail(error?.toString()));
    yield call(handleServiceError, error, CONNECT_ZOOM_ERROR_TOAST);
  }
}

function* saveUserSettingsNoToastSaga() {
  try {
    const input: CreateUserDataInput = yield select(selectSaveUserDataInputRequest);
    yield call(saveUserSettings, input);

    yield put(userSettingsActions.saveUserSettingsNoToastSuccess());
  } catch (error: unknown) {
    yield put(userSettingsActions.saveUserSettingsNoToastFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_USER_SETTINGS_ERROR_TOAST);
  }
}

export function* watchUserSettingsSaga() {
  yield takeLatest(UserSettingsActionTypes.GET_USER_SETTINGS_REQUEST, getUserSettingsSaga);
  yield takeLatest(UserSettingsActionTypes.SAVE_USER_SETTINGS_REQUEST, saveUserSettingsSaga);
  yield takeLatest(UserSettingsActionTypes.CHECK_USER_LINK_REQUEST, checkUserLinkSaga);
  yield takeLatest(UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_REQUEST, connectExternalCalendarSaga);
  yield takeLatest(UserSettingsActionTypes.UPLOAD_AVATAR_FILE_REQUEST, uploadAvatarFileSaga);
  yield takeLatest(UserSettingsActionTypes.CLEAR_AVATAR_FILE_REQUEST, clearAvatarFileSaga);
  yield takeLatest(UserSettingsActionTypes.UPDATE_USER_LINK_REQUEST, updateUserLinkSaga);
  yield takeLatest(UserSettingsActionTypes.CONNECT_ZOOM_REQUEST, connectZoomSaga);
  yield takeLatest(UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_REQUEST, saveUserSettingsNoToastSaga);
}

export const userSettingsSagas = {
  getUserSettings: getUserSettingsSaga,
  saveUserSettings: saveUserSettingsSaga,
  checkUserLink: checkUserLinkSaga,
};
