import { t } from '../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  edit: t('Common:EDIT'),
  cancel: t('Common:CANCEL'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  clone: t('Common:CLONE'),
  delete: t('Common:DELETE'),
  activate: t('Common:ACTIVATE'),
  deactivate: t('Common:DEACTIVATE'),
  title: t('BookingPages:TITLE'),
  tooltip: t('BookingPages:TOOLTIP'),
  newBookingPage: t('BookingPages:NEW_BOOKING_PAGE'),
  whatTitlePart1: t('EditBookingPage:WHAT_TITLE_PART_1'),
  whatTitlePart2: t('EditBookingPage:WHAT_TITLE_PART_2'),
  howTitlePart1: t('EditBookingPage:HOW_TITLE_PART_1'),
  howTitlePart2: t('EditBookingPage:HOW_TITLE_PART_2'),
  howDescription: t('EditBookingPage:HOW_DESCRIPTION'),
  additionalOptions: t('EditBookingPage:ADDITIONAL_OPTIONS'),
  calendarTitlePart1: t('EditBookingPage:CALENDAR_TITLE_PART_1'),
  calendarTitlePart2: t('EditBookingPage:CALENDAR_TITLE_PART_2'),
  pageTitlePart1: t('EditBookingPage:PAGE_TITLE_PART_1'),
  pageTitlePart2: t('EditBookingPage:PAGE_TITLE_PART_2'),
  bookingTemplateTitle: t('EditBookingPage:BOOKING_TEMPLATE_TITLE'),
  bookingTemplatePart1: t('EditBookingPage:BOOKING_TEMPLATE_PART_1'),
  bookingTemplatePart2: t('EditBookingPage:BOOKING_TEMPLATE_PART_2'),
  bookingTemplatePart3: t('EditBookingPage:BOOKING_TEMPLATE_PART_3'),
  inviteeTitlePart1: t('EditBookingTemplate:INVITEE_TITLE_PART_1'),
  inviteeTitlePart2: t('EditBookingTemplate:INVITEE_TITLE_PART_2'),
  inviteeSubTitle: t('EditBookingTemplate:INVITEE_SUBTITLE'),
  notificationsTitlePart1: t('EditBookingTemplate:NOTIFICATIONS_TITLE_PART_1'),
  notificationsTitlePart2: t('EditBookingTemplate:NOTIFICATIONS_TITLE_PART_2'),
  confirmationTitlePart1: t('EditBookingTemplate:CONFIRMATION_TITLE_PART_1'),
  confirmationTitlePart2: t('EditBookingTemplate:CONFIRMATION_TITLE_PART_2'),
  whereTitlePart1: t('EditBookingTemplate:WHERE_TITLE_PART_1'),
  whereTitlePart2: t('EditBookingTemplate:WHERE_TITLE_PART_2'),
  whenTitlePart1: t('EditBookingTemplate:WHEN_TITLE_PART_1'),
  whenTitlePart2: t('EditBookingTemplate:WHEN_TITLE_PART_2'),
  whoTitlePart1: t('EditBookingPage:WHO_TITLE_PART_1'),
  whoTitlePart2: t('EditBookingPage:WHO_TITLE_PART_2'),
  viewBookingPage: t('EditBookingPage:VIEW_BOOKING_PAGE'),
  copyLink: t('EditBookingPage:COPY_LINK'),
  addToWebsite: t('EditBookingPage:ADD_TO_WEBSITE'),
  locked: t('Common:LOCKED'),
  bookingTemplateLinkTooltip: t('EditBookingPage:BOOKING_TEMPLATE_LINK_TOOLTIP'),
  bookingTemplateLinkOverride: t('EditBookingPage:BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE'),
  backToList: t('EditBookingPage:BACK_TO_LIST'),
};
