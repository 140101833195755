export const Users = Object.freeze({
  ADD_TO_TEAM: `Ajouter à l'équipe`,
  ADD_TO_TEAM_PLACEHOLDER: `Sélectionner l'équipe/les équipes`,
  ALREADY_INVITED_COUNT: `Déjà un utilisateur`,
  ASSIGN_BOOKING_TEMPLATES: `Attribuer des modèles de réservation`,
  ASSIGN_BOOKING_TEMPLATES_PLACEHOLDER: `Sélectionner un/des modèle(s) de réservation`,
  ASSIGN_ROLE: `Attribuer un rôle`,
  ASSIGN_WORKSPACE: `Attribuer un espace de travail`,
  CLOSE: `Fermer`,
  CONFIRM_DIALOG_DESC: `Pour confirmer, vous enverrez une invitation par e-mail comme suit :`,
  CSV_INVITE_MESSAGE_TITLE: `Configuration d'une grande équipe en une minute`,
  CSV_TEMPLATE_DESC: `Commencez par remplir ce modèle avec les adresses e-mail et les rôles de vos utilisateurs.`,
  CSV_TEMPLATE_TITLE: `Télécharger le modèle`,
  CSV_UPLOAD_DESC: `Téléchargez votre CSV entièrement peuplé pour une configuration rapide en quelques minutes.`,
  CSV_UPLOAD_TITLE: `Télécharger le CSV`,
  EMAIL: `E-mail`,
  FULL_NAME: `Nom complet`,
  HIDE_INACTIVE_USERS: `Masquer les utilisateurs inactifs`,
  INVITE: `Inviter`,
  INVITE_MESSAGE_DESC: `Mettez une virgule après chaque adresse e-mail que vous saisissez.`,
  INVITE_MESSAGE_TITLE: `Tapez ou collez les adresses e-mail à inviter.`,
  INVITE_TITLE: `Inviter des utilisateurs`,
  LAST_ACTIVE: `Dernière activité`,
  BOOKING_TEMPLATE: `Modèle de réservation`,
  NAME: `Nom`,
  NO_EMAIL_ADDRESSES: `Aucune adresse e-mail trouvée.`,
  OPTIONAL: `Facultatif`,
  ROLE: `Rôle`,
  STATUS: `Statut`,
  STATUS_INVITE_SENT: `Invitation Envoyée`,
  STATUS_ACTIVE: `Actif`,
  STATUS_INACTIVE: `Inactif`,
  SUPER_ADMIN_ACCESS_DESCRIPTION: `Le super administrateur aura accès à tous les espaces de travail`,
  TEAM: `Équipe`,
  TOOLTIP: `Inviter et gérer l'accès des employés à ce compte SUMO1.`,
  USER_COUNT: `Utilisateurs uniques à inviter`,
  WORKSPACE: `Espace de travail`,
});

export const UsersConfirmDialog = Object.freeze({
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  DELETE_COMMENT_1: `La désactivation ou la suppression d'un utilisateur libérera une licence.`,
  DELETE_NOTE_DESCRIPTION: `Toutes les données utilisateur seront définitivement supprimées des serveurs SUMO1.`,
  DELETE_NOTE_TITLE: `Conformité GDPR`,
  DELETE_TITLE: `Supprimer`,
  DISABLE_COMMENT_1: `La désactivation d'un utilisateur libérera une licence.`,
  DISABLE_COMMENT_2: `Les rendez-vous ne seront pas supprimés ou annulés.`,
  DISABLE_DESCRIPTION: `Êtes-vous sûr de vouloir désactiver`,
  DISABLE_TITLE: `Désactiver l'utilisateur`,
});

export const EditUser = Object.freeze({
  ACCESS_TITLE: `Accès`,
  ACTIVE: `Actif`,
  ACTIVE_TOOLTIP: `Cochez cette case pour désactiver cet utilisateur et libérer une licence.`,
  CONTACT_DETAILS_TITLE: `Coordonnées`,
  DEACTIVATE_HIMSELF: `Vous ne pouvez pas vous désactiver.`,
  DAYS_10: `10 jours`,
  DAYS_15: `15 jours`,
  DAYS_20: `20 jours`,
  DAYS_30: `30 jours`,
  EXPIRATION_DATE: `Date d'expiration`,
  HIGHER_ROLE_DESCRIPTION: `Cet utilisateur a un rôle supérieur au vôtre,`,
  INVITE_SENT_TOOLTIP: `L'utilisateur n'a pas encore ouvert de session. Supprimer l'enregistrement libérera la licence.`,
  BOOKING_TEMPLATES: `Modèles de réservation`,
  BOOKING_TEMPLATES_MESSAGE: `Cet utilisateur prend en charge les modèles de réservation suivants`,
  BOOKING_TEMPLATES_TOOLTIP: `Sélectionnez d'abord un espace de travail`,
  NO_PERMISSION_DESCRIPTION: `Vous n'avez pas la permission de modifier les utilisateurs,`,
  ONLY_ONE_SUPER_ADMIN: `Il n'y a plus de super administrateur à part vous, pour changer de rôle, invitez au moins un super administrateur supplémentaire`,
  READONLY_DESCRIPTION: ` le mode lecture seule est disponible uniquement.`,
  TEAMS: `Équipes`,
  TEAMS_MESSAGE: `Cet utilisateur est membre des équipes suivantes`,
  TITLE: `Modifier l'utilisateur`,
  USER_OF_WORKSPACE_TITLE: `Utilisateur de l'espace de travail`,
  WORKSPACE_MESSAGE: `Cet utilisateur peut utiliser les espaces de travail suivants`,
});

export const UsersToastsNotifications = Object.freeze({
  EDIT_USER_ERROR_TOAST: `Échec de la demande de modification de l'utilisateur`,
  EDIT_USER_SUCCESS_TOAST: `La demande de modification de l'utilisateur a réussi`,
  ENABLE_USER_TOAST_HEADER: `Utilisateur activé`,
  ENABLE_USER_SUCCESS_TOAST: `est désormais activé.`,
  GET_LICENSES_ERROR_MESSAGE: `Échec de la demande de licences`,
  GET_USERS_ERROR_MESSAGE: `Échec de la demande d'utilisateurs`,
  INVITE_USERS_EMAIL_INVALID: `Une ou plusieurs des adresses e-mail saisies sont invalides.`,
  INVITE_USERS_ERROR_TOAST: `Échec de la demande d'invitation des utilisateurs`,
  INVITE_USERS_SUCCESS_TOAST: `La demande d'invitation des utilisateurs a réussi`,
  DELETE_USER_TOAST_HEADER: `Utilisateur supprimé`,
  DELETE_USER_ERROR_TOAST: `Échec de la demande de suppression de l'utilisateur`,
  DELETE_USER_SUCCESS_TOAST: `est maintenant supprimé.`,
  DISABLE_USER_TOAST_HEADER: `Utilisateur désactivé`,
  DISABLE_USER_ERROR_TOAST: `Échec de la demande de désactivation/activation de l'utilisateur`,
  DISABLE_USER_SUCCESS_TOAST: `est maintenant désactivé.`,
});

export const License = Object.freeze({
  ASSIGNED: `Assigné`,
  BUY_LICENSES: `Acheter des licences`,
  MY_BILLINGS: `Mes facturations`,
  LICENSE_TYPE: `Type de licence`,
  OWNED: `Possédé`,
  PAID_EXPIRES_MESSAGE_PART_1: `Paiement en retard :`,
  PAID_EXPIRES_MESSAGE_PART_2_AGO: `il y a quelques jours`,
  PAID_EXPIRES_MESSAGE_PART_2_LEFT: `jours restants`,
  PAID_EXPIRES_MESSAGE_PART_2_TODAY: `aujourd'hui`,
  REMAINING: `Restant`,
  REMAINING_MESSAGE: `Vous n'avez pas assez de licences restantes`,
  TITLE: `État de la licence utilisateur`,
  TRIAL_EXPIRES_MESSAGE: `L'essai expire dans`,
  SEATS_REMOVAL_SCHEDULED: `Changements planifiés pour supprimer des places.`,
});
