import { useDispatch } from 'react-redux';
import { EDIT_MEETING_NOTES_MODAL_NAME, editMeetingNotesModalActions } from '../../../store/scheduledMeetings/modal';
import { Modal, TextEditor } from '../../common';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { scheduledMeetingsActions } from '../../../store/scheduledMeetings/actions';
import { useSelector } from 'react-redux';
import { scheduledMeetingsSelectors } from '../../../store/scheduledMeetings/selectors';
import labels from './labels';

export const EditMeetingNotesModal = () => {
  const dispatch = useDispatch();
  const event = useSelector(scheduledMeetingsSelectors.selectSelectedEvent);
  const [localNotes, setLocalNotes] = useState(event?.internalNotes || '');

  useEffect(() => {
    if (event?.internalNotes) {
      setLocalNotes(event.internalNotes);
    }
  }, [event]);

  const closeEditMeetingNotesModal = () => {
    dispatch(editMeetingNotesModalActions.closeModal());
  };

  const saveEditMeetingNotes = () => {
    dispatch(
      scheduledMeetingsActions.updateScheduledMeetingRequest({
        ...event,
        internalNotes: localNotes,
        editMeetingNotes: true,
      })
    );
  };

  return (
    <Modal.Container name={EDIT_MEETING_NOTES_MODAL_NAME}>
      <Modal.Header>
        <div className="flex flex-column align-items-center -mb-3">{labels.meetingNotes}</div>
      </Modal.Header>
      <div className="flex flex-column align-items-center" style={{ width: '720px' }}>
        <div className="text-lg mb-2">{labels.meetingNotesDesc}</div>
        <TextEditor value={localNotes} setValue={setLocalNotes} className="w-11" style={{ height: '400px' }} />
      </div>

      <Modal.Buttons>
        <Button
          label={labels.close}
          onClick={closeEditMeetingNotesModal}
          outlined
          className="w-6rem justify-content-center"
        />
        <Button label={labels.save} onClick={saveEditMeetingNotes} className="w-10rem justify-content-center" />
      </Modal.Buttons>
    </Modal.Container>
  );
};
