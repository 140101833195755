import { useDispatch } from 'react-redux';
import labels from './labels';
import { useSelector } from 'react-redux';
import {
  BookingPageWhoOption,
  VIDEO_CONFERENCE_OPTIONS,
  bookingPageSelectors,
  bookingPagesActions,
} from '../../../store/bookingPages';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primereact/autocomplete';
import { Fragment, useState } from 'react';
import { Chip } from 'primereact/chip';
import { IntegrationType, VideoConferenceType } from '../../../API';
import { ReactComponent as GoogleMeetIcon } from '../../../assets/icons/google-meet.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/icons/zoom.svg';
import { ReactComponent as TeamsIcon } from '../../../assets/icons/teams.svg';
import { Tooltip } from 'primereact/tooltip';
import { faUser, faUserSecret, faUsers, faUsersLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadioButton } from 'primereact/radiobutton';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authentificationSelectors } from '../../../store/authentification';

export const BookingPageWhoStep = () => {
  const dispatch = useDispatch();
  const isHostUser = useSelector(bookingPageSelectors.selectIsHostUser);
  const hostsIds = useSelector(bookingPageSelectors.selectHostsIds);
  const hostsData = useSelector(bookingPageSelectors.selectHostsData);
  const coHosts = useSelector(bookingPageSelectors.selectCoHosts);
  const coHostsData = useSelector(bookingPageSelectors.selectCoHostsData);
  const allAssignedHostsIds = useSelector(bookingPageSelectors.selectAllAssignedHostsIds);
  const allAssignedTeamsIds = useSelector(bookingPageSelectors.selectAllAssignedTeamsIds);
  const potentialHosts = useSelector(bookingPageSelectors.selectPotentialHosts);
  const potentialTeams = useSelector(bookingPageSelectors.selectPotentialTeams);
  const hostVideoConferences = useSelector(bookingPageSelectors.selectHostVideoConferences);
  const videoConferenceType = useSelector(bookingPageSelectors.selectVideoConferenceType);
  const isWhoStepValid = useSelector(bookingPageSelectors.selectIsWhoStepValid);
  const isUserRequiredHost = useSelector(bookingPageSelectors.selectIsUserRequiredHost);
  const userId = useSelector(authentificationSelectors.selectUserId);
  const isVideoConference = useSelector(bookingPageSelectors.selectIsVideoConference);
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const isHostLocationValid = useSelector(bookingPageSelectors.selectIsHostLocationValid);

  const isTeamRead = useSelector(userSettingsSelectors.selectTeamsRead);

  const [filteredHosts, setFilteredHosts] = useState<BookingPageWhoOption[]>([]);
  const [localValue, setLocalValue] = useState('');

  const handleHostsListSelect = (e: AutoCompleteSelectEvent) => {
    const isTeam = e.value.isTeam;
    const newHost = { [isTeam ? 'teamId' : 'userId']: e.value.value };
    // if host is empty - assign host, otherwise push to coHosts
    const updateParams = !hostsIds.length ? { host: [newHost] } : { cohosts: [...coHosts, newHost] };
    if (!hostsIds.length) {
      dispatch(
        bookingPagesActions.updateWhereStep({ videoConferenceType: isTeam ? null : e.value.defaultVideoIntegration })
      );
    }
    dispatch(bookingPagesActions.updateWhoStep(updateParams));
    setLocalValue('');
  };

  const filterHosts = (e: AutoCompleteCompleteEvent) => {
    const userHosts = potentialHosts.filter((host) => {
      return (
        (!e.query.trim().length || host.label.toLowerCase().match(e.query.toLowerCase())) &&
        !allAssignedHostsIds.includes(host.value)
      );
    });

    const teamsHosts = isTeamRead
      ? potentialTeams.filter((team) => {
          return (
            (!e.query.trim().length || team?.label?.toLowerCase().match(e.query.toLowerCase())) &&
            !allAssignedTeamsIds.includes(team.value)
          );
        })
      : [];

    setFilteredHosts([...userHosts, ...teamsHosts]);
  };

  const handleRemoveHost = () => {
    if (coHosts.length) {
      // replace host with the first user/team from cohosts
      const newCoHost = coHosts[0];
      dispatch(bookingPagesActions.updateWhoStep({ host: [coHosts[0]], cohosts: coHosts.slice(1) }));
      if (newCoHost?.userId) {
        const coHostData = coHostsData.find((coHost) => coHost.value === coHosts[0]?.userId);
        dispatch(
          bookingPagesActions.updateWhereStep({
            videoConferenceType: coHostData?.defaultVideoIntegration,
          })
        );
      }
    } else {
      // remove host
      dispatch(bookingPagesActions.updateWhoStep({ host: [] }));
    }
  };

  const handleRemoveCoHost = (coHost: BookingPageWhoOption) => {
    dispatch(
      bookingPagesActions.updateWhoStep({
        cohosts: coHosts.filter((cohost) => coHost.value !== (coHost.isTeam ? cohost?.teamId : cohost?.userId)),
      })
    );
  };

  const handleVideoConferenceType = (type: VideoConferenceType) => {
    dispatch(
      bookingPagesActions.updateWhereStep({
        videoConferenceType: type,
      })
    );
  };

  const getUserIcon = (isHost: boolean, isTeam: boolean, isOption: boolean) => {
    return isHost ? (
      <>
        {!isOption && <Tooltip target=".mainHost" />}
        <span className="mainHost mr-2 text-lg text-xl" data-pr-tooltip={labels.mainHost} data-pr-position="top">
          <FontAwesomeIcon icon={isTeam ? faUsersLine : faUserSecret} />
        </span>
      </>
    ) : (
      <>
        {!isOption && <Tooltip target=".cohost" />}
        <span className="cohost mr-2 text-lg text-xl" data-pr-tooltip={labels.coHost} data-pr-position="top">
          <FontAwesomeIcon icon={isTeam ? faUsers : faUser} />
        </span>
      </>
    );
  };

  const itemTemplate = (
    item: BookingPageWhoOption,
    isHost: boolean,
    handleRemove?: (user: BookingPageWhoOption) => void,
    isOption?: boolean
  ) => {
    return (
      <div className={`flex align-items-center ${isOption ? '' : 'p-1'} `} key={item.value}>
        {getUserIcon(isHost, item.isTeam, !!isOption)}
        <div className="p-chip-text">{item.label}</div>

        <div className="flex align-items-center gap-1 pl-1">
          {item.phoneNumber && <i className="pi pi-phone text-lg" title={labels.phoneCall} />}
          {item.integrationTypes?.map((type) => {
            switch (type) {
              case IntegrationType.ZOOM:
                return <ZoomIcon key={type} title={labels.zoom} />;
              case IntegrationType.GOOGLE_MEET:
                return <GoogleMeetIcon key={type} title={labels.googleMeet} />;
              case IntegrationType.MICROSOFT_TEAMS:
                return <TeamsIcon key={type} title={labels.microsoftTeams} />;
              default:
                return <Fragment key={`default_${type}`}></Fragment>;
            }
          })}
        </div>
        {/* hide remove icon for Read-Only, and for the Host who doesn't have permissions to All Booking Pages or Teams */}
        {!isBookingPageLocked &&
          !(isUserRequiredHost && item.value === userId) &&
          handleRemove &&
          (!item.isTeam || isTeamRead) && (
            <span className="ml-3 pi pi-times-circle cursor-pointer" onClick={() => handleRemove(item)} />
          )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-12 flex align-items-center p-2 mb-3 border-round" style={{ background: '#e7f4fd' }}>
        <span className="pi pi-info-circle mr-2"></span>
        {labels.hostsLabel}
      </div>
      <div className="grid p-fluid">
        <div className="col-12">
          <div>{labels.hosts}</div>
          <AutoComplete
            field="email"
            itemTemplate={(data: BookingPageWhoOption) => itemTemplate(data, false, undefined, true)}
            placeholder={labels.hostsPlaceholder}
            dropdown
            dropdownMode="current"
            value={localValue}
            onChange={(e) => setLocalValue(e.value)}
            onSelect={handleHostsListSelect}
            suggestions={filteredHosts}
            completeMethod={filterHosts}
            disabled={isBookingPageLocked}
            className={`${!isWhoStepValid ? 'p-invalid' : ''}`}
          />
          {!isHostLocationValid && <div className="mt-2 -mb-2 p-error">{labels.hostNotification}</div>}
          <div className="col-12">
            {hostsData[0] && (
              <Chip
                key={`host-${hostsData[0].value}`}
                label={hostsData[0].label}
                className="my-2 mr-2"
                template={itemTemplate(hostsData[0], true, handleRemoveHost)}
              />
            )}
            {coHostsData.map((coHost) => {
              return (
                <Chip
                  key={`co-host-${coHost.value}`}
                  label={coHost.label}
                  className="my-2 mr-2"
                  template={itemTemplate(coHost, false, handleRemoveCoHost)}
                />
              );
            })}
          </div>
          {isHostUser && isVideoConference && !!hostVideoConferences.length && (
            <div className="col-12">
              <div className="mb-2">{labels.hostVideoConference}</div>
              {hostVideoConferences.map((conference) => (
                <div className="field-radiobutton" key={conference}>
                  <RadioButton
                    inputId={`conference${conference}`}
                    value={conference}
                    checked={(videoConferenceType as string) === (conference as string)}
                    onChange={(e) => handleVideoConferenceType(e.target.value as VideoConferenceType)}
                    disabled={isBookingPageLocked}
                  />
                  <label htmlFor={`conference${conference}`} className="ml-0 py-1 px-2">
                    {VIDEO_CONFERENCE_OPTIONS[conference]}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
