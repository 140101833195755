import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { DefaultVideoConference, QuickSetupScreen } from '..';
import labels from './labels';
import { Button } from 'primereact/button';
import { quickSetupNavigationActions } from '../../../store/quickSetup';
import googleMeetLogo from '../../../assets/images/GoogleMeetLogo.svg';
import microsoftTeamsLogo from '../../../assets/images/MicrosoftTeamsLogo.svg';
import zoomLogo from '../../../assets/images/ZoomLogo.svg';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authentificationActions, authentificationSelectors } from '../../../store/authentification';
import { ZoomAuthButton } from '../../private/';
import { VideoConferenceType } from '../../../API';

export const SetupVideoConferenceStep = () => {
  const isZoomConnected = useSelector(userSettingsSelectors.selectIsZoomConnected);
  const isThirdPartyGoogle = useSelector(authentificationSelectors.selectIsThirdPartyGoogle);
  const isThirdPartyMicrosoft = useSelector(authentificationSelectors.selectIsThirdPartyMicrosoft);

  const dispatch = useDispatch<AppDispatch>();

  const handleBack = () => {
    dispatch(quickSetupNavigationActions.navigateBack());
  };
  const handleNext = () => {
    dispatch(quickSetupNavigationActions.navigateNext());
  };

  const handleGetZoomCodeRedirect = () => {
    dispatch(authentificationActions.getZoomCodeRedirect(window.location.href));
  };

  return (
    <QuickSetupScreen.Container title={labels.title}>
      <div className="flex flex-column align-items-center">
        <div className="text-xl mt-3">{labels.description}</div>
        <div>{labels.message}</div>
        <div className="w-full flex flex-row align-items-center justify-content-around flex-wrap mt-4 gap-6 px-4">
          <div
            className={`h-22rem flex flex-column align-items-center flex-1 shadow-1 border-1 border-100 ${
              isZoomConnected ? 'bg-gray-100' : ''
            }`}
          >
            <div className="w-full flex align-items-center justify-content-start py-3">
              <img src={zoomLogo} className="w-4rem mx-3" />
              <div className="text-lg font-bold text-900">{labels.zoomTitle}</div>
            </div>

            {isZoomConnected ? (
              <>
                <div className="flex align-items-center pt-8">
                  <div className="text-lg font-medium">
                    <i className="pi pi-check text-lg text-green-500 font-bold mr-1"></i>
                    {labels.complete}
                  </div>
                </div>
                <DefaultVideoConference videoConferenceType={VideoConferenceType.ZOOM} />
              </>
            ) : (
              <div className="pt-7">
                <ZoomAuthButton
                  label={labels.connect}
                  outlined
                  severity="secondary"
                  className="px-5 py-2"
                  onRedirect={handleGetZoomCodeRedirect}
                />
              </div>
            )}
          </div>

          {isThirdPartyGoogle && (
            <div className="h-22rem flex flex-column align-items-center flex-1 shadow-1 border-1 border-100 bg-gray-100">
              <div className="w-full flex align-items-center justify-content-start py-3">
                <img src={googleMeetLogo} className="w-4rem mx-3" />
                <div className="text-lg font-bold text-900">{labels.googleMeetTitle}</div>
              </div>
              <div className="flex align-items-center text-lg font-medium pt-8">
                <i className="pi pi-check text-lg text-green-500 font-bold mr-1"></i>
                {labels.complete}
              </div>
              <DefaultVideoConference videoConferenceType={VideoConferenceType.GOOGLE_MEET} />
            </div>
          )}

          {isThirdPartyMicrosoft && (
            <div className="h-22rem flex flex-column align-items-center flex-1 shadow-1 border-1 border-100 bg-gray-100">
              <div className="w-full flex align-items-center justify-content-start py-3">
                <img src={microsoftTeamsLogo} className="w-4rem mx-3" />
                <div className="text-lg font-bold text-900">{labels.microsoftTeamsTitle}</div>
              </div>
              <div className="flex align-items-center text-lg font-medium pt-8">
                <i className="pi pi-check text-lg text-green-500 font-bold mr-1"></i>
                {labels.complete}
              </div>
              <DefaultVideoConference videoConferenceType={VideoConferenceType.MICROSOFT_TEAMS} />
            </div>
          )}
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <div className="flex justify-content-between">
          <Button label={labels.back} className="w-8rem m-2" onClick={handleBack} outlined />
          <Button label={labels.next} className="w-8rem m-2" onClick={handleNext} />
        </div>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
