import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions, globalSelectors } from '../../../store/global';
import { isEqualArrays } from '../../../services/utils';

interface ISearch {
  placeholder: string;
  value: string;
  onChange?: (value: string) => void;
  paths?: string[];
  maxTextLength: number;
}

export const Search = ({ placeholder, value, onChange, paths, maxTextLength }: ISearch) => {
  const dispatch = useDispatch();
  const searchPaths = useSelector(globalSelectors.selectSearchPaths);

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    } else if (paths) {
      const convertedPaths = paths.map((path) => path.replace(/\/:[^/].+$/g, '/'));
      if (
        (!searchPaths && convertedPaths.some((name) => location.pathname.startsWith(name))) ||
        (searchPaths && isEqualArrays(convertedPaths, searchPaths))
      ) {
        dispatch(globalActions.setSearch({ searchString: value, paths: convertedPaths }));
      }
    }
  };
  return (
    <>
      <span className="p-inputgroup-addon">
        <i className="pi pi-search"></i>
      </span>
      <InputText
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        maxLength={maxTextLength}
      />
    </>
  );
};
