import { t } from '../../../i18n/i18n';

export default {
  bookingPage: t('ScheduledMeetings:BOOKING_PAGE'),
  bookingPagePlaceholder: t('ScheduledMeetings:BOOKING_PAGE_PLACEHOLDER'),
  dateRange: t('Common:DATE_RANGE'),
  export: t('Common:EXPORT'),
  filter: t('Common:FILTER'),
  no: t('Common:NO'),
  noLocationGiven: t('ScheduledMeetings:NO_LOCATION_GIVEN'),
  past: t('ScheduledMeetings:PAST'),
  status: t('ScheduledMeetings:STATUS'),
  statusPlaceholder: t('ScheduledMeetings:STATUS_PLACEHOLDER'),
  team: t('ScheduledMeetings:TEAM'),
  upcoming: t('ScheduledMeetings:UPCOMING'),
  yes: t('Common:YES'),
};
