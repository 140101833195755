export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Les utilisateurs suivants sont assignés à utiliser uniquement cet espace de travail`,
  NOTES: `Vous devez d'abord assigner ces utilisateurs à un autre espace de travail avant de le supprimer.`,
  TITLE: `Réaffecter les utilisateurs`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Échec de la requête d'activation/désactivation de l'espace de travail`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail activé`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Échec de la requête de suppression de l'espace de travail`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Requête de suppression de l'espace de travail réussie`,
  GET_WORKSPACES_ERROR_MESSAGE: `Échec de la requête d'obtention des espaces de travail`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail désactivé`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Échec de la requête d'enregistrement de l'espace de travail`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail enregistré avec succès`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administrateurs`,
  ADMIN_DESCRIPTION: `Les personnes suivantes peuvent administrer cet espace de travail.`,
  INVITE_SETTINGS_TITLE: `Paramètres d'invitation`,
  NOTES_PART_1: `Vous pouvez également utiliser la section `,
  NOTES_PART_2: `Gestion des utilisateurs`,
  NOTES_PART_3: ` pour trouver un utilisateur et l'ajouter à un espace de travail.`,
  PHONE_NUMBER_DESCRIPTION: `Si vous prévoyez d'utiliser SUMO pour réserver des appels téléphoniques, il est recommandé de le laisser activé.`,
  PHONE_NUMBER_TITLE: `Rendre obligatoire le champ "Numéro de téléphone" des utilisateurs.`,
  SEARCH_ADD_ADMINS: `Rechercher et ajouter un administrateur`,
  SEARCH_ADD_USERS: `Rechercher et ajouter des utilisateurs`,
  SUPER_ADMINS_DESCRIPTION: `Les super-administrateurs seront toujours préremplis`,
  USERS_DESCRIPTION: `Les personnes suivantes peuvent utiliser cet espace de travail.`,
  USERS_TITLE: `Utilisateurs`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Actif`,
  ADDITIONAL_OPTIONS: `Options supplémentaires`,
  ADMIN_TITLE: `Admin`,
  BRANDING_DESCRIPTION: `Par défaut, toutes les pages de réservation de cet espace de travail utiliseront ces paramètres de branding. Cependant, vous pouvez remplacer les paramètres pour cette page de réservation spécifique après.`,
  BRANDING_TITLE_PART_1: `Quels seront les`,
  BRANDING_TITLE_PART_2: `paramètres de branding`,
  BRANDING_TITLE_PART_3: `pour cet espace de travail ?`,
  DEFAULT_WORKSPACE: `Espace de Travail Par Défaut`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  EDIT_LABELS_DESCRIPTION: `Modifiez les étiquettes de toutes les pages de réservation de cet espace de travail. Vous pouvez remplacer ces paramètres pour des pages de réservation spécifiques si nécessaire.`,
  INACTIVE: `Inactif`,
  NAME_TITLE: `Nom de l'espace de travail`,
  NEW_WORKSPACE: `Nouvel espace de travail`,
  PREVIEW_BUTTON: `Voir la page de réservation et modifier les étiquettes`,
  SELECTED_WORKSPACES: `espaces de travail sélectionnés`,
  TITLE: `Espaces de travail`,
  TOOLTIP: `Gérez des espaces de travail qui offrent des paramètres globaux uniques aux départements ou aux équipes.`,
  WHAT_TITLE_PART_1: `Quels sont les`,
  WHAT_TITLE_PART_2: `paramètres de cet espace de travail ?`,
  WHO_TITLE_PART_1: `Qui peut utiliser cet`,
  WHO_TITLE_PART_2: `espace de travail ?`,
});
