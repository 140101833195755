import { useSelector } from 'react-redux';
import labels from './labels';
import { useState } from 'react';
import { IntegrationCard } from '../../components/integration';
import {
  IntegrationFilter,
  INTEGRATION_FILTER_LABEL,
  integrationSelectors,
  integrationActions,
} from '../../store/integration';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../types/constants';
import { Search, SectionTitle } from '../../components/common';
import { useDispatch } from 'react-redux';

export const Integration = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');
  const integrationFilter = useSelector(integrationSelectors.selectIntegrationFilter);
  const integrationList = useSelector(integrationSelectors.selectIntegrationList);
  const integrationFilteredList = useSelector(integrationSelectors.selectSearchIntegrations(searchString));

  const handleFilterChange = (value: IntegrationFilter) => {
    dispatch(integrationActions.setIntegrationFilter(value));
  };

  const handleSearchChange = (value: string) => {
    setSearchString(value?.trim()?.toLowerCase());
  };

  return (
    <div>
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle id="integratrionTitle" icon="pi-link" title={labels.title} />
      </div>

      <div className="card">
        <div className="mb-1">{labels.desc}</div>
        {integrationList.length >= MIN_ITEMS_FOR_SEARCH && (
          <div className="my-3 pb-1 p-inputgroup col-12 md:col-6">
            <Search
              placeholder={labels.search}
              value={searchString}
              onChange={handleSearchChange}
              maxTextLength={MAX_LENGTH_NAME}
            />
          </div>
        )}
        <div className="flex flex-wrap">
          <ul className="col-12 md:col-3 lg:col-2 list-none text-lg my-0">
            {Object.values(IntegrationFilter).map((filter) => (
              <li
                key={filter}
                className={`cursor-pointer my-2 ${integrationFilter === filter ? 'font-bold' : ''}`}
                onClick={() => handleFilterChange(filter)}
              >
                {INTEGRATION_FILTER_LABEL[filter]}
              </li>
            ))}
          </ul>
          <div className="col grid ml-0 lg:ml-3">
            {integrationFilteredList.length ? (
              integrationFilteredList.map((integration) => (
                <IntegrationCard
                  containerClassName=" col-12 md:col-6 lg:col-4 p-3"
                  integration={integration}
                  key={integration.type}
                />
              ))
            ) : (
              <div className="col p-3">{labels.noItems}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
