import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Path } from '../../routing';
import { AppDispatch } from '../../store/rootStore';
import {
  deleteBookingTemplatesModalActions,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../store/bookingTemplates';
import {
  BookingTemplateAdditionalRulesStep,
  BookingTemplateWhatStep,
  BookingTemplateWhoStep,
  BookingTemplateWhereStep,
  BookingTemplateWhenStep,
  BookingTemplateNotificationsStep,
  BookingTemplateConfirmationStep,
  BookingTemplateCloneModal,
  BookingTemplateDeleteModal,
  BookingTemplateHowStep,
  BookingTemplateCalendarStep,
  BookingTemplateSettingsStep,
} from '../../components/bookingTemplates';
import { ConfirmationModal, Preloader, SectionTitle } from '../../components/common';
import labels from './labels';

import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { navigationService } from '../../services/NavigationService';
import { userSettingsSelectors } from '../../store/userSettings';
import { BookingPagePreviewModal } from '../../components/bookingPages';
import { BookingPageLabelsInput, UpdateBookingPageInput } from '../../API';

export const EditBookingTemplate = () => {
  const { bookingTemplateId: bookingTemplateIdParam } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const bookingTemplate = useSelector(bookingTemplatesSelectors.selectBookingTemplate);
  const isFetching = useSelector(bookingTemplatesSelectors.selectIsFetching);
  const isEdited = useSelector(bookingTemplatesSelectors.selectIsEdited);
  const isExisting = useSelector(bookingTemplatesSelectors.selectIsExisting);
  const isActive = useSelector(bookingTemplatesSelectors.selectIsActive);
  const bookingTemplateId = useSelector(bookingTemplatesSelectors.selectId);
  const bookingTemplateName = useSelector(bookingTemplatesSelectors.selectWhatName);
  const isWhatStepValid = useSelector(bookingTemplatesSelectors.selectIsWhatStepValid);
  const isWhoStepValid = useSelector(bookingTemplatesSelectors.selectIsWhoStepValid);
  const isWhereStepValid = useSelector(bookingTemplatesSelectors.selectIsWhereStepValid);
  const isWhenStepValid = useSelector(bookingTemplatesSelectors.selectIsWhenStepValid);
  const isNotificationsStepValid = useSelector(bookingTemplatesSelectors.selectIsNotificationsStepValid);
  const isConfirmationStepValid = useSelector(bookingTemplatesSelectors.selectIsConfirmationStepValid);
  const isBookingTemplatesCreate = useSelector(userSettingsSelectors.selectBookingTemplatesCreate);
  const isBookingTemplatesEdit = useSelector(bookingTemplatesSelectors.selectIsEditBookingTemplate);
  const isBookingTemplatesDelete = useSelector(userSettingsSelectors.selectBookingTemplatesDelete);
  const lockedTooltip = useSelector(bookingTemplatesSelectors.selectLockedTooltip);
  const styleForPreview = useSelector(bookingTemplatesSelectors.selectStyleForPreview);

  const [accordion1Index, setAccordion1Index] = useState<number | number[] | null>(null);
  const [accordion2Index, setAccordion2Index] = useState<number | number[] | null>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    if (bookingTemplateIdParam && bookingTemplateIdParam !== 'new') {
      dispatch(bookingTemplatesActions.getBookingTemplateRequest(bookingTemplateIdParam));
    } else {
      dispatch(bookingTemplatesActions.getBookingTemplateRequest(''));
      setAccordion1Index(0);
    }
  }, []);

  // open first accordion when existing Booking Template is loaded
  useEffect(() => {
    bookingTemplateId && setAccordion1Index(0);
  }, [bookingTemplateId]);

  const handleActivate = () => {
    dispatch(
      bookingTemplatesActions.enableBookingTemplateRequest({
        ...bookingTemplate,
        enabled: !isActive,
      })
    );
  };

  const handleClone = () => {
    dispatch<any>(bookingTemplatesActions.cloneBookingTemplateThunk()); // TODO: any
  };

  const handleDelete = () => {
    dispatch(bookingTemplatesActions.selectBookingTemplate(bookingTemplate.id));
    dispatch(deleteBookingTemplatesModalActions.openModal());
  };

  const menuItems = [
    {
      label: isActive ? labels.deactivate : labels.activate,
      icon: isActive ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open',
      command: handleActivate,
      visible: isBookingTemplatesEdit,
    },
    { label: labels.clone, icon: 'pi pi-fw pi-clone', command: handleClone, visible: isBookingTemplatesCreate },
    { label: labels.delete, icon: 'pi pi-fw pi-trash', command: handleDelete, visible: isBookingTemplatesDelete },
  ];

  const handleAccordion1TabChange = (index: number | number[] | null) => {
    setAccordion1Index(index);
    setAccordion2Index(null);
  };

  const handleAccordion2TabChange = (index: number | number[] | null) => {
    setAccordion2Index(index);
    setAccordion1Index(null);
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // prevent closing the accordion

    if (!isWhatStepValid) {
      handleAccordion1TabChange(0);
      return;
    }
    if (!isWhoStepValid) {
      handleAccordion1TabChange(1);
      return;
    }
    if (!isWhereStepValid) {
      handleAccordion1TabChange(2);
      return;
    }
    if (!isWhenStepValid) {
      handleAccordion1TabChange(3);
      return;
    }
    if (!isNotificationsStepValid) {
      handleAccordion2TabChange(2);
      return;
    }
    if (!isConfirmationStepValid) {
      handleAccordion2TabChange(3);
      return;
    }

    isExisting
      ? dispatch(bookingTemplatesActions.saveBookingTemplateRequest())
      : dispatch(bookingTemplatesActions.createBookingTemplateRequest());
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // for no close the accordion
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmCancel = () => {
    navigationService.navigateTo(Path.BookingTemplates);
    dispatch(bookingTemplatesActions.clearBookingTemplate());
  };

  const handleLabelsSave = (labels: BookingPageLabelsInput) => {
    dispatch(bookingTemplatesActions.updateHowStep(labels));
  };

  const renderSaveCancelButtons = () => (
    <>
      <Button className="mr-2 bg-white" label={labels.cancel} outlined onClick={handleCancel} />
      <Button label={labels.save} onClick={handleSave} disabled={!isBookingTemplatesEdit} />
    </>
  );

  const renderAccordionTab = (
    titleElement: ReactJSXElement,
    stepElement: ReactJSXElement,
    showHeaderButtons = false,
    showBottomButtons = false,
    stepInfo?: string | null
  ) => (
    <AccordionTab
      contentClassName={`${!isBookingTemplatesEdit ? 'pointer-events-none' : ''}`}
      header={
        <>
          {showHeaderButtons && (
            <div className="absolute right-0 mr-2 h-1rem flex align-items-center">{renderSaveCancelButtons()}</div>
          )}
          <div className="font-normal h-1rem flex flex-column justify-content-center">
            <div>{titleElement}</div>
            {stepInfo && <div className="mt-1 text-sm text-gray-500">{stepInfo}</div>}
          </div>
        </>
      }
    >
      <div className="lg:p-2">{stepElement}</div>
      {showBottomButtons && (
        <div className="flex justify-content-end -mx-3 -mb-3 p-2 border-top-1 border-gray-300 p-accordion-header-text">
          {renderSaveCancelButtons()}
        </div>
      )}
    </AccordionTab>
  );

  return (
    <div className="pb-6">
      {isFetching && <Preloader />}
      <BookingTemplateCloneModal />
      <BookingTemplateDeleteModal />
      <BookingPagePreviewModal
        bookingPage={{ ...bookingTemplate, style: styleForPreview } as UpdateBookingPageInput}
        onSave={handleLabelsSave}
      />

      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        cancelButtonProps={{ className: 'w-auto mr-0' }}
        confirmButtonProps={{ className: 'w-auto ml-4 mr-0' }}
        onConfirm={handleConfirmCancel}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle id="bookingTemplatesTitle" icon="pi-th-large" title={labels.title} tooltip={labels.tooltip} />
        <div className="flex align-items-center -my-2">
          {(isBookingTemplatesCreate || isBookingTemplatesEdit || isBookingTemplatesDelete) && (
            <div>
              <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
              <Button rounded text icon="pi pi-cog text-lg" onClick={(event) => menu.current?.toggle(event)} />
            </div>
          )}
          {accordion1Index === null && accordion2Index === null && (
            <div className="p-accordion-header-text ml-2">{renderSaveCancelButtons()}</div>
          )}
        </div>
      </div>

      <div className="text-xl pl-5 ml-2 mb-2 mt-4">
        {isExisting ? labels.edit + ' "' + bookingTemplateName + '"' : labels.newBookingTemplate}
        {!isBookingTemplatesEdit && (
          <>
            <Tooltip target=".locked" />
            <span
              className="locked bg-yellow-100 border-round ml-3 p-1 px-2"
              style={{'cursor': 'default'}}
              data-pr-tooltip={lockedTooltip}
              data-pr-position="top"
            >
              <i className="pi pi-lock mr-2" />
              {labels.locked}
            </span>
          </>
        )}
      </div>

      <Accordion activeIndex={accordion1Index} onTabChange={(e) => handleAccordion1TabChange(e.index)}>
        {renderAccordionTab(
          <>
            <b>{labels.whatTitlePart1}</b>
            {labels.whatTitlePart2}
          </>,
          <BookingTemplateWhatStep />,
          accordion1Index === 0,
          false
        )}
        {renderAccordionTab(
          <>
            <b>{labels.whoTitlePart1}</b>
            {labels.whoTitlePart2}
          </>,
          <BookingTemplateWhoStep />,
          accordion1Index === 1,
          false
        )}
        {renderAccordionTab(
          <>
            <b>{labels.whereTitlePart1}</b>
            {labels.whereTitlePart2}
          </>,
          <BookingTemplateWhereStep />,
          accordion1Index === 2,
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.whenTitlePart1}</b>
            {labels.whenTitlePart2}
          </>,
          <BookingTemplateWhenStep />,
          accordion1Index === 3,
          true
        )}
      </Accordion>

      <div className="text-xl pl-5 ml-2 mb-2 mt-4">{labels.additionalTitle}</div>

      <Accordion activeIndex={accordion2Index} onTabChange={(e) => handleAccordion2TabChange(e.index)}>
        {renderAccordionTab(
          <>
            <b>{labels.howTitlePart1}</b> {labels.howTitlePart2}
          </>,
          <BookingTemplateHowStep />,
          accordion2Index === 0,
          false,
          labels.howDescription
        )}
        {renderAccordionTab(
          <>
            {labels.inviteeTitlePart1}
            <b>{labels.inviteeTitlePart2}</b>
          </>,
          <BookingTemplateAdditionalRulesStep />,
          accordion2Index === 1,
          true,
          labels.inviteeSubTitle
        )}
        {renderAccordionTab(
          <>
            <b>{labels.notificationsTitlePart1}</b>
            {labels.notificationsTitlePart2}
          </>,
          <BookingTemplateNotificationsStep />,
          accordion2Index === 2,
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.confirmationTitlePart1}</b>
            {labels.confirmationTitlePart2}
          </>,
          <BookingTemplateConfirmationStep />,
          accordion2Index === 3,
          false
        )}
        {renderAccordionTab(
          <>
            <b>{labels.calendarTitlePart1}</b> {labels.calendarTitlePart2}
          </>,
          <BookingTemplateCalendarStep />,
          accordion2Index === 4,
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.pageTitlePart1}</b> {labels.pageTitlePart2}
          </>,
          <BookingTemplateSettingsStep />,
          accordion2Index === 5,
          false
        )}
      </Accordion>
    </div>
  );
};
