import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../../../store/bookingPages';
import {
  DURATION_TIME_UNIT_OPTIONS,
  MIN_DURATION_COUNT,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../../../store/bookingTemplates';
import { TimeUnit } from '../../../../../API';
import { Tooltip } from 'primereact/tooltip';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import labels from './labels';

type DurationSettingsProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const DurationSettings = ({ selectors, actions, isReadOnly }: DurationSettingsProps) => {
  const dispatch = useDispatch();
  const durationCount = useSelector(selectors.selectDurationCount);
  const durationTimeUnit = useSelector(selectors.selectDurationTimeUnit);
  const isDurationCountValid = useSelector(selectors.selectIsDurationCountValid);

  const handleDurationCountChange = (value: number | null | undefined) => {
    dispatch(actions.updateWhenDuration({ count: value }));
  };

  const handleTimeUnitChange = (timeUnit: TimeUnit) => {
    dispatch(actions.updateWhenDuration({ timeUnit }));
  };

  return (
    <div>
      <div className="grid p-fluid">
        <div className="col-12 font-bold">
          <span>{labels.duration}</span>
          <Tooltip target=".invite-others" className="max-w-30rem" />
          <i
            className="invite-others pi pi-info-circle ml-2"
            data-pr-position="top"
            data-pr-my="center bottom-10"
            data-pr-tooltip={labels.durationTooltip}
          ></i>
        </div>
        <div className="col-6 md:col-2">
          <InputNumber
            id="inputduration"
            className={`${!isDurationCountValid && 'p-invalid'}`}
            showButtons
            min={MIN_DURATION_COUNT}
            max={durationTimeUnit === TimeUnit.MINUTE ? 720 : 12}
            value={durationCount}
            onValueChange={(e) => handleDurationCountChange(e.target.value)}
            disabled={isReadOnly}
          ></InputNumber>
        </div>
        <div className="col-6 md:col-2">
          <Dropdown
            value={durationTimeUnit}
            onChange={(e) => handleTimeUnitChange(e.target.value)}
            options={DURATION_TIME_UNIT_OPTIONS}
            optionLabel="label"
            disabled={isReadOnly}
          />
        </div>
      </div>
    </div>
  );
};

DurationSettings.displayName = 'DurationSettings';
