import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  adminDescription: t('QuickSetupSetupAvailabilityStep:ADMIN_DESCRIPTION'),
  message: t('QuickSetupSetupAvailabilityStep:MESSAGE'),
  next: t('Common:NEXT'),
  setupDaysInputLabel: t('QuickSetupSetupAvailabilityStep:SETUP_DAYS_INPUT_LABEL'),
  setupHoursInputLabel: t('QuickSetupSetupAvailabilityStep:SETUP_HOURS_INPUT_LABEL'),
  timeInValidText: t('Availability:TIME_INVALID_TEXT'),
  title: t('QuickSetupSetupAvailabilityStep:TITLE'),
  userDescription: t('QuickSetupSetupAvailabilityStep:USER_DESCRIPTION'),
  minimumDaysErrorText: t('QuickSetupSetupAvailabilityStep:MINIMUM_DAYS_ERROR_TEXT'),
};
