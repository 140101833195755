import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NEW_AVAILABILITY_NAME,
  newAvailabilityNameModalActions,
  userAvailabilityActions,
  userAvailabilitySelectors,
  DEFAULT_AVAILABILITY,
  DEFAULT_AVAILABILITY_DATA,
  AvailabilityOption,
  LIMIT_30_ITEMS_ERROR_TOAST,
} from '../../store/availability';
import { AvailabilityRecord } from '../../components/availability';
import labels from './labels';
import { Modal, Preloader, SectionTitle } from '../../components/common';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AppDispatch } from '../../store/rootStore';
import { Dropdown } from 'primereact/dropdown';
import { MAX_LENGTH_NAME } from '../../types/constants';
import { timeHandlerService } from '../../services/TimeHandlerService';
import { userSettingsSelectors } from '../../store/userSettings';
import { notificationsActions } from '../../store/notifications';
import { useUserNavigation } from '../../hooks/useUserNavigation';

export const Availability = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const availabilitiesOptions = useSelector(userAvailabilitySelectors.selectUserAvailabilitiesOptions);
  const selectedAvailabilityLink = useSelector(userAvailabilitySelectors.selectUserAvailabilityLink);
  const isFetching = useSelector(userAvailabilitySelectors.selectIsFetching);
  const availabilitiesName = useSelector(userAvailabilitySelectors.selectUserAvailabilitiesNames);
  const isCreateAvail = useSelector(userSettingsSelectors.selectAvailabilityCreate);

  const [nameForNewScheduleDialog, setNameForNewScheduleDialog] = useState('');
  const [isNameEmpty, setIsNameEmpty] = useState(true);
  const [isNameDuplicated, setIsNameDuplicated] = useState(false);
  const STAR_UNICODE = ' \u2605';

  useEffect(() => {
    if (!isInitialVisit) {
      dispatch(userAvailabilityActions.getUserAvailabilityRequest());
    }
    
    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, [isInitialVisit]);

  const onAvailabilityClick = (link: string) => {
    dispatch(userAvailabilityActions.selectAvailabilityThunk(link));
  };

  const handleNewSchedule = () => {
    if (availabilitiesOptions.length >= 30) {
      dispatch(notificationsActions.showToast(LIMIT_30_ITEMS_ERROR_TOAST));
    } else {
      setIsNameEmpty(true);
      setIsNameDuplicated(false);
      dispatch(newAvailabilityNameModalActions.openModal());
    }
  };

  const handleChangeNameCancelDialog = () => {
    dispatch(newAvailabilityNameModalActions.closeModal());
    setNameForNewScheduleDialog('');
  };

  const handleChangeNameSave = () => {
    dispatch(
      userAvailabilityActions.setUserAvailability({
        ...DEFAULT_AVAILABILITY,
        availabilityData: {
          ...DEFAULT_AVAILABILITY_DATA,
          name: nameForNewScheduleDialog.trim(),
        },
      })
    );
    dispatch(userAvailabilityActions.createUserAvailabilityRequest());
    dispatch(newAvailabilityNameModalActions.closeModal());
    setNameForNewScheduleDialog('');
  };

  const handleChangeScheduleName = (name: string) => {
    setNameForNewScheduleDialog(name);
    const tempName = name.trim();
    setIsNameEmpty(tempName.length === 0);
    setIsNameDuplicated(availabilitiesName.includes(tempName));
  };

  const dropdownTemplate = (option: AvailabilityOption) => {
    return option?.label + (option?.isDefault ? STAR_UNICODE : '');
  };

  return (
    <div>
      {/* {isFetching && <Preloader />} */}

      <div className="card mb-3 flex justify-content-between sumo-header-bg">
        <SectionTitle
          id="availabilityTitle"
          icon={isFetching ? 'pi-spin pi-spinner' : 'pi-calendar'}
          title={labels.title}
        />
      </div>

      <div className="card">
        <p className="p-text-bold">{labels.description}</p>

        <div className="mb-3 grid gap-2">
          {availabilitiesOptions.length >= 4 ? (
            <div className="col flex align-items-center">
              <Dropdown
                className="w-full md:w-5"
                value={selectedAvailabilityLink}
                options={availabilitiesOptions}
                optionLabel="label"
                onChange={(e) => onAvailabilityClick(e.value || '')}
                filter
                filterBy="label"
                itemTemplate={(option: AvailabilityOption) => dropdownTemplate(option)}
                valueTemplate={(option: AvailabilityOption) => dropdownTemplate(option)}
              />
            </div>
          ) : (
            <div className="col flex align-items-center overflow-x-auto">
              {availabilitiesOptions.map((option, index) => (
                <Button
                  key={option.value}
                  label={option.label + (index === 0 ? STAR_UNICODE : '')}
                  className={`m-1 mr-3 flex flex-none ${
                    option.value === selectedAvailabilityLink ? 'p-button-info' : 'p-button-text'
                  }`}
                  icon="pi pi-calendar"
                  onClick={() => onAvailabilityClick(option.value || '')}
                />
              ))}
            </div>
          )}

          <div className="col-fixed">
            <Button
              label={labels.newScheduleButton}
              icon="pi pi-plus"
              className="p-button-outlined my-1"
              onClick={handleNewSchedule}
              disabled={!isCreateAvail}
            />
          </div>
        </div>

        <div className="m-1 p-fluid">
          <div className="border-top-1 border-gray-200" style={{ margin: '1rem -1rem' }} />
        </div>

        <AvailabilityRecord />
      </div>

      <Modal.Container name={NEW_AVAILABILITY_NAME}>
        <Modal.Header>
          <div className="text-3xl">{labels.dialogNewTitleName}</div>
        </Modal.Header>
        <div className="w-25rem pt-2 p-fluid">
          <h6>{labels.dialogTitle}</h6>
          <InputText
            id="scheduleName"
            type="text"
            value={nameForNewScheduleDialog}
            onChange={(e) => {
              handleChangeScheduleName(e.target.value);
            }}
            placeholder={labels.dialogPlaceholder}
            className={`${(isNameEmpty || isNameDuplicated) && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
          {isNameDuplicated && (
            <small id="scheduleName-help" className="p-error block">
              {labels.duplicateNameMessage}
            </small>
          )}
        </div>
        <Modal.Buttons>
          <div className="flex gap-2 justify-content-end -mr-2">
            <Button onClick={handleChangeNameCancelDialog} className="p-button-outlined">
              {labels.cancel}
            </Button>
            <Button onClick={handleChangeNameSave} color="primary" autoFocus disabled={isNameEmpty || isNameDuplicated}>
              {labels.save}
            </Button>
          </div>
        </Modal.Buttons>
      </Modal.Container>
    </div>
  );
};
