import { Checkbox } from 'primereact/checkbox';
import labels from './labels';
import {
  DISABLED_PERMISSIONS,
  FULL_PERMISSION_ACTION_LIST,
  FULL_PERMISSION_LIST,
  PERMISSION_LIST,
  Permission,
  rolesActions,
} from '../../../store/roles';
import { useDispatch } from 'react-redux';
import { CreateRoleInput, PermissionAction } from '../../../API';

type RolePermissionsProps = {
  role: CreateRoleInput;
  isPreview?: boolean;
};
export const RolePermissions = ({ role, isPreview }: RolePermissionsProps) => {
  const dispatch = useDispatch();

  const handleChange = (name: string, value: boolean) => {
    if (name === 'isAccessToAllWorkspaces' && value) {
      const allCRUD = FULL_PERMISSION_LIST.reduce(
        (prev, permission) => ({ ...prev, [permission]: FULL_PERMISSION_ACTION_LIST }),
        {}
      );
      dispatch(rolesActions.updateSelectedRole({ [name]: value, ...allCRUD }));
    } else {
      dispatch(rolesActions.updateSelectedRole({ [name]: value }));
    }
  };

  const handlePermissionChange = (permission: Permission, actionType: PermissionAction, checked: boolean) => {
    if (checked) {
      dispatch(rolesActions.checkPermission({ permission, actionType }));
    } else {
      dispatch(rolesActions.uncheckPermission({ permission, actionType }));
    }
  };

  return (
    <>
      {/* <div className="surface-50 text-sm p-2 uppercase">{labels.featureAccessTitle}</div>
      <div className="p-fluid grid p-1 py-3">
        <div className="align-self-end text-right col-8 md:col-4">{labels.chromeExtensionTitle}</div>
        <div className="align-items-center col-4 md:col-1 flex flex-column">
          <label>{labels.active}</label>
          <Checkbox
            checked={role.hasChromeAccess}
            name="hasChromeAccess"
            className="mt-2"
            onChange={(e) => handleChange(e.target.name, !!e.target.checked)}
            disabled={isPreview}
          />
        </div>
      </div> */}

      <div className="surface-50 text-sm p-2 uppercase">{labels.permissionsTitle}</div>
      <div className="flex flex-column p-fluid py-3">
        <div className="grid p-1">
          <div className="col-4"></div>
          {labels.access.map((accessType) => (
            <div className="col-2 md:col-1 md:mx-3 text-center" key={accessType}>
              {accessType}
            </div>
          ))}
        </div>
        {PERMISSION_LIST.map(
          (permission) =>
            permission !== 'resources' &&
            role[permission] && (
              <div className="grid p-1" key={permission}>
                <div className="col-4 text-right">{labels.permissionsLabel[permission]}</div>
                {FULL_PERMISSION_ACTION_LIST.map((action) => (
                  <div className="col-2 md:col-1 md:mx-3 text-center" key={action}>
                    <Checkbox
                      checked={!!role[permission]?.includes(action)}
                      onChange={(e) => handlePermissionChange(permission, action, !!e.target.checked)}
                      disabled={
                        isPreview ||
                        DISABLED_PERMISSIONS.some(
                          (value) => value.permission === permission && value.action.includes(action)
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            )
        )}
      </div>
      <div className="surface-50 text-sm p-2 uppercase">{labels.adminTitle}</div>
      <div className="flex flex-column p-fluid py-3">
        {!role.isAccessToAllWorkspaces && (
          <>
            <div className="grid p-1">
              <div className="col-4"></div>
              {labels.access.map((accessType) => (
                <div className="col-2 md:col-1 md:mx-3 text-center" key={accessType}>
                  {accessType}
                </div>
              ))}
            </div>
            <div className="grid p-1">
              <div className="col-4 text-right">{labels.workspaceAdmin}</div>
              {role.workspaceAdminActions &&
                FULL_PERMISSION_ACTION_LIST.map((action) => (
                  <div className="col-2 md:col-1 md:mx-3 text-center" key={action}>
                    <Checkbox
                      checked={!!role.workspaceAdminActions?.includes(action)}
                      onChange={(e) => handlePermissionChange('workspaceAdminActions', action, !!e.target.checked)}
                      disabled={isPreview}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
        <div className="grid align-items-center pt-2">
          <div className="text-right col-4">{labels.superAdmin}</div>
          <div className="col-2 md:col-1 md:mx-3 text-center">
            <Checkbox
              checked={!!role.isAccessToAllWorkspaces}
              name="isAccessToAllWorkspaces"
              onChange={(e) => handleChange(e.target.name, !!e.target.checked)}
              disabled={isPreview}
            />
          </div>
          <div className="ml-3">{labels.accessToAllWorkspaces}</div>
        </div>
      </div>
    </>
  );
};
