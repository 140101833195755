import { useSelector } from 'react-redux';
import errorImage from '../../../assets/images/error_desert.png';
import { eventSelectors } from '../../../store/publicBookingPage';

export const PublicBookingPageError = () => {
  const error = useSelector(eventSelectors.selectError);

  // const handleHome = () => {
  //   navigationService.navigateTo(Path.Landing);
  // };

  return (
    <>
      {/* <div className="pl-3 ml-1 text-xl font-semibold">{labels.error}</div> */}
      <div className="card mb-2 border-1 surface-border shadow-none">
        <div className="flex flex-column justify-content-center align-items-center">
          <div className="text-xl my-4">{error}</div>
          <div>
            <img src={errorImage} alt="error_desert" />
          </div>
          {/* <div className="mt-4">
            <Button onClick={handleHome} className="w-8rem" label="Home"></Button>
          </div> */}
        </div>
      </div>
    </>
  );
};
