import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TERM_OPTIONS, OPSConsoleUsersActions, OPSConsoleUsersSelectors } from '../../../store/opsConsole/users';
import { useEffect } from 'react';
import { Path } from '../../../routing';
import { Preloader, SectionTitle } from '../../../components/common';
import labels from './labels';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DEFAULT_PRICING_TIER, PRICING_TIER_OPTIONS } from '../../../store/opsConsole/orgs';
import { navigationService } from '../../../services/NavigationService';
import { UserStatus } from '../../../store/users/typings';
import { authentificationSelectors } from '../../../store/authentification';

export const UserDetails = () => {
  const { tenantId, email } = useParams();
  const dispatch = useDispatch();
  const currentUserId = useSelector(authentificationSelectors.selectUserId);
  const isFetching = useSelector(OPSConsoleUsersSelectors.selectIsFetching);
  const userId = useSelector(OPSConsoleUsersSelectors.selectUserId);
  const userName = useSelector(OPSConsoleUsersSelectors.selectUserName);
  const accountName = useSelector(OPSConsoleUsersSelectors.selectAccountName);
  const roleName = useSelector(OPSConsoleUsersSelectors.selectRoleName);
  const userStatus = useSelector(OPSConsoleUsersSelectors.selectUserStatus);
  const firstLogin = useSelector(OPSConsoleUsersSelectors.selectFirstLogin);
  const lastActive = useSelector(OPSConsoleUsersSelectors.selectLastActive);
  const bookedMeetings = useSelector(OPSConsoleUsersSelectors.selectBookedMeetings);
  const term = useSelector(OPSConsoleUsersSelectors.selectTerm);
  const usersAssigned = useSelector(OPSConsoleUsersSelectors.selectUsersAssigned);
  const licenseEndDate = useSelector(OPSConsoleUsersSelectors.selectLicenseEndDate);
  const isMainAdminOrSupport = useSelector(authentificationSelectors.selectIsMainAdminOrSupport);

  useEffect(() => {
    if (tenantId && email) {
      dispatch(OPSConsoleUsersActions.getUserDetailsRequest({ tenantId, email }));
    } else {
      navigateBack();
    }
  }, []);

  const navigateBack = () => {
    navigationService.navigateTo(Path.OPSConsoleUsers);
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle
          id="userDetailsTitle"
          icon="pi-user"
          title={labels.title}
        />
      </div>

      <div className="card">
        <div className="flex justify-content-between align-items-center mb-3">
          <div className="flex align-items-center">
            <Button label={labels.back} icon="pi pi-arrow-left" onClick={navigateBack} outlined />
            <div className="ml-3 text-xl">{`${userName} (${email || ''})`}</div>
          </div>
          {isMainAdminOrSupport && userStatus !== UserStatus.InviteSent && currentUserId !== userId && (
            <Button
              label={labels.viewAsUser}
              onClick={() => {
                window.open(
                  window.location.origin +
                    Path.OPSConsoleViewAsUser.replace(':userId', userId).replace(':tenantId', tenantId || ''),
                  '_blank'
                );
              }}
            />
          )}
        </div>

        <div className="border-1 border-round p-4">
          <div className="text-lg font-bold mb-2">{labels.userInfo}</div>
          <div className="grid justify-content-between">
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="accountName">{labels.accountName}</label>
              <Dropdown value={accountName} options={[accountName]} id="accountName" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="orgId">{labels.orgId}</label>
              <InputText value={tenantId || ''} id="orgId" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="role">{labels.role}</label>
              <InputText value={roleName || ''} id="role" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="status">{labels.status}</label>
              <InputText value={userStatus} id="status" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="registrationDate">{labels.registrationDate}</label>
              <InputText value={firstLogin} id="registrationDate" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="lastActive">{labels.lastActive}</label>
              <InputText value={lastActive} id="lastActive" disabled />
            </div>
            <div className="flex flex-column col-12 md:col-5 col-offset-0 md:col-offset-7">
              <label htmlFor="bookedMeeting">{labels.bookedNumber}</label>
              <InputText value={'' + bookedMeetings} id="bookedMeeting" disabled />
            </div>
          </div>
          <div className="text-lg font-bold mb-2">{labels.licenseStatus}</div>
          <div className="grid justify-content-between">
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="pricingTier">{labels.featurePricingTier}</label>
              <Dropdown value={DEFAULT_PRICING_TIER} options={PRICING_TIER_OPTIONS} id="pricingTier" disabled />
            </div>
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="term">{labels.term}</label>
              <Dropdown value={term} options={TERM_OPTIONS} id="term" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="renewalDate">{labels.renewalDate}</label>
              <InputText value={licenseEndDate} id="renewalDate" disabled />
            </div>
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="activeUsers">{labels.activeUsersNumber}</label>
              <InputText value={'' + usersAssigned} id="activeUsers" disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
