import { combineReducers } from 'redux';
import { OrgsAction, OrgsActionTypes } from './actions';
import { GlobalAction, GlobalActionTypes } from '../../global/actions';
import { OPSConsoleLicense, OrgDetailsType, OrgFilters, OrgsDataTableType, PayLaterDetails } from './types';
import { DEFAULT_FILTER, DEFAULT_PAY_LATER_DETAILS, ORG_STATUS_VALUES } from './constants';

const orgs = (state: OrgsDataTableType[] = [], action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    case OrgsActionTypes.GET_ORGS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const orgDetails = (state = {} as OrgDetailsType, action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    case OrgsActionTypes.GET_ORG_DETAILS_SUCCESS:
      return action.payload;
    case OrgsActionTypes.UPDATE_TENANT:
      return { ...state, tenantRecord: { ...state.tenantRecord, ...action.payload } };
    case OrgsActionTypes.CHANGE_ACCOUNT:
      return { ...state, accountId: action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
    case OrgsActionTypes.GET_ORGS_REQUEST:
      return {} as OrgDetailsType;
    default:
      return state;
  }
};

const license = (state = {} as OPSConsoleLicense, action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    case OrgsActionTypes.GET_ORG_DETAILS_SUCCESS:
      const lastLicense = action.payload.licenseRecords.sort((a, b) =>
        a.endDate && b.endDate && a.endDate > b.endDate ? 1 : -1
      )[0];
      return lastLicense;
    case OrgsActionTypes.UPDATE_LICENSE:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
    case OrgsActionTypes.GET_ORGS_REQUEST:
      return {} as OPSConsoleLicense;
    default:
      return state;
  }
};

const payLaterDetails = (state = DEFAULT_PAY_LATER_DETAILS as PayLaterDetails, action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    // case OrgsActionTypes.GET_ORG_DETAILS_SUCCESS:
    //   const lastLicense = action.payload.licenseRecords.sort((a, b) =>
    //     a.endDate && b.endDate && a.endDate > b.endDate ? 1 : -1
    //   )[0];
    //   return lastLicense;
    case OrgsActionTypes.UPDATE_PAY_LATER:
      return { ...state, ...action.payload };
    case OrgsActionTypes.GET_ORGS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_PAY_LATER_DETAILS as PayLaterDetails;
    default:
      return state;
  }
};

const filter = (state: OrgFilters = DEFAULT_FILTER, action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    case OrgsActionTypes.CHANGE_FILTER:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_FILTER;
    default:
      return state;
  }
};

const isEdited = (state: boolean = false, action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    case OrgsActionTypes.UPDATE_LICENSE:
    case OrgsActionTypes.UPDATE_TENANT:
    case OrgsActionTypes.CHANGE_ACCOUNT:
      return true;
    case OrgsActionTypes.GET_ORG_DETAILS_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    case OrgsActionTypes.GET_ORGS_REQUEST:
    case OrgsActionTypes.GET_ORG_DETAILS_REQUEST:
    case OrgsActionTypes.EXTEND_LICENSE_REQUEST:
    case OrgsActionTypes.EXPIRE_TRIAL_REQUEST:
    case OrgsActionTypes.CONVERT_TO_TEST_REQUEST:
    case OrgsActionTypes.CONVERT_TO_PAY_LATER_REQUEST:
    case OrgsActionTypes.SAVE_ORG_DETAILS_REQUEST:
    case OrgsActionTypes.DELETE_ORG_REQUEST:
      return true;
    case OrgsActionTypes.GET_ORGS_SUCCESS:
    case OrgsActionTypes.GET_ORGS_FAIL:
    case OrgsActionTypes.GET_ORG_DETAILS_SUCCESS:
    case OrgsActionTypes.GET_ORG_DETAILS_FAIL:
    case OrgsActionTypes.EXTEND_LICENSE_SUCCESS:
    case OrgsActionTypes.EXTEND_LICENSE_FAIL:
    case OrgsActionTypes.EXPIRE_TRIAL_SUCCESS:
    case OrgsActionTypes.EXPIRE_TRIAL_FAIL:
    case OrgsActionTypes.CONVERT_TO_TEST_SUCCESS:
    case OrgsActionTypes.CONVERT_TO_TEST_FAIL:
    case OrgsActionTypes.CONVERT_TO_PAY_LATER_SUCCESS:
    case OrgsActionTypes.CONVERT_TO_PAY_LATER_FAIL:
    case OrgsActionTypes.SAVE_ORG_DETAILS_SUCCESS:
    case OrgsActionTypes.SAVE_ORG_DETAILS_FAIL:
    case OrgsActionTypes.DELETE_ORG_SUCCESS:
    case OrgsActionTypes.DELETE_ORG_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: OrgsAction | GlobalAction) => {
  switch (action.type) {
    case OrgsActionTypes.GET_ORGS_FAIL:
    case OrgsActionTypes.GET_ORG_DETAILS_FAIL:
    case OrgsActionTypes.EXTEND_LICENSE_FAIL:
    case OrgsActionTypes.EXPIRE_TRIAL_FAIL:
    case OrgsActionTypes.CONVERT_TO_TEST_FAIL:
    case OrgsActionTypes.CONVERT_TO_PAY_LATER_FAIL:
    case OrgsActionTypes.SAVE_ORG_DETAILS_FAIL:
    case OrgsActionTypes.DELETE_ORG_FAIL:
      return action.error;
    case OrgsActionTypes.GET_ORGS_REQUEST:
    case OrgsActionTypes.GET_ORG_DETAILS_REQUEST:
    case OrgsActionTypes.EXTEND_LICENSE_REQUEST:
    case OrgsActionTypes.EXPIRE_TRIAL_REQUEST:
    case OrgsActionTypes.CONVERT_TO_TEST_REQUEST:
    case OrgsActionTypes.CONVERT_TO_PAY_LATER_REQUEST:
    case OrgsActionTypes.SAVE_ORG_DETAILS_REQUEST:
    case OrgsActionTypes.DELETE_ORG_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  error,
  orgs,
  payLaterDetails,
  orgDetails,
  license,
  isEdited,
  filter,
});
