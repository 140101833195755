export const Common = Object.freeze({
  ACCEPT_INVITE: `Accept Invite`,
  ACCESS_RESTRICT: `Sorry, you are not authorized to access this page`,
  ACTIVATE: `Activate`,
  ALL: `All`,
  ARE_YOU_SURE: `Are you sure?`,
  AUTHORISE: `Authorise`,
  AVAILABLE: `Available`,
  BACK: `Back`,
  CALENDAR_DAYS: `Calendar days`,
  CANCEL: `Cancel`,
  CHOOSE_DATE: `Choose Date`,
  CLEAR: `Clear`,
  CLONE: `Clone`,
  CLOSE: `Close`,
  COMMON: `Common`,
  COMPLETE: `Complete`,
  CONNECT: `Connect`,
  CONNECTED: `Connected`,
  CONFIRM_DELETE: `Confirm Delete`,
  COPY: `Copy`,
  COPY_LINK: `Copy link`,
  COPIED_LINK: `Copied!`,
  COUNTINUE: `Countinue`,
  COUNTRY: `Country`,
  CUSTOM: `Custom`,
  DATE: `Date`,
  DATE_RANGE: `Date range`,
  DAYS: `days`,
  DEACTIVATE: `Deactivate`,
  DEFAULT: `Default`,
  DELETE: `Delete`,
  DESCRIPTION: `Description`,
  DISABLE: `Disable`,
  DISCONNECT: `Disconnect`,
  DOWNLOAD: `Download`,
  EDIT: `Edit`,
  EMAIL: `Email`,
  EMAIL_ALL_SMALL: `email`,
  EMPTY_ERROR: `Can't be empty`,
  ENABLE: `Enable`,
  ERROR: `Error`,
  EXPORT: `Export`,
  FILTER: `Filter`,
  FRIDAYS: `Fridays`,
  FROM: `from`,
  GOOGLE: `Google`,
  HOURS: `hours`,
  HOUR_SHORTENED: `hrs`,
  INFO: `Info`,
  INTERNAL: `Internal`,
  LEARN_MORE: `Learn More`,
  LOCKED: `Locked`,
  LOGIN: `Login`,
  LOG_OUT: `Log out`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `mins`,
  MINUTES: `minutes`,
  MONDAYS: `Mondays`,
  MY_ACCOUNT: `My account`,
  NAME_IS_DUPLICATE: `Oops! This name has already been used. Please enter a different one.`,
  NEXT: `Next`,
  NONE: `None`,
  NO: `No`,
  NO_RESULTS_FOUND: `No results found`,
  NEVER: 'Never',
  OFF: `Off`,
  ON: `On`,
  ONLY_ONE_ACCOUNT: `You may only have one SUMO1 account per email address.`,
  PASSWORD: `password`,
  PHONE_NUMBER: `Phone number`,
  PICTURE_FORMATS: `SVG, JPG, GIF or PNG. Maximum size of 5 MB.`,
  PREVIEW: `Preview`,
  QUICK_SETUP: `Quick Setup`,
  REGISTER: `Register`,
  RESET: `Reset`,
  ROLES: `Roles`,
  SATURDAYS: `Saturdays`,
  SAVE: `Save`,
  SAVE_CHANGES: `Save Changes`,
  SEARCH: `Search`,
  SECOND_SHORTENED: `sec`,
  SELECTED: `Selected`,
  SHARE: `Share`,
  SIGN_OUT: `Sign out`,
  SIGN_UP: `Sign Up`,
  SUCCESS: `Success`,
  SUNDAYS: `Sundyas`,
  TIMEZONE: `TimeZone`,
  THURSDYAS: `Thursdays`,
  TIMES: `Times`,
  TODAY: `Today`,
  TO: `to`,
  TUESDAYS: `Tuesdays`,
  UNAVAILABLE: `Unavailable`,
  UNLINK: `Unlink`,
  UNSAVED_CHANGES: `Any unsaved changes will be lost.`,
  UPLOAD: `Upload`,
  UPLOAD_PICTURE: `Upload picture`,
  USER: `User`,
  USERS: `Users`,
  VIEW: `View`,
  YES: `Yes`,
  YES_CANCEL: `Yes, Cancel`,
  WARNING: `Warning`,
  WEDNESDAYS: `Wednesdays`,
  WEEKDAYS: `Weekdays`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Forbidden Error`,
  TITLE_404: `404 - Page Not Found`,
  TITLE_500: `500 - Internal Server Error`,
  MESSAGE_403_1: `You do not have permission to access `,
  MESSAGE_403_2: ` on this server.`,
  MESSAGE_404_1: `The request URL `,
  MESSAGE_404_2: ` was not found on this server.`,
  MESSAGE_500_1: `The was an error. That's all we know. Please wait and `,
  MESSAGE_500_2: `try reloading`,
  MESSAGE_500_3: ` again.`,
  HOME_BUTTON: `Home`,
});

export const PrivateRoute = Object.freeze({
  ACCOUNT: `Account`,
  ACCOUNT_SETTINGS: `Account Settings`,
  PROFILE: `Profile`,
  ADMIN_SETTINGS: `Admin Settings`,
  AVAILABILITY: `Availability`,
  CALENDAR_CONNECTIONS: `Calendar Connections`,
  HELP_CENTER: `Help Center`,
  HOME: `Home`,
  USER_SETUP: `User Setup`,
  USER_SETTINGS: `User Settings`,
  INTEGRATION: `Integration`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Copyright 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc.  All rights reserved.`,
  PRIVACY_POLICY: `Privacy Policy`,
  SECURITY_POLICY: `Security Policy`,
  TERMS: `Terms`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Accounts`,
  ADMIN_SETTINGS_TITLE: `Admin Settings`,
  ADMIN_TOOLS_TITLE: `Admin Tools`,
  BILLING_TITLE: `Billing`,
  BOOKING_TOOLS_TITLE: `Booking Tools`,
  DASHBOARD_TITLE: `Dashboard`,
  MORE_TOOLS_TITLE: `More Tools`,
  OPERATIONS_TITLE: `Operations`,
  ORGS_TITLE: `Orgs`,
  STAFF_TITLE: `Staff`,
  SUMO1_FOR_GMAIL: `SUMO1 for Gmail`,
  SUPPORT_TITLE: `Support`,
  OPEN_MY_SUMO1: `Open My SUMO1`,
  OPEN_OPS_CONSOLE: `Open OPS Console`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Click here to book a call or virtual meeting talk to an expert.`,
  BOOK_MEETING_TITLE: `Book a meeting`,
  CALL_DESCRIPTION: `Call us anytime Mon-Fri 8am-5pm EST.`,
  CALL_TITLE: `CALL 800-708-1790`,
  CHAT_DESCRIPTION: `Click the "Chat with us" link in the bottom right to see if we're available.`,
  CHAT_TITLE: `Chat now`,
  DESCRIPTION: `Also you may contact sales or support for assistance.`,
  EMAIL_DESCRIPTION: `Email experts@sumoscheduler.com for answers.`,
  EMAIL_TITLE: `Email us`,
  HEADER_TITLE: `Login Failed`,
  HELP_CENTER_DESCRIPTION: `Help Videos, Knowledge Base Articles, and more.`,
  HELP_CENTER_TITLE: `Help Center & KBI`,
  LOG_CASE_DESCRIPTION: `Customers may click here to log a case with customer support.`,
  LOG_CASE_TITLE: `Log-a-case`,
  MESSAGE: `Your "super admin" may login to purchase licenses and resume your use of SUMO1.`,
  TITLE: `License Expired`,
  TITLE_TRIAL: `Free Trial Expired`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Cancels`,
  MEETINGS_BOOKED: `Meetings Booked`,
  REMINDERS_SENT: `Reminders Sent`,
  RESCHEDULES: `Reschedules`,
  TITLE: `My Booking Stats`,
  INSTALL_CHROME_EXTENSION_PART1: `Install SUMO1 Chrome Extension`,
  INSTALL_CHROME_EXTENSION_PART2: `to send email invite links to people from within your inbox, calendar, or CRM.`,
  INVITE_PEOPLE_DESCRIPTION: `Add users to SUMO1 to take advantage of co-hosting, team booking, booking on behalf of, and more.`,
  INVITE_PEOPLE_TITLE: `Invite People`,
  CALENDAR_WARNING_DESCRIPTION: `Your calendar is not connected. You have a risk of double booking if you do not connect a calendar.`,
  CALENDAR_WARNING_BUTTON: `Connect Calendar`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Add a "Book with me" link to your email signature.`,
  STEP1_TITLE: `Setup Email Signature`,
  STEP2_DESCRIPTION: `Send a meeting invite to someone with the chrome extension.`,
  STEP2_TITLE: `Send 1-Click Invite`,
  STEP3_DESCRIPTION: `What else should they test from day 1?`,
  STEP3_TITLE: `Test Another Feature`,
  TITLE: `Start Easy Booking`,
});
