import labels from './labels';

export enum ADD_TO_WEBSITE_TYPE {
  'EMBED' = 'EMBED',
  'POPUP' = 'POPUP',
  'LINK' = 'LINK',
}

export const BUTTON_POSITION = [
  { label: labels.bottomRight, value: 'bottom-right' },
  { label: labels.bottomLeft, value: 'bottom-left' },
  { label: labels.topRight, value: 'top-right' },
  { label: labels.topLeft, value: 'top-left' },
];
export const DEFAULT_BUTTON_POSITION = BUTTON_POSITION[0].value;
export const DEFAULT_LABEL = labels.bookMeeting;
export const ADD_TO_WEBSITE_OPTIONS = [
  { type: ADD_TO_WEBSITE_TYPE.EMBED, label: labels.embedTitle, desc: labels.embedDesc, icon: 'pi-code' },
  { type: ADD_TO_WEBSITE_TYPE.POPUP, label: labels.popupTitle, desc: labels.popupDesc, icon: 'pi-external-link' },
  { type: ADD_TO_WEBSITE_TYPE.LINK, label: labels.linkTitle, desc: labels.linkDesc, icon: 'pi-link' },
];
