import { useDispatch, useSelector } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { MAX_LENGTH_DESCRIPTION } from '../../../types/constants';
import { CustomField } from '../../common/meeting/customField/CustomField';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { CustomFieldInput, CustomFieldType } from '../../../API';
import { Label } from '../../common';
import labels from './labels';
import { Checkbox } from 'primereact/checkbox';

export const PublicBookingPageYourInformation = () => {
  const dispatch = useDispatch();
  const isPreviewMode = useSelector(eventSelectors.selectIsPreviewMode);
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const inputFields = useSelector(eventSelectors.selectFilteredInputFields);
  const hasAdditionalOptions = useSelector(eventSelectors.selectHasAdditionalOptions);
  const event = useSelector(eventSelectors.selectEvent);
  const inviteOthers = useSelector(eventSelectors.selectInviteOthers);
  const enterNote = useSelector(eventSelectors.selectEnterNote);
  const informationLabel = useSelector(eventSelectors.selectInformationLabel);
  const additionalLabel = useSelector(eventSelectors.selectAdditionalLabel);
  const subHeaderStyle = useSelector(eventSelectors.selectSubHeaderStyle);
  const mainTextStyle = useSelector(eventSelectors.selectMainTextStyle);
  const isSMSEnabled = useSelector(eventSelectors.selectIsSMSEnabled);
  const isSMSAllowed = useSelector(eventSelectors.selectIsSMSAllowed);

  const handleCustomFieldChange = (customField: CustomFieldInput) => {
    dispatch(eventActions.updateCustomField(customField));
  };

  const handleChangeGuestEmails = (value: string) => {
    dispatch(eventActions.updateEvent({ guestEmails: value.split(',').map((item) => item.trim()) }));
  };

  const handleChangeNote = (value: string) => {
    dispatch(eventActions.updateEvent({ note: value }));
  };

  const handleInformationLabelChange = (informationLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ informationLabel }));
  };

  const handleAdditionalLabelChange = (additionalLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ additionalLabel }));
  };

  const handleSMSAllowedChange = (isSMSAllowed: boolean) => {
    dispatch(eventActions.updateEvent({isSMSAllowed}));
  };

  const renderSubHeaderLabel = (value: string, onChange: (value: string) => void) => (
    <Label
      editClassName="hover:text-primary"
      textClassName="text-xl font-semibold"
      textStyle={subHeaderStyle}
      editable={isEditable}
      value={value}
      onBlurUpdate={onChange}
    />
  );

  return (
    <>
      <div className="pl-3 ml-1 mt-3">{renderSubHeaderLabel(informationLabel, handleInformationLabelChange)}</div>

      <div className="card mb-0 mt-1 border-1 surface-border shadow-none">
        <div className="grid p-fluid -m-3" style={mainTextStyle}>
          {inputFields?.map(
            (customField) =>
              customField?.enabled && (
                <div key={customField.id} className="col-12 md:col-6 p-3">
                  <CustomField
                    customField={customField}
                    onChange={handleCustomFieldChange}
                    disabled={isPreviewMode}
                  ></CustomField>
                  {isSMSEnabled && customField.fieldType === CustomFieldType.PHONE && (
                    <div className="mt-3">
                      <Checkbox
                        inputId="allowSms"
                        checked={isSMSAllowed}
                        onChange={(e) => handleSMSAllowedChange(Boolean(e.checked))}
                      />
                      <label htmlFor="allowSms" className="cursor-pointer ml-2">
                        {labels.allowSms}
                      </label>
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </div>

      {hasAdditionalOptions && (
        <>
          <div className="pl-3 ml-1 mt-3">{renderSubHeaderLabel(additionalLabel, handleAdditionalLabelChange)}</div>
          <div className="card mb-0 mt-1 border-1 surface-border shadow-none">
            <div className="grid p-fluid -m-3">
              {inviteOthers && (
                <div className="col-12 md:col-6 p-3 flex flex-column">
                  <label htmlFor="inviteOthers">{labels.inviteOthers}</label>
                  <InputTextarea
                    id="inviteOthers"
                    autoResize
                    rows={4}
                    placeholder={labels.inviteOthersPlaceholder}
                    value={event.guestEmails?.join(',') || ''}
                    onChange={(e) => handleChangeGuestEmails(e.target.value.trimStart())}
                    maxLength={1000}
                    disabled={isPreviewMode}
                  />
                </div>
              )}
              {enterNote && (
                <div className="col-12 md:col-6 p-3 flex flex-column">
                  <label htmlFor="noteForAttendees">{labels.noteForAttendees}</label>
                  <InputTextarea
                    id="noteForAttendees"
                    autoResize
                    rows={4}
                    placeholder={labels.notePlaceholder}
                    value={event.note || ''}
                    onChange={(e) => handleChangeNote(e.target.value.trimStart())}
                    maxLength={MAX_LENGTH_DESCRIPTION}
                    disabled={isPreviewMode}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
