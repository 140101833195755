import { useContext, useEffect } from 'react';
import AppMenu from './AppMenu';
import { LayoutContext } from '../context/layoutcontext';
import { MenuProvider } from '../context/menucontext';
import { Link } from 'react-router-dom';

const AppSidebar = (props) => {
  const { setLayoutState, layoutState } = useContext(LayoutContext); // { setLayoutState, layoutConfig, layoutState }
  const anchor = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      anchored: !prevLayoutState.anchored,
    }));
  };

  // const logoColor = () => {
  //   let logo;

  //   if (layoutConfig.colorScheme == 'light') {
  //     logo = layoutConfig.menuTheme === 'white' || layoutConfig.menuTheme === 'orange' ? 'dark' : 'white';
  //   } else {
  //     logo = 'dark';
  //   }
  //   return logo;
  // };

  useEffect(() => {
    return () => {
      resetOverlay();
    };
  }, []);

  const resetOverlay = () => {
    if (layoutState.overlayMenuActive) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        overlayMenuActive: false,
      }));
    }
  };

  let timeout = null;

  const onMouseEnter = () => {
    if (!layoutState.anchored) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        sidebarActive: true,
      }));
    }
  };

  const onMouseLeave = () => {
    if (!layoutState.anchored) {
      if (!timeout) {
        timeout = setTimeout(
          () =>
            setLayoutState((prevLayoutState) => ({
              ...prevLayoutState,
              sidebarActive: false,
            })),
          300
        );
      }
    }
  };

  return (
    <>
      <div ref={props.sidebarRef} className="layout-sidebar" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className="sidebar-header">
          <Link href="/" className="logo">
            <div className="logo-image">
              <i className="pi pi-bars text-2xl text-color"></i>
            </div>
            <div className="app-name">
              Menu
            </div>
          </Link>
          <button className="layout-sidebar-anchor p-link" type="button" onClick={anchor}></button>
        </div>
        <div className="layout-menu-container">
          <MenuProvider>
            <AppMenu />
          </MenuProvider>
        </div>
      </div>
    </>
  );
};

export default AppSidebar;
