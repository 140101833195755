import { t } from '../../i18n/i18n';
import {
  AssignmentMode,
  BookingPageLabelsInput,
  BookingPageStyleInput,
  CalendarFormat,
  LocationType,
  LookAndFeelInput,
  RescheduleWith,
  TimeFormat,
  TimeZoneType,
  UpdateBookingPageInput,
} from '../../API';
import { TIME_ZONE_OPTIONS } from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';
import urlConstants from '../../shared/JSON/urlConstants.json';
import {
  DEFAULT_COLOR,
  DEFAULT_CONFIRMATIONS,
  DEFAULT_INPUT_FIELDS,
  DEFAULT_NOTIFICATIONS,
  DEFAULT_WHEN,
  DEFAULT_WHERE,
} from '../bookingTemplates';
import { VideoConferencesEnum } from '../../shared/backend/constants';
import { AccordionIndexes } from './types';

export const CLONE_NAME_POSTFIX = ' (copy)';
export const FILE_COPY_POSTFIX = '_copy';
export const EMPTY_ACCORDION_INDEXES: AccordionIndexes = {
  first: null,
  second: null,
};
export const FILTER_OPTION_TEAMS = "teams";
export const VIDEO_CONFERENCE_OPTIONS = {
  [VideoConferencesEnum.ZOOM]: t('EditBookingTemplateWhereStep:ZOOM'),
  [VideoConferencesEnum.GOOGLE_MEET]: t('EditBookingTemplateWhereStep:GOOGLE_MEET'),
  [VideoConferencesEnum.MICROSOFT_TEAMS]: t('EditBookingTemplateWhereStep:MICROSOFT_TEAMS'),
};

export const LOCATION_TYPE_ICONS = {
  [LocationType.VIDEO_CONFERENCE]: 'pi pi-video',
  [LocationType.PHONE_CALL]: 'pi pi-phone',
  [LocationType.IN_PERSONAL]: 'pi pi-home',
};

export const TIME_FORMAT_OPTIONS = {
  [TimeFormat.HOUR_12]: t('BookingPageCalendarStep:HOUR_12'),
  [TimeFormat.HOUR_24]: t('BookingPageCalendarStep:HOUR_24'),
};
export const CALENDAR_FORMAT_OPTIONS = {
  [CalendarFormat.MONTH_VIEW]: t('BookingPageCalendarStep:MONTH_VIEW'),
  [CalendarFormat.WEEK_VIEW]: t('BookingPageCalendarStep:WEEK_VIEW'),
};
export const INTERVAL_OPTIONS = [
  { value: 15, label: t('BookingPageCalendarStep:INTERVALS_15_MIN') },
  { value: 20, label: t('BookingPageCalendarStep:INTERVALS_20_MIN') },
  { value: 30, label: t('BookingPageCalendarStep:INTERVALS_30_MIN') },
  { value: 60, label: t('BookingPageCalendarStep:INTERVALS_60_MIN') },
];
export const TOP_OF_INTERVAL_LABEL = t('BookingPageCalendarStep:TOP_OF_THE_INTERVAL');
export const INVITE_OTHERS_SHORT = t('BookingPageSettingsStep:INVITE_OTHERS_SHORT');
export const ENTER_NOTE_SHORT = t('BookingPageSettingsStep:ENTER_NOTE_SHORT');

export const DEFAULT_INTERVAL_COUNT = 15;
export const DEFAULT_BOOKING_PAGE_NAME = t('DefaultBookingPage:NAME');
export const DEFAULT_BOOKING_PAGE_HOW: BookingPageLabelsInput = {
  eventNameLabel: t('DefaultBookingPage:BOOK_A_MEETING'),
  detailsLabel: t('DefaultBookingPage:MEETING_DETAILS'),
  dateLabel: t('DefaultBookingPage:SELECT_DATE'),
  timeLabel: t('DefaultBookingPage:SELECT_TIME'),
  informationLabel: t('DefaultBookingPage:YOUR_INFORMATION'),
  additionalLabel: t('DefaultBookingPage:MORE_OPTIONS'),
  cancelLabel: t('DefaultBookingPage:CANCEL'),
  bookButtonLabel: t('DefaultBookingPage:BOOK_MEETING_BUTTON'),
  updateButtonLabel: t('DefaultBookingPage:SAVE_MEETING_BUTTON'),
  bookAnotherButtonLabel: t('DefaultBookingPage:BOOK_ANOTHER_MEETING_BUTTON'),
  cancelButtonLabel: t('DefaultBookingPage:CANCEL_MEETING_BUTTON'),
  bookTitle: t('DefaultBookingPage:MEETING_BOOKED_TITLE'),
  bookDescription: t('DefaultBookingPage:MEETING_BOOKED_DESCRIPTION'),
  rescheduleTitle: t('DefaultBookingPage:MEETING_RESCHEDULED_TITLE'),
  rescheduleDescription: t('DefaultBookingPage:MEETING_RESCHEDULED_DESCRIPTION'),
  cancelTitle: t('DefaultBookingPage:MEETING_CANCELED_TITLE'),
  cancelDescription: t('DefaultBookingPage:MEETING_CANCELED_DESCRIPTION'),
};
export const DEFAULT_LOOK_AND_FEEL: LookAndFeelInput = {
  siteColorPrimary: '#FFFFFF',
  siteColorBackground: '#F5F5F5',
  siteColorButtons: '#2196F3',
  fontColorHeader: '#495057',
  fontColorSubHeader: '#495057',
  fontColorMainText: '#495057',
  fontHeader: 'Nunito',
  fontSubheader: 'Nunito',
  fontMainText: 'Nunito',
};
export const DEFAULT_OPACITY = 60;
export const DEFAULT_STYLE: BookingPageStyleInput = {
  avatar: null,
  backgroundImage: null,
  lookAndFeel: DEFAULT_LOOK_AND_FEEL,
  opacity: DEFAULT_OPACITY,
  css: null,
  footer: `<a href="${urlConstants.NAME_URL_SITE}" target="_blank">Powered by SUMO</a>`,
};
export const DEFAULT_BOOKING_PAGE_DATA: UpdateBookingPageInput = {
  id: '',
  workspaceId: '',
  inviteOthers: false,
  enterNote: false,
  adminOnly: false,
  labels: DEFAULT_BOOKING_PAGE_HOW,
  style: DEFAULT_STYLE,
  calendar: {
    timeFormat: TimeFormat.HOUR_12,
    calendarFormat: CalendarFormat.MONTH_VIEW,
    timeInterval: DEFAULT_INTERVAL_COUNT,
    topOfInterval: false,
    timeZoneType: TimeZoneType.DETECT_INVITEE,
    selectedTimeZone: [],
    defaultTimeZone: null,
  },
  what: {
    customName: DEFAULT_BOOKING_PAGE_NAME,
    isActive: true,
    color: DEFAULT_COLOR,
    bookingTemplateId: undefined,
  },
  when: DEFAULT_WHEN,
  where: DEFAULT_WHERE,
  who: {
    hostAssignmentMode: AssignmentMode.ROUND_ROBIN,
    hostRescheduleWith: RescheduleWith.ASSIGNED_HOST,
  },
  inputFields: DEFAULT_INPUT_FIELDS,
  notifications: DEFAULT_NOTIFICATIONS,
  confirmationsPage: DEFAULT_CONFIRMATIONS,
};

export const CLONE_BOOKING_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('BookingPagesToastsNotifications:CLONE_BOOKING_PAGES_ERROR_MESSAGE'),
});
export const CLONE_BOOKING_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('BookingPagesToastsNotifications:CLONE_BOOKING_PAGES_SUCCESS_MESSAGE'),
});
export const DELETE_BOOKING_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('BookingPagesToastsNotifications:DELETE_BOOKING_PAGES_ERROR_MESSAGE'),
});
export const DELETE_BOOKING_PAGES_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('BookingPagesToastsNotifications:DELETE_BOOKING_PAGES_SUCCESS_MESSAGE'),
});
export const GET_BOOKING_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('BookingPagesToastsNotifications:GET_BOOKING_PAGES_ERROR_MESSAGE'),
});
export const GET_BOOKING_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('BookingPagesToastsNotifications:GET_BOOKING_PAGE_ERROR_MESSAGE'),
});
export const SAVE_BOOKING_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('BookingPagesToastsNotifications:SAVE_BOOKING_PAGE_ERROR_MESSAGE'),
});
export const SAVE_BOOKING_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('BookingPagesToastsNotifications:SAVE_BOOKING_PAGE_SUCCESS_MESSAGE'),
});

export const AVAILABLE_TIME_ZONE_OPTIONS = TIME_ZONE_OPTIONS.flatMap(({ zones }) =>
  zones.map(({ city, time, timeZone }) => ({ name: `${city} - ${time}`, value: timeZone }))
);
