import { memo, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { CustomType, CustomFieldInput } from '../../../../API';
import { MULTISELECT_DELIMITER } from '../../../../store/bookingTemplates';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_SHORT_ANSWER } from '../../../../types/constants';
import { PhoneNumber } from '../../phoneNumber/PhoneNumber';
import { validateCustomField } from '../../../../store/publicBookingPage/utils';

interface ICustomField {
  customField: CustomFieldInput;
  onChange?: (customField: CustomFieldInput) => void;
  disabled?: boolean;
}

export const CustomField = memo(({ customField, onChange, disabled }: ICustomField) => {
  const [value, setValue] = useState<string>(customField.value || '');
  const className = !customField.enabled ? 'bg-gray-200' : validateCustomField(customField) ? '' : 'p-invalid';

  const handleChange = (value: string) => {
    setValue(value);
    onChange && onChange({ ...customField, value });
  };

  const handleTextChange = (value: string) => {
    setValue(value);
  };

  const handleTextBlur = () => {
    handleChange(value.trim());
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const values = (value && value.split(MULTISELECT_DELIMITER)) || [];
    const updatedValues = e.checked ? [...values, e.value] : values.filter((item) => item !== e.value);
    handleChange(updatedValues.join(MULTISELECT_DELIMITER));
  };

  const handleRadioChange = (e: RadioButtonChangeEvent) => {
    handleChange(e.value);
  };

  const handleDropdownChange = (value: string) => {
    handleChange(value);
  };

  const handlePhoneChange = (phone: string) => {
    setValue(`${value.split('-')[0]}-${phone}`);
  };

  const handlePhoneBlur = () => {
    handleChange(value.trim());
  };

  const handlePhoneCodeChange = (code: string) => {
    handleChange(`${code}-${value?.slice(value.indexOf('-') + 1)}`);
  };

  return (
    <div className="flex flex-column">
      <div>
        <span>{customField.label}</span>
        {customField.required && <span className="text-red-500"> *</span>}
      </div>

      {customField.type === CustomType.TEXT && (
        <InputText
          disabled={!customField.enabled || disabled}
          className={className}
          value={value}
          onChange={(e) => handleTextChange(e.target.value)}
          onBlur={handleTextBlur}
          maxLength={MAX_LENGTH_SHORT_ANSWER}
        />
      )}

      {customField.type === CustomType.TEXTAREA && (
        <InputTextarea
          disabled={!customField.enabled || disabled}
          className={className}
          autoResize
          rows={3}
          value={value}
          onChange={(e) => handleTextChange(e.target.value)}
          onBlur={handleTextBlur}
          maxLength={MAX_LENGTH_DESCRIPTION}
        />
      )}

      {customField.type === CustomType.PHONE && (
        // format BY_375-xxxxxxxx
        <PhoneNumber
          countryCode={value.split('-')[0] || ''}
          handleChangeCode={handlePhoneCodeChange}
          phoneNumber={value?.slice(value.indexOf('-') + 1) || ''}
          handleChangePhone={handlePhoneChange}
          handleBlurPhone={handlePhoneBlur}
          inputClassName={className}
          disabled={!customField.enabled || disabled}
        />
      )}

      {customField.type === CustomType.CHECKBOXES &&
        customField.answers?.map((answer, index) => (
          <div key={index} className="mb-1 text-color">
            <Checkbox
              inputId={(customField.label || '') + index}
              disabled={!customField.enabled || disabled}
              className={className}
              value={answer}
              checked={!!value.split(MULTISELECT_DELIMITER).find((item) => item === answer)}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={(customField.label || '') + index} className="cursor-pointer ml-2">
              {answer}
            </label>
          </div>
        ))}

      {customField.type === CustomType.RADIO_BUTTONS &&
        customField.answers?.map((answer, index) => (
          <div key={index} className="mt-1 text-color">
            <RadioButton
              inputId={(customField.label || '') + index}
              disabled={!customField.enabled || disabled}
              className={className}
              value={answer}
              checked={value === answer}
              onChange={handleRadioChange}
            />
            <label htmlFor={(customField.label || '') + index} className="cursor-pointer ml-2">
              {answer}
            </label>
          </div>
        ))}

      {customField.type === CustomType.SELECT && (
        <Dropdown
          disabled={!customField.enabled || disabled}
          className={className}
          value={value}
          onChange={(e) => handleDropdownChange(e.target.value)}
          options={(customField.answers || []).map((answer) => answer)}
        />
      )}
    </div>
  );
});

CustomField.displayName = 'CustomField';
