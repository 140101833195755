export const Common = Object.freeze({
  ACCEPT_INVITE: `Aceptar invitación`,
  ACCESS_RESTRICT: `Lo siento, no tienes autorización para acceder a esta página`,
  ACTIVATE: `Activar`,
  ALL: `Todo`,
  ARE_YOU_SURE: `¿Estás seguro?`,
  AUTHORISE: `Autorizar`,
  AVAILABLE: `Disponible`,
  BACK: `Volver`,
  CALENDAR_DAYS: `Días de calendario`,
  CANCEL: `Cancelar`,
  CHOOSE_DATE: `Elegir fecha`,
  CLEAR: `Limpiar`,
  CLONE: `Clonar`,
  CLOSE: `Cerrar`,
  COMMON: `Común`,
  COMPLETE: `Completar`,
  CONNECT: `Conectar`,
  CONNECTED: `Conectado`,
  CONFIRM_DELETE: `Confirmar eliminación`,
  COPY: `Copiar`,
  COPY_LINK: `Copiar enlace`,
  COPIED_LINK: `¡Copiado!`,
  COUNTINUE: `Continuar`,
  COUNTRY: `País`,
  CUSTOM: `Personalizado`,
  DATE: `Fecha`,
  DATE_RANGE: `Rango de fechas`,
  DAYS: `días`,
  DEACTIVATE: `Desactivar`,
  DEFAULT: `Predeterminado`,
  DELETE: `Eliminar`,
  DESCRIPTION: `Descripción`,
  DISABLE: `Desactivar`,
  DISCONNECT: `Desconectar`,
  DOWNLOAD: `Descargar`,
  EDIT: `Editar`,
  EMAIL: `Correo electrónico`,
  EMAIL_ALL_SMALL: `correo electrónico`,
  EMPTY_ERROR: `No puede estar vacío`,
  ENABLE: `Habilitar`,
  ERROR: `Error`,
  EXPORT: `Exportar`,
  FILTER: `Filtrar`,
  FRIDAYS: `Viernes`,
  FROM: `desde`,
  GOOGLE: `Google`,
  HOURS: `horas`,
  HOUR_SHORTENED: `hrs`,
  INFO: `Información`,
  INTERNAL: `Interno`,
  LEARN_MORE: `Más información`,
  LOCKED: `Bloqueado`,
  LOGIN: `Iniciar sesión`,
  LOG_OUT: `Cerrar sesión`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `mins`,
  MINUTES: `minutos`,
  MONDAYS: `Lunes`,
  MY_ACCOUNT: `Mi cuenta`,
  NAME_IS_DUPLICATE: `¡Ups! Este nombre ya se ha utilizado. Por favor, ingrese uno diferente.`,
  NEXT: `Siguiente`,
  NONE: `Ninguno`,
  NO: `No`,
  NO_RESULTS_FOUND: `No se encontraron resultados`,
  NEVER: 'Nunca',
  OFF: `Apagar`,
  ON: `Encender`,
  ONLY_ONE_ACCOUNT: `Solo puedes tener una cuenta de SUMO1 por dirección de correo electrónico.`,
  PASSWORD: `Contraseña`,
  PHONE_NUMBER: `Número de teléfono`,
  PICTURE_FORMATS: `SVG, JPG, GIF o PNG. Tamaño máximo de 5 MB.`,
  PREVIEW: `Vista previa`,
  QUICK_SETUP: `Configuración rápida`,
  REGISTER: `Registrarse`,
  RESET: `Restablecer`,
  ROLES: `Roles`,
  SATURDAYS: `Sábados`,
  SAVE: `Guardar`,
  SAVE_CHANGES: `Guardar cambios`,
  SEARCH: `Buscar`,
  SECOND_SHORTENED: `seg`,
  SELECTED: `Seleccionado`,
  SHARE: `Compartir`,
  SIGN_OUT: `Cerrar sesión`,
  SIGN_UP: `Registrarse`,
  SUNDAYS: `Domingos`,
  SUCCESS: `Éxito`,
  TIMEZONE: `Zona horaria`,
  THURSDAYS: `Jueves`,
  TIMES: `Veces`,
  TODAY: `Hoy`,
  TUESDAYS: `Martes`,
  UNAVAILABLE: `No disponible`,
  UNLINK: `Desvincular`,
  UNSAVED_CHANGES: `Se perderán todos los cambios no guardados.`,
  UPLOAD: `Subir`,
  UPLOAD_PICTURE: `Subir imagen`,
  USER: `Usuario`,
  USERS: `Usuarios`,
  VIEW: `Ver`,
  YES: `Sí`,
  YES_CANCEL: `Sí, Cancelar`,
  WARNING: `Advertencia`,
  WEDNESDAYS: `Miércoles`,
  WEEKDAYS: `Días de la semana`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Error prohibido`,
  TITLE_404: `404 - Página no encontrada`,
  TITLE_500: `500 - Error interno del servidor`,
  MESSAGE_403_1: `No tienes permiso para acceder a `,
  MESSAGE_403_2: ` en este servidor.`,
  MESSAGE_404_1: `La URL solicitada `,
  MESSAGE_404_2: ` no se encontró en este servidor.`,
  MESSAGE_500_1: `Hubo un error. Eso es todo lo que sabemos. Por favor, espera y `,
  MESSAGE_500_2: `intenta recargar`,
  MESSAGE_500_3: ` de nuevo.`,
  HOME_BUTTON: `Inicio`,
});

export const PrivateRoute = Object.freeze({
  ACCOUNT: `Cuenta`,
  ACCOUNT_SETTINGS: `Configuración de cuenta`,
  PROFILE: `Perfil`,
  ADMIN_SETTINGS: `Configuración de administrador`,
  AVAILABILITY: `Disponibilidad`,
  CALENDAR_CONNECTIONS: `Conexiones de calendario`,
  HELP_CENTER: `Centro de Ayuda`,
  HOME: `Inicio`,
  USER_SETUP: `Configuración de usuario`,
  USER_SETTINGS: `Configuración de usuario`,
  INTEGRATION: `Integración`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Derechos de autor 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc. Todos los derechos reservados.`,
  PRIVACY_POLICY: `Política de privacidad`,
  SECURITY_POLICY: `Política de seguridad`,
  TERMS: `Términos`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Cuentas`,
  ADMIN_SETTINGS_TITLE: `Configuración de admin`,
  ADMIN_TOOLS_TITLE: `Herramientas de admin`,
  BILLING_TITLE: `Facturación`,
  BOOKING_TOOLS_TITLE: `Herramientas de reserva`,
  DASHBOARD_TITLE: `Tablero`,
  MORE_TOOLS_TITLE: `Más herramientas`,
  OPERATIONS_TITLE: `Operaciones`,
  ORGS_TITLE: `Organizaciones`,
  STAFF_TITLE: `Personal`,
  SUMO1_FOR_GMAIL: `SUMO1 para Gmail`,
  SUPPORT_TITLE: `Soporte`,
  OPEN_MY_SUMO1: `Abrir Mi SUMO1`,
  OPEN_OPS_CONSOLE: `Abrir Consola OPS`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Haz clic aquí para reservar una llamada o reunión virtual con un experto.`,
  BOOK_MEETING_TITLE: `Reservar una reunión`,
  CALL_DESCRIPTION: `Llámanos en cualquier momento de lunes a viernes de 8 am a 5 pm (EST).`,
  CALL_TITLE: `LLAMAR AL 800-708-1790`,
  CHAT_DESCRIPTION: `Haz clic en el enlace "Chatea con nosotros" en la esquina inferior derecha para ver si estamos disponibles.`,
  CHAT_TITLE: `Chatear ahora`,
  DESCRIPTION: `También puedes ponerte en contacto con ventas o soporte para obtener ayuda.`,
  EMAIL_DESCRIPTION: `Envía un correo electrónico a experts@sumoscheduler.com para obtener respuestas.`,
  EMAIL_TITLE: `Envíanos un correo electrónico`,
  HEADER_TITLE: `Inicio de sesión fallido`,
  HELP_CENTER_DESCRIPTION: `Videos de ayuda, artículos de la base de conocimientos y más.`,
  HELP_CENTER_TITLE: `Centro de ayuda y KBI`,
  LOG_CASE_DESCRIPTION: `Los clientes pueden hacer clic aquí para registrar un caso con soporte al cliente.`,
  LOG_CASE_TITLE: `Registrar un caso`,
  MESSAGE: `Tu "superadministrador" puede iniciar sesión para comprar licencias y reanudar el uso de SUMO1.`,
  TITLE: `Licencia caducada`,
  TITLE_TRIAL: `Prueba gratuita caducada`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Cancelaciones`,
  MEETINGS_BOOKED: `Reuniones reservadas`,
  REMINDERS_SENT: `Recordatorios enviados`,
  RESCHEDULES: `Reprogramaciones`,
  TITLE: `Mis estadísticas de reserva`,
  INSTALL_CHROME_EXTENSION_PART1: `Instala la extensión de Chrome de SUMO1`,
  INSTALL_CHROME_EXTENSION_PART2: `para enviar enlaces de invitación por correo electrónico desde tu bandeja de entrada, calendario o CRM.`,
  INVITE_PEOPLE_DESCRIPTION: `Agrega usuarios a SUMO1 para aprovechar la co-organización, la reserva de equipos, la reserva en nombre de y más.`,
  INVITE_PEOPLE_TITLE: `Invitar personas`,
  CALENDAR_WARNING_DESCRIPTION: `Su calendario no está conectado. Tiene el riesgo de hacer una doble reserva si no conecta un calendario.`,
  CALENDAR_WARNING_BUTTON: `Conectar calendario`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Añade un enlace "Reservar conmigo" a tu firma de correo electrónico.`,
  STEP1_TITLE: `Configurar firma de correo electrónico`,
  STEP2_DESCRIPTION: `Envía una invitación a una reunión a alguien con la extensión de Chrome.`,
  STEP2_TITLE: `Enviar invitación con un clic`,
  STEP3_DESCRIPTION: `¿Qué más deberían probar desde el primer día?`,
  STEP3_TITLE: `Probar otra función`,
  TITLE: `Comienza con la reserva fácil`,
});
