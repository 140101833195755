import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { logoutUser, deleteAndCreateUser, generateCookies, getLicenses } from './service';
import { AuthentificationActionTypes, authentificationActions } from './actions';
import {
  CHOOSE_AN_ACCOUNT_ERROR_TOAST,
  ENCRYPTED_USER_ID,
  GET_USER_AUTH_ERROR_TOAST,
  GET_USER_LOGIN_ERROR_TOAST,
  GET_USER_LOGOUT_ERROR_TOAST,
  THIRD_PARTY,
  USER_CONTEXT_PARAM,
} from './constants';
import { UserSettingsKeys } from '../userSettings';
import { authentificationSelectors } from './selectors';
import { notificationsActions } from '../notifications';
import { CreateLicenseInput, CreateTenantInput, CreateUserDataInput, IntegrationType, LicenseType } from '../../API';
import { getAuthResponse, handleAuthResponse } from './inviteSagas';
import { globalActions } from '../global';
import { getTenantInfo } from '../workspaces/service';
import { ThirdPartyType } from './typings';
import { getUserProfile } from '../userSettings/service';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { SUMO1TenantId } from '../../shared/backend/constants';
import { billingActions } from '../billing';
import { handleServiceError } from '../utils/reduxUtils';
import { VIEW_AS_USER_FAIL_TOAST } from '../opsConsole/staff';
import { changeSeatsOrTerm } from '../billing/service';
import { SERVER_ERROR_CODES } from '../../types/constants';
import { BUY_LICENSE_SUCCESS_TOAST } from '../users/constants';

// TODO: find out how to describe function generator with typeScript
function* thirdPartyAuthSaga({ payload }: any): any {
  try {
    const {
      type,
      codeResponse,
      userTimeZone,
      switchAccount,
      updateIntegration,
      integrationType,
      inviteTenantId,
      login,
    } = payload;

    const authResponse = yield call(
      getAuthResponse as any,
      type,
      codeResponse,
      userTimeZone,
      switchAccount,
      updateIntegration,
      integrationType,
      inviteTenantId,
      login,
    );
    // Handle the authentication response
    yield call(handleAuthResponse, authResponse, type, switchAccount, updateIntegration, inviteTenantId, login);
  } catch (error: unknown) {
    if (payload.updateIntegration) {
      // handle error in userSettings.connectExternalCalendarSaga
      throw error;
    } else {
      const errorMessage = error?.toString() || ''; // TODO: make for login error the separate code and move this logic to handleServiceError
      if (errorMessage.includes(SERVER_ERROR_CODES.NotFound)) {
        yield call(handleServiceError, error, GET_USER_LOGIN_ERROR_TOAST);
      } else {
        yield call(handleServiceError, error, GET_USER_AUTH_ERROR_TOAST);
        yield put(authentificationActions.logoutUserRequest(Path.Landing));
      }
      yield put(authentificationActions.thirdPartyAuthFail(error?.toString()));
    }
  }
}

function* chooseAccountSaga(action: ReturnType<typeof authentificationActions.chooseAnAccountRequest>): any {
  try {
    if (action.type === AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST) {
      const { email, tenantId } = action.payload;

      yield call(deleteAndCreateUser, email, tenantId);

      if (tenantId) {
        // localStorage.setItem(UserSettingsKeys.TENANT_ID, tenantId);
        // yield put(authentificationActions.updateUserDataCore({ tenantId: tenantId }));

        yield put(globalActions.getMainDataRequest()); // let's start authentication
        yield put(authentificationActions.chooseAnAccountSuccess());
      } else {
        throw new Error('tenantId in chosen account was not found');
      }
    }
  } catch (error: unknown) {
    yield call(handleServiceError, error, CHOOSE_AN_ACCOUNT_ERROR_TOAST);
    yield put(authentificationActions.chooseAnAccountFail(error?.toString()));
  }
}

function* clearLocalStorage(): any {
  localStorage.removeItem(UserSettingsKeys.USER_ID);
  localStorage.removeItem(UserSettingsKeys.TENANT_ID);
  localStorage.removeItem(UserSettingsKeys.LINK);

  localStorage.removeItem(UserSettingsKeys.WORKSPACE_ID);
  localStorage.removeItem(THIRD_PARTY);
  localStorage.removeItem(UserSettingsKeys.SUMO1_STAFF_DATA);
  localStorage.removeItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS);
  localStorage.removeItem(ENCRYPTED_USER_ID);
  localStorage.removeItem(USER_CONTEXT_PARAM);
}

function* resetViewAsUserSaga(action: ReturnType<typeof authentificationActions.resetViewAsUserRequest>) {
  try {
    if (action.type === AuthentificationActionTypes.RESET_VIEW_AS_USER_REQUEST) {
      const staffUserId: string = yield select(authentificationSelectors.selectSumo1AdminUserId);
      // clear user items storage
      localStorage.removeItem(UserSettingsKeys.LINK);

      localStorage.removeItem(UserSettingsKeys.WORKSPACE_ID);
      localStorage.removeItem(THIRD_PARTY);
      localStorage.removeItem(ENCRYPTED_USER_ID);
      // replace with staff data
      localStorage.setItem(UserSettingsKeys.USER_ID, staffUserId);
      localStorage.setItem(UserSettingsKeys.TENANT_ID, SUMO1TenantId);

      yield put(authentificationActions.resetViewAsUserSuccess());
      if (action.payload.redirect) {
        navigationService.navigateTo(Path.OPSConsoleOrgs);
        window.location.reload();
      }
    }
  } catch (error: unknown) {
    yield put(authentificationActions.resetViewAsUserFail(error?.toString()));
  }
}

function* logoutUserSaga(action: ReturnType<typeof authentificationActions.logoutUserRequest>) {
  try {
    if (action.type === AuthentificationActionTypes.LOGOUT_USER_REQUEST) {
      const isViewAsUserMode: boolean = yield select(authentificationSelectors.selectIsViewAsUser);
      if (isViewAsUserMode) {
        yield put(authentificationActions.resetViewAsUserRequest({}));
      } else {
        yield call(clearLocalStorage);
        navigationService.navigateTo(action.redirectTo);
        yield put(globalActions.resetWholeStore());
        yield put(authentificationActions.logoutUserSuccess());
        yield call(logoutUser);
      }
    }
  } catch (error: unknown) {
    yield put(authentificationActions.logoutUserFail(error?.toString()));
    yield call(handleServiceError, error, GET_USER_LOGOUT_ERROR_TOAST);
  }
}

function* getLicenseSaga() {
  try {
    const tenantId: string = yield select(authentificationSelectors.selectTenantId);

    const licenses: CreateLicenseInput[] = yield call(getLicenses, tenantId);

    yield put(authentificationActions.getLicenseSuccess(licenses));
  } catch (error: unknown) {
    yield put(authentificationActions.getLicenseFail(error?.toString()));
  }
}

// TODO: move it to Users saga 
// refresh trial license until it becomes paid license, 15 attempts with a 4-second delay
function* refreshTrialLicenseSaga() {
  try {
    yield put<any>(notificationsActions.showToast(BUY_LICENSE_SUCCESS_TOAST));

    const tenantId: string = yield select(authentificationSelectors.selectTenantId);
    const attempts = 15;
    let attempt = 1;
    let isTrialLicense = true;
    let licenses: CreateLicenseInput[] = [];

    while (attempt++ <= attempts && isTrialLicense) {
      yield delay(4000);
      licenses = yield call(getLicenses, tenantId);
      isTrialLicense = licenses[0].type === LicenseType.TRIAL;
    }

    yield put(billingActions.getBillingRequest());
    yield call(changeSeatsOrTerm, {}); // to update Additional Contacts
    yield put(authentificationActions.getLicenseSuccess(licenses));
  } catch (error: unknown) {
    yield put(authentificationActions.getLicenseFail(error?.toString()));
  }
}

function* getZoomCodeRedirect(action: ReturnType<typeof authentificationActions.getZoomCodeRedirect>) {
  if (action.type === AuthentificationActionTypes.GET_ZOOM_CODE_REDIRECT) {
    const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
    const scope = process.env.REACT_APP_ZOOM_SCOPE;
    let redirectUri = action.uri; // process.env.REACT_APP_ZOOM_REDIRECT_URI;

    window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  }
}

// TODO: probably move to another store?
function* getTenantSaga() {
  try {
    const tenantId: string = yield select(authentificationSelectors.selectTenantId);
    const tenant: CreateTenantInput = yield call(getTenantInfo, tenantId);
    yield put(authentificationActions.getTenantSuccess(tenant));
  } catch (error: unknown) {
    yield put(authentificationActions.getTenantFail(error?.toString()));
  }
}

function* viewAsUserSaga(action: ReturnType<typeof authentificationActions.viewAsUserRequest>) {
  try {
    if (action.type === AuthentificationActionTypes.VIEW_AS_USER_REQUEST) {
      const tenant = action.payload.tenantId;
      const userId = action.payload.userId;
      localStorage.setItem(UserSettingsKeys.TENANT_ID, tenant);
      localStorage.removeItem(UserSettingsKeys.WORKSPACE_ID);
      localStorage.setItem(UserSettingsKeys.USER_ID, userId);
      yield put(authentificationActions.updateUserDataCore({ userId, tenantId: tenant }));

      const encryptedUserId: string = yield call(generateCookies, userId);
      localStorage.setItem(ENCRYPTED_USER_ID, encryptedUserId);

      const profileRecord: CreateUserDataInput = yield call(getUserProfile, userId);
      const link = profileRecord.link;
      localStorage.setItem(UserSettingsKeys.LINK, link);
      yield put(authentificationActions.updateUserDataCore({ link }));

      if (profileRecord.userSettings) {
        const integrations = profileRecord.userSettings.integrations || [];
        const isGoogleIntegrated = integrations.some(
          (integration) =>
            integration?.type === IntegrationType.GOOGLE_CALENDAR || integration?.type === IntegrationType.GOOGLE_MEET
        );
        const thirdParty = isGoogleIntegrated ? ThirdPartyType.GOOGLE : ThirdPartyType.MICROSOFT;
        yield put(authentificationActions.setThirdParty(thirdParty));
        localStorage.setItem(THIRD_PARTY, thirdParty);
      }

      // yield call(licenseCheckSaga);  
      // yield put(authentificationActions.licenseCheckRequest());
      // yield take(AuthentificationActionTypes.LICENSE_CHECK_SUCCESS);
      yield put(authentificationActions.viewAsUserSuccess());
    }
  } catch (error: unknown) {
    yield put(authentificationActions.viewAsUseFail(error?.toString()));
    yield call(handleServiceError, error, VIEW_AS_USER_FAIL_TOAST, true);
  }
}

export function* watchAuthenticationSaga() {
  yield takeLatest(AuthentificationActionTypes.THIRD_PARTY_AUTH_REQUEST, thirdPartyAuthSaga);
  yield takeLatest(AuthentificationActionTypes.LOGOUT_USER_REQUEST, logoutUserSaga);
  yield takeLatest(AuthentificationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST, chooseAccountSaga);
  yield takeLatest(AuthentificationActionTypes.GET_ZOOM_CODE_REDIRECT, getZoomCodeRedirect);
  yield takeLatest(AuthentificationActionTypes.GET_TENANT_REQUEST, getTenantSaga);
  yield takeLatest(AuthentificationActionTypes.VIEW_AS_USER_REQUEST, viewAsUserSaga);
  yield takeLatest(AuthentificationActionTypes.RESET_VIEW_AS_USER_REQUEST, resetViewAsUserSaga);
  yield takeLatest(AuthentificationActionTypes.GET_LICENSE_REQUEST, getLicenseSaga);
  yield takeLatest(AuthentificationActionTypes.REFRESH_TRIAL_LICENSE_REQUEST, refreshTrialLicenseSaga);
}

export const authenticationSagas = {
  thirdPartyAuth: thirdPartyAuthSaga,
  getTenant: getTenantSaga,
  getLicense: getLicenseSaga,
};
