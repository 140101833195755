import { combineReducers } from 'redux';
import { AgendaType, EventSteps, ViewSettings } from './types';
import { EventActionTypes, EventAction } from './actions';
import { CreateUserEventInput } from '../../API';
import { DEFAULT_AGENDA, DEFAULT_EVENT, EVENT_STEPS_ORDER, DEFAULT_VIEW_DATE } from './constants'; // PREVIEW_HOST
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { calculateEventDate, calculateInitialEventLocation, calculateViewDate } from './utils';

const event = (state: CreateUserEventInput = DEFAULT_EVENT, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.UPDATE_EVENT:
      return { ...state, ...action.payload } as CreateUserEventInput;
    case EventActionTypes.GET_AGENDA_SUCCESS:
      return {
        ...state,
        eventDate: calculateEventDate(action.payload, state.eventDate),
        inputFields: state.inputFields || action.payload.bookingPage.inputFields,
        location: state.location || calculateInitialEventLocation(action.payload.bookingPage),
      } as CreateUserEventInput;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return action.payload.event as CreateUserEventInput;
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return action.payload as CreateUserEventInput;
    case EventActionTypes.SET_PREVIEW_DATA: {
      const { bookingPage } = action.payload;
      return {
        ...state,
        inputFields: bookingPage?.inputFields || state.inputFields,
        location: bookingPage ? calculateInitialEventLocation(bookingPage) : state.location,
      } as CreateUserEventInput;
    }
    case EventActionTypes.UPDATE_LOCATION:
      return {
        ...state,
        location: { ...state.location, ...action.payload },
      } as CreateUserEventInput;
    case EventActionTypes.UPDATE_CUSTOM_FIELD:
      return {
        ...state,
        inputFields: state.inputFields?.map((field) => (field?.id === action.payload.id ? action.payload : field)),
      } as CreateUserEventInput;
    case EventActionTypes.UPDATE_CANCELED:
      return {
        ...state,
        canceled: { ...state.canceled, ...action.payload },
      } as CreateUserEventInput;
    case EventActionTypes.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        ...action.payload.event,
      } as CreateUserEventInput;
    case EventActionTypes.CANCEL_EVENT_SUCCESS:
      return {
        ...state,
        canceled: { ...state.canceled, isCanceled: true },
      } as CreateUserEventInput;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_EVENT;
    default:
      return state;
  }
};

const agenda = (state: AgendaType = DEFAULT_AGENDA, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.GET_AGENDA_SUCCESS:
    case EventActionTypes.SET_PREVIEW_DATA:
      return {
        ...state,
        // host: state.host || PREVIEW_HOST,
        ...action.payload,
      } as AgendaType;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return { ...state, bookingPage: action.payload.bookingPage || state.bookingPage } as AgendaType;
    case EventActionTypes.SET_PREVIEW_BOOKING_PAGE_HOW:
      return {
        ...state,
        bookingPage: {
          ...state.bookingPage,
          labels: {
            ...state.bookingPage.labels,
            ...action.payload,
          },
        },
      } as AgendaType;
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        host: action.payload.host || state.host,
        cohosts: action.payload.cohosts || state.cohosts,
      } as AgendaType;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_AGENDA;
    default:
      return state;
  }
};

const bookingPageId = (state: string = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_BOOKING_PAGE_ID:
      return action.payload;
    case EventActionTypes.GET_AGENDA_SUCCESS:
      return action.payload.bookingPage?.id || state;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return action.payload.bookingPage?.id || action.payload.event.bookingPageId || state;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const eventId = (state: string = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_EVENT_ID:
      return action.payload;
    case EventActionTypes.GET_AGENDA_SUCCESS:
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.CREATE_EVENT_SUCCESS:
      return action.payload.event?.eventId || state;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const agendaLoaded = (state: Boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.GET_AGENDA_SUCCESS:
      return true;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const step = (state: EventSteps = EventSteps.WHEN, action: EventAction | GlobalAction) => {
  // EventSteps.WHAT
  switch (action.type) {
    case EventActionTypes.SET_EVENT_STEP:
      return action.payload;
    case EventActionTypes.SET_PREVIOUS_STEP: {
      const index = EVENT_STEPS_ORDER.findIndex((step) => step === state);
      return EVENT_STEPS_ORDER[index - 1] || state;
    }
    case EventActionTypes.SET_NEXT_STEP: {
      const index = EVENT_STEPS_ORDER.findIndex((step) => step === state);
      return EVENT_STEPS_ORDER[index + 1] || state;
    }
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return EventSteps.WHEN;
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
      return EventSteps.BOOKED;
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return action.payload?.canceled?.isCanceled ? EventSteps.BOOKED : state;
    case EventActionTypes.SET_PREVIEW_DATA:
      return EventSteps.WHEN; // (action.payload.bookingTemplates?.length || 0) > 1 ? EventSteps.WHAT : EventSteps.WHEN => EventSteps.WHEN
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return EventSteps.WHEN; // EventSteps.WHAT
    default:
      return state;
  }
};

const viewDate = (state: number = DEFAULT_VIEW_DATE, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_VIEW_DATE:
      return action.payload;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return calculateViewDate(action.payload.event.eventDate);
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return calculateViewDate(action.payload.eventDate);
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_VIEW_DATE;
    default:
      return state;
  }
};

const isRescheduleMode = (state: boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return true;
    case EventActionTypes.SET_PREVIEW_DATA:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isPreviewMode = (state: boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_PREVIEW_DATA:
      return true;
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isReadOnly = (state: boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_READ_ONLY:
      return action.payload;
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isHostReschedule = (state: boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return true;
    case EventActionTypes.SET_PREVIEW_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isMobile = (state: boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_IS_MOBILE:
      return true;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isRescheduled = (state: boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
      return true;
    case EventActionTypes.CANCEL_EVENT_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const viewSettings = (state = {} as ViewSettings, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_VIEW_SETTINGS:
      return { ...state, ...action.payload };
    case EventActionTypes.CREATE_EVENT_SUCCESS:
      return { ...state, editKey: action.payload.event.editKey || null } as ViewSettings;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return { ...state, iframe: true } as ViewSettings;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as ViewSettings;
    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.GET_AGENDA_REQUEST:
    case EventActionTypes.CREATE_EVENT_REQUEST:
    case EventActionTypes.UPDATE_EVENT_REQUEST:
    case EventActionTypes.CANCEL_EVENT_REQUEST:
      return true;
    case EventActionTypes.GET_AGENDA_SUCCESS:
    case EventActionTypes.GET_AGENDA_FAIL:
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.CREATE_EVENT_FAIL:
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
    case EventActionTypes.UPDATE_EVENT_FAIL:
    case EventActionTypes.CANCEL_EVENT_SUCCESS:
    case EventActionTypes.CANCEL_EVENT_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: string = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.GET_AGENDA_REQUEST:
    case EventActionTypes.CREATE_EVENT_REQUEST:
    case EventActionTypes.CANCEL_EVENT_REQUEST:
    case EventActionTypes.UPDATE_EVENT_REQUEST:
      return '';
    case EventActionTypes.GET_AGENDA_FAIL:
    case EventActionTypes.CREATE_EVENT_FAIL:
    case EventActionTypes.CANCEL_EVENT_FAIL:
    case EventActionTypes.UPDATE_EVENT_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  event,
  agenda,
  bookingPageId,
  eventId,
  agendaLoaded,
  step,
  viewDate,
  isRescheduleMode,
  isPreviewMode,
  isReadOnly,
  isHostReschedule,
  isMobile,
  isRescheduled,
  viewSettings,
  isFetching,
  error,
});
