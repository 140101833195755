import { Skeleton } from 'primereact/skeleton';
import { useSelector } from 'react-redux';
import { bookingPageSelectors } from '../../../store/bookingPages';

type StatItemProps = {
  label: string;
  value: string;
};
export const StatItem = ({ label, value }: StatItemProps) => {
  const isFetching = useSelector(bookingPageSelectors.selectIsFetching);

  return (
    <>
      {isFetching ? (
        <Skeleton height="7.3rem" />
      ) : (
        <div className="card shadow-none border-1 surface-border flex flex-column h-full">
          <div className="text-4xl font-medium">{value}</div>
          <div className="font-medium text-lg uppercase">{label}</div>
        </div>
      )}
    </>
  );
};
