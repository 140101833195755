import { Path } from '../../../routing';
import { Authentication } from '../../common';
import labels from './labels';
import { navigationService } from '../../../services/NavigationService';

export const Login = () => {
  const openSignUp = () => {
    navigationService.navigateTo(Path.Landing);
  };

  return (
    <Authentication
      title={labels.title}
      subtitle={labels.subtitle}
      typeTitle={labels.login}
      icon="pi pi-key"
      linkSubtext={labels.doesntHaveAnAccount}
      linkText={labels.signUp}
      linkAction={openSignUp}
      googleLabel={labels.googleSignIn}
      microsoftLabel={labels.microsoftSignIn}
      login={true}
    />
  );
};
