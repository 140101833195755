import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { TeamMemberInput } from '../../API';
import { authentificationSelectors } from '../authentification';
import { workspacesSelectors } from '../workspaces';

const teamsState = (state: State) => state.teams;

const selectIsFetching = createSelector(teamsState, (teams) => teams.isFetching);
const selectError = createSelector(teamsState, (teams) => teams.error);
const selectTeams = createSelector(teamsState, (teams) => teams.teams);
const selectSelectedTeam = createSelector(teamsState, (teams) => teams.team);
const selectSearchedTeams = (searchString: string) =>
  createSelector(selectTeams, (teams) =>
    (searchString
      ? teams?.filter((team) => team.name && team.name.toLowerCase().includes(searchString))
      : teams.slice()
    ).sort((teamA, teamB) => (teamA.createdAt > teamB.createdAt ? 1 : -1))
  );

const selectTeamId = createSelector(selectSelectedTeam, (team) => team.id);
const selectTeamName = createSelector(selectSelectedTeam, (team) => team.name);
const selectIsTeamActive = createSelector(selectSelectedTeam, (team) => team.active);
const selectMembers = createSelector(
  selectSelectedTeam,
  (team) => team.members?.filter((member): member is TeamMemberInput => member !== null) || []
);
const selectMembersIds = createSelector(selectMembers, (members) => members.map((member) => member?.userId));
const selectWorkspaceId = createSelector(selectSelectedTeam, (team) => team.workspaceId || '');
const selectWorkspaceName = createSelector(
  selectWorkspaceId,
  workspacesSelectors.selectWorkspacesNamesById,
  (id, namesById) => namesById[id] || ''
);
const selectWorkspacesForRead = createSelector(
  selectSelectedTeam,
  (team) => team.workspacesForRead?.filter((workspace): workspace is string => workspace !== null) || []
);
const selectSharedForReadTeam = createSelector(selectSelectedTeam, (team) => !!team.readOnly);

const selectIsExistingTeam = createSelector(selectSelectedTeam, (team) => Boolean(team.id));
const selectIsNameDuplicate = createSelector(selectTeamName, selectTeamId, selectTeams, (name, id, teams) =>
  teams.some((team) => team.name === name && team.id !== id)
);
const selectIsNameValid = createSelector(
  selectTeamName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectTeamsByCurrentWorkspace = createSelector(
  authentificationSelectors.selectWorkspace,
  selectTeams,
  (workspaceId, teams) =>
    teams.filter((team) => team.workspaceId === workspaceId || team.workspacesForRead?.includes(workspaceId))
);
const selectTeamNamesById = createSelector(selectTeams, (teams) =>
  teams.reduce((namesById, team) => {
    if (team.id) namesById[team.id] = team.name || '';
    return namesById;
  }, {} as Record<string, string>)
);

export const teamsSelectors = {
  selectIsFetching,
  selectError,
  selectTeams,
  selectSelectedTeam,
  selectSearchedTeams,

  selectTeamId,
  selectTeamName,
  selectIsTeamActive,
  selectMembers,
  selectMembersIds,
  selectWorkspaceId,
  selectWorkspaceName,
  selectWorkspacesForRead,
  selectSharedForReadTeam,

  selectIsExistingTeam,
  selectIsNameDuplicate,
  selectIsNameValid,

  selectTeamsByCurrentWorkspace,
  selectTeamNamesById,
};
