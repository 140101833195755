import { useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { Button } from 'primereact/button';
import labels from './labels';
import { Path } from '../../../routing';
import { useDispatch } from 'react-redux';
import { inviteUsersModalActions } from '../../../store/users/modal';
import { navigationService } from '../../../services/NavigationService';

export const InvitePeople = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(userSettingsSelectors.selectIsAdminRole);
  const isInvitePeopleClosed = useSelector(userSettingsSelectors.selectIsInvitePeopleClosed);

  const handleClose = () => {
    dispatch(userSettingsActions.updateUserSettings({ isInvitePeopleClosed: true }));
    dispatch(userSettingsActions.saveUserSettingsNoToastRequest());
  };

  const handleClickInvite = () => {
    navigationService.navigateTo(Path.Users);
    dispatch(inviteUsersModalActions.openModal());
  };
  return (
    <>
      {isAdmin && !isInvitePeopleClosed && (
        <div className="card sumo-header-bg">
          <div className="flex align-items-center justify-content-between flex-row mb-3 -mr-2 -mt-2">
            <div>{labels.description}</div>
            <Button icon="pi pi-times" text rounded className="text-color" onClick={handleClose} />
          </div>
          <Button label={labels.invitePeople} className="px-3" onClick={handleClickInvite} />
        </div>
      )}
    </>
  );
};
