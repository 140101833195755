import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import labels from './labels';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useEffect, useRef, useState } from 'react';
import { inviteUsersModalActions, inviteUsersModalSelectors } from '../../../store/users/modal';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '../../../store/notifications';
import {
  DEFAULT_INVITE_DATA,
  INVITE_SEND_STATUS,
  INVITE_USERS_EMAIL_INVALID,
  InviteUsersTemplateLabels,
  potentialExpirationDays,
  defaultExpirationDays,
} from '../../../store/users/constants';
import * as Papa from 'papaparse';
import { FileUpload } from 'primereact/fileupload';
import { Divider } from 'primereact/divider';
import { UserRecordTemplate } from '../../../store/users/typings';
import { InviteDialogOptions } from './InviteDialogOptions';
import { validateEmails } from '../../../services/utils';
import { usersActions, usersSelectors } from '../../../store/users';
import { CreateAdminDataInput } from '../../../API';
import InviteUsersTemplate from '../../../templates/InviteUsersTemplate.csv';
import { authentificationSelectors } from '../../../store/authentification';
import { userSettingsSelectors } from '../../../store/userSettings/selectors';
import { rolesSelectors } from '../../../store/roles';

export const InviteDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector(inviteUsersModalSelectors.selectIsModalOpen);
  const emailList = useSelector(usersSelectors.selectUsersEmails);
  const userWorkspace = useSelector(authentificationSelectors.selectWorkspace);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const standardUserRoleId = useSelector(rolesSelectors.selectStandardUserId);
  const roles = useSelector(userSettingsSelectors.selectSupportedRoles);
  const supportedRolesName = useSelector(userSettingsSelectors.selectSupportedRolesName);
  const remainingLicenses = useSelector(authentificationSelectors.selectRemainingLicenses);

  const wasOpen = useRef<boolean>();

  const [formData, setFormData] = useState(DEFAULT_INVITE_DATA);
  const [inviteUsersData, setInviteUsersData] = useState([DEFAULT_INVITE_DATA]);
  const [expirationInviteDays, setExpirationInviteDays] = useState(defaultExpirationDays);
  const [activeTabIndex, setActiveTabIndex] = useState(0); // index of selected Tab: 0 - Emails, 1 - CSV
  const [isConfirm, setIsConfirm] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [alreadyInvited, setAlreadyInvited] = useState<string[]>([]);

  useEffect(() => {
    setIsConfirm(false); // TODO: if initial state is false why we need this?
  }, []);

  useEffect(() => {
    if (open && wasOpen.current !== open) {
      setIsConfirm(false);
      setFormData({ ...DEFAULT_INVITE_DATA, roleId: standardUserRoleId });
      handleFileRemove();
    }
    wasOpen.current = open;
  }, [open]);

  const getRoleIdByName = (name: string) => {
    return roles.find((role) => role.name === name)?.id || '';
  };

  const getRoleNameById = (id: string) => {
    return roles.find((role) => role.id === id)?.name || '';
  };

  const filterUniqueEmails = (email: string, uniqueEmailsList: Set<string>) => {
    if (email.length > 0 && !uniqueEmailsList.has(email)) {
      if (emailList.includes(email)) {
        setAlreadyInvited((prev) => (prev.includes(email) ? prev : [...prev, email]));
      } else {
        uniqueEmailsList.add(email);
        return true;
      }
    }
    return false;
  };

  const createUserInviteRecordsFormData = () => {
    const uniqueEmailsList = new Set<string>();
    setAlreadyInvited([]);
    const emailArr = formData.email
      .split(',')
      .map((el) => el.trim().toLowerCase())
      .filter((el) => filterUniqueEmails(el, uniqueEmailsList));

    if (validateEmails(emailArr)) {
      const records = emailArr.map(
        (email) =>
          ({
            ...formData,
            email,
            status: INVITE_SEND_STATUS,
          } as CreateAdminDataInput)
      );
      setInviteUsersData(records);
      setIsConfirm(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch<any>(notificationsActions.showToast(INVITE_USERS_EMAIL_INVALID)); // TODO: replace any
    }
  };

  const createUserInviteRecordsCSVFile = () => {
    if (uploadedFile) {
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header: string, index: number) => InviteUsersTemplateLabels[index],
        complete: function (results: Papa.ParseResult<UserRecordTemplate>) {
          const emails = results.data.map((el) => el.email);
          if (validateEmails(emails)) {
            const uniqueEmailsList = new Set<string>();
            setAlreadyInvited([]);
            const uniqueData = results.data.filter(
              (el) =>
                filterUniqueEmails(el.email.toLowerCase(), uniqueEmailsList) && supportedRolesName.includes(el.role)
            );
            const records = uniqueData.map(
              (record) =>
                ({
                  ...record,
                  email: record.email.toLowerCase(),
                  team: record.team ? record.team.split(';') : [],
                  workspaceIds: [userWorkspace],
                  bookingTemplateIds: [],
                  tenantId: '',
                  status: INVITE_SEND_STATUS,
                  roleId: getRoleIdByName(record.role),
                } as CreateAdminDataInput)
            );
            setInviteUsersData(records);
            setIsConfirm(true);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dispatch<any>(notificationsActions.showToast(INVITE_USERS_EMAIL_INVALID)); // TODO: replace any
          }
        },
        error: (error: Error, file: File) => {
          console.error(`Parsing file ${file.name} failed. Error: ${error.message}`);
        },
      });
    }
  };

  const handleFileRemove = () => {
    setUploadedFile(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement> | DropdownChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleExpirationDateChange = (event: React.ChangeEvent<HTMLTextAreaElement> | DropdownChangeEvent) => {
    const { value } = event.target;
    setExpirationInviteDays(value);
  };

  const handleClose = () => {
    dispatch(inviteUsersModalActions.closeModal());
  };

  const handleNext = () => {
    activeTabIndex === 0 ? createUserInviteRecordsFormData() : createUserInviteRecordsCSVFile();
  };

  const sendInvite = () => {
    dispatch(usersActions.inviteUsersRequest(inviteUsersData, expirationInviteDays));
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-between">
        <Button label={labels.close} outlined className="w-6rem" onClick={handleClose} />
        <Button
          label={labels.next}
          className="w-8rem"
          disabled={
            activeTabIndex === 0
              ? formData.email.length === 0 || !formData.workspaceIds?.length || !formData.roleId
              : !uploadedFile
          }
          onClick={handleNext}
        />
      </div>
    );
  };

  const confirmFooter = () => {
    const userNum = inviteUsersData.length;
    return (
      <div className="flex justify-content-between">
        <Button label={labels.back} outlined className="w-6rem" onClick={() => setIsConfirm(false)} />
        <Button
          label={`${labels.invite} ${userNum} ${userNum === 1 ? labels.user : labels.users}`}
          disabled={userNum < 1 || remainingLicenses - userNum < 0}
          className="px-4"
          onClick={sendInvite}
          tooltip={remainingLicenses - userNum < 0 ? labels.remainingMessage : ''}
          tooltipOptions={{ position: 'left', showOnDisabled: true }}
        />
      </div>
    );
  };

  return (
    <>
      {isConfirm ? (
        <Dialog
          header={labels.inviteTitle}
          headerClassName="text-center text-900"
          footer={confirmFooter}
          visible={open}
          onHide={handleClose}
          className="w-10 md:w-6 lg:w-4"
          focusOnShow={false}
        >
          <div className="p-fluid">
            <div className="font-semibold text-lg text-900 mb-2">{labels.confirmDialogDesc}</div>
            <div className="flex gap-3 px-3">
              <div className="w-12rem text-right">
                <div>{labels.userCount}:</div>
                <div>{labels.alreadyInvitedCount}:</div>
                {activeTabIndex === 0 && (
                  <>
                    {userWorkspaces?.length > 1 && <div>{labels.assignWorkspace}:</div>}
                    <div>{labels.role}:</div>
                    <div>{labels.team}:</div>
                    <div>{labels.bookingTemplate}:</div>
                  </>
                )}
              </div>
              <div className="text-left">
                <div>{inviteUsersData.length}</div>
                <div>{alreadyInvited.length}</div>
                {activeTabIndex === 0 && (
                  <>
                    {userWorkspaces?.length > 1 && <div>{formData.workspaceIds?.length}</div>}
                    <div>{getRoleNameById(formData.roleId)}</div>
                    <div>{formData.team?.length ? formData.team?.length : 0}</div>
                    <div>{formData.bookingTemplateIds?.length ? formData.bookingTemplateIds?.length : 0}</div>
                  </>
                )}
              </div>
            </div>
            <Divider />
            <div className="flex gap-3 px-3 align-items-center">
              <div className="w-12rem text-right">
                <div>{labels.expirationDate}:</div>
              </div>
              <div className="text-left">
                <Dropdown
                  id="expirationDate"
                  name="expirationDate"
                  value={expirationInviteDays}
                  options={[
                    { name: labels.days_30, id: potentialExpirationDays.days_30 },
                    { name: labels.days_20, id: potentialExpirationDays.days_20 },
                    { name: labels.days_15, id: potentialExpirationDays.days_15 },
                    { name: labels.days_10, id: potentialExpirationDays.days_10 },
                  ]}
                  optionLabel="name"
                  optionValue="id"
                  onChange={handleExpirationDateChange}
                  className={`${!expirationInviteDays && 'p-invalid'}`}
                />
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog
          header={labels.inviteTitle}
          headerClassName="text-center text-900"
          footer={dialogFooter}
          visible={open}
          onHide={handleClose}
          className="w-10 lg:w-6"
          focusOnShow={false}
        >
          <div className="flex p-fluid flex-column md:flex-row">
            <div className={`${activeTabIndex ? 'w-12' : 'w-12 md:w-7'}`}>
              <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                <TabPanel header="Paste Emails">
                  <div className="font-semibold text-lg text-900">{labels.inviteMessageTitle}</div>
                  <p>{labels.inviteMessageDesc}</p>
                  <InputTextarea
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    rows={10}
                    cols={30}
                    className={`${formData.email.length === 0 && 'p-invalid'}`}
                    maxLength={1000}
                    autoResize
                  />
                  {formData.email.length === 0 && (
                    <small id="emails-help" className="p-error block">
                      {labels.noEmail}
                    </small>
                  )}
                </TabPanel>
                <TabPanel header="Upload CSV">
                  <div className="font-semibold text-lg text-900 mb-3">{labels.CSVInviteMessage}: </div>
                  <div className="flex flex-column md:flex-row gap-5">
                    <div className="flex flex-column w-12 md:w-6 surface-50 p-3">
                      <div className="font-bold text-900 mb-3">{labels.CSVTemplateTitle}</div>
                      <p>{labels.CSVTemplateDesc}</p>
                      <a
                        className="p-button p-button-text align-self-center hover:underline w-min"
                        href={InviteUsersTemplate}
                        download="InviteUsersTemplate"
                      >
                        {labels.download}
                      </a>
                    </div>
                    <div className="flex flex-column w-12 md:w-6 surface-50 p-3">
                      <div className="font-bold text-900 mb-3">{labels.CSVUploadTitle}</div>
                      <p>{labels.CSVUploadDesc}</p>
                      {!uploadedFile ? (
                        <FileUpload
                          mode="basic"
                          accept=".csv"
                          auto
                          chooseLabel={labels.upload}
                          customUpload
                          uploadHandler={(e) => setUploadedFile(e.files[0])}
                          className="align-self-center"
                        />
                      ) : (
                        <div className="flex align-items-center align-self-center">
                          <div>{uploadedFile.name}</div>
                          <Button onClick={handleFileRemove} icon="pi pi-times" text />
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
            {activeTabIndex === 0 && (
              <>
                <Divider layout="vertical" className="hidden md:block" />
                <InviteDialogOptions formData={formData} setFormData={setFormData} handleChange={handleChange} />
              </>
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};
