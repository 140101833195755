import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBookingTemplatesModalActions,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  FILTER_OPTIONS,
  FilterType,
} from '../../store/bookingTemplates';
import { usersActions, usersSelectors } from '../../store/users';
import { Path } from '../../routing';
import { Preloader, Search, SectionTitle } from '../../components/common';
import labels from './labels';
import { Button } from 'primereact/button';
import { styles } from './styles';
import {
  BookingTemplateCard,
  BookingTemplateCloneModal,
  BookingTemplateDeleteModal,
} from '../../components/bookingTemplates';
import { Divider } from 'primereact/divider';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../types/constants';
import { userSettingsSelectors } from '../../store/userSettings';
import { navigationService } from '../../services/NavigationService';
import { Dropdown } from 'primereact/dropdown';
import { globalSelectors } from '../../store/global';
import urlConstants from '../../shared/JSON/urlConstants.json';
import { bookingPagesActions } from '../../store/bookingPages';
import { useUserNavigation } from '../../hooks/useUserNavigation';

export const BookingTemplates = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const isFetching = useSelector(bookingTemplatesSelectors.selectIsFetching);
  const filteredBookingTemplates = useSelector(bookingTemplatesSelectors.selectFilteredBookingTemplates); // filter by Filter component and default filter
  const searchedBookingTemplates = useSelector(bookingTemplatesSelectors.selectSearchedBookingTemplates); // filter by Search input
  const selectedBookingTemplates = useSelector(bookingTemplatesSelectors.selectSelectedBookingTemplates); // filter by Select checkboxes

  const searchString = useSelector(globalSelectors.selectSearchString);
  const isBookingTemplatesCreate = useSelector(userSettingsSelectors.selectBookingTemplatesCreate);
  const isMultipleUsers = useSelector(usersSelectors.selectIsMultipleUsers);
  const filterType = useSelector(bookingTemplatesSelectors.selectFilterType);
  const bookingTemplatesRead = useSelector(userSettingsSelectors.selectBookingTemplatesRead);

  useEffect(() => {
    if (!isInitialVisit) {
      dispatch(bookingTemplatesActions.getBookingTemplatesRequest());
      dispatch(usersActions.getUsersRequest());
      dispatch(bookingPagesActions.getBookingPagesRequest()); // required for delete modal
    }
  }, [isInitialVisit]);

  const handleCheckboxChange = (id: string, selected: boolean | undefined) => {
    selected
      ? dispatch(bookingTemplatesActions.selectBookingTemplate(id))
      : dispatch(bookingTemplatesActions.unselectBookingTemplate(id));
  };

  const handleNewBookingTemplate = () => {
    navigationService.navigateTo(Path.EditBookingTemplate.replace(':bookingTemplateId', 'new'));
  };

  const handleCancelSelection = () => {
    dispatch(bookingTemplatesActions.unselectAllBookingTemplate());
  };

  const handleOpenDeleteModal = () => {
    dispatch(deleteBookingTemplatesModalActions.openModal());
  };

  const handleFilterChange = (value: FilterType) => {
    dispatch(bookingTemplatesActions.setFilter(value));
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <BookingTemplateCloneModal />
      <BookingTemplateDeleteModal />

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle
          id="bookingTemplatesTitle"
          icon="pi-th-large"
          title={labels.title}
          tooltip={labels.tooltip}
          learnMoreURL={urlConstants.KB_BOOKING_TEMPLATES_URL}
        />
        {isBookingTemplatesCreate && (
          <div className="-my-2 p-accordion-header-text">
            <Button label={labels.newBookingTemplate} icon="pi pi-calendar-plus" onClick={handleNewBookingTemplate} />
          </div>
        )}
      </div>

      <div className="card">
        <div className="grid mb-2">
          {bookingTemplatesRead && isMultipleUsers && (
            <div className="col-12 sm:col-6 lg:col-4 mb-3 p-inputgroup">
              <Dropdown
                options={FILTER_OPTIONS}
                value={filterType}
                onChange={(e) => handleFilterChange(e.target.value)}
              />
            </div>
          )}
          {/* {isStandardRole && (
            <div className="col-fixed text-xl mb-3">
              {labels.myTemplates}
            </div>
          )} */}
          {filteredBookingTemplates.length >= MIN_ITEMS_FOR_SEARCH && (
            <div className="col mb-3 p-inputgroup">
              <Search
                placeholder={labels.search}
                value={searchString}
                paths={[Path.BookingTemplates, Path.EditBookingTemplate]}
                maxTextLength={MAX_LENGTH_NAME}
              />
            </div>
          )}
        </div>
        <div className="grid -mb-2">
          {searchedBookingTemplates.map((bookingTemplate) => (
            <div className="col-12 md:col-6 lg:col-4" key={bookingTemplate.id}>
              <BookingTemplateCard bookingTemplate={bookingTemplate} handleCheckboxChange={handleCheckboxChange} />
            </div>
          ))}
        </div>
        {!searchedBookingTemplates.length && <div className="mt-3">{labels.noResults}</div>}
      </div>

      {selectedBookingTemplates.length > 0 && (
        <div style={styles.container}>
          <div style={styles.wrapper}>
            <div style={styles.selected}>
              <Button onClick={handleCancelSelection}>X</Button>
              <div className="pr-2">{`${selectedBookingTemplates.length} ${labels.selected}`}</div>
            </div>
            <Divider layout="vertical" />
            <Button onClick={handleOpenDeleteModal} icon="pi pi-trash" className="px-4" />
          </div>
        </div>
      )}
    </div>
  );
};
