import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Preloader, SectionTitle } from '../../../components/common';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { accountsActions, accountsSelectors } from '../../../store/opsConsole/accounts';
import { AccountsTable } from '../../../components/opsConsole/accounts/accountsTable/AccountsTable';
import { AccountsMergeModal } from '../../../components/opsConsole/accounts/accountsMergeModal/AccountsMergeModal';
import { AccountsDeleteModal } from '../../../components/opsConsole/accounts/accountsDeleteModal/AccountsDeleteModal';
import { Path } from '../../../routing';
import labels from './labels';
import { downloadCSV } from '../../../services/utils';
import { DateFormat } from '../../../store/userSettings';
import { formatDateUTC } from '../../../services/DateService';
import { navigationService } from '../../../services/NavigationService';
import { opsConsoleSelectors } from '../../../store/opsConsole/common/selectors';
import { opsConsoleActions } from '../../../store/opsConsole/common/actions';

export const Accounts = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(accountsSelectors.selectIsFetching);
  const searchString = useSelector(opsConsoleSelectors.selectSearchString);
  const accounts = useSelector(accountsSelectors.selectAccounts);

  useEffect(() => {
    dispatch(accountsActions.getAccountsRequest());
  }, []);

  const createAccount = () => {
    navigationService.navigateTo(Path.OPSConsoleAccount.replace(':accountId', 'new'));
  };

  const exportAccounts = () => {
    const header = [labels.accountName, labels.accountNote, labels.accountOrgs, labels.createdBy, labels.createdDate]
      .join(',')
      .toUpperCase();

    const rows = accounts.map((account) =>
      [
        account.name,
        account.note,
        account.tenantIds?.join(';') || '',
        account.createdBy,
        formatDateUTC(account.createdAt, DateFormat.default),
      ].join(',')
    );

    downloadCSV([header, ...rows].join('\n'), 'accounts.csv');
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <AccountsMergeModal />
      <AccountsDeleteModal />

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg-dark">
        <SectionTitle
          id="accountsTitle"
          icon="pi-building"
          title={labels.title}
        />
      </div>

      <div className="card">
        <div className="grid">
          <div className="col-12 md:col-6 p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              placeholder={labels.searchPlaceholder}
              value={searchString}
              onChange={(e) => dispatch(opsConsoleActions.setSearchString(e.target.value))}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>

          <div className="col-12 md:col-6 flex justify-content-end gap-3">
            <Button icon="pi pi-cloud-download" label={labels.export} text onClick={exportAccounts} />
            <Button label={labels.createAccount} onClick={createAccount} />
          </div>

          <div className="col-12">
            <Divider />
          </div>

          <div className="col-12">
            <AccountsTable />
          </div>
        </div>
      </div>
    </div>
  );
};
