import { Auth, API } from 'aws-amplify';
import { CreateLicenseInput, CreateTenantInput } from '../../API';
import { CodeThirdPartyResponse, MicrosoftCodeResponse, ThirdPartyLambdaResponse } from './typings';
import { APIPostRequest } from '../utils/reduxUtils';

// ***
// When changing functions, do not forget to specify new data
// in src/docs/Authentification.md
// ***

export async function getAuthentification() {
  try {
    return await APIPostRequest('/api/public/authentication', {});
  } catch (error: unknown) {
    console.error('Redux global service; Error getAuthentification function:', error);
    throw error;
  }
}

export const googleAuth = async (
  codeResponse: CodeThirdPartyResponse,
  userTimeZone: string,
  switchAccount: boolean,
  updateIntegration: boolean,
  integrationType: string,
  joinTenant: string | null = null,
  login: boolean,
): Promise<ThirdPartyLambdaResponse> => {
  try {
    const response = await APIPostRequest('/api/public/auth/googleAuth', {
      code: codeResponse.code,
      switchAccount,
      userTimeZone,
      updateIntegration,
      integrationType,
      joinTenant,
      login,
    });
    const currentUser = await Auth.currentUserInfo();
    if (response && !currentUser && !response.inviteExpired) {
      const cognitoUser = await Auth.signIn(response.email);
      await Auth.sendCustomChallengeAnswer(cognitoUser, response.secret);
    }
    return response;
  } catch (error: unknown) {
    console.error('Redux authentification service; Error googleAuth function:', error);
    throw error;
  }
};

export const microsoftAuth = async (
  codeResponse: MicrosoftCodeResponse,
  userTimeZone: string,
  switchAccount: boolean,
  updateIntegration: boolean,
  integrationType: string,
  joinTenant: string | null = null,
  login: boolean,
): Promise<ThirdPartyLambdaResponse> => {
  try {
    const response = await APIPostRequest('/api/public/auth/microsoftAuth', {
      code: codeResponse.code,
      redirectUrl: codeResponse.redirectUrl,
      switchAccount,
      userTimeZone,
      updateIntegration,
      integrationType,
      joinTenant,
      login,
    });
    const currentUser = await Auth.currentUserInfo();
    if (response && !currentUser && !response.inviteExpired) {
      const cognitoUser = await Auth.signIn(response.email);
      await Auth.sendCustomChallengeAnswer(cognitoUser, response.secret);
    }
    return response;
  } catch (error: unknown) {
    console.error('Redux authentification service; Error microsoftAuth function:', error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    await Auth.signOut();
  } catch (error: unknown) {
    console.error('Redux authentification service; Error logoutUser function:', error);
    throw error;
  }
};

// export const getCurrentUserCredentials = async (): Promise<UserCredentials> => {
//   try {
//     const currentUser = await Auth.currentAuthenticatedUser();
//     const userId = currentUser ? currentUser.attributes.sub : null;
//     const email = currentUser ? currentUser.attributes.email : null;
//     const identities = currentUser ? currentUser.attributes.identities : null;

//     // get the access token of the signed in user
//     // const accessToken = (await Auth.currentSession()).getAccessToken();
//     // // get the tenant from the top of the cognito groups list
//     // const cognitoGroups = accessToken.payload['cognito:groups'];
//     // const tenant = cognitoGroups ? cognitoGroups[0] : userId;
//     const tenant = localStorage.getItem(UserSettingsKeys.TENANT);

//     return {
//       authenticated: !!currentUser,
//       userId: userId,
//       tenant: tenant,
//       email: email,
//       identities: identities,
//     };
//   } catch (error: unknown) {
//     return {
//       authenticated: false,
//       userId: null,
//       tenant: null,
//       email: null,
//       identities: null,
//     };
//   }
// };

export const getTenantData = async (tenantId: string) => {
  try {
    return (await APIPostRequest('/api/js/getTenantByTenantId', { tenantId })) as CreateTenantInput;
  } catch (error) {
    console.error('Redux authentification service; Error getTenantData function:', error);
    throw error;
  }
};

export const deleteAndCreateUser = async (email: string, tenantId: string): Promise<string> => {
  try {
    return (await APIPostRequest('/api/public/deleteAndCreateUser', {
      email,
      newTenantId: tenantId,
    })) as string;
  } catch (error) {
    console.error('Redux authentification service; Error deleteAndCreateUser function:', error);
    throw error;
  }
};

export const generateCookies = async (userId: string) => {
  try {
    const response = await APIPostRequest('/api/js/generateCookies', {
      userId,
    });
    return response.encryptedUserId;
  } catch (error) {
    console.error('Redux authentification service; Error generateCookies function:', error);
    throw error;
  }
};

export const getLicenses = async (tenantId: string): Promise<CreateLicenseInput[]> => {
  try {
    return await APIPostRequest('/api/js/getLicenses', {
      tenantId,
    }) as CreateLicenseInput[];

  } catch (error: unknown) {
    console.error('Redux authentification service; Error getLicenses function:', error);
    throw error;
  }
};
