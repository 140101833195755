import { t } from '../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  edit: t('Common:EDIT'),
  cancel: t('Common:CANCEL'),
  title: t('Workspaces:TITLE'),
  newWorkspace: t('Workspaces:NEW_WORKSPACE'),
  whatTitlePart1: t('Workspaces:WHAT_TITLE_PART_1'),
  whatTitlePart2: t('Workspaces:WHAT_TITLE_PART_2'),
  whoTitlePart1: t('Workspaces:WHO_TITLE_PART_1'),
  whoTitlePart2: t('Workspaces:WHO_TITLE_PART_2'),
  brandingTitlePart1: t('Workspaces:BRANDING_TITLE_PART_1'),
  brandingTitlePart2: t('Workspaces:BRANDING_TITLE_PART_2'),
  brandingTitlePart3: t('Workspaces:BRANDING_TITLE_PART_3'),
  additionalOptions: t('Workspaces:ADDITIONAL_OPTIONS'),
  learnMore: t('Common:LEARN_MORE'),
  tooltip: t('Workspaces:TOOLTIP'),
};
