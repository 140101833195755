import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Path } from '../../routing';
import { AppDispatch } from '../../store/rootStore';
import { Preloader, SectionTitle } from '../../components/common';
import labels from './labels';

import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { workspacesSelectors } from '../../store/workspaces';
import { WorkspaceBrandingStep, WorkspaceWhatStep, WorkspaceWhoStep } from '../../components/workspaces';
import { workspacesActions } from '../../store/workspaces/actions';
import { navigationService } from '../../services/NavigationService';
import urlConstants from '../../shared/JSON/urlConstants.json';
import { userSettingsSelectors } from '../../store/userSettings';

export const EditWorkspace = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(workspacesSelectors.selectIsFetching);

  const name = useSelector(workspacesSelectors.selectName);
  const whatStepInfo = useSelector(workspacesSelectors.selectWhatStepInfo);
  const whoStepInfo = useSelector(workspacesSelectors.selectWhoStepInfo);
  const isExistingWorkspace = useSelector(workspacesSelectors.selectIsExistingWorkspace);
  const isSelectedWorkspaceEmpty = useSelector(workspacesSelectors.selectIsSelectedWorkspaceEmpty);
  const isWhatStepValid = useSelector(workspacesSelectors.selectIsWhatStepValid);
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);

  const [accordion1Index, setAccordion1Index] = useState<number | number[] | null>(0);
  const [accordion2Index, setAccordion2Index] = useState<number | number[] | null>(null);

  useEffect(() => {
    isSelectedWorkspaceEmpty && navigationService.navigateTo(Path.Workspaces);
  }, [isSelectedWorkspaceEmpty]);

  const handleAccordion1TabChange = (index: number | number[] | null) => {
    setAccordion1Index(index);
    setAccordion2Index(null);
  };
  const handleAccordion2TabChange = (index: number | number[] | null) => {
    setAccordion2Index(index);
    setAccordion1Index(null);
  };
  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // for no close the accordion
    navigationService.navigateTo(Path.Workspaces);
  };
  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // for no close the accordion
    if (!isWhatStepValid) {
      handleAccordion1TabChange(0);
      return;
    }

    dispatch(workspacesActions.saveWorkspaceRequest());
  };

  const renderSaveCancelButtons = () => (
    <>
      <Button className="mr-2 bg-white" label={labels.cancel} outlined onClick={handleCancel} />
      <Button label={labels.save} onClick={handleSave} disabled={isWorkspacesReadOnly} />
    </>
  );

  const renderAccordionTab = (
    titleElement: ReactJSXElement,
    stepElement: ReactJSXElement,
    showHeaderButtons = false,
    showBottomButtons = false,
    stepInfo: string | null
  ) => (
    <AccordionTab
      header={
        <>
          {showHeaderButtons && (
            <div className="absolute right-0 mr-2 h-1rem flex align-items-center">{renderSaveCancelButtons()}</div>
          )}
          <div className="font-normal h-1rem flex flex-column justify-content-center">
            <div>{titleElement}</div>
            {stepInfo && <div className="mt-1 text-sm text-gray-500">{stepInfo}</div>}
          </div>
        </>
      }
    >
      <div className="lg:p-2">{stepElement}</div>
      {showBottomButtons && (
        <div className="flex justify-content-end -mx-3 -mb-3 p-2 border-top-1 border-gray-300 p-accordion-header-text">
          {renderSaveCancelButtons()}
        </div>
      )}
    </AccordionTab>
  );

  return (
    <div>
      {isFetching && <Preloader />}

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle
          id="editWorkspaceTitle"
          icon="pi-globe"
          title={labels.title}
          tooltip={labels.tooltip}
          learnMoreURL={urlConstants.KB_WORKSPACES_URL}
        />
        {accordion1Index === null && accordion2Index === null && (
          <div className="-my-2 p-accordion-header-text">{renderSaveCancelButtons()}</div>
        )}
      </div>

      <div className="text-xl pl-1 ml-3 mb-2 mt-4">
        {isExistingWorkspace ? labels.edit + ' "' + name + '"' : labels.newWorkspace}
      </div>

      <Accordion activeIndex={accordion1Index} onTabChange={(e) => handleAccordion1TabChange(e.index)}>
        {renderAccordionTab(
          <>
            <b>{labels.whatTitlePart1}</b> {labels.whatTitlePart2}
          </>,
          <WorkspaceWhatStep />,
          accordion1Index === 0,
          false,
          whatStepInfo
        )}
        {renderAccordionTab(
          <>
            <b>{labels.whoTitlePart1}</b> {labels.whoTitlePart2}
          </>,
          <WorkspaceWhoStep />,
          accordion1Index === 1,
          false,
          whoStepInfo
        )}
      </Accordion>

      <div className="text-xl pl-1 ml-3 mb-2 mt-4">{labels.additionalOptions}</div>

      <Accordion activeIndex={accordion2Index} onTabChange={(e) => handleAccordion2TabChange(e.index)}>
        {renderAccordionTab(
          <>
            {labels.brandingTitlePart1} <b>{labels.brandingTitlePart2}</b> {labels.brandingTitlePart3}
          </>,
          <WorkspaceBrandingStep />,
          accordion2Index === 0,
          true,
          null
        )}
      </Accordion>
    </div>
  );
};
