import { t } from '../../../i18n/i18n';

export default {
  activate: t('Common:ACTIVATE'),
  clone: t('Common:CLONE'),
  deactivate: t('Common:DEACTIVATE'),
  delete: t('Common:DELETE'),
  edit: t('Common:EDIT'),
  hour: t('BookingTemplates:HOUR'),
  inPerson: t('EditBookingTemplateWhereStep:IN_PERSON'),
  minute: t('BookingTemplates:MINUTE'),
  phoneCall: t('EditBookingTemplateWhereStep:PHONE_CALL'),
  viewDetails: t('BookingTemplates:VIEW_DETAILS'),
  videoConference: t('EditBookingTemplateWhereStep:VIDEO_CONFERENCE'),
  locked: t('Common:LOCKED'),
};
