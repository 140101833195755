import { CreateUserEventInput, UpdateUserEventInput } from '../../API';
import { AgendaType, CancelEventRequestType, CreateUpdateEventResponse, EventAction, GetAgendaRequestType } from './types';
import { HOST_NOTIFICATION_TEMPLATE } from './constants';
import { cancelReasons } from '../scheduledMeetings';
import { APIPostRequest } from "../utils/reduxUtils";

export async function fetchAgenda(agenda: GetAgendaRequestType) {
  try {
    return (await APIPostRequest('/api/public/PreprocessEventCreation', agenda)) as AgendaType;
  } catch (error: unknown) {
    console.error('Redux event service; Error fetch agenda:', error);
    throw error;
  }
}

export async function createEvent(event: CreateUserEventInput) {
  try {
    return (await APIPostRequest('/api/public/CreateUserEvent', {event})) as CreateUpdateEventResponse;
  } catch (error: unknown) {
    console.error('Redux event service; Error post event:', error);
    throw error;
  }
}

export async function updateEvent(event: UpdateUserEventInput) {
  try {
    return (await APIPostRequest('/api/public/updateUserEvent', {event})) as CreateUpdateEventResponse;
  } catch (error: unknown) {
    console.error('Redux event service; Error post event:', error);
    throw error;
  }
}

export async function cancelEvent(event: CancelEventRequestType) {
  try {
    await APIPostRequest('/api/public/cancelEvent', {event});
  } catch (error: unknown) {
    console.error('Redux event service; Error delete event:', error);
    throw error;
  }
}

export async function sendNotifications(eventId: string, eventActions: EventAction) {
  try {
    await APIPostRequest('/api/js/sendNotificationSection', {
      eventId,
      eventActions,
      hostNotificationTemplates: HOST_NOTIFICATION_TEMPLATE,
      cancelReasons: cancelReasons,
    });
  } catch (error: unknown) {
    console.error('Redux event service; Error post event:', error);
    throw error;
  }
}
