export const DefaultBookingTemplate = Object.freeze({
  NAME: `Reunião de 30 Minutos`,
  CONFIRMATION_SUBJECT: 'Nova reunião: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Reagendado: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Lembrete: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Cancelado: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Acompanhamento: <Meeting Name> com <Host Name>',
  CONFIRMATION_BODY: `Olá <Invitee Name>,

Sua nova reunião <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date> está agendada.

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Olá <Invitee Name>,

Sua reunião <Meeting Name> com <Host Name> foi reagendada.

<b>Hora e Data atualizadas</b>
<Meeting Time>, <Meeting Date>

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Olá <Invitee Name>,

Este é um lembrete amigável de que sua <Meeting Name> com <Host Name> é às <Meeting Time>, <Meeting Date>.

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Olá <Invitee Name>,

Sua reunião <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date> foi cancelada.`,
  FOLLOW_UP_BODY: `Olá <Invitee Name>,

Esperamos que sua reunião "<Meeting Name>" com <Host Name> tenha sido produtiva.

Se você tiver algum comentário ou precisar de mais assistência, não hesite em nos contatar.`,
  SMS_TEXT:
    'Este é um lembrete amigável de que sua <Meeting Name> com <Host Name> é às <Meeting Time>, <Meeting Date>. Ver ou editar reunião <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `Novo modelo`,
  TITLE: `Modelos de reserva`,
  VIEW_DETAILS: `Ver Detalhes`,
  MINUTE: `min`,
  HOUR: `h`,
  CLONE_MODAL_TITLE: `Clone modelo de reserva`,
  DELETE_MODAL_CONFIRM_TEXT: `Tem certeza que deseja excluir este modelo de reserva?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `Tem certeza que deseja excluir estes modelos de reserva?`,
  DELETE_MODAL_USED_PAGES_TEXT: `Primeiro você deve remover esses modelos de reservas das seguintes páginas de reservas:`,
  FILTER_MY_TEMPLATES: `Meus modelos`,
  FILTER_ALL_TEMPLATES: `Todos os modelos`,
  TOOLTIP: `Criar modelos de reserva para páginas de reserva.`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Falha na clonagem do modelo de reserva`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Modelo de reserva clonado com sucesso`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Falha na remoção do modelo de reserva`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Modelo de reserva removido com sucesso`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `Falha ao obter modelo de reserva`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Falha ao salvar o modelo de reserva`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Modelo de reserva salvo com sucesso`,
});

export const EditBookingTemplate = Object.freeze({
  ADDITIONAL_TITLE: `Opções Adicionais`,
  WHAT_TITLE_PART_1: `O que`,
  WHAT_TITLE_PART_2: ` evento é este?`,
  WHO_TITLE_PART_1: `Quem`,
  WHO_TITLE_PART_2: `pode comparecer?`,
  WHERE_TITLE_PART_1: `Onde`,
  WHERE_TITLE_PART_2: ` este evento acontecerá?`,
  WHEN_TITLE_PART_1: `Quando`,
  WHEN_TITLE_PART_2: ` as pessoas podem agendar este evento?`,
  INVITEE_TITLE_PART_1: `Perguntas para o`,
  INVITEE_TITLE_PART_2: ` convidado`,
  INVITEE_SUBTITLE: `Nome, E-mail, Perguntas Personalizadas`,
  NOTIFICATIONS_TITLE_PART_1: `Notificações`,
  NOTIFICATIONS_TITLE_PART_2: ``,
  CONFIRMATION_TITLE_PART_1: `Página de`,
  CONFIRMATION_TITLE_PART_2: ` Confirmação`, // e Política de Cancelamento
  LOCKED_TOOLTIP: `Registro bloqueado. Você não tem permissões para editar. Última modificação por:`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  TEAMS: `Equipes potenciais`,
  HOSTS: `Anfitriões`,
  HOSTS_TOOLTIP: `Selecione um ou vários hosts para este modelo de reserva. Se você selecionar vários organizadores, eles serão adicionados à reunião reservada.`,
  RESCHEDULE_WITH: `Reagendar com`,
  RESCHEDULE_WITH_TOOLTIP: `Se o convidado reagendar a reunião, com quem o SUMO deve reagendar?`,
  ASSIGNED_HOST: `Anfitrião Designado`,
  ROUND_ROBIN_TO_NEW_HOST: `Round-Robin para um Novo Anfitrião`,
  POTENTIAL_HOSTS: `Anfitriões Potenciais`,
  HOST_VIDEO_CONFERENCE: `Vídeo Conferência do Anfitrião`,
  HOST_LIST_REQUIREMENTS: `Você deve estar na lista de anfitriões, você não tem permissão para Todas as Páginas de Reserva.`,
  HOSTS_LABEL: `Escolha uma pessoa, várias pessoas ou uma equipe (onde um membro será selecionado via round-robin) para participar desta reunião.`,
  HOSTS_PLACEHOLDER: `Pesquisar anfitriões ou equipes`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Selecione uma ou mais opções para apresentar ao convidado ao agendar por conta própria na página de reservas:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Pular o passo "Onde" para reuniões virtuais`,
  SKIP_WHERE_STEP_DESCRIPTION: `O link da videoconferência será adicionado automaticamente à reunião.`,
  PHONE_CALL: `Chamada telefônica`,
  HOSTS_PHONE: `Número de telefone do Anfitrião`,
  HOSTS_PHONE_DESCRIPTION: `O número de telefone do anfitrião será adicionado automaticamente à reunião.`,
  HOSTS_PHONE_NOTIFICATION: `Um ou mais anfitriões não possuem número de telefone`,
  INVITEE_PHONE: `Solicitar ao convidado que informe o número de telefone deles`,
  INVITEE_PHONE_DESCRIPTION: `O convidado será solicitado a adicionar o número de telefone.`,
  CUSTOM_PHONE: `Número de telefone personalizado`,
  CUSTOM_PHONE_PLACEHOLDER: `Informe o número de telefone`,
  CUSTOM_PHONE_DESCRIPTION: `Este número de telefone será adicionado automaticamente à reunião.`,
  IN_PERSON: `Presencial`,
  CUSTOM_ADDRESS: `Endereço Personalizado`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Informe o endereço da reunião`,
  CUSTOM_ADDRESS_DESCRIPTION: `Este endereço será adicionado automaticamente à reunião.`,
  LOCATION: `Local da empresa`,
  LOCATION_DESCRIPTION: `O convidado será solicitado a escolher um destes locais.`,
  INVITEE_LOCATION: `Localização do convidado`,
  INVITEE_LOCATION_DESCRIPTION: `O convidado será solicitado a adicionar um endereço de reunião.`,
  DEFAULT_LOCATION: 'Localização padrão',
  VIDEO_CONFERENCE: `Videoconferência`,
  VIDEO_CONFERENCE_NOTIFICATION: `Um ou mais anfitriões não possuem videoconferência`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Nome completo`,
  EMAIL_LABEL: `E-mail`,
  ADD_QUESTION_BUTTON: `Adicionar Nova Pergunta`,
  QUESTION_TITLE_NEW: `Nova Pergunta`,
  QUESTION_TITLE_EDIT: `Editar Pergunta`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  DESCRIPTION: `Adicione uma pergunta para ser adicionada à Página de Reservas para o convidado completar ao agendar a reunião.`,
  QUESTION_LABEL: `Pergunta`,
  REQUIRED_LABEL: `Obrigatório`,
  ACTIVE_LABEL: `Ativo`,
  TYPE_LABEL: `Tipo de Resposta`,
  TYPE_CHECKBOXES: `Caixas de Seleção`,
  TYPE_RADIO_BUTTONS: `Botões de Opção`,
  TYPE_SELECT: `Dropdown`,
  TYPE_TEL: `Número de Telefone`,
  TYPE_TEXT: `Linha Única`,
  TYPE_TEXTAREA: `Múltiplas Linhas`,
  ANSWERS_LABEL: `Respostas`,
  ANSWERS_SINGLE_DESCRIPTION_1: `O convidado pode selecionar `,
  ANSWERS_SINGLE_DESCRIPTION_2: `uma`,
  ANSWERS_SINGLE_DESCRIPTION_3: ` das seguintes`,
  ANSWERS_MULTIPLE_DESCRIPTION_1: `O convidado pode selecionar uma ou `,
  ANSWERS_MULTIPLE_DESCRIPTION_2: `várias`,
  ANSWERS_MULTIPLE_DESCRIPTION_3: ` das seguintes`,
  ANSWER_PLACEHOLDER: `Resposta`,
  ADD_ANSWER_BUTTON: `Adicionar Resposta`,
  DELETE_BUTTON: `Excluir`,
  CANCEL_BUTTON: `Cancelar`,
  SAVE_BUTTON: `Salvar`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `Confirmações por E-mail`,
  CONFIRMATION_DESCRIPTION: `Uma reunião será sempre criada na agenda do host no SUMO1, e o convidado será adicionado como um participante.
Ao ativar esta funcionalidade, o SUMO1 também enviará uma confirmação por e-mail.`,
  RESCHEDULE_TITLE: `Reprogramação de e-mail`,
  RESCHEDULE_DESCRIPTION: `O convidado receberá uma notificação por e-mail sobre quaisquer alterações em sua reunião.`,
  REMINDER_TITLE: `Lembretes por E-mail`,
  REMINDER_DESCRIPTION: `O convidado receberá um lembrete por e-mail antes da reunião agendada.`,
  CANCELATION_TITLE: `Cancelamentos por E-mail`,
  CANCELATION_DESCRIPTION: `Um e-mail de notificação será enviado ao seu convidado se você cancelar a reunião.`,
  FOLLOW_UP_TITLE: `Follow-Up por E-mail`,
  FOLLOW_UP_DESCRIPTION: `Automatize o acompanhamento dos próximos passos com um e-mail enviado após a realização da reunião.`,
  SMS_TITLE: `Lembrete por SMS`,
  SMS_DESCRIPTION: `O convidado terá a opção de receber lembretes por SMS antes de uma reunião agendada.`,
  SMS_BODY: `SMS Corpo da mensagem`,
  SMS_LABEL_PART1: `Apenas nos EUA e Canadá`,
  SMS_LABEL_PART2: `Atualmente, os lembretes por texto são gratuitos e disponíveis apenas para números de telefone de convidados nos EUA e no Canadá.`,
  PERSONALIZE: `Personalizar`,
  EMAIL_SUBJECT: `Assunto do E-mail`,
  EMAIL_BODY: `Corpo do E-mail`,
  MERGE_VARIABLES: `Variáveis de Mesclagem`,
  TIMING: `Temporização`,
  RESET: `Redefinir`,
  ADD_ANOTHER_REMINDER: `Adicionar Outro Lembrete`,
  CANCELATION_POLICY: `Política de Cancelamento`,
  CANCELATION_POLICY_TOOLTIP: `Qualquer texto que você inserir aqui aparecerá em todos os e-mails de notificação enviados ao convidado.`,
  TIME_UNIT_DAYS_BEFORE: `dia(s) antes`,
  TIME_UNIT_HOURS_BEFORE: `hora(s) antes`,
  TIME_UNIT_MINUTES_BEFORE: `minuto(s) antes`,
  TIME_UNIT_DAYS_AFTER: `dia(s) depois`,
  TIME_UNIT_HOURS_AFTER: `hora(s) depois`,
  TIME_UNIT_MINUTES_AFTER: `minuto(s) depois`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `Após Agendar`,
  TYPE_DISPLAY_SUMO: `Exibir Página de Confirmação SUMO`,
  TYPE_DISPLAY_EXTERNAL: `Redirecionar para uma Página Externa`,
  EXTERNAL_LINK_LABEL: `URL de Redirecionamento Externo`,
  EXTERNAL_LINK_PLACEHOLDER: `https://redirecionamento-aqui.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Exibir botão "Agendar Outra Reunião"`,
  ALLOW_RESCHEDULE_LABEL: `Permitir Reagendamento`,
  ALLOW_CANCEL_LABEL: `Permitir Cancelamento`,
  ADD_CUSTOM_LINK_LABEL: `Adicionar Link Personalizado`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Adicione um link personalizado à Página de Confirmação, como um link para uma pesquisa.`,
  ADD_CUSTOM_LINK_TITLE: `Adicionar Link Personalizado`,
  EDIT_CUSTOM_LINK_TITLE: `Editar Link Personalizado`,
  CUSTOM_LINK_LABEL: `Rótulo do Link Personalizado`,
  CUSTOM_LINK_PLACEHOLDER: `Exemplo de Link`,
  LINK_URL_LABEL: `URL do Link`,
  LINK_URL_PLACEHOLDER: `https://exemplo-link.com`,
});
