export const Common = Object.freeze({
  ACCEPT_INVITE: `Accepter l'invitation`,
  ACCESS_RESTRICT: `Désolé, vous n'êtes pas autorisé à accéder à cette page`,
  ACTIVATE: `Activer`,
  ALL: `Tous`,
  ARE_YOU_SURE: `Êtes-vous sûr ?`,
  AUTHORISE: `Autoriser`,
  AVAILABLE: `Disponible`,
  BACK: `Retour`,
  CALENDAR_DAYS: `Jours du calendrier`,
  CANCEL: `Annuler`,
  CHOOSE_DATE: `Choisir une date`,
  CLEAR: `Effacer`,
  CLONE: `Dupliquer`,
  CLOSE: `Fermer`,
  COMMON: `Commun`,
  COMPLETE: `Terminer`,
  CONNECT: `Connecter`,
  CONNECTED: `Connecté`,
  CONFIRM_DELETE: `Confirmer la suppression`,
  COPY: `Copier`,
  COPY_LINK: `Copier le lien`,
  COPIED_LINK: `Copié !`,
  COUNTINUE: `Continuer`,
  COUNTRY: `Pays`,
  CUSTOM: `Personnalisé`,
  DATE: `Date`,
  DATE_RANGE: `Plage de dates`,
  DAYS: `Jours`,
  DEACTIVATE: `Désactiver`,
  DEFAULT: `Par défaut`,
  DELETE: `Supprimer`,
  DESCRIPTION: `Description`,
  DISABLE: `Désactiver`,
  DISCONNECT: `Déconnecter`,
  DOWNLOAD: `Télécharger`,
  EDIT: `Modifier`,
  EMAIL: `Courriel`,
  EMAIL_ALL_SMALL: `courriel`,
  EMPTY_ERROR: `Ne peut pas être vide`,
  ENABLE: `Activer`,
  ERROR: 'Erreur',
  EXPORT: 'Exporter',
  FILTER: `Filtrer`,
  FRIDAYS: `Vendredis`,
  FROM: `de`,
  GOOGLE: `Google`,
  HOURS: `Heures`,
  HOUR_SHORTENED: `h`,
  INFO: 'Info',
  INTERNAL: `Interne`,
  LEARN_MORE: `En savoir plus`,
  LOCKED: `Verrouillé`,
  LOGIN: `Connexion`,
  LOG_OUT: `Déconnexion`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `min`,
  MINUTES: `Minutes`,
  MONDAYS: `Lundis`,
  MY_ACCOUNT: `Mon compte`,
  NAME_IS_DUPLICATE: `Oups ! Ce nom a déjà été utilisé. Veuillez en saisir un différent.`,
  NEXT: `Suivant`,
  NONE: `Aucun`,
  NO: `Non`,
  NO_RESULTS_FOUND: `Aucun résultat trouvé`,
  NEVER: 'Jamais',
  OFF: `Désactivé`,
  ON: `Activé`,
  ONLY_ONE_ACCOUNT: `Vous ne pouvez avoir qu'un seul compte SUMO1 par adresse e-mail.`,
  PASSWORD: `Mot de passe`,
  PHONE_NUMBER: `Numéro de téléphone`,
  PICTURE_FORMATS: `SVG, JPG, GIF ou PNG. Taille maximale de 5 Mo.`,
  PREVIEW: `Aperçu`,
  QUICK_SETUP: `Configuration rapide`,
  REGISTER: `S'inscrire`,
  RESET: `Réinitialiser`,
  ROLES: `Rôles`,
  SATURDAYS: `Samedis`,
  SAVE: `Enregistrer`,
  SAVE_CHANGES: `Enregistrer les modifications`,
  SEARCH: `Rechercher`,
  SECOND_SHORTENED: `s`,
  SELECTED: `Sélectionné`,
  SHARE: `Partager`,
  SIGN_OUT: `Déconnexion`,
  SIGN_UP: `Inscription`,
  SUCCESS: 'Succès',
  SUNDAYS: `Dimanches`,
  TIMEZONE: `Fuseau horaire`,
  THURSDYAS: `Jeudis`,
  TIMES: `Horaires`,
  TODAY: `Aujourd'hui`,
  TO: `à`,
  TUESDAYS: `Mardis`,
  UNAVAILABLE: `Indisponible`,
  UNLINK: `Dissocier`,
  UNSAVED_CHANGES: `Toutes les modifications non enregistrées seront perdues.`,
  UPLOAD: `Télécharger`,
  UPLOAD_PICTURE: `Télécharger une image`,
  USER: `Utilisateur`,
  USERS: `Utilisateurs`,
  VIEW: `Voir`,
  YES: `Oui`,
  YES_CANCEL: `Oui, Annuler`,
  WARNING: 'Avertissement',
  WEDNESDAYS: `Mercredis`,
  WEEKDAYS: `Jours de la semaine`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Erreur interdite`,
  TITLE_404: `404 - Page non trouvée`,
  TITLE_500: `500 - Erreur interne du serveur`,
  MESSAGE_403_1: `Vous n'avez pas la permission d'accéder à `,
  MESSAGE_403_2: ` sur ce serveur.`,
  MESSAGE_404_1: `L'URL de la demande `,
  MESSAGE_404_2: ` n'a pas été trouvée sur ce serveur.`,
  MESSAGE_500_1: `Il y a eu une erreur. C'est tout ce que nous savons. Veuillez attendre et `,
  MESSAGE_500_2: `réessayer de recharger`,
  MESSAGE_500_3: ` à nouveau.`,
  HOME_BUTTON: `Accueil`,
});

export const PrivateRoute = Object.freeze({
  ACCOUNT: `Compte`,
  ACCOUNT_SETTINGS: `Paramètres du compte`,
  PROFILE: `Profil`,
  ADMIN_SETTINGS: `Paramètres d'administration`,
  AVAILABILITY: `Disponibilité`,
  CALENDAR_CONNECTIONS: `Connexions de calendrier`,
  HELP_CENTER: `Centre d'aide`,
  HOME: `Accueil`,
  USER_SETUP: `Configuration de l'utilisateur`,
  USER_SETTINGS: `Paramètres de l'utilisateur`,
  INTEGRATION: `Intégration`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Copyright 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc.  Tous droits réservés.`,
  PRIVACY_POLICY: `Politique de confidentialité`,
  SECURITY_POLICY: `Politique de sécurité`,
  TERMS: `Conditions`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Comptes`,
  ADMIN_SETTINGS_TITLE: `Paramètres d'administration`,
  ADMIN_TOOLS_TITLE: `Outils d'administration`,
  BILLING_TITLE: `Facturation`,
  BOOKING_TOOLS_TITLE: `Outils de réservation`,
  DASHBOARD_TITLE: `Tableau de bord`,
  MORE_TOOLS_TITLE: `Plus d'outils`,
  OPERATIONS_TITLE: `Opérations`,
  ORGS_TITLE: `Organisations`,
  STAFF_TITLE: `Personnel`,
  SUMO1_FOR_GMAIL: `SUMO1 pour Gmail`,
  SUPPORT_TITLE: `Support`,
  OPEN_MY_SUMO1: `Ouvrir Mon SUMO1`,
  OPEN_OPS_CONSOLE: `Ouvrir la Console OPS`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Cliquez ici pour réserver un appel ou une réunion virtuelle avec un expert.`,
  BOOK_MEETING_TITLE: `Réserver une réunion`,
  CALL_DESCRIPTION: `Appelez-nous à tout moment du lundi au vendredi de 8h à 17h (HNE).`,
  CALL_TITLE: `APPELEZ LE 800-708-1790`,
  CHAT_DESCRIPTION: `Cliquez sur le lien "Discutez avec nous" en bas à droite pour voir si nous sommes disponibles.`,
  CHAT_TITLE: `Discutez maintenant`,
  DESCRIPTION: `Vous pouvez également contacter les ventes ou le support pour obtenir de l'aide.`,
  EMAIL_DESCRIPTION: `Envoyez un courriel à experts@sumoscheduler.com pour obtenir des réponses.`,
  EMAIL_TITLE: `Envoyez-nous un courriel`,
  HEADER_TITLE: `Échec de la connexion`,
  HELP_CENTER_DESCRIPTION: `Vidéos d'aide, articles de la base de connaissances, et plus encore.`,
  HELP_CENTER_TITLE: `Centre d'aide et BCI`,
  LOG_CASE_DESCRIPTION: `Les clients peuvent cliquer ici pour enregistrer un cas auprès du support client.`,
  LOG_CASE_TITLE: `Enregistrer un cas`,
  MESSAGE: `Votre "super administrateur" peut se connecter pour acheter des licences et reprendre l'utilisation de SUMO1.`,
  TITLE: `Licence expirée`,
  TITLE_TRIAL: `Essai gratuit expiré`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Annulations`,
  MEETINGS_BOOKED: `Réunions réservées`,
  REMINDERS_SENT: `Rappels envoyés`,
  RESCHEDULES: `Reportages`,
  TITLE: `Mes statistiques de réservation`,
  INSTALL_CHROME_EXTENSION_PART1: `Installez l'extension Chrome SUMO1`,
  INSTALL_CHROME_EXTENSION_PART2: `pour envoyer des liens d'invitation par e-mail aux personnes depuis votre boîte de réception, votre calendrier ou votre CRM.`,
  INVITE_PEOPLE_DESCRIPTION: `Ajoutez des utilisateurs à SUMO1 pour profiter de la coanimation, de la réservation d'équipe, de la réservation pour le compte de, et plus encore.`,
  INVITE_PEOPLE_TITLE: `Inviter des personnes`,
  CALENDAR_WARNING_DESCRIPTION: `Votre calendrier n'est pas connecté. Vous risquez de double-réservation si vous ne connectez pas un calendrier.`,
  CALENDAR_WARNING_BUTTON: `Connecter le calendrier`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Ajoutez un lien "Réservez avec moi" à votre signature électronique.`,
  STEP1_TITLE: `Configuration de la signature électronique`,
  STEP2_DESCRIPTION: `Envoyez une invitation à une réunion à quelqu'un avec l'extension Chrome.`,
  STEP2_TITLE: `Envoyer une invitation en un clic`,
  STEP3_DESCRIPTION: `Que devraient-ils tester d'autre dès le premier jour ?`,
  STEP3_TITLE: `Tester une autre fonctionnalité`,
  TITLE: `Commencez facilement à réserver`,
});
