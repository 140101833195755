import { forwardRef, useImperativeHandle, useContext, useRef } from 'react';
import { StyleClass } from 'primereact/styleclass';
import { Ripple } from 'primereact/ripple';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { authentificationActions } from '../../../store/authentification';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authentificationSelectors } from '../../../store/authentification';
import labels from './labels';
import { UserAvatar } from '../../common';
import sumoLogo from '../../../assets/images/sumoLogo.svg';
import sumoLogoWhite from '../../../assets/images/sumoLogoWhite.svg';
import AppBreadcrumb from '../../common/app/AppBreadCrumb';
import AppSidebar from '../../common/app/AppSidebar';
import { LayoutContext } from '../../common/context/layoutcontext';
import { Dropdown } from 'primereact/dropdown';
import { workspacesSelectors } from '../../../store/workspaces';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { navigationService } from '../../../services/NavigationService';
import { ViewAsUserWarningBlock } from '../../opsConsole';
import urlConstants from '../../../shared/JSON/urlConstants.json';

const AppTopbarAuth = forwardRef((props, ref) => {
  // const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);
  const menubuttonRef = useRef(null);
  //onRightMenuButtonClick
  const { onMenuToggle, layoutConfig } = useContext(LayoutContext); // toggleSearch, layoutConfig

  const dispatch = useDispatch();
  const location = useLocation();
  const nameOrEmail = useSelector(userSettingsSelectors.selectNameOrEmail);
  const staffNameOrEmail = useSelector(authentificationSelectors.selectStaffNameOrEmail);
  // const tabs = useSelector(routingSelectors.selectTabs);
  const userWasDeleted = useSelector(authentificationSelectors.selectUserWasDeleted);
  const selectedWorkspace = useSelector(authentificationSelectors.selectWorkspace);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces);
  const userWorkspacesOptions = useSelector(workspacesSelectors.selectWorkspaceOptionsByIds(userWorkspaces));
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const trialExpirationMessage = useSelector(authentificationSelectors.selectTrialExpirationMessage);
  const paidExpirationMessage = useSelector(authentificationSelectors.selectPaidExpirationMessage);
  const showExpirationUserMessage = useSelector(authentificationSelectors.selectShowExpirationUserMessage);
  const [isMounted, setIsMounted] = useState(false);

  // //anchorEl
  // const [, setAnchorEl] = useState(null);
  // //currentTab
  // const [, setCurrentTab] = useState(tabs.some(({ value }) => value === location.pathname) ? location.pathname : false);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
  }));

  useEffect(() => {
    userWasDeleted && handleSignOut();
  }, [userWasDeleted]);

  const handleWorkspaceChange = (value) => {
    dispatch(authentificationActions.updateUserDataCore({ workspaceId: value }));
    window.location.reload();
  };

  /* const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }; */

  const closeMenu = () => {
    // setAnchorEl(null);

    // hide user menu after following a link
    btnRef2.current.nextElementSibling.classList.add('hidden');
  };

  const openAccountSettings = () => {
    // setCurrentTab(false);
    closeMenu();
    navigationService.navigateTo(Path.Account);
  };

  const openCalendarConnections = () => {
    // setCurrentTab(false);
    closeMenu();
    navigationService.navigateTo(Path.CalendarConnections);
  };

  const handleSignOut = () => {
    dispatch(authentificationActions.logoutUserRequest(Path.Landing));
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const getMainURL = () => {
    return Path.BookingPages === location.pathname.startsWith(Path.OPSConsole) ? null : Path.BookingPages;
  }

  return (
    <div className="layout-topbar px-3">
      <div className="topbar-left">
        {(layoutConfig.colorScheme === 'dim' || (isQuickSetupFinished )) && (
          <button ref={menubuttonRef} type="button" className="menu-button p-link" onClick={onMenuToggle}>
            <i className="pi pi-chevron-left"></i>
          </button>
        )}
        <ViewAsUserWarningBlock />
        <span className="topbar-separator"></span>
        <AppBreadcrumb />
        <Link to={getMainURL()}>
          <img
            id="logo-mobile"
            className="mobile-logo"
            src={layoutConfig.colorScheme === 'dim' ? sumoLogoWhite : sumoLogo}
          />
        </Link>
      </div>
      <div className="layout-topbar-menu-section">
        <Link to={getMainURL()}>
          <img className="sumo-logo" src={layoutConfig.colorScheme === 'dim' ? sumoLogoWhite : sumoLogo} />
        </Link>
        {(layoutConfig.colorScheme === 'dim' || (isQuickSetupFinished )) && (
          <AppSidebar sidebarRef={props.sidebarRef} />
        )}
      </div>
      <div className="layout-mask modal-in"></div>

      <div className="topbar-right">
        <ul className="topbar-menu gap-3">
          {/* <li className="search-item">
            <a type="button" onClick={toggleSearch}>
              <i className="pi pi-search"></i>
            </a>
          </li>
          <li className="static sm:relative">
            <StyleClass
              nodeRef={btnRef1}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="scalein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
              hideOnOutsideClick
            >
              <a tabIndex={0} ref={btnRef1}>
                <i className="pi pi-bell"></i>
                <span className="topbar-badge">5</span>
              </a>
            </StyleClass>
            <ul className="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-19rem mt-2 right-0 z-5 top-auto">
              <li>
                <a className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                  <i className="pi pi-shopping-cart mr-3"></i>
                  <span className="flex flex-column">
                    <span className="font-semibold">New Order</span>
                    <span className="text-color-secondary">
                      You have <strong>3</strong> new orders.
                    </span>
                  </span>
                  <Ripple />
                </a>
                <a className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                  <i className="pi pi-check-square mr-3"></i>
                  <span className="flex flex-column">
                    <span className="font-semibold">Withdrawn Completed</span>
                    <span className="text-color-secondary">Funds are on their way.</span>
                  </span>
                  <Ripple />
                </a>
                <a className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                  <i className="pi pi-chart-line mr-3"></i>
                  <span className="flex flex-column">
                    <span className="font-semibold">Monthly Reports</span>
                    <span className="text-color-secondary">Monthly Reports are ready.</span>
                  </span>
                  <Ripple />
                </a>
                <a className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                  <i className="pi pi-comments mr-3"></i>
                  <span className="flex flex-column">
                    <span className="font-semibold">Comments</span>
                    <span className="text-color-secondary">
                      <strong>2</strong> new comments.
                    </span>
                  </span>
                  <Ripple />
                </a>
                <a className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                  <i className="pi pi-exclamation-circle mr-3"></i>
                  <span className="flex flex-column">
                    <span className="font-semibold">Chargeback Request</span>
                    <span className="text-color-secondary">
                      <strong>1</strong> to review.
                    </span>
                  </span>
                </a>
              </li>
            </ul>
          </li> */}

          {/* <li className="profile-item static sm:relative border-right-none pr-0"> */}
          {isQuickSetupFinished && layoutConfig.colorScheme !== 'dim' && isLicenseActive ? (
            <>
              {trialExpirationMessage && (
                <Link to={Path.Users} className="text-color">
                  <Tooltip target=".trial-expiration-icon" className="block md:hidden lg:block xl:hidden " />
                  <div
                    className="trial-expiration-icon bg-yellow-200 border-round p-2 flex align-items-center"
                    data-pr-tooltip={trialExpirationMessage}
                  >
                    <span className="pi pi-calendar-times text-xl lg:text-xl md:text-base xl:text-base " />
                    <span className="ml-1 hidden lg:hidden md:block xl:block"> {trialExpirationMessage}</span>
                  </div>
                </Link>
              )}
              {isSuperAdmin && paidExpirationMessage && (
                <Link to={Path.Billing} className="text-color">
                  <Tooltip target=".paid-expiration-icon" className="block md:hidden lg:block xl:hidden " />
                  <div
                    className="paid-expiration-icon bg-red-200 border-round p-2 flex align-items-center"
                    data-pr-tooltip={paidExpirationMessage}
                  >
                    <span className="pi pi-calendar-times text-xl lg:text-xl md:text-base xl:text-base " />
                    <span className="ml-1 hidden lg:hidden md:block xl:block"> {paidExpirationMessage}</span>
                  </div>
                </Link>
              )}
              {showExpirationUserMessage && (
                <>
                  <Tooltip target=".paid-expiration-icon" className="block md:hidden lg:block xl:hidden " />
                  <div
                    className="paid-expiration-icon bg-red-200 border-round p-2 flex align-items-center"
                    data-pr-tooltip={paidExpirationMessage}
                  >
                    <span className="pi pi-calendar-times text-xl lg:text-xl md:text-base xl:text-base " />
                    <span className="ml-1 hidden lg:hidden md:block xl:block"> {paidExpirationMessage}</span>
                  </div>
                </>
              )}
              {(userWorkspaces?.length || 0) > 1 && (
                <Dropdown
                  options={userWorkspacesOptions}
                  optionValue="id"
                  optionLabel="name"
                  value={selectedWorkspace}
                  dropdownIcon={`${userWorkspacesOptions?.length ? 'pi pi-chevron-down' : null}`}
                  valueTemplate={(options) =>
                    userWorkspacesOptions?.length ? (
                      <div>{options?.name}</div>
                    ) : (
                      <div className="w-4rem text-right">
                        <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
                      </div>
                    )
                  }
                  placeholder="Loading..." // no need in labels - never shown, used to hide dropdown standard css class
                  disabled={!userWorkspacesOptions?.length}
                  onChange={(e) => handleWorkspaceChange(e.value)}
                />
              )}
              <li className="profile-item static sm:relative border-none p-0 m-0">
                {isMounted && (
                  <StyleClass
                    nodeRef={btnRef2}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="scalein"
                    leaveToClassName="hidden"
                    leaveActiveClassName="fadeout"
                    hideOnOutsideClick="true"
                  >
                    <a tabIndex={1} ref={btnRef2} className="pl-1">
                      <UserAvatar />
                      <span className="ml-2 profile-name font-medium">{nameOrEmail}</span>
                    </a>
                  </StyleClass>
                )}
                <ul className="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-19rem mt-2 right-0 z-5 top-auto">
                  <li className="flex flex-column">
                    <span className="text-sm font-bold uppercase p-2 text-color-secondary">{labels.userSetup}</span>
                    <Link to={Path.CalendarConnections} className="text-color">
                      <span
                        className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                        onClick={openCalendarConnections}
                      >
                        <i className="pi pi-calendar mr-2 text-xl"></i>
                        <span className="flex flex-column">
                          <span>{labels.calendarConnections}</span>
                        </span>
                        <Ripple />
                      </span>
                    </Link>
                    <Link to={Path.Availability} className="text-color">
                      <span
                        className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                        onClick={openAccountSettings}
                      >
                        <i className="pi pi-thumbs-up mr-2 text-xl"></i>
                        <span className="flex flex-column">
                          <span>{labels.availability}</span>
                        </span>
                        <Ripple />
                      </span>
                    </Link>
                    {/* <a className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                  <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className="mr-2 text-xl" />
                  <span className="flex flex-column">
                    <span>{labels.integration}</span>
                  </span>
                  <Ripple />
                </a> */}
                    <span className="text-sm font-bold uppercase p-2 text-color-secondary">{labels.userSettings}</span>
                    <Link to={Path.Account} className="text-color">
                      <span
                        className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                        onClick={openAccountSettings}
                      >
                        <i className="pi pi-cog mr-2 text-xl"></i>
                        <span className="flex flex-column">
                          <span>{labels.profile}</span>
                        </span>
                        <Ripple />
                      </span>
                    </Link>
                    <Link to={urlConstants.KNOWLEDGE_BASE_URL} target="_blank" className="text-color">
                      <span className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                        <i className="pi pi-question-circle mr-2 text-xl"></i>
                        <span className="flex flex-column">
                          <span>{labels.helpCenter}</span>
                        </span>
                        <Ripple />
                      </span>
                    </Link>

                    <a
                      className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                      onClick={handleSignOut}
                    >
                      <i className="pi pi-power-off mr-2 text-xl"></i>
                      <span className="flex flex-column">
                        <span>{labels.logOut}</span>
                      </span>
                      <Ripple />
                    </a>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            <>
              <div className="mr-3">
                <UserAvatar />
                <span className="ml-2 profile-name">
                  {layoutConfig.colorScheme === 'dim' ? staffNameOrEmail : nameOrEmail}
                </span>
              </div>
              <Button
                label={labels.logOut}
                onClick={handleSignOut}
                icon="pi pi-power-off"
                outlined
                className="line-height-2"
              />
            </>
          )}
        </ul>
      </div>
    </div>
  );
});
AppTopbarAuth.displayName = 'AppTopbar';

export default AppTopbarAuth;
