export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Fundo`,
  BUTTONS_LABEL: `Botões`,
  FONT_COLORS_TITLE: `Cores da Fonte`,
  FONT_FAMILIES_TITLE: `Famílias de Fontes`,
  HEADER_FONT_LABEL: `Fonte do Cabeçalho`,
  HEADER_LABEL: `Cabeçalho`,
  MAIN_TEXT_FONT_LABEL: `Fonte do Texto Principal`,
  MAIN_TEXT_LABEL: `Texto Principal`,
  PRIMARY_LABEL: `Primário`,
  SITE_COLORS_TITLE: `Cores do Site`,
  SUB_HEADER_FONT_LABEL: `Fonte do Subcabeçalho`,
  SUB_HEADER_LABEL: `Subcabeçalho`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `Seu calendário está conectado!`,
  INSTRUCTION_NOTE: `A maioria dos administradores gosta de usar o SUMO para uso pessoal e de teste. No entanto, você sempre pode desconectar seu calendário mais tarde no menu no canto superior direito.`,
  INSTRUCTION_STEP_A1: `Vamos verificar `,
  INSTRUCTION_STEP_A2: ` para conflitos.`,
  INSTRUCTION_STEP_B: `Vamos adicionar reuniões ao calendário para `,
  INSTRUCTION_STEP_C1: `Vamos criptografar `,
  INSTRUCTION_STEP_C2: `todos os dados`,
  INSTRUCTION_STEP_C3: `em trânsito e oferecer opções sobre seus dados.`,
  INSTRUCTION_TITLE: `Veja como o SUMO funcionará com `,
  MESSAGE: `O SUMO verificará automaticamente seu calendário em busca de horários ocupados e adicionará novas reuniões conforme forem agendadas.`,
  TITLE: `Calendário`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Aceitar Termos`,
  ACCEPT_TERMS_PART2: `e`,
  ACCEPT_TERMS_PART3: `Política de Privacidade`,
  BENEFITS_DESCRIPTION: `A melhor experiência com o SUMO1 é usar o SUMO a partir da sua caixa de entrada, instalando a Extensão para Chrome. Use o SUMO em:`,
  BENEFITS_LIST_PART1: `Google Gmail e Calendário`,
  BENEFITS_LIST_PART2: `Microsoft Inbox e Calendário`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Benefícios`,
  CHROME_EXTENSION: `Extensão para Chrome`,
  REQUIREMENTS_DESCRIPTION: `Você deve usar o navegador Google Chrome.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SUMO1_FOR_GOOGLE: `Instalar "SUMO1 para Gmail"`,
  SUMO1_FOR_MICROSOFT: `Instalar "SUMO1 para Outlook"`,
  TITLE: `Instalar Extensão para Chrome`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  ADMIN_DESCRIPTION: `Quando sua equipe está tipicamente disponível para compromissos?`,
  MESSAGE: `DICA: Você pode definir vários horários e exceções mais tarde.`,
  MINIMUM_DAYS_ERROR_TEXT: `Seleccione pelo menos um dia com horas disponíveis`,
  SETUP_DAYS_INPUT_LABEL: `Dias disponíveis`,
  SETUP_HOURS_INPUT_LABEL: `Horas disponíveis`,
  TITLE: `Disponibilidade`,
  USER_DESCRIPTION: `Quando você está tipicamente disponível para compromissos?`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Quando o SUMO reserva automaticamente uma ligação telefônica para você, qual número de telefone o convidado deve ligar para falar com você?`,
  MESSAGE: `Este é um passo importante no processo de configuração do SUMO. A maioria dos usuários insere seu número de celular para receber também alertas por SMS. Claro, você pode sempre alterar isso posteriormente nas Configurações do usuário (menu no canto superior direito).`,
  PHONE_DETAILS_LABELS: `Detalhes do telefone`,
  PHONE_DETAILS_PLACEHOLDER: `Adicione informações adicionais ou instruções aqui.\r\nExemplo: Estou ansioso para a reunião. Envie-me um SMS a qualquer momento.`,
  PHONE_LABEL: `Seu número de telefone`,
  PHONE_PLACEHOLDER: `Insira seu número de telefone aqui`,
  TITLE: `Chamadas telefônicas`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Erro ao salvar configuração rápida`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Agora você está pronto para agendar facilmente.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Configuração completa!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  COMPLETED_BUTTON_LABEL: `Eu terminei isso.`,
  DEFAULT_DESCRIPTION: `Videoconferência Padrão`,
  DESCRIPTION: `Conecte suas contas de videoconferência.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MESSAGE: `Opcional, mas recomendado para reuniões virtuais.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  TITLE: `Videoconferência`,
  ZOOM_DESC: `Adicione automaticamente detalhes do Zoom em suas reuniões do SUMO.`,
  ZOOM_REQUIREMENTS: `Dependendo da sua configuração, o administrador da sua conta Zoom pode precisar aprovar antecipadamente o SUMO1 no Mercado Zoom.`,
  ZOOM_STEP_1: `Faça login em sua conta corporativa do ZOOM como administrador.`,
  ZOOM_STEP_2: `Clique em Configurações no canto superior direito.`,
  ZOOM_STEP_3: `Pesquise no mercado de ZOOM por "SUMO1".`,
  ZOOM_STEP_4: `Clique em "Autorizar SUMO1".`,
  ZOOM_STEP_5: `Clique em Aceitar.`,
  ZOOM_TITLE: `Reuniões ZOOM`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  COLORS_NOTES: `Não se preocupe, você pode modificar e testar essas configurações nas Páginas de Reserva mais tarde.`,
  COLORS_TITLE: `Cores e Fontes`,
  BACKGROUND: `Fundo`,
  DESCRIPTION: `Faça com que a experiência de reserva reflita a marca da sua empresa.`,
  LOGO: `Logotipo`,
  LOGO_DESCRIPTION: `A marca da sua empresa aparecerá em todas as páginas de reserva neste espaço de trabalho.`,
  LOGO_TITLE: `Seu Logotipo e Fundo`,
  PREVIEW_TOOLTIP: `Após ajustar as configurações, clique em Visualizar para ver uma página de reserva de exemplo.`,
  SAVE_BUTTON: `Salvar e Concluir Configuração`,
  TITLE: `Sua Marca`,
  UPLOAD_BACKGROUND: `Carregar Fundo`,
  UPLOAD_LOGO: `Carregar Logotipo`,
  YOUR_BACKGROUND: `Seu Fundo`,
  YOUR_LOGO: `Seu Logotipo`,
});
