import { t } from '../../i18n/i18n';

export default {
  activate: t('Common:ACTIVATE'),
  createTeam: t('Teams:CREATE_TEAM'),
  createdBy: t('Teams:CREATED_BY'),
  createdDate: t('Teams:CREATED_DATE'),
  deactivate: t('Common:DEACTIVATE'),
  deactivateTeamDesc: t('Teams:DEACTIVATE_TEAM_DESCRIPTION'),
  deactivateTeamTitle: t('Teams:DEACTIVATE_TEAM_TITLE'),
  delete: t('Common:DELETE'),
  deleteTeamDesc: t('Teams:DELETE_TEAM_DESCRIPTION'),
  deleteTeamTitle: t('Teams:DELETE_TEAM_TITLE'),
  edit: t('Common:EDIT'),
  members: t('Teams:MEMBERS'),
  name: t('Teams:NAME'),
  no: t('Common:NO'),
  noResults: t('Common:NO_RESULTS_FOUND'),
  search: t('Common:SEARCH'),
  title: t('Teams:TITLE'),
  tooltip: t('Teams:TOOLTIP'),
  view: t('Common:VIEW'),
  workspace: t('Teams:WORKSPACE'),
  yes: t('Common:YES'),
};
