import { useDispatch } from 'react-redux';
import { VIEW_ATTENDEES_MODAL_NAME, viewAttendeesModalActions } from '../../../store/scheduledMeetings/modal';
import { Modal } from '../../common';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { scheduledMeetingsSelectors } from '../../../store/scheduledMeetings/selectors';
import labels from './labels';
import { usersSelectors } from '../../../store/users';

export const ViewAttendeesModal = () => {
  const dispatch = useDispatch();
  const event = useSelector(scheduledMeetingsSelectors.selectSelectedEvent);
  const attendeeInfo = useSelector(scheduledMeetingsSelectors.selectEventAttendeeInfo);
  const hostId = useSelector(scheduledMeetingsSelectors.selectHostId);
  const coHostIds = useSelector(scheduledMeetingsSelectors.selectCoHostIds);
  const userInfoById = useSelector(usersSelectors.selectUserInfoById);

  const closeViewAttendees = () => {
    dispatch(viewAttendeesModalActions.closeModal());
  };

  const getHostInfo = (id: string | null | undefined) => {
    const userInfo = userInfoById[id || ''];
    return userInfo ? (userInfo.fullName ? `${userInfo.fullName} ` : '') + `(${userInfo.email})` : '';
  };

  return (
    <Modal.Container name={VIEW_ATTENDEES_MODAL_NAME}>
      <Modal.Header>
        <div className="flex flex-column align-items-center -mb-3">{labels.viewAttendees}</div>
      </Modal.Header>
      <div className="flex flex-column align-items-center" style={{ width: '560px' }}>
        <div className="w-12 flex mb-2 text-lg">
          <div className="w-6 flex justify-content-center">{labels.hosts}</div>
          <div className="w-6 flex justify-content-center">{labels.attendees}</div>
        </div>
        <div className="w-12 border-1 surface-border border-round h-12rem overflow-hidden">
          <div className="h-full overflow-y-auto">
            <div className="flex min-h-full">
              <div className="w-6 p-2 border-right-1 surface-border">
                <div>
                  <b>{getHostInfo(hostId) || event.hostName}</b>
                </div>
                {!!coHostIds.length &&
                  coHostIds.map((id, index) => (
                    <div key={`${id}`}>{getHostInfo(id) || (event.cohostNames && event.cohostNames[index])}</div>
                  ))}
              </div>
              <div className="w-6 p-2">
                <div>
                  <b>{attendeeInfo}</b>
                </div>
                {!!event.guestEmails?.length &&
                  event.guestEmails.map((email, index) => <div key={`${email}_${index}`}>{email}</div>)}
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-12 -mb-2 border-round flex justify-content-center align-items-center mt-4 p-2"
          style={{ background: '#e7f4fd' }}
        >
          <i className="pi pi-info-circle text-xl pr-2" />
          {labels.viewAttendeesNote}
        </div>
      </div>

      <Modal.Buttons>
        <Button label={labels.close} onClick={closeViewAttendees} outlined className="w-6rem justify-content-center" />
      </Modal.Buttons>
    </Modal.Container>
  );
};
