import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../common';
import {
  billingActions,
  billingSelectors,
  CHANGE_TERM_MODAL,
  changeTermModalActions,
  CHARGEBEE_TERM_OPTIONS,
} from '../../../store/billing';
import labels from './labels';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export const BillingChangeTermModal = () => {
  const dispatch = useDispatch();
  const term = useSelector(billingSelectors.selectTerm);
  const isChangeTermValid = useSelector(billingSelectors.selectIsChangeTermValid);

  const handleTermValueChange = (value: string) => {
    dispatch(billingActions.setTerm(value));
  };

  const handleCancel = () => {
    dispatch(changeTermModalActions.closeModal());
  };

  const handleChangeTerm = () => {
    dispatch(billingActions.changeTermRequest());
  };

  return (
    <Modal.Container name={CHANGE_TERM_MODAL}>
      <Modal.Header>
        <div className="font-bold">{labels.changeTerm}</div>
      </Modal.Header>
      <div className="w-30rem p-fluid">
        <Dropdown
          id="cancelType"
          value={term}
          options={CHARGEBEE_TERM_OPTIONS}
          onChange={(e) => handleTermValueChange(e.value)}
        />
      </div>
      <Modal.Buttons>
        <div className="flex gap-2 justify-content-end -mr-2">
          <Button label={labels.cancel} onClick={handleCancel} outlined />
          <Button label={labels.changeTerm} onClick={handleChangeTerm} disabled={!isChangeTermValid} />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
