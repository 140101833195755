
import { useDispatch, useSelector } from 'react-redux';
import labels from './labels';
import { errorSelectors } from '../../../store/error/selectors';
import { ErrorCodes } from '../../../store/error/types';
import { useLocation } from 'react-router-dom';
import errorImage from '../../../assets/images/error_desert.png';
import { Button } from 'primereact/button';
import { Path } from '../../../routing';
import { useEffect } from 'react';
import { errorActions } from '../../../store/error/actions';
import { navigationService } from '../../../services/NavigationService';
import { getBaseUrl } from '../../../services/utils';
import { authentificationActions } from '../../../store/authentification';

export const GlobalError = () => {
  const errorCode = useSelector(errorSelectors.selectErrorCode);
  const errorLocation = useSelector(errorSelectors.selectErrorLocation);
  const location = useLocation();
  const dispatch = useDispatch();

  const fullUrl = getBaseUrl().replace(/^https?:\/\//, '') + location.pathname;

  let errorTitle;
  let errorMessagePart1;
  let errorMessagePart2;
  let errorMessagePart3;
  switch (errorCode) {
    case ErrorCodes.CODE_403:
      errorTitle = labels.title403;
      errorMessagePart1 = labels.message403_1;
      errorMessagePart2 = fullUrl;
      errorMessagePart3 = labels.message403_2;
      break;
    case ErrorCodes.CODE_404:
      errorTitle = labels.title404;
      errorMessagePart1 = labels.message404_1;
      errorMessagePart2 = fullUrl;
      errorMessagePart3 = labels.message404_2;
      break;
    case ErrorCodes.CODE_500:
      errorTitle = labels.title500;
      errorMessagePart1 = labels.message500_1;
      errorMessagePart2 = labels.message500_2;
      errorMessagePart3 = labels.message500_3;
      break;
    default:
      errorTitle = labels.title404;
      errorMessagePart1 = labels.message404_1;
      errorMessagePart2 = fullUrl;
      errorMessagePart3 = labels.message404_2;
  }

  useEffect(() => {
    if (errorLocation && errorLocation !== location.pathname) dispatch(errorActions.resetTheError());
  }, [location]);

  const handleButtonHome = () => {
    if (errorCode === ErrorCodes.CODE_500) {
      dispatch(authentificationActions.logoutUserRequest(Path.Landing));
    }
    dispatch(errorActions.resetTheError());
    navigationService.navigateTo(Path.Landing);
  };

  const errorMessage = (
    <>
      <div>{errorMessagePart1}</div>
      <div style={{ fontWeight: 'bold', textDecoration: errorCode === ErrorCodes.CODE_500 ? 'underline' : 'none' }}>
        {errorMessagePart2}
      </div>
      <div>{errorMessagePart3}</div>
    </>
  );

  //if (layoutConfig.colorScheme === 'light') document.body.style.backgroundColor = 'white';

  return (
    <div className="flex flex-column justify-content-center align-items-center mt-8">
      <div className="m-3 text-5xl font-semibold">{errorTitle}</div>
      <div className="flex justify-content-center flex-wrap gap-1 w-4 text-xl mb-4">{errorMessage}</div>
      <div>
        <img src={errorImage} alt="error_desert" />
      </div>
      <div className="w-2 flex flex-column mt-4">
        <Button onClick={handleButtonHome} className="text-xl justify-content-center" color="primary">
          {labels.homeButton}
        </Button>
      </div>
    </div>
  );
};
