import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CodeResponse as GoogleCodeResponse } from '@react-oauth/google';
import {
  MicrosoftCodeResponse,
  ThirdPartyType,
  authentificationActions,
  authentificationSelectors,
} from '../../../store/authentification';
import { GoogleAuthButton, MicrosoftAuthButton } from '../../public';
import { timeZoneService } from '../../../services/TimeZoneService';
import { useLocation } from 'react-router-dom';
import { IntegrationType } from '../../../API';
import { globalSelectors } from '../../../store/global';
import { Button } from 'primereact/button';
import labels from './labels';

interface IAuthentication {
  title: string;
  subtitle: string;
  comment?: string;
  typeTitle: string;
  icon: string;
  linkText: string;
  linkSubtext: string;
  linkAction?: () => void;
  googleLabel: string;
  microsoftLabel: string;
  login: boolean;
}

export const Authentication = memo(
  ({
    title,
    subtitle,
    comment,
    typeTitle,
    icon,
    linkText,
    linkSubtext,
    linkAction,
    googleLabel,
    microsoftLabel,
    login,
  }: IAuthentication) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const tenantId = params.get('tenantId');
    const isFetching = useSelector(authentificationSelectors.selectIsFetching);
    const isGlobalFetching = useSelector(globalSelectors.selectIsFetching);

    const userTimeZone = timeZoneService.getCurrentTimeZone();
    const [thirdPartyType, setThirdPartyType] = useState<ThirdPartyType | null>(null);

    const handleGoogleAuthSuccess = (codeResponse: GoogleCodeResponse) => {
      setThirdPartyType(ThirdPartyType.GOOGLE);
      dispatch(
        authentificationActions.thirdPartyAuthRequest({
          type: ThirdPartyType.GOOGLE,
          integrationType: IntegrationType.GOOGLE_CALENDAR,
          codeResponse,
          userTimeZone,
          switchAccount: false,
          inviteTenantId: tenantId,
          login,
        })
      );
    };

    const handleMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse) => {
      setThirdPartyType(ThirdPartyType.MICROSOFT);
      dispatch(
        authentificationActions.thirdPartyAuthRequest({
          type: ThirdPartyType.MICROSOFT,
          integrationType: IntegrationType.MICROSOFT_CALENDAR,
          codeResponse,
          userTimeZone,
          switchAccount: false,
          inviteTenantId: tenantId,
          login,
        })
      );
    };

    return (
      <div>
        <div className="card mb-3 flex justify-content-between sumo-header-bg">
          <div className="flex align-items-center">
            <i className={`text-2xl mr-3 ${icon}`}></i>
            <h5 className="m-0">{typeTitle}</h5>
          </div>
        </div>
        <div className="card flex flex-column align-items-center pt-4 pb-6" style={{ background: '#fdfdfd' }}>
          <h1>{title}</h1>
          <div className="text-2xl mb-2">{subtitle}</div>
          <div className="text-sm mb-4">{comment}</div>
          <div className="card flex flex-column align-items-center p-5">
            {(isFetching || isGlobalFetching) && thirdPartyType === ThirdPartyType.GOOGLE ? (
              <Button
                label={isFetching ? labels.connectingToGoogle : labels.syncingCalendar}
                icon="pi pi-spinner pi-spin"
                className="w-21rem"
              />
            ) : (
              <GoogleAuthButton
                label={googleLabel}
                icon="pi pi-google"
                onSuccess={handleGoogleAuthSuccess}
                className="w-21rem"
                login={login}
                disabled={isFetching || isGlobalFetching}
              />
            )}
            {(isFetching || isGlobalFetching) && thirdPartyType === ThirdPartyType.MICROSOFT ? (
              <Button
                label={isFetching ? labels.connectingToMicrosoft : labels.syncingCalendar}
                icon="pi pi-spinner pi-spin"
                className="w-21rem mt-5"
              />
            ) : (
              <MicrosoftAuthButton
                label={microsoftLabel}
                icon="pi pi-microsoft"
                onRedirect={handleMicrosoftAuthRedirect}
                className="w-21rem mt-5"
                login={login}
                disabled={isFetching || isGlobalFetching}
              />
            )}
          </div>
          <div>
            {linkSubtext}
            <a href="#" onClick={linkAction} className="no-underline hover:underline text-primary ml-2">
              {linkText}
            </a>
          </div>
        </div>
      </div>
    );
  }
);

Authentication.displayName = 'Authentication';
