import { t } from '../../../i18n/i18n';

export default {
  addNewMember: t('OPSConsoleStaff:ADD_NEW_MEMBER'),
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  save: t('Common:SAVE'),
  title: t('OPSConsoleStaff:TITLE'),
  email: t('Common:EMAIL'),
  name: t('Users:NAME'),
  role: t('Users:ROLE'),
  status: t('Users:STATUS'),
  lastActive: t('Users:LAST_ACTIVE'),
  deactivateTitle: t('UsersConfirmDialog:DISABLE_TITLE'),
  deactivateMessage: t('UsersConfirmDialog:DISABLE_DESCRIPTION'),
  yes: t('Common:YES'),
  no: t('Common:NO'),
  duplicateEmail: t('OPSConsoleStaff:DUPLICATE_EMAIL'),
};
