import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { DateFormat, TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { Dropdown } from 'primereact/dropdown';
import { scheduledMeetingsSelectors } from '../../../store/scheduledMeetings/selectors';
import { CreateUserEventInput, CustomFieldInput, CustomFieldType, LocationType } from '../../../API';
import { formatDateByThreeOptions, formatDateTimeThroughATSymbol } from '../../../services/DateService';
import { scheduledMeetingsActions } from '../../../store/scheduledMeetings/actions';
import { SortMethods, Statuses } from '../../../store/scheduledMeetings/typings';
import { MultiSelect } from 'primereact/multiselect';
import { editorTextRegex, locationLabels, statusList } from '../../../store/scheduledMeetings/constants';
import dayjs from 'dayjs';
import { usersSelectors } from '../../../store/users';
import i18n from '../../../i18n/i18n';
import { BookingPageOption, bookingPageSelectors } from '../../../store/bookingPages';

export const ScheduledFilters = () => {
  const dispatch = useDispatch();
  const calendarRef = useRef<HTMLDivElement>(null);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat) || DateFormat.default;
  const timeForamt = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const scheduledMeetings = useSelector(scheduledMeetingsSelectors.selectFilteredEvents);
  const bookingPages = useSelector(bookingPageSelectors.selectBookingPagesOptions);
  const selectedStatus = useSelector(scheduledMeetingsSelectors.selectStatus);
  const selectedSortMethod = useSelector(scheduledMeetingsSelectors.selectSortMethod);
  const selectedBookingPages = useSelector(scheduledMeetingsSelectors.selectSelectedBookingPages);
  const dateRange = useSelector(scheduledMeetingsSelectors.selectDateRange);
  const userNamesById = useSelector(usersSelectors.selectUserNamesById);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilters, setShowFilters] = useState(
    Boolean(selectedBookingPages.length || selectedStatus !== Statuses.ALL)
  );
  const [selectedRange, setSelectedRange] = useState(dateRange.map((date) => new Date(date)));

  // const [selectedTeam, setSelectedTeam] = useState(null); // TODO: move it to store/scheduledEvents when Teams will be implemented
  // const teams = [
  //   // TODO: hardcode until Teams is not implemented
  //   { label: 'Team A', value: 'Team A' },
  //   { label: 'Team B', value: 'Team B' },
  // ];

  const optionsMap = new Map(bookingPages.map((page) => [page.value, page]));
  scheduledMeetings.forEach((event) => {
    if (!optionsMap.has(event.bookingPageId)) {
      optionsMap.set(event.bookingPageId, { label: event.bookingPageName, value: event.bookingPageId });
    }
  });

  const getLocationName = (location: LocationType | null | undefined) => {
    return location ? locationLabels[location] : labels.noLocationGiven;
  };

  const getAttendeeEmail = (customField: Array<CustomFieldInput | null> | null | undefined) => {
    const emailField = customField?.find((field) => field?.fieldType === CustomFieldType.EMAIL) || null;
    return emailField && emailField?.answers && emailField.answers[0] ? emailField.answers[0] : '';
  };

  // utility to Convert Array to CSV
  const convertArrayToCSV = (data: CreateUserEventInput[]): string => {
    const CSVHeaders = [
      // 'MEETING NAME', // TODO Add Meeting name from event
      'DATETIME',
      'TIME ZONE',
      'LOCATION',
      'HOST',
      'ATTENDEES',
      'MEETING NOTES',
      'CANCELED',
      'NO-SHOW',
      // 'ATTENDEE LIMIT',
      // 'BOOKED VS LIMITS',
    ];

    const rows = data.map((obj) =>
      [
        // bookingTemplateNamesById[obj.meetingTypeId] || '',
        formatDateTimeThroughATSymbol(obj.startTime, obj.endTime, dateFormat, timeForamt), // 01/01/1995 @ 01-00pm - 02:00pm
        obj.timeZone,
        getLocationName(obj.location?.type),
        userNamesById[obj.userId] || '',
        getAttendeeEmail(obj.inputFields) + (obj.guestEmails?.length ? '; ' + obj.guestEmails?.join('; ') : ''),
        obj.note ? obj.note.replace(editorTextRegex, '') : '',
        obj.canceled?.isCanceled ? labels.yes : labels.no,
        obj.isNoShow ? labels.yes : labels.no,
        // '', // empty for 'ATTENDEE LIMIT' until this entity is not exist
        // '', // empty for 'BOOKED VS LIMITS' until this entity is not exist
      ].join(',')
    );

    return [CSVHeaders.join(','), ...rows].join('\n');
  };

  // handler to download CSV
  const downloadCSV = () => {
    const csvContent = convertArrayToCSV(scheduledMeetings);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'scheduled-meetings.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getRangeLabel = () => {
    if (selectedRange && selectedRange[0]) {
      return (
        formatDateByThreeOptions(selectedRange[0], dateFormat) +
        (selectedRange[1] ? ` - ${formatDateByThreeOptions(selectedRange[1], dateFormat)}` : '')
      );
    }
    return labels.dateRange;
  };

  const wizardItems = [
    {
      label: labels.upcoming,
      command: () => {
        dispatch(scheduledMeetingsActions.setSortMethod(SortMethods.UPCOMING));
      },
    },
    {
      label: labels.past,
      command: () => {
        dispatch(scheduledMeetingsActions.setSortMethod(SortMethods.PAST));
      },
    },
    {
      label: getRangeLabel(),
      command: () => {
        if (selectedSortMethod === SortMethods.DATE_RANGE) {
          setShowCalendar(true);
        } else {
          dispatch(scheduledMeetingsActions.setSortMethod(SortMethods.DATE_RANGE));
          if (!selectedRange.length) {
            setShowCalendar(true);
          }
        }
      },
    },
  ];

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDateChange = (e: CalendarChangeEvent) => {
    if (Array.isArray(e.value)) {
      setSelectedRange(e.value);
      const from = e.value[0] ? dayjs(e.value[0]).format('YYYY-MM-DD') : '';
      const to = e.value[1] ? dayjs(e.value[1]).format('YYYY-MM-DD') : from;
      dispatch(scheduledMeetingsActions.setDateRange([from, to]));
    } else {
      setSelectedRange([]); //clear button clicked
      dispatch(scheduledMeetingsActions.setDateRange([]));
    }
  };

  const todayClickHandle = () => {
    const today = new Date();
    const todayString = dayjs().format('YYYY-MM-DD');
    setSelectedRange([today, today]);
    dispatch(scheduledMeetingsActions.setDateRange([todayString, todayString]));
  };

  return (
    <div className="card">
      <div className="flex">
        <div className="-mt-2">
          <TabMenu model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
          {showCalendar && (
            <div ref={calendarRef} style={{ position: 'absolute', zIndex: 1 }}>
              <Calendar
                selectionMode="range"
                locale={i18n.language}
                showButtonBar
                inline
                value={selectedRange.length ? selectedRange : null}
                onChange={handleDateChange}
                onTodayButtonClick={todayClickHandle}
              />
            </div>
          )}
        </div>
        <div className="flex-1">
          <div className="flex justify-content-end gap-3">
            <Button label={labels.filter} outlined icon="pi pi-filter" onClick={() => setShowFilters(!showFilters)} />
            <Button label={labels.export} outlined icon="pi pi-download" onClick={downloadCSV} />
          </div>
        </div>
      </div>
      {showFilters && (
        <div className="flex gap-3 mt-4">
          {/* <div className="flex gap-2 flex-column w-3">
            <label htmlFor="team" className="font-semibold">
              {labels.team}
            </label>
            <MultiSelect
              id="team"
              name="team"
              value={selectedTeam}
              options={teams}
              onChange={(e) => setSelectedTeam(e.value)}
              placeholder="Select Team"
              display="chip"
            />
          </div> */}
          <div className="flex gap-2 flex-column w-3">
            <label htmlFor="bookingPages" className="font-semibold">
              {labels.bookingPage}
            </label>
            <MultiSelect
              id="bookingPages"
              name="bookingPages"
              value={selectedBookingPages || []}
              options={Array.from(optionsMap.values())}
              onChange={(e) => dispatch(scheduledMeetingsActions.setSelectedBookingPages(e.value))}
              placeholder={labels.bookingPagePlaceholder}
              display="chip"
              itemTemplate={(option) => (
                <div className="w-16rem flex justify-content-between gap-1">
                  <div className="flex-1 overflow-hidden text-overflow-ellipsis">{option.label}</div>
                  {option.displayId && <div className="bg-gray-200 px-2 border-round">{option.displayId}</div>}
                </div>
              )}
            />
          </div>
          <div className="flex gap-2 flex-column w-3">
            <label htmlFor="status" className="font-semibold">
              {labels.status}
            </label>
            <Dropdown
              id="status"
              name="status"
              value={selectedStatus}
              options={statusList}
              onChange={(e) => dispatch(scheduledMeetingsActions.setStatus(e.value))}
              placeholder={labels.statusPlaceholder}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ScheduledFilters.displayName = 'ScheduledFilters';
