import { useSelector } from 'react-redux';
import labels from './labels';
import { userSettingsSelectors } from '../../../store/userSettings';
import { SectionTitle } from '../../common';
import { StatItem } from './StatItem';

export const BookingStats = () => {
  const eventCreated = useSelector(userSettingsSelectors.selectEventCreatedStat);
  const emailsSent = useSelector(userSettingsSelectors.selectEmailsSentStat);
  const eventRescheduled = useSelector(userSettingsSelectors.selectEventRescheduledStat);
  const eventCanceled = useSelector(userSettingsSelectors.selectEventCanceledStat);
  const statsItems = [eventCreated, emailsSent, eventRescheduled, eventCanceled];

  return (
    <>
      <div className="card flex justify-content-between align-items-center sumo-header-bg border-noround-bottom mb-0 py-2 line-height-4">
        <SectionTitle id="bookingStatsPageTitle" icon="pi-chart-bar" title={labels.title} />
      </div>
      <div className="card border-noround-top">
        <div className="grid -mb-2">
          {statsItems.map((item, index) => {
            const label = labels.statItemsLabels[index];
            return (
              <div className="col-6 md:col-3" key={`statItems_${index}`}>
                <StatItem label={label} value={item} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
