import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import labels from './labels';
import { useDispatch } from 'react-redux';
import { Path } from '../../../../routing';
import {
  accountsActions,
  deleteAccountModalActions,
  mergeAccountsModalActions,
} from '../../../../store/opsConsole/accounts';
import { navigationService } from '../../../../services/NavigationService';

type AccountsTableRowActionsProps = {
  accountId: string;
  showEdit?: boolean;
  showDelete?: boolean;
  showMerge?: boolean;
  disableDelete?: boolean;
};

export const AccountsTableRowActions = ({
  accountId,
  showEdit = true,
  showDelete = true,
  showMerge = false,
  disableDelete,
}: AccountsTableRowActionsProps) => {
  const dispatch = useDispatch();
  const menu = useRef<Menu | null>(null);

  const menuEditItem = {
    label: labels.edit,
    command: () => {
      navigationService.navigateTo(Path.OPSConsoleAccount.replace(':accountId', accountId));
    },
  };
  const menuMergeItem = {
    label: labels.merge,
    command: () => {
      dispatch(accountsActions.selectAccount(accountId));
      dispatch(mergeAccountsModalActions.openModal());
    },
  };
  const menuDeleteItem = {
    label: labels.delete,
    command: () => {
      dispatch(accountsActions.selectAccount(accountId));
      dispatch(deleteAccountModalActions.openModal());
    },
    disabled: disableDelete,
  };

  const menuItems = [
    ...(showEdit ? [menuEditItem] : []),
    ...(showMerge ? [menuMergeItem] : []),
    ...(showDelete ? [menuDeleteItem] : []),
  ];

  return (
    <>
      <Button
        icon="pi pi-ellipsis-h text-color"
        text
        onClick={(e) => {
          return menu.current?.toggle && menu.current.toggle(e);
        }}
      />
      <Menu model={menuItems} popup ref={menu} />
    </>
  );
};
