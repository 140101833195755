import { timeZoneService } from '../services/TimeZoneService';
import urlConstants from '../shared/JSON/urlConstants.json';

export const languageNames: { [key: string]: string } = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  pt: 'Português',
  es: 'Español',
  // Add more languages here
};

export const CURRENT_TIME_ZONE = timeZoneService.getCurrentTimeZone();
export const TIME_ZONES_BY_NAME = timeZoneService.getTimeZonesDataByName();
export const TIME_ZONE_OPTIONS = timeZoneService.getCommonTimeZonesByRegion();
export const TIME_ZONE_LIST = timeZoneService.getTimeZonesDataList();
export const isUserFromCanadaOrUS = timeZoneService.isUserFromUSOrCanada();

export const GA_TRACKING_ID = 'G-G0BPF3DW5P'; // Google Analytics TRACKING_ID

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z|a-z]{2,}$/i;

export const MAX_LENGTH_NAME = 40;
export const MAX_LENGTH_DESCRIPTION = 500;
export const MAX_LENGTH_EMAIL = 320;
export const MAX_LENGTH_PHONE = 16;
export const MAX_LENGTH_QUESTION = 200;
export const MAX_LENGTH_SHORT_ANSWER = 100;
export const MAX_LENGTH_LONG_ANSWER = 200;

export const MIN_ITEMS_FOR_SEARCH = 6;

export const FILE_IMAGE_TYPES = 'image/jpeg, image/gif, image/png, image/svg+xml';
export const MAX_FILE_SIZE_BYTES = 1024 * 1024 * 5;

export const excludeUrls = [urlConstants.SUMO1_APP_OLD_PROD_URL, urlConstants.SUMO1_APP_PROD_URL];

export enum SERVER_ERROR_CODES {
  Forbidden = '403',
  NotFound = '404',
  LicenseExpired = '423',
  RoleDeactivated = '460',
  UserDeactivate = '461',
  TrialLimit = '462',
  DeleteRole = '463',
  SwitchAccount = '464', // email already in use
  ServerError = '500',
  NoUsersAvailable = '466',
}
