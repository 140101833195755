import { useSelector } from 'react-redux';
import labels from './labels';
import { authentificationSelectors } from '../../store/authentification';
import urlConstants from '../../shared/JSON/urlConstants.json';

export const LicenseExpired = () => {
  const isTrialLicense = useSelector(authentificationSelectors.selectIsTrialLicense);

  return (
    <div>
      <div className="card mb-3 flex justify-content-between sumo-header-bg">
        <div className="flex align-items-center">
          <i className="text-2xl mr-3 pi pi-key"></i>
          <h5 className="m-0">{labels.login}</h5>
        </div>
      </div>
      <div className="card flex flex-column align-items-center pt-4 pb-6">
        <h1>{isTrialLicense ? labels.titleTrial : labels.title}</h1>
        <div className="flex p-2 border-1 border-300 text-lg" style={{ background: '#e7f4fd' }}>
          <i className="pi pi-info-circle text-2xl pr-2" />
          {labels.message}
        </div>
        <div className="text-2xl mt-4 mb-1">{labels.desc}</div>

        <div className="w-11 border-1 border-200 shadow-1 my-3 flex flex-column align-items-center">
          <div className="w-10 grid py-3">
            <div className="flex flex-row justify-content-center flex-wrap">
              <div className="col-10 md:col-4 flex flex-column align-items-center p-5">
                <i className="pi pi-comments text-6xl"></i>
                <div className="font-bold uppercase pt-3 pb-2">{labels.chatTitle}</div>
                <div className="text-center">{labels.chatDesc}</div>
              </div>
              <div className="col-10 md:col-4 flex flex-column align-items-center p-5">
                <a className="text-color flex flex-column align-items-center" href={urlConstants.TALK_TO_AN_EXPERT_URL} target="blank">
                  <i className="pi pi-calendar text-6xl"></i>
                  <div className="font-bold uppercase pt-3 pb-2">
                    {labels.bookMeetingTitle}
                  </div>
                </a>
                <div className="text-center">{labels.bookMeetingDesc}</div>
              </div>
              <div className="col-10 md:col-4 flex flex-column align-items-center p-5">
                <a className="text-color flex flex-column align-items-center" href={urlConstants.LOG_A_CASE_URL} target="blank">
                  <i className="pi pi-briefcase text-6xl"></i>
                  <div className="font-bold uppercase pt-3 pb-2">
                    {labels.logCaseTitle}
                  </div>
                </a>
                <div className="text-center">{labels.logCaseDesc}</div>
              </div>
            </div>
            <div className="flex flex-row justify-content-center flex-wrap">
              <div className="col-10 md:col-4 flex flex-column align-items-center p-5">
                <a className="text-color flex flex-column align-items-center" href={urlConstants.KNOWLEDGE_BASE_URL} target="blank">
                  <i className="pi pi-video text-6xl"></i>
                  <div className="font-bold uppercase pt-3 pb-2">
                    {labels.helpCenterTitle}
                  </div>
                </a>
                <div className="text-center">{labels.helpCenterDesc}</div>
              </div>
              <div className="col-10 md:col-4 flex flex-column align-items-center p-5">
                <a className="text-color flex flex-column align-items-center" href={urlConstants.EXPERTS_EMAIL_ADDRESS}>
                  <i className="pi pi-envelope text-6xl"></i>
                  <div className="font-bold uppercase pt-3 pb-2">
                    {labels.emailTitle}
                  </div>
                </a>
                <div className="text-center">{labels.emailDesc}</div>
              </div>
              <div className="col-10 md:col-4 flex flex-column align-items-center p-5">
                <i className="pi pi-phone text-6xl"></i>
                <div className="font-bold uppercase pt-3 pb-2">{labels.callTitle}</div>
                <div className="text-center">{labels.callDesc}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
