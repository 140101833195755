import { IntegrationType, VideoConferenceType } from '../../API';

export const generateVideoConference = (type: IntegrationType) => {
  switch (type) {
    case IntegrationType.GOOGLE_MEET:
      return VideoConferenceType.GOOGLE_MEET;
    case IntegrationType.MICROSOFT_TEAMS:
      return VideoConferenceType.MICROSOFT_TEAMS;
    case IntegrationType.ZOOM:
      return VideoConferenceType.ZOOM;
    default:
      return null;
  }
}
