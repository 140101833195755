import { Modal } from '../../common';
import { CHOOSE_THE_ACCOUNT, chooseTheAccountModalActions } from '../../../store/authentification/modal';
import { CreateAdminDataInput } from '../../../API';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  CreateAdminDataType,
  authentificationActions,
  authentificationSelectors,
} from '../../../store/authentification';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { styles } from './styles';
import labels from './labels';

export const ModalUserAccounts = () => {
  const allUserAccounts: CreateAdminDataInput[] | null = useSelector(authentificationSelectors.selectUserAccounts);
  const isLastSuperAdmin = useSelector(authentificationSelectors.selectIsLastSuperAdmin);
  const userAccounts = allUserAccounts?.filter((account) => account.invitedByName);
  const accountWithoutInvite = allUserAccounts?.find((account) => !account.invitedByName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allUserAccounts && allUserAccounts.length > 1) {
      dispatch(chooseTheAccountModalActions.openModal());
    }
  }, [allUserAccounts]);

  const handleClosePopup = () => {
    dispatch(chooseTheAccountModalActions.closeModal());
  };

  const handleCloseDeleteDialog = () => {
    if (accountWithoutInvite) {
      dispatch(authentificationActions.chooseAnAccountRequest(accountWithoutInvite));
    }
    dispatch(chooseTheAccountModalActions.closeModal());
  };

  const handleChooseAnAccount = (account: CreateAdminDataInput) => {
    dispatch(authentificationActions.chooseAnAccountRequest(account));
    dispatch(chooseTheAccountModalActions.closeModal());
  };

  return (
    <Modal.Container name={CHOOSE_THE_ACCOUNT}>
      <Modal.Header>
        <div className="flex justify-between align-items-center">
          <span className="font-semibold">{labels.title}</span>
          <Button className="ml-auto" icon="pi pi-times" text onClick={handleClosePopup} />
        </div>
      </Modal.Header>

      <div className="w-24rem mb-4">
        {isLastSuperAdmin && (
          <div className="bg-blue-100 p-3 border-round mb-2">
            <i className="pi pi-info-circle pr-2 vertical-align-middle" />
            {labels.lastSuperAdmin}
          </div>
        )}
        <div>{labels.inviteText}</div>
        <div className="mt-2 mb-2 text-red-500">{labels.onlyOneAccount}</div>
        <div>{labels.selectText}</div>
      </div>

      <div className="flex flex-column">
        <div className="flex font-bold justify-content-center align-items-center">{labels.inviteFrom}</div>
        <Divider />
        {userAccounts &&
          userAccounts.map((account: CreateAdminDataType) => (
            <div key={account.updatedAt}>
              <div className="flex align-items-center">
                <div className="flex-1">{account.invitedByName}</div>
                <div className="flex justify-content-end">
                  <Button
                    onClick={() => {
                      handleChooseAnAccount(account);
                    }}
                    disabled={isLastSuperAdmin}
                  >
                    {labels.acceptInvite}
                  </Button>
                </div>
              </div>
              <Divider />
            </div>
          ))}
      </div>

      <Modal.Buttons>
        <div style={styles.buttons} className="flex-grow-1 flex justify-content-around line-height-4">
          <Button onClick={handleCloseDeleteDialog} text outlined className="w-8rem justify-content-center">
            {labels.cancel}
          </Button>
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
