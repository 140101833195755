export const Availability = Object.freeze({
  ACTIVE_ON: `Ativo em`,
  BOOKING_PAGE: `Página de Reservas`,
  BOOKING_PAGES: `Páginas de Reservas`,
  CLONE_SCHEDULE_TITLE: `Clonar horário`,
  DATE_OVERRIDE_BUTTON: `Nova Anulação`,
  DATE_OVERRIDE_DESCRIPTION: `Adicionar exceções a este horário abaixo:`,
  DATE_OVERRIDE_MODAL_HOURS: `Selecione suas horas de disponibilidade:`,
  DATE_OVERRIDE_MODAL_PROMPT: `Selecione a data (ou datas):`,
  DATE_OVERRIDE_MODAL_UNAVAILABLE: `Clique em Salvar para bloquear esta data (ou datas) como indisponível.`,
  DATE_OVERRIDE_TITLE: `Adicionar anulação de data`,
  DATES_OVERLAPPING_TEXT: `Algumas datas já foram anuladas.`,
  DEFAULT_AVAILABILITY_NAME: `Horário de trabalho`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  DELETE_MESSAGE: `será definido como o novo horário padrão.`,
  DESCRIPTION: `Escolha um horário abaixo para editar ou crie um novo que você pode aplicar às suas páginas de reservas.`,
  DIALOG_EDIT_NAME_TITLE: `Editar nome do horário`,
  DIALOG_NEW_NAME_TITLE: `Novo horário`,
  DIALOG_PLACEHOLDER: `Horas de trabalho, Horas exclusivas, etc...`,
  DIALOG_TITLE: `Nome do horário`,
  DUPLICATE_NAME_MESSAGE: `Já existe um horário com este nome`,
  EDIT_NAME: `Editar nome`,
  IS_DEFAULT: `horário padrão`,
  NEW_SCHEDULE: `Novo Horário`,
  SAVE_AVAILABILITY: `Salvar disponibilidade`,
  SET_AS_DEFAULT: `Definir como Padrão`,
  SET_WEEKLY_HOURS: `Defina suas horas semanais:`,
  TIME_INTERVAL_OVERLAPPING: `Os intervalos de tempo não podem se sobrepor.`,
  TIME_INVALID_TEXT: `Escolha uma hora de término posterior à hora de início`,
  TIME_ZONE: `Fuso Horário`,
  TITLE: `Disponibilidade`,
  TOOLTIP: `Gerir a sua disponibilidade com horários de trabalho`,
});

export const AvailabilityToastsNotifications = Object.freeze({
  CREATE_AVAILABILITY_ERROR_MESSAGE: `Erro ao solicitar a criação de disponibilidade`,
  CREATE_AVAILABILITY_SUCCESS_MESSAGE: `Solicitação de criação de disponibilidade bem-sucedida`,
  DELETE_AVAILABILITY_ERROR_MESSAGE: `Erro ao solicitar a exclusão de disponibilidade`,
  DELETE_AVAILABILITY_SUCCESS_MESSAGE: `Solicitação de exclusão de disponibilidade bem-sucedida`,
  GET_AVAILABILITY_ERROR_MESSAGE: `Erro ao solicitar a disponibilidade`,
  SAVE_AVAILABILITY_ERROR_MESSAGE: `Erro ao solicitar a salvaguarda de disponibilidade`,
  SAVE_AVAILABILITY_SUCCESS_MESSAGE: `Solicitação de salvaguarda de disponibilidade bem-sucedida`,
  SET_DEFAULT_AVAILABILITY_ERROR_MESSAGE: `Erro ao solicitar a definição da disponibilidade padrão`,
  SET_DEFAULT_AVAILABILITY_SUCCESS_MESSAGE: `Solicitação de definição de disponibilidade padrão bem-sucedida`,
});

export const AvailabilityWeekDays = Object.freeze({
  MONDAY_SHORT: 'Seg',
  TUESDAY_SHORT: 'Ter',
  WEDNESDAY_SHORT: 'Qua',
  THURSDAY_SHORT: 'Qui',
  FRIDAY_SHORT: 'Sex',
  SATURDAY_SHORT: 'Sáb',
  SUNDAY_SHORT: 'Dom',
});
