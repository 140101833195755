export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Os seguintes usuários estão designados para usar apenas este espaço de trabalho`,
  NOTES: `Você deve primeiro atribuir esses usuários a outro espaço de trabalho antes de excluí-lo.`,
  TITLE: `Reatribuir Usuários`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Erro ao ativar/desativar o espaço de trabalho`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espaço de trabalho ativado`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Erro ao excluir o espaço de trabalho`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Solicitação de exclusão do espaço de trabalho bem-sucedida`,
  GET_WORKSPACES_ERROR_MESSAGE: `Erro ao obter a solicitação de espaços de trabalho`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espaço de trabalho desativado`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Erro ao salvar a solicitação do espaço de trabalho`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Espaço de trabalho salvo com sucesso`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administradores`,
  ADMIN_DESCRIPTION: `As seguintes pessoas podem administrar este espaço de trabalho.`,
  INVITE_SETTINGS_TITLE: `Configurações de Convite`,
  NOTES_PART_1: `Alternativamente, você pode usar a seção de `,
  NOTES_PART_2: `Gestão de Usuários`,
  NOTES_PART_3: ` para encontrar um usuário e adicioná-lo a um espaço de trabalho.`,
  PHONE_NUMBER_DESCRIPTION: `Se você planeja usar o SUMO para agendar chamadas telefônicas, é recomendável mantê-lo ativado.`,
  PHONE_NUMBER_TITLE: `Tornar obrigatório o campo "Número de Telefone" para os usuários.`,
  SEARCH_ADD_ADMINS: `Pesquisar e adicionar administrador`,
  SEARCH_ADD_USERS: `Pesquisar e adicionar usuários`,
  SUPER_ADMINS_DESCRIPTION: `Os superadministradores serão sempre pré-preenchidos`,
  USERS_DESCRIPTION: `As seguintes pessoas podem usar este espaço de trabalho.`,
  USERS_TITLE: `Usuários`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Ativo`,
  ADDITIONAL_OPTIONS: `Opções Adicionais`,
  ADMIN_TITLE: `Admin`,
  BRANDING_DESCRIPTION: `Por padrão, todas as páginas de reserva deste espaço de trabalho usarão esta configuração de marca. No entanto, você pode substituir as configurações para esta página de reserva específica se necessário.`,
  BRANDING_TITLE_PART_1: `Quais serão as`,
  BRANDING_TITLE_PART_2: `configurações de marca`,
  BRANDING_TITLE_PART_3: `para este espaço de trabalho?`,
  DEFAULT_WORKSPACE: `Espaço de Trabalho Padrão`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  EDIT_LABELS_DESCRIPTION: `Editar as etiquetas para todas as páginas de reserva neste espaço de trabalho. Você pode substituir essas configurações em páginas de reserva específicas conforme necessário.`,
  INACTIVE: `Inativo`,
  NAME_TITLE: `Nome do Espaço de Trabalho`,
  NEW_WORKSPACE: `Novo Espaço de Trabalho`,
  PREVIEW_BUTTON: `Visualizar Página de Reserva e Editar Etiquetas`,
  SELECTED_WORKSPACES: `espaços de trabalho selecionados`,
  TITLE: `Espaços de Trabalho`,
  TOOLTIP: `Gerencie espaços de trabalho que oferecem aos departamentos ou equipes configurações globais exclusivas.`,
  WHAT_TITLE_PART_1: `Qual é a`,
  WHAT_TITLE_PART_2: `configuração deste espaço de trabalho?`,
  WHO_TITLE_PART_1: `Quem`,
  WHO_TITLE_PART_2: `pode usar este espaço de trabalho?`,
});
