import { ConfirmationModal, Preloader, Search, SectionTitle } from '../../components/common';
import labels from './labels';
import { Button } from 'primereact/button';
import { MAX_LENGTH_NAME } from '../../types/constants';
import { Menu } from 'primereact/menu';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Team } from '../../API';
import { useSelector } from 'react-redux';
import { DEFAULT_TEAM, editTeamModalActions, teamsActions, teamsSelectors } from '../../store/teams';
import { authentificationSelectors } from '../../store/authentification';
import { EditTeamModal } from '../../components/teams';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { userSettingsSelectors } from '../../store/userSettings';
import { formatDateTimeThroughGap } from '../../services/DateService';
import { workspacesSelectors } from '../../store/workspaces';
import urlConstants from '../../shared/JSON/urlConstants.json';
import { usersSelectors } from '../../store/users';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';

export const Teams = () => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(authentificationSelectors.selectWorkspace);
  const isFetching = useSelector(teamsSelectors.selectIsFetching);
  const isTeamActive = useSelector(teamsSelectors.selectIsTeamActive);
  const teamName = useSelector(teamsSelectors.selectTeamName);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat);
  const workspacesNamesById = useSelector(workspacesSelectors.selectWorkspacesNamesById);
  const currentUser = useSelector(usersSelectors.selectCurrentUserNameOrEmail);

  const isRead = useSelector(userSettingsSelectors.selectTeamsRead);
  const isCreate = useSelector(userSettingsSelectors.selectTeamsCreate);
  const isEdit = useSelector(userSettingsSelectors.selectTeamsEdit);
  const isDelete = useSelector(userSettingsSelectors.selectTeamsDelete);
  const isReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyTeam);
  const sharedForRead = useSelector(teamsSelectors.selectSharedForReadTeam);

  const [searchString, setSearchString] = useState('');
  const teams = useSelector(teamsSelectors.selectSearchedTeams(searchString));
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const menu = useRef<Menu | null>(null);

  useEffect(() => {
    if (isRead) {
      dispatch(teamsActions.getTeamsPageRequest());
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  const menuItems = [
    {
      label: labels.edit,
      icon: 'pi pi-pencil',
      command: () => {
        dispatch(editTeamModalActions.openModal());
      },
      visible: isEdit && !sharedForRead,
    },
    {
      label: isTeamActive ? labels.deactivate : labels.activate,
      icon: isTeamActive ? 'pi pi-ban' : 'pi pi-check-circle',
      command: () => {
        if (isTeamActive) {
          setDeactivateModalOpen(true);
        } else {
          dispatch(teamsActions.deactivateTeamRequest());
        }
      },
      visible: isEdit && !sharedForRead,
    },
    {
      label: labels.delete,
      icon: 'pi pi-trash',
      command: () => {
        setDeleteModalOpen(true);
      },
      visible: isDelete && !sharedForRead,
    },
    {
      label: labels.view,
      icon: 'pi pi-eye',
      command: () => {
        dispatch(editTeamModalActions.openModal());
      },
      visible: isReadOnly || sharedForRead,
    },
  ];

  const handleDeleteCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    dispatch(teamsActions.deleteTeamRequest());
    setDeleteModalOpen(false);
  };

  const handleDeactivateCloseModal = () => {
    setDeactivateModalOpen(false);
  };

  const handleDeactivate = () => {
    dispatch(teamsActions.deactivateTeamRequest());
    setDeactivateModalOpen(false);
  };

  const createTeam = () => {
    dispatch(teamsActions.setSelectedTeam({ ...DEFAULT_TEAM, workspaceId, createdBy: currentUser }));
    dispatch(editTeamModalActions.openModal());
  };

  const renderActions = (data: Team) => {
    return (
      <>
        <Button
          icon="pi pi-ellipsis-h text-color"
          text
          onClick={(e) => {
            dispatch(teamsActions.setSelectedTeam(data));
            return menu.current?.toggle && menu.current.toggle(e);
          }}
        />
        <Menu model={menuItems} popup ref={menu} />
      </>
    );
  };

  const handleSearchChange = (value: string) => {
    setSearchString(value.toLowerCase());
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="card mb-3 flex justify-content-between sumo-header-bg">
        <SectionTitle
          id="teamsTitle"
          icon="pi-users"
          title={labels.title}
          tooltip={labels.tooltip}
          learnMoreURL={urlConstants.KB_TEAMS_URL}
        />
      </div>

      <div className="card">
        <div className="grid mb-4">
          <div className="p-inputgroup col-12 md:col-6">
            <Search
              placeholder={labels.search}
              value={searchString}
              onChange={handleSearchChange}
              maxTextLength={MAX_LENGTH_NAME}
            />
          </div>
          {isCreate && (
            <div className="col-12 md:col-6 flex align-items-start md:justify-content-end">
              <Button label={labels.createTeam} onClick={createTeam} />
            </div>
          )}
        </div>

        <DataTable
          value={teams}
          paginator={teams.length > 10}
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          size="small"
          showGridlines
          removableSort
          resizableColumns
          emptyMessage={labels.noResults}
        >
          <Column field="name" header={labels.name} sortable bodyClassName="text-overflow-ellipsis" />
          <Column
            field="members"
            header={labels.members}
            sortable
            bodyClassName="text-overflow-ellipsis"
            body={(data: Team) => data?.members?.length || ''}
          />
          <Column
            field="workspaceId"
            header={labels.workspace}
            sortable
            bodyClassName="text-overflow-ellipsis"
            body={(data: Team) => (data?.workspaceId ? workspacesNamesById[data.workspaceId] : '')}
          />
          <Column field="createdBy" header={labels.createdBy} sortable bodyClassName="text-overflow-ellipsis" />
          <Column
            field="createdAt"
            header={labels.createdDate}
            sortable
            bodyClassName="text-overflow-ellipsis"
            body={(data: Team) => formatDateTimeThroughGap(data?.createdAt, dateFormat || '', timeFormat || '')}
            frozen
          />
          <Column body={renderActions} className="w-4rem" align="center" frozen />
        </DataTable>
      </div>
      <EditTeamModal />
      <ConfirmationModal
        title={labels.deactivateTeamTitle}
        description={`${labels.deactivateTeamDesc} "${teamName}"?`}
        visible={deactivateModalOpen}
        onClose={handleDeactivateCloseModal}
        onCancel={handleDeactivateCloseModal}
        onConfirm={handleDeactivate}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTeamTitle}
        description={`${labels.deleteTeamDesc} "${teamName}"?`}
        visible={deleteModalOpen}
        onClose={handleDeleteCloseModal}
        onCancel={handleDeleteCloseModal}
        onConfirm={handleDelete}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
    </div>
  );
};
