export const Account = Object.freeze({
  MY_ROLE_TITLE: `Meine Rolle`,
  ROLE_DESCRIPTION: `Ihre aktuelle Rolle - `,
  TITLE: `Kontoeinstellungen`,
});

export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Sie können 1 Kalender verbinden`, // Um dieses Limit zu erhöhen, kontaktieren Sie den Verkauf, um Ihre Lizenzstufe zu aktualisieren.
  DESCRIPTION: `Verbinden Sie Ihren Kalender, damit SUMO weiß, wann Sie verfügbar sind, und aktualisiert Ihren Kalender, wenn Termine gebucht werden.`,
  DISCONNECT_MESSAGE: `Sind Sie sicher, dass Sie Ihren Kalender trennen möchten?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Termine werden nicht gelöscht oder abgesagt.`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 oder 2019`,
  EXCHANGE_CALENDAR_TITLE: `Exchange Kalender`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Google Kalender`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com oder Hotmail Kalender`,
  MICROSOFT_CALENDAR_TITLE: `Office 365 Kalender`,
  SELECT_CALENDAR_TITLE: `Kalender auswählen`,
  TITLE: `Kalenderverbindungen`,
  TOOLTIP: `Verbinden Sie Ihren Google- oder Microsoft-Kalender mit SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Zustimmen und Schließen`,
  DESCRIPTION: `Wenn Sie eine Website besuchen, hat sie die Möglichkeit, bestimmte Informationen in Ihrem Browser zu speichern oder abzurufen. Diese Informationen, oft in Form von Cookies, können sich auf Ihre Präferenzen, Ihr Gerät oder andere Details beziehen. Ihr Hauptzweck besteht darin, sicherzustellen, dass die Website so funktioniert, wie Sie es erwarten würden. Obwohl diese Informationen Sie in der Regel nicht direkt identifizieren, können sie Ihre Web-Browsing-Erfahrung verbessern, indem sie Inhalte personalisieren. Wir verstehen und respektieren Ihr Recht auf Privatsphäre, weshalb wir Ihnen die Möglichkeit bieten, Ihre Cookie-Einstellungen zu kontrollieren und anzupassen. Durch Klicken auf die verschiedenen Kategorieüberschriften können Sie mehr über jeden Cookie-Typ erfahren und unsere Standardeinstellungen ändern. Beachten Sie, dass das Blockieren bestimmter Arten von Cookies Ihre Gesamterfahrung auf der Website beeinträchtigen und die von uns angebotenen Dienste einschränken kann.`,
  DESCRIPTION_FIRST_PART: `Wenn Sie eine Website besuchen, hat sie die Möglichkeit, bestimmte Informationen in Ihrem Browser zu speichern oder abzurufen. Diese Informationen, oft in Form von Cookies, können sich auf Ihre Präferenzen, Ihr Gerät oder andere Details beziehen. Ihr Hauptzweck besteht darin, sicherzustellen, dass die Website so funktioniert, wie Sie es erwarten würden.`,
  DESCRIPTION_SECOND_PART: `Obwohl diese Informationen Sie in der Regel nicht direkt identifizieren, können sie Ihre Web-Browsing-Erfahrung verbessern, indem sie Inhalte personalisieren. Wir verstehen und respektieren Ihr Recht auf Privatsphäre, weshalb wir Ihnen die Möglichkeit bieten, Ihre Cookie-Einstellungen zu kontrollieren und anzupassen.`,
  DESCRIPTION_THIRD_PART: `Durch Klicken auf die verschiedenen Kategorieüberschriften können Sie mehr über jeden Cookie-Typ erfahren und unsere Standardeinstellungen ändern. Beachten Sie, dass das Blockieren bestimmter Arten von Cookies Ihre Gesamterfahrung auf der Website beeinträchtigen und die von uns angebotenen Dienste einschränken kann.`,
  FUNCTIONAL_NAME: `Funktionale Cookies`,
  MANAGE_TITLE: `Einwilligungspräferenzen verwalten`,
  NECESSARY_ONLY: `Nur unbedingt erforderlich`,
  PERFORMANCE_NAME: `Leistungscookies`,
  STRICTLY_NAME: `Unbedingt erforderliche Cookies`,
  TARGETING_NAME: `Zielgerichtete Cookies`,
  TITLE: `Cookie-Einstellungen`,
});

export const Login = Object.freeze({
  CONTACT_US: `Bitte kontaktieren Sie den Support, wenn Sie Unterstützung benötigen`,
  DESCRIPTION: `Sie melden sich bei SUMO1 mit dem folgenden verbundenen Konto an`,
  GOOGLE_ACCOUNT: `Google Konto`,
  GOOGLE_ACCOUNTS_SWITCH: `Google Konten wechseln`,
  GOOGLE_SWITCH: `Zu Google-Anmeldung wechseln`,
  GOOGLE_SWITCH_DESC: `Ändern Sie von der Microsoft-Authentifizierung zu Google`,
  MICROSOFT_ACCOUNT: `Microsoft Konto`,
  MICROSOFT_ACCOUNTS_SWITCH: `Microsoft Konten wechseln`,
  MICROSOFT_SWITCH: `Zur Microsoft-Anmeldung wechseln`,
  MICROSOFT_SWITCH_DESC: `Ändern Sie von der Google-Authentifizierung zu Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Ihr SUMO1-Konto verliert den Zugriff auf Daten, die nur über Ihren ursprünglichen Anbieter verfügbar sind. Sie müssen möglicherweise auch den Zugriff auf Integrationen und Kalender erneut gewähren.`,
  SWITCH_DESCRIPTION_PART1: `Wechseln von `,
  SWITCH_DESCRIPTION_PART2: ` Authentifizierung zu `,
  TITLE: `Anmelden`,
});

export const MyLink = Object.freeze({
  LINK: `Link Name`,
  LINK_HELPER_TEXT: `Mindestens 3 Symbole. Verwenden Sie nur a-z, 0-9 Zeichen und -.&=_'-+,<>`,
  SUBTITLE: `Wenn Sie Ihre SUMO1-URL ändern, funktionieren alle kopierten Links nicht mehr und werden aktualisiert`,
  TITLE: `Mein Link`,
});

export const Phone = Object.freeze({
  LABEL: `Telefonnummer`,
  TITLE: `Telefonnummer(n)`,
});

export const Profile = Object.freeze({
  DATE_FORMAT: `Datumsformat`,
  DELETE_ACCOUNT: `Konto löschen`,
  DELETE_ACCOUNT_DIALOG_TEXT: `Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Ihre Anfrage wird an den Administrator gesendet.`,
  DELETE_ACCOUNT_REQUEST_TEXT: `Löschungsanfrage wurde an den Administrator gesendet.`,
  DELETE_ACCOUNT_REQUEST_TITLE: `Gesendet`,
  LANGUAGE: `Sprache`,
  NAME: `Name`,
  WELCOME_MESSAGE: `Willkommensnachricht`,
  PHONE_DETAILS_LABELS: `Telefonangaben`,
  PHONE_DETAILS_PLACEHOLDER: `Fügen Sie hier zusätzliche Informationen oder Anweisungen hinzu.\r\nBeispiel: Ich freue mich auf das Treffen. SMS mir jederzeit.`,
  TIME_FORMAT: `Zeitformat`,
  TIME_ZONE: `Zeitzone`,
  TITLE: `Profil`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: `Neue Kalenderanfrage fehlgeschlagen`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: `Neuer Kalender erfolgreich verbunden!`,
  CONNECT_ZOOM_ERROR_TOAST: `Zoom-Verbindungsanfrage fehlgeschlagen`,
  CONNECT_ZOOM_SUCCESS_TOAST: `Zoom erfolgreich verbunden!`,
  GET_USER_SETTINGS_FAIL: `Benutzerdaten nicht gefunden, bitte kontaktieren Sie den Support`,
  SAVE_USER_SETTINGS_ERROR_TOAST: `Anforderung zum Speichern der Einstellungen fehlgeschlagen`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST: `Ihre Einstellungen wurden erfolgreich gespeichert!`,
  UPDATE_USER_LINK_ERROR_MESSAGE: `Der Link ist bereits vergeben`,
  UPDATE_USER_LINK_SUCCESS_MESSAGE: `Ihr Link wurde erfolgreich aktualisiert!`,
});
