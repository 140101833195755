import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { CreateUserEventInput, UpdateUserEventInput } from '../../API';
import { scheduledMeetingsActions, ScheduledMeetingsActionTypes } from './actions';
import { fetchEvents, updateEvent } from './service';
import { notificationsActions } from '../notifications';
import {
  CANCEL_EVENT_ERROR_TOAST,
  CANCEL_EVENT_SUCCESS_TOAST,
  GET_SCHEDULED_MEETINGS_ERROR_TOAST,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST,
} from './constants';
import { editMeetingNotesModalActions } from './modal';
import { UpdateScheduledMeeting } from './typings';
import { EventAction } from '../publicBookingPage';
import { scheduledMeetingsSelectors } from './selectors';
import { cancelEvent, sendNotifications } from '../publicBookingPage/service';
import { handleServiceError } from '../utils/reduxUtils';

function* getScheduledMeetingsSaga(): any {
  try {
    const response: CreateUserEventInput[] = yield call(fetchEvents);

    yield put(scheduledMeetingsActions.getScheduledMeetingsSuccess(response));
  } catch (error: unknown) {
    yield put(scheduledMeetingsActions.getScheduledMeetingsFail(error?.toString()));
    yield call(handleServiceError, error, GET_SCHEDULED_MEETINGS_ERROR_TOAST, true);
  }
}

function* updateScheduledMeetingSaga({ payload }: any): any {
  try {
    const event: UpdateScheduledMeeting = { ...payload };
    const updatedEvent: UpdateUserEventInput = {
      userId: event.userId,
      tenant: event.tenant,
      eventDate: event.eventDate,
      eventId: event.eventId,
      internalNotes: event.internalNotes,
      isNoShow: event.isNoShow,
    };
    yield call(updateEvent, updatedEvent);

    yield put(scheduledMeetingsActions.updateScheduledMeetingSuccess(event));
    if (payload.editMeetingNotes) {
      yield put(editMeetingNotesModalActions.closeModal());
    }
    yield put(scheduledMeetingsActions.getScheduledMeetingsRequest());
  } catch (error: unknown) {
    yield put(scheduledMeetingsActions.updateScheduledMeetingFail(error?.toString()));
    yield call(handleServiceError, error, UPDATE_SCHEDULED_MEETING_ERROR_TOAST);
  }
}

function* cancelScheduledMeetingSaga(): any {
  try {
    const eventId = yield select(scheduledMeetingsSelectors.selectEventId);
    const canceled = yield select(scheduledMeetingsSelectors.selectEventCanceled);

    yield call(cancelEvent, { eventId, canceled });
    yield fork(sendNotifications, eventId, EventAction.CANCEL);

    yield put(scheduledMeetingsActions.cancelScheduledMeetingSuccess());
    yield put<any>(notificationsActions.showToast(CANCEL_EVENT_SUCCESS_TOAST));
    yield put(scheduledMeetingsActions.getScheduledMeetingsRequest());
  } catch (error: unknown) {
    yield put(scheduledMeetingsActions.cancelScheduledMeetingFail(error?.toString() || ''));
    yield call(handleServiceError, error, CANCEL_EVENT_ERROR_TOAST);
  }
}

export function* watchScheduledMeetingsSaga() {
  yield takeLatest(ScheduledMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST, getScheduledMeetingsSaga);
  yield takeLatest(ScheduledMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST, updateScheduledMeetingSaga);
  yield takeLatest(ScheduledMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST, cancelScheduledMeetingSaga);
}
