import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authentificationActions, authentificationSelectors } from '../store/authentification';
import { Path } from './pathes';
import Layout from '../components/public/layout/layout';
import { ModalLeaveAccount, ModalUserAccounts } from '../components/public';
import { GlobalError } from '../components/common';
import { errorSelectors } from '../store/error/selectors';
import { useDispatch } from 'react-redux';
import { globalSelectors } from '../store/global';
import { errorActions } from '../store/error/actions';
import { ErrorCodes } from '../store/error/types';
import { navigationService } from '../services/NavigationService';
import { userSettingsSelectors } from '../store/userSettings';
import { useUserNavigation } from '../hooks/useUserNavigation';

export const PublicRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { setIsAuthenticated } = useUserNavigation();
  const userId = useSelector(authentificationSelectors.selectUserId);
  const isUserAuthenticated = useSelector(authentificationSelectors.selectIsUserAuthenticated);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const searchParams = new URLSearchParams(location.search); // params in URL after "?"
  const isGlobalError = useSelector(globalSelectors.selectIsError);
  const hasError = useSelector(errorSelectors.selectHasError);
  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);
  const isSumoONEAdmin = useSelector(authentificationSelectors.selectIsSumo1Admin);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);

  useEffect(() => {
    if (isGlobalError) {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_500));
    }
  }, [isGlobalError]);

  useEffect(() => {
    if (isUserAuthenticated || userId) {
      setIsAuthenticated(true); // set isAuthenticated to true in useUserNavigation
      if (isSumoONEAdmin) {
        navigate(Path.OPSConsoleOrgs); // TODO navigate to Path.OPSConsole when Dashboard is done
      } else if (!isLicenseActive) {
        // The authentication request indicates that the license has expired.
        if (isSuperAdmin) {
          // if expired and Super Admin - redirect to Billing Page
          navigationService.navigateTo(Path.Billing);
        } else {
          // logout, and to Trial Expired
          dispatch(authentificationActions.logoutUserRequest(Path.LicenseExpired));
        }
      } else if (isQuickSetupFinished === false) {
        navigate(`${Path.QuickSetup}?${searchParams.toString()}`);
      } else {
        navigate(Path.BookingPages);
      }
    }
  }, [userId, isUserAuthenticated, isLicenseActive, isSumoONEAdmin, isSuperAdmin]);

  if (hasError) return <GlobalError />;

  return (
    <Layout>
      <Outlet />

      <ModalUserAccounts />
      <ModalLeaveAccount />
    </Layout>
  );
};
