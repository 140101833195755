import { useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { Button } from 'primereact/button';
import labels from './labels';
import { Path } from '../../../routing';
import { useDispatch } from 'react-redux';
import { navigationService } from '../../../services/NavigationService';
import dayjs from 'dayjs';
import { IntegrationFilter, integrationActions } from '../../../store/integration';

export const CalendarWarning = () => {
  const dispatch = useDispatch();
  const isCalendarWarning = useSelector(userSettingsSelectors.selectIsCalendarWarning);

  const handleClose = () => {
    dispatch(userSettingsActions.updateUserSettings({ calendarWarningDate: dayjs().toISOString() }));
    dispatch(userSettingsActions.saveUserSettingsNoToastRequest());
  };

  const handleClickInvite = () => {
    dispatch(integrationActions.setIntegrationFilter(IntegrationFilter.CALENDARS));
    navigationService.navigateTo(Path.Integration);
  };
  return (
    <>
      {isCalendarWarning && (
        <div className="card border-1 border-red-500">
          <div className="flex align-items-center justify-content-between flex-row mb-3 -mr-2 -mt-2">
            <div>{labels.description}</div>
            <Button icon="pi pi-times" text rounded className="text-color" onClick={handleClose} />
          </div>
          <Button label={labels.button} className="px-3" onClick={handleClickInvite} />
        </div>
      )}
    </>
  );
};
