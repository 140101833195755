import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const EDIT_MEETING_NOTES_MODAL_NAME: ModalName = 'editMeetingNotesModal';
export const editMeetingNotesModalActions = modalsActionsFactory(EDIT_MEETING_NOTES_MODAL_NAME);
export const editMeetingNotesModalSelectors = modalsSelectorsFactory(EDIT_MEETING_NOTES_MODAL_NAME);

export const VIEW_ATTENDEES_MODAL_NAME: ModalName = 'viewAttendeesModal';
export const viewAttendeesModalActions = modalsActionsFactory(VIEW_ATTENDEES_MODAL_NAME);
export const viewAttendeesModalSelectors = modalsSelectorsFactory(VIEW_ATTENDEES_MODAL_NAME);

export const CANCEL_EVENT_MODAL_NAME: ModalName = 'cancelEventModal';
export const cancelEventModalActions = modalsActionsFactory(CANCEL_EVENT_MODAL_NAME);
export const cancelEventModalSelectors = modalsSelectorsFactory(CANCEL_EVENT_MODAL_NAME);

export const RESCHEDULE_EVENT_MODAL_NAME: ModalName = 'rescheduleEventModal';
export const rescheduleEventModalActions = modalsActionsFactory(RESCHEDULE_EVENT_MODAL_NAME);
export const rescheduleEventModalSelectors = modalsSelectorsFactory(RESCHEDULE_EVENT_MODAL_NAME);

export const NO_SHOW_EVENT_MODAL_NAME: ModalName = 'noShowEventModal';
export const noShowEventModalActions = modalsActionsFactory(NO_SHOW_EVENT_MODAL_NAME);
export const noShowEventModalSelectors = modalsSelectorsFactory(NO_SHOW_EVENT_MODAL_NAME);
