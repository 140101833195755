import { t } from '../../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  confirmDelete: t('Common:CONFIRM_DELETE'),
  country: t('Common:COUNTRY'),
  dateFormat: t('Profile:DATE_FORMAT'),
  deleteAccount: t('Profile:DELETE_ACCOUNT'),
  deleteAccountDialogCancel: t('Common:CANCEL'),
  deleteAccountDialogDelete: t('Common:DELETE'),
  deleteAccountDialogText: t('Profile:DELETE_ACCOUNT_DIALOG_TEXT'),
  deleteReqText: t('Profile:DELETE_ACCOUNT_REQUEST_TEXT'),
  deleteReqTitle: t('Profile:DELETE_ACCOUNT_REQUEST_TITLE'),
  language: t('Profile:LANGUAGE'),
  name: t('Profile:NAME'),
  phoneDetailsLabel: t('Profile:PHONE_DETAILS_LABELS'),
  phoneDetailsPlaceholder: t('Profile:PHONE_DETAILS_PLACEHOLDER'),
  phoneNumber: t('Phone:LABEL'),
  pictureFormats: t('Common:PICTURE_FORMATS'),
  saveChanges: t('Common:SAVE_CHANGES'),
  timeFormat: t('Profile:TIME_FORMAT'),
  timeZone: t('Profile:TIME_ZONE'),
  title: t('Profile:TITLE'),
  uploadPicture: t('Common:UPLOAD_PICTURE'),
};
