import { useSelector } from 'react-redux';
import { BookingPageBrandingSettings, BookingPagePreviewModal } from '../../bookingPages';
import { workspacesSelectors } from '../../../store/workspaces';
import { DEFAULT_BOOKING_PAGE_DATA, previewModalActions } from '../../../store/bookingPages';
import { useDispatch } from 'react-redux';
import { workspacesActions } from '../../../store/workspaces/actions';
import { Button } from 'primereact/button';
import labels from './labels';
import { BookingPageLabelsInput } from '../../../API';
import { ImageType } from '../../../types/types';
import { useEffect, useState } from 'react';
import { userSettingsSelectors } from '../../../store/userSettings';

export const WorkspaceBrandingStep = () => {
  const dispatch = useDispatch();
  const workspaceStyle = useSelector(workspacesSelectors.selectWorkspaceStyle);
  const workspaceLabels = useSelector(workspacesSelectors.selectLabels);
  const avatar = useSelector(workspacesSelectors.selectFinalLogoImage);
  const backgroundImage = useSelector(workspacesSelectors.selectFinalBackgroundImage);
  const isFetchingAvatar = useSelector(workspacesSelectors.selectIsFetchingAvatar);
  const isFetchingBackground = useSelector(workspacesSelectors.selectIsFetchingBackground);
  const styleForPreview = useSelector(workspacesSelectors.selectStyleForPreview);
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);

  const [localStyle, setLocalStyle] = useState(workspaceStyle || {});

  useEffect(() => {
    setLocalStyle((prev) => ({ ...prev, backgroundImage }));
  }, [backgroundImage]);

  useEffect(() => {
    setLocalStyle((prev) => ({ ...prev, avatar }));
  }, [avatar]);

  const uploadLogo = (file: File) => {
    dispatch(workspacesActions.uploadAvatarFileRequest({ imageType: ImageType.avatar, file }));
  };
  const resetLogo = () => {
    dispatch(workspacesActions.resetImage(ImageType.avatar));
  };
  const clearLogo = () => {
    dispatch(workspacesActions.clearImage(ImageType.avatar));
  };

  const uploadBackground = (file: File) => {
    dispatch(workspacesActions.uploadBackgroundFileRequest({ imageType: ImageType.backgroundImage, file }));
  };
  const resetBackground = () => {
    dispatch(workspacesActions.resetImage(ImageType.backgroundImage));
  };
  const clearBackground = () => {
    dispatch(workspacesActions.clearImage(ImageType.backgroundImage));
  };

  const handleColorsAndFontsChange = (name: string, value: string | number) => {
    dispatch(
      workspacesActions.updateWorkspaceStyle({
        lookAndFeel: { ...workspaceStyle?.lookAndFeel, [name]: value },
      })
    );
  };

  const handleStyleChange = (name: string, value: string | number) => {
    dispatch(workspacesActions.updateWorkspaceStyle({ [name]: value }));
  };

  const handlePreviewOpen = () => {
    dispatch(previewModalActions.openModal());
  };

  const handleSaveLabels = (labels: BookingPageLabelsInput) => {
    dispatch(workspacesActions.updateWorkspace({ labels }));
  };

  return (
    <div>
      <BookingPageBrandingSettings
        description={labels.description}
        style={localStyle}
        uploadLogo={uploadLogo}
        resetLogo={resetLogo}
        clearLogo={clearLogo}
        uploadBackground={uploadBackground}
        resetBackground={resetBackground}
        clearBackground={clearBackground}
        handleColorsAndFontsChange={handleColorsAndFontsChange}
        handleStyleChange={handleStyleChange}
        isFetchingAvatar={isFetchingAvatar}
        isFetchingBackground={isFetchingBackground}
        isReadOnly={isWorkspacesReadOnly}
      />

      <div className="flex align-items-center flex-wrap md:flex-nowrap">
        <Button
          label={labels.previewButton}
          outlined
          onClick={handlePreviewOpen}
          className="min-w-max mt-4 mb-1 md:mb-4 mr-4"
        />
        <div className="mb-3 md:mb-0">{labels.editLabelsDesc}</div>
      </div>
      <BookingPagePreviewModal
        bookingPage={{
          ...DEFAULT_BOOKING_PAGE_DATA,
          style: styleForPreview,
          labels: workspaceLabels,
        }}
        onSave={handleSaveLabels}
        isReadOnly={isWorkspacesReadOnly}
      />
    </div>
  );
};
