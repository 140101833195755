import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import { authentificationActions, authentificationSelectors } from '../store/authentification';
import { Path } from './pathes';
import Layout from '../components/private/auth/layout';
import { GlobalError, Preloader } from '../components/common';
import { globalActions } from '../store/global';
import { UserSettingsKeys, userSettingsSelectors } from '../store/userSettings';
import { navigationService } from '../services/NavigationService';
import { DEFAULT_GLOBAL_SEARCH, globalSelectors } from '../store/global';
import { errorSelectors } from '../store/error/selectors';
import { useUserNavigation } from '../hooks/useUserNavigation';

export const PrivateRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated } = useUserNavigation();
  const isUserAuthenticated = useSelector(authentificationSelectors.selectIsUserAuthenticated);

  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isGlobalFetching = useSelector(globalSelectors.selectIsFetching);

  const hasError = useSelector(errorSelectors.selectHasError); // TODO: looks like this is global error as well but catch somewhere else
  const isMainDataError = useSelector(globalSelectors.selectIsError); // TODO: reneame or change the logic
  const searchPaths = useSelector(globalSelectors.selectSearchPaths);

  const isLicenseActive = useSelector(authentificationSelectors.selectIsLicenseActive);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isViewAsUserMode = useSelector(authentificationSelectors.selectIsViewAsUser);
  const staffUserId = useSelector(authentificationSelectors.selectSumo1AdminUserId);
  const searchParams = new URLSearchParams(location.search); // params in URL after "?"
  const userWasDeleted = useSelector(authentificationSelectors.selectUserWasDeleted);
  const startOnes = useRef(false);

  useEffect(() => {
    if (isAuthenticated && !isUserAuthenticated && !isMainDataError && !startOnes.current) {
      startOnes.current = true;
      dispatch(globalActions.getMainDataRequest());
    }
  }, [isAuthenticated, isUserAuthenticated, isMainDataError, startOnes]);

  useEffect(() => {
    if (isMainDataError) {
      // dispatch(errorActions.setTheError(ErrorCodes.CODE_500));
      dispatch(authentificationActions.logoutUserRequest(Path.Landing));
    }
  }, [isUserAuthenticated, isMainDataError]);

  useEffect(() => {
    // navigate to Quick Setup if it's not finished
    if (isUserAuthenticated) {
      if (isViewAsUserMode) {
        // check if the View As User session closed
        setInterval(() => {
          const userId = localStorage.getItem(UserSettingsKeys.USER_ID);
          if (userId && userId === staffUserId) {
            navigationService.navigateTo(Path.OPSConsoleOrgs);
            window.location.reload();
          }
        }, 2000);
      } else {
        if (!isLicenseActive) {
          if (isSuperAdmin) {
            // if expired and Super Admin - redirect to Billing Page
            navigationService.navigateTo(Path.Billing);
          } else {
            // logout, and to Trial Expired
            dispatch(authentificationActions.logoutUserRequest(Path.LicenseExpired));
          }
        }
        if (isLicenseActive && !isQuickSetupFinished) {
          navigationService.navigateTo(`${Path.QuickSetup}?${searchParams.toString()}`);
        }
      }
    }
  }, [isUserAuthenticated, isLicenseActive]);

  useEffect(() => {
    if (userWasDeleted) {
      dispatch(authentificationActions.logoutUserRequest(Path.Landing));
    }
  }, [userWasDeleted]);

  useEffect(() => {
    if (searchPaths && !searchPaths.some((name) => location.pathname.startsWith(name))) {
      dispatch(globalActions.setSearch(DEFAULT_GLOBAL_SEARCH));
    }
  }, [location]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authentificationActions.logoutUserRequest(Path.Landing));
    }
  }, [isAuthenticated]);

  if (hasError) return <GlobalError />;

  if (!isUserAuthenticated || isGlobalFetching) return <Preloader />;

  // if (!isUserAuthenticated && isLicenseActive) return <Navigate to={Path.Landing} replace={true} />

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
