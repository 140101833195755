import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { updateAvailability } from '../availability';
import { UserSettingsKeys, userSettingsActions, userSettingsSelectors } from '../userSettings';
import { QuickSetupActionTypes, quickSetupActions } from './actions';
import { authentificationSelectors } from '../authentification';
import {
  BookingPageStyleInput,
  CreateUserDataInput,
  LocationType,
  UpdateBookingPageInput,
  UpdateBookingTemplateInput,
  UserRecordType,
  UserSettingsInput,
} from '../../API';
import { quickSetupSelectors } from './selectors';
import { notificationsActions } from '../notifications';
import { SAVE_QUICK_SETUP_ERROR_TOAST, SAVE_QUICK_SETUP_SUCCESS_TOAST } from './constants';
import { saveUserSettings, uploadPublicFile } from '../userSettings/service';
import { updateWorkspaceList } from '../workspaces/service';
import { DEFAULT_WORKSPACE, DEFAULT_WORKSPACE_NAME, UpdateWorkspaceList, WorkspaceActions } from '../workspaces';
import { createSelector } from 'reselect';
import { DEFAULT_BOOKING_PAGE_DATA, bookingPageSelectors } from '../bookingPages';
import { postBookingPage } from '../bookingPages/service';
import { workspacesActions } from '../workspaces/actions';
import { DEFAULT_BOOKING_TEMPLATE_DATA, bookingTemplatesSelectors } from '../bookingTemplates';
import { postBookingTemplate } from '../bookingTemplates/service';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { appendPostfixToFileName } from '../../services/utils';
import { UploadFileData } from './types';
import { UserDataInputCreatedAt } from '../global/types';
import { handleServiceError } from '../utils/reduxUtils';

const selectUpdateWorkspaceListRequest = (avatar: string, backgroundImage: string) =>
  createSelector(
    authentificationSelectors.selectTenantId,
    quickSetupSelectors.selectDefaultWorkspace,
    (tenantId, defaultWorkspace) => ({
      type: WorkspaceActions.updateWorkspace,
      tenant: {
        tenantId,
        workspace: {
          ...defaultWorkspace,
          // update default record with translated values
          name: DEFAULT_WORKSPACE_NAME,
          labels: DEFAULT_WORKSPACE.labels,
          style: {
            ...defaultWorkspace.style,
            avatar,
            backgroundImage,
          },
        },
      },
    })
  );

const selectCreateUserDataInputRequest = createSelector(
  authentificationSelectors.selectTenantId,
  userSettingsSelectors.selectUserSettings,
  authentificationSelectors.selectUserId,
  authentificationSelectors.selectLink,
  userSettingsSelectors.selectStatistics,
  (tenantId, userSettings, userId, link, statistics) => ({
    userId,
    tenant: tenantId,
    link,
    recordType: UserRecordType.PROFILE,
    statistics,
    userSettings,
  })
);

const selectUserUpdateBookingPageRequest = createSelector(
  bookingPageSelectors.selectMyDefaultBookingPage,
  quickSetupSelectors.selectDefaultWorkspace,
  quickSetupSelectors.selectPhoneCalls,
  authentificationSelectors.selectIsThirdPartyGoogle,
  authentificationSelectors.selectIsThirdPartyMicrosoft,
  userSettingsSelectors.selectIsZoomConnected,
  userSettingsSelectors.selectDefaultVideoIntegration,
  (myDefaultBookingPage, defaultWorkspace, phoneDetails, hasGoogle, hasMicrosoft, hasZoom, defaultVideoIntegration) =>
    myDefaultBookingPage && defaultWorkspace
      ? ({
          ...myDefaultBookingPage,
          // update default record with translated values
          what: DEFAULT_BOOKING_PAGE_DATA.what,
          notifications: DEFAULT_BOOKING_PAGE_DATA.notifications,
          inputFields: DEFAULT_BOOKING_PAGE_DATA.inputFields,
          labels: DEFAULT_BOOKING_PAGE_DATA.labels,
          style: defaultWorkspace.style,
          where: {
            ...myDefaultBookingPage.where,
            locationTypes: [
              ...(phoneDetails?.phoneNumber ? [LocationType.PHONE_CALL] : []),
              ...(hasGoogle || hasMicrosoft || hasZoom ? [LocationType.VIDEO_CONFERENCE] : []),
            ],
            defaultLocationType:
              hasGoogle || hasMicrosoft || hasZoom ? LocationType.VIDEO_CONFERENCE : LocationType.PHONE_CALL,
            videoConferenceType: defaultVideoIntegration,
          },
        } as UpdateBookingPageInput)
      : null
);

const selectAdminUpdateBookingPageRequest = (avatar: string, backgroundImage: string) =>
  createSelector(
    bookingPageSelectors.selectBookingPages,
    quickSetupSelectors.selectDefaultWorkspace,
    quickSetupSelectors.selectPhoneCalls,
    authentificationSelectors.selectIsThirdPartyGoogle,
    authentificationSelectors.selectIsThirdPartyMicrosoft,
    userSettingsSelectors.selectIsZoomConnected,
    userSettingsSelectors.selectDefaultVideoIntegration,
    (bookingPages, defaultWorkspace, phoneDetails, hasGoogle, hasMicrosoft, hasZoom, defaultVideoIntegration) =>
      bookingPages.length && defaultWorkspace
        ? ({
            ...bookingPages[0],
            // update default record with translated values
            what: DEFAULT_BOOKING_PAGE_DATA.what,
            notifications: DEFAULT_BOOKING_PAGE_DATA.notifications,
            inputFields: DEFAULT_BOOKING_PAGE_DATA.inputFields,
            labels: DEFAULT_BOOKING_PAGE_DATA.labels,
            style: {
              ...defaultWorkspace.style,
              avatar,
              backgroundImage,
            },
            where: {
              ...bookingPages[0].where,
              locationTypes: [
                ...(phoneDetails?.phoneNumber ? [LocationType.PHONE_CALL] : []),
                ...(hasGoogle || hasMicrosoft || hasZoom ? [LocationType.VIDEO_CONFERENCE] : []),
              ],
              defaultLocationType:
                hasGoogle || hasMicrosoft || hasZoom ? LocationType.VIDEO_CONFERENCE : LocationType.PHONE_CALL,
              videoConferenceType: defaultVideoIntegration,
            },
          } as UpdateBookingPageInput)
        : null
  );

const selectUpdateBookingTemplateRequest = (avatar: string, backgroundImage: string) =>
  createSelector(
    bookingTemplatesSelectors.selectBookingTemplates,
    quickSetupSelectors.selectDefaultWorkspace,
    quickSetupSelectors.selectPhoneCalls,
    authentificationSelectors.selectIsThirdPartyGoogle,
    authentificationSelectors.selectIsThirdPartyMicrosoft,
    userSettingsSelectors.selectIsZoomConnected,
    (bookingTemplates, defaultWorkspace, phoneDetails, hasGoogle, hasMicrosoft, hasZoom) =>
      bookingTemplates.length && bookingTemplates[0]
        ? ({
            ...bookingTemplates[0],
            // update default record with translated values
            what: DEFAULT_BOOKING_TEMPLATE_DATA.what,
            notifications: DEFAULT_BOOKING_TEMPLATE_DATA.notifications,
            inputFields: DEFAULT_BOOKING_TEMPLATE_DATA.inputFields,
            labels: DEFAULT_BOOKING_PAGE_DATA.labels,
            style: {
              ...defaultWorkspace.style,
              avatar,
              backgroundImage,
            },
            where: {
              ...bookingTemplates[0].where,
              locationTypes: [
                ...(phoneDetails?.phoneNumber ? [LocationType.PHONE_CALL] : []),
                ...(hasGoogle || hasMicrosoft || hasZoom ? [LocationType.VIDEO_CONFERENCE] : []),
              ],
              defaultLocationType:
                hasGoogle || hasMicrosoft || hasZoom ? LocationType.VIDEO_CONFERENCE : LocationType.PHONE_CALL,
            },
          } as UpdateBookingTemplateInput)
        : null
  );

const selectUpdateUserSettingsRequest = createSelector(quickSetupSelectors.selectPhoneCalls, (phoneDetails) => ({
  phoneNumber: phoneDetails.phoneNumber,
  phoneDetails: phoneDetails.phoneDetails,
  countryCode: phoneDetails.countryCode,
  isQuickSetupFinished: true,
}));

function* uploadImageFiles(prevLogo?: string | null, prevBackground?: string | null, postfix?: string) {
  let logo = '',
    background = '';
  const path: string = yield select(authentificationSelectors.selectTenantFileFolderPath);
  const logoData: UploadFileData | undefined = yield select(quickSetupSelectors.selectLogoData);
  const backgroundData: UploadFileData | undefined = yield select(quickSetupSelectors.selectBackgroundData);

  if (prevLogo) {
    if (prevLogo !== logoData?.url) {
      // remove previous logo if changed
      // yield call(removeFile, prevLogo);
    } else {
      // otherwise set the link to file
      logo = prevLogo;
    }
  }
  // upload a new file if selected
  if (logoData?.fileName) {
    const logoFile: Blob = yield fetch(logoData.url).then((res) => res.blob());
    logo = yield call(
      uploadPublicFile,
      new File([logoFile], postfix ? appendPostfixToFileName(logoData.fileName, postfix) : logoData.fileName),
      path
    );
  }

  if (prevBackground) {
    if (prevBackground !== backgroundData?.url) {
      // remove previous background if changed
      // yield call(removeFile, prevBackground);
    } else {
      // otherwise set the link to file
      background = prevBackground;
    }
  }
  // upload a new file if selected
  if (backgroundData?.fileName) {
    const backgroundFile: Blob = yield fetch(backgroundData.url).then((res) => res.blob());
    background = yield call(
      uploadPublicFile,
      new File(
        [backgroundFile],
        postfix ? appendPostfixToFileName(backgroundData.fileName, postfix) : backgroundData.fileName
      ),
      path
    );
  }
  return { logo, background };
}

function* updateWorkspaceIcons() {
  const workspaceLogo: string | null | undefined = yield select(quickSetupSelectors.selectWorkspaceLogo);
  const workspaceBackground: string | null | undefined = yield select(quickSetupSelectors.selectWorkspaceBackground);
  const { logo, background } = yield call(uploadImageFiles, workspaceLogo, workspaceBackground);
  return { logo, background };
}

function* updateBookingPageIcons() {
  const bookingPages: UpdateBookingPageInput[] = yield select(bookingPageSelectors.selectBookingPages);
  const bookingPageStyle: BookingPageStyleInput | null | undefined = bookingPages[0]?.style;
  const { logo, background } = yield call(
    uploadImageFiles,
    bookingPageStyle?.avatar,
    bookingPageStyle?.backgroundImage,
    '_copy'
  );
  return { logoBookingPage: logo, backgroundBookingPage: background };
}

function* saveQuickSetupSaga() {
  try {
    const availability: UserDataInputCreatedAt = yield select(quickSetupSelectors.selectUserAvailability);
    const availabilityForUpdate: CreateUserDataInput = {
      userId: availability.userId,
      tenant: availability.tenant,
      link: availability.link,
      recordType: availability.recordType,
      availabilityData: availability.availabilityData,
    };
    const isFirstAdmin: boolean = yield select(userSettingsSelectors.selectIsFirstTenantUser);
    const userDataProfile: CreateUserDataInput = yield select(selectCreateUserDataInputRequest);
    const profileUpdates: Partial<UserSettingsInput> = yield select(selectUpdateUserSettingsRequest);
    const userDataProfileForUpdate = {
      ...userDataProfile,
      userSettings: {
        ...userDataProfile.userSettings,
        ...profileUpdates,
      },
    };

    const updateSettingsTasks = [
      call(updateAvailability, availabilityForUpdate),
      call(saveUserSettings, userDataProfileForUpdate),
      put(userSettingsActions.updateUserSettings(profileUpdates)),
    ];

    if (isFirstAdmin) {
      const { logo, background } = yield call(updateWorkspaceIcons);
      const { logoBookingPage, backgroundBookingPage } = yield call(updateBookingPageIcons);
      const updateWorkspace: UpdateWorkspaceList = yield select(selectUpdateWorkspaceListRequest(logo, background));
      const bookingPageUpdate: UpdateBookingPageInput | null = yield select(
        selectAdminUpdateBookingPageRequest(logoBookingPage, backgroundBookingPage)
      );
      const bookingTemplateUpdate: UpdateBookingTemplateInput | null = yield select(
        selectUpdateBookingTemplateRequest(logoBookingPage, backgroundBookingPage)
      );

      const adminTasks = [
        call(updateWorkspaceList, updateWorkspace),
        ...(bookingPageUpdate ? [call(postBookingPage, bookingPageUpdate)] : []),
        ...(bookingTemplateUpdate ? [call(postBookingTemplate, bookingTemplateUpdate)] : []),
      ];

      yield all([...updateSettingsTasks, ...adminTasks]);
      yield put(workspacesActions.getWorkspacesRequest());
    } else {
      const bookingPageUpdate: UpdateBookingPageInput | null = yield select(selectUserUpdateBookingPageRequest);
      if (bookingPageUpdate) {
        updateSettingsTasks.push(call(postBookingPage, bookingPageUpdate));
      }
      yield all(updateSettingsTasks);
    }

    localStorage.removeItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS);

    yield call(navigationService.navigateTo, Path.BookingPages);

    yield put(quickSetupActions.saveQuickSetupSuccess());
    yield put<any>(notificationsActions.showToast(SAVE_QUICK_SETUP_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(quickSetupActions.saveQuickSetupFail(error));
    yield call(handleServiceError, error, SAVE_QUICK_SETUP_ERROR_TOAST);
  }
}

export function* watchQuickSetupSaga() {
  yield takeLatest(QuickSetupActionTypes.SAVE_QUICK_SETUP_REQUEST, saveQuickSetupSaga);
}
