export const DefaultBookingPage = Object.freeze({
  NAME: `Reunião de 30 Minutos`,
  BOOK_A_MEETING: `Agendar uma Reunião`,
  MEETING_DETAILS: `Detalhes da Reunião`,
  SELECT_DATE: `Selecionar Data`,
  SELECT_TIME: `Selecionar Hora`,
  YOUR_INFORMATION: `Suas Informações`,
  MORE_OPTIONS: `Mais Opções`,
  CANCEL: `Cancelar`,
  BOOK_MEETING_BUTTON: `Agendar Reunião`,
  BOOK_ANOTHER_MEETING_BUTTON: `Agendar Outra Reunião`,
  CANCEL_MEETING_BUTTON: `Cancelar Reunião`,
  SAVE_MEETING_BUTTON: `Remarcar`,
  MEETING_BOOKED_TITLE: `Reunião Agendada!`,
  MEETING_BOOKED_DESCRIPTION: `Uma confirmação por email foi enviada para sua caixa de entrada.`,
  MEETING_RESCHEDULED_TITLE: `Reunião Remarcada!`,
  MEETING_RESCHEDULED_DESCRIPTION: `Uma confirmação por email foi enviada para sua caixa de entrada.`,
  MEETING_CANCELED_TITLE: `Reunião Cancelada!`,
  MEETING_CANCELED_DESCRIPTION: `Uma confirmação por email foi enviada para sua caixa de entrada.`,
});

export const BookingPagesEditBookingPageBufferTimeSettings = Object.freeze({
  AFTER_CHECKBOX_LABEL: `Depois do evento`,
  BEFORE_CHECKBOX_LABEL: `Antes do evento`,
  DESCRIPTION: `Configurar tempos de intervalo antes e depois`,
  EVENT_TIME: `Hora do evento`,
  TITLE: `Bloquear automaticamente o horário do calendário antes ou depois deste modelo de reserva?`,
});

export const BookingPagesEditBookingPageDateRangeSettings = Object.freeze({
  INVITEES_CAN_SCHEDULE: `Os convidados podem agendar...`,
  INVITEES_CAN_NOT_SCHEDULE: `Os convidados não podem agendar menos de...`,
  RADIO_GROUP_DAYS_IN_FUTURE_OPTION: `Dias no futuro`,
  RADIO_GROUP_SPECIFIC_DATES_OPTION: `Dentro de um intervalo de datas`,
  RADIO_GROUP_UNLIMITED_OPTION: `Indefinidamente no futuro`,
});

export const BookingPagesEditBookingPageDurationSettings = Object.freeze({
  DURATION: `Duração`,
  DURATION_TOOLTIP: `Defina quanto tempo durará sua reunião. Não ultrapassar 12 horas.`,
});

export const BookingPagesEditBookingPageWhatStep = Object.freeze({
  EVENT_COLOR_INPUT_LABEL: `Cor`,
  EVENT_DESCRIPTION_INPUT_LABEL: `Descrição/Instruções`,
  EVENT_DESCRIPTION_INPUT_PLACEHOLDER: `Escreva um resumo e quaisquer detalhes que o seu convidado deva saber sobre o evento.`,
  EVENT_NAME_INPUT_LABEL: `Nome`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Falha na solicitação de clonagem da página de reserva`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Solicitação de clonagem da página de reserva bem-sucedida`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Falha na solicitação de exclusão de páginas de reserva`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Solicitação de exclusão de páginas de reserva bem-sucedida`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Não foi possível recuperar a página de reserva, ela pode ter sido excluída`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Falha na solicitação de páginas de reserva`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Falha na solicitação de salvamento da página de reserva`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Solicitação de salvamento da página de reserva bem-sucedida`,
});

export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Nova Página de Reserva`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Tem certeza de que deseja excluir esta página de reservas?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Tem certeza de que deseja excluir estas páginas de reservas?`,
  TITLE: `Páginas de Reserva`,
  ADD_TO_SITE: `Adicionar ao Site`,
  CLONE_MODAL_TITLE: `Clonar Página de Reserva`,
  DEFAULT_FILTER: `Minhas Páginas de Reserva`,
  SHARED_LABEL: `Compartilhado`,
  CLONE_NOTIFICATION: `Por favor, note que a propriedade "Apenas para Administradores" está desativada por padrão para um registro clonado.`,
  TOOLTIP: `Criar ligações de marcação para se encontrar consigo e/ou com outros utilizadores`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE_PART_1: `O que`,
  WHAT_TITLE_PART_2: `é esta Página de Reserva para?`,
  WHO_TITLE_PART_1: `Quem`,
  WHO_TITLE_PART_2: `pode hospedar?`,
  HOW_TITLE_PART_1: `Como`,
  HOW_TITLE_PART_2: `deveria parecer?`,
  HOW_DESCRIPTION: `Marca, Etiquetas`,
  ADDITIONAL_OPTIONS: `Opções Adicionais`,
  CALENDAR_TITLE_PART_1: `Configuração de`,
  CALENDAR_TITLE_PART_2: `Calendário`,
  PAGE_TITLE_PART_1: `Configuração de`,
  PAGE_TITLE_PART_2: `Página`,
  BOOKING_TEMPLATE_TITLE: `Mais Configurações da Página de Reserva?`,
  BOOKING_TEMPLATE_PART_1: `Cada `,
  BOOKING_TEMPLATE_PART_2: `"Modelo de reserva"`,
  BOOKING_TEMPLATE_PART_3: ` contém configurações que afetam as páginas de reservas associadas. Por exemplo, cada “Página de confirmação” pode ser exclusiva do modelo de reserva, portanto, essas configurações são encontradas no “Modelo de reserva”.`,
  VIEW_BOOKING_PAGE: 'Ver página de reserva',
  COPY_LINK: 'Copiar link',
  ADD_TO_WEBSITE: 'Adicionar ao site',
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Registro bloqueado. Essas configurações são do modelo de reserva intitulado: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Clique aqui para substituir essas configurações`,
  MAIN_HOST: `Host Principal`,
  CO_HOST: `Co-Host`,
  HOST_NOTIFICATION: `O Anfitrião Principal ainda não possui opções de localização conectadas. Esta página de reserva não funcionará até que ele o faça.`,
  LOCKED_ADMIN_ONLY: `Apenas um administrador pode modificar estas definições. Última modificação por:`,
  LOCKED_NO_PERMISSIONS: `Não tem permissões para editar esta página de reservas. Última modificação por:`,
  BACK_TO_LIST: 'Voltar à lista',
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Nome da Página de Reserva`,
  NAME_PLACEHOLDER: `Nome interno para esta Página de Reserva`,
  NAME_TOOLTIP: `Este texto será exibido aos participantes da reunião no calendário e nos e-mails. Por exemplo, "Reunião de 30min" ou "Exame Dentário".`,
  ACTIVE: `Ativa`,
  INACTIVE: `Inativa`,
  BOOKING_TEMPLATE: `Modelo de Reserva`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Pesquisar e adicionar modelo de reserva`,
  MEETING_NAME_LABEL: `Nome da Reunião`,
  CUSTOM_NAME_LABEL: `Nome de exibição`,
  CUSTOM_NAME_PLACEHOLDER: `Nome personalizado para esta página de reserva`,
  BOOKING_PAGE: `Página de Reserva`,
  ADMIN_ONLY: `Apenas Admin`,
  ADMIN_ONLY_PLACEHOLDER: `Ativar para que apenas um administrador possa modificar essas configurações.`,
});

export const BookingPageHowStep = Object.freeze({
  PREVIEW_DESCRIPTION: `Você pode visualizar a aparência atual desta página de reserva e substituir rótulos aqui.`,
  PREVIEW_BUTTON: `Visualizar e Substituir Rótulos`,
  PREVIEW_TITLE: `Substituir Rótulos`,
  BRANDING_DESCRIPTION: `Você pode substituir as configurações de marcação aqui.`,
  BRANDING_BUTTON: `Substituir Configurações de Marcação`,
  BRANDING_TITLE: `Substituir Configurações de Marcação`,
});

export const BookingPageBranding = Object.freeze({
  DESCRIPTION: `Por padrão, esta página de reserva usará as configurações de marcação definidas para este espaço de trabalho. No entanto, você pode substituir as configurações específicas desta página de reserva abaixo.`,
  UPLOAD_LOGO: `Envie seu logotipo`,
  UPLOAD_LOGO_BUTTON: `Enviar logotipo`,
  UPLOAD_BACKGROUND: `Envie seu plano de fundo`,
  UPLOAD_BACKGROUND_BUTTON: `Enviar plano de fundo`,
  OPACITY: `Opacidade`,
  CSS: `CSS`,
  CSS_PLACEHOLDER: `O CSS personalizado pode ser adicionado aqui para substituições`,
  FOOTER_TEXT: `Texto do rodapé`,
  SAVE: `Salvar`,
});

export const BookingPageCalendarStep = Object.freeze({
  TIME_FORMAT: `Formato de Hora`,
  HOUR_12: `12 Horas`,
  HOUR_24: `24 Horas`,
  CALENDAR_FORMAT: `Formato de Calendário`,
  MONTH_VIEW: `Visualização Mensal`,
  WEEK_VIEW: `Visualização Semanal`,
  START_TIME_INTERVALS: `Intervalos de Início`,
  START_TIME_INTERVALS_DESCRIPTION: `Mostrar slots de tempo disponíveis em incrementos de...`,
  START_TIME_INTERVALS_TOOLTIP: `Escolha a duração dos intervalos que deseja mostrar ao agendar uma consulta. Por padrão, temos 15 minutos selecionados para maximizar a disponibilidade de slots. Esta opção exibirá os slots disponíveis por esse intervalo.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Topo do Intervalo`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Ative "Topo do Intervalo" se você precisar que todos os compromissos sempre comecem no início do intervalo, independentemente da duração do modelo de reserva. Por exemplo, as durações dos slots estariam disponíveis às 8h, 9h, 10h, etc. NOTA: Ativar esse recurso não permitirá que o usuário maximize a disponibilidade.`,
  TIME_ZONES: `Fusos Horários`,
  TIME_ZONES_TOOLTIP: `Os seguintes fusos horários estarão disponíveis na Página de Reserva voltada para o cliente. Se você escolher apenas um fuso horário, ele será fixo, então o campo de fuso horário será desativado e o usuário não poderá alterá-lo.`,
  TIME_ZONE_DETECT: `Detectar e exibir automaticamente os slots de tempo no fuso horário do convidado.`,
  TIME_ZONE_DISPLAY: `Apenas exibir os seguintes fusos horários (ideal para eventos presenciais)`,
  DEFAULT_TIME_ZONE: `Fuso Horário Padrão`,
});

export const BookingPageSettingsStep = Object.freeze({
  INVITE_OTHERS_LABEL: `Permitir que os convidados convidem outros`,
  INVITE_OTHERS_TOOLTIP: `Ativar esta função exibirá um campo "Convidar Outros" na Página de Reserva, permitindo que o convidado adicione outras pessoas à lista de participantes digitando endereços de e-mail separados por vírgulas.`,
  INVITE_OTHERS_SHORT: `Convidar outros`,
  ENTER_NOTE_LABEL: `Permitir que os convidados insiram uma nota para todos os convidados`,
  ENTER_NOTE_TOOLTIP: `Ativar esta função exibirá um campo "Nota para os Participantes", permitindo que o convidado insira uma nota a ser exibida no compromisso do calendário reservado para que todos os participantes vejam.`,
  ENTER_NOTE_SHORT: `Inserir uma nota`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Inferior Esquerdo`,
  BOTTOM_RIGHT: `Inferior Direito`,
  BUTTON_POPUP_TITLE: `Botão para Pop-up`,
  COPY_CODE_EMBED_DESCRIPTION: `Incorpore seu widget SUMO em seu site colando o código HTML no local desejado.`,
  COPY_CODE_LINK_DESCRIPTION: `Exiba um botão de reserva flutuante nas páginas desejadas, colocando este código em seu HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Exiba seu link de reserva em qualquer lugar, colocando este código HTML em suas páginas, assinaturas de e-mail e muito mais.`,
  COPY_CODE_TITLE: `Copiar Código`,
  CUSTOMIZE_DESCRIPTION: `Personalize a aparência para se integrar perfeitamente ao seu site.`,
  CUSTOMIZE_TITLE: `Personalizar`,
  EMBED_DESCRIPTION: `Exiba sua página de reserva SUMO1 em um iFrame em seu site.`,
  EMBED_TITLE: `Incorporar`,
  FLOATING_BUTTON_DESCRIPTION: `Torne o botão flutuante na página`,
  HIDE_PAGE_DETAILS: `Ocultar Detalhes da Página`,
  HIDE_COOKIE_BANNER: `Ocultar Banner de Cookies`,
  LABEL: `Rótulo`,
  LINK_DESCRIPTION: `Adicione um link de texto ao seu site que abrirá sua página de reserva SUMO1 em um pop-up.`,
  LINK_TITLE: `Link de Texto`,
  LOCATION: `Localização`,
  POPUP_DESCRIPTION: `Exiba sua página de reserva SUMO1 em um pop-up quando um botão for clicado.`,
  POPUP_TITLE: `Pop-up`,
  TITLE: `Como você adicionará o SUMO ao seu site?`,
  TOP_LEFT: `Superior Esquerdo`,
  TOP_RIGHT: `Superior Direito`,
});

export const EditBookingPageWhereStep = Object.freeze({
  HOSTS_PHONE_NOTIFICATION: `O anfitrião selecionado não possui número de telefone`,
  VIDEO_CONFERENCE_NOTIFICATION: `O anfitrião selecionado não possui nenhuma videoconferência`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirmar Substituição`,
  DESCRIPTION: `Ao substituir as configurações de um modelo de reserva, você tecnicamente não está mais usando esse modelo de reserva,
  mas sim criando uma Página de Reserva Personalizada, que requer um nome.`,
});
